import { useRef, useState } from "react";
import LinkWithIcon from "../../General/LinkWithIcon";
import Badge from "../../General/Badge";

const ArticleCard = ({ data }) => {
  const MAXIMUMLINKCOUNT = 3;
  const [cardIsExpanded, setCardIsExpanded] = useState(false);
  const expandingCard = useRef();

  return (
    <div
      className="ui-card h-82 h- overflow-hidden transition-all ease-in-out duration-300"
      ref={expandingCard}
      style={
        cardIsExpanded ? { height: expandingCard.current.scrollHeight } : {}
      }
    >
      <div className="p-4 text-center">
        <div className="font-header text-theme-text-quinary text-3xl">
          {data.title}
        </div>
        <p className="text-sm mt-4">{data.instructions}</p>
      </div>

      <div className="px-4 pb-1 border-b border-theme-rule-medium md:px-6">
        {data.links.length > MAXIMUMLINKCOUNT ? (
          <div className="flex gap-x-6">
            <button
              className="group text-lg font-semibold flex items-start justify-start hover:text-theme-action-hover text-left"
              onClick={() => setCardIsExpanded((current) => !current)}
            >
              <span className="flex-none no-underline mt-[.375rem] mr-3">
                <svg
                  className="ui-icon w-4 h-4 text-theme-action group-hover:text-theme-action-hover"
                  focusable="false"
                  aria-hidden="true"
                  role="presentation"
                >
                  <use
                    href={`#${
                      cardIsExpanded ? "ui-icon-minus" : "ui-icon-plus"
                    }`}
                  ></use>
                </svg>
              </span>
              <span className="group-hover:underline">
                {data.expandableTriggerText}{" "}
                {data?.showCount ? `(${data.links.length})` : ""}
              </span>
            </button>
            {data?.badgeStatus && data?.badgeText && (
              <div className="self-start min-w-20">
                <Badge
                  srPrefix="Training"
                  status={data.badgeStatus}
                  statusText={`${data.badgeText}${
                    data.showCount ? ` (${data.links.length})` : ""
                  }`}
                />
              </div>
            )}
          </div>
        ) : (
          <div className="text-lg font-semibold">
            <div className="flex flex-row justify-between">
              {data.expandableTriggerText}
              {data?.showCount ? `(${data.links.length})` : ""}
              {data?.badgeStatus && data?.badgeText && (
                <div className="self-start min-w-20">
                  <Badge
                    srPrefix="Training"
                    status={data.badgeStatus}
                    statusText={`${data.badgeText} (${data.links.length})`}
                  />
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <div className="p-4 md:px-6 md:pb-6">
        <div className="grid grid-cols-1 gap-4">
          {data.links.map((link, index) => (
            <LinkWithIcon key={`${link.title}-training-${index}`} link={link} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ArticleCard;
