import { useState } from "react";
import { usePagination } from "../../../hooks/usePagination";
import SimpleDataRow from "../../General/SimpleDataRow";
import Pagination from "../../General/Pagination";
import AgentSendReminderModal from "../ModalDialogs/AgentSendReminderModal";

export default function FilteredBusinessTabPanel({ businesses }) {
  const [isOpenSendReminder, setIsOpenSendReminder] = useState(false);
  const NUMBERPERPAGE = 5;
  const { current: currentSelectedBusinesses, paginationProps } = usePagination(
    businesses,
    NUMBERPERPAGE
  );
  return (
    <>
      {currentSelectedBusinesses.map((business, idx) => {
        return (
          <SimpleDataRow
            key={`agent-business-category-${business.category} - ${idx}`}
            type="bookOfBusiness"
            data={business}
            isOpenSendReminder={isOpenSendReminder}
            setIsOpenSendReminder={setIsOpenSendReminder}
          />
        );
      })}
      {/* <!-- paging component --> */}
      <div className="flex justify-between items-start mt-6">
        <div></div>
        <Pagination {...paginationProps} />
      </div>

      {/* Send customer a reminder Dialog */}
      <AgentSendReminderModal
        isOpenSendReminder={isOpenSendReminder}
        setIsOpenSendReminder={setIsOpenSendReminder}
      />
    </>
  );
}
