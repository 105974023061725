import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";

import { bob } from "../../js/placeholderData/agents/bobData";
import { Tab } from "@headlessui/react";
import { Link } from "react-router-dom";

import FilteredBusinessTabPanel from "../../components/AgentPortal/BookOfBusiness/FilteredBusinessTabPanel";
import FilterDialog from "../../components/AgentPortal/BookOfBusiness/FilterDialog";
import SortByDate from "../../components/General/SortByDate";
import PolicyLookupModal from "../../components/AgentPortal/ModalDialogs/PolicyLookupModal";

export default function BookOfBusiness() {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });

  let [searchParams, setSearchParams] = useSearchParams();

  const [currentPolicyStatusSelection, setCurrentPolicyStatusSelection] =
    useState("");
  const [filteredBusinesses, setFilteredBusinesses] = useState(bob);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [isOpenPolicyLookup, setIsOpenPolicyLookup] = useState(false);

  useEffect(() => {
    setValue(
      "policyStatus",
      searchParams.get("type") ? searchParams.get("type") : "all"
    );
  }, []);

  const policyStatusChanged = (data) => {
    console.log(data);
  };

  return (
    <>
      <div className="container mb-8">
        <h1>Book of Business</h1>
        <p>
          To search for policies from any customer use the{" "}

          {/* <Link to="/policy-lookup">Policy Look-up</Link> */}
          <button
            className="ui-btn-hyperlink ui-link-icon font-semibold"
            onClick={() => setIsOpenPolicyLookup(true)}
          >
            <svg
              className="ui-icon w-4 h-4"
              focusable="false"
              aria-hidden="true"
              role="presentation"
            >
              <use href="#ui-icon-search"></use>
            </svg>
            Policy lookup
          </button>
          .
        </p>
        <hr />
      </div>

      <div className="container ui-component-spacing">
        <form
          className="flex flex-col sm:flex-row sm:items-center"
          onSubmit={handleSubmit(policyStatusChanged)}
        >
          <label
            htmlFor="policyStatus"
            className="whitespace-nowrap mb-1 sm:mr-2 sm:mb-0"
          >
            View by policy status
          </label>
          <span id="policyStatusInstructions" className="sr-only">
            Selecting a policy status will change which policies are displayed
          </span>
          <div>
            <select
              id="policyStatus"
              aria-describedby="policyStatusInstructions"
              aria-controls="bookOfBusinessList"
              {...register("policyStatus", {
                onChange: (e) => {
                  setSearchParams({ type: e.target.value });
                  setCurrentPolicyStatusSelection(
                    e.target.value === "all"
                      ? ""
                      : e.target.options[e.target.selectedIndex].text
                  );
                },
              })}
            >
              <option value="all">All</option>
              <option value="pendingLapse">Pending Lapse</option>
              <option value="conditionallyIssued">Conditionally Issued</option>
              <option value="inForce">In Force</option>
              <option value="policiesWithOpenClaims">
                Policies with Open Claims
              </option>
              <option value="incomplete">Incomplete</option>
              <option value="issuedPendingAcceptance">
                Issued Pending Acceptance
              </option>
            </select>
          </div>
        </form>
      </div>

      <div className="container ui-component-spacing">
        <Tab.Group
          onChange={(tabGroupIndex) => {
            switch (tabGroupIndex) {
              // all
              case 0:
                setFilteredBusinesses(bob);
                break;
              // category 1
              case 1:
                setFilteredBusinesses(
                  bob.filter((businesses) => businesses.category === "1")
                );
                break;
              // category 2
              case 2:
                setFilteredBusinesses(
                  bob.filter((businesses) => businesses.category === "2")
                );
                break;
              // category 3
              case 3:
                setFilteredBusinesses(
                  bob.filter((businesses) => businesses.category === "3")
                );
                break;
            }
          }}
        >
          <div className="ui-card flex-grow px-2 p-1 md:px-4">
            <Tab.List>
              <Tab className="text-sm mx-3 pt-3 pb-2 mb-1 md:text-base md:mx-4 lg:text-lg">
                All {currentPolicyStatusSelection}
              </Tab>
              <Tab className="text-sm mx-3 pt-3 pb-2 mb-1 md:text-base md:mx-4 lg:text-lg">
                Priority
              </Tab>
              <Tab className="text-sm mx-3 pt-3 pb-2 mb-1 md:text-base md:mx-4 lg:text-lg">
                Pinned
              </Tab>
            </Tab.List>
          </div>

          <div className="mx-2 flex flex-col items-centers justify-between md:mx-4 md:flex-row md:space-x-4">
            <div className="flex flex-row items-center mt-4 xl:mt-6">
              <div className="font-semibold">
                All {currentPolicyStatusSelection} ({filteredBusinesses.length})
              </div>
            </div>
            <div className="flex flex-row items-center mt-4 space-x-4 justify-between md:justify-end lg:space-x-6 xl:mt-6">
              <div>
                <SortByDate changesDisplay="bookOfBusinessList" />
              </div>
              <div>
                <button
                  className="ui-btn-primary flex items-center"
                  onClick={() => setFilterDialogOpen(true)}
                >
                  <svg
                    className="ui-icon w-3.5 h-3.5 mr-2"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-filter"></use>
                  </svg>
                  Filter options
                </button>
              </div>
            </div>
          </div>
          <div id="bookOfBusinessList" className="mt-4 xl:mt-6">
            <Tab.Panels>
              {/* category all */}
              <Tab.Panel>
                <FilteredBusinessTabPanel businesses={filteredBusinesses} />
              </Tab.Panel>

              {/* category 1 */}
              <Tab.Panel>
                <FilteredBusinessTabPanel businesses={filteredBusinesses} />
              </Tab.Panel>

              {/* category 2 */}
              <Tab.Panel>
                <FilteredBusinessTabPanel businesses={filteredBusinesses} />
              </Tab.Panel>
            </Tab.Panels>
          </div>
        </Tab.Group>
      </div>

      <FilterDialog
        dialogState={filterDialogOpen}
        setDialogState={setFilterDialogOpen}
      />
      {/* Policy Lookup Dialog */}
      <PolicyLookupModal
        isOpenPolicyLookup={isOpenPolicyLookup}
        setIsOpenPolicyLookup={setIsOpenPolicyLookup}
      />
    </>
  );
}
