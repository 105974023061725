import { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import SVGSprite from "./components/General/SvgSprite";
import Header from "./components/General/Header";
import UnauthHeader from "./components/UnauthorizedUser/UnauthHeader";
import Breadcrumb from "./components/General/Breadcrumb";
// import ChatBubble from "./components/General/ChatBubble";
import Footer from "./components/General/Footer";

import Landing from "./routes/landing";
import Register from "./routes/register";
import ForgotPasswordContainer from "./routes/forgot-password";
import ForgotUsername from "./components/ForgotUsername/ForgotUsername";
import UnauthClaims from "./routes/claims-unauth";

import Policies from "./routes/policies";
import PolicyDetails from "./routes/policy-details";
import Beneficiaries from "./routes/beneficiaries";

import Payment from "./routes/payment";
import MakeAPayment from "./routes/make-a-payment";
import UnauthPayment from "./routes/make-a-payment-unauth";
import AutoPay from "./routes/autopay";
import ManagePayment from "./routes/manage-payment";

import ServicesClaims from "./routes/services-claims";
import ServicesLifeInsurance from "./routes/services-life-insurance";
import ServicesAnnuity from "./routes/services-annuity";
import ServicesDetails from "./routes/services-request-details";
import Claims from "./routes/claims";
import FileAClaim from "./routes/file-a-claim";
import ClaimsStatus from "./routes/claims-status";

import Messages from "./routes/messages";
import Help from "./routes/help";
import HelpArticle from "./routes/help-article";
import HelpFaqs from "./routes/help-faqs";
import HelpExplore from "./routes/help-explore";
import SearchResults from "./routes/search-results";
import Billing from "./routes/billing";
import BeneficiaryErrorPlaceholderPage from "./routes/beneficiaryErrorPlaceholderPage";
import Profile from "./routes/profile";

import SalesForceTest from "./routes/saleforce-test";

import { useQuery, gql } from "@apollo/client";

export default function App() {
  // initialize var
  var initESW = function (gslbBaseURL) {
    window.embedded_svc.settings.displayHelpButton = true; //Or false
    window.embedded_svc.settings.language = ""; //For example, enter 'en' or 'en-US'

    //window.embedded_svc.settings.defaultMinimizedText = '...'; //(Defaults to Chat with an Expert)
    //window.embedded_svc.settings.disabledMinimizedText = '...'; //(Defaults to Agent Offline)

    //window.embedded_svc.settings.loadingText = ''; //(Defaults to Loading)
    //window.embedded_svc.settings.storageDomain = 'yourdomain.com'; //(Sets the domain for your deployment so that visitors can navigate subdomains during a chat session)

    // Settings for Chat
    //window.embedded_svc.settings.directToButtonRouting = function(prechatFormData) {
    // Dynamically changes the button ID based on what the visitor enters in the pre-chat form.
    // Returns a valid button ID.
    //};
    //window.embedded_svc.settings.prepopulatedPrechatFields = {}; //Sets the auto-population of pre-chat form fields
    //window.embedded_svc.settings.fallbackRouting = []; //An array of button IDs, user IDs, or userId_buttonId
    //window.embedded_svc.settings.offlineSupportMinimizedText = '...'; //(Defaults to Contact Us)

    window.embedded_svc.settings.enabledFeatures = ["LiveAgent"];
    window.embedded_svc.settings.entryFeature = "LiveAgent";

    window.embedded_svc.init(
      "https://nttfsiclaims--fsicedev.sandbox.my.salesforce.com",
      "https://nttfsiclaims--fsicedev.sandbox.my.salesforce-sites.com/liveAgentSetupFlow",
      gslbBaseURL,
      "00D2f0000008gBu",
      "EVL_Agent_Chat_Embed_Deploy",
      {
        baseLiveAgentContentURL:
          "https://c.la3-c1cs-ia2.salesforceliveagent.com/content",
        deploymentId: "5722f00000001AA",
        buttonId: "5732f0000000250",
        baseLiveAgentURL: "https://d.la3-c1cs-ia2.salesforceliveagent.com/chat",
        eswLiveAgentDevName:
          "EmbeddedServiceLiveAgent_Parent04I2f0000008OggEAE_182d02b3997",
        isOfflineSupportEnabled: false,
      }
    );
  };

  useEffect(() => {
    // start init
    if (!window.embedded_svc) {
      console.log("here");
      const script = document.createElement("script");
      const link = document.createElement("link");
      script.src =
        "https://nttfsiclaims--fsicedev.sandbox.my.salesforce.com/embeddedservice/5.0/esw.min.js";
      link.href = "./sf_minimized_button_style.css";
      link.type = "text/css";
      link.rel = "stylesheet";
      script.onload = function () {
        initESW(null);
      };
      document.body.appendChild(script);
      document.head.appendChild(link);
    } else {
      console.log("has embeded");
      initESW("https://service.force.com");
    }
  }, []);

  const [authenticated, setAuthenicated] = useState(
    window.localStorage.getItem("authenticated") === "true" ? true : false
  );
  let navigate = useNavigate();
  let location = useLocation();

  const GET_RAM = gql`
    query Query {
      characters(page: 2, filter: { name: "Morty" }) {
        info {
          count
        }
        results {
          name
          image
        }
      }
    }
  `;
  const { loading, error, data } = useQuery(GET_RAM);

  useEffect(() => {
    console.log(data);
  }, [data]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    if (
      !authenticated &&
      !["/", "/forgot-password", "/forgot-username", "/register"].includes(
        location.pathname
      ) &&
      location.pathname.split("/")[1] !== "guest" &&
      location.pathname.split("/")[1] !== "help"
    ) {
      navigate("/", { replace: true });
    }
  }, [location.pathname]);

  return (
    <div className="font-body text-theme-text-primary">
      <SVGSprite />

      <div className="flex flex-col min-h-screen">
        {authenticated ? (
          <Header auth={authenticated} setAuthenicated={setAuthenicated} />
        ) : (
          <UnauthHeader
            auth={authenticated}
            setAuthenicated={setAuthenicated}
          />
        )}
        <main id="mainContent" className="flex flex-col flex-grow">
          <div className="border border-transparent">
            <Breadcrumb />
            {/* <ChatBubble /> */}

            <Routes>
              <Route path="/salesforce-test" element={<SalesForceTest />} />
              <Route
                path="/"
                element={<Landing setAuthenicated={setAuthenicated} />}
              />
              <Route path="/register" element={<Register />} />
              <Route
                path="/forgot-password"
                element={<ForgotPasswordContainer />}
              />
              <Route path="/forgot-username" element={<ForgotUsername />} />
              {/* unauthorized routes */}
              <Route path="/guest/make-a-payment" element={<UnauthPayment />} />
              <Route path="/guest/claims" element={<UnauthClaims />} />
              <Route path="/guest/claims/:id" element={<ClaimsStatus />} />
              <Route
                path="/guest/claims/file-a-claim"
                element={<FileAClaim />}
              />
              <Route path="/guest/help" element={<Help />} />
              {/* customer profile routes */}
              <Route path="/profile" element={<Profile />} />
              {/* policy routes */}
              <Route path="/policies" element={<Policies />} />
              <Route path="/policies/:policy_id" element={<PolicyDetails />} />
              <Route
                path="/policies/beneficiaries/"
                element={<Beneficiaries />}
              >
                <Route
                  path="/policies/beneficiaries/:policy_id"
                  element={<Beneficiaries />}
                />
              </Route>
              {/* payment routes */}
              <Route path="/payment" element={<Payment />} />
              <Route path="/payment/autopay/:policy_id" element={<AutoPay />} />
              <Route
                path="/payment/make-a-payment"
                element={<MakeAPayment />}
              />
              <Route path="/payment/billing" element={<Billing />} />
              <Route
                path="/payment/manage-payments"
                element={<ManagePayment />}
              />
              {/* service and claim routes */}
              <Route path="/services-claims" element={<ServicesClaims />} />
              <Route
                path="/services-claims/services-life-insurance"
                element={<ServicesLifeInsurance />}
              />
              <Route
                path="/services-claims/services-annuity"
                element={<ServicesAnnuity />}
              />
              <Route path="/services-claims/claims" element={<Claims />} />
              <Route
                path="/services-claims/claims/file-a-claim"
                element={<FileAClaim />}
              />
              <Route
                path="/services-claims/claims/:id"
                element={<ClaimsStatus />}
              />
              <Route
                path="/services-claims/services/:id"
                element={<ServicesDetails />}
              />
              {/* message routes */}
              <Route path="/messages" element={<Messages />} />
              {/* help routes */}
              <Route path="/help" element={<Help />} />
              <Route path="/help/help-faqs" element={<HelpFaqs />} />
              <Route path="/help/help-explore" element={<HelpExplore />} />
              <Route path="/help/search-results" element={<SearchResults />} />
              <Route
                path="/help/article/:article_id"
                element={<HelpArticle />}
              />
              <Route
                path="/beneficiaryErrorPlaceholderPage"
                element={<BeneficiaryErrorPlaceholderPage />}
              />
            </Routes>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}
