import LoginCard from "../../components/LoginCard";
import AgentSignupCard from "../../components/AgentPortal/AgentSignupCard";

export default function Landing({ setAuthenicated }) {
  return (
    <>
      <div className="container mb-8">
        <h1>Welcome to the Everlake Agent Portal</h1>
        <hr />
      </div>

      <div className="container ui-component-spacing">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 xl:gap-6">
          <LoginCard setAuthenicated={setAuthenicated} />
          <AgentSignupCard />
        </div>
      </div>
    </>
  );
}
