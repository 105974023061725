import { useState } from "react";
import { RadioGroup } from "@headlessui/react";

import FormInputError from "../../General/FormInputError"; 
export default function EnrollmentStatus({
  register,
  setValue,
  errors,
  setCurrentStep,
  showNext,
  setDisableSubmit,
  filteredPolicy
}) {
  const [option, setOption] = useState(""); 

  return (
    <>
      <div className="px-0 md:px-8">
        <RadioGroup
          className="ui-form-card-row2 font-semibold grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
          value={option}
          onChange={(value) => {
            setValue("status", value);
            setOption(value);
          }}
          aria-label="Autopay enrollment status"
        >
          {filteredPolicy.isAutoPay === false && (
            <RadioGroup.Option
              className={({ checked }) =>
                `ui-form-card cursor-pointer font-semibold ${
                  checked ? "selected" : ""
                }`
              }
              value="no"
              onClick={(e) => {
                setCurrentStep(1);
                setDisableSubmit(false);
              }}
            >
              <RadioGroup.Label as="label">
                <span className="block text-sm mr-5 mb-1">Not enrolled</span>
              </RadioGroup.Label>
              <div className="ui-form-card-checkbox">
                <svg
                  className="ui-icon w-2 h-2 text-white hidden"
                  focusable="false"
                  aria-hidden="true"
                  role="presentation"
                >
                  <use href="#ui-icon-checkmark"></use>
                </svg>
              </div>
            </RadioGroup.Option>
          )}

          <RadioGroup.Option
            className={({ checked }) =>
              `ui-form-card cursor-pointer font-semibold ${
                checked ? "selected" : ""
              }`
            }
            value="yes"
            onClick={(e) => {
              setDisableSubmit(true);
              showNext();
            }}
          >
            <RadioGroup.Label as="label">
              <span className="block text-sm mr-5 mb-1">Enrolled</span>
              <span className="block text-xxs text-theme-text-tertiary font-normal">
                Peace of mind that policies will be in good order
              </span>
            </RadioGroup.Label>
            <div className="ui-form-card-checkbox">
              <svg
                className="ui-icon w-2 h-2 text-white hidden"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href="#ui-icon-checkmark"></use>
              </svg>
            </div>
          </RadioGroup.Option>
        </RadioGroup>
      </div>

      {errors.status?.message && (
        <FormInputError errorMessage={errors.status.message} />
      )}
    </>
  );
}
