
import AgentNotesDeleteModal from "../../ModalDialogs/AgentNotesDeleteModal";
import AgentNotesEditModal from "../../ModalDialogs/AgentNotesEditModal";
import { useState } from "react";

const AgentNotesCard = ({ data }) => {
  const [isOpenDeleteNote, setIsOpenDeleteNote] = useState(false);
  const [isOpenEditNote, setIsOpenEditNote] = useState(false);
  
  return (
    <>
      <div className="ui-card p-4 my-4 xl:px-6">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-[25%,45%,1fr,1fr] xl:grid-cols-[20%,55%,1fr,1fr] xl:gap-6">
          <div>
            <div className="text-lg font-semibold text-theme-text-secondary">
              {data.type}
            </div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              {data.subject}
            </div>
          </div>
          <div className="">
            <div className="text-xs text-theme-text-tertiary">Note</div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              {data.note}
            </div>
          </div>
          <div className="">
            <div className="text-xs text-theme-text-tertiary">Date</div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              {data.date}
            </div>
          </div>

          <div className="md:justify-self-end">
            <div className="text-xs text-theme-text-tertiary">You can</div>
            <div className="text-sm font-semibold  text-theme-text-secondary">
              <button
                className="ui-btn-hyperlink font-semibold"
                onClick={() => setIsOpenDeleteNote(true)}
              >
                Delete<span className="sr-only"> this note</span>
              </button>
              <span className="mx-2">|</span>
              <button
                className="ui-btn-hyperlink font-semibold"
                onClick={() => setIsOpenEditNote(true)}
              >
                Edit<span className="sr-only"> this note</span>
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Delete Agent Note Dialog */}
      <AgentNotesDeleteModal
        isOpenDeleteNote={isOpenDeleteNote}
        setIsOpenDeleteNote={setIsOpenDeleteNote}
      />
      {/* Edit Agent Note Dialog */}
      <AgentNotesEditModal
        isOpenEditNote={isOpenEditNote}
        setIsOpenEditNote={setIsOpenEditNote}
      />
    </>
  );
};

export default AgentNotesCard;