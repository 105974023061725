import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  gql,
} from "@apollo/client";

import "./index.css";
import CustomerPortal from "./CustomerPortal";
import AgentPortal from "./AgentPortal";
import reportWebVitals from "./reportWebVitals";

const client = new ApolloClient({
  uri: "https://rickandmortyapi.com/graphql",
  cache: new InMemoryCache(),
});

const container = document.getElementById("root");
const root = createRoot(container); // createRoot(container!) if you use TypeScript

// check agent prefix
if (window.location.pathname.split("/")[1] === "agent") {
  // add in agent prefix for all routes
  root.render(
    <ApolloProvider client={client}>
      <BrowserRouter basename="agent">
        <Routes>
          <Route path="*" element={<AgentPortal />} />
        </Routes>
      </BrowserRouter>
    </ApolloProvider>
  );
} else {
  root.render(
    <ApolloProvider client={client}>
      <BrowserRouter>
        <Routes>
          <Route path="*" element={<CustomerPortal />} />
        </Routes>
      </BrowserRouter>
    </ApolloProvider>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
