export const securityQuestions = [
  { id: 1, question: `What city were you born in? ` },
  { id: 2, question: `What was the name of your elementary school? ` },
  { id: 3, question: `What is the name of the first street you lived on? ` },
  {
    id: 4,
    question: `What is the name of the high school you graduated from? `,
  },
  { id: 5, question: `What was your high school mascot? ` },
  { id: 6, question: `What year did you graduate from high school? ` },
  { id: 7, question: `What is your mother's maiden name? ` },
  { id: 8, question: `What is your mother's date of birth (MMDD format)?` },
  { id: 9, question: `What is your mother's middle name? ` },
  {
    id: 10,
    question: `In what city or town was your father born (Enter city only)? `,
  },
  { id: 11, question: `What is your father's middle name? ` },
  { id: 12, question: `What is the model of the first car you bought? ` },
  { id: 13, question: `What is your favorite sport? ` },
  {
    id: 14,
    question: `What is a relative's phone number that Is not your own? `,
  },
  { id: 15, question: `What is the farthest place you have traveled to? ` },
  { id: 16, question: `What is/was the name of your first pet? ` },
  {
    id: 17,
    question: `What is the name of the first company you worked for? `,
  },
];
