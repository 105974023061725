import ServicesAndClaimsCard from "../components/ServicesAndClaims/ServicesAndClaimsCard";
export default function ServicesLifeInsurance() {
  return (
    <>
      {/* SPRINT-3-CUSTOMER-claims-and-forms.pdf (pg 6)*/}
      <div className="container mb-8">
        <h1>Life Insurance Services</h1>
        <p>
          Here are links to forms and resource to manage your life insurance
          policies.
        </p>
        <hr />
      </div>

      <div className="container ui-component-spacing">
        <div className="grid grid-cols-1 auto-rows-fr gap-4 mt-4 md:grid-cols-2 lg:grid-cols-3 lg:gap-6 md:mt-6">
          <ServicesAndClaimsCard
            data={{
              title: "Payments",
              desc: "Blurb about flexible and comprehensive self service options for payments goes here. This tile is flexible to accommodate any payment functions a client wants to enable for self service.",
              subTitle: "You can",
              links: [
                {
                  title: "Make a payment",
                  icon: "#ui-icon-payment",
                  url: "/payment",
                },
                {
                  title: "Update a payment method",
                  icon: "#ui-icon-edit-payment",
                  url: "/payment/manage-payments",
                },
                {
                  title: "Turn on or off autopay",
                  icon: "#ui-icon-toggle",
                  url: "/payment/manage-payments",
                },
                {
                  title: "Change payment draft dates",
                  icon: "#ui-icon-edit-calendar",
                  url: "/payment/manage-payments",
                },
                {
                  title: "Additional payment functions can be added",
                  icon: "#ui-icon-edit-settings",
                  url: "/payment",
                },
              ],
            }}
          />
          <ServicesAndClaimsCard
            data={{
              title: "Beneficiaries",
              desc: "Blurb about this grouping of services goes here. This tile is flexible to accommodate any functions a client wants to enable for self service.",
              subTitle: "You can",
              links: [
                {
                  title: "Add, edit or remove beneficiaries",
                  icon: "#ui-icon-user-plus",
                  url: "/policies/beneficiaries/54723452",
                },
                {
                  title: "Change the percentage split between beneficiaries",
                  icon: "#ui-icon-percentage",
                  url: "/policies/beneficiaries/54723452",
                },
                {
                  title: "Additional payment functions can be added",
                  icon: "#ui-icon-edit-settings",
                  url: "/",
                },
              ],
            }}
          />
          <ServicesAndClaimsCard
            data={{
              title: "Policy changes",
              desc: "Blurb about flexible and comprehensive self service options for payments goes here. This tile is flexible to accommodate any payment functions a client wants to enable for self service.",
              subTitle: "You can",
              links: [
                {
                  title: "Request a rate change",
                  icon: "#ui-icon-refresh",
                  url: "/",
                },
                {
                  title: "Change owner/insured/payor address",
                  icon: "#ui-icon-edit-user",
                  url: "/",
                },
                {
                  title: "Change owner/insured/payor names",
                  icon: "#ui-icon-edit-user",
                  url: "/",
                },
                {
                  title: "Convert a term policy",
                  icon: "#ui-icon-convert",
                  url: "/",
                },
                {
                  title: "Surrender or cancel a policy",
                  icon: "#ui-icon-cancel",
                  url: "/",
                },
                {
                  title: "Additional payment functions can be added",
                  icon: "#ui-icon-edit-settings",
                  url: "/",
                },
              ],
            }}
          />
          <ServicesAndClaimsCard
            data={{
              title: "Quotes",
              desc: "Blurb about flexible and comprehensive self service options for payments goes here. This tile is flexible to accommodate any payment functions a client wants to enable for self service.",
              subTitle: "You can",
              links: [
                {
                  title:
                    "Get info and a quote on additional coverage that may be available on your current policies",
                  icon: "#ui-icon-document",
                  url: "/",
                },
                {
                  title: "Get info and quotes on a new policy",
                  icon: "#ui-icon-document",
                  url: "/",
                },
                {
                  title: "Additional self service functions can be added",
                  icon: "#ui-icon-edit-settings",
                  url: "/",
                },
              ],
            }}
          />
          <ServicesAndClaimsCard
            data={{
              title: "Loans",
              desc: "Blurb about flexible and comprehensive self service options for payments goes here. This tile is flexible to accommodate any payment functions a client wants to enable for self service.",
              subTitle: "You can",
              links: [
                {
                  title: "Apply for a loan against policy value(s)",
                  icon: "#ui-icon-apply",
                  url: "/",
                },
                {
                  title: "Make payments on loans",
                  icon: "#ui-icon-payment",
                  url: "/",
                },
                {
                  title: "Additional self service functions can be added",
                  icon: "#ui-icon-edit-settings",
                  url: "/",
                },
              ],
            }}
          />
          <ServicesAndClaimsCard
            data={{
              title: "Title for grouping of life insurance services",
              desc: "Blurb about flexible and comprehensive self service options for payments goes here. This tile is flexible to accommodate any payment functions a client wants to enable for self service.",
              subTitle: "You can",
              links: [
                {
                  title: "Self service functions are listed here",
                  icon: "#ui-icon-edit-settings",
                  url: "/",
                },
                {
                  title: "Another self service function",
                  icon: "#ui-icon-edit-settings",
                  url: "/",
                },
                {
                  title: "Additional self service functions can be added",
                  icon: "#ui-icon-edit-settings",
                  url: "/",
                },
              ],
            }}
          />
        </div>
      </div>
    </>
  );
}
