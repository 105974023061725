import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Disclosure } from "@headlessui/react";
import moment from "moment";

import { policies } from "../js/placeholderData/policiesData";

import AuthorizeModal from "../components/ModalDialogs/AuthorizeModal";
import ConfirmModal from "../components/ModalDialogs/ConfirmModal";
import AddPaymentMethodModal from "../components/ModalDialogs/AddPaymentMethodModal";
import DeleteAutopayPaymentModal from "../components/ModalDialogs/DeleteAutopayPaymentModal";
import DeleteNonAutopayPaymentModal from "../components/ModalDialogs/DeleteNonAutopayPaymentModal";
import PanelTwo from "../components/Payments/MakeAPayment/PanelTwo";
import PanelOne from "../components/Payments/MakeAPayment/PanelOne";
import PanelThree from "../components/Payments/MakeAPayment/PanelThree";
import PanelFour from "../components/Payments/MakeAPayment/PanelFour";
import { useNavigate } from "react-router-dom";
import { useAccountTypeCheck } from "../hooks/useAccountTypeCheck";


const paymentArray = [
  { id: 1, name: "an account", value: "an account" },
  { id: 2, name: "another account", value: "another account" },
  { id: 3, name: "some other account", value: "some other account" },
];
const cardArray = [
  { id: 1, name: "Credit Card", value: "card 1" },
  { id: 2, name: "Debit Card", value: "card 2" },
  { id: 3, name: "Other?", value: "card 3" },
];
const MakeAPayment = () => {
  const onSubmit = () => {
    setAddPaymentModal(false);
    setConfirmationModal(true);
  };
  const dataSubmit = (data) => {
    console.log("form data==============>>>", data);
  };
  const {
    register,
    setValue,
    trigger,
    handleSubmit,
    control,
    resetField,
    getValues,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: ''
  });

  const [currentStep, setCurrentStep] = useState(1);
  const [policy, setPolicy] = useState();
  const [payment, setPayment] = useState();
  const [date, setDate] = useState(null);
  const [selectedDate, setSelectedDate] = useState("");

  const [config, setConfig] = useState("Add");
  const [addPaymentModal, setAddPaymentModal] = useState(false);
  const [authorizeModal, setAuthorizeModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [deleteAutopayPaymentModal, setDeleteAutopayPaymentModal] =
    useState(false);
  const [deleteNonAutopayPaymentModal, setDeleteNonAutopayPaymentModal] =
    useState(false);
  const { userType } = useAccountTypeCheck("isBeneficiaryUser");
  let navigate = useNavigate(); 

  useEffect(() => {  
    console.log('Is beneficiary user', userType);
    if(userType){
      navigate(`/beneficiaryErrorPlaceholderPage`, { replace: true });
    }      
  }, [userType]);

  const currDate = moment().format("MM/DD/YYYY");
  const policyFilter = policy
    ? policies.filter((item) => item.policyNumber === policy)
    : "";

  return (
    <>
      <div id="mainContent" className="flex flex-col flex-grow">
        <div className="border border-transparent">
          <div className="container mb-8">
            <h1>Make a Payment</h1>
            <p>Lorem ipsum</p>
            <hr />
          </div>

          <div className="container mb-8">
            <div className="ui-form-make-payment container">
              {/* <!-- Step 1 --> */}
              <div
                id="ID_paymentStep1"
                className="flex flex-row md:ml-8 md:mr-0 lg:ml-20 xl:ml-28 lg:pr-16"
              >
                <div className="w-8 md:flex md:flex-col md:justify-center md:mr-14">
                  <div className="flex justify-center items-center mt-6 mb-4">
                    <div
                      id="ID_paymentStep1Number"
                      className={`inline-flex justify-center items-center h-8 w-8 text-white ${
                        currentStep >= 1
                          ? "bg-ui-blue-dark"
                          : "bg-ui-gray-medium-dark"
                      } rounded-full overflow-hidden`}
                    >
                      1
                    </div>
                  </div>
                  <div className="flex h-full w-px bg-ui-gray-medium-light mx-auto overflow-hidden"></div>
                </div>

                <div className="w-full flex-grow">
                  {/* <!-- Step 1 Card --> */}

                  <div className="ui-card relative px-4 py-4 mb-6 lg:px-6">
                    <Disclosure as="div">
                      {({ open }) => (
                        <>
                          <Disclosure.Button as="div">
                            <div className="flex justify-between items-start">
                              <h2 className="flex flex-grow font-body font-bold text-xl">
                                Select Policy to apply payment towards
                              </h2>
                              <div className="font-semibold text-sm whitespace-nowrap">
                                Step 1
                              </div>
                            </div>
                          </Disclosure.Button>

                          <div
                            id="ID_paymentStep1Options"
                            className="pt-4 mt-2 lg:pt-6 border-t border-theme-rule-light"
                          >
                            <Disclosure.Panel
                              static
                              className="pb-2 text-sm text-gray-500"
                            >
                              <p className="mb-4 text-sm">
                                This is a description of the first step of
                                billing, where we tell the user they can pick
                                the pending policies or choose any other.
                              </p>

                              <PanelOne
                                panelOneSelection={policy}
                                setPanelOneSelection={setPolicy}
                                dataArray={policies}
                                register={register}
                                setCurrentStep={setCurrentStep}
                                handleSubmit={handleSubmit}
                                dataSubmit={dataSubmit}
                                setValue={setValue}
                              />
                            </Disclosure.Panel>
                          </div>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>

              {/* <!-- Step 2 --> */}
              <div
                id="ID_paymentStep2"
                className="flex flex-row md:ml-8 md:mr-0 lg:ml-20 xl:ml-28 lg:pr-16"
              >
                <div className="w-8 md:flex md:flex-col md:justify-center md:mr-14">
                  <div className="flex justify-center items-center mt-3 mb-4">
                    <div
                      id="ID_paymentStep2Number"
                      className={`inline-flex justify-center items-center h-8 w-8 text-white ${
                        currentStep >= 2
                          ? "bg-ui-blue-dark"
                          : "bg-ui-gray-medium-dark"
                      } rounded-full overflow-hidden`}
                    >
                      2
                    </div>
                  </div>
                  <div className="flex h-full w-px bg-ui-gray-medium-light mx-auto overflow-hidden"></div>
                </div>
                {/* <!-- Card 2 --> */}

                <div className="w-full flex-grow">
                  <div className="ui-card relative px-4 py-4 mb-6 lg:px-6">
                    <Disclosure as="div">
                      {({ open }) => (
                        <>
                          <Disclosure.Button as="div">
                            <div className="flex justify-between items-start">
                              <h2 className="flex flex-grow font-body font-bold text-xl">
                                Select payment amount
                              </h2>
                              <div className="font-semibold text-sm whitespace-nowrap">
                                Step 2
                              </div>
                            </div>
                          </Disclosure.Button>
                          <div
                            id="ID_paymentStep2Options"
                            className="pt-4 mt-2 lg:pt-6 border-t border-theme-rule-light"
                          >
                            {currentStep > 1 && (
                              <Disclosure.Panel
                                className="pb-2 text-sm text-gray-500"
                                static
                              >
                                <p className="mb-4 text-sm">
                                  This is a description of the first step of
                                  billing, where we tell the user they can pick
                                  the pending policies or choose any other.
                                </p>

                                <PanelTwo
                                  panelTwoSelection={payment}
                                  setPanelTwoSelection={setPayment}
                                  dataArray={paymentArray}
                                  selected={policyFilter[0]}
                                  register={register}
                                  trigger={trigger}
                                  setValue={setValue}
                                  errors={errors}
                                  handleSubmit={handleSubmit}
                                  setCurrentStep={setCurrentStep}
                                  control={control}
                                  dataSubmit={dataSubmit}
                                  resetField={resetField}
                                  getValues={getValues}
                                />
                              </Disclosure.Panel>
                            )}
                          </div>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>

              {/* <!-- Step 3 --> */}
              <div
                id="ID_paymentStep3"
                className="flex flex-row md:ml-8 md:mr-0 lg:ml-20 xl:ml-28 lg:pr-16"
              >
                <div className="w-8 md:flex md:flex-col md:justify-center md:mr-14">
                  <div className="flex justify-center items-center mt-3 mb-4">
                    <div
                      id="ID_paymentStep3Number"
                      className={`inline-flex justify-center items-center h-8 w-8 text-white ${
                        currentStep >= 3
                          ? "bg-ui-blue-dark"
                          : "bg-ui-gray-medium-dark"
                      } rounded-full overflow-hidden`}
                    >
                      3
                    </div>
                  </div>
                  <div className="flex h-full w-px bg-ui-gray-medium-light mx-auto overflow-hidden"></div>
                </div>

                {/* Card 3 */}
                <div className="w-full flex-grow">
                  <div className="ui-card relative px-4 py-4 mb-6 lg:px-6">
                    <Disclosure as="div">
                      {({ open }) => (
                        <>
                          <Disclosure.Button as="div">
                            <div className="flex justify-between items-start">
                              <h2 className="flex flex-grow font-body font-bold text-xl">
                                Select payment method
                              </h2>
                              <div className="font-semibold text-sm whitespace-nowrap">
                                Step 3
                              </div>
                            </div>
                          </Disclosure.Button>
                          <div
                            id="ID_paymentStep3Options"
                            className="pt-4 mt-2 lg:pt-6 border-t border-theme-rule-light"
                          >
                            {currentStep > 2 && (
                              <Disclosure.Panel
                                className="pb-2 text-sm text-gray-500"
                                static
                              >
                                <p className="mb-4 text-sm">
                                  This is a description of the first step of
                                  billing, where we tell the user they can pick
                                  the pending policies or choose any other.
                                </p>

                                <PanelThree
                                  panelThreeSelection={date}
                                  setPanelThreeSelection={setDate}
                                  dataArray={cardArray}
                                  handleSubmit={handleSubmit}
                                  dataSubmit={dataSubmit}
                                  modalState={addPaymentModal}
                                  setModalState={setAddPaymentModal}
                                  register={register}
                                  setValue={setValue}
                                  setCurrentStep={setCurrentStep}
                                />
                              </Disclosure.Panel>
                            )}
                          </div>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>
              {/* Step Four */}
              <div
                id="ID_paymentStep3"
                className="flex flex-row md:ml-8 md:mr-0 lg:ml-20 xl:ml-28 lg:pr-16"
              >
                <div className="w-8 md:flex md:flex-col md:justify-center md:mr-14">
                  <div className="flex justify-center items-center mt-3 mb-4">
                    <div
                      id="ID_paymentStep3Number"
                      className={`inline-flex justify-center items-center h-8 w-8 text-white ${
                        currentStep >= 4
                          ? "bg-ui-blue-dark"
                          : "bg-ui-gray-medium-dark"
                      } rounded-full overflow-hidden`}
                    >
                      4
                    </div>
                  </div>
                  <div className="flex h-full w-px bg-transparent mx-auto overflow-hidden"></div>
                </div>

                {/* Card 4 */}
                <div className="w-full flex-grow">
                  <div className="ui-card relative px-4 py-4 mb-6 lg:px-6">
                    <Disclosure as="div">
                      {({ open }) => (
                        <>
                          <Disclosure.Button as="div">
                            <div className="flex justify-between items-start">
                              <h2 className="flex flex-grow font-body font-bold text-xl">
                                Schedule payment date
                              </h2>
                              <div className="font-semibold text-sm whitespace-nowrap">
                                Step 4
                              </div>
                            </div>
                          </Disclosure.Button>
                          <div
                            id="ID_paymentStep3Options"
                            className="pt-4 mt-2 lg:pt-6 border-t border-theme-rule-light"
                          >
                            {currentStep > 3 && (
                              <Disclosure.Panel
                                className="pb-2 text-sm text-gray-500"
                                static
                              >
                                <p className="mb-4 text-sm">
                                  This is a description of the first step of
                                  billing, where we tell the user they can pick
                                  the pending policies or choose any other.
                                </p>

                                <PanelFour
                                  selectedDate={selectedDate}
                                  setSelectedDate={setSelectedDate}
                                  currDate={currDate}
                                  register={register}
                                  setValue={setValue}
                                  setCurrentStep={setCurrentStep}
                                  handleSubmit={handleSubmit}
                                  dataSubmit={dataSubmit}
                                  policyFilter={policyFilter}
                                  control={control}
                                />
                              </Disclosure.Panel>
                            )}
                          </div>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>
              <div className="block text-right mb-8 md:ml-12 md:mr-6 lg:ml-28 lg:pr-16">
                <button
                  className="ui-btn-secondary min-w-[140px]"
                  aria-label="cancel make a payment"
                >
                  Cancel
                </button>
                <button
                  id="ID_submitPayment"
                  className="ui-btn-primary min-w-[140px]"
                  aria-label="submit payment"
                  disabled={currentStep !== 5}
                  onClick={() => setAuthorizeModal(true)}
                >
                  Submit
                </button>
              </div>
              {/* Modal Dialogs */}
              <AuthorizeModal
                modalState={authorizeModal}
                setModalState={setAuthorizeModal}
                setConfirmationModal={setConfirmationModal}
              />

              <ConfirmModal
                modalState={confirmationModal}
                setModalState={setConfirmationModal}
              />

              <AddPaymentMethodModal
                modalState={addPaymentModal}
                setModalState={setAddPaymentModal}
                config={config}
                setConfig={setConfig}
                onSubmit={onSubmit}
              />

              <DeleteAutopayPaymentModal
                modalState={deleteAutopayPaymentModal}
                setModalState={setDeleteAutopayPaymentModal}
                onSubmit={onSubmit}
              />

              <DeleteNonAutopayPaymentModal
                modalState={deleteNonAutopayPaymentModal}
                setModalState={setDeleteNonAutopayPaymentModal}
                onSubmit={onSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MakeAPayment;
