import Tooltip from "../../General/Tooltip";
import { useCollapsePanel } from "../../../hooks/useCollapsePanel";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
export default function OwnerAndInsuredCard({ data }) {
  const uuid = uuidv4();
  useCollapsePanel(uuid);
  return (
    <div className="ui-card mb-4 md:mb-6">
      <div className="md:flex md:flex-row md:items-start">
        <div className="px-4 pt-3 md:flex-grow xl:px-6 xl:pt-6">
          <h2 className="mb-0">
            <div className="flex flex-row md:hidden">
              <button
                id={uuid}
                className="flex items-center group md:hidden"
                aria-controls={`${uuid}-panel`}
                aria-expanded="true"
                aria-label="Expand or collapse Owner &amp; Insured details"
              >
                <span className="inline-block p-1 rounded-full mr-2 text-ui-blue group-hover:text-white group-hover:bg-ui-blue">
                  <svg
                    className="ui-icon ui-plus w-4 h-4"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-plus"></use>
                  </svg>
                  <svg
                    className="ui-icon ui-minus w-4 h-4"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-minus"></use>
                  </svg>
                </span>
                <span className="text-base font-body font-bold md:ui-no-badge-icon-inline">
                  Owner &amp; Insured
                </span>
              </button>
              <Tooltip
                srText="Owner &amp; Insured Help"
                content="The policy owner is the person or organization in 
                whose name an insurance policy is registered. The insured is 
                the one who is covered by the  insurance policy."
              />
            </div>
            <span className="text-xl font-body font-bold hidden md:block">
              Owner &amp; Insured
              <Tooltip
                srText="Owner &amp; Insured Help"
                content="The policy owner is the person or organization in 
                whose name an insurance policy is registered. The insured is 
                the one who is covered by the  insurance policy."
              />
            </span>
          </h2>
        </div>
        <hr className="mb-0 mt-3 md:hidden" />
          <div className="px-4 pt-4 text-sm md:justify-self-end xl:px-6 xl:pt-6">
            {/* Cutover one will not have edit capability */}
            <Link to="/profile">Edit Contact Info</Link> 
          </div> 
      </div>
      <hr className="hidden md:block" />
      <div
        id={`${uuid}-panel`}
        className="px-4 pb-4 overflow-hidden h-20 md:h-auto xl:px-6 xl:pb-6"
      >
        {/* <!-- Owner Details --> */}
        <div className="grid grid-cols-2 gap-4 md:grid-cols-4 xl:gap-6">
          <div>
            <div className="text-xs text-theme-text-tertiary">Owner</div>
            <div className="text-sm text-theme-text-secondary font-semibold">
              {data.owner}
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">Insured</div>
            <div className="text-sm text-theme-text-secondary font-semibold">
              {data.insured}
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">
              Owner Birth date
            </div>
            <div className="text-sm text-theme-text-secondary font-semibold">
              {data.dob}
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">
              Owner Issue Age
            </div>
            <div className="text-sm text-theme-text-secondary font-semibold">
              {data.issueAge}
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">
              Owner Address
            </div>
            <div className="text-sm text-theme-text-secondary font-semibold">
              {data.address.streetNumber} {data.address.streetName}
            </div>
            <div className="text-sm text-theme-text-secondary font-semibold">
              {data.address.city}, {data.address.state} {data.address.zipCode}
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">Owner Phone</div>
            {data.phone.map((phone, idx) => {
              return (
                <div
                  className="text-sm text-theme-text-secondary font-semibold"
                  key={`phone - ${idx}`}
                >
                  <a href={`tel:+1${phone.number}`}>{phone.number}</a>{" "}
                  {phone.type && `(${phone.type})`}
                </div>
              );
            })}
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">Owner Email</div>
            <div className="text-sm text-theme-text-secondary font-semibold">
              <a href={`mailto:${data.email}`}>{data.email}</a>
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">
              Opt in to Alerts?
            </div>
            <div className="text-sm text-theme-text-secondary font-semibold">
              <Link to="/profile">
                {data.notification.join(" & ")}
              </Link>
            </div>
          </div>
        </div>
        {/* <!-- Owner Details --> */}
      </div>
    </div>
  );
}
