import { RadioGroup } from "@headlessui/react";
import { useState, useEffect } from "react";

const PanelTemplate = ({
  panelOneSelection,
  setPanelOneSelection,
  dataArray,
  register,
  setCurrentStep,
  handleSubmit,
  dataSubmit,
  setValue,
}) => {
  const mainPolicy = dataArray[0]
  const [plan, setPlan] = useState('')

  const [buttonOneChecked, setButtonOneChecked] = useState(false)
  const [buttonTwoChecked, setButtonTwoChecked] = useState(false)

  const onButtonOneClick = () => {
    setValue("policy", mainPolicy.policyNumber)
    handleSubmit(dataSubmit)()
  }

  const onButtonTwoClick = () => {
    setValue("policy", panelOneSelection)
    handleSubmit(dataSubmit)()
  }
  useEffect(() => {
    if (buttonOneChecked) {
      onButtonOneClick()
      setCurrentStep(2)
    }
    if (buttonTwoChecked) {
      onButtonTwoClick()
      setCurrentStep(2)
    }
  }, [
    panelOneSelection,
    panelOneSelection,
    buttonOneChecked,
    buttonTwoChecked
  ])

  return (
    <div className="px-0 md:px-16">
      <form>
        <RadioGroup
          className="ui-form-card-row"
          value={plan}
          onChange={setPlan}
        >

          <RadioGroup.Option
            id="ID_paymentStep1Option1"
            className={({ checked }) =>
              `${(checked && buttonOneChecked) ? "ui-form-card selected" : "ui-form-card"}  `
            }
            tabIndex="0"
            aria-label="Select to pay this policy"
            aria-controls="ID_paymentStep2Options"
            aria-expanded="false"
            value="policyOne"
            {...register("policy")}
            onClick={() => {
              setPanelOneSelection(mainPolicy.policyNumber)
              setButtonTwoChecked(false)
              setButtonOneChecked(true)
            }
            }
          >
            {({ checked }) => (
              <>
                <RadioGroup.Label
                  className="block text-xxs text-ui-gray-default pr-8 cursor-pointer font-semibold"
                  as="div"
                >
                  Policy # {mainPolicy.policyNumber}
                </RadioGroup.Label>
                <RadioGroup.Label
                  className="block text-black group-hover:underline"
                  as="div"
                >
                  {mainPolicy.policyTitle}
                </RadioGroup.Label>

                <RadioGroup.Label className="flex items-center text-xxs">
                  <svg
                    className="ui-icon w-4 h-4 text-ui-red mr-2"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-circle-close"></use>
                  </svg>
                  $XX.XX past due
                </RadioGroup.Label>
                <div className="text-xxs font-semibold pr-8 group-hover:underline">
                  <div className="ui-form-card-checkbox">
                    <svg
                      className="ui-icon w-2 h-2 text-white hidden"
                      focusable="false"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <use href="#ui-icon-checkmark"></use>
                    </svg>
                  </div>
                </div>
              </>
            )}
          </RadioGroup.Option>
          
          <RadioGroup.Option
            id="ID_paymentStep1Option1"
            className={({ checked }) =>
              `${(buttonTwoChecked) ? "ui-form-card selected" : "ui-form-card"}  `
            }
            tabIndex="0"
            aria-label="Select to pay this policy"
            aria-controls="ID_paymentStep2Options"
            aria-expanded="false"
          >
            {({ checked }) => (
              <>
                <RadioGroup.Label as="div">
                  <div
                    id="ID_paymentStep1Option2"
                    className="group xl:basis-1/2"
                    tabIndex="0"
                    aria-label="Enable ability to choose another policy"
                    aria-controls="ID_selectPolicy"
                  >
                    <div className="ui-form-card-checkbox">
                      <svg
                        className="ui-icon w-2 h-2 text-white hidden"
                        focusable="false"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <use href="#ui-icon-checkmark"></use>
                      </svg>
                    </div>

                    <div className="text-xxs font-semibold pr-8 group-hover:underline mb-1">
                      Another Policy
                    </div>

                    <select
                      id="ID_selectPolicy"
                      name="policy"
                      className="text-sm"
                      {...register("policy")}
                      value={panelOneSelection}
                      aria-label="Select policy to pay"
                      aria-controls="ID_paymentStep2Options"
                      aria-expanded="false"
                      onClick={e => e.stopPropagation()}
                      onChange={(e) => {
                        setButtonOneChecked(false)
                        setButtonTwoChecked(true)
                        setPanelOneSelection(e.target.value)
                      }}
                    >
                      {dataArray.map((item, idx) => (
                        <option value={item.policyNumber}
                          key={idx}>{item.policyTitle}</option>
                      ))}
                    </select>

                  </div>
                </RadioGroup.Label>
              </>
            )}
          </RadioGroup.Option>
        </RadioGroup>
      </form>
    </div>
  );
};

export default PanelTemplate;
