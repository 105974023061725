import { Dialog } from "@headlessui/react";
import { v4 as uuid } from "uuid";
import { useForm } from "react-hook-form";
import NotificationSelection from "../NotificationSelection";
import DialogBackdrop from "../../ModalDialogs/DialogBackdrop";
export default function UserNotificationDialog({
  dialogState,
  setDialogState,
}) {
  const { register, handleSubmit } = useForm();
  const notificationTypes = ["text", "email", "mail"];
  return (
    <Dialog
      open={dialogState}
      onClose={() => setDialogState(false)}
      className="ui-dialogs"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <DialogBackdrop />
      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel
          role="dialog"
          aria-modal="true"
          className="ui-default-dialog"
        >
          <Dialog.Title className="font-body text-xl font-bold text-left">
            Manage Notification Preferences
          </Dialog.Title>
          <button
            className="ui-default-dialog-close"
            aria-label="close manage notification preferences window"
            onClick={() => setDialogState(false)}
          >
            <svg
              className="ui-icon h-3.5 w-3.5"
              focusable="false"
              aria-hidden="true"
              role="presentation"
            >
              <use href="#ui-icon-close"></use>
            </svg>
          </button>
          <hr />
          <Dialog.Description>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
            tincidunt mi sit amet ipsum tempus interdum. Maecenas non enim
            lectus.
          </Dialog.Description>

          <form
            onSubmit={handleSubmit((formData) => {
              alert(`data -- ${JSON.stringify(formData)}`);
              setDialogState(false);
            })}
          >
            <div className="ui-grid-manage-notification">
              <div>{/* empty cell for grid */}</div>
              <div className="text-sm font-semibold px-4 text-center">Text</div>
              <div className="text-sm font-semibold px-4 text-center">
                Email
              </div>
              <div className="text-sm font-semibold px-4 text-center">Mail</div>
              {/* E-Delivery Confirmation */}
              <fieldset className="contents">
                <legend className="text-base font-semibold py-3">
                  E-Delivery Confirmation
                </legend>
                {notificationTypes.map((type) => {
                  return (
                    <NotificationSelection
                      key={`edelivery-${type}-checkBox`}
                      register={register}
                      mainType="edelivery"
                      subType={type}
                    />
                  );
                })}
              </fieldset>

              <hr className="ui-no-margins col-span-4" />

              {/* Policy Correspondence */}
              <fieldset className="contents">
                <legend className="text-base font-semibold py-3">
                  Policy Correspondence
                </legend>
                {notificationTypes.map((type) => {
                  return (
                    <NotificationSelection
                      key={`policyCorrespondence-${type}-checkBox`}
                      register={register}
                      mainType="policyCorrespondence"
                      subType={type}
                    />
                  );
                })}
              </fieldset>

              <hr className="ui-no-margins col-span-4" />

              {/* Policy Changes */}
              <fieldset className="contents">
                <legend className="text-base font-semibold py-3">
                  Policy Changes
                </legend>
                {notificationTypes.map((type) => {
                  return (
                    <NotificationSelection
                      key={`policyChanges-${type}-checkBox`}
                      register={register}
                      mainType="policyChanges"
                      subType={type}
                    />
                  );
                })}
              </fieldset>

              <hr className="ui-no-margins col-span-4" />

              {/* Courtesy Payment Drafts Reminders */}
              <fieldset className="contents">
                <legend className="text-base font-semibold py-3">
                  Courtesy Payment Drafts Reminders
                </legend>
                {notificationTypes.map((type) => {
                  return (
                    <NotificationSelection
                      key={`courtesyPaymentDraftsReminders-${type}-checkBox`}
                      register={register}
                      mainType="courtesyPaymentDraftsReminders"
                      subType={type}
                    />
                  );
                })}
              </fieldset>

              <hr className="ui-no-margins col-span-4" />

              {/* Service Request Statuses */}
              <fieldset className="contents">
                <legend className="text-base font-semibold py-3">
                  Service Request Statuses
                </legend>
                {notificationTypes.map((type) => {
                  return (
                    <NotificationSelection
                      key={`serviceRequestStatues-${type}-checkBox`}
                      register={register}
                      mainType="serviceRequestStatues"
                      subType={type}
                    />
                  );
                })}
              </fieldset>
              <hr className="ui-no-margins col-span-4" />

              {/* Payment Confirmations */}
              <fieldset className="contents">
                <legend className="text-base font-semibold py-3">
                  Payment Confirmations
                </legend>
                {notificationTypes.map((type) => {
                  return (
                    <NotificationSelection
                      key={`paymentConfirmation-${type}-checkBox`}
                      register={register}
                      mainType="paymentConfirmation"
                      subType={type}
                    />
                  );
                })}
              </fieldset>
              <hr className="ui-no-margins col-span-4" />
            </div>

            {/* <!-- Buttons --> */}
            <div className="block text-right mt-6">
              <button
                type="button"
                className="ui-btn-secondary ui-btn-min-w mt-4"
                onClick={() => setDialogState(false)}
              >
                Cancel
                <span className="sr-only">
                  changes to your security settings
                </span>
              </button>
              <button className="ui-btn-primary ui-btn-min-w mt-4">
                Save
                <span className="sr-only">
                  changes to your security settings
                </span>
              </button>
            </div>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
