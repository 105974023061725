
const CurrentLoansCard = ({ data }) => {
  return (
    <>
      <div className="ui-card p-4 my-4 xl:p-6">
        <div className="text-base font-bold lg:text-lg">
          Load Against {data.title}
        </div>
        <hr className="ui-under-title" />
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 xl:gap-6">
          <div>
            <div className="text-xs text-theme-text-tertiary">Policy number</div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              <div>#{data.number}</div>
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">Loan amount</div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              <div>${data.amount}</div>
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">Loan Type</div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              <div>{data.type}</div>
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">
              Date loan was taken
            </div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              <div>{data.startDate}</div>
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">Interest rate</div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              <div>{data.interestRate}</div>
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">Loan balance</div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              <div>${data.balance}</div>
            </div>
          </div>
        </div>
        <div className="mt-6 text-right">
          <button className="ui-btn-primary">
            Make Payment
            <span className="sr-only"> Load Against {data.title}</span>
          </button>
        </div>
      </div>
    </>
  );
};

export default CurrentLoansCard;