import { html, Grid } from "gridjs";
import { useEffect, useRef } from "react";

export default function ScheduledPaymentsGrid({ data }) {
  const scheduledPaymentsGridWrapper = useRef(null);
  const grid = new Grid({
    columns: [
      {
        name: "Payment amount",
        data: (row) => row.amount,
        formatter: (cell) => {
          return html(`<span>$${cell}</span>`);
        },
      },
      {
        name: "Payment Method",
        data: (row) => row.method,
        formatter: (cell) => {
          return html(`<span>${cell}</span>`);
        },
      },
      {
        name: "Payment posted",
        data: (row) => row.postedDate,
        formatter: (cell) => {
          return html(`<span>${cell}</span>`);
        },
      },
      {
        name: "You can",
        formatter: () => {
          return html(
            `<button class="ui-btn-hyperlink" aria-label="Cancel scheduled payments" onclick="alert('cancelling')">Cancel</button><span class="mx-2">/</span><button class="ui-btn-hyperlink" aria-label="Edit scheduled payments" onclick="alert('editing')">Edit</button>`
          );
        },
      },
    ],
    data: data,
    className: {
      table: "ui-grid-table-sm",
    },
  });
  useEffect(() => {
    grid.render(scheduledPaymentsGridWrapper.current);
  }, []);

  return <div ref={scheduledPaymentsGridWrapper} />;
}
