import { html, Grid } from "gridjs";
import { useEffect, useRef } from "react";

export default function ScheduledPaymentsGrid({ data }) {
  const scheduledPaymentsGridWrapper = useRef(null);
  const grid = new Grid({
    columns: [
      {
        name: "Date added",
        data: (row) => row.dateAdded,
        formatter: (cell) => {
          return html(`<span>${cell}</span>`);
        },
      },
      {
        name: "Type",
        data: (row) => row.type,
        formatter: (cell) => {
          return html(`<span>${cell}</span>`);
        },
      },
      {
        name: "You can",
        formatter: (cell) => {
          return html(
            `<a href="/" class="ui-link-icon text-xs">
              <svg class="ui-icon w-4 h-4" focusable="false" aria-hidden="true" role="presentation">
                <use href="#ui-icon-document-download"></use>
              </svg>
              Download
            </a>`
          );
        },
      },
    ],
    data: data,
    className: {
      table: "ui-grid-table-sm",
    },
  });
  useEffect(() => {
    grid.render(scheduledPaymentsGridWrapper.current);
  }, []);

  return <div ref={scheduledPaymentsGridWrapper} />;
}
