export const reportDetail = {
  id: "1",
  title: "annual Book of Business report",
  records: [
    {
      policyNumber: "57234523",
      policyType: "Whole Life",
      ownerName: "Wendy Ames",
      coverage: "<data point>",
      issueDate: "01/01/2023",
      coverageEndDate: "01/01/2023",
      value: "<data point>",
      status: "problem",
      statusText: "Payment past due",
    },
    {
      policyNumber: "57234523",
      policyType: "Term Life",
      ownerName: "<Owner Name>",
      coverage: "<data point>",
      issueDate: "01/01/2023",
      coverageEndDate: "01/01/2023",
      value: "<data point>",
      status: "risk",
      statusText: "Payment past due",
    },
    {
      policyNumber: "57234523",
      policyType: "Term Life",
      ownerName: "<Owner Name>",
      coverage: "<data point>",
      issueDate: "01/01/2023",
      coverageEndDate: "01/01/2023",
      value: "<data point>",
      status: "good",
      statusText: "Paid",
    },
    {
      policyNumber: "57234523",
      policyType: "Term Life",
      ownerName: "<Owner Name>",
      coverage: "<data point>",
      issueDate: "01/01/2023",
      coverageEndDate: "01/01/2023",
      value: "<data point>",
      status: "",
      statusText: "",
    },
    {
      policyNumber: "57234523",
      policyType: "Whole Life",
      ownerName: "<Owner Name>",
      coverage: "<data point>",
      issueDate: "01/01/2023",
      coverageEndDate: "01/01/2023",
      value: "<data point>",
      status: "",
      statusText: "",
    },
    {
      policyNumber: "57234523",
      policyType: "Whole Life",
      ownerName: "<Owner Name>",
      coverage: "<data point>",
      issueDate: "01/01/2023",
      coverageEndDate: "01/01/2023",
      value: "<data point>",
      status: "",
      statusText: "",
    },
    {
      policyNumber: "57234523",
      policyType: "Whole Life",
      ownerName: "<Owner Name>",
      coverage: "<data point>",
      issueDate: "01/01/2023",
      coverageEndDate: "01/01/2023",
      value: "<data point>",
      status: "",
      statusText: "",
    },
    {
      policyNumber: "57234523",
      policyType: "Whole Life",
      ownerName: "<Owner Name>",
      coverage: "<data point>",
      issueDate: "01/01/2023",
      coverageEndDate: "01/01/2023",
      value: "<data point>",
      status: "",
      statusText: "",
    },
    {
      policyNumber: "57234523",
      policyType: "Whole Life",
      ownerName: "<Owner Name>",
      coverage: "<data point>",
      issueDate: "01/01/2023",
      coverageEndDate: "01/01/2023",
      value: "<data point>",
      status: "",
      statusText: "",
    },
    {
      policyNumber: "57234523",
      policyType: "Whole Life",
      ownerName: "<Owner Name>",
      coverage: "<data point>",
      issueDate: "01/01/2023",
      coverageEndDate: "01/01/2023",
      value: "<data point>",
      status: "",
      statusText: "",
    },
    {
      policyNumber: "57234523",
      policyType: "Whole Life",
      ownerName: "<Owner Name>",
      coverage: "<data point>",
      issueDate: "01/01/2023",
      coverageEndDate: "01/01/2023",
      value: "<data point>",
      status: "",
      statusText: "",
    },
    {
      policyNumber: "57234523",
      policyType: "Whole Life",
      ownerName: "<Owner Name>",
      coverage: "<data point>",
      issueDate: "01/01/2023",
      coverageEndDate: "01/01/2023",
      value: "<data point>",
      status: "",
      statusText: "",
    },
    {
      policyNumber: "57234523",
      policyType: "Whole Life",
      ownerName: "<Owner Name>",
      coverage: "<data point>",
      issueDate: "01/01/2023",
      coverageEndDate: "01/01/2023",
      value: "<data point>",
      status: "",
      statusText: "",
    },
  ],
};
