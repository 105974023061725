// route: /policies
export const helpfulLinks = [  
  {
    name: "Request Changes to a Policy",
    route: "#",
    icon: "ui-icon-clipboard",
  },
  {
    name: "Manage Billing and Payments",
    route: "/payment",
    icon: "ui-icon-app",
  },
  {
    name: "View Beneficiaries",
    route: "/policies/beneficiaries",
    icon: "ui-icon-user-plus",
  },
  {
    name: "File a Claim",
    route: "/services-claims/claims/file-a-claim",
    icon: "ui-icon-edit",
  },
  {
    name: " Get policy documents",
    route: "#",
    icon: "ui-icon-document",
  },
  {
    name: "View Alerts",
    route: "/messages",
    icon: "ui-icon-message",
  },
  {
    name: "View Service and Claim History",
    route: "/services-claims",
    icon: "ui-icon-clock",
  }
];
