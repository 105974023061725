const HelpfulLinks = ({ links, config }) => {
  return (
    <>
      <div className="ui-card mb-4 md:mb-6">
        <div className="px-4 pt-4 pb-1 border-b border-theme-rule-light">
          <h2 className="text-sm font-body font-semibold">Things You Can Do</h2>
        </div>
        <div className="p-4">
          <div className="grid gap-4 grid-flow-dense sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-1">
            {config === "single" &&
              links.map((item, idx) => (
                <div key={`helpful links - ${idx}`}>
                  <div className="font-semibold mb-2">{item.heading}</div>
                  <ul className="ml-2 text-sm">
                    {item.links.map((link, index) => (
                      <li key={index} className="mb-2">
                        <a href="/">{link.name}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
          </div>
          {config === "all" &&
            links.map((item, idx) => (
              <ul className="text-sm" key={`helpful links - ${idx}`}>
                <li className="mb-2">
                  <a href="/">{item.name}</a>
                </li>
              </ul>
            ))}
        </div>
      </div>
    </>
  );
};

export default HelpfulLinks;
