import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { v4 as uuid } from "uuid";
import { useForm } from "react-hook-form";
import FormInputError from "../General/FormInputError";
import DialogBackdrop from "./DialogBackdrop";

const AuthorizeModal = ({
  modalState,
  setModalState,
  unauthUser,
  submitParentData,
  submitFuction, setConfirmationModal
}) => {
  const UUID = uuid();

  const {
    register: authorizeModalRegister,
    handleSubmit: handleAuthorizeModalSubmit,
    formState: { errors },
  } = useForm();

  const handleSubmit = () => {
    if (submitParentData) submitFuction();
    setModalState(false);
    setConfirmationModal(true);
  };

  return (
    <Transition appear show={modalState} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        open={modalState}
        onClose={() => {
          setModalState(false);
        }}
      >
        <DialogBackdrop />

        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-5xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title
                as="h2"
                className="dialog_label2 font-body text-xl font-bold text-left"
              >
                Authorize payment
                <button
                  className="ui-default-dialog-close"
                  onClick={() =>
                    setModalState(false)
                  }
                >
                  <svg
                    className="ui-icon h-3.5 w-3.5"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-close"></use>
                  </svg>
                </button>
              </Dialog.Title>
              <hr />
              <form onSubmit={handleAuthorizeModalSubmit(handleSubmit)}>
                <div className="mx-auto lg:w-4/5">
                  <p id="ID_dialogAuthorizePaymentDesc">
                    By signing below I authorize this payment to be
                    processed and remitted to{" "}
                    <strong>&lt;name of company&gt;</strong> through my{" "}
                    <strong>&lt;selected payment method&gt;</strong> as
                    referenced herein. I understand that by clicking the
                    “Submit” button below that I am submitting the payment
                    request and providing my electronic signature.
                  </p>
                  <div className="mt-6">
                    <label className="ui-checkbox">
                      <input
                        type="checkbox"
                        {...authorizeModalRegister("confirmCheck", {
                          required:
                            "Please check confirmation box before continuing",
                        })}
                      />
                      <div className="ui-checkmark">
                        <svg
                          className="ui-icon w-2 h-2 text-white"
                          focusable="false"
                          aria-hidden="true"
                          role="presentation"
                        >
                          <use href="#ui-icon-checkmark"></use>
                        </svg>
                      </div>
                      <span>
                        By signing below, I authorize this payment request
                        on MM/DD/YYYY.
                      </span>
                    </label>
                    {errors.confirmCheck?.message && (
                      <FormInputError
                        errorMessage={errors.confirmCheck.message}
                      />
                    )}
                  </div>

                  {unauthUser && (
                    <>
                      <div className="ui-form-card-no-event mt-4">
                        <label
                          id={UUID + "-name-label"}
                          htmlFor={`${UUID}-name`}
                          className="block mb-1"
                        >
                          Enter name *
                        </label>
                        <input
                          id={`${UUID}-name`}
                          aria-labelledby={UUID + "-name-label"}
                          aria-describedby={`${UUID + "-name-help"} ${UUID + "-name-error"
                            }`}
                          type="text"
                          placeholder="Enter name"
                          {...authorizeModalRegister("name", {
                            required:
                              "Please enter name before continuing.",
                          })}
                        />
                        {errors.name?.message && (
                          <FormInputError
                            errorMessage={errors.name.message}
                          />
                        )}
                      </div>

                      <div className="ui-form-card-no-event mt-4">
                        <label
                          id={UUID + "-confirm-label"}
                          htmlFor={`${UUID}-confirm`}
                          className="block mb-1"
                        >
                          Type name to confirm *
                        </label>
                        <input
                          id={`${UUID}-confirm`}
                          aria-labelledby={UUID + "-confirm-label"}
                          aria-describedby={`${UUID + "-confirm-help"} ${UUID + "-confirm-error"
                            }`}
                          type="text"
                          placeholder="Enter name again to confirm"
                          {...authorizeModalRegister("confirmName", {
                            required:
                              "Please confirm name before continuing.",
                          })}
                        />
                        {errors.confirmName?.message && (
                          <FormInputError
                            errorMessage={errors.confirmName.message}
                          />
                        )}
                      </div>
                    </>
                  )}
                  <div className="block text-right mt-6">
                    <button
                      type="button"
                      className="ui-btn-secondary min-w-[140px] mt-4"
                      aria-label="Cancel authorizing this payment"
                      onClick={() =>
                        setModalState(false)
                      }
                    >
                      Cancel
                    </button>
                    <button
                      id="ID_submitAuthorizePayment"
                      className="ui-btn-primary min-w-[140px] mt-4"
                      aria-label="authorize this payment"
                    >
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AuthorizeModal;
