import { Dialog } from "@headlessui/react";
import { RadioGroup } from "@headlessui/react";
import { useState } from "react";

import DialogBackdrop from "../../ModalDialogs/DialogBackdrop";

export default function FilterDialog({ dialogState, setDialogState }) {
  const [status, setStatus] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState([]);
  const [policyType, setPolicyType] = useState([]);


  const [isChecked, setIsChecked] = useState(true);
  const handleOnChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <Dialog
      open={dialogState}
      onClose={() => setDialogState(false)}
      className="ui-dialogs"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <DialogBackdrop />
      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel
          role="dialog"
          aria-modal="true"
          className="ui-default-dialog"
        >
          <Dialog.Title className="font-body text-xl font-bold text-left">
            Filter sales training
          </Dialog.Title>
          <button
            className="ui-default-dialog-close"
            aria-label="close manage notification preferences window"
            onClick={() => setDialogState(false)}
          >
            <svg
              className="ui-icon h-3.5 w-3.5"
              focusable="false"
              aria-hidden="true"
              role="presentation"
            >
              <use href="#ui-icon-close"></use>
            </svg>
          </button>
          <hr />
          <Dialog.Description>
            Check or uncheck options below to filter training on Sales &
            Training page.
          </Dialog.Description>
          <form>
            <fieldset>
              <legend>Show training in the following status</legend>
              <label className="mt-1 ui-checkbox text-sm font-normal">
                <input
                  type="checkbox"
                  value="pending"
                  defaultChecked={isChecked}
                  onChange={handleOnChange}
                  // onClick={() => setSavePayment(!savePayemnt)}
                />
                <div className="ui-checkmark">
                  <svg
                    className="ui-icon w-2 h-2 text-white"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-checkmark"></use>
                  </svg>
                </div>
                <span>Overdue</span>
              </label>
              <label className="mt-1 ui-checkbox text-sm font-normal">
                <input
                  type="checkbox"
                  value="incomplete"
                  defaultChecked={isChecked}
                  onChange={handleOnChange}
                  // onClick={() => setSavePayment(!savePayemnt)}
                />
                <div className="ui-checkmark">
                  <svg
                    className="ui-icon w-2 h-2 text-white"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-checkmark"></use>
                  </svg>
                </div>
                <span>Incomplete</span>
              </label>
              <label className="mt-1 ui-checkbox text-sm font-normal">
                <input
                  type="checkbox"
                  value="conditionally-issued"
                  defaultChecked={isChecked}
                  onChange={handleOnChange}
                  // onClick={() => setSavePayment(!savePayemnt)}
                />
                <div className="ui-checkmark">
                  <svg
                    className="ui-icon w-2 h-2 text-white"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-checkmark"></use>
                  </svg>
                </div>
                <span>New product</span>
              </label>
              <label className="mt-1 ui-checkbox text-sm font-normal">
                <input
                  type="checkbox"
                  value="in-force"
                  defaultChecked={isChecked}
                  onChange={handleOnChange}
                  // onClick={() => setSavePayment(!savePayemnt)}
                />
                <div className="ui-checkmark">
                  <svg
                    className="ui-icon w-2 h-2 text-white"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-checkmark"></use>
                  </svg>
                </div>
                <span>Required</span>
              </label>
              <label className="mt-1 ui-checkbox text-sm font-normal">
                <input
                  type="checkbox"
                  value="withdrawn"
                  defaultChecked={isChecked}
                  onChange={handleOnChange}
                  // onClick={() => setSavePayment(!savePayemnt)}
                />
                <div className="ui-checkmark">
                  <svg
                    className="ui-icon w-2 h-2 text-white"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-checkmark"></use>
                  </svg>
                </div>
                <span>Recommended</span>
              </label>
            </fieldset>
            <hr />
            <fieldset>
              <legend>Show training type</legend>
              <label className="mt-1 ui-checkbox text-sm font-normal">
                <input
                  type="checkbox"
                  value="past-due"
                  defaultChecked={isChecked}
                  onChange={handleOnChange}
                  // onClick={() => setSavePayment(!savePayemnt)}
                />
                <div className="ui-checkmark">
                  <svg
                    className="ui-icon w-2 h-2 text-white"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-checkmark"></use>
                  </svg>
                </div>
                <span>Training type</span>
              </label>
              <label className="mt-1 ui-checkbox text-sm font-normal">
                <input
                  type="checkbox"
                  value="declined"
                  defaultChecked={isChecked}
                  onChange={handleOnChange}
                  // onClick={() => setSavePayment(!savePayemnt)}
                />
                <div className="ui-checkmark">
                  <svg
                    className="ui-icon w-2 h-2 text-white"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-checkmark"></use>
                  </svg>
                </div>
                <span>Training type</span>
              </label>
              <label className="mt-1 ui-checkbox text-sm font-normal">
                <input
                  type="checkbox"
                  value="expired"
                  defaultChecked={isChecked}
                  onChange={handleOnChange}
                  // onClick={() => setSavePayment(!savePayemnt)}
                />
                <div className="ui-checkmark">
                  <svg
                    className="ui-icon w-2 h-2 text-white"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-checkmark"></use>
                  </svg>
                </div>
                <span>Training type</span>
              </label>
            </fieldset>
            <div className="block text-right">
              <button className="ui-btn-secondary ui-btn-min-w mt-4">
                Cancel
                <span className="sr-only"> filtering training</span>
              </button>
              <button className="ui-btn-primary ui-btn-min-w mt-4">
                Apply<span className="sr-only"> filter to training</span>
              </button>
            </div>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
