import ServicesAndClaimsCard from "../components/ServicesAndClaims/ServicesAndClaimsCard";
export default function ServicesAnnuity() {
  return (
    <>
      {/* SPRINT-3-CUSTOMER-claims-and-forms.pdf (pg 7) */}
      <div className="container mb-8">
        <h1>Annuity Services</h1>
        <p>
          Lorem ipsum dolor sit amet, eam ad autem fabulas. Expetenda dissentiet
        </p>
        <hr />
      </div>

      <div className="container ui-component-spacing">
        <div className="grid grid-cols-1 auto-rows-fr gap-4 mt-4 md:grid-cols-2 lg:grid-cols-3 lg:gap-6 md:mt-6">
          <ServicesAndClaimsCard
            data={{
              title: "Annuity changes",
              desc: "Blurb about this grouping of services goes here. This tile is flexible to accommodate any functions a client wants to enable for self service.",
              subTitle: "You can",
              links: [
                {
                  title: "Make investment changes",
                  icon: "#ui-icon-convert",
                  url: "/",
                },
                {
                  title: "Surrender a policy",
                  icon: "#ui-icon-cancel",
                  url: "/",
                },
                {
                  title: "Cancel a policy",
                  icon: "#ui-icon-cancel",
                  url: "/",
                },
                {
                  title: "Additional payment functions can be added",
                  icon: "#ui-icon-edit-settings",
                  url: "/",
                },
              ],
            }}
          />
          {/* 2 */}
          <ServicesAndClaimsCard
            data={{
              title: "Deposits & Withdrawals",
              desc: "Blurb about this grouping of services goes here. This tile is flexible to accommodate any functions a client wants to enable for self service.",
              subTitle: "You can",
              links: [
                {
                  title: "Make a deposit",
                  icon: "#ui-icon-money",
                  url: "/",
                },
                {
                  title: "Make a withdrawal",
                  icon: "#ui-icon-money",
                  url: "/",
                },
                {
                  title: "Additional payment functions can be added",
                  icon: "#ui-icon-edit-settings",
                  url: "/",
                },
              ],
            }}
          />
          {/* 3  */}
          <ServicesAndClaimsCard
            data={{
              title: "Beneficiaries",
              desc: "Blurb about this grouping of services goes here. This tile is flexible to accommodate any functions a client wants to enable for self service.",
              subTitle: "You can",
              links: [
                {
                  title: "Add, edit or remove beneficiaries",
                  icon: "#ui-icon-user-plus",
                  url: "/",
                },
                {
                  title: "Change the percentage split between beneficiaries",
                  icon: "#ui-icon-percentage",
                  url: "/",
                },
                {
                  title: "Additional payment functions can be added",
                  icon: "#ui-icon-edit-settings",
                  url: "/",
                },
              ],
            }}
          />
          {/* 4 */}
          <ServicesAndClaimsCard
            data={{
              title: "Loans",
              desc: "Blurb about flexible and comprehensive self service options for payments goes here. This tile is flexible to accommodate any payment functions a client wants to enable for self service.",
              subTitle: "You can",
              links: [
                {
                  title: "Apply for a loan against policy value(s)",
                  icon: "#ui-icon-apply",
                  url: "/",
                },
                {
                  title: "Make payments on loans",
                  icon: "#ui-icon-payment",
                  url: "/",
                },
                {
                  title: "Additional self service functions can be added",
                  icon: "#ui-icon-edit-settings",
                  url: "/",
                },
              ],
            }}
          />
          {/* 5 */}
          <ServicesAndClaimsCard
            data={{
              title: "Title for grouping of life insurance services",
              desc: "Blurb about flexible and comprehensive self service options for payments goes here. This tile is flexible to accommodate any payment functions a client wants to enable for self service.",
              subTitle: "You can",
              links: [
                {
                  title: "Self service functions are listed here",
                  icon: "#ui-icon-edit-settings",
                  url: "/",
                },
                {
                  title: "Another self service function",
                  icon: "#ui-icon-edit-settings",
                  url: "/",
                },
                {
                  title: "Additional self service functions can be added",
                  icon: "#ui-icon-edit-settings",
                  url: "/",
                },
              ],
            }}
          />
        </div>
      </div>
    </>
  );
}
