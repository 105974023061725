export const singlePolicyViewLinks = [
  {
    heading: "Manage payments",
    links: [
      {
        id: 1,
        name: "Make a payment",
        route: "/",
      },
      {
        id: 2,
        name: "View billing statements",
        route: "/",
      },
      {
        id: 3,
        name: "View scheduled payments",
        route: "/",
      },
      {
        id: 4,
        name: "View payment history",
        route: "/",
      },
    ],
  },

  {
    heading: "Manage service requests and claims",
    links: [
      {
        id: 1,
        name: "Remind policyholder to review beneficiaries",
        route: "/",
      },
      {
        id: 2,
        name: "Share info packet with beneficiaries",
        route: "/",
      },
      {
        id: 3,
        name: "Update policy owner's contact information",
        route: "/",
      },
      {
        id: 4,
        name: "File a claim",
        route: "/",
      },
    ],
  },
  {
    heading: "Get policy documents",
    links: [
      {
        id: 1,
        name: "Get policy pages",
        route: "/",
      },
      {
        id: 2,
        name: "Request proof of insurance",
        route: "/",
      },
    ],
  },
  {
    heading: "Title",
    links: [
      {
        id: 1,
        name: "Run illustrations",
        route: "/",
      },
    ],
  },
  {
    heading: "Need product help?",
    links: [
      {
        id: 1,
        name: "More about this type of policy",
        route: "/",
      },
      {
        id: 2,
        name: "More about closed block products",
        route: "/",
      },
      {
        id: 3,
        name: "Alternate open block products",
        route: "/",
      },
    ],
  },
];
