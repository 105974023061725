import AgentInfoCard from "./PolicyCards/AgentInfoCard";
import BeneficiariesCard from "./PolicyCards/BeneficiariesCard";
import CoverageAndDocuments from "./PolicyCards/CoverageAndDocuments";
import FinancialsCard from "./PolicyCards/FinancialsCard";
import OwnerAndInsuredCard from "./PolicyCards/OwnerAndInsuredCard";
import PaymentSummaryCard from "./PolicyCards/PaymentSummaryCard";
import AgentSendReminderModal from "../ModalDialogs/AgentSendReminderModal";
import { useState } from "react";

const SinglePolicyView = ({ policy, policyDetail }) => {
  const [isOpenSendReminder, setIsOpenSendReminder] = useState(false);

  return (
    <>
      <div className="grid grid-cols-1 gap-4 xl:gap-6">
        {/* Coverage and Documents Card */}
        <CoverageAndDocuments
          policy={policy}
          policyDetail={policyDetail}
          isOpenSendReminder={isOpenSendReminder}
          setIsOpenSendReminder={setIsOpenSendReminder}
        />
        {/* Financials Card */}
        <FinancialsCard policy={policy} policyDetail={policyDetail} />
        {/* Owner and Insured Card */}
        <OwnerAndInsuredCard
          policy={policy}
          policyDetail={policyDetail}
          isOpenSendReminder={isOpenSendReminder}
          setIsOpenSendReminder={setIsOpenSendReminder}
        />
        {/* Beneficiaries card */}
        <BeneficiariesCard
          policy={policy}
          policyDetail={policyDetail}
          isOpenSendReminder={isOpenSendReminder}
          setIsOpenSendReminder={setIsOpenSendReminder}
        />
        {/* Payment summary card */}
        <PaymentSummaryCard
          policyDetail={policyDetail}
          isOpenSendReminder={isOpenSendReminder}
          setIsOpenSendReminder={setIsOpenSendReminder}
        />
        {/* Agent info */}
        <AgentInfoCard policy={policy} policyDetail={policyDetail} />
      </div>

      {/* Send customer a reminder Dialog */}
      <AgentSendReminderModal
        isOpenSendReminder={isOpenSendReminder}
        setIsOpenSendReminder={setIsOpenSendReminder}
      />
    </>
  );
};

export default SinglePolicyView;
