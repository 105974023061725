import { useState, useEffect } from "react";
import { Routes, Route, useNavigate, useLocation } from "react-router-dom";

import SVGSprite from "./components/General/SvgSprite";
import Header from "./components/AgentPortal/General/Header";
import UnauthHeader from "./components/UnauthorizedUser/UnauthHeader";
import AgentBreadcrumb from "./components/AgentPortal/General/AgentBreadcrumb";
// import ChatBubble from "./components/General/ChatBubble";
import Footer from "./components/General/Footer";

import Landing from "./routes/AgentPortal/landing";
import Register from "./routes/register";
import ForgotPasswordContainer from "./routes/forgot-password";
import ForgotUsernameContainer from "./routes/forgot-username";

import Policies from "./routes/AgentPortal/policies";
import Dashboard from "./routes/AgentPortal/dashboard";
import BookOfBusiness from "./routes/AgentPortal/book-of-business";
import PolicyLookUp from "./routes/AgentPortal/policyLookUp";
import PolicyDetails from "./routes/AgentPortal/policy-details";

import AgentReports from "./routes/AgentPortal/reports";
import AgentReportDetail from "./routes/AgentPortal/reportDetail";

import ServicesClaims from "./routes/AgentPortal/services-claims";

import Commissions from "./routes/AgentPortal/commissions";

import SalesMaterials from "./routes/AgentPortal/salesMaterials";

import Messages from "./routes/AgentPortal/messages";

import Help from "./routes/AgentPortal/help";
import HelpFaqs from "./routes/help-faqs";
import HelpExplore from "./routes/help-explore";
import SearchResults from "./routes/search-results";
import HelpArticle from "./routes/help-article";

import Profile from "./routes/AgentPortal/profile";

export default function App() {
  const [authenticated, setAuthenicated] = useState(
    window.localStorage.getItem("authenticated") === "true" ? true : false
  );
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    if (
      !authenticated &&
      !["/", "/forgot-password", "/forgot-username", "/register"].includes(
        location.pathname
      ) &&
      location.pathname.split("/")[1] !== "guest"
    ) {
      navigate("/", { replace: true });
    }
  }, [location.pathname]);

  return (
    <div className="font-body text-theme-text-primary">
      <SVGSprite />

      <div className="flex flex-col min-h-screen">
        {authenticated ? (
          <Header auth={authenticated} setAuthenicated={setAuthenicated} />
        ) : (
          <UnauthHeader
            auth={authenticated}
            setAuthenicated={setAuthenicated}
          />
        )}
        <main id="mainContent" className="flex flex-col flex-grow">
          <div className="border border-transparent">
            <AgentBreadcrumb />
            {/* <ChatBubble /> */}

            <Routes>
              <Route
                path="/"
                element={<Landing setAuthenicated={setAuthenicated} />}
              />
              <Route path="/register" element={<Register />} />
              <Route
                path="/forgot-password"
                element={<ForgotPasswordContainer />}
              />
              <Route
                path="/forgot-username"
                element={<ForgotUsernameContainer />}
              />

              {/* agent profile routes */}

              {/* policy routes */}
              <Route path="/dashboard" element={<Dashboard />} />

              <Route path="/policies" element={<Policies />} />

              <Route path="/policies/:policy_id" element={<PolicyDetails />} />

              <Route path="/policyLookup" element={<PolicyLookUp />} />

              {/* Agent Book of business */}
              <Route path="/book-of-business" element={<BookOfBusiness />} />

              {/* Agent Policy breakdown */}
              <Route path="/policy-detail" element={<PolicyDetails />} />

              {/* service and claim routes */}
              <Route path="/services-claims" element={<ServicesClaims />} />

              {/* message routes */}
              <Route path="/messages" element={<Messages />} />

              {/* Agent reports */}
              <Route path="/reports" element={<AgentReports />} />
              <Route
                path="/reports/:report_id"
                element={<AgentReportDetail />}
              />

              {/* Agent sales materials */}
              <Route path="/salesmaterials" element={<SalesMaterials />} />

              {/* Agent commissions */}
              <Route path="/commissions" element={<Commissions />} />

              {/* help routes */}
              <Route path="/help" element={<Help />} />
              <Route path="/help/faqs" element={<HelpFaqs />} />
              <Route path="/help/explore" element={<HelpExplore />} />
              <Route path="/help/search-results" element={<SearchResults />} />
              <Route
                path="/help/article/:article_id"
                element={<HelpArticle />}
              />

              {/* Agent profile */}
              <Route path="/profile" element={<Profile />} />
            </Routes>
          </div>
        </main>
        <Footer />
      </div>
    </div>
  );
}
