import { Link } from "react-router-dom";
import { useCollapsePanel } from "../../../hooks/useCollapsePanel";
import { v4 as uuidv4 } from "uuid";
export default function PaymentSummaryCard({ data, policy_id }) {
  const uuid = uuidv4();
  useCollapsePanel(uuid);
  return (
    <div className="ui-card mb-4 md:mb-6">
      <div className="md:flex md:flex-row md:items-start">
        <div className="px-4 pt-3 md:flex-grow xl:px-6 xl:pt-6">
          <h2 className="mb-0">
            <button
              id={uuid}
              className="flex items-center group md:hidden"
              aria-controls={`${uuid}-panel`}
              aria-expanded="true"
              aria-label="Expand or collapse Payment Summary details"
            >
              <span className="inline-block p-1 rounded-full mr-2 text-ui-blue group-hover:text-white group-hover:bg-ui-blue">
                <svg
                  className="ui-icon ui-plus w-4 h-4"
                  focusable="false"
                  aria-hidden="true"
                  role="presentation"
                >
                  <use href="#ui-icon-plus"></use>
                </svg>
                <svg
                  className="ui-icon ui-minus w-4 h-4"
                  focusable="false"
                  aria-hidden="true"
                  role="presentation"
                >
                  <use href="#ui-icon-minus"></use>
                </svg>
              </span>
              <span className="text-base font-body font-bold md:ui-no-badge-icon-inline">
                Payment Summary
              </span>
            </button>
            <span className="text-xl font-body font-bold hidden md:block">
              Payment Summary
            </span>
          </h2>
        </div>
        <hr className="mb-0 mt-3 md:hidden" />
        <div className="px-4 text-sm md:pt-4 md:justify-self-end xl:px-6 xl:pt-6">
          <Link to="/payment">View Policy Payment Details</Link>
        </div>
      </div>
      <hr className="hidden md:block" />
      <div
        id={`${uuid}-panel`}
        className="px-4 pb-4 overflow-hidden h-20 md:h-auto xl:px-6 xl:pb-6"
      >
        {/* <!-- Payment Summary Details --> */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4 md:mt-0 xl:gap-6 ">
          <div>
            <div className="text-xs text-theme-text-tertiary">
              Payment Status
            </div>
            <div className="text-sm text-theme-text-secondary font-semibold">
              {data.paymentStatus}
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">
              Payment Due Date
            </div>
            <div className="text-sm text-theme-text-secondary font-semibold">
              {data.dueDate}
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">
              Payment Frequency
            </div>
            <div className="text-sm text-theme-text-secondary font-semibold">
              {data.frequency}
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">
              Payment Amount
            </div>
            <div className="text-sm text-theme-text-secondary font-semibold">
              ${data.amount}
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">
              Payment Method
            </div>
            <div className="text-sm text-theme-text-secondary font-semibold">
              {data.method}
            </div>
          </div>
        </div>
        {/* <!-- Payment Summary Details --> */}

        <div className="text-right mt-6">
          <Link to={`/payment/autopay/${policy_id}`} className="mr-2">
            <button className="ui-btn-secondary">Sign Up For Autopay</button>
          </Link>
          <Link to="/payment">
            <button className="ui-btn-primary">Make a Payment</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
