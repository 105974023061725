
import { Link } from "react-router-dom";
export default function HelpfulLinks({ links }) {
  return (
    <div className="ui-component-spacing ui-card p-4 xl:p-6">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1">
        {links.map((link, index) => {
          return (
            <div className="mb-4" key={`links - ${index}`}>
              <Link to={link.route} className="ui-link-icon">
                <svg
                  className="ui-icon w-4 h-4"
                  focusable="false"
                  aria-hidden="true"
                  role="presentation"
                >
                  <use href={`#${link.icon}`}></use>
                </svg>
                {link.name}
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}
