import { policies, services, claims } from "../js/placeholderData/policiesData";
import { carouselData } from "../js/placeholderData/carouselData";
import { helpfulLinks } from "../js/placeholderData/helpfulLinks";

import { useState } from "react";
import { Link } from "react-router-dom";
import { Tab } from "@headlessui/react";
import { usePagination } from "../hooks/usePagination";

import Carousel from "../components/General/Carousel";
import HelpfulLinks from "../components/HelpfulLinks";
import PolicyCard from "../components/PolicyCard";
import Pagination from "../components/General/Pagination";
import SimpleDataRow from "../components/General/SimpleDataRow";
import StatusDot from "../components/General/StatusDot";
import AnnuityPolicyCard from "../components/AnnuityPolicyCard";

export default function Policies() {
  const [policiesDisplay, setPoliciesDisplay] = useState("card");

  const { current, paginationProps } = usePagination(policies, 3);

  return (
    <>
      <div className="container mb-8">
        <h1>Welcome, George</h1>
        <p>
          <StatusDot status="problem" inlineStyle={true} />
          You have <Link to="/messages">1 new message</Link> that needs your
          attention.
        </p>
        <hr />
      </div>
      <div className="container ui-component-spacing">
        <div className="ui-card p-4 elative xl:p-6 r">
          <Carousel data={carouselData} />
        </div>
      </div>
      <div className="container ui-component-spacing">
        <div className="ui-policy-grid">
          <div className="flex flex-row items-center justify-between p-4 md:p-0 lg:block">
            <h2 className="text-xl md:text-3xl">My Policies</h2>
            <div className="hidden md:flex md:justify-between md:items-start">
              <p className="hidden text-sm mr-6 lg:block">
                Any values displayed are current as of <strong>xx/xx/xx</strong>{" "}
                (current date displays). Please refer to your policy documents
                for an explanation of these values along with applicable terms
                and conditions.
              </p>
              <div className="inline-flex items-center">
                <div className="text-sm font-light mr-2">View</div>
                <button
                  className={`${
                    policiesDisplay === "card"
                      ? "ui-btn-layout-current"
                      : "ui-btn-layout"
                  } rounded-l`}
                  aria-label="View policies as cards"
                  onClick={() => setPoliciesDisplay("card")}
                >
                  <svg
                    className="ui-icon w-4 h-4"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-layout-cards"></use>
                  </svg>
                </button>
                <button
                  className={`${
                    policiesDisplay === "table"
                      ? "ui-btn-layout-current"
                      : "ui-btn-layout"
                  } rounded-r`}
                  aria-label="View policies in table"
                  onClick={() => setPoliciesDisplay("table")}
                >
                  <svg
                    className="ui-icon w-4 h-4"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-layout-table"></use>
                  </svg>
                </button>
              </div>
            </div>
          </div>

          <hr className="hidden md:block" />

          <div
            className={`${
              policiesDisplay === "card" ? "ui-grid-cards" : "ui-grid-table"
            }`}
          >
            {current.map((policy, index) => {
              switch(policy.policyType){
                case 'Annuity': 
                  return <AnnuityPolicyCard data={policy} key={`policy - ${index}`} />;       
              default:
                  return <PolicyCard data={policy} key={`policy - ${index}`} />;
              }              
            })}
          </div>

          {/* <!-- paging component --> */}
          <div className="flex justify-between items-start mt-6">
            <div>
              <p className="hidden text-sm md:block lg:hidden mr-6">
                Any values displayed are current as of <strong>xx/xx/xx</strong>{" "}
                (current date displays). Please refer to your policy documents
                for an explanation of these values along with applicable terms
                and conditions.
              </p>
            </div>

            <Pagination {...paginationProps} />
          </div>
          {/* <!-- paging component --> */}
        </div>
        <p className="block mt-6 text-sm md:hidden">
          Any values displayed are current as of
          <strong>xx/xx/xx</strong> (current date displays). Please refer to
          your policy documents for an explanation of these values along with
          applicable terms and conditions.
        </p>
      </div>
      <div className="container">
        <div className="flex flex-col lg:grid lg:gap-x-4 lg:grid-cols-3">
          {/* <!-- Things You Can Do --> */}
          <div>
            <>
              <h2>Things You Can Do</h2>
              <p className="text-sm">Find your way around your account</p>
              <hr />
            </>
            <HelpfulLinks links={helpfulLinks} />
          </div>
          {/* <!-- Things You Can Do --> */}

          {/* <!-- My Requests & Claims --> */}
          <div className="lg:col-span-2">
            <div
              className="lg:col-span-2"
              role="tablist"
              aria-label="My Requests &amp; Claims"
            >
              <h2>My Requests &amp; Claims</h2>
              <p className="text-sm">
                See the status of your open service requests and claims
              </p>
              <hr />
            </div>

            <div className="hidden md:grid grid-cols-1 md:grid-cols-2 gap-x-4">
              <div className="ui-card ui-component-spacing md:flex md:flex-col md:justify-between">
                <div className="font-semibold p-4 pb-2 hidden md:block">
                  My Service Requests
                </div>
                {services.map((service, idx) => (
                  <SimpleDataRow
                    type="customerServicesAndClaims"
                    key={`Customer service - ${idx}`}
                    data={service}
                  />
                ))}
                <button className="ui-card-btn-more">View More</button>
              </div>

              <div className="ui-card ui-component-spacing md:flex md:flex-col md:justify-between">
                <div className="font-semibold p-4 pb-2 hidden md:block">
                  My Claims
                </div>
                {claims.map((claim, idx) => (
                  <SimpleDataRow
                    type="customerServicesAndClaims"
                    key={`Customer claim - ${idx}`}
                    data={claim}
                  />
                ))}
                <button className="ui-card-btn-more">View More</button>
              </div>
            </div>
          </div>

          <Tab.Group className="md:hidden" as="div">
            <div className="ui-card flex-grow px-2 p-1 mb-4">
              <Tab.List>
                <Tab className="text-sm mx-3 pt-3 pb-2 mb-1">
                  My service Requests
                </Tab>
                <Tab className="text-sm mx-3 pt-3 pb-2 mb-1">My Claims</Tab>
              </Tab.List>
            </div>
            <Tab.Panels>
              <Tab.Panel>
                <div className="ui-card ui-component-spacing">
                  {services.map((service, idx) => (
                    <SimpleDataRow
                      type="customerServicesAndClaims"
                      key={`Customer service - ${idx}`}
                      data={service}
                    />
                  ))}
                  <button className="ui-card-btn-more">View More</button>
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <div className="ui-card ui-component-spacing">
                  {claims.map((claim, idx) => (
                    <SimpleDataRow
                      type="customerServicesAndClaims"
                      key={`Customer claim - ${idx}`}
                      data={claim}
                    />
                  ))}
                  <button className="ui-card-btn-more">View More</button>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
          {/* <!-- My Requests & Claims --> */}
        </div>
      </div>
    </>
  );
}
