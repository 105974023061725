import { Link } from "react-router-dom";
import Badge from "./General/Badge";
export default function PolicySummaryCard({ data }) {
  return (
    <div className="ui-card relative overflow-hidden">
      {data.banner && (
        <div className={`ui-badge-bar ui-badge-bar-${data.banner}`}></div>
      )}
      <div className="p-4 xl:p-6">
        <div className="flex items-center">
          {data.badgeStatus && (
            <span className="mr-2">
              <Badge iconOnly={true} status={data.badgeStatus} />
            </span>
          )}
          <div
            className={`font-semibold ${
              data.number > 0
                ? "text-theme-text-primary"
                : "text-theme-text-tertiary"
            }`}
          >
            {data.title}
          </div>
        </div>
        <div
          className={`${
            data.number > 0
              ? "text-theme-text-primary"
              : "text-theme-text-tertiary"
          }`}
        >
          <span
            className="text-2xl font-semibold  mr-2"
            className={`text-2xl font-semibold mr-2 ${
              data.number > 0
                ? "text-theme-text-secondary"
                : "text-theme-text-tertiary"
            }`}
          >
            {data.number}
          </span>
          <span className="text-xs">Policies</span>
        </div>
        <hr className="my-4" />
        <div
          className={`text-xxs ${
            data.number > 0
              ? "text-theme-text-primary"
              : "text-theme-text-tertiary"
          }`}
        >
          {data.description}
        </div>
        <div className={`mt-4 text-right ${data.actionNeeded ? "" : "hidden"}`}>
          <Link
            to={`/book-of-business?type=${data.type}`}
            className="ui-btn-primary"
          >
            Work on This <span className="sr-only"> {data.title}</span>
          </Link>
        </div>
      </div>
    </div>
  );
}
