
import { useRowPagination } from "../../../hooks/useRowPagination";
import RowPagination from "../../../components/General/RowPagination";
import SimpleDataRow from "../../../components/General/SimpleDataRow";
import { Disclosure, Transition } from "@headlessui/react";


import {
  scheduledPayments,
  paymentHistory,
  statements,
} from "../../../js/placeholderData/billingData";



const PaymentTab2 = ({ updatePolicyChoice, policies }) => {
  const { rowsForDisplay: scheduledPaymentsRows, rowPaginationProps } =
    useRowPagination(scheduledPayments);
  
  
  const { rowsForDisplay: paymentHistoryRows, rowPaginationProps1 } =
    useRowPagination(paymentHistory);  
  
    const { rowsForDisplay: statementsRows, rowPaginationProps2 } =
      useRowPagination(statements);  
  

  return (
    <>
      <div className="ui-component-spacing mt-6">
        <Disclosure>
          {({ open }) => (
            <div className="mt-6 xl:mt-8">
              <h3>
                <Disclosure.Button className="group px-2 text-lg text-left flex items-start justify-start hover:text-theme-action-hover">
                  <span className="flex-none no-underline mt-[.375rem] mr-4">
                    <svg
                      className="ui-icon w-4 h-4 text-theme-action group-hover:text-theme-action-hover"
                      focusable="false"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <use
                        href={`#${open ? "ui-icon-minus" : "ui-icon-plus"}`}
                      ></use>
                    </svg>
                  </span>
                  <span className="group-hover:underline font-brand text-lg md:text-2xl">
                    Scheduled Payments
                  </span>
                </Disclosure.Button>
              </h3>
              <hr className="ui-under-title" />
              <Transition
                show={open}
                enter="transition duration-200 ease-out"
                enterFrom="transform scale-y-0 opacity-0"
                enterTo="transform scale-y-100 opacity-100"
                leave="transition duration-200 ease-out"
                leaveFrom="transform scale-y-100 opacity-100"
                leaveTo="transform scale-y-0 opacity-0"
              >
                <Disclosure.Panel className="overflow-hidden transition-all ease-in-out duration-300">
                  {scheduledPaymentsRows.map((item, idx) => (
                    <div key={idx} className="ui-card-bar mt-4">
                      <div className="px-3 py-2 lg:p-4">
                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 md:grid-cols-5 xl:grid-cols-5 xl:gap-6">
                          <div>
                            <div className="text-xs text-theme-text-tertiary">
                              {item.policyName}
                            </div>
                            <div className="text-sm font-semibold text-theme-text-secondary">
                              <div>#{item.policyNumber}</div>
                            </div>
                          </div>
                          <div>
                            <div className="text-xs text-theme-text-tertiary">
                              Payment amount
                            </div>
                            <div className="text-sm font-semibold text-theme-text-secondary">
                              <div>{item.amount}</div>
                            </div>
                          </div>
                          <div>
                            <div className="text-xs text-theme-text-tertiary">
                              Payment method
                            </div>
                            <div className="text-sm font-semibold text-theme-text-secondary">
                              <div>{item.method}</div>
                            </div>
                          </div>
                          <div>
                            <div className="text-xs text-theme-text-tertiary">
                              Payment submitted
                            </div>
                            <div className="text-sm font-semibold text-theme-text-secondary">
                              <div>{item.scheduledDate}</div>
                            </div>
                          </div>
                          <div className="md:justify-self-end">
                            <div>
                              <div className="text-xs text-theme-text-tertiary">
                                You can
                              </div>
                              <div className="text-sm font-semibold text-theme-text-secondary">
                                <button className="ui-btn-hyperlink font-semibold ">
                                  Cancel
                                  <span className="sr-only">
                                    {" "}
                                    policy number {item.policyNumber}
                                  </span>
                                </button>
                                <span className="mx-2">|</span>
                                <button className="ui-btn-hyperlink font-semibold">
                                  Edit
                                  <span className="sr-only">
                                    {" "}
                                    policy number {item.policyNumber}{" "}
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <RowPagination {...rowPaginationProps} />
                </Disclosure.Panel>
              </Transition>
            </div>
          )}
        </Disclosure>

        <Disclosure>
          {({ open }) => (
            <div className="mt-6 xl:mt-8">
              <h3>
                <Disclosure.Button className="group px-2 text-lg text-left flex items-start justify-start hover:text-theme-action-hover">
                  <span className="flex-none no-underline mt-[.375rem] mr-4">
                    <svg
                      className="ui-icon w-4 h-4 text-theme-action group-hover:text-theme-action-hover"
                      focusable="false"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <use
                        href={`#${open ? "ui-icon-minus" : "ui-icon-plus"}`}
                      ></use>
                    </svg>
                  </span>
                  <span className="group-hover:underline font-brand text-lg md:text-2xl">
                    Completed payments
                  </span>
                </Disclosure.Button>
              </h3>
              <hr className="ui-under-title" />
              <Transition
                show={open}
                enter="transition duration-200 ease-out"
                enterFrom="transform scale-y-0 opacity-0"
                enterTo="transform scale-y-100 opacity-100"
                leave="transition duration-200 ease-out"
                leaveFrom="transform scale-y-100 opacity-100"
                leaveTo="transform scale-y-0 opacity-0"
              >
                <Disclosure.Panel className="overflow-hidden transition-all ease-in-out duration-300">
                  {scheduledPaymentsRows.map((item, idx) => (
                    <div key={idx} className="ui-card-bar mt-4">
                      <div className="px-3 py-2 lg:p-4">
                        <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 md:grid-cols-5 xl:grid-cols-5 xl:gap-6">
                          <div>
                            <div className="text-xs text-theme-text-tertiary">
                              {item.policyName}
                            </div>
                            <div className="text-sm font-semibold text-theme-text-secondary">
                              <div>#{item.policyNumber}</div>
                            </div>
                          </div>
                          <div>
                            <div className="text-xs text-theme-text-tertiary">
                              Payment amount
                            </div>
                            <div className="text-sm font-semibold text-theme-text-secondary">
                              <div>{item.amount}</div>
                            </div>
                          </div>
                          <div>
                            <div className="text-xs text-theme-text-tertiary">
                              Payment method
                            </div>
                            <div className="text-sm font-semibold text-theme-text-secondary">
                              <div>{item.method}</div>
                            </div>
                          </div>
                          <div>
                            <div className="text-xs text-theme-text-tertiary">
                              Payment submitted
                            </div>
                            <div className="text-sm font-semibold text-theme-text-secondary">
                              <div>{item.scheduledDate}</div>
                            </div>
                          </div>
                          <div className="md:justify-self-end">
                            <div>
                              <div className="text-xs text-theme-text-tertiary">
                                You can
                              </div>
                              <div className="text-sm font-semibold text-theme-text-secondary">
                                <button className="ui-btn-hyperlink font-semibold ">
                                  Cancel
                                  <span className="sr-only">
                                    {" "}
                                    policy number {item.policyNumber}
                                  </span>
                                </button>
                                <span className="mx-2">|</span>
                                <button className="ui-btn-hyperlink font-semibold">
                                  Edit
                                  <span className="sr-only">
                                    {" "}
                                    policy number {item.policyNumber}{" "}
                                  </span>
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <RowPagination {...rowPaginationProps} />
                </Disclosure.Panel>
              </Transition>
            </div>
          )}
        </Disclosure>

        <Disclosure>
          {({ open }) => (
            <div className="mt-6 xl:mt-8">
              <h3>
                <Disclosure.Button className="group px-2 text-lg text-left flex items-start justify-start hover:text-theme-action-hover">
                  <span className="flex-none no-underline mt-[.375rem] mr-4">
                    <svg
                      className="ui-icon w-4 h-4 text-theme-action group-hover:text-theme-action-hover"
                      focusable="false"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <use
                        href={`#${open ? "ui-icon-minus" : "ui-icon-plus"}`}
                      ></use>
                    </svg>
                  </span>
                  <span className="group-hover:underline font-brand text-lg md:text-2xl">
                    Statement
                  </span>
                </Disclosure.Button>
              </h3>
              <hr className="ui-under-title" />
              <Transition
                show={open}
                enter="transition duration-200 ease-out"
                enterFrom="transform scale-y-0 opacity-0"
                enterTo="transform scale-y-100 opacity-100"
                leave="transition duration-200 ease-out"
                leaveFrom="transform scale-y-100 opacity-100"
                leaveTo="transform scale-y-0 opacity-0"
              >
                <Disclosure.Panel className="overflow-hidden transition-all ease-in-out duration-300">
                  {statementsRows.map((c, idx) => {
                    return (
                      <SimpleDataRow
                        key={`statement row for statement - ${c.id}`}
                        type="statement"
                        data={c}
                      />
                    );
                  })}
                  <RowPagination {...rowPaginationProps} />
                </Disclosure.Panel>
              </Transition>
            </div>
          )}
        </Disclosure>
      </div>
    </>
  );
}

export default PaymentTab2;