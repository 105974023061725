
import { Dialog } from "@headlessui/react";
import { v4 as uuidv4 } from "uuid";


const AgentNotesDeleteModal = ({ isOpenDeleteNote, setIsOpenDeleteNote }) => {
  const noteId = uuidv4();
  return (
    <>
      <Dialog
        open={isOpenDeleteNote}
        onClose={() => setIsOpenDeleteNote(false)}
        className="ui-dialogs"
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel
            role="dialog"
            aria-modal="true"
            className="ui-default-dialog"
          >
            <Dialog.Title className="font-body text-xl font-bold text-left">
              Delete this note?
            </Dialog.Title>
            <button
              className="ui-default-dialog-close"
              aria-label="close add note window"
              onClick={() => setIsOpenDeleteNote(false)}
            >
              <svg
                className="ui-icon h-3.5 w-3.5"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href="#ui-icon-close"></use>
              </svg>
            </button>
            <hr />
            <Dialog.Description>
              <span className="sr-only">
                Use this window to delete this agent note.
              </span>
            </Dialog.Description>
            <p className="font-bold">This will delete this note forever.</p>
            <form>
              <div className="block text-right">
                <button
                  className="ui-btn-secondary ui-btn-min-w mt-4"
                  onClick={() => setIsOpenDeleteNote(false)}
                >
                  No, never mind
                </button>
                <button
                  className="ui-btn-primary ui-btn-min-w mt-4"
                  onClick={() => setIsOpenDeleteNote(false)}
                >
                  Yes, delete
                </button>
              </div>
            </form>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

export default AgentNotesDeleteModal;
