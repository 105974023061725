import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import FormInputError from "../../../General/FormInputError";

export default function FACStepFour({ formStep, setFormStep, setFACFormData }) {
  const uuid = uuidv4();

  const {
    register: FACStepFourRegister,
    handleSubmit: FACStepFourHandleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setFormStep(5);
    setFACFormData((currentFormData) => {
      return {
        ...currentFormData,
        ...data,
      };
    });
  };

  return (
    <>
      {formStep === 4 && (
        <>
          <div>
            <hr />
            <p className="text-sm">
              Text about how providing this information can help coordinate and
              expedite the payment of final expenses. Lorem Ipsum dolor sit
              amet.
            </p>

            <form onSubmit={FACStepFourHandleSubmit(onSubmit)}>
              <div className="grid gap-4 grid-cols-1 lg:gap-6">
                <div className="grid gap-4 grid-cols-1 md:gap-y-0 md:gap-x-4 md:grid-cols-[2fr,2fr,1fr]"></div>
                <div>
                  <label htmlFor={`${uuid}-name`} className="block mb-1">
                    Name *
                  </label>
                  <input
                    id={`${uuid}-name`}
                    type="text"
                    placeholder="Please enter name for funeral home (required)"
                    {...FACStepFourRegister(`funeralHomeName`, {
                      required: "Please enter a name for the funeral home",
                    })}
                  />
                  <FormInputError
                    id={`${uuid}-name-error`}
                    errorMessage={errors?.funeralHomeName?.message}
                  />
                </div>

                <div>
                  <label htmlFor={`${uuid}-fh-sa`} className="block mb-1">
                    Street address
                  </label>
                  <input
                    id={`${uuid}-fh-sa`}
                    type="text"
                    placeholder="Please enter address for funeral home"
                    {...FACStepFourRegister(`funeralHomeStreetAddress`)}
                  />
                  <FormInputError
                    id={`${uuid}-fh-sa-error`}
                    errorMessage={errors?.funeralHomeStreetAddress?.message}
                  />
                </div>

                <div>
                  <label htmlFor={`${uuid}-fh-sa2`} className="block mb-1">
                    Street address 2
                  </label>
                  <input
                    id={`${uuid}-fh-sa2`}
                    type="text"
                    {...FACStepFourRegister(`funeralHomeStreetAddress2`)}
                  />
                  <FormInputError
                    id={`${uuid}-fh-sa2-error`}
                    errorMessage={errors?.funeralHomeStreetAddress2?.message}
                  />
                </div>
                <div className="grid gap-4 grid-cols-1 md:gap-y-0 md:gap-x-4 md:grid-cols-[1.5fr,1fr,1fr]">
                  <div>
                    <label htmlFor={`${uuid}-fh-city`} className="block mb-1">
                      City
                    </label>
                    <input
                      id={`${uuid}-fh-city`}
                      type="text"
                      {...FACStepFourRegister(`funeralHomeCity`)}
                    />
                    <FormInputError
                      id={`${uuid}-fh-city-error`}
                      errorMessage={errors?.funeralHomeCity?.message}
                    />
                  </div>

                  <div>
                    <label htmlFor={`${uuid}-fh-state`} className="block mb-1">
                      State
                    </label>
                    <input
                      id={`${uuid}-fh-state`}
                      type="text"
                      {...FACStepFourRegister(`funeralHomeState`)}
                    />
                    <FormInputError
                      id={`${uuid}-fh-state-error`}
                      errorMessage={errors?.funeralHomeState?.message}
                    />
                  </div>

                  <div>
                    <label htmlFor={`${uuid}-fh-zip`} className="block mb-1">
                      Zip
                    </label>
                    <input
                      id={`${uuid}-fh-zip`}
                      type="text"
                      {...FACStepFourRegister(`funeralHomeZip`)}
                    />
                    <FormInputError
                      id={`${uuid}-fh-zip-error`}
                      errorMessage={errors?.funeralHomeZip?.message}
                    />
                  </div>
                </div>
                <div className="grid gap-4 grid-cols-1 md:gap-y-0 md:gap-x-4 md:grid-cols-[1.5fr,1fr,1fr]">
                  <div>
                    <label htmlFor={`${uuid}-fh-phone`} className="block mb-1">
                      Phone
                    </label>
                    <input
                      id={`${uuid}-fh-phone`}
                      aria-describedby={`${uuid}-fh-phone-note`}
                      type="text"
                      {...FACStepFourRegister(`funeralHomePhone`)}
                    />
                    <div
                      id={`${uuid}-fh-phone-note`}
                      className="text-xxs text-theme-text-tertiary mt-1"
                    >
                      Use format: XXX-XX-XXXX
                    </div>
                    <FormInputError
                      id={`${uuid}-fh-phone-error`}
                      errorMessage={errors?.funeralHomePhone?.message}
                    />
                  </div>
                </div>
                <div className="flex justify-end">
                  <button
                    className="ui-btn-secondary ui-btn-min-w mt-4"
                    type="button"
                    onClick={() => setFormStep((current) => (current -= 1))}
                  >
                    Previous
                  </button>

                  <button className="ui-btn-primary ui-btn-min-w mt-4">
                    Continue
                  </button>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
}
