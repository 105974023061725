import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";

import CallToActionTextOverImage from "../components/General/CallToActionTextOverImage";
import ServicesAndClaimsCard from "../components/ServicesAndClaims/ServicesAndClaimsCard";
import FormInputError from "../components/General/FormInputError";

export default function UnauthClaims() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const UUID = uuid();

  const claimsIdCapture = (data) => {
    navigate(`${data.claimNumber}`);
  };

  return (
    <>
      {/* SPRINT-3-CUSTOMER-claims-and-forms.pdf (pgs 20-22) */}
      <div className="container mb-8">
        <h1>Claims</h1>
        <p>
          Lorem ipsum dolor sit amet, eam ad autem fabulas. Expetenda dissentiet
        </p>
        <hr />
      </div>

      <div className="container ui-component-spacing">
        {/* CTA Image */}
        <CallToActionTextOverImage
          backgroundImagePath="/assets/img/cta-Image-3.jpg"
          backgroundImageAltText="We are Here for You"
          backgroundImageOpacity=".2"
          headline="We are Here for You"
          tagline="We are very sorry for your loss, we know losing a loved one is difficult.  Our Customer Service team is here to help you through the process of filing a claim and make it easy as possible for you."
        />
      </div>
      <div className="relative container ui-component-spacing lg:pb-[4.5rem]">
        <div className="lg:absolute lg:left-0 lg:right-0 lg:-mt-[5.25rem] lg:z-50">
          <div className="ui-card p-4 lg:w-10/12 lg:mx-auto xl:p-6">
            <div className="flex flex-col justify-center">
              <h2 className="text-center text-theme-text-quinary">
                Have an open claims? You can work on it or track it here
              </h2>
              <form
                onSubmit={handleSubmit(claimsIdCapture)}
                className="md:w-3/4 md:mx-auto"
              >
                <div className="mt-2 flex flex-col md:flex-row">
                  <label
                    className="mt-2 whitespace-nowrap mr-2"
                    htmlFor={`${UUID}-claim-input`}
                  >
                    Enter Claim ID #
                  </label>

                  <div className="flex flex-row">
                    <div className="flex flex-col mr-2">
                      <input
                        id={`${UUID}-claim-input`}
                        className=""
                        {...register("claimNumber", {
                          required:
                            "Please enter a claim ID # before continuing.",
                        })}
                      />
                      <FormInputError
                        errorMessage={errors?.claimNumber?.message}
                      />
                      <div className="mt-1 text-xxs text-theme-text-tertiary">
                        You can find your claim ID number in the confirmation
                        email you received after filing your claim
                      </div>
                    </div>
                    <div>
                      <button className="ui-btn-primary h-[2.375rem]">
                        Track
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div className="container ui-component-spacing">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 lg:gap-6">
          {/* File a death claim */}
          <ServicesAndClaimsCard
            titleFont="font-header text-theme-text-quinary"
            data={{
              title: "File a death claim",
              desc: "Our beneficiary services team is here to help you file your claim and answer your questions.",
              imageResource: "/assets/img/video-placeholder1.png",
              subTitle: "Resources",
              links: [
                {
                  title: "File a death claim & what to expect when filing",
                  icon: "#ui-icon-checklist",
                  url: "file-a-claim",
                },
                {
                  title: "Survivor support",
                  icon: "#ui-icon-steps",
                  url: "/",
                },

                {
                  title: "Any other relevant links can be added",
                  icon: "#ui-icon-document",
                  url: "/",
                },
              ],
            }}
          />

          {/* File a disability claim */}
          <ServicesAndClaimsCard
            titleFont="font-header text-theme-text-quinary"
            data={{
              title: "File a disability claim",
              desc: "Text appropriate for this type of claim and the support we offer lorem ipsum dolor sit amet consecteteur alum wisi nonummy sunc.",
              subTitle: "Resources",
              imageResource: "/assets/img/video-placeholder1.png",
              links: [
                {
                  title: "File a disability claim & what to expect when filing",
                  icon: "#ui-icon-checklist",
                  url: "/",
                },
                {
                  title: "Support groups",
                  icon: "#ui-icon-user-group",
                  url: "/",
                },
                {
                  title: "Any other relevant links can be added",
                  icon: "#ui-icon-document",
                  url: "/",
                },
              ],
            }}
          />
          {/* File a another type of claim */}
          <ServicesAndClaimsCard
            titleFont="font-header text-theme-text-quinary"
            data={{
              title: "File a another type of claim",
              desc: "Text appropriate for this type of claim and the support we offer lorem ipsum dolor sit amet consecteteur alum wisi nonummy sunc.",
              imageResource: "/assets/img/video-placeholder1.png",
              subTitle: "Resources",
              links: [
                {
                  title:
                    "File a <this type of> claim & what to expect when filing",
                  icon: "#ui-icon-steps",
                  url: "/",
                },
                {
                  title: "Any other relevant links can be added",
                  icon: "#ui-icon-document",
                  url: "/",
                },
              ],
            }}
          />
        </div>
      </div>
    </>
  );
}
