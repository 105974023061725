// route: /services-claims/services/:id
export const service = {
  id: "938473984",
  policyNumber: "55555555555",
  status: "good",
  statusText: "Processing",
  type: "Change insured name",
  dateSubmitted: "01/01/2023",
  expectedToComplete: "X-X business days",
  detail: {
    original: "Sarah Jameson",
    new: "Sarah Toissant",
    reason: "data",
    moreReason: "data",
    requiredDocs: [
      {
        id: 1,
        name: "Policy Pages",
        location: "#",
        desc: "Download PDF",
      },
    ],
  },
};
