import DownloadDocumentTile from "../../General/DownloadDocumentTile";
import { html, Grid } from "gridjs";
import { useEffect, useRef } from "react";
import Badge from "../../General/Badge";
import { useCollapsePanel } from "../../../hooks/useCollapsePanel";
import { v4 as uuidv4 } from "uuid";
import Tooltip from "../../General/Tooltip";

export default function CoverageAndDocumentsCard({ data }) {
  const uuid = uuidv4();
  useCollapsePanel(uuid);
  const additionalBenefitsGrid = useRef(null);
  const grid = new Grid({
    columns: [
      {
        name: "Benefit/Rider",
        data: (row) => row.benefitName,
        formatter: (cell, row) => {
          return html(`<a href="${row.id}">${cell}</a>`);
        },
      },
      {
        name: "Coverage",
        data: (row) => `$${row.coverage}`,
      },
      {
        name: "Date Issued",
      },
      {
        name: "Expiry Date",
      },
    ],
    data: data.additionalBenefits,
    className: {
      table: "ui-grid-table",
    },
  });
  useEffect(() => {
    grid.render(additionalBenefitsGrid.current);
  }, []);
  return (
    <div className="ui-card mb-4 xl:mb-6">
      <div className="md:flex md:flex-row md:items-start">
        <div className="px-4 pt-3 md:flex-grow xl:px-6 xl:pt-6">
          <h2 className="mb-0">
            <button
              id={uuid}
              className="flex items-center group md:hidden"
              aria-controls={`${uuid}-panel`}
              aria-expanded="true"
              aria-label="Expand or collapse coverage &amp; documents details"
            >
              <span className="inline-block p-1 rounded-full mr-2 text-ui-blue group-hover:text-white group-hover:bg-ui-blue">
                <svg
                  className="ui-icon ui-plus w-4 h-4"
                  focusable="false"
                  aria-hidden="true"
                  role="presentation"
                >
                  <use href="#ui-icon-plus"></use>
                </svg>
                <svg
                  className="ui-icon ui-minus w-4 h-4"
                  focusable="false"
                  aria-hidden="true"
                  role="presentation"
                >
                  <use href="#ui-icon-minus"></use>
                </svg>
              </span>
              <span
                className={`text-base font-body font-bold md:ui-no-badge-icon-inline ${data.status ? `ui-badge-inline-icon-${data.status}` : ""
                  }`}
              >
                Coverage &amp; Documents
              </span>
            </button>

            <span className="text-xl font-body font-bold hidden md:block">
              Coverage &amp; Documents
              <Tooltip
                srText="Owner &amp; Insured Help"
                content="Access policy details document below"
              />
            </span>
          </h2>

        </div>
        <hr className="mb-0 mt-3 md:hidden" />
        <div className="hidden px-4 pt-4 text-sm md:justify-self-end md:px-6 md:pt-6">
          {/* <!-- Card title link --> */}
        </div>
      </div>
      <hr className="hidden md:block" />

      <div
        id={`${uuid}-panel`}
        className="px-4 pb-4 overflow-hidden h-20 md:h-auto xl:px-6 xl:pb-6"
      >
        {/* <!-- there is an associated mobile class "ui-badge-inline-icon-problem" on the title that displays when this block hides --> */}
        <div className="hidden md:block md:mb-4 xl:mb-6">
          <Badge
            status={data.status}
            statusText={data.statusText}
            srPrefix="Policy"
          />
        </div>

        {/* <!-- Policy Details --> */}
        <div className="grid grid-cols-2 gap-4 md:grid-cols-4 xl:gap-6">
          <div>
            <div className="text-xs text-theme-text-tertiary">
              Policy Number
            </div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              #{data.policyNumber}
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">Coverage</div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              ${data.coverage}
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">Product Name
            </div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              {data.name}
              <Tooltip
                srText="Product Name"
                content="Learn more here"
                cta="/help/help-faqs"
              />
            </div>
            <div className="text-xxs font-semibold">Term - {data.term}</div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">Issue Date</div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              {data.startDate}
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">
              Coverage End Date
            </div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              {data.endDate}
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">Title</div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              {data.title}
            </div>
          </div>
        </div>
        {/* <!-- Policy Details --> */}

        {/* <!-- Policy Documents --> */}
        <div className="font-semibold mt-6 mb-1">Policy Documents</div>
        <div className="ui-download-document">
          {data.documents.map((document) => {
            return <DownloadDocumentTile data={document} key={document.id} />;
          })}
        </div>
        {/* <!-- Policy Documents --> */}

        {/* <!-- Additional Benefits --> */}
        <div className="font-semibold mt-6 mb-1">Additional Benefits</div>
        <div ref={additionalBenefitsGrid} />
        {/* <!-- Additional Benefits --> */}

        <div className="text-center text-xs text-theme-text-tertiary mt-4 md:mt-6">
          Ask your agent about additional available benefits
        </div>
      </div>
    </div>
  );
}
