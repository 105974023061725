import { Dialog } from "@headlessui/react";
import { v4 as uuid } from "uuid";
import { useForm } from "react-hook-form";
import NotificationSelection from "../NotificationSelection";
import DialogBackdrop from "../../../ModalDialogs/DialogBackdrop";
export default function UserNotificationDialog({
  dialogState,
  setDialogState,
}) {
  const { register, handleSubmit } = useForm();
  const notificationTypes = ["text", "email", "mail"];
  
  const notificationTypesMailOnly = ["none", "none", "mail"];
  const notificationTypesNoMail = ["text", "email", "none"];

  return (
    <Dialog
      open={dialogState}
      onClose={() => setDialogState(false)}
      className="ui-dialogs"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <DialogBackdrop />
      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel
          role="dialog"
          aria-modal="true"
          className="ui-default-dialog"
        >
          <Dialog.Title className="font-body text-xl font-bold text-left">
            Manage Notification Preferences
          </Dialog.Title>
          <button
            className="ui-default-dialog-close"
            aria-label="close manage notification preferences window"
            onClick={() => setDialogState(false)}
          >
            <svg
              className="ui-icon h-3.5 w-3.5"
              focusable="false"
              aria-hidden="true"
              role="presentation"
            >
              <use href="#ui-icon-close"></use>
            </svg>
          </button>
          <hr />
          <Dialog.Description>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
            tincidunt mi sit amet ipsum tempus interdum. Maecenas non enim
            lectus.
          </Dialog.Description>

          <form
            onSubmit={handleSubmit((formData) => {
              alert(`data -- ${JSON.stringify(formData)}`);
              setDialogState(false);
            })}
          >
            <div className="ui-grid-manage-notification">
              <div>{/* empty cell for grid */}</div>
              <div className="text-sm font-semibold px-4 text-center">Text</div>
              <div className="text-sm font-semibold px-4 text-center">
                Email
              </div>
              <div className="text-sm font-semibold px-4 text-center">Mail</div>
              {/* 1099 Notifications */}
              <fieldset className="contents">
                <legend className="text-base font-semibold py-3">
                  1099 Notifications
                  <div className="text-xs text-theme-text-tertiary">
                    This form can only be received by mail.
                  </div>
                </legend>
                {notificationTypesMailOnly.map((type) => {
                  return (
                    <NotificationSelection
                      key={`notifications1099-${type}-checkBox`}
                      register={register}
                      mainType="notifications1099"
                      subType={type}
                      textDisabled="true"
                      emailDisabled="true"
                      mailDisabled="false"
                    />
                  );
                })}
              </fieldset>

              <hr className="ui-no-margins col-span-4" />

              {/* Address Changes */}
              <fieldset className="contents">
                <legend className="text-base font-semibold py-3">
                  Address Changes
                </legend>
                {notificationTypes.map((type) => {
                  return (
                    <NotificationSelection
                      key={`addressChanges-${type}-checkBox`}
                      register={register}
                      mainType="addressChanges"
                      subType={type}
                    />
                  );
                })}
              </fieldset>

              <hr className="ui-no-margins col-span-4" />

              {/* Name Changes */}
              <fieldset className="contents">
                <legend className="text-base font-semibold py-3">
                  Name Changes
                </legend>
                {notificationTypes.map((type) => {
                  return (
                    <NotificationSelection
                      key={`nameChanges-${type}-checkBox`}
                      register={register}
                      mainType="nameChanges"
                      subType={type}
                    />
                  );
                })}
              </fieldset>

              <hr className="ui-no-margins col-span-4" />

              {/* Outstanding Requirements */}
              <fieldset className="contents">
                <legend className="text-base font-semibold py-3">
                  Outstanding Requirements
                </legend>
                {notificationTypes.map((type) => {
                  return (
                    <NotificationSelection
                      key={`outstandingRequirements-${type}-checkBox`}
                      register={register}
                      mainType="outstandingRequirements"
                      subType={type}
                    />
                  );
                })}
              </fieldset>

              <hr className="ui-no-margins col-span-4" />

              {/* Upcoming Policy Lapses */}
              <fieldset className="contents">
                <legend className="text-base font-semibold py-3">
                  Upcoming Policy Lapses
                </legend>
                {notificationTypesNoMail.map((type) => {
                  return (
                    <NotificationSelection
                      key={`upcomingPolicyLapses-${type}-checkBox`}
                      register={register}
                      mainType="upcomingPolicyLapses"
                      subType={type}
                    />
                  );
                })}
              </fieldset>
              <hr className="ui-no-margins col-span-4" />

              {/* Upcoming Policy Expirations */}
              <fieldset className="contents">
                <legend className="text-base font-semibold py-3">
                  Upcoming Policy Expirations
                </legend>
                {notificationTypesNoMail.map((type) => {
                  return (
                    <NotificationSelection
                      key={`upcomingPolicyExpirations-${type}-checkBox`}
                      register={register}
                      mainType="upcomingPolicyExpirations"
                      subType={type}
                    />
                  );
                })}
              </fieldset>
              <hr className="ui-no-margins col-span-4" />

              {/* Notification */}
              <fieldset className="contents">
                <legend className="text-base font-semibold py-3">
                  Notification
                </legend>
                {notificationTypes.map((type) => {
                  return (
                    <NotificationSelection
                      key={`notification-${type}-checkBox`}
                      register={register}
                      mainType="notification"
                      subType={type}
                    />
                  );
                })}
              </fieldset>
              <hr className="ui-no-margins col-span-4" />
            </div>

            {/* <!-- Buttons --> */}
            <div className="block text-right mt-6">
              <button
                type="button"
                className="ui-btn-secondary ui-btn-min-w mt-4"
                onClick={() => setDialogState(false)}
              >
                Cancel
                <span className="sr-only">
                  changes to your security settings
                </span>
              </button>
              <button className="ui-btn-primary ui-btn-min-w mt-4">
                Save
                <span className="sr-only">
                  changes to your security settings
                </span>
              </button>
            </div>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
