
const ReportCard = ({ data, setIsOpenExport }) => {
  return (
    <>
      {data.map((item, idx) => (
        <div
          key={`row-${idx}`}
          className="p-4 border-b border-theme-rule-medium-light xl:py-6 last:border-b-0"
        >
          <div className="text-xxs text-theme-text-tertiary">{item.date}</div>
          <div className="grid gap-4 grid-cols-1 sm:grid-cols-[1fr,max-content]">
            <div className="text-sm text-theme-text-secondary font-semibold">
              {item.title}
            </div>
            <button
              className="ui-btn-hyperlink ui-link-icon text-xs font-bold"
              onClick={() => setIsOpenExport(true)}
            >
              <svg
                className="ui-icon w-4 h-4"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href="#ui-icon-document-download"></use>
              </svg>
              Download PDF <span className="sr-only"> {item.srTitle}</span>
            </button>
          </div>
        </div>
      ))}
    </>
  );
}

export default ReportCard