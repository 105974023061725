import { Link } from "react-router-dom";
export default function SearchRow({ data }) {
  return (
    <div className="ui-card p-4">
      <div className="grid gap-4 grid-cols-1 md:grid-cols-[78%_20%] grid-rows-[max-content]">
        <div>
          <div className="font-semibold">{data.title}</div>
          <div className="mt-1 text-xxs grid gap-1 grid-cols-1 md:gap-4 md:grid-cols-[max-content_1fr]">
            <div className="text-theme-text-tertiary">
              <span className="font-semibold mr-2">Published on:</span>
              {data.publishedOn}
            </div>
            <div className="text-theme-text-tertiary">
              <span className="font-semibold mr-2">Tags:</span>
              {data.tags.join(" | ")}
            </div>
          </div>
          <div className="mt-2 text-xs">
            {data.article}
            <Link to={`/help/article/${data.id}`}>read more</Link>
          </div>
        </div>
        {data.ctaUrl && (
          <div className="md:justify-self-center md:self-center">
            <Link to={data.ctaUrl} className="ui-btn-primary">
              {data.ctaText}
            </Link>
          </div>
        )}
        {data.videoUrl && (
          <div className="md:justify-self-center md:self-center">
            <img
              src={data.videoUrl}
              className="w-full h-auto max-w-none"
              alt=""
            />
          </div>
        )}
      </div>
    </div>
  );
}
