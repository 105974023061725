import FormInputError from "../../General/FormInputError";
import { v4 as uuidv4 } from "uuid";

export default function SchedulePaymentDate({
  register,
  errors,
  trigger,
  getFieldState,
  setDisableSubmit,
}) {
  let debounceFunction; //timer identifier
  let debounceTimer = 1000; //time in ms, 1 seconds for example

  const autoPay = uuidv4();

  return (
    <>
      <div className="px-0 md:px-8">
        <div className="ui-form-card-row2 font-semibold grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
          <div className="ui-form-card font-semibold selected">
            <label
              id={autoPay + "-label"}
              htmlFor={autoPay}
              className="block text-xxs mr-5 mb-1"
            >
              Date of month
            </label>
            <input
              id={autoPay}
              aria-labelledby={autoPay + "-label"}
              aria-describedby={autoPay + "-error"}
              type="text"
              {...register("date", {
                required: {
                  value: true,
                  message: "Please enter a date",
                },
                min: {
                  value: 1,
                  message: "Please choose a date between 1 to 28",
                },
                max: {
                  value: 28,
                  message: "Please choose a date between 1 to 28",
                },
              })}
              onKeyUp={() => {
                clearTimeout(debounceFunction);
                debounceFunction = setTimeout(async () => {
                  await trigger("date");
                  if (!getFieldState("date").invalid) setDisableSubmit(false);
                }, debounceTimer);
              }}
              onKeyDown={() => {
                clearTimeout(debounceFunction);
              }}
            />
            <div className="ui-form-card-checkbox">
              <svg
                className="ui-icon w-2 h-2 text-white hidden"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href="#ui-icon-checkmark"></use>
              </svg>
            </div>
          </div>
        </div>
        <div id={autoPay + "-error"}>
          {errors.date?.message && (
            <FormInputError errorMessage={errors.date.message} />
          )}
        </div>
      </div>
    </>
  );
}
