export const faqs = [
  {
    title: "Site Navigation",
    desc: `<p>If you are having difficulty finding something on this site, simply send an email to Member Services using the Contact Us link at the bottom of the page. You can also call the toll-free Member Services number located at the bottom of the page or click on the Chat icon at the top of the page.</p>`,
  },
  {
    title: "How do I find my agent’s contact information?",
    desc: `Your agent’s contact information can be found <a href="/help#contact-us">here</a>.`,
  },
  {
    title: "What if my email address changes?",
    desc: `
      To change your email address for the Customer Portal, click on your profile icon in the upper right corner of the site and click on ‘Update’ in the Contact Info card.<br />
      <a href="/profile">Here</a> is where you can update your email address.
    `,
  },
  {
    title: "What is premium payment?",
    desc: `A life insurance premium is the payment that you pay your life insurance company in exchange for your life insurance policy coverage. Typically, you pay your premium once a month or once a year, however you can choose a different timeframe for most policies.`,
  },
  {
    title: "When is my premium draft date or day?",
    desc: `You can find the date your premium will be drafted on the Manage Payments & Billing page by clicking <a href="/payment">here</a>.`,
  },
  {
    title: "How do I make or track the status of a payment?",
    desc: `
      To access the Manage Billing & Payments section of the site, please click <a href="/payment/billing">here</a>.<br />
      You can easily view the history of your payments for each policy, make a payment, set up recurring payments and track any past due payments.<br />
      You can also call the toll-free Customer Service number found at the bottom of this page.<br />
    `,
  },
  {
    title: "How do I track the status of a claim?",
    desc: `
    To track the status of a claim you have submitted, please click <a href="/services-claims">here</a>.<br />
    On this page you can see notifications of any documentation we need to complete the processing of your claim, you can upload documentation and send to us and view where we are in the process of completing your claim.<br />
    You can also call the toll-free Customer Service number found at the bottom of this page.<br />`,
  },
  {
    title: "How do I change my payment method?",
    desc: `
    To use a credit card to pay your premium on auto-pay, click <a href="/payment/manage-payments">here</a>.<br />
    You will select the payment amount and frequency, select ‘Add Payment Method’ and enter your credit card details, select the day of the month you would like your credit card payment to be made and click ‘Submit’.<br />
    The system will confirm if payment information was successful.<br />`,
  },
  {
    title: "What is the last day to pay to keep policy from lapsing?",
    desc: `
      The grace period to pay your premium and avoid the policy lapsing is located <a href="/payment">here</a>.<br />
      This date is shown in the ‘Policy Will Lapse On’ field.<br />
      We must receive your premium payment before this date in order to keep your policy in force.
    `,
  },
  {
    title: "What do I need to do to reinstate my policy?",
    desc: `
    Common items needed to reinstate a policy that has lapsed are a completed Reinstatement Form, Premium payment and sometimes requires underwriting review.<br />
    Please contact Customer Service at XXX-XXX-XXXX or contact your agent for details.`,
  },
  {
    title:
      "My lender has requested Collateral Assignment of my policy, is this possible?",
    desc: `
    If your lender is requesting Collateral Assignment of your policy for a loan, you will need to complete a Collateral Assignment Form.<br />
    Please contact us at XXX-XXX-XXXX to obtain the form and ask any questions you may have on the process.`,
  },
  {
    title: "How can I remove a Collateral Assignment on my policy?",
    desc: `We typically require a sign-off from your lender to remove the Collateral Assignment.<br />
    Please contact Customer Service at XXX-XX-XXXX to obtain the form needed and provide it to your lender for completion.<br />
    You can upload and submit the completed form within this portal <a href="#">here</a> and we will process the request.`,
  },
  {
    title: "How much do I need to pay to reinstate my Policy?",
    desc: `To find out how much you need to pay to reinstate your policy, please contact your agent whose information can be found <a href="/help">here</a>.<br />
    `,
  },
  {
    title: "Why did I receive a Grace/Lapse Notice?",
    desc: `Common reasons for receiving this notification is if you sent in the payment after payment due date or the payment amount was not enough to keep the policy in force.<br />
    Please contact your agent or call our Customer Service number at XXX-XXX-XXXX.`,
  },
  {
    title: "How do I add a beneficiary or change my beneficiary?",
    desc: `To manage your beneficiaries, please click <a href="/policies/beneficiaries">here</a>.<br />
    You can edit the details for your current beneficiaries, add additional primary and contingent beneficiaries and designate the percentage split for each beneficiary.`,
  },
  {
    title:
      "What is the difference between a primary beneficiary and a contingent beneficiary?",
    desc: `Simply stated, a primary beneficiary is the first person entitled to receive the benefits, and a contingent beneficiary is next in line. As the name insinuates, primary beneficiaries have the first right to claim the benefits.`,
  },
  {
    title: "Must my beneficiary have an insurable interest?",
    desc: `No. If you buy a policy on your own life, you become the owner of the policy. As the owner, you can name anyone as beneficiary... even a stranger!`,
  },
  {
    title: "What is life insurance?",
    desc: `
    In its simplest state, every life insurance policy is made up of these four components:<br />
    <b>Insured</b> - The person whose life is covered under the policy.Typically, this is the person who owns the policy and pays the premiums, however, it is possible for the policy owner and payor to be someone other than the insured.<br />
    <b>Beneficiary</b> - The person(s), entity, or institution(s) that receive the death benefit if the insured person dies.You can name one person(or more) as beneficiaries when you purchase a policy.<br />
    <b>Premium</b> - The money paid to keep a policy active.Payment ensures that the insurance company will provide your beneficiaries with the stated death benefit in the event of your passing.If you stop paying premiums, the policy lapses.<br />
    <b>Death benefit</b> - The money paid out if the insured person passes away.Death benefits are generally not subject to an income tax and beneficiaries usually receive the benefit in one lump - sum payment.
    `,
  },
  {
    title: "Who can take a life insurance policy out on my life?",
    desc: `Only someone who has an "insurable interest" can purchase an insurance policy on your life. That means a stranger cannot buy a policy to insure your life. People with an insurable interest generally include members of your immediate family. In some circumstances your employer or business partner might also have an insurable interest.

    Insurable interest may also be proper for institutions or people who become your major creditors.`,
  },
  {
    title: `How does Term Life Insurance work?`,
    desc: `If you pass away while your term life insurance policy is in force, your beneficiary will receive the death benefit. If you do not pass away during the term, no one will receive the death benefit.<br />
    And premiums you pay are typically nonrefundable, unless you bought a term policy with a "return of premium" option.`,
  },
  {
    title: `What happens when the term ends?`,
    desc: `If your term policy is renewable, you may be able to extend your coverage for another term, up to a specified age. If your term policy is convertible, you may be able to convert it to a permanent life policy. Check with your agent for details on convertible policies, as the conversion typically has to happen within a specified time period.`,
  },
  {
    title: `What is Term Conversion?`,
    desc: `A term conversion is when you convert your term life insurance policy into a permanent life insurance policy. Many term policies will include a stipulation that allows you to convert some or all of your coverage into a permanent policy within a certain time frame.`,
  },
  {
    title: `How do I provide authorization for a person different than the policy owner to be provided information?`,
    desc: `If you are the policy owner and would like to provide authorization for a secondary person to receive information one time you can contact Customer Service at XXX-XXX-XXXX.<br />
    To authorize future communications for a secondary person to obtain information, please send the request in writing and mail to XXXX.`,
  },
];
