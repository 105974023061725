import { primary, contingent } from "../js/placeholderData/beneficiaries";
import { policies } from "../js/placeholderData/policiesData";

import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Dialog } from "@headlessui/react";

import AddBeneficiaryCard from "../components/Policy/Beneficiary/AddBeneficiaryCard";
import BeneficiaryDetailCard from "../components/Policy/Beneficiary/BeneficiaryDetailCard";
import DialogBackdrop from "../components/ModalDialogs/DialogBackdrop";
import Tooltip from "../components/General/Tooltip";
import FormInputError from "../components/General/FormInputError";

export default function Beneficiaries() {
  const { policy_id } = useParams();
  const navigate = useNavigate();
  let [allocationDialogIsOpen, setAllocationDialogIsOpen] = useState(false);

  useEffect(() => {
    if (policy_id) setValue("policy_selection", policy_id);
  }, [policy_id]);

  // allocation split form config
  const [allocationArr, setAllocationArr] = useState(
    contingent.map((c) => c.percentage)
  );

  const [totalAllocationPercentage, setTotalAllocationPercentage] = useState(0);
  useEffect(() => {
    setTotalAllocationPercentage(() => getAllocationSum());
  }, [allocationArr]);

  const getAllocationSum = () => {
    return allocationArr.reduce((partialSum, a) => partialSum + a, 0);
  };

  const {
    register,
    setValue,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const submitAllocation = (data) => {
    if (totalAllocationPercentage !== 100) return;
    console.log(data);
    return;
  };

  return (
    <>
      <div className="container ui-component-spacing">
        <h1>Beneficiary details</h1>
        <p>
          Details for the person or entity that you legally designate to receive the
          benefits from your financial products
        </p>
        <hr />
      </div>

      <div className="container ui-component-spacing">
        <div className="inline-flex flex-col justify-start md:flex-row md:items-center">
          <label
            id="ID_selectPolicyLabel"
            htmlFor="ID_selectPolicy"
            className="mr-4"
          >
            {policy_id
              ? "Policy: "
              : "Please select a policy to see beneficiaries of selected policy"}
          </label>
          <select
            id="ID_selectPolicy"
            aria-labelledby="ID_selectPolicyLabel"
            {...register("policy_selection")}
            onChange={(e) => {
              navigate(e.target.value);
            }}
          >
            <option value="">Please select a policy</option>
            {policies.map((policy, idx) => (
              <option
                value={policy.policyNumber}
                key={`user all policy selection - ${idx}`}
              >
                {policy.policyTitle} #{policy.policyNumber}
              </option>
            ))}
          </select>
        </div>
      </div>

      {policy_id && (
        <>
          <div className="container ui-component-spacing">
            <div className="inline-flex items-start">
              <h2>Primary beneficiaries</h2>
              <Tooltip
                srText="Primary Beneficiaries help"
                content="The person (or persons) first in line to receive the death benefit from your policy"
              />
            </div>

            <hr />

            <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 lg:grid-cols-3 auto-rows-fr">
              {primary.map((bene, idx) => (
                <BeneficiaryDetailCard
                  data={bene}
                  key={`beneficiary - ${idx}`}
                  policy_id={policy_id}
                />
              ))}
              {/* Hidden for cutover 1
          <AddBeneficiaryCard type="primary" /> */}
            </div>
          </div>
          {contingent.length > 0 && (
            <div className="container ui-component-spacing">
              <div className="flex flex-col md:flex-row justify-between">
                <div className="inline-flex items-start">
                  <h2>Contingent beneficiaries</h2>
                  <Tooltip
                    srText="Contingent beneficiaries help"
                    content="The person (or persons) alternatively named to receive the benefits from your policy"
                  />
                </div>
                {/* Hidden for cutover one
          <button
            className="ui-btn-hyperlink"
            onClick={() => setAllocationDialogIsOpen(true)}
          >
            Adjust percentage split allocation
          </button> */}
              </div>
              <hr />

              <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 lg:grid-cols-3 auto-rows-fr">
                {contingent.map((bene, idx) => (
                  <BeneficiaryDetailCard
                    data={bene}
                    key={`contingent beneficiary - ${idx}`}
                    policy_id={policy_id}
                  />
                ))}

                {/* Hidden for cutover one
          <AddBeneficiaryCard type="contingent" /> */}
              </div>
            </div>

          )}
        </>
      )}

      {/* Adjust allocation Dialog */}
      <Dialog
        open={allocationDialogIsOpen}
        onClose={() => setAllocationDialogIsOpen(false)}
        className="ui-dialogs"
      >
        <DialogBackdrop />
        <form onSubmit={handleSubmit(submitAllocation)}>
          <Dialog.Panel className="ui-default-dialog">
            <Dialog.Title className="dialog_label2 font-body text-xl font-bold text-left">
              Readjust percentage split allocation
            </Dialog.Title>
            <button
              className="ui-default-dialog-close"
              onClick={() => setAllocationDialogIsOpen(false)}
            >
              <svg
                className="ui-icon h-3.5 w-3.5"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href="#ui-icon-close"></use>
              </svg>
            </button>

            <hr />

            <Dialog.Description as="div">
              <p className="text-xs">
                Since you added or removed a beneficiary please update the
                percentage split allocation to add up to 100%
              </p>

              <div className="text-sm font-semibold mx-auto w-full md:w-4/5 lg:w-3/4">
                <div className="grid grid-cols-2 gap-x-2 justify-items-stretch rounded mb-2 border border-ui-gray-medium-light bg-ui-gray-lightest">
                  <div className="p-2 pt-4">Beneficiary name</div>
                  <div id="ID_percentageSplit" className="p-2 pt-4">
                    Percentage split
                  </div>
                </div>

                {contingent.map((beneficiary, idx) => {
                  return (
                    <div
                      className="grid grid-cols-2 gap-x-2 content-center rounded mb-2 border border-ui-gray-medium-light"
                      key={`contingent - ${idx}`}
                    >
                      <label
                        id={`contingent-${idx}-label`}
                        htmlFor={`contingent-${idx}-input`}
                        className="p-2 text-sm self-center"
                      >
                        {beneficiary.name}
                      </label>
                      <div className="p-2">
                        <input
                          id={`contingent-${idx}-input`}
                          aria-labelledby={`contingent-${idx}-label`}
                          aria-describedby={`ID_percentageSplit contingent-${idx}-error`}
                          type="number"
                          defaultValue={beneficiary.percentage}
                          {...register(`${beneficiary.name}-allocation-value`, {
                            required:
                              "Please enter a allocation percentage for this beneficiary.",
                            min: {
                              value: 0,
                              message:
                                "Allocation percentage can not be less than 1%.",
                            },
                          })}
                          onChange={(e) => {
                            let newArr = allocationArr;
                            newArr[idx] = e.target.valueAsNumber;
                            setAllocationArr(newArr);
                            setTotalAllocationPercentage(getAllocationSum());
                          }}
                        />
                        <FormInputError
                          id={`contingent-${idx}-error`}
                          errorMessage={
                            errors[`${beneficiary.name}-allocation-value`]
                              ?.message
                          }
                        />
                      </div>
                    </div>
                  );
                })}

                <div className="grid grid-cols-2 gap-x-2 content-center rounded mb-2">
                  <label
                    id="ID_percentageTotalLabel"
                    htmlFor="ID_percentageTotal"
                    className="p-2 text-sm self-center"
                  >
                    Total
                  </label>
                  <div className="p-2">
                    {totalAllocationPercentage}

                    {totalAllocationPercentage !== 100 && (
                      <>
                        <div
                          id="ID_percentageTotalError"
                          className="ui-input-error"
                        >
                          Allocation total must equal 100%
                        </div>
                        <p className="text-xs">
                          Since you added or removed a beneficiary please update
                          the percentage split allocation to add up to 100%
                        </p>
                      </>
                    )}
                  </div>
                </div>
              </div>

              {/* form on changed display this */}
            </Dialog.Description>
            <div className="block text-right mt-6">
              <button
                type="button"
                className="ui-btn-secondary ui-btn-min-w mt-4"
                onClick={() => setAllocationDialogIsOpen(false)}
              >
                Cancel
              </button>
              <button className="ui-btn-primary ui-btn-min-w mt-4">Save</button>
            </div>
          </Dialog.Panel>
        </form>
      </Dialog>
      {/* Adjust allocation Dialog */}
    </>
  );
}
