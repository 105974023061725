import { Tab } from "@headlessui/react";
import { usePagination } from "../../../hooks/usePagination";
import SimpleDataRow from "../../General/SimpleDataRow";
import Pagination from "../../General/Pagination";

export default function StatementsTab({ data }) {
  const { current, paginationProps } = usePagination(data, 5);

  return (
    <Tab.Panel>
      {current.map((c, idx) => {
        return (
          <SimpleDataRow
            key={`statement row for statement - ${c.id}`}
            type="statement"
            data={c}
          />
        );
      })}
      {/* <!-- paging component --> */}
      <div className="flex justify-between items-start mt-6">
        <div></div>
        <Pagination {...paginationProps} />
      </div>
      {/* <!-- paging component --> */}
    </Tab.Panel>
  );
}
