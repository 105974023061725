// route: /policies/beneficiaries/:policy_id
export const primary = [
  {
    name: "Sarah Toissant",
    type: "Primary",
    relationship: "Spouse",
    percentage: 100,
    ssn: "5555",
    address: {
      streetNumber: 555,
      streetName: "Maple Lane",
      city: "Anytown",
      state: "NY",
      zipcode: "55555",
    },
    email: "sarah.toissant@email.com",
    phoneNumber: [
      {
        type: "Mobile",
        number: "555-555-5555",
      },
      {
        type: "Home",
        number: "555-555-5555",
      },
    ],
  },
];

export const contingent = [
  {
    name: "Renny Toissant",
    relationship: "Child",
    type: "Contingent",
    percentage: 34,
    ssn: "5555",
    address: {
      streetNumber: 555,
      streetName: "Maple Lane",
      city: "Anytown",
      state: "NY",
      zipcode: "55555",
    },
    phoneNumber: [
      {
        type: "Mobile",
        number: "555-555-5555",
      },
      {
        type: "Home",
        number: "555-555-5555",
      },
    ],
  },
  {
    name: "Ruby Toissant",
    relationship: "Child",
    type: "Contingent",
    percentage: 33,
    ssn: "5555",
    address: {
      streetNumber: 555,
      streetName: "Maple Lane",
      city: "Anytown",
      state: "NY",
      zipcode: "55555",
    },
    phoneNumber: [
      {
        type: "Mobile",
        number: "555-555-5555",
      },
      {
        type: "Home",
        number: "555-555-5555",
      },
    ],
  },
  {
    name: "Roger Toissant",
    relationship: "Child",
    type: "Contingent",
    percentage: 33,
    ssn: "5555",
    address: {
      streetNumber: 555,
      streetName: "Maple Lane",
      city: "Anytown",
      state: "NY",
      zipcode: "55555",
    },
    phoneNumber: [
      {
        type: "Mobile",
        number: "555-555-5555",
      },
      {
        type: "Home",
        number: "555-555-5555",
      },
    ],
  },
];
