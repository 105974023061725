import ForgotUsername from "../components/ForgotUsername/ForgotUsername";
import AgentForgotUsername from "../components/AgentPortal/ForgotUsername/AgentForgotUsername";
import { useIsAgentCheck } from "../hooks/useIsAgentCheck";

export default function ForgotUsernameContainer() {
  const { IS_AGENT } = useIsAgentCheck();

  return (
    <>
      {/* Forgot username */}
      <div className="container mb-8">
        <h1>Forgot username</h1>
        <p>
          Lorem ipsum dolor sit amet, eam ad autem fabulas. Expetenda dissentiet
        </p>
        <hr />
      </div>
      {IS_AGENT ? <AgentForgotUsername /> : <ForgotUsername />}
    </>
  );
}
