import { useLocation, matchPath } from "react-router-dom";

export const useBreadcrumb = () => {
  const location = useLocation();
  let matched = "";

  [
    "/",
    "/register",
    "/forgot-password",
    "/forgot-username",
    "/guest/make-a-payment",
    "/profile",
    "/policies",
    "/policies/:policy_id",
    "/policies/beneficiaries/:policy_id",
    "/payment",
    "/payment/autopay/:policy_id",
    "/payment/make-a-payment",
    "/payment/billing",
    "/payment/manage-payments",
    "/services-claims",
    "/services-claims/services-life-insurance",
    "/services-claims/services-annuity",
    "/services-claims/claims",
    "/services-claims/claims/:id",
    "/services-claims/claims/file-a-claim",
    "/services-claims/services/:id",
    "/messages",
    "/help",
    "/help/help-faqs",
    "/help/help-explore",
    "/help/search-results",
    "/help/article/:article_id",
    "/reports/:report_id",
    "/help/faqs",
    "/help/explore",
  ].forEach((v) => {
    if (matchPath(v, location.pathname)) return (matched = v);
  });

  switch (matched) {
    case "/guest/make-a-payment":
      return [
        { name: "Home", url: "/" },
        { name: "Make a payment", url: "#" },
      ];

    case "/profile":
      return [
        { name: "Home", url: "/policies" },
        { name: "Profile", url: "#" },
      ];

    case "/policies":
      return [{ name: "Policies Overview", url: "#" }];

    case "/policies/:policy_id":
      return [
        { name: "Home", url: "/policies" },
        { name: "Term life - George Policy Details", url: "#" },
      ];

    case "/policies/beneficiaries/:policy_id":
      return [
        { name: "Home", url: "/policies" },
        {
          name: "Term life - George Policy Details",
          url: "/policies/93485397",
        },
        { name: "Beneficiaries", url: "#" },
      ];

    case "/payment":
      return [{ name: "Payment", url: "#" }];

    case "/payment/autopay/:policy_id":
      return [
        { name: "Payment", url: "/payment" },
        { name: "Manage Auto Pay", url: "#" },
      ];

    case "/payment/make-a-payment":
      return [
        { name: "Payment", url: "/payment" },
        { name: "Make a payment", url: "#" },
      ];

    case "/payment/billing":
      return [
        { name: "Payment", url: "/payment" },
        { name: "Billing", url: "#" },
      ];

    case "/payment/manage-payments":
      return [
        { name: "Payment", url: "/payment" },
        { name: "Manage payments", url: "#" },
      ];

    case "/services-claims":
      return [{ name: "Service and Claim", url: "#" }];

    case "/services-claims/services-life-insurance":
      return [
        { name: "Service and Claim", url: "/services-claims" },
        { name: "Life insurance services", url: "#" },
      ];

    case "/services-claims/services-annuity":
      return [
        { name: "Service and Claim", url: "/services-claims" },
        { name: "Annuity services", url: "#" },
      ];

    case "/services-claims/claims":
      return [
        { name: "Service and Claim", url: "/services-claims" },
        { name: "Claims", url: "#" },
      ];

    case "/services-claims/claims/:id":
      return [
        { name: "Service and Claim", url: "/services-claims" },
        { name: "Claims", url: "/services-claims/claims" },
        { name: "Claim status", url: "#" },
      ];

    case "/services-claims/claims/file-a-claim":
      return [
        { name: "Service and Claim", url: "/services-claims" },
        { name: "Claims", url: "/services-claims/claims" },
        { name: "File a claim", url: "#" },
      ];

    case "/services-claims/services/:id":
      return [
        { name: "Service and Claim", url: "/services-claims" },
        { name: "Services", url: "/services-claims" },
        { name: "Service status", url: "#" },
      ];

    case "/messages":
      return [{ name: "Messages", url: "#" }];

    case "/help":
      return [{ name: "Help", url: "#" }];

    case "/help/help-faqs":
      return [
        {
          name: "Help",
          url:
            window.localStorage.getItem("authenticated") &&
            window.localStorage.getItem("authenticated") === "true"
              ? "/help"
              : "/guest/help",
        },
        { name: "FAQ", url: "#" },
      ];
    case "/help/help-explore":
      return [
        {
          name: "Help",
          url:
            window.localStorage.getItem("authenticated") &&
            window.localStorage.getItem("authenticated") === "true"
              ? "/help"
              : "/guest/help",
        },
        { name: "Explore", url: "#" },
      ];

    case "/help/search-results":
      return [
        {
          name: "Help",
          url:
            window.localStorage.getItem("authenticated") &&
            window.localStorage.getItem("authenticated") === "true"
              ? "/help"
              : "/guest/help",
        },
        { name: "Search result", url: "#" },
      ];

    case "/help/article/:article_id":
      return [
        {
          name: "Help",
          url:
            window.localStorage.getItem("authenticated") &&
            window.localStorage.getItem("authenticated") === "true"
              ? "/help"
              : "/guest/help",
        },
        { name: "Article name here", url: "#" },
      ];

    case "/reports/:report_id":
      return [
        { name: "Reports", url: "/reports" },
        { name: "Run annual Book of Business report", url: "#" },
      ];

    default:
      return [];
  }
};
