import ServicesAndClaimsCard from "../components/ServicesAndClaims/ServicesAndClaimsCard";
export default function Claims() {
  return (
    <>
      {/* SPRINT-3-CUSTOMER-claims-and-forms.pdf (pgs 20-22) */}
      <div className="container mb-8">
        <h1>Claims</h1>
        <p>
          Lorem ipsum dolor sit amet, eam ad autem fabulas. Expetenda dissentiet
        </p>
        <hr />
      </div>

      <div className="container ui-component-spacing">
        {/* CTA Image */}
        <div>
          <img
            alt="video placeholder"
            src="/assets/img/pexels-marcus-aurelius-6787960a.jpg"
            className="w-full h-auto max-w-none"
          />
        </div>

        <div className="grid grid-cols-1 gap-4 mt-4 md:grid-cols-2 lg:grid-cols-3 lg:gap-6 xl:mt-6 lg:-mt-[6.25rem]">
          {/* File a death claim */}
          <ServicesAndClaimsCard
            titleFont="font-header text-theme-text-quinary"
            data={{
              title: "File a death claim",
              desc: "Text appropriate for this type of claim and the support we offer lorem ipsum dolor sit amet consecteteur alum wisi nonummy sunc.",
              imageResource: "/assets/img/video-placeholder1.png",
              subTitle: "Resources",
              links: [
                {
                  title: "Checklist of what you will need to file a claim",
                  icon: "#ui-icon-checklist",
                  url: "/",
                },
                {
                  title:
                    "Step by step guide of what to expect in the claims process",
                  icon: "#ui-icon-steps",
                  url: "/",
                },
                {
                  title: "Survivor support",
                  icon: "#ui-icon-user-group",
                  url: "/",
                },
                {
                  title: "Any other relevant links can be added",
                  icon: "#ui-icon-document",
                  url: "/",
                },
              ],
            }}
          />

          {/* File a disability claim */}
          <ServicesAndClaimsCard
            titleFont="font-header text-theme-text-quinary"
            data={{
              title: "File a disability claim",
              desc: "Text appropriate for this type of claim and the support we offer lorem ipsum dolor sit amet consecteteur alum wisi nonummy sunc.",
              subTitle: "Resources",
              imageResource: "/assets/img/video-placeholder1.png",
              links: [
                {
                  title: "Checklist of what you will need to file a claim",
                  icon: "#ui-icon-checklist",
                  url: "/",
                },
                {
                  title:
                    "Step by step guide of what to expect in the claims process",
                  icon: "#ui-icon-steps",
                  url: "/",
                },
                {
                  title: "Support groups",
                  icon: "#ui-icon-user-group",
                  url: "/",
                },
                {
                  title: "Any other relevant links can be added",
                  icon: "#ui-icon-document",
                  url: "/",
                },
              ],
            }}
          />
          {/* File a another type of claim */}
          <ServicesAndClaimsCard
            titleFont="font-header text-theme-text-quinary"
            data={{
              title: "File a another type of claim",
              desc: "Text appropriate for this type of claim and the support we offer lorem ipsum dolor sit amet consecteteur alum wisi nonummy sunc.",
              imageResource: "/assets/img/video-placeholder1.png",
              subTitle: "Resources",
              links: [
                {
                  title: "Checklist of what you will need to file a claim",
                  icon: "#ui-icon-checklist",
                  url: "/",
                },
                {
                  title:
                    "Step by step guide of what to expect in the claims process",
                  icon: "#ui-icon-steps",
                  url: "/",
                },
                {
                  title: "Any other relevant links can be added",
                  icon: "#ui-icon-document",
                  url: "/",
                },
              ],
            }}
          />
        </div>
      </div>
    </>
  );
}
