import { useState } from "react";
import Badge from '../../General/Badge';
import UserAvatarDialog from "./Dialogs/UserAvatarDialog";

const AgentProfileCard = ({ data }) => {
  const [openUserAvatarDialog, setOpenUserAvatarDialog] = useState(false);

  return (
    <>
      <div className="container ui-component-spacing">
        <div className="ui-card p-4 xl:p-6">
          <div className="flex flex-col md:flex-row">
            <div className="mb-4 mr-auto sm:w-60 lg:w-3/5 md:mr-6">
              <div className="relative">
                <img
                  src={data.userAvatar}
                  alt="avatar"
                  className="w-full h-auto max-w-none"
                />
                <button
                  className="absolute group shadow-md bottom-2 right-2 rounded-full border border-transparent text-theme-action hover:text-theme-action hover:border-white"
                  onClick={() => setOpenUserAvatarDialog(true)}
                >
                  <svg
                    className="ui-icon h-6 w-6"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                    aria-label="Edit your account photo"
                  >
                    <g className="group-hover:hidden">
                      <use href="#ui-icon-edit-stroke"></use>
                    </g>
                    <g className="hidden group-hover:block">
                      <use href="#ui-icon-edit-solid"></use>
                    </g>
                  </svg>
                </button>
              </div>
            </div>
            <div>
              <div className="text-lg font-semibold flex flow-root">
                {data.firstName} {data.lastName}
                <span className="ml-2 text-base font-normal">
                  {data.pronoun}
                </span>
                <div className="mt-2 sm:mt-0 sm:float-right">
                  <Badge
                    status={data.status}
                    statusText={data.statusText}
                    srPrefix="Policy"
                  />
                </div>
              </div>

              <hr />
              <div className="grid grid-cols-1 gap-4 items-start sm:grid-cols-2 xl:gap-6">
                <p className="text-xs">{data.goal}</p>
                <p className="text-xs">{data.additionalText}</p>
                <div className="grid grid-cols-1 gap-4 items-start sm:grid-cols-2 xl:gap-6">
                  <div>
                    <div className="text-xs text-theme-text-tertiary">
                      Address
                    </div>
                    <div className="text-sm font-semibold text-theme-text-secondary">
                      <div>{data.agencyName}</div>
                      {data.suiteNumber && <div>{data.suiteNumber}</div>}
                      <div>{data.streetAddress}</div>
                      <div>
                        {data.city}, {data.state} {data.zipcode}
                      </div>
                    </div>
                  </div>

                  <div className="grid grid-cols-1 gap-4 items-start md:gap-2">
                    {data.businessPhone && (
                      <div>
                        <div className="text-xs text-theme-text-tertiary">
                          Business Number
                        </div>
                        <div className="text-sm text-theme-text-secondary font-semibold">
                          {data.businessPhone}
                        </div>
                      </div>
                    )}
                    {data.mobilePhone && (
                      <div>
                        <div className="text-xs text-theme-text-tertiary">
                          Mobile Number
                        </div>
                        <div className="text-sm text-theme-text-secondary font-semibold">
                          {data.mobilePhone}
                        </div>
                      </div>
                    )}
                    {data.faxNumber && (
                      <div>
                        <div className="text-xs text-theme-text-tertiary">
                          faxNumber
                        </div>
                        <div className="text-sm text-theme-text-secondary font-semibold">
                          {data.faxNumber}
                        </div>
                      </div>
                    )}
                  </div>
                </div>

                <div className="grid grid-cols-1 gap-4 items-start sm:grid-cols-2 xl:gap-6">
                  <div className="grid grid-cols-1 gap-4 items-start md:gap-2">
                    {data.specialty && (
                      <div>
                        <div className="text-xs text-theme-text-tertiary">
                          Specialty
                        </div>
                        <div className="text-sm text-theme-text-secondary font-semibold">
                          {data.specialty}
                        </div>
                      </div>
                    )}
                    {data.yearsInService && (
                      <div>
                        <div className="text-xs text-theme-text-tertiary">
                          Years in Service
                        </div>
                        <div className="text-sm text-theme-text-secondary font-semibold">
                          {data.yearsInService}
                        </div>
                      </div>
                    )}
                  </div>
                  <div>
                    {data.manager && (
                      <div>
                        <div className="text-xs text-theme-text-tertiary">
                          Manager
                        </div>
                        <div className="text-sm text-theme-text-secondary font-semibold">
                          {data.manager}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UserAvatarDialog
        state={openUserAvatarDialog}
        setState={setOpenUserAvatarDialog}
      />
    </>
  );
}

export default AgentProfileCard