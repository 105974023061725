export const policyLookUpData = [
  {
    banner: "problem",
    badgeStatus: "problem",
    badgeSrText: "Policy has problem",
    badgeText: "$0.00 past due",
    policyNumber: "5723452",
    policyTitle: "Term Life - Sarah Toisssant - 1",
    insuredName: "Sarah Toissant",
    coverage: "500,000",
    productName: "Annual Renewable",
    term: "15 Years",
    value: "0.00",
    startDate: "01/01/2023",
    endDate: "01/01/2023",
    cta: "payment",
    faceAmount: "<value>",
    deathBenefit: "<value>",
    nfOption: "Extended term insurance",
    accountValue: "<value>",
    surrenderCharge: "<value>",
    surrenderValue: "<value>",
    billingMethod: "<value>",
    draftDate: "Extended term insurance",
    mode: "<data>",
    modalPremium: "<data>",
    paymentAmount: "XX.XX",
    paymentMethod: "Bank Account",
    dueDate: "01/01/2023",
  },
  {
    banner: "",
    badgeStatus: "risk",
    badgeSrText: "Policy is risk",
    badgeText: "Payment draft in X",
    policyNumber: "93485397",
    policyTitle: "Whole Life - George -2",
    insuredName: "George Toissant",
    coverage: "100,000",
    productName: "Whole Life Product",
    term: "10 Years",
    value: "0,000",
    startDate: "01/01/2023",
    endDate: "01/01/2023",
    cta: "detail",
    faceAmount: "<value>",
    deathBenefit: "<value>",
    nfOption: "Extended term insurance",
    accountValue: "<value>",
    surrenderCharge: "<value>",
    surrenderValue: "<value>",
    billingMethod: "<value>",
    draftDate: "Extended term insurance",
    mode: "<data>",
    modalPremium: "<data>",
    paymentAmount: "XX.XX",
    paymentMethod: "Bank Account",
    dueDate: "01/01/2023",
  },
  {
    banner: "",
    badgeStatus: "good",
    badgeSrText: "Policy is good",
    badgeText: "Paid to Date",
    policyNumber: "9517535",
    policyTitle: "Term Life - George Toisssant - 3",
    insuredName: "Sarah Toissant",
    coverage: "500,000",
    productName: "Annual Renewable",
    term: "15 Years",
    value: "",
    startDate: "01/01/2023",
    endDate: "01/01/2023",
    cta: "detail",
    faceAmount: "<value>",
    deathBenefit: "<value>",
    nfOption: "Extended term insurance",
    accountValue: "<value>",
    surrenderCharge: "<value>",
    surrenderValue: "<value>",
    billingMethod: "<value>",
    draftDate: "Extended term insurance",
    mode: "<data>",
    modalPremium: "<data>",
    paymentAmount: "XX.XX",
    paymentMethod: "Bank Account",
    dueDate: "01/01/2023",
  },
  {
    banner: "problem",
    badgeStatus: "problem",
    badgeSrText: "Policy has problem",
    badgeText: "$0.00 past due",
    policyNumber: "7953198",
    policyTitle: "Term Life - Sarah Toisssant - 4",
    insuredName: "Sarah Toissant",
    coverage: "500,000",
    productName: "Annual Renewable",
    term: "15 Years",
    value: "0.00",
    startDate: "01/01/2023",
    endDate: "01/01/2023",
    cta: "payment",
    faceAmount: "<value>",
    deathBenefit: "<value>",
    nfOption: "Extended term insurance",
    accountValue: "<value>",
    surrenderCharge: "<value>",
    surrenderValue: "<value>",
    billingMethod: "<value>",
    draftDate: "Extended term insurance",
    mode: "<data>",
    modalPremium: "<data>",
    paymentAmount: "XX.XX",
    paymentMethod: "Bank Account",
    dueDate: "01/01/2023",
  },
  {
    banner: "problem",
    badgeStatus: "problem",
    badgeSrText: "Policy has problem",
    badgeText: "$0.00 past due",
    policyNumber: "2976173",
    policyTitle: "Term Life - Sarah Toisssant - 5",
    insuredName: "Sarah Toissant",
    coverage: "500,000",
    productName: "Annual Renewable",
    term: "15 Years",
    value: "0.00",
    startDate: "01/01/2023",
    endDate: "01/01/2023",
    cta: "payment",
    faceAmount: "<value>",
    deathBenefit: "<value>",
    nfOption: "Extended term insurance",
    accountValue: "<value>",
    surrenderCharge: "<value>",
    surrenderValue: "<value>",
    billingMethod: "<value>",
    draftDate: "Extended term insurance",
    mode: "<data>",
    modalPremium: "<data>",
    paymentAmount: "XX.XX",
    paymentMethod: "Bank Account",
    dueDate: "01/01/2023",
  },
  {
    banner: "problem",
    badgeStatus: "problem",
    badgeSrText: "Policy has problem",
    badgeText: "$0.00 past due",
    policyNumber: "1973258",
    policyTitle: "Term Life - Sarah Toisssant - 6",
    insuredName: "Sarah Toissant",
    coverage: "500,000",
    productName: "Annual Renewable",
    term: "15 Years",
    value: "0.00",
    startDate: "01/01/2023",
    endDate: "01/01/2023",
    cta: "payment",
    faceAmount: "<value>",
    deathBenefit: "<value>",
    nfOption: "Extended term insurance",
    accountValue: "<value>",
    surrenderCharge: "<value>",
    surrenderValue: "<value>",
    billingMethod: "<value>",
    draftDate: "Extended term insurance",
    mode: "<data>",
    modalPremium: "<data>",
    paymentAmount: "XX.XX",
    paymentMethod: "Bank Account",
    dueDate: "01/01/2023",
  },
];
