import FormInputError from "../../General/FormInputError";
import { v4 as uuidv4 } from "uuid";
export default function PaymentPayor({
  register,
  validate,
  getFieldState,
  errors,
  setCurrentStep,
}) {
  // debounce function config
  let debounceFunction; //timer identifier
  let debounceTimer = 500; //time in ms, 0.5 seconds for example

  const fNameId = uuidv4();
  const lNameId = uuidv4();
  const mInitialID = uuidv4();
  const emailID = uuidv4();
  const zipID = uuidv4();
  const mobileID = uuidv4();

  // make sure all payor require values has input before enabling submit
  const validatePayor = async () => {
    let formHasError = false;
    ["firstName", "lastName", "emailAddress", "zipcode"].forEach((v) => {
      validate(v);

      if (getFieldState(v).error) return (formHasError = true);
    });
    if (!formHasError) setCurrentStep(6);
  };

  return (
    <>
      <div className="ui-form-card-no-event m-0 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
        <div>
          <label
            id={fNameId + "-label"}
            htmlFor={fNameId}
            className="block mb-1"
          >
            First name *
          </label>
          <input
            id={fNameId}
            aria-labelledby={fNameId + "-label"}
            aria-describedby={fNameId + "-error"}
            type="text"
            {...register("firstName", {
              required: {
                value: true,
                message: "Please enter a first name.",
              },
            })}
            onKeyUp={() => {
              clearTimeout(debounceFunction);
              debounceFunction = setTimeout(async () => {
                await validate("firstName");
                await validatePayor();
              }, debounceTimer);
            }}
            onKeyDown={() => {
              clearTimeout(debounceFunction);
            }}
          />
          <div id={fNameId + "-error"}>
            {errors.firstName?.message && (
              <FormInputError errorMessage={errors.firstName.message} />
            )}
          </div>
        </div>
        <div>
          <label
            id={lNameId + "-label"}
            htmlFor={lNameId}
            className="block mb-1"
          >
            Last name *
          </label>
          <input
            id={lNameId}
            aria-labelledby={lNameId + "-label"}
            aria-describedby={lNameId + "-error"}
            type="text"
            {...register("lastName", {
              required: {
                value: true,
                message: "Please enter your last name.",
              },
            })}
            onKeyUp={() => {
              clearTimeout(debounceFunction);
              debounceFunction = setTimeout(async () => {
                await validate("lastName");
                await validatePayor();
              }, debounceTimer);
            }}
            onKeyDown={() => {
              clearTimeout(debounceFunction);
            }}
          />
          <div id={lNameId + "-error"}>
            {errors.lastName?.message && (
              <FormInputError errorMessage={errors.lastName.message} />
            )}
          </div>
        </div>
        <div>
          <label
            id={mInitialID + "-label"}
            htmlFor={mInitialID}
            className="block mb-1"
          >
            Middle initial
          </label>
          <input
            id={mInitialID}
            aria-labelledby={mInitialID + "-label"}
            type="text"
            {...register("mInitial")}
          />
        </div>
        <div>
          <label
            id={emailID + "-label"}
            htmlFor={emailID}
            className="block mb-1"
          >
            Email address *
          </label>
          <input
            id={emailID}
            aria-labelledby={emailID + "-label"}
            aria-describedby={`${emailID + "-help"} ${emailID + "-error"}`}
            type="text"
            {...register("emailAddress", {
              required: {
                value: true,
                message: "Please enter your email address.",
              },
              pattern: {
                value: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
                message: "Please enter a valid email",
              },
            })}
            onKeyUp={() => {
              clearTimeout(debounceFunction);
              debounceFunction = setTimeout(async () => {
                await validate("emailAddress");
                await validatePayor();
              }, debounceTimer);
            }}
            onKeyDown={() => {
              clearTimeout(debounceFunction);
            }}
          />
          <div id={emailID + "-error"}>
            {errors.emailAddress?.message && (
              <FormInputError errorMessage={errors.emailAddress.message} />
            )}
          </div>
          <div id={emailID + "-help"} className="text-xxs text-theme-text-tertiary mt-1">
            Format requirements for email
          </div>
        </div>
        <div>
          <label id={zipID + "-label"} htmlFor={zipID} className="block mb-1">
            Zip Code *
          </label>
          <input
            id={zipID}
            aria-labelledby={zipID + "-label"}
            aria-describedby={zipID + "-error"}
            type="text"
            {...register("zipcode", {
              required: {
                value: true,
                message: "Please enter your Zip Code.",
              },
              minLength: {
                value: 5,
                message: "Please enter your Zip Code.",
              },
            })}
            onKeyUp={() => {
              clearTimeout(debounceFunction);
              debounceFunction = setTimeout(async () => {
                await validate("zipcode");
                await validatePayor();
              }, debounceTimer);
            }}
            onKeyDown={() => {
              clearTimeout(debounceFunction);
            }}
          />
          <div id={zipID + "-error"}>
            {errors.zipcode?.message && (
              <FormInputError errorMessage={errors.zipcode.message} />
            )}
          </div>
        </div>
        <div>
          <label
            id={mobileID + "-label"}
            htmlFor={mobileID}
            className="block mb-1"
          >
            Mobile number
          </label>
          <input
            id={mobileID}
            aria-labelledby={mobileID + "-label"}
            aria-describedby={mobileID + "-help"}
            type="text"
            {...register("mobilePhone")}
          />
          <div id={mobileID + "-help"} className="text-xxs text-theme-text-tertiary mt-1">
            Format requirements for phone number
          </div>
        </div>
      </div>
    </>
  );
}
