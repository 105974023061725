import { useState, useEffect, useRef, Fragment } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

import { useMobileHeader } from "../../hooks/useMobileHeader";

import { Popover, Dialog, Transition } from "@headlessui/react";

import SearchForm from "./SearchForm";
import NavLink from "./NavLink";

export default function Header({ setAuthenicated }) {
  const { open: openMobileMenuNav, close: closeMobileMenuNav } =
    useMobileHeader();
  const [currentPage, setCurrentPage] = useState("/");
  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    setCurrentPage(location.pathname);
    // close search when navigating
    setShowDesktopSearch(false);
    setSiteSearchDialogIsOpen(false);
  }, [location.pathname]);

  // mobile menu trap focus
  const mobileMenuButton = useRef(null);

  // dialog for mobile site search
  let [sitesearchDialogIsOpen, setSiteSearchDialogIsOpen] = useState(false);
  let searchDialogInitialFocusRef = useRef(null);

  // site search config
  const siteSearchIcon = useRef(null);
  const [showDesktoSearch, setShowDesktopSearch] = useState(false);

  return (
    <>
      <header className="lg:bg-white shadow-drop">
        <a className="ui-skip-to-content" href="#mainContent">
          Skip to main content
        </a>

        <div id="id_header">
          <div id="id_headerTopBar">
            <div id="id_utilityMenu" className="ui-utilitybar">
              <div className="container py-4">
                <div className="flex justify-between flex-row-reverse items-center lg:grid lg:gap-x-2 lg:grid-cols-[1fr_max-content_max-content_max-content_max-content] lg:items-center">
                  <div className="lg:flex lg:items-center">
                    <div className="lg:flex">
                      <div id="everlakeLogoHeader">
                        <Link
                          to="/policies"
                          title="Return to dashboard"
                          onClick={() => setCurrentPage((page) => (page = "/"))}
                        >
                          <img
                            src="/assets/img/everlake-logo-midnight-blue.svg"
                            className="ui-logo-header"
                            alt="Everlake Logo"
                          />
                        </Link>
                      </div>
                      <div id="lumicoLogoHeader">
                        <Link
                          to="/policies"
                          title="Return to dashboard"
                          onClick={() => setCurrentPage((page) => (page = "/"))}
                        >
                          <img
                            src="/assets/img/lumico-header-logo.png"
                            className="ui-logo-header"
                            alt="Lumico Logo"
                          />
                        </Link>
                      </div>
                    </div>
                    <div className="hidden lg:flex text-[color:var(--theme-color-portal-title)] font-semibold ml-6 px-6 border-l border-theme-rule">
                      Customer Hub
                    </div>
                  </div>

                  {/* <!-- START - Header Icons  --> */}
                  <button
                    id="id_searchBtn"
                    ref={siteSearchIcon}
                    aria-controls="id_headerSearchForm"
                    aria-expanded={showDesktoSearch ? "true" : "false"}
                    aria-label="Open and focus site search"
                    title="Open and focus site search"
                    className={`ui-btn-icon relative h-7 w-7 rounded-full hidden lg:flex lg:items-center lg:justify-center ${
                      showDesktoSearch ? "ui-active" : ""
                    }`}
                    onClick={() => {
                      setShowDesktopSearch((show) => (show = !show));
                    }}
                  >
                    <svg
                      className="ui-icon w-4 h-4 "
                      focusable="false"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <use href="#ui-icon-search"></use>
                    </svg>
                  </button>
                  <button
                    aria-label="Start chat"
                    title="Start chat"
                    className="ui-btn-icon h-7 w-7 rounded-full hidden lg:flex lg:items-center lg:justify-center"
                  >
                    <svg
                      className="ui-icon w-4 h-4"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <use href="#ui-icon-chat"></use>
                    </svg>
                  </button>

                  <button
                    aria-label="User profile"
                    title="My Account"
                    aria-expanded="false"
                    aria-controls="id_account_menu"
                    className={`relative
                    ${
                      currentPage.split("/")[1] === "profile"
                        ? "ui-current"
                        : ""
                    }
                  `}
                    onClick={() => {
                      navigate("/profile");
                    }}
                  >
                    <span className="ui-btn-icon group w-7 h-7 rounded-full overflow-hidden hidden lg:flex lg:items-center lg:justify-center">
                      <img
                        src="/assets/img/profile-img.png"
                        alt="avatar"
                        className="w-7 h-auto group-hover:hidden"
                      />
                      <svg
                        className="ui-icon w-4 h-4 hidden group-hover:block"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <use href="#ui-icon-profile"></use>
                      </svg>
                    </span>
                  </button>

                  <button
                    aria-label="Sign out"
                    title="Sign out"
                    className="ui-btn-icon h-7 w-7 rounded-full hidden lg:flex lg:items-center lg:justify-center"
                    onClick={() => {
                      window.localStorage.setItem("authenticated", "");
                      setAuthenicated(false);
                      navigate("/profile");
                    }}
                  >
                    <svg
                      className="ui-icon w-4 h-4"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <use href="#ui-icon-signout"></use>
                    </svg>
                  </button>

                  <button
                    id="id_menuBtn"
                    ref={mobileMenuButton}
                    onClick={openMobileMenuNav}
                    aria-label="Main"
                    aria-expanded="false"
                    aria-controls="id_mainMenu"
                    className="ui-btn-icon h-8 w-8 flex items-center justify-center rounded-full m-0 lg:hidden"
                  >
                    <svg
                      className="ui-icon w-5 h-4"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <use href="#ui-icon-menu"></use>
                    </svg>
                  </button>
                </div>
                {/* <!-- START - Header Icons  --> */}
              </div>
            </div>

            {/* Desktop Header Site Search */}
            <div
              id="id_headerSearchForm"
              className={`ui-header-search ${
                showDesktoSearch ? "ui-open" : "ui-close"
              }`}
            >
              <div className="ui-header-search-desktop container py-6">
                <div className="mx-auto w-3/5 xl:w-1/2">
                  <SearchForm
                    focus={true}
                    display="desktop"
                    siteSearchIcon={siteSearchIcon}
                    setShowDesktopSearch={setShowDesktopSearch}
                  />
                </div>
              </div>
            </div>
            {/* Desktop Header Site Search */}
          </div>

          <div id="id_mainMenu" className="">
            <div className="ui-navbar w-64 lg:flex lg:w-full lg:z-auto lg:relative">
              <div
                id="id_mobileMenuMask"
                className="hidden fixed inset-0 w-full h-full bg-ui-gray-dark opacity-40 z-[800] lg:hidden lg:inset-auto lg:z-auto"
              ></div>

              <nav
                aria-label="Main"
                className="ui-main-menu hidden absolute w-64 z-[802] bottom-0 bg-white lg:flex lg:relative lg:w-full lg:z-auto lg:inset-auto lg:bg-transparent lg:container"
              >
                <div className="flex flex-col justify-between h-full">
                  <ul className="ui-disclosure-nav flex flex-col p-0 m-0 left-0 border-t border-ui-gray-medium-light lg:flex-row lg:left-auto lg:border-0 lg:relative">
                    <Popover as="li" className="group">
                      {({ close }) => (
                        <>
                          <Popover.Button
                            className={
                              currentPage.split("/")[1] === "policies"
                                ? "ui-current"
                                : ""
                            }
                          >
                            My policies
                          </Popover.Button>

                          <Popover.Panel
                            as="ul"
                            className="bg-white border-b lg:shadow lg:rounded lg:border-ui-gray-medium-light"
                          >
                            <li
                              role="presentation"
                              aria-hidden="true"
                              className="ui-arrow"
                            ></li>
                            <li>
                              <NavLink
                                title="My Policies Overview"
                                url="/policies"
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                closeMenu={close}
                                closeMobileMenu={closeMobileMenuNav}
                              />
                            </li>
                          </Popover.Panel>
                        </>
                      )}
                    </Popover>

                    <Popover as="li" className="group">
                      {({ close }) => (
                        <>
                          <Popover.Button
                            className={
                              currentPage.split("/")[1] === "payment"
                                ? "ui-current"
                                : ""
                            }
                          >
                            Payments
                          </Popover.Button>

                          <Popover.Panel
                            as="ul"
                            className="bg-white border-b lg:shadow lg:rounded lg:border-ui-gray-medium-light"
                          >
                            <li
                              role="presentation"
                              aria-hidden="true"
                              className="ui-arrow"
                            ></li>
                            <li>
                              <NavLink
                                title="Overview"
                                url="/payment"
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                closeMenu={close}
                                closeMobileMenu={closeMobileMenuNav}
                              />
                            </li>
                            <li>
                              <NavLink
                                title="Make a payment"
                                url="/payment/make-a-payment"
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                closeMenu={close}
                                closeMobileMenu={closeMobileMenuNav}
                              />
                            </li>
                            <li>
                              <NavLink
                                title={"Manage Payment Methods"}
                                url="/payment/manage-payments"
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                closeMenu={close}
                                closeMobileMenu={closeMobileMenuNav}
                              />
                            </li>
                            <li>
                              <NavLink
                                title="Billing Schedule, History &amp; Statements"
                                url="/payment/billing"
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                closeMenu={close}
                                closeMobileMenu={closeMobileMenuNav}
                              />
                            </li>
                          </Popover.Panel>
                        </>
                      )}
                    </Popover>

                    <Popover as="li" className="group">
                      {({ close }) => (
                        <>
                          <Popover.Button
                            className={
                              currentPage.split("/")[1] === "services-claims"
                                ? "ui-current"
                                : ""
                            }
                          >
                            Services &amp; Claims
                          </Popover.Button>

                          <Popover.Panel
                            as="ul"
                            className="bg-white border-b lg:shadow lg:rounded lg:border-ui-gray-medium-light"
                          >
                            <li
                              role="presentation"
                              aria-hidden="true"
                              className="ui-arrow"
                            ></li>
                            <li>
                              <NavLink
                                title="Services &amp; Claims Overview"
                                url="/services-claims"
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                closeMenu={close}
                                closeMobileMenu={closeMobileMenuNav}
                              />
                            </li>
                            <li>
                              <NavLink
                                title="Life Insurance Services"
                                url="/services-claims/services-life-insurance"
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                closeMenu={close}
                                closeMobileMenu={closeMobileMenuNav}
                              />
                            </li>
                            <li>
                              <NavLink
                                title="Annuity Services"
                                url="/services-claims/services-annuity"
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                closeMenu={close}
                                closeMobileMenu={closeMobileMenuNav}
                              />
                            </li>
                            <li>
                              <NavLink
                                title="Claims"
                                url="/services-claims/claims"
                                currentPage={currentPage}
                                setCurrentPage={setCurrentPage}
                                closeMenu={close}
                                closeMobileMenu={closeMobileMenuNav}
                              />
                            </li>
                          </Popover.Panel>
                        </>
                      )}
                    </Popover>
                    <li>
                      <NavLink
                        mainLink={true}
                        title="Alert Center"
                        url="/messages"
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        closeMobileMenu={closeMobileMenuNav}
                      />
                    </li>
                    <li>
                      <NavLink
                        mainLink={true}
                        title="Need Help?"
                        url="/help"
                        includeUrl="help"
                        currentPage={currentPage}
                        setCurrentPage={setCurrentPage}
                        closeMobileMenu={closeMobileMenuNav}
                      />
                    </li>
                  </ul>

                  {/* mobile menu utility bar icons */}
                  <div className="mt-8 lg:hidden">
                    <ul className="ui-disclosure-nav">
                      <li>
                        <button
                          className="ui-main-link"
                          onClick={() => setSiteSearchDialogIsOpen(true)}
                        >
                          <svg
                            className="ui-icon w-4.5 h-4.5 text-ui-blue mr-2"
                            focusable="false"
                            aria-hidden="true"
                            role="presentation"
                          >
                            <use href="#ui-icon-search"></use>
                          </svg>
                          Search
                        </button>
                      </li>
                      <li>
                        <a href="/" className="ui-main-link">
                          <svg
                            className="ui-icon w-4.5 h-4.5  text-ui-blue mr-2"
                            focusable="false"
                            aria-hidden="true"
                            role="presentation"
                          >
                            <use href="#ui-icon-chat"></use>
                          </svg>
                          Live Chat
                        </a>
                      </li>
                      <li>
                        <Link to="/profile" className="ui-main-link">
                          <div className="w-6 h-6 rounded-full overflow-hidden mr-2">
                            <img
                              src="/assets/img/profile-img.png"
                              alt="avatar"
                              className="w-6 h-auto"
                            />
                          </div>
                          My Profile
                        </Link>
                      </li>
                      <li
                        onKeyDown={(e) => {
                          if (
                            e.key === "Tab" &&
                            localStorage.getItem("isMobileHeaderMenuOpen") &&
                            localStorage.getItem("isMobileHeaderMenuOpen") ===
                              "true"
                          )
                            setTimeout(() => {
                              mobileMenuButton.current.focus();
                            }, 5);
                        }}
                      >
                        <a
                          href="/"
                          className="ui-main-link"
                          onClick={() => {
                            localStorage.removeItem("authenticated");
                            navigate("/");
                          }}
                        >
                          <svg
                            className="ui-icon w-4.5 h-4.5 text-ui-blue mr-2"
                            focusable="false"
                            aria-hidden="true"
                            role="presentation"
                          >
                            <use href="#ui-icon-signout"></use>
                          </svg>
                          Sign Out
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </nav>
            </div>
          </div>

          {/* Mobile/tablet Header Site Search */}

          <Transition appear show={sitesearchDialogIsOpen} as={Fragment}>
            <Dialog
              // open={sitesearchDialogIsOpen}
              as="div"
              initialFocus={searchDialogInitialFocusRef}
              onClose={() => setSiteSearchDialogIsOpen(false)}
              className="ui-dialogs"
            >
              {/* The backdrop, rendered as a fixed sibling to the panel container */}
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-500"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="dialog-backdrop z-[2000]" aria-hidden="true" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto  z-[2002]">
                <div className="flex min-h-full items-center justify-center p-4 text-center">
                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-500 transform"
                    enterFrom="-translate-x-100"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                  >
                    <Dialog.Panel className="ui-default-dialog z-[2001] left-auto">
                      <Dialog.Title className="font-body text-xl font-bold text-left">
                        Search
                      </Dialog.Title>
                      <button
                        id="ID_mobileDialogSearchCloseBtn"
                        className="ui-default-dialog-close"
                        onClick={() => setSiteSearchDialogIsOpen(false)}
                        aria-label="close search window"
                        ref={searchDialogInitialFocusRef}
                      >
                        <svg
                          className="ui-icon h-3.5 w-3.5"
                          focusable="false"
                          aria-hidden="true"
                          role="presentation"
                        >
                          <use href="#ui-icon-close"></use>
                        </svg>
                      </button>

                      <hr />

                      <Dialog.Description as="div">
                        <SearchForm focus={true} display="dialog" />
                      </Dialog.Description>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        </div>
      </header>
    </>
  );
}
