import Badge from "../General/Badge";
import Tooltip from "../General/Tooltip";
export default function ClaimStatusCard({ data }) {
  let stepOneClasses, stepTwoClasses, stepThreeClasses, stepFourClasses;
  switch (data.step) {
    case 1:
      stepOneClasses = "bg-theme-action text-white";
      stepTwoClasses =
        "border border-dashed border-theme-action text-theme-action";
      stepThreeClasses =
        "border border-dashed border-theme-action text-theme-action";
      stepFourClasses =
        "border border-dashed border-theme-action text-theme-action";
      break;

    case 2:
      stepOneClasses = "bg-ui-green text-white";
      stepTwoClasses = "bg-theme-action text-white";
      stepThreeClasses =
        "border border-dashed border-theme-action text-theme-action";
      stepFourClasses =
        "border border-dashed border-theme-action text-theme-action";
      break;

    case 3:
      stepOneClasses = "bg-ui-green text-white";
      stepTwoClasses = "bg-ui-green text-white";
      stepThreeClasses = "bg-theme-action text-white";
      stepFourClasses =
        "border border-dashed border-theme-action text-theme-action";
      break;

    case 4:
      stepOneClasses = "bg-ui-green text-white";
      stepTwoClasses = "bg-ui-green text-white";
      stepThreeClasses = "bg-ui-green text-white";
      stepFourClasses = "bg-theme-action text-white";
      break;
    default:
      stepOneClasses =
        "border border-dashed border-theme-action text-theme-action";
      stepTwoClasses =
        "border border-dashed border-theme-action text-theme-action";
      stepThreeClasses =
        "border border-dashed border-theme-action text-theme-action";
      stepFourClasses =
        "border border-dashed border-theme-action text-theme-action";
      break;
  }
  return (
    <div className="ui-card">
      <div className="flex flex-row items-start border-b border-theme-rule-light">
        <div className="p-4 flex-grow xl:p-6">
          <h2 className="text-lg font-body font-bold">Claim ID &amp; Status</h2>
        </div>
        <div className="p-4 xl:p-6">
          <Badge
            mediaSwitch={true}
            status={data.status}
            statusText={data.statusText}
            srPrefix="Claim status"
          />
        </div>
      </div>
      <div className="p-4 xl:p-6">
        <div className="grid grid-cols-2 gap-4 md:grid-cols-4 md:gap-6">
          <div>
            <div className="text-xs text-theme-text-tertiary mb-1">Claim ID</div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              {data.id}
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary mb-1">Policy Number</div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              {data.policyNumber}
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary mb-1">Claim Type</div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              {data.claimType}
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary mb-1">Date Submitted</div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              {data.dateSubmitted}
            </div>
          </div>
        </div>
      </div>
      <hr className="my-4" />
      <div className="p-4 pt-0 xl:p-6 xl:pt-0">
        <div className="text-lg">
          Where you are in the process:
        </div>

        <div className="flex justify-center mt-4 lg:mt-6">
          <div className="inline-grid grid-cols-1 md:grid-cols-4 justify-items-stretch content-center text-center">
            <div className="ui-claim-process-start relative">
              <div
                className={`ui-claim-process-step bg-white ${
                  data.step === 1 ? "ui-claim-process-step-current" : ""
                }`}
              >
                <div
                  className={`w-12 h-12 rounded-full flex items-center justify-center ${stepOneClasses}`}
                >
                  <svg
                    className="ui-icon w-4 h-4"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-edit"></use>
                  </svg>
                </div>
              </div>
              <div className="text-xs">
                File a claim
                <Tooltip
                  content="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                  xMargin="mx-1"
                />
              </div>
            </div>

            <div className="ui-claim-process-middle relative">
              <div
                className={`ui-claim-process-step bg-white ${
                  data.step === 2 ? "ui-claim-process-step-current" : ""
                }`}
              >
                <div
                  className={`w-12 h-12 rounded-full flex items-center justify-center ${stepTwoClasses}`}
                >
                  <svg
                    className="ui-icon w-4 h-4"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-apply"></use>
                  </svg>
                </div>
              </div>
              <div className="text-xs">
                Complete claims packet
                <Tooltip
                  content="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                  xMargin="mx-1"
                />
              </div>
            </div>

            <div className="ui-claim-process-middle relative">
              <div
                className={`ui-claim-process-step bg-white ${
                  data.step === 3 ? "ui-claim-process-step-current" : ""
                }`}
              >
                <div
                  className={`w-12 h-12 rounded-full flex items-center justify-center ${stepThreeClasses}`}
                >
                  <svg
                    className="ui-icon w-4 h-4"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-search"></use>
                  </svg>
                </div>
              </div>
              <div className="text-xs">
                Track claim
                <Tooltip
                  content="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                  xMargin="mx-1"
                />
              </div>
            </div>

            <div className="ui-claim-process-last relative">
              <div
                className={`ui-claim-process-step bg-white ${
                  data.step === 4 ? "ui-claim-process-step-current" : ""
                }`}
              >
                <div
                  className={`w-12 h-12 rounded-full flex items-center justify-center ${stepFourClasses}`}
                >
                  <svg
                    className="ui-icon w-4 h-4"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-edit"></use>
                  </svg>
                </div>
              </div>
              <div className="text-xs">
                Receive payment
                <Tooltip
                  content="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
                  xMargin="mx-1"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
