import { Link, useParams } from "react-router-dom";
import { useCollapsePanel } from "../../../hooks/useCollapsePanel";
import { v4 as uuidv4 } from "uuid";
export default function BeneficiariesCard({ data }) {
  const { policy_id } = useParams();
  const uuid = uuidv4();
  useCollapsePanel(uuid);
  return (
    <div className="ui-card mb-4 md:mb-6">
      <div className="md:flex md:flex-row md:items-start">
        <div className="px-4 pt-3 md:flex-grow xl:px-6 xl:pt-6">
          <h2 className="mb-0">
            <button
              id={uuid}
              className="flex items-center group md:hidden"
              aria-controls={`${uuid}-panel`}
              aria-expanded="true"
              aria-label="Expand or collapse Beneficiaries details"
            >
              <span className="inline-block p-1 rounded-full mr-2 text-ui-blue group-hover:text-white group-hover:bg-ui-blue">
                <svg
                  className="ui-icon ui-plus w-4 h-4"
                  focusable="false"
                  aria-hidden="true"
                  role="presentation"
                >
                  <use href="#ui-icon-plus"></use>
                </svg>
                <svg
                  className="ui-icon ui-minus w-4 h-4"
                  focusable="false"
                  aria-hidden="true"
                  role="presentation"
                >
                  <use href="#ui-icon-minus"></use>
                </svg>
              </span>
              <span className="text-base font-body font-bold md:ui-no-badge-icon-inline">
                Beneficiaries
              </span>
            </button>
            <span className="text-xl font-body font-bold hidden md:block">
              Beneficiaries
            </span>
          </h2>
        </div>
        <hr className="mb-0 mt-3 md:hidden" />
        <div className="px-4 text-sm md:justify-self-end xl:px-6 xl:pt-6">
          <Link to={`/policies/beneficiaries/${policy_id}`}>
            View Beneficiary Details
          </Link>
        </div>
      </div>
      <hr className="hidden md:block" />
      <div
        id={`${uuid}-panel`}
        className="px-4 pb-4 overflow-hidden h-20 mt-4 md:h-auto md:mt-0 xl:px-6 xl:pb-6"
      >
        {/* <!-- Beneficiaries Details --> */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 xl:gap-6">
          <div>
            <div className="text-xs text-theme-text-tertiary">
              Primary Beneficiaries
            </div>
            <div className="text-sm text-theme-text-secondary font-semibold">
              {data.primary}
            </div>
          </div>
          {data.contingent.length > 0 && (
            <div>
              <div className="text-xs text-theme-text-tertiary">
                Contingent Beneficiaries
              </div>
              {data.contingent.map((name, idx) => {
                return (
                  <div
                    className="text-sm text-theme-text-secondary font-semibold"
                    key={`contingent - ${idx}`}
                  >
                    {name}
                  </div>
                );
              })}
            </div>
          )}
        </div>
        {/* <!-- Beneficiaries Details --> */}
      </div>
    </div>
  );
}
