import { Dialog } from "@headlessui/react";
import { v4 as uuidv4 } from "uuid";

const AgentNotesEditModal = ({ isOpenEditNote, setIsOpenEditNote }) => {
  const noteId = uuidv4();
  return (
    <>
      <Dialog
        open={isOpenEditNote}
        onClose={() => setIsOpenEditNote(false)}
        className="ui-dialogs"
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel
            role="dialog"
            aria-modal="true"
            className="ui-default-dialog"
          >
            <Dialog.Title className="font-body text-xl font-bold text-left">
              Edit Note
            </Dialog.Title>
            <button
              className="ui-default-dialog-close"
              aria-label="close add note window"
              onClick={() => setIsOpenEditNote(false)}
            >
              <svg
                className="ui-icon h-3.5 w-3.5"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href="#ui-icon-close"></use>
              </svg>
            </button>
            <p className="text-xs">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
              tincidunt mi sit amet ipsum tempus interdum.
            </p>
            <hr />
            <Dialog.Description>
              <span className="sr-only">
                Use this window to add new agent note.
              </span>
            </Dialog.Description>
            <div className="mx-auto md:w-4/5 xl:w-3/5">
              <form className="grid grid-cols-1 gap-4">
                <div>
                  <label forHTML={`${noteId}-category`} className="mb-1 block">
                    Note Category
                  </label>
                  <select id={`${noteId}-category`}>
                    <option>Payments</option>
                    <option>Contract Change</option>
                    <option>Client Policy Questions</option>
                  </select>
                </div>
                <div>
                  <label forHTML={`${noteId}-title`} className="mb-1 block">
                    Title of note
                  </label>
                  <input
                    type="text"
                    id={`${noteId}-title`}
                    value="Paid on loan"
                  />
                </div>
                <div>
                  <label forHTML={`${noteId}-note`} className="mb-1 block">
                    Note
                  </label>
                  <textarea
                    type="text"
                    id={`${noteId}-note`}
                    rows="4"
                    value="Lorem ipsum dolor sit amet, eam ad autem fabulas. Expetenda dissentiet"
                  />
                </div>
                <div className="block text-right">
                  <button
                    className="ui-btn-secondary ui-btn-min-w mt-4"
                    onClick={() => setIsOpenEditNote(false)}
                  >
                    Cancel
                    <span className="sr-only"> adding note</span>
                  </button>
                  <button
                    className="ui-btn-primary ui-btn-min-w mt-4"
                    onClick={() => setIsOpenEditNote(false)}
                  >
                    Save
                    <span className="sr-only"> note</span>
                  </button>
                </div>
              </form>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

export default AgentNotesEditModal;
