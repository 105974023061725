import { Link } from "react-router-dom";

export default function FeaturedCard({ data }) {
  return (
    <div className="ui-card">
      <img
        src={data.videoThumbnail}
        className="w-full h-auto max-w-none rounded-t"
        alt=""
      />
      <div className="p-4">
        <div className="font-semibold">Featured Help Article</div>
        <div className="text-xxs mt-4">
          {data.article}
          <Link to={`/help/article/${data.id}`}>read more</Link>
        </div>
        <Link to={`/help/article/${data.id}`} className="mt-4 ui-btn-primary">
          View Featured Item
        </Link>
      </div>
    </div>
  );
}
