// route: /help/search-results
export const results = [
  {
    id: "1",
    publishedOn: "01/01/2023",
    tags: ["Knowledge base article", "Sales & training", "Video"],
    title: "It's Tax Season. Your Statements Are - 1",
    article:
      " Your year-end tax statement is now available to download. Additional copy for this example of a temporary homepage message goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam pharetra placerat elit at feugiat. Maecenas…",
    ctaText: "Call to Action",
    ctaUrl: "/help",
  },
  {
    id: "2",
    publishedOn: "01/01/2023",
    tags: ["Knowledge base article", "Sales & training", "Video"],
    title: "It's Tax Season. Your Statements Are - 2",
    article:
      " Your year-end tax statement is now available to download. Additional copy for this example of a temporary homepage message goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam pharetra placerat elit at feugiat. Maecenas…",
  },
  {
    id: "3",
    publishedOn: "01/01/2023",
    tags: ["Knowledge base article", "Sales & training", "Video"],
    title: "It's Tax Season. Your Statements Are - 3",
    article:
      " Your year-end tax statement is now available to download. Additional copy for this example of a temporary homepage message goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam pharetra placerat elit at feugiat. Maecenas…",
    videoThumbnail: "/assets/img/video-placeholder1.png",
    videoUrl: "/assets/img/video-placeholder1.png",
  },
  {
    id: "4",
    publishedOn: "01/01/2023",
    tags: ["Knowledge base article", "Sales & training", "Video"],
    title: "It's Tax Season. Your Statements Are - 4",
    article:
      " Your year-end tax statement is now available to download. Additional copy for this example of a temporary homepage message goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam pharetra placerat elit at feugiat. Maecenas…",
    ctaText: "Call to Action",
    ctaUrl: "/help",
  },
  {
    id: "5",
    publishedOn: "01/01/2023",
    tags: ["Knowledge base article", "Sales & training", "Video"],
    title: "It's Tax Season. Your Statements Are - 5",
    article:
      " Your year-end tax statement is now available to download. Additional copy for this example of a temporary homepage message goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam pharetra placerat elit at feugiat. Maecenas…",
    ctaText: "Call to Action",
    ctaUrl: "/help",
  },
  {
    id: "6",
    publishedOn: "01/01/2023",
    tags: ["Knowledge base article", "Sales & training", "Video"],
    title: "It's Tax Season. Your Statements Are - 6",
    article:
      " Your year-end tax statement is now available to download. Additional copy for this example of a temporary homepage message goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam pharetra placerat elit at feugiat. Maecenas…",
  },
  {
    id: "7",
    publishedOn: "01/01/2023",
    tags: ["Knowledge base article", "Sales & training", "Video"],
    title: "It's Tax Season. Your Statements Are - 7",
    article:
      " Your year-end tax statement is now available to download. Additional copy for this example of a temporary homepage message goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam pharetra placerat elit at feugiat. Maecenas…",
  },
  {
    id: "8",
    publishedOn: "01/01/2023",
    tags: ["Knowledge base article", "Sales & training", "Video"],
    title: "It's Tax Season. Your Statements Are - 8",
    article:
      " Your year-end tax statement is now available to download. Additional copy for this example of a temporary homepage message goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam pharetra placerat elit at feugiat. Maecenas…",
  },
  {
    id: "9",
    publishedOn: "01/01/2023",
    tags: ["Knowledge base article", "Sales & training", "Video"],
    title: "It's Tax Season. Your Statements Are - 9",
    article:
      " Your year-end tax statement is now available to download. Additional copy for this example of a temporary homepage message goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam pharetra placerat elit at feugiat. Maecenas…",
  },
  {
    id: "10",
    publishedOn: "01/01/2023",
    tags: ["Knowledge base article", "Sales & training", "Video"],
    title: "It's Tax Season. Your Statements Are - 10",
    article:
      " Your year-end tax statement is now available to download. Additional copy for this example of a temporary homepage message goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam pharetra placerat elit at feugiat. Maecenas…",
    ctaText: "Call to Action",
    ctaUrl: "/help",
  },
  {
    id: "11",
    publishedOn: "01/01/2023",
    tags: ["Knowledge base article", "Sales & training", "Video"],
    title: "It's Tax Season. Your Statements Are - 11",
    article:
      " Your year-end tax statement is now available to download. Additional copy for this example of a temporary homepage message goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam pharetra placerat elit at feugiat. Maecenas…",
  },
  {
    id: "12",
    publishedOn: "01/01/2023",
    tags: ["Knowledge base article", "Sales & training", "Video"],
    title: "It's Tax Season. Your Statements Are - 12",
    article:
      " Your year-end tax statement is now available to download. Additional copy for this example of a temporary homepage message goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam pharetra placerat elit at feugiat. Maecenas…",
  },
  {
    id: "13",
    publishedOn: "01/01/2023",
    tags: ["Knowledge base article", "Sales & training", "Video"],
    title: "It's Tax Season. Your Statements Are - 13",
    article:
      " Your year-end tax statement is now available to download. Additional copy for this example of a temporary homepage message goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam pharetra placerat elit at feugiat. Maecenas…",
  },
  {
    id: "14",
    publishedOn: "01/01/2023",
    tags: ["Knowledge base article", "Sales & training", "Video"],
    title: "It's Tax Season. Your Statements Are - 14",
    article:
      " Your year-end tax statement is now available to download. Additional copy for this example of a temporary homepage message goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam pharetra placerat elit at feugiat. Maecenas…",
  },
];

export const featured = [
  {
    id: "1",
    title: "Featured Help Article",
    videoThumbnail: "/assets/img/video-placeholder1.png",
    videoUrl: "/assets/img/video-placeholder1.png - 1",
    article:
      " Your year-end tax statement is now available to download. Additional copy for this example of a temporary homepage message goes here. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam pharetra placerat elit at feugiat. Maecenas…",
    ctaText: "Call to Action",
    ctaUrl: "/help",
  },
];
