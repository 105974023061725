import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { cardArray } from "../../js/placeholderData/managePaymentData";
import AddPaymentCard from "./AddPaymentCard";
import AddPaymentEFT from "./AddPaymentEFT";
import DialogBackdrop from "./DialogBackdrop";

const AddPaymentMethodModal = ({
  modalState,
  setModalState,
  config,
  setConfig,
  onSubmit
}) => {
  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    shouldUnregister: true,
  });

  const [autopay, setAutopay] = useState(false);
  const [savePayemnt, setSavePayment] = useState(false);
  const [paymentSelection, setPaymentSelection] = useState("Credit Card");

  const closeModal = () => {
    setModalState(false);
    setPaymentSelection("Credit Card");
    setConfig("Update");
  };

  return (
    <Transition appear show={modalState} as={Fragment}>
      <Dialog
        className="fixed inset-0 z-10 overflow-y-auto"
        as="div"
        open={modalState}
        onClose={() => closeModal()}
      >
        <DialogBackdrop />

        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>
          {/* This element is to trick the browser into centering the modal contents. */}

          <span
            className="inline-block h-screen align-middle"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
          >
            <div className="inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title
                as="div"
                className="dialog_label2 font-body text-xl font-bold text-left"
              >
                {config} payment method
                <button
                  className="ui-default-dialog-close"
                  onClick={() => setModalState(false)}
                >
                  <svg
                    className="ui-icon h-3.5 w-3.5"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-close"></use>
                  </svg>
                </button>
              </Dialog.Title>
              <hr />
              <div className="mt-2">
                <p className="text-sm">
                  This is a description of the first step of billing, where we
                  tell the user they can pick the pending.
                </p>
              </div>
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="grid grid-cols-1 md:grid-cols-2 grid-flow-row gap-4 mt-4 xl:mt-6">
                  <div className="md:col-span-2">
                    <label
                      id="ID_paymentMethodLabel"
                      htmlFor="ID_paymentMethod"
                    >
                      Payment method:
                    </label>
                    <div className="">
                      <select
                        id="ID_paymentMethod"
                        aria-labelledby="ID_paymentMethodLabel"
                        aria-describedby="ID_paymentMethodError"
                        onChange={(e) => setPaymentSelection(e.target.value)}
                      >
                        {cardArray.map((item, idx) => (
                          <option key={idx} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {paymentSelection === "Credit Card" && (
                    <AddPaymentCard
                      register={register}
                      errors={errors}
                      watch={watch}
                      control={control}
                    />
                  )}

                  {paymentSelection === "EFT (Electronic Funds Transfer)" && (
                    <AddPaymentEFT
                      register={register}
                      errors={errors}
                      watch={watch}
                    />
                  )}
                </div>

                <div className="mt-6">
                  <label className="ui-checkbox">
                    <input
                      type="checkbox"
                      value={savePayemnt}
                      onClick={() => setSavePayment(!savePayemnt)}
                    />
                    <div className="ui-checkmark">
                      <svg
                        className="ui-icon w-2 h-2 text-white"
                        focusable="false"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <use href="#ui-icon-checkmark"></use>
                      </svg>
                    </div>
                    <span>Save this payment method to my account wallet</span>
                  </label>
                  <label className="ui-checkbox">
                    <input
                      type="checkbox"
                      value={autopay}
                      onClick={() => setAutopay(!autopay)}
                    />
                    <div className="ui-checkmark focus-visible">
                      <svg
                        className="ui-icon w-2 h-2 text-white"
                        focusable="false"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <use href="#ui-icon-checkmark"></use>
                      </svg>
                    </div>
                    <span>Enroll in autopay with this payment method</span>
                  </label>
                </div>
                <div className="block text-right mt-6">
                  <button
                    type="button"
                    className="ui-btn-secondary min-w-[140px] mt-4"
                    aria-label="cancel new payment method"
                    onClick={() => closeModal()}
                  >
                    Cancel
                  </button>
                  <button
                    id="ID_submitPaymentMethod"
                    className="ui-btn-primary min-w-[140px] mt-4"
                    aria-label="add new payment method"
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default AddPaymentMethodModal;
