import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { Link } from "react-router-dom";

export default function HelpArticle() {
  const [isOpenShare, setIsOpenShare] = useState(false);

  return (
    <>
      {/* SPRINT-3-CUSTOMER-claims-and-forms.pdf (pgs 20-22) */}
      <div className="container mb-8">
        <h1>What to Expect When Filing a Death Claim</h1>
        <p>
          Lorem ipsum dolor sit amet, eam ad autem fabulas. Expetenda dissentiet
        </p>
        <hr />
      </div>

      {/* Icon bar - share, download, favorite */}
      <div className="container mb-3">
        <div className="flex justify-end">
          <button
            aria-label="Share this article on social media"
            className="ui-btn-icon h-7 w-7 rounded-full ml-2 flex items-center justify-center"
            onClick={() => setIsOpenShare(true)}
          >
            <svg
              className="ui-icon w-4 h-4 "
              focusable="false"
              aria-hidden="true"
              role="presentation"
            >
              <use href="#ui-icon-share"></use>
            </svg>
          </button>
          <button
            aria-label="export this article"
            className="ui-btn-icon h-7 w-7 rounded-full ml-2 flex items-center justify-center"
          >
            <svg
              className="ui-icon w-4 h-4 "
              focusable="false"
              aria-hidden="true"
              role="presentation"
            >
              <use href="#ui-icon-external-link"></use>
            </svg>
          </button>
          <button
            aria-label="mark this article as favorite"
            className="ui-btn-icon h-7 w-7 rounded-full ml-2 flex items-center justify-center"
          >
            <svg
              className="ui-icon w-4 h-4 "
              focusable="false"
              aria-hidden="true"
              role="presentation"
            >
              <use href="#ui-icon-heart"></use>
            </svg>
          </button>
        </div>
      </div>

      <div className="container ui-component-spacing">
        <div className="grid gap-4 grid-cols-1 lg:grid-cols-[66.666667%_1fr] xl:gap-6">
          <div>
            <div className="grid grid-cols-1 gap-4 xl:gap-6">
              {/* Step 1 */}
              <div className="ui-card p-4 xl:p-6">
                <h2 className="text-xl font-body font-bold">
                  Step 1: Before You File: Gather Information
                </h2>
                <hr />
                <div className="text-sm">
                  <p className="font-semibold">
                    To start a claim, you'll need the following information
                    about the policyholder:
                  </p>
                  <ul className="list-disc ml-6 mt-2">
                    <li>
                      Policy Number (if available) - if there are multiple
                      policies that the deceased was insured with us, you only
                      need to enter one. We will automatically process the claim
                      for all applicable policies.
                    </li>
                    <li>Full Name</li>
                    <li>Social Security Number</li>
                    <li>Date of Birth</li>
                    <li>Date of Death</li>
                    <li>Place of Death</li>
                    <li>
                      Policyholder's Original, Certified Death Certificate
                    </li>
                    <li>
                      To complete this information online, click{" "}
                      <Link to="#">here</Link>.
                    </li>
                  </ul>
                </div>
              </div>
              {/* Step 2 */}
              <div className="ui-card p-4 xl:p-6">
                <h2 className="text-xl font-body font-bold">
                  Step 2: Start Your Claim
                </h2>
                <hr />
                <div className="mt-6 text-sm grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-6">
                  <div>
                    <div className="text-base font-semibold">By Phone</div>
                    <div className="mt-2">
                      Call the life claim department at{" "}
                      <a href="tel:1-844-953-0347">1-844-953-0347</a> to start
                      your claim over the phone
                    </div>
                  </div>
                  <div>
                    <div className="text-base font-semibold">By Fax</div>
                    <div className="mt-2">
                      Download and fill out our claim notification form and fax
                      it to 1-833-636-0034
                    </div>
                  </div>
                  <div>
                    <div className="text-base font-semibold">By Email</div>
                    <div className="mt-2">
                      Download and fill out our claim notification form and
                      email it to{" "}
                      <a href="mailto:LifeClaimReports@allstate.com">
                        LifeClaimReports@allstate.com
                      </a>
                    </div>
                  </div>
                  <div>
                    <div className="text-base font-semibold">By Mail</div>
                    <div className="mt-2">
                      <p>
                        Download and fill out our claim notification form and
                        mail it to:
                      </p>
                      <br />
                      <p>
                        P.O. Box 83328
                        <br />
                        Lincoln, NE 68501
                        <br />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Step 3 */}
              <div className="ui-card p-4 xl:p-6">
                <h2 className="text-xl font-body font-bold">
                  Step 3: Claims Packet
                </h2>
                <hr />
                <div className="text-sm">
                  Once your claim is started, you will receive a packet via your
                  selected method, either email or mail that includes a claimant
                  statement form you must fill out.The packet will also include
                  detailed instructions on what to do next.
                </div>
              </div>
              {/* Step 4 */}
              <div className="ui-card p-4 xl:p-6">
                <h2 className="text-xl font-body font-bold">
                  Step 4: Complete Your Claims Packet
                </h2>
                <hr />
                <div className="mt-2 text-sm">
                  Complete the required claimant statement form and it back to
                  us with a certified copy of the death certificate. Your claim
                  representative will let you know if you're required to provide
                  additional documents or fill out additional forms.
                </div>
                <div className="mt-6 text-sm grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-6">
                  {/* <div>
                    <div className="text-base font-semibold">
                      Complete Packet Online
                    </div>
                    <div className="mt-2">
                      <p>
                        Text explaining ease and benefits of filing online goes
                        here.
                      </p>
                    </div>
                  </div> */}
                  <div>
                    <div className="text-base font-semibold">By Mail</div>
                    <div className="mt-2">
                      <p>
                        Mail to:
                        <br />
                        P.O. Box 83328
                        <br />
                        Lincoln, NE 68501
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="text-base font-semibold">Overnight:</div>
                    <div className="mt-2">
                      <p>
                        Mail to:
                        <br />
                        777 Research Dr.
                        <br />
                        Lincoln, NE 68501
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Step 5 */}
              <div className="ui-card p-4 xl:p-6">
                <h2 className="text-xl font-body font-bold">
                  Step 5: Track Your Claim
                </h2>
                <hr />
                <div className="text-sm">
                  We've made it easy for you to track your claim online. Along
                  with the status of your claim, you will also see a high-level
                  view of your claim including confirmation of receipt of claim
                  and confirmation of receipt of completed claim packet. To view
                  the status of a claim click{" "}
                  <Link to="/services-claims">here</Link>.
                </div>
                <div className="mt-6 text-sm grid grid-cols-1 gap-4 lg:grid-cols-3 lg:gap-6">
                  <div>
                    <div className="text-base font-semibold">Online</div>
                    <div className="mt-2">
                      <p>
                        Text explaining ease and benefits of tracking online
                        goes here.
                      </p>
                    </div>
                  </div>
                  <div>
                    <div className="text-base font-semibold">By Phone</div>
                    <div className="mt-2">
                      <p>
                        Call the life claim department at 1-800-366-3495 to
                        start your claim over the phone.
                      </p>
                    </div>
                  </div>
                  <div></div>
                </div>
              </div>
              {/* Step 6 */}
              <div className="ui-card p-4 xl:p-6">
                <h2 className="text-xl font-body font-bold">
                  Step 6: Receive Your Payment
                </h2>
                <hr />
                <div className="text-sm">
                  Most claims are processed within X-X business days after
                  receiving your completed claim form and related documents.
                  Beneficiaries usually receive payment shortly thereafter. Some
                  claims may take longer. Once your claim is approved, you can
                  decide how you'd like to receive the life insurance benefit.
                  We can mail you a check or issue a direct deposit.
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="grid grid-cols-1 gap-4 xl:gap-6">
              <div className="ui-card text-sm p-4 md:p-6">
                <h2 className="font-body text-sm font-semibold">
                  Claims Support
                </h2>
                <hr className="my-4" />
                <p>
                  Our insurance professionals can help guide you through each
                  step of the process, help explain your policy and provide
                  compassionate support.
                </p>
                <br />
                <div className="text-base font-semibold">Live Chat</div>
                <br />
                <p>
                  Info about live chat feature and support goes here Lorem ipsum
                  dolor.
                </p>
                <br />
                <button className="ui-btn-primary">Live Chat Now</button>
                <br />
                <br />
                <div className="text-base font-semibold">By Phone</div>
                <br />
                <p>
                  Call the life claim department at 1-800-366-3495 to start your
                  claim over the phone.
                </p>
              </div>
              <div className="ui-card text-sm p-4 xl:p-6">
                <h2 className="font-body text-sm font-semibold">
                  Survivor Resources
                </h2>
                <hr className="my-4" />
                <p>
                  It may be helpful after losing a loved one to know there are
                  resources you can use for assistance.
                </p>
                <br />
                <p className="font-semibold">Support groups:</p>
                <br />
                <p>
                  There are plenty of groups that can provide a safe place to
                  talk about grief and help you discuss your emotions. When
                  looking for one of these groups be sure to consider the
                  funeral home, family and friends, your place of worship, your
                  employer or military services office. These groups are a good
                  place to start when looking for groups to support you in the
                  coming weeks and months.
                </p>
                <br />
                <p>
                  The support groups in addition to public libraries and your
                  bereavement that will assist you.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="container ui-component-spacing">
        <div className="text-xl font-bold">Share This Article</div>
        <div className="mt-4 grid gap-2 grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 xl:grid-cols-8">
          <button
            aria-label="Share this article on social media"
            className="ui-card m-0 flex flex-col items-center p-3 no-underline group hover:bg-theme-action"
            onClick={() => setIsOpenShare(true)}
          >
            <span className="block text-theme-action group-hover:text-white">
              <svg
                className="ui-icon w-4 h-4 mx-auto"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href="#ui-icon-share"></use>
              </svg>
            </span>
            <span className="mt-1 block text-xs font-semibold whitespace-nowrap group-hover:text-white group-hover:underline">
              Share On Social
            </span>
          </button>
          <button
            aria-label="export this article"
            className="ui-card m-0 flex flex-col items-center p-3 no-underline group hover:bg-theme-action"
          >
            <span className="block text-theme-action group-hover:text-white">
              <svg
                className="ui-icon w-4 h-4 mx-auto"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href="#ui-icon-external-link"></use>
              </svg>
            </span>
            <span className="mt-1 block text-xs font-semibold whitespace-nowrap group-hover:text-white group-hover:underline">
              Export
            </span>
          </button>
          <button
            aria-label="mark this article as favorite"
            className="ui-card m-0 flex flex-col items-center p-3 no-underline group hover:bg-theme-action"
          >
            <span className="block text-theme-action group-hover:text-white">
              <svg
                className="ui-icon w-4 h-4 mx-auto"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href="#ui-icon-heart"></use>
              </svg>
            </span>
            <span className="mt-1 block text-xs font-semibold whitespace-nowrap group-hover:text-white group-hover:underline">
              Favorite
            </span>
          </button>
        </div>
      </div>

      <Dialog
        open={isOpenShare}
        onClose={() => setIsOpenShare(false)}
        className="ui-dialogs"
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel
            role="dialog"
            aria-modal="true"
            className="ui-default-dialog"
          >
            <Dialog.Title className="font-body text-xl font-bold text-left">
              Share
            </Dialog.Title>
            <button
              className="ui-default-dialog-close"
              aria-label="close edit photo window"
              onClick={() => setIsOpenShare(false)}
            >
              <svg
                className="ui-icon h-3.5 w-3.5"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href="#ui-icon-close"></use>
              </svg>
            </button>
            <Dialog.Description>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </Dialog.Description>
            <hr />
            <div className="my-4 flex justify-center">
              {/* twitter */}
              <button
                aria-label="share this article on twitter"
                className="ui-btn-icon mx-2 flex items-center justify-center w-12 h-12 rounded-full text-[#1DA1F2] border border-theme-rule-medium"
              >
                <svg
                  className="ui-icon h-6 w-6 mx-auto"
                  focusable="false"
                  aria-hidden="true"
                  role="presentation"
                >
                  <use href="#ui-icon-twitter"></use>
                </svg>
              </button>
              {/* instagram */}
              <button
                aria-label="share this article on instagram"
                className="ui-btn-icon group mx-2 flex items-center justify-center w-12 h-12 rounded-full border border-theme-rule-medium"
              >
                <img
                  src="/assets/img/icon-instagram-color.svg"
                  className="h-5 w-5 mx-auto block group-hover:hidden"
                  aria-hidden="true"
                  role="presentation"
                />
                <img
                  src="/assets/img/icon-instagram-white.svg"
                  className="h-5 w-5 mx-auto hidden group-hover:block"
                  aria-hidden="true"
                  role="presentation"
                />
              </button>
              {/* facebook */}
              <button
                aria-label="share this article on facebook"
                className="ui-btn-icon mx-2 flex items-center justify-center w-12 h-12 rounded-full text-[#3B5998] border border-theme-rule-medium"
              >
                <svg
                  className="ui-icon h-6 w-6 mx-auto"
                  focusable="false"
                  aria-hidden="true"
                  role="presentation"
                >
                  <use href="#ui-icon-facebook"></use>
                </svg>
              </button>

              {/* what's app */}
              <button
                aria-label="share this article by what's app"
                className="ui-btn-icon mx-2 flex items-center justify-center w-12 h-12 rounded-full text-[#4FCE5D] border border-theme-rule-medium"
              >
                <svg
                  className="ui-icon h-5 w-5 mx-auto"
                  focusable="false"
                  aria-hidden="true"
                  role="presentation"
                >
                  <use href="#ui-icon-whatsapp"></use>
                </svg>
              </button>
              {/* email */}
              <button
                aria-label="share this article by email"
                className="ui-btn-icon mx-2 flex items-center justify-center w-12 h-12 rounded-full border border-theme-rule-medium"
              >
                <svg
                  className="ui-icon h-5 w-5 mx-auto mb-1"
                  focusable="false"
                  aria-hidden="true"
                  role="presentation"
                >
                  <use href="#ui-icon-message"></use>
                </svg>
              </button>
            </div>
            <div className="ui-dialog-body">
              <div className="block text-sm mb-1 font-normal">Or copy link</div>
              <div className="flex">
                <input
                  id="ID_shareLink"
                  className="border border-ui-gray-medium h-9 rounded-r-none"
                  aria-label="Copy link to this article"
                  placeholder="Looking for something?"
                  value="http//:www.everlakeportal.com/articles/Article_Title"
                  readOnly={true}
                />
                <button className="ui-btn-primary rounded-l-none m-0">
                  Copy
                </button>
              </div>
            </div>
            <div className="block text-right mt-6">
              <button
                className="ui-btn-secondary ui-btn-min-w mt-4"
                onClick={() => setIsOpenShare(false)}
              >
                Close
                <span className="sr-only"> your account photo </span>
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
}
