import { Dialog } from "@headlessui/react";
import { v4 as uuid } from "uuid";
import { useForm } from "react-hook-form";
import { useEffect } from "react";
import { userData } from "../../../js/placeholderData/profile";
import FormInputError from "../../General/FormInputError";
import DialogBackdrop from "../../ModalDialogs/DialogBackdrop";
export default function UserInfoDialog({ dialogState, setDialogState }) {
  const UUID = uuid();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: userData,
  });

  const submitUserData = (formData) => {
    alert(`Submitting user date -- ${JSON.stringify(formData)}`);
    setDialogState(false);
  };

  useEffect(() => {
    reset(userData);
  }, [dialogState]);

  return (
    <Dialog
      open={dialogState}
      onClose={() => setDialogState(false)}
      className="ui-dialogs"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <DialogBackdrop />
      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel
          role="dialog"
          aria-modal="true"
          className="ui-default-dialog"
        >
          <Dialog.Title className="font-body text-xl font-bold text-left">
            Contact Information
          </Dialog.Title>
          <button
            className="ui-default-dialog-close"
            aria-label="close update contact information window"
            onClick={() => setDialogState(false)}
          >
            <svg
              className="ui-icon h-3.5 w-3.5"
              focusable="false"
              aria-hidden="true"
              role="presentation"
            >
              <use href="#ui-icon-close"></use>
            </svg>
          </button>
          <Dialog.Description>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
            tincidunt mi sit amet ipsum tempus interdum. Maecenas non enim
            lectus.
          </Dialog.Description>
          <hr />

          <form onSubmit={handleSubmit(submitUserData)}>
            <div className="w-full mx-auto grid grid-cols-1 gap-4 sm:w-4/5 md:w-3/4 lg:w-3/5">
              {/* Email */}
              <div>
                <label
                  id={`${UUID}-email-label`}
                  htmlFor={`${UUID}-email`}
                  className="block mb-1"
                >
                  Email
                </label>
                <input
                  id={`${UUID}-email`}
                  aria-labelledby={`${UUID}-email-label`}
                  type="text"
                  placeholder="Please enter a email"
                  {...register("email")}
                />
              </div>
              {/* phone */}
              <div>
                <label
                  id={`${UUID}-phone-label`}
                  htmlFor={`${UUID}-phone`}
                  className="block mb-1"
                >
                  Phone Number
                </label>
                <input
                  id={`${UUID}-phone`}
                  aria-labelledby={`${UUID}-phone-label`}
                  type="text"
                  placeholder="XXX-XXX-XXXX"
                  {...register("homePhone")}
                />
              </div>
              {/* Mobile */}
              <div>
                <label
                  id={`${UUID}-mobile-label`}
                  htmlFor={`${UUID}-mobile`}
                  className="block mb-1"
                >
                  Mobile Number *
                </label>
                <input
                  id={`${UUID}-mobile`}
                  aria-labelledby={`${UUID}-mobile-label`}
                  aria-describedby={`${UUID}-mobile-help`}
                  type="text"
                  placeholder="XXX-XXX-XXXX"
                  {...register("mobilePhone", {
                    required: "Please enter a phone number before proceeding.",
                  })}
                />
                <div
                  id={`${UUID}-mobile-help`}
                  className="text-xs text-theme-text-tertiary"
                >
                  * Add a mobile number to receive text notifications.
                </div>
                {errors.mobilePhone?.message && (
                  <FormInputError errorMessage={errors.mobilePhone.message} />
                )}
              </div>
              {/* Address */}
              <div>
                <label
                  id={`${UUID}-address-label`}
                  htmlFor={`${UUID}-address`}
                  className="block mb-1"
                >
                  Address Number
                </label>
                <input
                  id={`${UUID}-address`}
                  aria-labelledby={`${UUID}-address-label`}
                  type="text"
                  {...register("streetAddress")}
                />
              </div>
              <div className="grid grid-cols-1 gap-4 md:grid-cols-3">
                {/* City */}
                <div className="md:col-span-2">
                  <label
                    id={`${UUID}-city-label`}
                    htmlFor={`${UUID}-city`}
                    className="block mb-1"
                  >
                    City
                  </label>
                  <input
                    id={`${UUID}-city`}
                    aria-labelledby={`${UUID}-city-label`}
                    type="text"
                    {...register("city")}
                  />
                </div>
                {/* State */}
                <div>
                  <label
                    id={`${UUID}-state-label`}
                    htmlFor={`${UUID}-state`}
                    className="block mb-1"
                  >
                    State
                  </label>
                  <select
                    id={`${UUID}-state`}
                    aria-labelledby={`${UUID}-state-label`}
                    {...register("state")}
                  >
                    <option value="">Select</option>
                    <option value="DC">District of Columbia</option>
                    <option value="CA">CA</option>
                    <option value="NY">NY</option>
                  </select>
                </div>
                {/* ZIP */}
                <div>
                  <label
                    id={`${UUID}-zip-label`}
                    htmlFor={`${UUID}-zip`}
                    className="block mb-1"
                  >
                    Zip Code
                  </label>
                  <input
                    id={`${UUID}-zip`}
                    aria-labelledby={`${UUID}-zip-label`}
                    type="text"
                    {...register("zipcode")}
                  />
                </div>
              </div>
            </div>

            {/* <!-- Buttons --> */}
            <div className="block text-right mt-6">
              <button
                type="button"
                className="ui-btn-secondary ui-btn-min-w mt-4"
                onClick={() => setDialogState(false)}
              >
                Cancel
                <span className="sr-only">
                  changes to your contact information
                </span>
              </button>
              <button className="ui-btn-primary ui-btn-min-w mt-4">
                Save
                <span className="sr-only">
                  changes to your contact information
                </span>
              </button>
            </div>
          </form>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
