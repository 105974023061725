import { Link } from "react-router-dom";
import ServicesAndClaimsCardHotLink from "./ServicesAndClaimsCardHotLink";

export default function ServicesAndClaimsCard({
  data,
  titleFont = "font-body text-theme-text-quinary",
}) {
  return (
    <div
      className={`ui-card
        ${data.exploreLink ? "ui-card-link-grid-w-btn" : ""}
        ${data.subTitle ? "p-4 xl:p-6" : ""}
        ${data.imageResource ? "ui-card-link-grid" : ""}
      }`}
    >
      <div className="text-center">
        <h2 className={`${titleFont}`}>{data.title}</h2>
        <p className="text-sm">{data.desc}</p>
      </div>

      {data.imageResource && (
        <div>
          <img
            src={data.imageResource}
            className="w-full h-auto max-w-none rounded"
            alt=""
          />
        </div>
      )}

      <div
        className={`px-2 ${
          data.subTitle && !data.imageResource ? "mt-4 lg:mt-6" : ""
        }`}
      >
        {data.subTitle && (
          <div className="text-lg font-semibold">
            {data.subTitle}
          </div>
        )}
        <ul>
          {data.links.map((link, key) => {
            return (
              <ServicesAndClaimsCardHotLink
                key={`${data.title}-link-${key}`}
                data={link}
              />
            );
          })}
        </ul>
      </div>

      {data.exploreLink && (
        <div>
          <Link
            to={data.exploreLink}
            className="ui-btn-primary ui-btn-min-w mx-auto"
          >
            Explore All
            <span className="sr-only"> {data.title}</span>
          </Link>
        </div>
      )}
    </div>
  );
}
