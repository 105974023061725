import { Link } from "react-router-dom";
import { useState } from "react";
import { Tab } from "@headlessui/react";

import StatusDot from "../../components/General/StatusDot";
import Carousel from "../../components/General/Carousel";
import AppNavigationCard from "../../components/General/AppNavigationCard";
import SimpleDataRow from "../../components/General/SimpleDataRow";
import PolicyLookupModal from "../../components/AgentPortal/ModalDialogs/PolicyLookupModal";
import ReportCard from "../../components/AgentPortal/AgentDashboard/ReportCard";
import PolicySummaryCard from "../../components/PolicySummaryCard";
import AgentCard from "../../components/AgentPortal/ModalDialogs/AgentCard";
import ExportDialogModal from "../../components/AgentPortal/ModalDialogs/ExportDialogModal";

import { services, claims } from "../../js/placeholderData/policiesData";
import { carouselData } from "../../js/placeholderData/agents/carouselData";
import {
  agentList,
  policySummaries,
  policyPriority,
  policyPinned,
} from "../../js/placeholderData/agents/agentDashboardData";
import {
  commissionStatements,
  reportStatements,
} from "../../js/placeholderData/agents/commissionStatementsData";
import { agentDashboardData } from "../../js/placeholderData/agents/agentAppNavigationCardData";

export default function Dashboard() {
  const [isOpenExport, setIsOpenExport] = useState(false);
  const [isOpenPolicyLookup, setIsOpenPolicyLookup] = useState(false);

  return (
    <>
      {/* SPRINT-6-AGENT-dashboard.pdf Pgs 1-5 */}
      <div className="container mb-8">
        <h1>Welcome, Bob</h1>
        <hr />
        <p>
          <StatusDot status="problem" inlineStyle={true} />
          You have <Link to="/messages">1 new message</Link> and{" "}
          <Link to="/">7341 policies pending lapse</Link> that needs your
          attention.
        </p>
      </div>
      <div className="container ui-component-spacing">
        <div className="ui-card p-4 relative xl:p-6">
          <Carousel data={carouselData} />
        </div>
      </div>
      {/* Things You Can Do */}
      <div className="container ui-component-spacing">
        <h2>Things You Can Do</h2>
        <p className="text-sm">
          Your quick links for Service &amp; Claims for you and your customers
        </p>
        <hr />

        <div className="grid grid-cols-1 auto-rows-fr gap-4 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
          {agentDashboardData.map((item, idx) => (
            <AppNavigationCard
              key={idx}
              icon={item.icon}
              title={item.title}
              desc={item.desc}
              url={item.url}
            />
          ))}
        </div>
      </div>

      <div className="container ui-component-spacing">
        <h2>Book of Business</h2>
        <p className="text-sm">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </p>
        <hr />
        <Tab.Group>
          <div>
            <div className="ui-card flex-grow px-2 p-1 md:px-4">
              <Tab.List>
                <Tab className="text-sm mx-3 pt-3 pb-2 mb-1 md:text-base md:mx-4 lg:text-lg">
                  Agents
                </Tab>
                <Tab className="text-sm mx-3 pt-3 pb-2 mb-1 md:text-base md:mx-4 lg:text-lg">
                  Summary
                </Tab>
                <Tab className="text-sm mx-3 pt-3 pb-2 mb-1 md:text-base md:mx-4 lg:text-lg">
                  Priority
                </Tab>
                <Tab className="text-sm mx-3 pt-3 pb-2 mb-1 md:text-base md:mx-4 lg:text-lg">
                  Pinned
                </Tab>
                <Tab className="text-sm mx-3 pt-3 pb-2 mb-1 md:text-base md:mx-4 lg:text-lg">
                  Trend
                </Tab>
              </Tab.List>
            </div>
            <div className="mt-4 px-2">
              <div className="text-xs grid gap-4 grid-cols-[max-content,max-content,max-content] content-center place-content-end">
                <button
                  className="ui-btn-hyperlink ui-link-icon font-semibold"
                  onClick={() => setIsOpenExport(true)}
                >
                  <svg
                    className="ui-icon w-4 h-4"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-document-download"></use>
                  </svg>
                  Export report
                </button>
                <button
                  className="ui-btn-hyperlink ui-link-icon font-semibold"
                  onClick={() => setIsOpenPolicyLookup(true)}
                >
                  <svg
                    className="ui-icon w-4 h-4"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-search"></use>
                  </svg>
                  Policy lookup
                </button>
                <button className="ui-btn-hyperlink ui-link-icon font-semibold">
                  <svg
                    className="ui-icon w-4 h-4"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-filter"></use>
                  </svg>
                  Filter
                </button>
              </div>
            </div>
          </div>
          <Tab.Panels>
            <Tab.Panel>
              <div className="mt-4 grid gap-4 sm:grid-cols-2 md:grid-cols-3 lg:md:grid-cols-4 lg:mt-6 lg:gap-6">
                {/* Agent Cards */}
                {agentList.map((agent, index) => {
                  return <AgentCard data={agent} key={`agent - ${index}`} />;
                })}
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="mt-4 grid gap-4 md:grid-cols-2 lg:grid-cols-3 xl:md:grid-cols-4 lg:mt-6 lg:gap-6">
                {/* policies summary card */}
                {policySummaries.map((summary, index) => {
                  return (
                    <PolicySummaryCard
                      data={summary}
                      key={`summary - ${index}`}
                    />
                  );
                })}
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="mt-4 grid gap-4 md:grid-cols-2 lg:grid-cols-3 xl:md:grid-cols-4 lg:mt-6 lg:gap-6">
                {/* policies summary card */}
                {policyPriority.map((summary, index) => {
                  return (
                    <PolicySummaryCard
                      data={summary}
                      key={`summary - ${index}`}
                    />
                  );
                })}
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="mt-4 grid gap-4 md:grid-cols-2 lg:grid-cols-3 xl:md:grid-cols-4 lg:mt-6 lg:gap-6">
                {/* policies summary card */}
                {policyPinned.map((summary, index) => {
                  return (
                    <PolicySummaryCard
                      data={summary}
                      key={`summary - ${index}`}
                    />
                  );
                })}
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="ui-card p-4 mt-4 xl:p-6 xl:mt-6">
                <div className="w-full h-20 flex justify-center items-center">
                  {" "}
                  Chart Placeholder
                </div>
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>

      <div className="container ui-component-spacing">
        <hr />
      </div>

      {/* Marketing CTA */}
      <div className="container ui-component-spacing">
        <div className="ui-card relative overflow-hidden sm:bg-cover sm:bg-clip-border sm:bg-right sm:bg-origin-border sm:bg-no-repeat sm:bg-[url(/public/assets/img/cta-Image-2.jpg)] xl:bg-contain">
          <img
            src="/assets/img/cta-Image-2.jpg  "
            className="w-full h-auto sm:hidden"
          />
          <div className="w-full sm:w-3/5 md:w-3/5 lg:w-4/5">
            <div className="ui-cta-photo-mask p-4 md:p-8 lg:p-10">
              <div className="sm:pr-14 md:pr-20 lg:pr-28 xl:pr-32">
                <h2 className="text-2xl mb-2 md:text-3xl lg:text-5xl md:mb-6 lg:mb-8">
                  Featured Insurance Products
                </h2>
                <p className="mb-4 md:mb-6 lg:mb-8">
                  <strong>Everlake</strong> wants to do more than just provide
                  life insurance. We want to empower our agents to help
                  customers make all the right decisions along the way.
                </p>
                <p>Learn about:</p>
                <div className="ui-cta-actions">
                  <Link to="/" className="ui-btn-primary">
                    Term Products
                  </Link>
                  <Link to="/" className="ui-btn-primary">
                    Whole Life Productsz``
                  </Link>
                  <Link to="/" className="ui-btn-primary">
                    All Sales Materials & Products
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Monthly commission statement and Recent Reports */}
      <div className="container">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:gap-6">
          <div className="ui-component-spacing">
            <h2>Commissions Statements</h2>
            <hr />
            <div className="ui-card grid grid-cols-1 place-content-between">
              <ReportCard
                data={commissionStatements}
                setIsOpenExport={setIsOpenExport}
              />
              <button className="ui-card-btn-more">Go to Commissions</button>
            </div>
          </div>

          <div className="ui-component-spacing">
            <h2>Recent Reports</h2>
            <hr />
            <div className="ui-card flex flex-col items-stretch">
              <ReportCard
                data={reportStatements}
                setIsOpenExport={setIsOpenExport}
              />
              <button className="ui-card-btn-more">Go to Reports</button>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- My Requests & Claims --> */}
      <div className="container ui-component-spacing">
        <div
          className="lg:col-span-2"
          role="tablist"
          aria-label="My Requests &amp; Claims"
        >
          <h2>My Requests &amp; Claims</h2>
          <p>See the status of your open service requests and claims</p>
          <hr />
        </div>

        <Tab.Group className="md:hidden" as="div">
          <div className="ui-card flex-grow px-2 p-1 mb-4">
            <Tab.List>
              <Tab className="text-sm mx-3 pt-3 pb-2 mb-1">
                My service Requests
              </Tab>
              <Tab className="text-sm mx-3 pt-3 pb-2 mb-1">My Claims</Tab>
            </Tab.List>
          </div>
          <Tab.Panels>
            <Tab.Panel>
              <div className="ui-card ui-component-spacing">
                {services.map((service, idx) => (
                  <SimpleDataRow
                    type="customerServicesAndClaims"
                    key={`Customer service - ${idx}`}
                    data={service}
                  />
                ))}
                <button className="ui-card-btn-more">View More</button>
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="ui-card ui-component-spacing">
                {claims.map((claim, idx) => (
                  <SimpleDataRow
                    type="customerServicesAndClaims"
                    key={`Customer claim - ${idx}`}
                    data={claim}
                  />
                ))}
                <button className="ui-card-btn-more">View More</button>
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>

        <div className="hidden md:grid grid-cols-1 md:grid-cols-2 gap-x-4 xl:gap-x-6">
          <div
            className={`ui-card ui-component-spacing md:flex md:flex-col md:justify-between ${
              services.cardType === "sub" ? "hidden" : ""
            }`}
            role="tabpanel"
            id={services.cardId}
            aria-labelledby={`${services.cardId}_label`}
          >
            <div className="font-semibold p-4 pb-2 hidden md:block">
              {services.cardTitle} My Service Requests
            </div>
            {services.map((claim, idx) => (
              <SimpleDataRow
                type="customerServicesAndClaims"
                key={`Customer claim - ${idx}`}
                data={claim}
              />
            ))}
            <button className="ui-card-btn-more">View More</button>
          </div>

          <div
            className={`ui-card ui-component-spacing md:flex md:flex-col md:justify-between ${
              claims.cardType === "sub" ? "hidden" : ""
            }`}
            role="tabpanel"
            id={claims.cardId}
            aria-labelledby={`${claims.cardId}_label`}
          >
            <div className="font-semibold p-4 pb-2 hidden md:block">
              {claims.cardTitle} My Claims
            </div>
            {claims.map((claim, idx) => (
              <SimpleDataRow
                type="customerServicesAndClaims"
                key={`Customer claim - ${idx}`}
                data={claim}
              />
            ))}
            <button className="ui-card-btn-more">View More</button>
          </div>
        </div>
      </div>
      {/* <!-- My Requests & Claims --> */}

      {/* Export Dialog */}

      <ExportDialogModal
        isOpenExport={isOpenExport}
        setIsOpenExport={setIsOpenExport}
      />

      {/* Policy Lookup Dialog */}
      <PolicyLookupModal
        isOpenPolicyLookup={isOpenPolicyLookup}
        setIsOpenPolicyLookup={setIsOpenPolicyLookup}
      />
    </>
  );
}