export const cardData = [
  {
    name: "Mastercard",
    lastFour: 1234,
    isDefault: true,
  },
  {
    name: "Visa",
    lastFour: 2345,
    isDefault: false,
  },
  {
    name: "American Express",
    lastFour: 3456,
    isDefault: false,
  }
]