import { faqs } from "../js/placeholderData/help-faq-data";

import { useState } from "react";
import { Dialog } from "@headlessui/react";
import { useForm } from "react-hook-form";
import Accordion from "../components/General/Accordion";
import Carousel from "../components/General/Carousel";
import SearchForm from "../components/General/SearchForm";
import ArticleCard from "../components/General/ArticleCard";
import CallToActionCardWithIconOnTop from "../components/General/CallToActionCardWithIconOnTop";
import CallToActionTextOverImage from "../components/General/CallToActionTextOverImage";
import DialogBackdrop from "../components/ModalDialogs/DialogBackdrop";
import FormInputError from "../components/General/FormInputError";

export default function Help() {
  const MAXIMUMWORDCOUNT = 350;
  const [isOpenEmailUs, setIsOpenEmailUs] = useState(false);
  const [isOpenContactAgent, setIsOpenContactAgent] = useState(false);
  const [currentWordCount, setCurrentWordCount] = useState(0);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const submitEmailContact = (data) => {
    console.log(data);
    setIsOpenEmailUs(false);
  };

  return (
    <>
      {/* SPRINT-5-CUSTOMER-help-section-screens.pdf (pgs 4-5) */}
      <div className="container mb-8">
        <h1>Life Insurance that Fits Your Life</h1>
        <p>
          We're here to help you with any questions you have on your policies
        </p>
        <hr />
      </div>
      <div className="container ui-component-spacing">
        <div className="ui-card p-4 relative xl:p-6">
          <Carousel />
        </div>
      </div>
      <div className="container ui-component-spacing">
        <CallToActionTextOverImage
          backgroundImagePath="/assets/img/cta-help-placeholder.jpg"
          backgroundImageAltText="How can we help you today?"
          backgroundImageOpacity=".2"
          headline="How can we help you today?"
          tagline="This is your online touch point for accessing our knowledgeable, FAQs and our specialized customer support staff."
        />
      </div>

      {/* Site search */}
      <div className="container ui-component-spacing">
        <h2>Do a quick search</h2>
        <hr className="my-4" />
        <div className="mb-6">
          <div className="w-full md:w-3/5 xl:w-1/2">
            <SearchForm focus={false} />
          </div>
        </div>
      </div>

      {/* FAQs */}
      <div className="container ui-component-spacing">
        <h2>FAQs</h2>
        <div className="flex flex-col sm:flex-row sm:items-end sm:justify-between">
          <div className="flex-grow mr-8">
            <p className="text-sm">Lorem ipsum</p>
          </div>
          <div className="flex-shrink-0 mt-4 sm:mt-0">
            <a href="/help/help-faqs" className="ui-btn-primary">
              View All FAQs
            </a>
          </div>
        </div>
        <hr />
        <div className="grid grid-cols-1 gap-4">
          {faqs.slice(0, 3).map((faq, idx) => (
            <Accordion item={faq} key={`FAQ - ${idx}`} />
          ))}
        </div>
      </div>

      {/* Explore Help Section Articles */}
      <div className="container ui-component-spacing">
        <h2>Explore Help Section Articles</h2>
        <div className="flex flex-col sm:flex-row sm:items-end sm:justify-between">
          <div className="flex-grow mr-8">
            <p className="text-sm">Lorem ipsum</p>
          </div>
          <div className="flex-shrink-0 mt-4 sm:mt-0">
            <a href="/help/help-explore" className="ui-btn-primary">
              Explore All Articles
            </a>
          </div>
        </div>
        <hr />
        <div className="grid gap-4 grid-cols-1 auto-rows-min content-start lg:gap-6 lg:grid-cols-3 lg:grid-row-auto">
          <div>
            <ArticleCard
              title="Payments &amp; Billing"
              instructions="Copy about payments category of the help section and what can be
                found here."
              links={[
                {
                  icon: "#ui-icon-document",
                  label: "Make a payment",
                  url: "/help/article/1",
                },
                {
                  icon: "#ui-icon-app",
                  label: "Edit or cancel a Payment",
                  url: "/help/article/1",
                },
                {
                  icon: "#ui-icon-document",
                  label: "Check the status of a payment",
                  url: "/help/article/1",
                },
                {
                  icon: "#ui-icon-document",
                  label: "Change payment methods",
                  url: "/help/article/1",
                },
                {
                  icon: "#ui-icon-document",
                  label: "Manage paperless billing settings",
                  url: "/help/article/1",
                },
                {
                  icon: "#ui-icon-document",
                  label: "Manage autopay settings",
                  url: "/help/article/1",
                },
                {
                  icon: "#ui-icon-document",
                  label:
                    "Another article title that may wrap Loren ipsum dolor sit amen",
                  url: "/help/article/1",
                },
                {
                  icon: "#ui-icon-document",
                  label: "Manage paperless billing settings",
                  url: "/help/article/1",
                },
              ]}
            />
          </div>
          <div>
            <ArticleCard
              title="Claims"
              instructions="Copy about claims category of the help section and what can be found here."
              links={[
                {
                  icon: "#ui-icon-document",
                  label: "File a Claim",
                  url: "/help/article/1",
                },
                {
                  icon: "#ui-icon-document",
                  label: "File ro track a claim",
                  url: "/help/article/1",
                },
                {
                  icon: "#ui-icon-document",
                  label: "Find out when I will receive my payment",
                  url: "/help/article/1",
                },
              ]}
            />
          </div>
          <div>
            <ArticleCard
              title="Another Help Category"
              instructions="Copy about this category of the help section and what can be found here."
              links={[
                {
                  icon: "#ui-icon-document",
                  label: "Another article title",
                  url: "/help/article/1",
                },
                {
                  icon: "#ui-icon-document",
                  label: "Another article title",
                  url: "/help/article/1",
                },
                {
                  icon: "#ui-icon-document",
                  label: "Another article title",
                  url: "/help/article/1",
                },
              ]}
            />
          </div>
        </div>
      </div>

      {/* Contact Us section */}
      <div id="contact-us" className="container ui-component-spacing">
        <h2>Contact Us</h2>
        <p className="text-sm">
          Additional text needed to explain contact us options goes here Loren
          Epsom.
        </p>
        <hr />
        <div className="grid gap-4 grid-cols-1 auto-rows-min content-start md:grid-cols-2 lg:gap-6 lg:grid-cols-3 lg:grid-row-auto">
          {/* Services Support */}
          {window.localStorage.getItem("authenticated") &&
          window.localStorage.getItem("authenticated") === "true" ? (
            <CallToActionCardWithIconOnTop
              icon="#ui-icon-bolt"
              title="Services Support"
              desc="Manage payments, beneficiaries and other policy updates."
              ctaText="Self-service"
              ctaSrText="for services support"
              ctaUrl="/services-claims"
              subAction="phone"
              phoneNumber="1-800-555-5555"
            />
          ) : (
            <CallToActionCardWithIconOnTop
              icon="#ui-icon-bolt"
              title="Guest service"
              desc="Make a payment online. Call us for other policy services."
              ctaText="Make a payment"
              ctaSrText="for guest support"
              ctaUrl="/guest/make-a-payment"
              subAction="phone"
              phoneNumber="1-800-555-5555"
            />
          )}

          {/* Claims Support */}
          {window.localStorage.getItem("authenticated") &&
          window.localStorage.getItem("authenticated") === "true" ? (
            <CallToActionCardWithIconOnTop
              icon="#ui-icon-document"
              title="Claims Support"
              desc="Compassionate support for filing and tracking claims."
              ctaText="Self-service"
              ctaSrText="for claim support"
              ctaUrl="/services-claims/claims"
              subAction="phone"
              phoneNumber="1-800-555-5555"
            />
          ) : (
            <CallToActionCardWithIconOnTop
              icon="#ui-icon-document"
              title="Claims Support"
              desc="Compassionate support for filing and tracking claims."
              ctaText="File a claim"
              ctaSrText="for claim support"
              ctaUrl="/guest/claims/file-a-claim"
              subAction="phone"
              phoneNumber="1-800-555-5555"
            />
          )}

          {/* Live Chat Now Available */}
          <CallToActionCardWithIconOnTop
            icon="#ui-icon-chat-alt"
            title="Live Chat Now Available"
            desc="Live chat hours are XXam-XXpm CST."
            ctaText="Launch"
            ctaSrText="Live Chat"
            ctaUrl="#"
          />
          {/* Contact Your Agent */}
          {window.localStorage.getItem("authenticated") &&
            window.localStorage.getItem("authenticated") === "true" && (
              <CallToActionCardWithIconOnTop
                action="modal"
                actionMethod={() => setIsOpenContactAgent(true)}
                icon="#ui-icon-phone"
                title="Contact Your Agent"
                desc="Your experienced Life Insurance Professional will help you with lorem ipsum."
                ctaText="Contact Agent"
              />
            )}

          {/* Need a new insurance agent? */}
          <CallToActionCardWithIconOnTop
            icon="#ui-icon-user-plus"
            title="Need a new insurance agent?"
            desc="Make this a fall back for when customer doesn't have an assigned agent."
            ctaText="Agent Look Up"
            ctaUrl="#"
            subAction="phone"
            phoneNumber="1-800-555-4444"
          />
          {/* Email Us */}
          <CallToActionCardWithIconOnTop
            action="modal"
            actionMethod={() => setIsOpenEmailUs(true)}
            icon="#ui-icon-message"
            title="Email Us"
            desc="Text that goes with email option goes here."
            ctaText="Reach Out"
            ctaSrText="through email"
            ctaUrl="#"
            subAction="phone"
            phoneNumber="1-800-555-4444"
          />
        </div>
      </div>
      {/* Other Ways Of Contacting Us */}
      <div className="container ui-component-spacing">
        <div className="ui-card p-4 sm:p-6">
          <h2 className="font-body text-xl font-bold">
            Other Ways Of Contacting Us
          </h2>
          <hr className="my-4" />
          <div className="grid gap-4 grid-cols-1 auto-rows-min content-start md:grid-cols-2 lg:gap-6 lg:grid-cols-3 lg:grid-row-auto xl:grid-cols-5">
            <div>
              <div className="font-semibold">For General Mail:</div>
              <div className="text-sm">
                <div>Everlake Customer Support</div>
                <div>PO Box 55555</div>
                <div>Sayga, NY 55555</div>
              </div>
            </div>
            <div>
              <div className="font-semibold">For Overnight Mail:</div>
              <div className="text-sm">
                <div>Everlake Customer Support</div>
                <div>555 Herbert Road</div>
                <div>Sayga, NY 55555</div>
              </div>
            </div>
            <div>
              <div className="font-semibold">For Everlake Corporate:</div>
              <div className="text-sm">
                <div>Everlake</div>
                <div>3100 Sander Road</div>
                <div>Suite 303</div>
                <div>Northbrook, IL 60062</div>
              </div>
            </div>
            <div className="text-sm">
              <div className="font-semibold">By Fax</div>
              <div className="text-sm">
                <div>Use this number</div>
                <div>555-555-5555</div>
              </div>
            </div>
            <div className="text-sm">
              <div className="font-semibold">Want to work with us?</div>
              <div className="text-sm">
                <a href="#">Learn more here</a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={isOpenContactAgent}
        onClose={() => setIsOpenContactAgent(false)}
        className="ui-dialogs"
      >
        <DialogBackdrop />
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel
            role="dialog"
            aria-modal="true"
            className="ui-default-dialog"
          >
            <Dialog.Title className="font-body text-xl font-bold text-left">
              Contact Your Agent
            </Dialog.Title>
            <button
              className="ui-default-dialog-close"
              aria-label="close edit photo window"
              onClick={() => setIsOpenContactAgent(false)}
            >
              <svg
                className="ui-icon h-3.5 w-3.5"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href="#ui-icon-close"></use>
              </svg>
            </button>
            <Dialog.Description>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </Dialog.Description>
            <hr />
            <div className="ui-dialog-body">
              <div className="grid gap-4 grid-cols-[max-content_1fr]  mt-4">
                <img
                  alt="agent ben parker"
                  src="/assets/img/agent-ben-parker.png"
                  className="h-auto w-14 rounded"
                />
                <div>
                  <div className="font-semibold text-sm">Bob Ferguson</div>
                  <div className="text-xs mt-1">
                    <a href="mailto:bobcorey@everlake.com">
                      bobcorey@everlake.com
                    </a>
                    , <a href="tel:+10000000000">+1 000-000-0000</a>
                  </div>
                  <div className="text-xs mt-1">
                    <span>
                      Term Life <a href="/">#384828283</a>
                    </span>
                    ,
                    <span>
                      Annuity
                      <a href="/">#109367902</a>
                    </span>
                  </div>
                </div>
              </div>

              <div className="grid gap-4 grid-cols-[max-content_1fr]  mt-4">
                <img
                  alt="agent ben parker"
                  src="/assets/img/agent-clara-simpson.png"
                  className="h-auto w-14 rounded"
                />
                <div>
                  <div className="font-semibold text-sm">Clara Simpson</div>
                  <div className="text-xs mt-1">
                    <a href="mailto:clarasimpson@everlake.com">
                      clarasimpson@everlake.com
                    </a>
                  </div>
                  <div className="text-xs mt-1">
                    <span>
                      Whole Life <a href="/">#384828283</a>
                    </span>
                    ,{" "}
                    <span>
                      Term Life
                      <a href="/">#109367902</a>
                    </span>
                  </div>
                </div>
              </div>
              <div className="grid gap-4 grid-cols-[max-content_1fr] mt-4">
                <img
                  alt="agent ben parker"
                  src="/assets/img/agent-clark-royce.png"
                  className="h-auto w-14 rounded"
                />
                <div>
                  <div className="font-semibold text-sm">Clark Royce</div>
                  <div className="text-xs mt-1">
                    <a href="mailto:clarkroyce@everlake.com">
                      clarkroyce@everlake.com
                    </a>
                    ,<a href="tel:+10000000000">+1 000-000-0000</a>
                  </div>
                  <div className="text-xs mt-1">
                    <span>
                      Universal Life <a href="/">#384828283</a>
                    </span>
                    ,{" "}
                    <span>
                      Annuity
                      <a href="/">#109367902</a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="block text-right mt-6">
              <button
                className="ui-btn-secondary ui-btn-min-w mt-4"
                onClick={() => setIsOpenContactAgent(false)}
              >
                Close
                <span className="sr-only"> your account photo </span>
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      <Dialog
        open={isOpenEmailUs}
        onClose={() => setIsOpenEmailUs(false)}
        className="ui-dialogs"
      >
        <DialogBackdrop />
        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel
            role="dialog"
            aria-modal="true"
            className="ui-default-dialog"
          >
            <Dialog.Title className="font-body text-xl font-bold text-left">
              We'd love to hear from you
            </Dialog.Title>
            <button
              className="ui-default-dialog-close"
              aria-label="close edit photo window"
              onClick={() => setIsOpenEmailUs(false)}
            >
              <svg
                className="ui-icon h-3.5 w-3.5"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href="#ui-icon-close"></use>
              </svg>
            </button>
            <Dialog.Description>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
            </Dialog.Description>
            <hr />
            <form onSubmit={handleSubmit(submitEmailContact)}>
              <div className="ui-dialog-body">
                <div>
                  <label id="ID_emailLabel" htmlFor="ID_email">
                    Email address *
                  </label>
                  <input
                    id="ID_email"
                    type="text"
                    aria-labelledby="ID_emailLabel"
                    aria-describedby="ID_emailError"
                    className="mt-1"
                    {...register("email", {
                      required: "Please enter an email for us to reply back",
                    })}
                  />
                  <FormInputError
                    id="ID_emailError"
                    errorMessage={errors?.email?.message}
                  />
                </div>

                <div className="mt-4">
                  <label id="ID_emailSubjectLabel" htmlFor="ID_emailSubject">
                    Subject *
                  </label>
                  <div>
                    <select
                      id="ID_emailSubject"
                      aria-labelledby="ID_emailSubjectLabel"
                      aria-describedby="ID_emailSubjectError"
                      className="mt-1"
                      {...register("subject", {
                        required: "Please select a subject for this email",
                      })}
                    >
                      <option value="">Selected subject line</option>
                      <option value="subject1">Subject line 1</option>
                    </select>
                    <FormInputError
                      id="ID_emailError"
                      errorMessage={errors?.subject?.message}
                    />
                  </div>
                </div>
                <div className="mt-4">
                  <label id="ID_messageLabel" htmlFor="ID_message">
                    Your message (Max. {MAXIMUMWORDCOUNT} Character) *
                  </label>
                  <textarea
                    id="ID_message"
                    type="text"
                    name="message"
                    aria-labelledby="ID_messageLabel"
                    aria-describedby="ID_messageError"
                    className="mt-1"
                    rows="6"
                    {...register("message", {
                      required: "Please enter a message",
                      maxLength: {
                        value: 350,
                        message: "Maximum characters exceeded.",
                      },
                      onChange: (e) => {
                        setCurrentWordCount(e.target.value.length);
                      },
                    })}
                  />
                  <span
                    className={
                      currentWordCount > MAXIMUMWORDCOUNT
                        ? "ui-input-error"
                        : ""
                    }
                  >
                    ({currentWordCount}/{MAXIMUMWORDCOUNT})
                  </span>
                  <FormInputError
                    id="ID_emailError"
                    errorMessage={errors?.message?.message}
                  />
                </div>
              </div>
              <div className="block text-right mt-6">
                <button
                  type="button"
                  className="ui-btn-secondary ui-btn-min-w mt-4"
                  onClick={() => setIsOpenEmailUs(false)}
                >
                  Close
                  <span className="sr-only"> sending email </span>
                </button>
                <button className="ui-btn-primary ui-btn-min-w mt-4">
                  Submit
                  <span className="sr-only"> your email </span>
                </button>
              </div>
            </form>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
}
