import { usePagination } from "../hooks/usePagination";
import { featured, results } from "../js/placeholderData/search-result-data";

import SearchForm from "../components/General/SearchForm";
import SearchRow from "../components/Search/SearchRow";
import Pagination from "../components/General/Pagination";
import FeaturedCard from "../components/Search/FeaturedCard";

export default function SearchResults() {
  const { current, paginationProps } = usePagination(results, 5);

  return (
    <>
      {/* SPRINT-5-CUSTOMER-help-section-screens.pdf (pgs 17-18) */}
      <div className="container mb-8">
        <h1>Search</h1>
        <p>
          Lorem ipsum dolor sit amet, eam ad autem fabulas. Expetenda dissentiet
        </p>
        <hr />
      </div>
      <div className="container ui-component-spacing">
        <div className="w-full md:w-3/5 xl:w-1/2">
          <SearchForm />
        </div>
      </div>

      <div className="container ui-component-spacing">
        <div className="grid gap-4 grid-cols-1 lg:grid-cols-[66.666667%_33.333333%] lg:grid-rows-[min-content_max-content] lg:grid-flow-col xl:gap-6">
          <div className="lg:flex lg:flex-col lg:justify-end">
            <h2>Search Results ({results.length})</h2>
            <p className="text-sm">
              Lorem ipsum dolor sit amet, eam ad autem fabulas. Expetenda
              dissentiet
            </p>
            <hr className="mb-0" />
          </div>
          <div className="grid grid-cols-1 gap-4 auto-rows-max xl:gap-6">
            {current.map((result, idx) => {
              return <SearchRow data={result} key={`result-${result.id}`} />;
            })}
            <Pagination {...paginationProps} />
          </div>
          <div className="lg:flex lg:flex-col lg:justify-end">
            <h2>Featured ({featured.length})</h2>
            <p className="text-sm">
              Lorem ipsum dolor sit amet, eam ad autem fabulas. Expetenda
              dissentiet
            </p>
            <hr className="mb-0" />
          </div>
          <div>
            {/* Featured Card */}
            {featured.map((feature, idx) => {
              return (
                <FeaturedCard data={feature} key={`featured - ${feature.id}`} />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
