import { html, Grid } from "gridjs";
import { useEffect, useRef } from "react";

export default function PaymentsHistoryGrid({ data }) {
  const paymentsHistoryGridWrapper = useRef(null);
  const grid = new Grid({
    columns: [
      {
        name: "Payment amount",
        data: (row) => row.amount,
        formatter: (cell) => {
          return html(`<span>$${cell}</span>`);
        },
      },
      {
        name: "Payment method",
        data: (row) => row.method,
        formatter: (cell) => {
          return html(`<span>${cell}</span>`);
        },
      },
      {
        name: "Payment posted",
        data: (row) => row.postedDate,
        formatter: (cell) => {
          return html(`<span>${cell}</span>`);
        },
      },
    ],
    data: data,
    className: {
      table: "ui-grid-table-sm",
    },
  });
  useEffect(() => {
    grid.render(paymentsHistoryGridWrapper.current);
  }, []);

  return <div ref={paymentsHistoryGridWrapper} />;
}
