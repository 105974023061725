import { useState } from "react";
import UserAvatarDialog from "./Dialogs/UserAvatarDialog";
export default function ProfileCard({ data }) {
  const [openUserAvatarDialog, setOpenUserAvatarDialog] = useState(false);

  return (
    <>
      <div className="container ui-component-spacing">
        <div className="ui-card p-4 xl:p-6">
          <div className="flex flex-col md:flex-row">
            <div className="mb-4 mr-auto sm:w-60 md:mr-6">
              <div className="relative">
                <img
                  src={data.userAvatar}
                  alt="avatar"
                  className="w-full h-auto max-w-none"
                />
                <button
                  className="absolute group shadow-md bottom-2 right-2 rounded-full border border-transparent text-theme-action hover:text-theme-action hover:border-white"
                  onClick={() => setOpenUserAvatarDialog(true)}
                >
                  <svg
                    className="ui-icon h-6 w-6"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                    aria-label="Edit your account photo"
                  >
                    <g className="group-hover:hidden">
                      <use href="#ui-icon-edit-stroke"></use>
                    </g>
                    <g className="hidden group-hover:block">
                      <use href="#ui-icon-edit-solid"></use>
                    </g>
                  </svg>
                </button>
              </div>
            </div>
            <div>
              <div className="text-lg font-semibold">
                {data.firstName} {data.lastName}
                <span className="ml-2 text-base font-normal">
                  {data.pronoun}
                </span>
              </div>

              <hr />
              <div className="text-xs font-bold mb-2">
                Insurance &amp; Investment Goals:
              </div>
              <p className="text-xs">{data.goal}</p>

              <div className="grid grid-cols-1 mt-4 gap-4 md:grid-cols-2 lg:grid-cols-4 lg:mt-6 xl:gap-6">
                <div>
                  <div className="text-xs text-theme-text-tertiary">
                    Address
                  </div>
                  <div className="text-sm font-semibold text-theme-text-secondary">
                    <div>{data.agencyName}</div>
                    {data.suiteNumber && <div>{data.suiteNumber}</div>}
                    <div>{data.streetAddress}</div>
                    <div>
                      {data.city}, {data.state} {data.zipcode}
                    </div>
                  </div>
                </div>
                {data.mobilePhone && (
                  <div>
                    <div className="text-xs text-theme-text-tertiary">
                      Phone
                    </div>
                    <div className="text-sm text-theme-text-secondary font-semibold">
                      {data.mobilePhone}
                    </div>
                    <div className="text-xxs font-semibold">Mobile</div>
                  </div>
                )}
                {data.homePhone && (
                  <div>
                    <div className="text-xs text-theme-text-tertiary">
                      Phone
                    </div>
                    <div className="text-sm text-theme-text-secondary font-semibold">
                      {data.homePhone}
                    </div>
                    <div className="text-xxs font-semibold">Home</div>
                  </div>
                )}
                {data.email && (
                  <div>
                    <div className="text-xs text-theme-text-tertiary">
                      Email
                    </div>
                    <div className="text-sm text-theme-text-secondary font-semibold">
                      {data.email}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <UserAvatarDialog
        state={openUserAvatarDialog}
        setState={setOpenUserAvatarDialog}
      />
    </>
  );
}
