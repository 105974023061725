import React, { useEffect, useState } from "react";

export default function CallToActionTextOverImage({
  backgroundImagePath,
  backgroundImageAltText,
  backgroundImageOpacity,
  headline,
  tagline,
}) {
    const css = `@media (min-width: 768px){
      .ui-image-opacity {
        opacity: ${backgroundImageOpacity};
      }
    }`;
  return (
    <>
      <style>{css}</style>      
      <div className="relative overflow-hidden bg-white rounded shadow-card md:rounded-none md:shadow-none md:bg-black">
        <img
          alt={backgroundImageAltText}
          src={backgroundImagePath}
          className="w-full h-auto max-w-none ui-image-opacity"
        />
        <div className="text-center p-4 md:absolute md:inset-0 md:grid md:grid-cols-1 md:justify-items-stretch md:place-content-center md:text-white xl:p-6 md:ui-text-shadow-black">
          <div className="font-header text-2xl tracking-wide lg:text-4xl lg:w-3/4 lg:m-auto xl:text-5xl">
            {headline}
          </div>
          <div className="font-semibold font-sm mt-2 md:tracking-wide md:text-base md:mt-3 md:w-4/5 md:m-auto lg:text-xl lg:mt-4 lg:w-3/4 xl:text-2xl">
            {tagline}
          </div>
        </div>
      </div>    
    </>
  );
}
