import { v4 as uuidv4 } from "uuid";
import { useForm } from "react-hook-form";
import FormInputError from "../../../General/FormInputError";

export default function FACStepTwo({ formStep, setFormStep, setFACFormData }) {
  const uuid = uuidv4();
  const {
    register: FACStepTwoRegister,
    handleSubmit: FACStepTwoHandleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setFormStep(3);
    setFACFormData((currentFormData) => {
      return {
        ...currentFormData,
        ...data,
      };
    });
  };

  return (
    <>
      {formStep === 2 && (
        <form onSubmit={FACStepTwoHandleSubmit(onSubmit)}>
          <div>
            <hr />
            <div className="grid gap-4 grid-cols-1 lg:gap-6">
              <div className="grid gap-4 grid-cols-1 md:gap-y-0 md:gap-x-4 md:grid-cols-[2fr,2fr,1fr]">
                <div>
                  <label htmlFor={`${uuid}-fn-claimer`} className="block mb-1">
                    First Name *
                  </label>
                  <input
                    id={`${uuid}-fn-claimer`}
                    type="text"
                    {...FACStepTwoRegister("firstNameClaimer", {
                      required: "Please enter a first name",
                    })}
                  />
                  <FormInputError
                    id={`${uuid}-fn-claimer-error`}
                    errorMessage={errors.firstNameClaimer?.message}
                  />
                </div>

                <div>
                  <label htmlFor={`${uuid}-ln-claimer`} className="block mb-1">
                    Last Name *
                  </label>
                  <input
                    id={`${uuid}-ln-claimer`}
                    type="text"
                    {...FACStepTwoRegister("lastNameClaimer", {
                      required: "Please enter a last name",
                    })}
                  />
                  <FormInputError
                    id={`${uuid}-ln-claimer-error`}
                    errorMessage={errors.lastNameClaimer?.message}
                  />
                </div>

                <div>
                  <label htmlFor={`${uuid}-mn-claimer`} className="block mb-1">
                    Middle Initial
                  </label>
                  <input
                    id={`${uuid}-mn-claimer`}
                    type="text"
                    {...FACStepTwoRegister("middleNameClaimer")}
                  />
                  <FormInputError
                    id={`${uuid}-mn-claimer-error`}
                    errorMessage={errors.middleNameClaimer?.message}
                  />
                </div>
              </div>
              <div>
                <label htmlFor={`${uuid}-sa-claimer`} className="block mb-1">
                  Street Address
                </label>
                <input
                  id={`${uuid}-sa-claimer`}
                  type="text"
                  {...FACStepTwoRegister("streetAddressClaimer")}
                />
                <FormInputError
                  id={`${uuid}-sa-claimer-error`}
                  errorMessage={errors.streetAddressClaimer?.message}
                />
              </div>

              <div>
                <label htmlFor={`${uuid}-sa2-claimer`} className="block mb-1">
                  Street Address line 2
                </label>
                <input
                  id={`${uuid}-sa2-claimer`}
                  type="text"
                  {...FACStepTwoRegister("streetAddress2Claimer")}
                />
                <FormInputError
                  id={`${uuid}-sa2-claimer-error`}
                  errorMessage={errors.streetAddress2Claimer?.message}
                />
              </div>
              <div className="grid gap-4 grid-cols-1 md:gap-y-0 md:gap-x-4 md:grid-cols-[1.5fr,1fr,1fr]">
                <div>
                  <label
                    htmlFor={`${uuid}-city-claimer`}
                    className="block mb-1"
                  >
                    City
                  </label>
                  <input
                    id={`${uuid}-city-claimer`}
                    type="text"
                    {...FACStepTwoRegister("cityClaimer")}
                  />
                  <FormInputError
                    id={`${uuid}-city-claimer-error`}
                    errorMessage={errors.cityClaimer?.message}
                  />
                </div>

                <div>
                  <label
                    htmlFor={`${uuid}-state-claimer`}
                    className="block mb-1"
                  >
                    State
                  </label>
                  <input
                    id={`${uuid}-state-claimer`}
                    type="text"
                    {...FACStepTwoRegister("stateClaimer")}
                  />
                  <FormInputError
                    id={`${uuid}-state-claimer-error`}
                    errorMessage={errors.stateClaimer?.message}
                  />
                </div>

                <div>
                  <label htmlFor={`${uuid}-zip-claimer`} className="block mb-1">
                    Zip
                  </label>
                  <input
                    id={`${uuid}-zip-claimer`}
                    type="text"
                    {...FACStepTwoRegister("zipClaimer")}
                  />
                  <FormInputError
                    id={`${uuid}-zip-claimer-error`}
                    errorMessage={errors.zipClaimer?.message}
                  />
                </div>
              </div>
              <div className="grid gap-4 grid-cols-1 md:gap-y-0 md:gap-x-4 md:grid-cols-[1.5fr,1fr,1fr]">
                <div>
                  <label
                    htmlFor={`${uuid}-email-claimer`}
                    className="block mb-1"
                  >
                    Email address
                  </label>
                  <input
                    id={`${uuid}-email-claimer`}
                    type="text"
                    {...FACStepTwoRegister("emailClaimer")}
                  />
                  <FormInputError
                    id={`${uuid}-email-claimer-error`}
                    errorMessage={errors.emailClaimer?.message}
                  />
                </div>
                <div>
                  <label
                    htmlFor={`${uuid}-phone-claimer`}
                    className="block mb-1"
                  >
                    Phone number
                  </label>
                  <input
                    id={`${uuid}-phone-claimer`}
                    aria-describedby={`${uuid}-phone-claimer-note`}
                    type="text"
                    {...FACStepTwoRegister("phoneClaimer")}
                  />
                  <div
                    id={`${uuid}-phone-claimer-note`}
                    className="text-xxs text-theme-text-tertiary mt-1"
                  >
                    Use format: XXX-XX-XXXX
                  </div>
                  <FormInputError
                    id={`${uuid}-phone-claimer-error`}
                    errorMessage={errors.phoneClaimer?.message}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor={`${uuid}-relationship-claimer`}
                  className="block mb-1"
                >
                  Relationship to the deceased
                </label>

                <select
                  id={`${uuid}-relationship-claimer`}
                  className="w-auto"
                  {...FACStepTwoRegister("relationshipClaimer", {
                    required:
                      "Please select your relationship with the deceased",
                  })}
                >
                  <option value="">
                    Please select your relationship with the deceased
                  </option>
                  <option value="spouse">Spouse</option>
                  <option value="child">Child</option>
                </select>

                <FormInputError
                  id={`${uuid}-relationship-claimer`}
                  errorMessage={errors.relationshipClaimer?.message}
                />
              </div>

              <div className="flex justify-end">
                <button
                  className="ui-btn-secondary ui-btn-min-w mt-4"
                  type="button"
                  onClick={() => setFormStep((current) => (current -= 1))}
                >
                  Previous
                </button>

                <button className="ui-btn-primary ui-btn-min-w mt-4">
                  Continue
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
}
