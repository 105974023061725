import { reportDetail } from "../../js/placeholderData/agents/agentReportDetailData";
import { usePagination } from "../../hooks/usePagination";
import SimpleDataRow from "../../components/General/SimpleDataRow";
import Pagination from "../../components/General/Pagination";
export default function AgentReportDetail() {
  const MAXIMUMPERPAGE = 8;
  const { current: reportDetailRows, paginationProps } = usePagination(
    reportDetail.records,
    MAXIMUMPERPAGE
  );
  return (
    <>
      <div className="container mb-8">
        <h1>Run {reportDetail.title}</h1>
        <hr />
      </div>
      <div className="container ui-component-spacing">
        <h2>Customize report</h2>
        <p className="text-sm">
          Instructions or explanation of the default view can go here.
        </p>
        <hr />
        <div className="mt-2 grid gap-2 grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:gap-0 lg:flex lg:space-x-6">
          <div>
            <label htmlFor="year_selection">Fiscal or calendar year</label>
            <select id="year_selection" name="year_selection">
              <option value="fiscal">Fiscal</option>
              <option value="calendar">Calendar</option>
            </select>
          </div>
          <div>
            <label htmlFor="year">Year</label>
            <select id="year" name="year">
              <option value="2022">2022</option>
              <option value="2021">2021</option>
            </select>
          </div>
          <div>
            <label htmlFor="policyStatus">Policy Status</label>
            <select id="policyStatus" name="policyStatus">
              <option value="all">All</option>
              <option value="pastDue">Past Due</option>
            </select>
          </div>
          <div>
            <label htmlFor="policyType">Policy Type</label>
            <select id="policyType" name="policyType">
              <option value="all">All</option>
              <option value="life">Life</option>
            </select>
          </div>
          <div>
            <label htmlFor="anotherField">Another Field</label>
            <select id="anotherField" name="anotherField">
              <option value="other">Another</option>
              <option value="other1">Another 1</option>
            </select>
          </div>

          <div className="mt-4 sm:col-span-2 sm:self-end lg:mt-0">
            <button className="ui-btn-primary ui-btn-min-w h-[2.39rem] ">
              Reset to default
            </button>
          </div>
        </div>
      </div>
      <div className="container ui-component-spacing">
        <h3 className="font-body text-lg font-semibold">
          Book of Business records ({reportDetail?.records?.length})
        </h3>
        <hr className="my-4" />
        <div className="text-sm sm:text-right">
          <div></div>
          <div>
            <div className="inline mr-1">Sort by: </div>
            <button
              className="ui-btn-hyperlink font-semibold"
              aria-label="sort by a to z"
            >
              A-Z
            </button>
            <span className="mx-1">|</span>
            <button
              className="ui-btn-hyperlink font-semibold"
              aria-label="sort by a to z"
            >
              Z-A
            </button>
            <button className="ui-btn-hyperlink font-semibold ml-6">
              Export Report
            </button>
          </div>
        </div>

        {reportDetailRows.map((report, idx) => {
          return (
            <SimpleDataRow
              key={`detail report - ${idx}`}
              type="reportDetail"
              data={report}
            />
          );
        })}
        <div className="flex justify-between items-start mt-6">
          <div></div>
          <Pagination {...paginationProps} />
        </div>
      </div>
    </>
  );
}
