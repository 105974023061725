export const carouselData = [
  {
    uiTextColor: "text-ui-green",
    iconBorder: "ui-icon-border-good",
    title: "Grid Attention: Claims packet available for you to complete -- 0",
    detail: `We need a claimant form and several other documents to process claim #1CL23523. To expedite processing of your claim fill out the requested forms and upload the requested documents as soon as possible. You can <a href="#">work on the claim here</a>. If you need assistance, please <a href="#">contact us</a>.`,
    buttons: [
      {
        buttonText: "Sign Up For Autopay",
        buttonClass: "ui-btn-secondary",
        url: "/payment/manage-payments",
      },
      {
        buttonText: "Make a Payment",
        buttonClass: "ui-btn-primary",
        url: "/payment",
      },
    ],
  },
  {
    uiTextColor: "text-ui-yellow",
    iconBorder: "ui-icon-border-risk",
    title: "Grid Attention: Claims packet available for you to complete -- 1",
    detail: `We need a claimant form and several other documents to process claim #1CL23523. To expedite processing of your claim fill out the requested forms and upload the requested documents as soon as possible. You can <a href="#">work on the claim here</a>. If you need assistance, please <a href="#">contact us</a>.`,
    buttons: [
      {
        buttonText: "Sign Up For Autopay",
        buttonClass: "ui-btn-secondary",
        url: "/payment/manage-payments",
      },
      {
        buttonText: "Make a Payment",
        buttonClass: "ui-btn-primary",
        url: "/payment",
      },
    ],
  },
  {
    uiTextColor: "text-ui-red",
    iconBorder: "ui-icon-border-problem",
    title: "Grid Attention: Claims packet available for you to complete -- 2",
    detail: `We need a claimant form and several other documents to process claim #1CL23523. To expedite processing of your claim fill out the requested forms and upload the requested documents as soon as possible. You can <a href="#">work on the claim here</a>. If you need assistance, please <a href="#">contact us</a>.`,
    buttons: [
      {
        buttonText: "Sign Up For Autopay",
        buttonClass: "ui-btn-secondary",
        url: "/payment/manage-payments",
      },
      {
        buttonText: "Make a Payment",
        buttonClass: "ui-btn-primary",
        url: "/payment",
      },
    ],
  },
];