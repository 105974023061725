import { Link } from "react-router-dom";

export default function ThingsYouCanDoCard({ data }) {
  return (
    <div className="ui-card mb-4 xl:mb-6">
      <div className="px-4 pt-4 pb-1 border-b border-theme-rule-light">
        <h2 className="text-sm font-body font-semibold">Things You Can Do</h2>
      </div>
      <div className="p-4">
        <div className="md:grid md:gap-4 md:grid-cols-3 lg:block">
          {data?.payment && (
            <div className="mb-6">
              <div className="font-semibold mb-2">Manage Payments</div>
              <ul className="ml-2 text-sm">
                {data.payment.map((link, idx) => {
                  return (
                    <li className="mb-2" key={`payment link ${idx}`}>
                      <Link to={link.url}>{link.name}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {data?.services && (
            <div className="mb-6">
              <div className="font-semibold mb-2">
                Manage Service Requests and Claims
              </div>
              <ul className="ml-2 text-sm">
                {data.services.map((link, idx) => {
                  return (
                    <li className="mb-2" key={`services link ${idx}`}>
                      <Link to={link.url}>{link.name}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
          {data?.documents && (
            <div className="mb-6">
              <div className="font-semibold mb-2">Get Policy Documents</div>
              <ul className="ml-2 text-sm">
                {data.documents.map((link, idx) => {
                  return (
                    <li className="mb-2" key={`docs link ${idx}`}>
                      <Link to={link.url}>{link.name}</Link>
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      </div>
      <button
        className="ui-card-btn-more"
        aria-label="View more thing you can do"
      >
        View More
      </button>
    </div>
  );
}
