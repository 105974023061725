import { Tab } from "@headlessui/react";
import {
  AgentData,
  commissionTotals,
} from "../../js/placeholderData/agents/agentStatementDetails";
import { useRowPagination } from "../../hooks/useRowPagination";
import RowPagination from "../../components/General/RowPagination";
import CommissionsCard from "../../components/AgentPortal/Commissions/CommissionsCard";
import SimpleDataRow from "../../components/General/SimpleDataRow";
import SortByDate from "../../components/General/SortByDate";
import FilterDialog from "../../components/AgentPortal/SalesMaterials/FilterDialog";
import { useState } from "react";


const Commissions = () => {
  const { rowsForDisplay: agentCommisionRows, rowPaginationProps } =
    useRowPagination(AgentData);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  return (
    <>
      <div className="container mb-8">
        <h1>Commissions</h1>
        <p>
          Lorem ipsum dolor sit amet, eam ad autem fabulas. Expetenda dissentiet
        </p>
        <hr />
      </div>

      <div className="container ui-component-spacing">
        <CommissionsCard data={commissionTotals} />
      </div>

      <div className="container ui-component-spacing">
        <h2>Statements</h2>
        <hr />
        <Tab.Group>
          <div>
            <div className="ui-card sm:col-span-2 md:col-span-1 px-2 p-1 md:px-4">
              <Tab.List>
                <Tab className="text-sm mx-3 pt-3 pb-2 mb-1 md:text-base md:mx-4 lg:text-lg">
                  Monthly
                </Tab>
                <Tab className="text-sm mx-3 pt-3 pb-2 mb-1 md:text-base md:mx-4 lg:text-lg">
                  YTD
                </Tab>
                <Tab className="text-sm mx-3 pt-3 pb-2 mb-1 md:text-base md:mx-4 lg:text-lg">
                  Tax forms
                </Tab>
              </Tab.List>
            </div>

            <div className="mx-2 flex flex-col items-centers justify-between md:mx-4 md:flex-row md:space-x-4">
              <div className="flex flex-row items-center mt-4 xl:mt-6">
                <SortByDate />
              </div>
              <div className="flex flex-row items-center mt-4 space-x-4 justify-between md:justify-end lg:space-x-6 xl:mt-6">
                <div>
                  <button
                    className="ui-btn-primary flex items-center"
                    onClick={() => setFilterDialogOpen(true)}
                  >
                    <svg
                      className="ui-icon w-3.5 h-3.5 mr-2"
                      focusable="false"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <use href="#ui-icon-filter"></use>
                    </svg>
                    Filter options
                  </button>
                  <FilterDialog
                    dialogState={filterDialogOpen}
                    setDialogState={setFilterDialogOpen}
                  />
                </div>
              </div>
            </div>
          </div>
          <Tab.Panels className="mt-4 xl:mt-6">
            <Tab.Panel>
              {agentCommisionRows.map((item, idx) => (
                <SimpleDataRow
                  key={`agent-commision-row-${idx}`}
                  type="commission"
                  data={item}
                />
              ))}
              <RowPagination {...rowPaginationProps} />
            </Tab.Panel>
            <Tab.Panel>
              <div className="border border-dashed border-ui-gray p-6 my-6">
                <div className="text-center">
                  <div className="text-xl font-bold">
                    Currently there are no YTD statements
                  </div>
                  <div className="mt-1 text-theme-text-quaternary text-sm">
                    Nothing to see here. Lorem ipsum.
                  </div>
                </div>
              </div>
            </Tab.Panel>
            <Tab.Panel>
              <div className="border border-dashed border-ui-gray p-6 my-6">
                <div className="text-center">
                  <div className="text-xl font-bold">
                    Currently there are no Tax forms
                  </div>
                  <div className="mt-1 text-theme-text-quaternary text-sm">
                    Nothing to see here. Lorem ipsum.
                  </div>
                </div>
              </div>
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </>
  );
};

export default Commissions;
