const PanelTwo = ({
  register,
  onSubmit,
  handleSubmit,
  errors,
  watch,
  validatePanel,
}) => {
  return (
    <div
      id="ID_registrationStep2Form"
      className="pt-4 mt-2 lg:pt-6 border-t border-theme-rule-medium"
    >
      <div className="m-auto md:w-3/4">
        <form onSubmit={handleSubmit(() => onSubmit(2))}>
          <div>
            <label id="ID_usernameLabel">Username</label>
            <input
              id="ID_username"
              className="w-full"
              type="text"
              {...register("username", {
                required: "Username is required",
              })}
              aria-describedby="ID_usernameError"
              aria-labelledby="ID_usernameLabel"
            />
            <div id="ID_usernameError">
              {errors.username && (
                <div className="ui-input-error">{errors.username.message}</div>
              )}
            </div>
          </div>

          <div id="ID_passwordDesc" className="mt-4 text-sm">
            <div className="font-semibold">
              In order to protect your account, make sure your password:
            </div>
            <ul className="list-disc ml-4">
              <li>
                Make sure the length of your password is at least 10
                alphanumeric characters.
              </li>
              <li>At least one special character: {"! @ # $ % & * ( )"}</li>
              <li>At least one upper- and lower-case character each</li>
              <li>At least one numerical digit</li>
            </ul>
          </div>

          <div className="mt-4">
            <label id="ID_passwordLabel">Password</label>
            <input
              id="ID_password"
              className="w-full"
              type="password"
              {...register("password", {
                required: "Password is required",
                validate: {
                  minimumCharCount: (v) =>
                    v.length >= 4 ||
                    "Password should be at least 10 charters long",
                  hasLowerCaseLetter: (v) =>
                    /^(?=.*[a-z])/.test(v) ||
                    "Password should have at least one lowercase letter",
                  hasCapitalizedCaseLetter: (v) =>
                    /^(?=.*[A-Z])/.test(v) ||
                    "Password should have at least one capitalized letter",
                  hasNumericLetter: (v) =>
                    /^(?=.*[0-9])/.test(v) ||
                    "Password should have at least one numeric character",
                  allowedSpecialChars: (v) =>
                    /^(?=.*[!@#\$%&\*\(\)])/.test(v) ||
                    "Password should have at least one special charecters: ! @ # $ % & * ( )",
                  illegalLetter: (v) =>
                    v.replace(/[A-Za-z0-9!@#\$%&\*\(\)]/g, "").length < 1 ||
                    "MUST USE ONLY ! @ # $ % & * ( ) AS A SPECIAL CHARACTER",
                },
              })}
              aria-describedby="ID_passwordDesc ID_passwordError"
              aria-labelledby="ID_passwordLabel"
            />
            <div id="ID_passwordError">
              {errors.password && (
                <div className="ui-input-error">{errors.password.message}</div>
              )}
            </div>
          </div>
          <div className="mt-4">
            <label id="ID_confirmPasswordLabel">Confirm Password</label>
            <input
              id="ID_confirmPassword"
              className="w-full"
              type="password"
              {...register("confirmPassword", {
                validate: (value) => {
                  if (watch("password") !== value) {
                    return "The passwords do not match";
                  }
                },
              })}
              aria-describedby="ID_passwordDesc ID_confirmPasswordError"
              aria-labelledby="ID_confirmPasswordLabel"
            />
            <div id="ID_confirmPasswordError">
              {errors.confirmPassword && (
                <div className="ui-input-error">
                  {errors.confirmPassword.message}
                </div>
              )}
            </div>
          </div>
          <div className="mt-4">
            <label id="ID_emailLabel">Email Address *</label>
            <input
              id="ID_email"
              className="w-full"
              type="text"
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/,
                  message: "please enter a valid email",
                },
              })}
              aria-describedby="ID_emailError"
              aria-labelledby="ID_emailLabel"
            />
            <div id="ID_emailError">
              {errors.email?.message && (
                <div className="ui-input-error">{errors.email?.message}</div>
              )}
            </div>
          </div>
          <div className="mt-4">
            <label id="ID_emailConfirmLabel">Re-enter Email Address *</label>
            <input
              id="ID_emailConfirm"
              className="w-full"
              type="text"
              {...register("confirmEmail", {
                required: "please re-enter your email",
                validate: {
                  value: (val) => {
                    if (watch("email") !== val) {
                      return "email does not match";
                    }
                  },
                },
              })}
              aria-describedby="ID_emailConfirmError"
              aria-labelledby="ID_emailConfirmLabel"
            />
            <div id="ID_emailConfirmError">
              {errors.confirmEmail?.message && (
                <div className="ui-input-error">
                  {errors.confirmEmail?.message}
                </div>
              )}
            </div>
          </div>

          <div className="mt-4 text-right">
            <button
              className="ui-btn-secondary ui-btn-min-w mt-2"
              onClick={() => validatePanel(0)}
            >
              Previous<span className="sr-only"> step</span>
            </button>
            <button
              id="ID_registrationStep2Btn"
              className="ui-btn-primary ui-btn-min-w mt-2"
              aria-controls="ID_registrationStep3Form"
              aria-expanded="false"
            >
              Continue<span className="sr-only"> to step 3</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PanelTwo;
