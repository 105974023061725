export const requiredSalesMaterialsData = [
  {
    title: "AML Training",
    expandableTriggerText: "Training due or overdue:",
    instructions:
      "Copy abouttraining category of the training catelog and what can be found here.",
    showCount: true,
    badgeText: "Overdue",
    badgeStatus: "problem",
    links: [
      {
        icon: "#ui-icon-document",
        label: "Lorem ipsum dolor",
        url: "/help/article/1",
      },
      {
        icon: "#ui-icon-document",
        label: "Lorem ipsum dolor",
        url: "/help/article/1",
      },
      {
        icon: "#ui-icon-document",
        label: "Lorem ipsum dolor",
        url: "/help/article/1",
      },
      {
        icon: "#ui-icon-document",
        label: "Lorem ipsum dolor",
        url: "/help/article/1",
      },
    ],
  },
  {
    expandableTriggerText: "Upcoming Training: ",
    title: "Product Training",
    instructions:
      "Copy abouttraining category of the training catelog and what can be found here.",
    showCount: true,
    badgeText: "New product",
    badgeStatus: "risk",
    links: [
      {
        icon: "#ui-icon-document",
        label: "Lorem ipsum dolor",
        url: "/help/article/1",
      },
      {
        icon: "#ui-icon-document",
        label: "Lorem ipsum dolor",
        url: "/help/article/1",
      },
      {
        icon: "#ui-icon-document",
        label: "Lorem ipsum dolor",
        url: "/help/article/1",
      },
    ],
  },
  {
    expandableTriggerText: "Upcoming Training: ",
    title: "Suitability Training",
    instructions:
      "Copy abouttraining category of the training catelog and what can be found here.",
    links: [
      {
        icon: "#ui-icon-document",
        label: "Lorem ipsum dolor",
        url: "/help/article/1",
      },
      {
        icon: "#ui-icon-document",
        label: "Lorem ipsum dolor",
        url: "/help/article/1",
      },
      {
        icon: "#ui-icon-document",
        label: "Lorem ipsum dolor",
        url: "/help/article/1",
      },
    ],
  },
  {
    expandableTriggerText: "Upcoming Training: ",
    title: "Compliance Training",
    instructions:
      "Copy abouttraining category of the training catelog and what can be found here.",
    links: [
      {
        icon: "#ui-icon-document",
        label: "Lorem ipsum dolor",
        url: "/help/article/1",
      },
      {
        icon: "#ui-icon-document",
        label: "Lorem ipsum dolor",
        url: "/help/article/1",
      },
      {
        icon: "#ui-icon-document",
        label: "Lorem ipsum dolor",
        url: "/help/article/1",
      },
    ],
  },
];

export const recommandedSalesMaterialsData = [
  {
    expandableTriggerText: "Upcoming Training: ",
    title: "Product Training",
    instructions:
      "Copy abouttraining category of the training catelog and what can be found here.",
    showCount: true,
    badgeText: "New product",
    badgeStatus: "risk",
    links: [
      {
        icon: "#ui-icon-document",
        label: "Lorem ipsum dolor",
        url: "/help/article/1",
      },
      {
        icon: "#ui-icon-document",
        label: "Lorem ipsum dolor",
        url: "/help/article/1",
      },
      {
        icon: "#ui-icon-document",
        label: "Lorem ipsum dolor",
        url: "/help/article/1",
      },
    ],
  },
  {
    expandableTriggerText: "Upcoming Training: ",
    title: "Suitability Training",
    instructions:
      "Copy abouttraining category of the training catelog and what can be found here.",
    links: [
      {
        icon: "#ui-icon-document",
        label: "Lorem ipsum dolor",
        url: "/help/article/1",
      },
      {
        icon: "#ui-icon-document",
        label: "Lorem ipsum dolor",
        url: "/help/article/1",
      },
      {
        icon: "#ui-icon-document",
        label: "Lorem ipsum dolor",
        url: "/help/article/1",
      },
    ],
  },
];

export const pinnedSalesMaterialsData = [
  {
    expandableTriggerText: "Upcoming Training: ",
    title: "Product Training",
    instructions:
      "Copy abouttraining category of the training catelog and what can be found here.",
    showCount: true,
    badgeText: "New product",
    badgeStatus: "risk",
    links: [
      {
        icon: "#ui-icon-document",
        label: "Lorem ipsum dolor",
        url: "/help/article/1",
      },
      {
        icon: "#ui-icon-document",
        label: "Lorem ipsum dolor",
        url: "/help/article/1",
      },
      {
        icon: "#ui-icon-document",
        label: "Lorem ipsum dolor",
        url: "/help/article/1",
      },
    ],
  },
];

export const completedSalesMaterialsData = [
  {
    name: "name of training",
    category: "Traning Type",
    completedDate: "01/01/2023",
    goodForDuration: "1 Year",
    another: "<data point>",
  },
  {
    name: "name of training",
    category: "Traning Type",
    completedDate: "01/01/2023",
    goodForDuration: "1 Year",
    another: "<data point>",
  },
  {
    name: "name of training",
    category: "Traning Type",
    completedDate: "01/01/2023",
    goodForDuration: "1 Year",
    another: "<data point>",
  },
  {
    name: "name of training",
    category: "Traning Type",
    completedDate: "01/01/2023",
    goodForDuration: "1 Year",
    another: "<data point>",
  },
  {
    name: "name of training",
    category: "Traning Type",
    completedDate: "01/01/2023",
    goodForDuration: "1 Year",
    another: "<data point>",
  },
  {
    name: "name of training",
    category: "Traning Type",
    completedDate: "01/01/2023",
    goodForDuration: "1 Year",
    another: "<data point>",
  },
  {
    name: "name of training",
    category: "Traning Type",
    completedDate: "01/01/2023",
    goodForDuration: "1 Year",
    another: "<data point>",
  },
  {
    name: "name of training",
    category: "Traning Type",
    completedDate: "01/01/2023",
    goodForDuration: "1 Year",
    another: "<data point>",
  },
  {
    name: "name of training",
    category: "Traning Type",
    completedDate: "01/01/2023",
    goodForDuration: "1 Year",
    another: "<data point>",
  },
  {
    name: "name of training",
    category: "Traning Type",
    completedDate: "01/01/2023",
    goodForDuration: "1 Year",
    another: "<data point>",
  },
  {
    name: "name of training",
    category: "Traning Type",
    completedDate: "01/01/2023",
    goodForDuration: "1 Year",
    another: "<data point>",
  },
  {
    name: "name of training",
    category: "Traning Type",
    completedDate: "01/01/2023",
    goodForDuration: "1 Year",
    another: "<data point>",
  },
  {
    name: "name of training",
    category: "Traning Type",
    completedDate: "01/01/2023",
    goodForDuration: "1 Year",
    another: "<data point>",
  },
  {
    name: "name of training",
    category: "Traning Type",
    completedDate: "01/01/2023",
    goodForDuration: "1 Year",
    another: "<data point>",
  },
  {
    name: "name of training",
    category: "Traning Type",
    completedDate: "01/01/2023",
    goodForDuration: "1 Year",
    another: "<data point>",
  },
  {
    name: "name of training",
    category: "Traning Type",
    completedDate: "01/01/2023",
    goodForDuration: "1 Year",
    another: "<data point>",
  },
  {
    name: "name of training",
    category: "Traning Type",
    completedDate: "01/01/2023",
    goodForDuration: "1 Year",
    another: "<data point>",
  },
];
