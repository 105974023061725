import { Tab } from "@headlessui/react";
import { usePagination } from "../../hooks/usePagination";
import ServicesAndClaimsRow from "./ServicesAndClaimsRow";
import Pagination from "../General/Pagination";

export default function ServiceRequestsTab({ data }) {
  const { current, paginationProps } = usePagination(data, 3);
  return (
    <Tab.Panel>
      {current.map((service, key) => {
        return (
          <ServicesAndClaimsRow
            key={`${service.id} - ${key}`}
            type="service"
            data={service}
          />
        );
      })}

      {/* <!-- paging component --> */}
      <div className="flex justify-between items-start mt-6">
        <div></div>
        <Pagination {...paginationProps} />
      </div>
      {/* <!-- paging component --> */}
    </Tab.Panel>
  );
}
