import { v4 as uuidv4 } from "uuid";
import { useCollapsePanel } from "../../../../hooks/useCollapsePanel";

const AgentInfoCard = ({ policy, policyDetail }) => {
  const uuid = uuidv4();
  useCollapsePanel(uuid);
  return (
    <>
      <div className="ui-card">
        <div className="p-4 xl:pt-6 xl:px-6">
          <div className="flex flex-col md:flex-row md:justify-between md:items-center">
            <h2 className="mb-0">
              <button
                id={uuid}
                className="mb-0 flex items-center group md:hidden"
                aria-controls={`${uuid}-panel`}
                aria-expanded="true"
                aria-label="Expand or collapse agent info details"
              >
                <span className="inline-block p-1 rounded-full mr-1 text-ui-blue group-hover:text-white group-hover:bg-ui-blue">
                  <svg
                    className="ui-icon ui-plus w-4 h-4"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-plus"></use>
                  </svg>
                  <svg
                    className="ui-icon ui-minus w-4 h-4"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-minus"></use>
                  </svg>
                </span>
                <span className="text-base font-body font-bold">
                  Agent
                </span>
              </button>
              <span className="text-xl font-body font-bold hidden md:block">
                Agent
              </span>
            </h2>
          </div>
        </div>
        <hr className="ui-mobile-collapse" />
        <div
          id={`${uuid}-panel`}
          className="overflow-hidden h-20 p-4 md:h-auto xl:p-6"
        >
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:gap-6">
            <div>
              <div className="text-xs text-theme-text-tertiary">Name</div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {policyDetail.agent.name}
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">Address</div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                <div>
                  {policyDetail.agent.address.streetNumber}{" "}
                  {policyDetail.agent.address.streetName}
                </div>
                <div>
                  {policyDetail.agent.address.city},{" "}
                  {policyDetail.agent.address.state}{" "}
                  {policyDetail.agent.address.zipCode}
                </div>
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">Phone</div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {policyDetail.agent.phone}
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">Email</div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {policyDetail.agent.email}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentInfoCard;
