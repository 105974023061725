// To use, assign uuid to a button as ID and add aria-controls as `${uuid}-panel`
// then wrap the panel with a div with ID = `${uuid}-panel` for anything you wish to be wrapped

import { useEffect } from "react";
export const useCollapsePanel = (elementId) => {
  useEffect(() => {
    new collapsePanel(document.getElementById(elementId));
  }, [elementId]);
};

var getStyle = function (e, styleName) {
  var styleValue = "";
  if (document.defaultView && document.defaultView.getComputedStyle) {
    styleValue = document.defaultView
      .getComputedStyle(e, "")
      .getPropertyValue(styleName);
  } else if (e.currentStyle) {
    styleName = styleName.replace(/\-(\w)/g, function (strMatch, p1) {
      return p1.toUpperCase();
    });
    styleValue = e.currentStyle[styleName];
  }
  return styleValue;
};

class collapsePanel {
  constructor(domNode) {
    this.rootNode = domNode;
    this.panel = document.getElementById(
      this.rootNode.getAttribute("aria-controls")
    );

    if (getStyle(this.rootNode, "display") === "none") {
      this.rootNode.setAttribute("aria-expanded", "true");
    } else {
      this.rootNode.setAttribute("aria-expanded", "false");
    }

    this.rootNode.addEventListener("click", this.onButtonClick.bind(this));
    window.addEventListener("resize", this.onResize.bind(this));
  }
  onButtonClick(event) {
    if (this.panel?.hasAttribute("style")) {
      this.panel.removeAttribute("style");
      this.rootNode.setAttribute("aria-expanded", "false");
    } else {
      this.panel.style.height = "auto";
      this.rootNode.setAttribute("aria-expanded", "true");
    }
  }
  onResize(event) {
    if (this.panel.hasAttribute("style")) this.panel.removeAttribute("style");
    if (getStyle(this.rootNode, "display") === "none") {
      this.rootNode.setAttribute("aria-expanded", "true");
    } else {
      this.rootNode.setAttribute("aria-expanded", "false");
    }
  }
}
