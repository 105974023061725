import { usePagination } from "../../../hooks/usePagination";
import SimpleDataRow from "../../General/SimpleDataRow";
import Pagination from "../../General/Pagination";

export default function FilteredReportTabPanel({ reports }) {
  const NUMBERPERPAGE = 5;
  const { current: currentSelectedReport, paginationProps } = usePagination(
    reports,
    NUMBERPERPAGE
  );
  return (
    <>
      {currentSelectedReport.map((report, idx) => {
        return (
          <SimpleDataRow
            key={`agent-report-category-${report.category} - ${idx}`}
            type="report"
            data={report}
          />
        );
      })}
      {/* <!-- paging component --> */}
      <div className="flex justify-between items-start mt-6">
        <div></div>
        <Pagination {...paginationProps} />
      </div>
    </>
  );
}
