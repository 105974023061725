import { useState } from "react";
import { Tab, Dialog } from "@headlessui/react";
import { agentServiceClaimNavigationCardData } from "../../js/placeholderData/agents/agentServiceClaimNavigationCardData";
import { services, claims } from "../../js/placeholderData/servicesAndClaims";
import SimpleCallToActionCard from "../../components/General/SimpleCallToActionCard";
import AppNavigationCard from "../../components/General/AppNavigationCard";
import ServiceRequestsTab from "../../components/ServicesAndClaims/ServiceRequestsTab";
import ClaimsTab from "../../components/ServicesAndClaims/ClaimsTab";
import DialogBackdrop from "../../components/ModalDialogs/DialogBackdrop";

export default function ServicesClaims() {
  let [isOpenFiltersDialog, setIsOpenFilterDialog] = useState(false);
  return (
    <>
      <div className="container mb-8">
        <h1>Services &amp; Claims</h1>
        <p>
          Lorem ipsum dolor sit amet, eam ad autem fabulas. Expetenda dissentiet
        </p>
        <hr />
      </div>
      <div className="container mb-8">
        <h2>Services For Your Customers</h2>
        <hr />
      </div>
      <div className="container ui-component-spacing">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
          <SimpleCallToActionCard
            title="Quote &amp; Run Illustrations"
            desc="Copy about how payments that haven't been processed can be edited (draft date, payment method, etc.)"
            ctaText="Take Action"
            ctaUrl="/quote-run-illustrations"
          />
          <SimpleCallToActionCard
            title="Make Policy Changes"
            desc="Add or edit saved payment methods here. You can lorem ipsom dolor sit amen consectetur alum ut wish."
            ctaText="Take Action"
            ctaUrl="/make-policy-changes"
          />
          <SimpleCallToActionCard
            title="Submit an Application"
            desc="Blurb about benefits and reasons to opt for paperless billing go here lore Epsom dolor."
            ctaText="Take Action"
            ctaUrl="/submit-application"
          />

          <SimpleCallToActionCard
            title="Make Payment for a Customer"
            desc="Blurb about benefits and reasons to enroll in autopay go here lore Epsom dolor. You can edit your settings any time"
            ctaText="Take Action"
            ctaUrl="/assist-with-payments"
          />
          <SimpleCallToActionCard
            title="Learn About Claims"
            desc="Blurb about late fees or other relevant fees customers tend to have questions about goes here lore ipsum dolor sit amet dolor est."
            ctaText="Take Action"
            ctaUrl="/assist-with-claims"
          />
        </div>
      </div>
      <div className="container ui-component-spacing">
        <h2>Services For You</h2>
        <hr />
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 auto-rows-fr">
          {agentServiceClaimNavigationCardData.map((item, idx) => (
            <AppNavigationCard
              key={`agent-app-navigation-card-${idx}`}
              icon={item.icon}
              title={item.title}
              desc={item.desc}
              url={item.url}
            />
          ))}
        </div>
      </div>
      {(services.length > 0 || claims.length > 0) && (
        <div className="container ui-component-spacing">
          <h2>Service Requests &amp; Claims</h2>
          <hr />

          <Tab.Group>
            <div className="ui-card flex-grow px-2 p-1 md:px-4">
              <Tab.List>
                {services.length && (
                  <Tab className="text-sm mx-3 pt-3 pb-2 mb-1 md:text-base md:mx-4 lg:text-lg">
                    Service Requests
                  </Tab>
                )}
                {claims.length && (
                  <Tab className="text-sm mx-3 pt-3 pb-2 mb-1 md:text-base md:mx-4 lg:text-lg">
                    Claims
                  </Tab>
                )}
              </Tab.List>
            </div>

            <div className="mt-4 lg:flex lg:items-start lg:justify-between lg:mt-6">
              <div className="hidden lg:flex lg:items-center">
                <label
                  id="ID_categoryLabel"
                  htmlFor="ID_category"
                  className="mr-2"
                >
                  Category
                </label>
                <select id="ID_category" aria-labelledby="ID_categoryLabel">
                  <option>Show all</option>
                  <option>-----------------------</option>
                </select>
              </div>

              <div className="hidden lg:flex lg:items-start lg:mx-4">
                <label
                  id="ID_searchPolicyLabel"
                  htmlFor="ID_searchPolicy"
                  className="mt-2 mr-2"
                >
                  Search By Policy
                </label>
                <div>
                  <div className="flex flex-col flex-1 w-full relative">
                    <input
                      id="ID_searchPolicy"
                      aria-labelledby="ID_searchPolicyLabel"
                      aria-describedby="ID_searchPolicyError"
                      type="text"
                      className="pl-8 w-[350px] "
                      placeholder="Enter policy number or policy holder name"
                    />
                    <svg
                      className="ui-icon text-theme-action w-4 h-4 absolute ml-2 mt-3"
                      focusable="false"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <use href="#ui-icon-search"></use>
                    </svg>
                  </div>
                  <div id="ID_searchPolicyError" className="text-ui-red"></div>
                </div>
              </div>

              <div>
                <button
                  className="ui-btn-primary"
                  onClick={() => setIsOpenFilterDialog(true)}
                >
                  Filter Options
                </button>
              </div>
            </div>

            <Tab.Panels className="mt-4 lg:mt-6">
              {/* Service Requests */}
              {services.length && <ServiceRequestsTab data={services} />}
              {/* Claims */}
              {claims.length && <ClaimsTab data={claims} />}
            </Tab.Panels>
          </Tab.Group>

          {/* last component div */}
        </div>
      )}

      {/* <!-- dialogs --> */}
      {/* Don't think there is a wireframe, as seen in mocks and prototype */}
      <Dialog
        open={isOpenFiltersDialog}
        onClose={() => setIsOpenFilterDialog(false)}
        className="ui-dialogs"
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <DialogBackdrop />
        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel
            role="dialog"
            aria-modal="true"
            className="ui-default-dialog"
          >
            <Dialog.Title className="font-body text-xl font-bold text-left">
              Filter Options
            </Dialog.Title>
            <button
              className="ui-default-dialog-close"
              aria-label="close cancel payment window"
              onClick={() => setIsOpenFilterDialog(false)}
            >
              <svg
                className="ui-icon h-3.5 w-3.5"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href="#ui-icon-close"></use>
              </svg>
            </button>
            <hr />
            <Dialog.Description>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
              tincidunt mi sit amet ipsum tempus interdum.
            </Dialog.Description>

            <Tab.Group>
              <div className="p-1">
                <Tab.List>
                  <Tab className="text-base mr-4">All</Tab>
                  <Tab className="text-base mr-4">Agent(s)</Tab>
                  <Tab className="text-base mr-4">Customers</Tab>
                </Tab.List>
              </div>
              <Tab.Panels className="mt-4 lg:mt-6">
                {/* Service Requests */}
                <Tab.Panel>
                  All filters panel
                  <br />
                  <br />
                  ** Need to ask
                  <br />
                  Visual design makes it look like tabs <br />
                  If these are tabs with different filter options or if this
                  should be radio buttons instead. <br />
                </Tab.Panel>
                <Tab.Panel>Agent(s) filters</Tab.Panel>
                <Tab.Panel>Customers filters</Tab.Panel>
              </Tab.Panels>
            </Tab.Group>

            <div className="mt-4 lg:hidden">
              <label
                id="ID_categoryDialogLabel"
                htmlFor="ID_categoryDialog"
                className="block mb-1 mr-2"
              >
                Category
              </label>
              <select
                id="ID_categoryDialog"
                aria-labelledby="ID_categoryDialogLabel"
              >
                <option>Show all</option>
                <option>-----------------------</option>
              </select>
            </div>

            <div className="mt-4 lg:hidden">
              <label
                id="ID_searchPolicy2Label"
                htmlFor="ID_searchPolicy2"
                className="block pb-1 mr-2"
              >
                Search By Policy
              </label>
              <div className="flex flex-1 w-full relative">
                <input
                  id="ID_searchPolicy2"
                  aria-labelledby="ID_searchPolicy2Label"
                  aria-describedby=""
                  type="text"
                  className="pl-8"
                  placeholder="Enter policy number or policy holder name"
                />
                <svg
                  className="ui-icon text-theme-action w-4 h-4 absolute ml-2 mt-3"
                  focusable="false"
                  aria-hidden="true"
                  role="presentation"
                >
                  <use href="#ui-icon-search"></use>
                </svg>
              </div>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
              <div>
                <fieldset>
                  <legend className="block font-semibold text-xs">
                    Sort by
                  </legend>
                  <div className="mt-1">
                    <label className="inline-flex sm:items-center text-sm font-normal">
                      <input
                        type="radio"
                        name="sortby"
                        id="ID_sortbyNewestOldest"
                        value="yes"
                        className="mr-2"
                      />
                      Newest to oldest
                    </label>
                  </div>
                  <div className="mt-1">
                    <label className="inline-flex items-center  text-sm font-normal">
                      <input
                        type="radio"
                        name="sortby"
                        id="ID_sortbyLast30Days"
                        value="no"
                        className="mr-2"
                      />
                      Last 30 days
                    </label>
                  </div>
                </fieldset>
              </div>
              <div>
                <div
                  id="ID_statusLabel"
                  className="block font-semibold text-xs"
                >
                  Status
                </div>
                <label className="ui-checkbox mt-1">
                  <input
                    id="ID_statusActionRequired"
                    type="checkbox"
                    aria-describedby="ID_statusLabel"
                  />
                  <div className="ui-checkmark">
                    <svg
                      className="ui-icon w-2 h-2 text-white"
                      focusable="false"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <use href="#ui-icon-checkmark"></use>
                    </svg>
                  </div>
                  <span>Action required</span>
                </label>
                <label className="ui-checkbox mt-1">
                  <input
                    id="ID_statusProcessing"
                    type="checkbox"
                    aria-describedby="ID_statusLabel"
                  />
                  <div className="ui-checkmark">
                    <svg
                      className="ui-icon w-2 h-2 text-white"
                      focusable="false"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <use href="#ui-icon-checkmark"></use>
                    </svg>
                  </div>
                  <span>Processing</span>
                </label>
                <label className="ui-checkbox mt-1">
                  <input
                    id="ID_statusProcessed"
                    type="checkbox"
                    aria-describedby="ID_statusLabel"
                  />
                  <div className="ui-checkmark">
                    <svg
                      className="ui-icon w-2 h-2 text-white"
                      focusable="false"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <use href="#ui-icon-checkmark"></use>
                    </svg>
                  </div>
                  <span>Processed</span>
                </label>
              </div>
            </div>

            {/* <!-- Buttons --> */}
            <div className="block text-right mt-6">
              <button
                className="ui-btn-secondary ui-btn-min-w mt-4"
                aria-label="Close window without applying filters"
                onClick={() => setIsOpenFilterDialog(false)}
              >
                Cancel
              </button>
              <button
                id="ID_applyFilterOptions"
                className="ui-btn-primary ui-btn-min-w mt-4"
                onClick={() => setIsOpenFilterDialog(false)}
              >
                Apply filters
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
}
