
import { useRowPagination } from "../../../hooks/useRowPagination";
import RowPagination from "../../../components/General/RowPagination";

const PaymentTab = ({ updatePolicyChoice, policies }) => {
  const { rowsForDisplay: policiesRows, rowPaginationProps } =
    useRowPagination(policies);

  return (
    <>
      {/* Pending Payments */}
      <div className="ui-component-spacing">
        <h3 className="font-brand text-lg md:text-2xl">Pending Payments</h3>
        <hr className="ui-under-title" />
        {policiesRows.map((item, idx) => (
          <div key={idx} className="ui-card-bar mt-4">
            <div className="px-3 py-2 lg:p-4">
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 md:grid-cols-5 xl:grid-cols-5 xl:gap-6">
                <div>
                  <div className="text-xs text-theme-text-tertiary">
                    {item.policyTitle}
                  </div>
                  <div className="text-sm font-semibold text-theme-text-secondary">
                    <div>#{item.policyNumber}</div>
                  </div>
                </div>
                <div>
                  <div className="text-xs text-theme-text-tertiary">
                    Payment amount
                  </div>
                  <div className="text-sm font-semibold text-theme-text-secondary">
                    <div>{item.paymentAmount}</div>
                  </div>
                </div>
                <div>
                  <div className="text-xs text-theme-text-tertiary">
                    Payment method
                  </div>
                  <div className="text-sm font-semibold text-theme-text-secondary">
                    <div>{item.paymentMethod}</div>
                  </div>
                </div>
                <div>
                  <div className="text-xs text-theme-text-tertiary">
                    Payment submitted
                  </div>
                  <div className="text-sm font-semibold text-theme-text-secondary">
                    <div>{item.dueDate}</div>
                  </div>
                </div>
                <div className="md:justify-self-end">
                  <div>
                    <div className="text-xs text-theme-text-tertiary">
                      You can
                    </div>
                    <div className="text-sm font-semibold text-theme-text-secondary">
                      <button className="ui-btn-hyperlink font-semibold ">
                        Cancel
                        <span className="sr-only">
                          {" "}
                          policy number {item.policyNumber}
                        </span>
                      </button>
                      <span className="mx-2">|</span>
                      <button className="ui-btn-hyperlink font-semibold">
                        Edit
                        <span className="sr-only">
                          {" "}
                          policy number {item.policyNumber}{" "}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
        <RowPagination {...rowPaginationProps} />
      </div>
      {/* Completed payments */}
      <div className="ui-component-spacing">
        <h3 className="font-brand text-lg md:text-2xl">Completed payments</h3>
        <hr className="ui-under-title" />

        {policies.map((item, idx) => (
          <div key={idx} className="ui-card-bar mt-4">
            <div className="px-3 py-2 lg:p-4">
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-3 md:grid-cols-5 xl:grid-cols-5 xl:gap-6">
                <div>
                  <div className="text-xs text-theme-text-tertiary">
                    {item.policyTitle}
                  </div>
                  <div className="text-sm font-semibold text-theme-text-secondary">
                    #{item.policyNumber}
                  </div>
                </div>
                <div>
                  <div className="text-xs text-theme-text-tertiary">
                    Payment amount
                  </div>
                  <div className="text-sm font-semibold text-theme-text-secondary">
                    {item.paymentAmount}
                  </div>
                </div>
                <div>
                  <div className="text-xs text-theme-text-tertiary">
                    Payment method
                  </div>
                  <div className="text-sm font-semibold text-theme-text-secondary">
                    {item.paymentMethod}
                  </div>
                </div>
                <div>
                  <div className="text-xs text-theme-text-tertiary">
                    Payment posted
                  </div>
                  <div className="text-sm font-semibold text-theme-text-secondary">
                    {item.dueDate}
                  </div>
                </div>
                <div className="md:justify-self-end">
                  <div>
                    <div className="text-xs text-theme-text-tertiary">
                      You can
                    </div>
                    <div className="text-sm font-semibold text-theme-text-secondary">
                      <button className="ui-btn-hyperlink font-semibold">
                        Export
                        <span className="sr-only">
                          {" "}
                          policy number {item.policyNumber}
                        </span>
                      </button>
                      <span className="mx-2">|</span>
                      <button className="ui-btn-hyperlink font-semibold">
                        Print
                        <span className="sr-only">
                          {" "}
                          policy number {item.policyNumber}{" "}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

export default PaymentTab;