import { Dialog } from "@headlessui/react";
import DialogBackdrop from '../../ModalDialogs/DialogBackdrop'

const ExportDialogModal = ({
  isOpenExport,
  setIsOpenExport
}) => {

  return (
    <>
      <Dialog
        open={isOpenExport}
        onClose={() => setIsOpenExport(false)}
        className="ui-dialogs"
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <DialogBackdrop />
        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel
            role="dialog"
            aria-modal="true"
            className="ui-default-dialog"
          >
            <Dialog.Title className="font-body text-xl font-bold text-left">
              Export Report
            </Dialog.Title>
            <button
              className="ui-default-dialog-close"
              aria-label="close edit photo window"
              onClick={() => setIsOpenExport(false)}
            >
              <svg
                className="ui-icon h-3.5 w-3.5"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href="#ui-icon-close"></use>
              </svg>
            </button>
            <Dialog.Description>
              Lorem ipsum dolor sit amet l orem ipsum dolor sit ametLorem ipsum
              dolor sit amet
            </Dialog.Description>
            <hr />
            <div className="my-4 flex justify-center">
              {/* Excel */}
              <button
                aria-label="Export report as excel file"
                className="group text-theme-action hover:text-theme-action-hover"
              >
                <span className="ui-btn-icon mx-2 flex items-center justify-center w-12 h-12 rounded-full border border-theme-rule-medium group-hover:bg-theme-action-hover group-hover:text-white">
                  <svg
                    className="ui-icon h-6 w-6 mx-auto"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-collection"></use>
                  </svg>
                </span>
                <span className="mt-2 block text-sm group-hover:underline">
                  Excel
                </span>
              </button>
              {/* PDF */}
              <button
                aria-label="Export report as pdf file"
                className="group text-theme-action hover:text-theme-action-hover"
              >
                <span className="ui-btn-icon mx-2 flex items-center justify-center w-12 h-12 rounded-full border border-theme-rule-medium group-hover:bg-theme-action-hover group-hover:text-white">
                  <svg
                    className="ui-icon h-6 w-6 mx-auto"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-book"></use>
                  </svg>
                </span>
                <span className="mt-2 block text-sm group-hover:underline">
                  PDF
                </span>
              </button>
              {/* File */}
              <button
                aria-label="Export report as file"
                className="group text-theme-action hover:text-theme-action-hover"
              >
                <span className="ui-btn-icon mx-2 flex items-center justify-center w-12 h-12 rounded-full border border-theme-rule-medium group-hover:bg-theme-action-hover group-hover:text-white">
                  <svg
                    className="ui-icon h-6 w-6 mx-auto"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-document"></use>
                  </svg>
                </span>
                <span className="mt-2 block text-sm group-hover:underline">
                  File
                </span>
              </button>
              {/* File */}
              <button
                aria-label="Export report as file"
                className="group text-theme-action hover:text-theme-action-hover"
              >
                <span className="ui-btn-icon mx-2 flex items-center justify-center w-12 h-12 rounded-full border border-theme-rule-medium group-hover:bg-theme-action-hover group-hover:text-white">
                  <svg
                    className="ui-icon h-6 w-6 mx-auto"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-message2"></use>
                  </svg>
                </span>
                <span className="mt-2 block text-sm group-hover:underline">
                  File
                </span>
              </button>
            </div>
            <div className="block text-right mt-6">
              <button
                className="ui-btn-secondary ui-btn-min-w mt-4"
                onClick={() => setIsOpenExport(false)}
              >
                Close
                <span className="sr-only"> your account photo </span>
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  )
}

export default ExportDialogModal