export const allPolicyViewLinks = [
  {
    id: 1,
    name: 'Run illustrations',
    route: '/'
  },
  {
    id: 2,
    name: 'Quote additional products/riders',
    route: '/'
  },
  {
    id: 3,
    name: 'Quote new product',
    route: '/'
  },
  
]