import Tooltip from "../../General/Tooltip";

export default function ClaimStatusCard({ data }) {
  let stepOneClasses, stepTwoClasses, stepThreeClasses, stepFourClasses;
  switch (data.step) {
    case 1:
      stepOneClasses = "bg-theme-action text-white";
      stepTwoClasses =
        "border border-dashed border-theme-action text-theme-action";
      stepThreeClasses =
        "border border-dashed border-theme-action text-theme-action";
      stepFourClasses =
        "border border-dashed border-theme-action text-theme-action";
      break;

    case 2:
      stepOneClasses = "bg-ui-green text-white";
      stepTwoClasses = "bg-theme-action text-white";
      stepThreeClasses =
        "border border-dashed border-theme-action text-theme-action";
      stepFourClasses =
        "border border-dashed border-theme-action text-theme-action";
      break;

    case 3:
      stepOneClasses = "bg-ui-green text-white";
      stepTwoClasses = "bg-ui-green text-white";
      stepThreeClasses = "bg-theme-action text-white";
      stepFourClasses =
        "border border-dashed border-theme-action text-theme-action";
      break;

    case 4:
      stepOneClasses = "bg-ui-green text-white";
      stepTwoClasses = "bg-ui-green text-white";
      stepThreeClasses = "bg-ui-green text-white";
      stepFourClasses = "bg-theme-action text-white";
      break;
    default:
      stepOneClasses =
        "border border-dashed border-theme-action text-theme-action";
      stepTwoClasses =
        "border border-dashed border-theme-action text-theme-action";
      stepThreeClasses =
        "border border-dashed border-theme-action text-theme-action";
      stepFourClasses =
        "border border-dashed border-theme-action text-theme-action";
      break;
  }
  return (
    <div className="flex justify-center mt-4 lg:mt-6">
      <div className="inline-grid grid-cols-1 md:grid-cols-4 justify-items-stretch content-center text-center">
        <div className="ui-claim-process-start relative">
          <div
            className={`ui-claim-process-step bg-white ${
              data.step === 1 ? "ui-claim-process-step-current" : ""
            }`}
          >
            <div
              className={`w-12 h-12 rounded-full flex items-center justify-center ${stepOneClasses}`}
            >
              <svg
                className="ui-icon w-4 h-4"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href="#ui-icon-edit"></use>
              </svg>
            </div>
          </div>
          <div className="text-xs">
            File a claim
            <Tooltip
              srText="Tooltip for file a claim"
              content="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
              xMargin="mx-1"
            />
          </div>
        </div>

        <div className="ui-claim-process-middle relative">
          <div
            className={`ui-claim-process-step bg-white ${
              data.step === 2 ? "ui-claim-process-step-current" : ""
            }`}
          >
            <div
              className={`w-12 h-12 rounded-full flex items-center justify-center ${stepTwoClasses}`}
            >
              <svg
                className="ui-icon w-4 h-4"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href="#ui-icon-apply"></use>
              </svg>
            </div>
          </div>
          <div className="text-xs">
            Complete claims packet
            <Tooltip
              srText="Tooltip for complete claims packet"
              content="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
              xMargin="mx-1"
            />
          </div>
        </div>

        <div className="ui-claim-process-middle relative">
          <div
            className={`ui-claim-process-step bg-white ${
              data.step === 3 ? "ui-claim-process-step-current" : ""
            }`}
          >
            <div
              className={`w-12 h-12 rounded-full flex items-center justify-center ${stepThreeClasses}`}
            >
              <svg
                className="ui-icon w-4 h-4"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href="#ui-icon-search"></use>
              </svg>
            </div>
          </div>
          <div className="text-xs">
            Track claim
            <Tooltip
              srText="Tooltip for track claim"
              content="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
              xMargin="mx-1"
            />
          </div>
        </div>

        <div className="ui-claim-process-last relative">
          <div
            className={`ui-claim-process-step bg-white ${
              data.step === 4 ? "ui-claim-process-step-current" : ""
            }`}
          >
            <div
              className={`w-12 h-12 rounded-full flex items-center justify-center ${stepFourClasses}`}
            >
              <svg
                className="ui-icon w-4 h-4"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href="#ui-icon-edit"></use>
              </svg>
            </div>
          </div>
          <div className="text-xs">
            Receive payment
            <Tooltip
              content="Lorem ipsum dolor sit amet, consectetur adipiscing elit."
              xMargin="mx-1"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
