import Badge from "../General/Badge";
import DownloadDocumentTile from "../General/DownloadDocumentTile";

export default function ClaimStatusRequiredDocCard({ data }) {
  return (
    <div className="ui-card">
      <div className="ui-card-grid-layout">
        <div className="ui-document-grid-title text-sm font-semibold">
          {data.title}
        </div>
        <div className="ui-document-grid-badge">
          <Badge
            status={data.status}
            statusText={data.statusText}
            srPrefix="Document"
          />
        </div>
        <div className="ui-document-grid-desc">
          <p className="text-xs">
            This is the form you completed when first filing the claim.
          </p>
        </div>
        <div className="ui-document-grid-actions">
          <div className="ui-download-document">
            {data.document.location ? (
              <DownloadDocumentTile data={data.document} key={document.id} />
            ) : (
              <div className="ui-document-grid-actions">
                <button
                  className="ui-btn-primary ui-btn-min-w mb-4 mr-4"
                  aria-label="Upload missing document"
                >
                  Upload
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
