// route:  /policies/:policy_id
export const data = {
  pageTitle: "Term Life - George Policy Detail",
  policy_detail: {
    status: "problem",
    statusText: "Pending Lapse",
    policyNumber: "#55555",
    coverage: "500,000",
    name: "Annual Renewable",
    term: "15 Years",
    startDate: "01/01/2023",
    endDate: "01/01/2023",
    title: "Copy",
    documents: [
      {
        id: 1,
        name: "Policy Pages",
        location: "#",
        desc: "Download PDF",
      },
      {
        id: 2,
        name: "Proof of Insurance",
        location: "#",
        desc: "Download Text",
      },
    ],
    additionalBenefits: [
      {
        id: "asdf1",
        benefitName: "Accidental Death Rider",
        coverage: "XX,000",
        dateIssued: "01/01/2023",
        expiryDate: "01/01/2023",
      },
      {
        id: "asdf2",
        benefitName: "Dependent Child Rider",
        coverage: "XX,000",
        dateIssued: "01/01/2023",
        expiryDate: "01/01/2023",
      },
      {
        id: "asdf3",
        benefitName: "Waiver of Premium",
        coverage: "XX,000",
        dateIssued: "01/01/2023",
        expiryDate: "01/01/2023",
      },
    ],
  },
  people: {
    owner: "George Toissant",
    insured: "<name>",
    dob: "06/20/1980",
    issueAge: "42",
    address: {
      streetNumber: "555",
      streetName: "Maple Lane",
      city: "Anytown",
      state: "NY",
      zipCode: "55555",
    },
    phone: [
      {
        number: "555-555-5555",
        type: "mobile",
      },
      {
        number: "555-555-5555",
        type: "home",
      },
    ],
    email: "gtoissant@email.com",
    notification: ["Email"],
  },
  beneficiaries: {
    primary: "Sarah Toissant",
    contingent: ["Remy Toissant", "Ruby Toissant", "Roger Toissant"],
  },
  paymentSummary: {
    paymentStatus: "Payment past due",
    status: "problem",
    dueDate: "01/01/2023",
    frequency: "Monthly",
    amount: "XX.XX",
    method: "Direct Bill",
  },
  loans: {
    loan: {
      title: "Whole Life - George",
      number: "384828283",
      amount: "XX,XXX",
      type: "type",
      startDate: "01/01/2023",
      interestRate: "0.00",
      balance: "XX,XXX",
    },
    fund: {
      number: "384828283",
      maxLoanAmount: "XX,XXX",
      interestRate: "0.00",
      paymentAmount: "XX,XXX",
      startDate: "01/01/2023",
      duration: "36",
    },
  },
  agent: {
    name: "Robert Fisk",
    address: {
      streetNumber: "555",
      streetName: "Maple Lane",
      city: "Anytown",
      state: "NY",
      zipCode: "55555",
    },
    phone: "555-555-5555",
    email: "robert.fisk@email.com",
  },
};
export const links = {
  payment: [
    { name: "Make a payment", url: "/payment/make-a-payment" },
    { name: "View billing statements", url: "/payment/billing?tab=statement" },
    { name: "View scheduled payments", url: "/payment/billing?tab=scheduled" },
    { name: "View payment history", url: "/payment/billing?tab=history" },
  ],
  services: [
    // { name: "Remind policyholder to review beneficiaries", url: "#" },
    { name: "Beneficiary Resources", url: "/help/help-faqs" },
    // No edit capability for cutover one
    // { name: "Update policy owner's contact information", url: "#" },
    { name: "File a claim", url: "/services-claims/claims/file-a-claim" },
  ],
  // documents: [
  //   { name: "Get policy pages", url: "#" },
  //   { name: "Request proof of insurance", url: "#" },
  // ],
};
export const messages = [
  {
    title: "Payment past due alert",
    date: "01/01/2023",
    url: "/",
  },
  {
    title: "Statement available",
    date: "01/01/2023",
    url: "/",
  },
  {
    title: "Statement available",
    date: "01/01/2023",
    url: "/",
  },
];
