import { Dialog } from "@headlessui/react";
import { useState } from "react";
import DialogBackdrop from "../../ModalDialogs/DialogBackdrop";
import AutoPayConfirmationDialog from "../Dialog/AutoPayConfirmationDialog";
export default function OptoutDialog({ isDialogOpen, setDialogopen }) {
  const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
  return (
    <>
      <Dialog
        open={isDialogOpen}
        onClose={() => setDialogopen(false)}
        className="ui-dialogs"
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <DialogBackdrop />
        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel
            role="dialog"
            aria-modal="true"
            className="ui-default-dialog"
          >
            <Dialog.Title className="font-body text-xl font-bold text-left">
              Are you sure you want to opt-out of autopay?
            </Dialog.Title>
            <button
              className="ui-default-dialog-close"
              aria-label="close cancel payment window"
              onClick={() => setDialogopen(false)}
            >
              <svg
                className="ui-icon h-3.5 w-3.5"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href="#ui-icon-close"></use>
              </svg>
            </button>
            <hr />
            <Dialog.Description>Edit payment information</Dialog.Description>

            <div className="text-xl my">
              <p>
                Additional text explaining benefits to autopay or other options
                that can be taken than opting out can go here.
              </p>
            </div>

            {/* <!-- Buttons --> */}
            <div className="block text-right mt-6">
              <button
                className="ui-btn-secondary ui-btn-min-w mt-4"
                onClick={() => setDialogopen(false)}
              >
                No, keep me enrolled.
              </button>
              <button
                id="ID_applyFilterOptions"
                className="ui-btn-primary ui-btn-min-w mt-4"
                onClick={() => setShowConfirmationDialog(true)}
              >
                Yes, cancel autopay.
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      <AutoPayConfirmationDialog
        isDialogOpen={showConfirmationDialog}
        setDialogopen={setShowConfirmationDialog}
      />
    </>
  );
}
