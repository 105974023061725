import { Link } from "react-router-dom";
export default function GuestServicesCard() {
  return (
    <div className="ui-card text-center p-4 relative xl:p-6">
      <h2>Guest Services</h2>
      <p className="text-sm mb-6">You don't have to log in for these tasks.</p>
      <div className="w-40 px-2 mx-auto">
        <div className="mb-6">
          <Link to="/guest/claims" className="ui-btn-primary block w-full">
            File or track a Claim
          </Link>
        </div>
        <div className="mb-6">
          <Link
            to="/guest/make-a-payment"
            className="ui-btn-primary block w-full"
          >
            Make a Payment
          </Link>
        </div>
        <p className="text-xs w-40 leading-snug">
          Find answers and resources in <Link to="/help/help-faqs">FAQs</Link>{" "}
          &amp; <Link to="/guest/help">Help</Link>.
        </p>
      </div>
    </div>
  );
}
