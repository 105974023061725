export default function SimpleCTAToDialogCard({
  title,
  desc,
  ctaText,
  openDialog,
}) {
  return (
    <div className="ui-card grid grid-cols-1 place-content-between p-4 xl:p-6">
      <div className="text-left">
        <div className="text-lg font-semibold">{title}</div>
        <hr className="my-4" />
        <p className="text-sm">{desc} </p>
      </div>
      <div className="text-right mt-4 xl:mt-6">
        <button className="ui-btn-primary ui-btn-min-w" onClick={openDialog}>
          {ctaText}
          <span className="sr-only"> {title}</span>
        </button>
      </div>
    </div>
  );
}
