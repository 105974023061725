import { useForm, useFieldArray } from "react-hook-form";
import BeneficiaryInfoCard from "./BeneficiaryInfoCard";

export default function FACStepThree({
  formStep,
  setFormStep,
  setFACFormData,
}) {
  const {
    register: FACStepThreeRegister,
    handleSubmit: FACStepThreeHandleSubmit,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: {
      beneficiaries: [{}],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: "beneficiaries",
  });

  const onSubmit = (data) => {
    setFormStep(4);
    setFACFormData((currentFormData) => {
      return {
        ...currentFormData,
        ...data,
      };
    });
  };

  return (
    <>
      {formStep === 3 && (      
        <div>
          <hr />
          <p className="text-sm">
            Text about how providing this information can help coordinate and
            expedite the payment of final expenses. Lorem Ipsum dolor sit amet.
          </p>

          <form onSubmit={FACStepThreeHandleSubmit(onSubmit)}>
            {fields.map((beneficiaryField, index) => {
              return (
                <BeneficiaryInfoCard
                  key={beneficiaryField.id}
                  componentIndex={index}
                  lastComponent={fields.length - 1 === index}
                  FACStepThreeRegister={FACStepThreeRegister}
                  append={append}
                  remove={remove}
                  errors={errors}
                />
              );
            })}

            <div className="flex justify-end">
              <button
                className="ui-btn-secondary ui-btn-min-w mt-4"
                type="button"
                onClick={() => setFormStep((current) => (current -= 1))}
              >
                Previous
              </button>

              <button className="ui-btn-primary ui-btn-min-w mt-4">
                Continue
              </button>
            </div>
          </form>
        </div>      
      )}
    </>
  );
}
