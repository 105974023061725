import { Dialog } from "@headlessui/react";
import { useState } from "react";
import Badge from "../../General/Badge";
import { Link } from "react-router-dom";

export default function AgentCard({ data }) {
  const [isOpenAgentProfile, setIsOpenAgentProfile] = useState(false);

  return (
    <>
      <div className="ui-card ui-grid-item relative p-4">
        {/* Note: Need to ask where this arrow goes and updated the aria-label  */}
        <a
          className="ui-btn-arrow-details hidden md:block md:top-[1.5rem] md:right-[.5rem]"
          aria-label="View agent details"
          href="/"
        >
          <svg
            className="ui-icon w-8 h-8"
            focusable="false"
            aria-hidden="true"
            role="presentation"
          >
            <use href="#ui-icon-next"></use>
          </svg>
        </a>

        <div className="font-semibold">{data.agentName}</div>
        <div className="mt-2 grid grid-cols-1 gap-4 sm:grid-cols-[1fr,2fr] md:grid-cols-[1.5fr,2fr]">
          <div className="rounded overflow-hidden">
            <img
              src={`${
                data.photoURL.length > 0
                  ? data.photoURL
                  : "/assets/img/agent-default.png"
              }`}
              className="w-full h-auto max-w-[10rem]"
              alt={`photo of ${data.agentName}`}
            />
          </div>
          <div>
            <div>
              <div className="text-xl text-theme-text-secondary font-semibold">
                {data.policiesManaged}
              </div>
              <div className="text-xs text-theme-text-tertiary">
                Policies managed
              </div>
            </div>
            <div className="mt-1">
              <div className="text-xl text-theme-text-secondary font-semibold">
                ${data.earnings}
              </div>
              <div className="text-xs text-theme-text-tertiary">Earnings</div>
            </div>
          </div>
        </div>
        <div className="mt-4 flex flex-1 items-stretch">
          <button
            className="ui-btn-secondary-sm mr-2 sm:w-full"
            onClick={() => setIsOpenAgentProfile(true)}
          >
            View Profile
          </button>
          {/* <button className="ui-btn-primary-sm sm:w-full">View Book</button> */}
          <Link
            className="ui-btn-primary-sm sm:w-full"
            aria-label={` View book for ${data.agentName}`}
            to={`/book-of-business`}
          >
            View book
          </Link>
        </div>
      </div>
      {/* Agent Profile Dialog */}
      <Dialog
        open={isOpenAgentProfile}
        onClose={() => setIsOpenAgentProfile(false)}
        className="ui-dialogs"
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel
            role="dialog"
            aria-modal="true"
            className="ui-default-dialog"
          >
            <div className="grid gap-4 grid-cols-1 sm:grid-cols-[1fr,3fr]">
              <div>
                <img
                  src={`${
                    data.photoURL.length > 0
                      ? data.photoURL
                      : "/assets/img/agent-default.png"
                  }`}
                  className="w-full h-auto max-w-[14rem]"
                  alt={`photo of ${data.agentName}`}
                />
              </div>
              <div>
                <Dialog.Title className="font-body text-xl font-bold text-left">
                  {data.agentName}
                  {data.pronouns && (
                    <span className="font-normal text-base ml-2">
                      {data.pronouns}
                    </span>
                  )}
                </Dialog.Title>
                <button
                  className="ui-default-dialog-close"
                  aria-label="close edit photo window"
                  onClick={() => setIsOpenAgentProfile(false)}
                >
                  <svg
                    className="ui-icon h-3.5 w-3.5"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-close"></use>
                  </svg>
                </button>

                {data.badgeStatus && (
                  <Badge
                    status={data.badgeStatus}
                    statusText={data.badgeText}
                    srPrefix="Policy"
                  />
                )}
                <div>
                  <hr />
                </div>
                <Dialog.Description>
                  Space for copy for Rob to customize a brief bio about himself.
                  This would be displayed for managers and/or other users who
                  can see agent bios. This would be displayed for managers
                  and/or other users who can see agent bios.
                </Dialog.Description>
              </div>
            </div>

            <div className="mt-4 grid gap-4 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
              <div>
                <div className="text-xs text-theme-text-tertiary">
                  Home Office
                </div>
                <div className="text-sm font-semibold text-theme-text-secondary">
                  <div>{data.office}</div>
                  <div>{data.address}</div>
                  <div>{data.address2}</div>
                  <div>
                    {data.city}, {data.state} {data.zip}
                  </div>
                </div>
              </div>
              <div>
                <div className="text-xs text-theme-text-tertiary">
                  Specialty
                </div>
                <div className="text-sm font-semibold text-theme-text-secondary">
                  Whole Life and annuities
                </div>
              </div>
              <div>
                <div className="text-xs text-theme-text-tertiary">
                  Fax Number{" "}
                </div>
                <div className="text-sm font-semibold text-theme-text-secondary">
                  000-0000-0000
                </div>
              </div>
              <div>
                <div className="text-xs text-theme-text-tertiary">
                  Business Number
                </div>
                <div className="text-sm font-semibold text-theme-text-secondary">
                  000-0000-0000
                </div>
              </div>
              <div>
                <div className="text-xs text-theme-text-tertiary">
                  Mobile Number
                </div>
                <div className="text-sm font-semibold text-theme-text-secondary">
                  000-0000-0000
                </div>
              </div>
              <div>
                <div className="text-xs text-theme-text-tertiary">Manager</div>
                <div className="text-sm font-semibold text-theme-text-secondary">
                  Sarah Toissant
                </div>
              </div>
            </div>
            <div className="block text-right mt-6">
              <button
                className="ui-btn-secondary ui-btn-min-w mt-4"
                onClick={() => setIsOpenAgentProfile(false)}
              >
                Close
                <span className="sr-only"> your account photo </span>
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
}
