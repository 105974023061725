import { useDynamicSecurityQuestionsOptions } from "../../hooks/useDynamicSecurityQuestionsOptions";

const PanelThree = ({
  register,
  onSubmit,
  handleSubmit,
  errors,
  validatePanel,
  paperlessSettings,
  setPaperlessSettings,
}) => {
  const onOptionChange = (e) => setPaperlessSettings(e.target.value);
  const {
    setSecurityQuestionOneId,
    securityQuestionsOne,

    setSecurityQuestionTwoId,
    securityQuestionsTwo,

    setSecurityQuestionThreeId,
    securityQuestionsThree,
  } = useDynamicSecurityQuestionsOptions();

  return (
    <div className="pt-4 mt-2 lg:pt-6 border-t border-theme-rule-medium">
      <div className="m-auto md:w-3/4">
        <div className="block font-semibold text-base">
          Please select # security questions
        </div>
        <form onSubmit={handleSubmit(() => onSubmit(3))}>
          <div className="mt-4">
            <label id="ID_question1Label">Security question 1</label>
            <select
              id="ID_question1"
              className="w-full"
              {...register("securityQuestionOne", {
                required: {
                  value: true,
                  message: "Please select a security question",
                },
                onChange: (e) =>
                  setSecurityQuestionOneId(parseInt(e.target.value)),
              })}
              aria-describedby="ID_question1Error"
              aria-labelledby="ID_question1Label"
            >
              <option value="">Select a security question</option>
              {securityQuestionsOne.map((question) => (
                <option
                  key={`security question - ${question.id}`}
                  value={question.id}
                >
                  {question.question}
                </option>
              ))}
            </select>
            <div id="ID_question1Error">
              {errors.securityQuestionOne && (
                <div className="ui-input-error">
                  {errors.securityQuestionOne.message}
                </div>
              )}
            </div>
          </div>
          <div className="mt-4">
            <label id="ID_answer1Label">Answer to security question 1</label>
            <input
              id="ID_answer1"
              className="w-full"
              type="text"
              {...register("securtityAnswerOne", {
                required: {
                  value: true,
                  message: "Please answer selected security question",
                },
              })}
              aria-describedby="ID_answer1Error"
              aria-labelledby="ID_answer1Label"
            />
            <div id="ID_answer1Error">
              {errors.securtityAnswerOne && (
                <div className="ui-input-error">
                  {errors.securtityAnswerOne.message}
                </div>
              )}
            </div>
          </div>

          <div className="mt-4">
            <label id="ID_question2Label">Security question 2</label>
            <select
              id="ID_question2"
              className="w-full"
              {...register("securityQuestionTwo", {
                required: {
                  value: true,
                  message: "Please select a security question",
                },
                onChange: (e) =>
                  setSecurityQuestionTwoId(parseInt(e.target.value)),
              })}
              aria-describedby="ID_question2Error"
              aria-labelledby="ID_question2Label"
            >
              <option value="">Select a security question</option>
              {securityQuestionsTwo.map((question) => (
                <option
                  key={`security question - ${question.id}`}
                  value={question.id}
                >
                  {question.question}
                </option>
              ))}
            </select>
            <div id="ID_question2Error">
              {errors.securityQuestionTwo && (
                <div className="ui-input-error">
                  {errors.securityQuestionTwo.message}
                </div>
              )}
            </div>
          </div>
          <div className="mt-4">
            <label id="ID_answer2Label">Answer to security question 2</label>
            <input
              id="ID_answer2"
              className="w-full"
              type="text"
              {...register("securtityAnswerTwo", {
                required: {
                  value: true,
                  message: "Please answer selected security question",
                },
              })}
              aria-describedby="ID_answer2Error"
              aria-labelledby="ID_answer2Label"
            />
            <div id="ID_answer2Error">
              {errors.securtityAnswerTwo && (
                <div className="ui-input-error">
                  {errors.securtityAnswerTwo.message}
                </div>
              )}
            </div>
          </div>

          <div className="mt-4">
            <label id="ID_question3Label">Security question 3</label>
            <select
              id="ID_question3"
              className="w-full"
              {...register("securityQuestionThree", {
                required: {
                  value: true,
                  message: "Please select a security question",
                },
                onChange: (e) =>
                  setSecurityQuestionThreeId(parseInt(e.target.value)),
              })}
              aria-describedby="ID_question3Error"
              aria-labelledby="ID_question3Label"
            >
              <option value="">Select a security question</option>
              {securityQuestionsThree.map((question) => (
                <option
                  key={`security question - ${question.id}`}
                  value={question.id}
                >
                  {question.question}
                </option>
              ))}
            </select>
            <div id="ID_question3Error"></div>
            {errors.securityQuestionThree && (
              <div className="ui-input-error">
                {errors.securityQuestionThree.message}
              </div>
            )}
          </div>
          <div className="mt-4">
            <label id="ID_answer3Label">Answer to security question 3</label>
            <input
              id="ID_answer3"
              className="w-full"
              type="text"
              {...register("securtityAnswerThree", {
                required: {
                  value: true,
                  message: "Please answer selected security question",
                },
              })}
              aria-describedby="ID_answer3Error"
              aria-labelledby="ID_answer3Label"
            />
            <div id="ID_answer3Error">
              {errors.securtityAnswerThree && (
                <div className="ui-input-error">
                  {errors.securtityAnswerThree.message}
                </div>
              )}
            </div>
          </div>

          <hr />

          <div className="block font-semibold text-base">
            Choose your notification preferences
          </div>

          <fieldset className="mt-4">
            <legend className="text-sm font-semibold">
              Paperless Settings
            </legend>
            <p id="ID_paperSettingsDesc" className="text-sm mb-2">
              Though certain document may be required to be delivered by mail,
              you can still reduce paper usage.
            </p>
            <div className="inline-block mr-6">
              <label className="ui-radio inline-flex items-center text-sm font-normal">
                <input
                  type="radio"
                  name="paperlessSettings"
                  id="ID_paperlessSettingsYes"
                  aria-describedby="ID_paperSettingsDesc"
                  {...register("paperless")}
                  value="yes"
                  defaultChecked
                  onChange={onOptionChange}
                  className="mr-2"
                />
                Yes, enroll me in paperless
              </label>
            </div>
            <div className="inline-block">
              <label className="ui-radio inline-flex items-center  text-sm font-normal">
                <input
                  type="radio"
                  name="paperlessSettings"
                  id="ID_paperlessSettingsNo"
                  aria-describedby="ID_paperSettingsDesc"
                  {...register("paperless")}
                  value="no"
                  checked={paperlessSettings === "no"}
                  onChange={onOptionChange}
                  className="mr-2"
                />
                No, I'd like mailed copies of all document
              </label>
            </div>
          </fieldset>

          <fieldset className="mt-4">
            <legend className="text-sm font-semibold">
              Alerts &amp; notification method
            </legend>
            <p id="ID_alertMethodDesc" className="text-sm mb-2">
              Let us know how your would like to receive messages when
              statements are available or if there are issues with your account.
            </p>
            <label className="ui-checkbox md:inline-flex mr-6">
              <input
                id="ID_alertMethodEmail"
                type="checkbox"
                name="notification_method"
                value="email"
                {...register("notificationMethod", {
                  required: "please select a notification method",
                })}
                aria-describedby="ID_alertMethodDesc ID_alertMethodError"
              />
              <span className="ui-checkmark h-4 w-4">
                <svg
                  className="ui-icon w-2 h-2 text-white"
                  focusable="false"
                  aria-hidden="true"
                  role="presentation"
                >
                  <use href="#ui-icon-checkmark"></use>
                </svg>
              </span>
              <span className="font-normal ml-1">Email</span>
            </label>
            {/* Hidden functionality for cutover one */}
            {/* <label className="ui-checkbox md:inline-flex">
              <input
                id="ID_alertMethodText"
                type="checkbox"
                name="notification_method"
                value="text"
                {...register("notificationMethod", {
                  required: "please select a notification method",
                })}
                aria-describedby="ID_alertMethodDesc ID_alertMethodError"
              />
              <span className="ui-checkmark h-4 w-4">
                <svg
                  className="ui-icon w-2 h-2 text-white"
                  focusable="false"
                  aria-hidden="true"
                  role="presentation"
                >
                  <use href="#ui-icon-checkmark"></use>
                </svg>
              </span>
              <span className="font-normal ml-1">Text</span>
            </label> */}
            <div id="ID_alertMethodError">
              {errors.notificationMethod && (
                <div className="ui-input-error">
                  {errors.notificationMethod.message}
                </div>
              )}
            </div>
          </fieldset>

          <div className="mt-4 text-right">
            <button
              type="button"
              className="ui-btn-secondary ui-btn-min-w mt-2"
              onClick={() => validatePanel(1)}
            >
              Previous<span className="sr-only"> step</span>
            </button>
            <button
              id="ID_registrationStep3Btn"
              className="ui-btn-primary ui-btn-min-w mt-2"
              aria-controls="ID_registrationStep3Form"
              aria-expanded="false"
            >
              Continue<span className="sr-only"> to step 4</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PanelThree;
