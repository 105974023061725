import { Link } from "react-router-dom";
import { service } from "../js/placeholderData/service-detail-data";
import Badge from "../components/General/Badge";
import DownloadDocumentTile from "../components/General/DownloadDocumentTile";
import RecentPolicyMessageGrid from "../components/Policy/Detail/RecentPolicyMessagesGrid";
export default function ServicesDetails() {
  return (
    <>
      {/* SPRINT-3-CUSTOMER-claims-and-forms.pdf (pgs 17-18) */}
      <div className="container mb-8">
        <h1>{service.type}</h1>
        <p>
          Lorem ipsum dolor sit amet, eam ad autem fabulas. Expetenda dissentiet          
        </p>
        <hr />
      </div>

      <div className="container ui-component-spacing">
        <div className="flex flex-col lg:flex-row">
          <div className="w-full lg:w-2/3 lg:pr-3">
            <div className="ui-card ui-component-spacing">
              <div className="px-4 pt-3 md:px-6 lg:pt-6">
                <h2 className="font-body font-bold text-xl">
                  Request ID &amp; Status
                </h2>
              </div>
              <hr />
              <div className="px-4 pb-3 md:px-6 lg:pb-6">
                <div>
                  <Badge
                    status={service.status}
                    statusText={service.statusText}
                    srPrefix="Service"
                  />
                </div>

                {/* Request ID & Status */}
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4 md:gap-6 md:mt-6">
                  <div>
                    <div className="text-xs text-theme-text-tertiary">
                      Service Request ID:
                    </div>
                    <div className="text-sm text-theme-text-secondary font-semibold">
                      {service.id}
                    </div>
                  </div>
                  <div>
                    <div className="text-xs text-theme-text-tertiary">
                      Policy Number
                    </div>
                    <div className="text-sm text-theme-text-secondary font-semibold">
                      #{service.policyNumber}
                    </div>
                  </div>
                  <div>
                    <div className="text-xs text-theme-text-tertiary">
                      Service type
                    </div>
                    <div className="text-sm text-theme-text-secondary font-semibold">
                      {service.type}
                    </div>
                  </div>
                  <div>
                    <div className="text-xs text-theme-text-tertiary">
                      Date submitted
                    </div>
                    <div className="text-sm text-theme-text-secondary font-semibold">
                      {service.dateSubmitted}
                    </div>
                  </div>
                  <div>
                    <div className="text-xs text-theme-text-tertiary">
                      Expected time to complete
                    </div>
                    <div className="text-sm text-theme-text-secondary font-semibold">
                      {service.expectedToComplete}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Request Details */}
            <div className="ui-card ui-component-spacing">
              <div className="px-4 pt-3 md:px-6 lg:pt-6">
                <h2 className="font-body font-bold text-xl">Request Details</h2>
              </div>
              <hr />
              <div className="px-4 pb-3 md:px-6 lg:pb-6">
                <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mt-4 md:gap-6 md:mt-6">
                  <div>
                    <div className="text-xs text-theme-text-tertiary">
                      Original insured name
                    </div>
                    <div className="text-sm text-theme-text-secondary font-semibold">
                      {service.detail.original}
                    </div>
                  </div>
                  <div>
                    <div className="text-xs text-theme-text-tertiary">
                      New insured name
                    </div>
                    <div className="text-sm text-theme-text-secondary font-semibold">
                      {service.detail.new}
                    </div>
                  </div>
                  <div>
                    <div className="text-xs text-theme-text-tertiary">
                      Reason for change
                    </div>
                    <div className="text-sm text-theme-text-secondary font-semibold">
                      {service.detail.reason}
                    </div>
                  </div>
                  <div>
                    <div className="text-xs text-theme-text-tertiary">
                      Any other relevant field
                    </div>
                    <div className="text-sm text-theme-text-secondary font-semibold">
                      {service.detail.moreReason}
                    </div>
                  </div>
                </div>
                <div className="font-semibold mt-6 mb-1">
                  Required documents
                </div>
                <div className="ui-download-document">
                  {service.detail.requiredDocs.map((document) => {
                    return (
                      <DownloadDocumentTile data={document} key={document.id} />
                    );
                  })}
                </div>
              </div>
            </div>
            {/* Request Details */}
          </div>
          <div className="w-full lg:w-1/3 lg:pl-3">
            {/* Messages history for this request */}
            <div className="ui-card mb-4 md:mb-6">
              <div className="px-4 pt-4 pb-1 border-b border-ui-gray-medium-light">
                <h2 className="text-sm font-body font-semibold">
                  Messages history for this request
                </h2>
              </div>
              <div className="py-4">
                <RecentPolicyMessageGrid />
              </div>
              <Link to="/messages">
                <button
                  className="ui-card-btn-more"
                  aria-label="View more recent Policy Messages"
                >
                  View more
                </button>
              </Link>
            </div>
            {/* Messages history for this request */}
          </div>
        </div>
      </div>
    </>
  );
}
