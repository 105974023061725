import { Link } from "react-router-dom";
import Badge from "../General/Badge";
export default function MessageRow({ message }) {
  return (
    <div
      className={`${
        message.status ? `ui-badge-bar-left-${message.status}` : ""
      } relative flex flex-col py-4 pl-6 pr-4 bg-white border-b border-theme-rule-medium md:pl-8 lg:pl-12`}
    >
      <div className="flex flex-col-reverse md:flex-row justify-between">
        <div className="text-theme-text-secondary font-semibold flex flex-row items-center">
          {message.status && (
            <div className="mr-2">
              <Badge
                iconOnly={true}
                status={message.status}
                statusText={message.title}
                srPrefix="Message"
              />
            </div>
          )}
          <div>
            <div>{message.title}</div>
          </div>
        </div>
        <div className="text-xxs font-bold text-right">{message.date}</div>
      </div>
      <div className="font-bold text-xxs mt-2">
        {message.subject && <span className="mr-4">RE: {message.subject}</span>}
        <span className="">
          Policy #{message.policyNumber} -- ctg:{message.category}
        </span>
      </div>
      <div className="text-xxs min-h-8 mt-2">{message.desc}</div>
      <div>
        <Link to={message.ctaUrl} className="ui-btn-primary mt-4">
          {message.cta}
        </Link>
      </div>
    </div>
  );
}
