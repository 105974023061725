export default function Alphabetical({ changesDisplay }) {
  return (
    <>
      {/* Toggle disabling the button when user sorts */}
      <div className="text-sm font-semibold flex flex-row flex-wrap items-center">
        <div className="mr-2">Sort by:</div>
        <button
          className="ui-btn-hyperlink font-semibold"
          aria-controls={`${changesDisplay}`}
          disabled
        >
          A-Z
        </button>
        <div className="mx-1">|</div>
        <button
          className="ui-btn-hyperlink font-semibold"
          aria-controls={`${changesDisplay}`}
        >
          Z-A
        </button>
      </div>
    </>
  );
}
