import { Tab } from "@headlessui/react";
import { useNavigate } from "react-router-dom";

import Billboard from "../../components/General/Billboard";
import OwnerProfileCard from "../../components/AgentPortal/PolicyDetails/PolicyCards/OwnerProfileCard";
import AllPolicyView from "../../components/AgentPortal/PolicyDetails/AllPolicyView";
import PaymentTab from "../../components/AgentPortal/PolicyDetails/PaymentTab";
import HelpfulLinks from "../../components/AgentPortal/General/HelpfulLinks";
import ServiceAndClaimsTab from "../../components/AgentPortal/PolicyDetails/ServiceAndClaimsTab";
import MessagesTab from "../../components/AgentPortal/PolicyDetails/MessagesTab";
import PolicySelector from "../../components/AgentPortal/General/PolicySelector";

import { allPolicyViewLinks } from "../../js/placeholderData/agents/allPolicyViewLinks";
import { singlePolicyViewLinks } from "../../js/placeholderData/agents/singlePolicyViewLinks";
import { policies } from "../../js/placeholderData/policiesData";
import { data } from "../../js/placeholderData/policyDetail";
import { claims } from "../../js/placeholderData/agents/serviceRequestsAndClaims";
import { serviceRequests } from "../../js/placeholderData/agents/serviceRequestsAndClaims";
import {
  messsageCategories,
  messages,
} from "../../js/placeholderData/messageCenterData";
import Loans from "../../components/AgentPortal/PolicyDetails/LoansTab";
import AgentNotesTab from "../../components/AgentPortal/PolicyDetails/AgentNotesTab";
import { loansData } from "../../js/placeholderData/agents/loansData";
import { notes } from "../../js/placeholderData/agents/notes";


export const Policies = () => {
  const user = "George Toussant";
  let billboardData = {
    title: "It's tax season. Your statements are ready.",
    svgClass: "text-ui-green",
    svgUse: "ui-icon-border-good",
    description:
      "Your year-end tax statement is now available to download. Additional copy for this example of a temporary homepage message goes here. Login to access your documents.",
  };
  const navigate = useNavigate();

  const updatePolicyChoice = (idx) => {
    const policyFilter = policies.filter((item) => item.policyNumber === idx);
    navigate(`/policies/${policyFilter[0].policyNumber}`);
  };

  return (
    <>
      <div className="container mb-8">
        <h1>Policy details</h1>
        <p>
          To search for policies from any customer use the
          <button
            className="ui-btn-hyperlink ml-2"
            // onClick={() => setIsOpenPolicyLookup(true)}
          >
            Policy lookup
          </button>
          .
        </p>
        <hr />
      </div>

      <div className="container ui-component-spacing">
        <h2 className="mb-4">{user}</h2>

        {/* This select is everywhere so I made it into a component */}
        <div className="">
          <PolicySelector
            policies={policies}
            updatePolicyChoice={updatePolicyChoice}
          />
        </div>
      </div>

      <div className="container ui-component-spacing">
        <Tab.Group>
          <div className="">
            <div className="ui-card flex-grow px-2 p-1 md:px-4">
              <Tab.List className="flex flex-row whitespace-nowrap">
                <Tab className="text-sm mx-3 pt-3 pb-2 mb-1 md:text-base md:mx-4 lg:text-lg">
                  Policies
                </Tab>
                <Tab className="text-sm mx-3 pt-3 pb-2 mb-1 md:text-base md:mx-4 lg:text-lg">
                  Payments
                </Tab>
                <Tab className="text-sm mx-3 pt-3 pb-2 mb-1 md:text-base md:mx-4 lg:text-lg">
                  Requests &amp; claims
                </Tab>
                <Tab className="text-sm mx-3 pt-3 pb-2 mb-1 md:text-base md:mx-4 lg:text-lg">
                  Messages
                </Tab>
                <Tab className="text-sm mx-3 pt-3 pb-2 mb-1 md:text-base md:mx-4 lg:text-lg">
                  Loans
                </Tab>
                <Tab className="text-sm mx-3 pt-3 pb-2 mb-1 md:text-base md:mx-4 lg:text-lg">
                  Agent notes
                </Tab>
              </Tab.List>
            </div>
          </div>

          <Tab.Panels className="mt-6">
            <Tab.Panel>
              <div className="grid gap-4 grid-cols-1 lg:grid-cols-[66.666%,1fr] xl:gap-6">
                <div>
                  <div className="ui-component-spacing">
                    <Billboard data={billboardData} />
                  </div>
                  <AllPolicyView policies={policies} user={user} />
                </div>
                <div>
                  <HelpfulLinks links={allPolicyViewLinks} config={"all"} />
                  <OwnerProfileCard data={data} />
                </div>
              </div>
            </Tab.Panel>

            <Tab.Panel>
              <PaymentTab policies={policies} />
            </Tab.Panel>

            <Tab.Panel>
              <ServiceAndClaimsTab
                policies={policies}
                claims={claims}
                services={serviceRequests}
                singlePolicyViewLinks={singlePolicyViewLinks}
              />
            </Tab.Panel>
            <Tab.Panel>
              <MessagesTab
                messages={messages}
                messsageCategories={messsageCategories}
              />
            </Tab.Panel>
            <Tab.Panel>
              <div className="border border-dashed border-ui-gray p-6 my-6">
                <div className="text-center">
                  <div className="text-xl font-bold">
                    Welcome to the loans page!
                  </div>
                  <div className="mt-1 text-theme-text-quaternary text-sm">
                    Nothing to see here. Lorem ipsum.
                  </div>
                  <button className="mt-4 ui-btn-primary">
                    Call to Action
                  </button>
                </div>
              </div>
              <Loans loansData={loansData} />
            </Tab.Panel>
            <Tab.Panel>
              <AgentNotesTab data={notes} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </>
  );
};

export default Policies;
