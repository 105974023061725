import { useState } from "react";
import BeneficiartFormDialog from "./BeneficiaryFormDialog";
import RemoveBeneficiaryDialog from "./RemoveBeneficiaryDialog";
import { useNavigate } from "react-router-dom";

export default function BeneficiaryDetailCard({ data, policy_id }) {
  let [beneficiaryFormDialogIsOpen, setbeneficiaryFormDialogIsOpen] =
    useState(false);
  let [removeDialogIsOpen, setRemoveDialogIsOpen] = useState(false);
  let navigate = useNavigate();

  return (
    <>
      <div className="ui-card p-4 md:p-6">
        <div className="grid grid-cols-2 gap-4 md:gap-6">
          <div>
            <div className="text-lg font-semibold text-theme-text-secondary">
              {data.name}
            </div>
            <div className="text-sm font-semibold">{data.relationship}</div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">Percentage split</div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              {data.percentage}%
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">SSN</div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              ***-**-{data.ssn}
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">Address</div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              {data.address.streetNumber} {data.address.streetName}
            </div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              {data.address.city}, {data.address.state} {data.address.zipcode}
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">Phone number</div>
            {data.phoneNumber.map((p, n) => {
              return (
                <div
                  className="mb-1  text-theme-text-secondary"
                  key={`phone number - ${n}`}
                >
                  <div className="text-sm font-semibold">{p.number}</div>
                  <div className="text-xxs font-semibold">{p.type}</div>
                </div>
              );
            })}
          </div>
          <div className="place-self-end text-right">
            <button
              className="ui-btn-primary"
              onClick={() => navigate(`/policies/beneficiaries/${policy_id}`)}
            >
              Update Details
            </button>
            {/* Hidden for cutover one
            <button
              className="ui-btn-primary ui-btn-min-w"
              onClick={() => setbeneficiaryFormDialogIsOpen(true)}
            >
              Edit <span className="sr-only"> {data.name}</span> details
            </button> */}
            <div className="mt-2">
              {/* Hidden for cutover one
              <button
                className="ui-btn-hyperlink flex-shrink text-xs"
                onClick={() => setRemoveDialogIsOpen(true)}
              >
                Remove &amp; replace
                <span className="sr-only"> Sarah Toissant</span>
              </button> */}
            </div>
          </div>
        </div>
      </div>

      {/* Hidden for cutover one */}
      {/* edit beneficiary dialog */}
      {/* <BeneficiartFormDialog
        data={data}
        dialog={beneficiaryFormDialogIsOpen}
        open={() => setbeneficiaryFormDialogIsOpen(true)}
        close={() => setbeneficiaryFormDialogIsOpen(false)}
      /> */}
      {/* edit beneficiary dialog */}

      {/* remove beneficiary dialog */}
      {/* <RemoveBeneficiaryDialog
        data={data}
        dialog={removeDialogIsOpen}
        open={() => setRemoveDialogIsOpen(true)}
        close={() => setRemoveDialogIsOpen(false)}
      /> */}
      {/* remove beneficiary dialog */}
    </>
  );
}
