// route: /payment
export const payments = [
  {
    id: "1",
    typeId: "1",
    title: "Term Life - George Toissant",
    status: "problem",
    statusText: "Payment Past Due",
    policyNumber: "54723452",
    dueDate: "09/28/1996",
    dueAmount: "XX.XX",
    lapseDate: "01/01/2023",
    premiumAmount: "XX.XX",
    lastPaymentDate: "01/01/2023",
    frequency: "Monthly",
    method: "Bank account ending in XXXX",
    documents: [
      {
        id: 1,
        name: "Policy Pages",
        location: "#",
        desc: "View latest statement",
      },
      {
        id: 2,
        name: "Proof of Insurance",
        location: "#",
        desc: "View latest statement",
      },
    ],
    scheduledPayments: [
      {
        amount: "X.XX",
        method: "method data",
        postedDate: "01/01/2023",
      },
      {
        amount: "X.XX",
        method: "method data",
        postedDate: "01/01/2023",
      },
      {
        amount: "X.XX",
        method: "method data",
        postedDate: "01/01/2023",
      },
    ],
    paymentHistory: [
      {
        amount: "X.XX",
        method: "method data",
        postedDate: "01/01/2023",
      },
      {
        amount: "X.XX",
        method: "method data",
        postedDate: "01/01/2023",
      },
      {
        amount: "X.XX",
        method: "method data",
        postedDate: "01/01/2023",
      },
    ],
    statements: [
      {
        dateAdded: "01/01/2023",
        type: "Policy5555555555-01/01/2023",
      },
      {
        dateAdded: "01/01/2023",
        type: "Policy5555555555-01/01/2023",
      },
      {
        dateAdded: "01/01/2023",
        type: "Policy5555555555-01/01/2023",
      },
    ],
  },
  {
    id: "2",
    typeId: "1",
    title: "Term Life - Sarah Toissant",
    status: "risk",
    statusText: "Payment Drafts in X Days",
    policyNumber: "54723452",
    dueDate: "MM/DD",
    dueAmount: "XX.XX",
    lapseDate: "01/01/2023",
    premiumAmount: "XX.XX",
    lastPaymentDate: "01/01/2023",
    frequency: "Monthly",
    method: "Bank account ending in XXXX",
    documents: [
      {
        id: 1,
        name: "Policy Pages",
        location: "#",
        desc: "View latest statement",
      },
      {
        id: 2,
        name: "Proof of Insurance",
        location: "#",
        desc: "View latest statement",
      },
    ],
    scheduledPayments: [
      {
        amount: "1X.XX",
        method: "method data",
        postedDate: "01/01/2023",
      },
      {
        amount: "2X.XX",
        method: "method data",
        postedDate: "01/01/2023",
      },
      {
        amount: "3X.XX",
        method: "method data",
        postedDate: "01/01/2023",
      },
    ],
    paymentHistory: [
      {
        amount: "1X.XX",
        method: "method data",
        postedDate: "01/01/2023",
      },
      {
        amount: "1X.XX",
        method: "method data",
        postedDate: "01/01/2023",
      },
      {
        amount: "1X.XX",
        method: "method data",
        postedDate: "01/01/2023",
      },
    ],
    statements: [
      {
        dateAdded: "01/01/2023",
        type: "Policy5555555555-01/01/2023",
      },
      {
        dateAdded: "01/01/2023",
        type: "Policy5555555555-01/01/2023",
      },
      {
        dateAdded: "01/01/2023",
        type: "Policy5555555555-01/01/2023",
      },
    ],
  },
  {
    id: "3",
    typeId: "2",
    title: "Whole Life - George Toissant",
    status: "good",
    statusText: "Paid to Date",
    policyNumber: "888888888",
    dueDate: "MM/DD",
    dueAmount: "XX.XX",
    lapseDate: "01/01/2023",
    premiumAmount: "XX.XX",
    lastPaymentDate: "01/01/2023",
    frequency: "Semi-Quarter",
    method: "Bank account ending in XXXX",
    documents: [
      {
        id: 1,
        name: "Policy Pages",
        location: "#",
        desc: "View latest statement",
      },
      {
        id: 2,
        name: "Proof of Insurance",
        location: "#",
        desc: "View latest statement",
      },
    ],
    scheduledPayments: [
      {
        amount: "1X.XX",
        method: "method data",
        postedDate: "01/01/2023",
      },
      {
        amount: "2X.XX",
        method: "method data",
        postedDate: "01/01/2023",
      },
      {
        amount: "3X.XX",
        method: "method data",
        postedDate: "01/01/2023",
      },
    ],
    paymentHistory: [
      {
        amount: "1X.XX",
        method: "method data",
        postedDate: "01/01/2023",
      },
      {
        amount: "1X.XX",
        method: "method data",
        postedDate: "01/01/2023",
      },
      {
        amount: "1X.XX",
        method: "method data",
        postedDate: "01/01/2023",
      },
    ],
    statements: [
      {
        dateAdded: "01/01/2023",
        type: "Policy5555555555-01/01/2023",
      },
      {
        dateAdded: "01/01/2023",
        type: "Policy5555555555-01/01/2023",
      },
      {
        dateAdded: "01/01/2023",
        type: "Policy5555555555-01/01/2023",
      },
    ],
  },
  {
    id: "1",
    typeId: "3",
    title: "Annuity - George Toissant",
    status: "good",
    statusText: "Paid to Date",
    policyNumber: "888888888",
    dueDate: "MM/DD",
    dueAmount: "XX.XX",
    lapseDate: "01/01/2023",
    premiumAmount: "XX.XX",
    lastPaymentDate: "01/01/2023",
    frequency: "Annually",
    method: "Bank account ending in XXXX",
    documents: [
      {
        id: 1,
        name: "Policy Pages",
        location: "#",
        desc: "View latest statement",
      },
      {
        id: 2,
        name: "Proof of Insurance",
        location: "#",
        desc: "View latest statement",
      },
    ],
    scheduledPayments: [
      {
        amount: "1X.XX",
        method: "method data",
        postedDate: "01/01/2023",
      },
      {
        amount: "2X.XX",
        method: "method data",
        postedDate: "01/01/2023",
      },
      {
        amount: "3X.XX",
        method: "method data",
        postedDate: "01/01/2023",
      },
    ],
    paymentHistory: [
      {
        amount: "1X.XX",
        method: "method data",
        postedDate: "01/01/2023",
      },
      {
        amount: "1X.XX",
        method: "method data",
        postedDate: "01/01/2023",
      },
      {
        amount: "1X.XX",
        method: "method data",
        postedDate: "01/01/2023",
      },
    ],
    statements: [
      {
        dateAdded: "01/01/2023",
        type: "Policy5555555555-01/01/2023",
      },
      {
        dateAdded: "01/01/2023",
        type: "Policy5555555555-01/01/2023",
      },
      {
        dateAdded: "01/01/2023",
        type: "Policy5555555555-01/01/2023",
      },
    ],
  },
];
