
const OwnerProfileCard = ({ data }) => {

  return (
    <>
      <div className="ui-card mb-4 md:mb-6">
        <div className="px-4 pt-4 pb-1 border-b border-theme-rule-light">
          <h2 className="text-sm font-body font-semibold">Owner profile</h2>
        </div>
        <div className="p-4">
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-2">
            <div>
              <div className="text-xs text-theme-text-tertiary">Owner</div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {data.people.owner}
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">
                Owner birth date
              </div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {data.people.dob}
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">
                Owner issue age
              </div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                age
              </div>
            </div>
            <div></div>
            <div>
              <div className="text-xs text-theme-text-tertiary">
                Owner address
              </div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                <div>
                  {data.people.address.streetNumber}{" "}
                  {data.people.address.streetName}
                </div>
                <div>
                  {data.people.address.city}, {data.people.address.state}{" "}
                  {data.people.address.zipCode}
                </div>
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">
                Owner phone
              </div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {data.people.phone.map((item, idx) => (
                  <div key={"phone-" + idx}>
                    {item.number} ({item.type})
                  </div>
                ))}
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">
                Owner email
              </div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {data.people.email}
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">
                Opt in to alerts?
              </div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                <div>
                  {data.people.notification[0]}
                  {data.people.notification.length > 1 &&
                    ` & ${data.people.notification[1]}`}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OwnerProfileCard