import { useRef, useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";
import Tooltip from "../General/Tooltip";
import DownloadDocumentTile from "../General/DownloadDocumentTile";
import Badge from "../General/Badge";
import PaymentDetailMultiGridTabs from "./PaymentDetailMultiGridTabs";

export default function PaymentDetailCard({ data, idx }) {
  let panelRef = useRef(null);
  const [panelIsOpen, setPanelIsOpen] = useState(false);
  const UUID = uuidv4();
  useEffect(() => {
    if (idx === 0) setPanelIsOpen(true);
  }, [idx]);
  return (
    <div className="ui-card mt-4 xl:mt-6">
      <div className="flex flex-row items-start">
        <div className="px-4 pt-3 flex-grow xl:px-6 xl:pt-6">
          <h2 className="mb-0">
            <button
              ref={panelRef}
              className="flex items-center group"
              aria-controls={UUID}
              aria-expanded={panelIsOpen ? "true" : "false"}
              aria-label={`Expand or collapse Policy ${data.policyNumber} details`}
              onClick={() => {
                setPanelIsOpen((open) => (open = !open));
              }}
            >
              <span className="inline-block p-1 rounded-full mr-2 text-ui-blue group-hover:text-white group-hover:bg-ui-blue">
                <svg
                  className="ui-icon ui-plus w-4 h-4"
                  focusable="false"
                  aria-hidden="true"
                  role="presentation"
                >
                  <use href="#ui-icon-plus"></use>
                </svg>
                <svg
                  className="ui-icon ui-minus w-4 h-4"
                  focusable="false"
                  aria-hidden="true"
                  role="presentation"
                >
                  <use href="#ui-icon-minus"></use>
                </svg>
              </span>
              <span className="text-lg font-body font-bold">{data.title}</span>
            </button>
          </h2>
        </div>

        <div className="px-4 pt-3 xl:px-6 xl:pt-6">
          <Badge
            mediaSwitch={true}
            status={data.status}
            statusText={data.statusText}
            srPrefix="Payment"
          />
        </div>
      </div>

      <hr />

      <div
        id={UUID}
        className={`ui-payment-policy-card ${
          panelIsOpen ? "" : "ui-payment-policy-card-min"
        }`}
      >
        <div className="ui-payment-policy-details px-4 pb-4 xl:px-6 xl:pb-6">
          <div className="text-xs text-theme-text-tertiary">
            Policy Number #{data.policyNumber}
          </div>
          <div className="ui-payment-policy-details-grid">
            <div>
              <div className="text-xs text-theme-text-tertiary">
                Due {data.dueDate}
                <Tooltip
                  content="This is the amount that is due on the premium due date"
                  xMargin=""
                />
              </div>
              <div className="text-sm  text-theme-text-secondary font-semibold sm:text-base md:text-xl">
                ${data.dueAmount}
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">
                Policy will lapse on
                <Tooltip
                  content="If payment is not received by this date, the policy will lapse"
                  xMargin=""
                />
              </div>
              <div
                className={`text-sm font-semibold sm:text-base md:text-xl ${
                  data.status === "problem"
                    ? "text-ui-red"
                    : "text-theme-text-secondary"
                }`}
              >
                {data.lapseDate}
              </div>
            </div>
            <div className="ui-gridcell-hide">
              <div className="text-xs text-theme-text-tertiary">
                Premium amount
              </div>
              <div className="text-sm text-theme-text-secondary font-semibold sm:text-base md:text-xl">
                ${data.premiumAmount}
              </div>
            </div>
            <div className="ui-gridcell-hide">
              <div className="text-xs text-theme-text-tertiary">
                Last payment received
              </div>
              <div className="text-sm text-theme-text-secondary font-semibold">
                {data.lastPaymentDate}
              </div>
            </div>
            <div className="ui-gridcell-hide">
              <div className="text-xs text-theme-text-tertiary">
                Payment frequency
              </div>
              <div className="text-sm text-theme-text-secondary font-semibold">
                {data.frequency}
              </div>
              <div className="text-xs text-theme-text-tertiary">
                <Link to="/">Update frequency</Link>
              </div>
            </div>
            <div className="ui-gridcell-hide">
              <div className="text-xs text-theme-text-tertiary">
                Payment method
              </div>
              <div className="text-sm text-theme-text-secondary font-semibold">
                {data.method}
              </div>
              <div className="text-xs text-theme-text-tertiary">
                <Link to="/">Change payment method</Link>
              </div>
            </div>
          </div>
        </div>
        <PaymentDetailMultiGridTabs
          scheduledPayments={data.scheduledPayments}
          paymentHistory={data.paymentHistory}
          statements={data.statements}
        />
        <div className="ui-payment-policy-statements p-4 xl:p-6">
          {/* Last statement button */}
          <div className="ui-download-document">
            {data.documents.map((doc, idx) => {
              return (
                <DownloadDocumentTile
                  key={`doownload doc for policy ${data.policyNumber} - ${idx}`}
                  data={doc}
                />
              );
            })}
          </div>
        </div>
        <div className="ui-payment-policy-actions p-4 xl:p-6">
          <div className="text-right">
            <Link to="make-a-payment">
              <button className="ui-btn-primary ui-btn-min-w">
                Make Payment
              </button>
            </Link>
            <div className="mt-1 md:mt-2">
              <Link to={`/policies/${data.policyNumber}`} className="text-xs">
                View policy details
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
