import { Link, useNavigate } from "react-router-dom";
export default function BeneficiaryErrorPlaceholderPage() {   
  const navigate = useNavigate();
    return (
      <> 
      <div>
        <div className="sm:border-l sm:border-gray-200 sm:pl-6">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">Unauthorized User</h1>
        </div> 
        <div className="sm:border-l sm:border-gray-200 sm:pl-6" >
           <button class="ui-btn-secondary" onClick={() => navigate(-1)}>Back to Previous page</button>
        </div>    
      </div>   
      </>
    )
  }