import RegistrationConfirmModal from "../../components/ModalDialogs/RegistrationConfirmModal";
import { useState } from "react";
const PanelFour = ({ validatePanel }) => {
  const [modalState, setModalState] = useState(false);
  return (
    <>
      <div className="pt-4 mt-2 lg:pt-6 border-t border-theme-rule-medium">
        <div className="m-auto md:w-3/4">
          <p className="text-sm">
            We use a two-step authentication to confirm your email address.
          </p>
          <p className="text-sm">
            Please check your email for a confirmation code.
          </p>
          <div className="mt-4">
            <label id="ID_confirmationCodeLabel">Confirmation Code</label>
            <input
              id="ID_confirmationCode"
              className="w-full"
              type="text"
              aria-describedby="ID_confirmationCodeError"
              aria-labelledby="ID_confirmationCodeLabel"
            />
            <div id="ID_confirmationCodeError">
              <div className="ui-input-error hidden"></div>
            </div>
          </div>
        </div>
        <div className="mt-4 text-right">
          <button
            className="ui-btn-secondary ui-btn-min-w mt-2"
            onClick={() => validatePanel(2)}
          >
            Previous<span className="sr-only"> step</span>
          </button>
          <button className="ui-btn-secondary ui-btn-min-w mt-2">
            Resend Code
          </button>
          <button
            className="ui-btn-primary ui-btn-min-w mt-2"
            onClick={() =>
              setModalState({
                confirmationModal: true,
              })
            }
          >
            Submit
          </button>
        </div>
      </div>
      <RegistrationConfirmModal
        modalState={modalState}
        setModalState={setModalState}
      />
    </>
  );
};

export default PanelFour;
