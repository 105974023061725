import { Link } from "react-router-dom";
import Badge from "./Badge";
import StatusDot from "./StatusDot";
import Pill from "./Pill";
export default function SimpleDataRow({
  type,
  data,
  isOpenSendReminder,
  setIsOpenSendReminder,
}) {
  let dynamicMarkUp = "";
  switch (type) {
    case "reportDetail":
      dynamicMarkUp = (
        <div className="flex flex-col-reverse md:flex-row">
          <div className="flex-grow">
            <div className="grid gap-2 grid-cols-1 sm:gap-4 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-6">
              <div>
                <div className="text-xs text-theme-text-tertiary">
                  Policy# {data.policyNumber} : {data.policyType}
                </div>
                <div className="text-sm font-semibold text-theme-text-secondary">
                  {data.ownerName}
                </div>
              </div>
              <div>
                <div className="text-xs text-theme-text-tertiary">Coverage</div>
                <div className="text-sm font-semibold text-theme-text-secondary">
                  {data.coverage}
                </div>
              </div>
              <div>
                <div className="text-xs text-theme-text-tertiary">
                  Issue date
                </div>
                <div className="text-sm font-semibold text-theme-text-secondary">
                  {data.issueDate}
                </div>
              </div>
              <div>
                <div className="text-xs text-theme-text-tertiary">
                  Coverage end date
                </div>
                <div className="text-sm font-semibold text-theme-text-secondary">
                  {data.coverageEndDate}
                </div>
              </div>
              <div>
                <div className="text-xs text-theme-text-tertiary">Value</div>
                <div className="text-sm font-semibold text-theme-text-secondary">
                  {data.value}
                </div>
              </div>
              <div>
                <div className="text-xs text-theme-text-tertiary">
                  Another report policy field
                </div>
                <div className="text-sm font-semibold text-theme-text-secondary">
                  {"<data point>"}
                </div>
              </div>
            </div>
          </div>
          <div className="md:w-40 md:text-right">
            {data.status && (
              <div className="mb-4">
                <Badge
                  status={data.status}
                  statusText={data.statusText}
                  srPrefix="Policy"
                />
              </div>
            )}
          </div>
        </div>
      );
      break;
    case "report":
      dynamicMarkUp = (
        <div className="grid grid-cols-2 gap-4 md:grid-cols-[2fr,1fr,1fr] place-content-between">
          <div className="col-span-2 md:col-span-1">
            <div className="text-xs text-theme-text-tertiary">Name</div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              {data.name}
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">Date</div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              {data.date}
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">You can:</div>
            <div className="text-sm">
              <button className="ui-btn-hyperlink font-semibold">
                <Link to={`/reports/${data.id}`}>View</Link>
                <span className="sr-only"> {data.name}</span>
              </button>
              <span className="mx-4">/</span>
              <button className="ui-btn-hyperlink font-semibold">
                <Link to={`/reports/${data.id}`}>Download</Link>
                <span className="sr-only"> {data.name}</span>
              </button>
            </div>
          </div>
        </div>
      );
      break;
    case "commission":
      dynamicMarkUp = (
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:gap-6">
          <div>
            <div className="text-xs text-theme-text-tertiary">Month</div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              {data.month}
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">
              Statement date
            </div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              <div>{data.statementDate}</div>
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">
              Commission amount
            </div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              <div>{data.commissionAmount}</div>
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">Charge-backs</div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              <div>{data.chargebacks}</div>
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">You can</div>
            <div>
              <button className="ui-btn-hyperlink font-semibold">
                View
                <span className="sr-only"> {data.month} statement</span>
              </button>
              <span className="mx-2">|</span>
              <button className="ui-btn-hyperlink font-semibold">
                Print
                <span className="sr-only"> {data.month} statement</span>
              </button>
            </div>
          </div>
        </div>
      );
      break;
    case "statement":
      dynamicMarkUp = (
        <>
          <div className="grid grid-cols-1 gap-4 md:grid-cols-4 xl:gap-4">
            <div className="col-span-2">
              <div className="text-xs text-theme-text-tertiary">
                Statement Name
              </div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {data.statementName}
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">Date</div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {data.date}
              </div>
            </div>
            <div className="justify-self-end">
              <div>
                <div className="text-xs text-theme-text-tertiary">You can</div>
                <div className="text-sm font-semibold text-theme-text-secondary">
                  <button className="ui-btn-hyperlink font-semibold">
                    View<span className="sr-only"> {data.statementName}</span>
                  </button>
                  <span className="mx-2">/</span>
                  <button className="ui-btn-hyperlink font-semibold">
                    Download
                    <span className="sr-only"> {data.statementName}</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </>
      );
      break;
    case "bookOfBusiness":
      dynamicMarkUp = (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 xl:gap-6">
            <div className="">
              <div className="text-lg font-semibold text-theme-text-secondary">
                {data.policyName}
              </div>
              <div className="text-xs text-theme-text-tertiary">
                Policy # {data.policyNumber}: {data.policyType}
              </div>
            </div>
            <div className="sm:text-right">
              <Badge
                status={data.policyStatus}
                statusText={data.policyStatusTexxt}
              />
            </div>
          </div>

          <div className="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2 md:grid-cols-4 xl:mt-6">
            <div>
              <div className="text-xs text-theme-text-tertiary">
                Due {data.dueDate}
              </div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                $ {data.dueAmount}
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">
                Policy will lapse on
              </div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                <span
                  className={`${
                    data.policyStatus === "problem" ? "text-ui-red" : ""
                  }`}
                >
                  {data.lapseDate}
                </span>
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">
                Premium Amount
              </div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {data.premiumAmount}
              </div>
            </div>
            <div className="">
              {/* <div className="flex flex-row-reverse justify-between sm:flex-col sm:justify-end"> */}
              <div className="grid grid-cols-2 gap-x-4 sm:grid-cols-1 sm:gap-2">
                <div className="col-start-2 row-start-1 place-self-end sm:col-auto sm:row-auto">
                  <button
                    className="ui-btn-primary font-semibold"
                    onClick={() => setIsOpenSendReminder(true)}
                  >
                    Send reminder
                  </button>
                </div>

                <div className="col-start-1 row-start-1 place-self-start sm:col-auto sm:row-auto sm:place-self-end">
                  <Link to="/policies/93485397" className="text-xs text-left">
                    View policy details
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </>
      );
      break;
    case "policyResult":
      dynamicMarkUp = (
        <>
          <div className="relative">
            {data.banner && (
              <div className={`ui-badge-bar ui-badge-bar-${data.banner}`}></div>
            )}
            <div className="relative ui-card-bar px-3 py-2 lg:p-4">
              {data.banner && <div className="h-2"></div>}
              <div className="grid grid-cols-2 items-start gap-4 mr-10 sm:grid-cols-3 md:grid-cols-5 lg:grid-cols-7 xl:gap-6">
                <div className="col-span-2 sm:col-span-3 md:col-span-5 lg:col-span-2">
                  <div className="text-xxs text-theme-text-tertiary">
                    Policy number {data.policyNumber}
                  </div>
                  <div className="">
                    <span className="mr-2 md:hidden">
                      <Badge
                        iconOnly="true"
                        status={data.badgeStatus}
                        statusText={data.badgeText}
                        srPrefix="Policy"
                      />
                    </span>
                    <span className="text-lg font-semibold text-theme-text-secondary">
                      {data.policyTitle}
                    </span>
                  </div>
                </div>
                <div className="hidden md:grid">
                  <div className="text-xs text-theme-text-tertiary">
                    Insured Name
                  </div>
                  <div className="text-sm font-semibold text-theme-text-secondary">
                    {data.insuredName}
                  </div>
                </div>

                <div className="hidden md:grid">
                  <div className="text-xs text-theme-text-tertiary">
                    Product Name
                  </div>
                  <div className="text-sm font-semibold text-theme-text-secondary">
                    {data.productName}
                  </div>
                  <div className="text-xxs text-gray-dark">
                    Term - {data.term}
                  </div>
                </div>
                <div className="">
                  <div className="text-xs text-theme-text-tertiary">
                    Coverage
                  </div>
                  <div className="text-sm font-semibold text-theme-text-secondary">
                    ${data.coverage}
                  </div>
                </div>
                <div className="">
                  <div className="text-xs text-theme-text-tertiary">Value</div>
                  <div className="text-sm font-semibold text-theme-text-secondary">
                    ${data.value}
                  </div>
                </div>
                <div className="col-span-2 text-right sm:col-span-1">
                  <div className="hidden md:block">
                    <Badge
                      status={data.badgeStatus}
                      statusText={data.badgeText}
                      srPrefix="Policy"
                    />
                  </div>
                  {data.badgeStatus === "problem" && (
                    <button
                      className="ui-btn-primary mt-2"
                      onClick={() => setIsOpenSendReminder(true)}
                    >
                      Send reminder
                      <span className="sr-only">
                        {" "}
                        to customer for policy {data.policyNumber}
                      </span>
                    </button>
                  )}
                </div>
              </div>
              <Link
                to={`/policies/${data.policyNumber}`}
                target="_blank"
                className="ui-btn-card-arrow rounded-none border-t-0 border-r-0 border-b-0"
                aria-label={`View details for policy ${data.policyNumber}`}
              >
                <svg
                  className="ui-icon w-8 h-8"
                  focusable="false"
                  aria-hidden="true"
                  role="presentation"
                >
                  <use href="#ui-icon-next"></use>
                </svg>
              </Link>
            </div>
          </div>
        </>
      );
      break;
    case "completedTraining":
      dynamicMarkUp = (
        <div className="grid grid-cols-1 gap-4 place-content-between sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 xl:gap-6">
          <div className="col-span-1 sm:col-span-2 md:col-span-1">
            <div className="text-sm font-semibold text-theme-text-secondary">
              {data.name}
            </div>
            <div className="text-xs text-theme-text-tertiary">
              {data.category}
            </div>
          </div>

          <div className="">
            <div className="text-xs text-theme-text-tertiary">
              Completed Date
            </div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              {data.completedDate}
            </div>
          </div>

          <div className="">
            <div className="text-xs text-theme-text-tertiary">Good For</div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              {data.goodForDuration}
            </div>
          </div>

          <div className="">
            <div className="text-xs text-theme-text-tertiary">
              Another Field
            </div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              {data.another}
            </div>
          </div>

          <div>
            <div className="text-xs text-theme-text-tertiary">You can:</div>
            <div className="text-sm">
              <button className="ui-btn-hyperlink font-semibold">
                <Link to={`#`}>View</Link>
                <span className="sr-only"> {data.name}</span>
              </button>
              <span className="mx-2">/</span>
              <button className="ui-btn-hyperlink font-semibold">
                <Link to={`#`}>Download Certificate</Link>
                <span className="sr-only"> for {data.name}</span>
              </button>
            </div>
          </div>
        </div>
      );
      break;
    case "customerServicesAndClaims":
      return (
        <div className="p-4 border-t border-theme-rule-light">
          <div className="flex flex-col-reverse sm:flex-row justify-between items-stretch ml-5">
            <div className="flex flex-grow self-start text-xxs font-bold">
              Service Request ID: {data.id}
            </div>
            <div className="flex self-end text-xxs text-theme-text-tertiary text-right">
              Submitted: {data.submittedDate}
            </div>
          </div>
          <div className="flex justify-start items-stretch">
            <div className="flex pt-2 items-start flex-shrink-0 w-5">
              {data.showDot && <StatusDot status={data.status} />}
            </div>
            <div className="flex flex-col flex-grow self-start">
              <div className="text-sm font-semibold text-theme-text-secondary mt-1">
                {data.title}
              </div>
              <div className="text-xs mt-1">Life Policy #{data.policyNumber}</div>
              <div className="text-xs mt-2">
                <Pill status={data.status} statusText={data.statusText} />
              </div>
            </div>
            <div className="self-center">
              <Link
                to={`/services-claims/${
                  type === "service" ? "services" : "claims"
                }/${data.id}`}
              >
                <button
                  className="ui-btn-arrow"
                  aria-label="View policy details"
                >
                  <svg
                    className="ui-icon w-8 h-8"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-next"></use>
                  </svg>
                </button>
              </Link>
            </div>
          </div>
        </div>
      );
  }
  switch (type) {
    case "policyResult":
      return <div className="relative mt-4">{dynamicMarkUp}</div>;
    default:
      return (
        <div className="ui-card-bar relative mt-4">
          <div className="px-3 py-2 lg:p-4">{dynamicMarkUp}</div>
        </div>
      );
  }
}
