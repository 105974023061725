import { Link } from "react-router-dom";
import { Tab } from "@headlessui/react";
import {
  requiredSalesMaterialsData,
  recommandedSalesMaterialsData,
  pinnedSalesMaterialsData,
  completedSalesMaterialsData,
} from "../../js/placeholderData/agents/salesMaterialsData";
import { usePagination } from "../../hooks/usePagination";
import Billboard from "../../components/General/Billboard";
import ArticleCard from "../../components/AgentPortal/SalesMaterials/ArticalCard";
import SimpleDataRow from "../../components/General/SimpleDataRow";
import Pagination from "../../components/General/Pagination";
import SortByAlphabetical from "../../components/General/SortByAlphabetical";
import FilterDialog from "../../components/AgentPortal/SalesMaterials/FilterDialog";
import UploadProofOfTrainingDialog from "../../components/AgentPortal/SalesMaterials/UploadProofOfTrainingDialog";
import CallToActionCardWithVideo from "../../components/General/CallToActionCardWithVideo";


import { useState } from "react";

export default function SalesMaterials() {
  const billboardData = {
    title: "Required suitability training needed to be completed by MM/DD",
    svgClass: "text-ui-yellow",
    svgUse: "ui-icon-border-risk",
    description:
      "Additional content to clearify what needs to be done in here.",
  };

  const {
    current: currentCompletedTrainingData,
    numberPerPage,
    totalPages,
    totalItems,
    currentPage,
    setCurrentPage,
  } = usePagination(completedSalesMaterialsData, 6);

  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);

  return (
    <>
      <div className="container mb-8">
        <h1>Sales &amp; Training</h1>
        <p>Lorem ipsum</p>

        <hr />
      </div>
      <div className="container ui-component-spacing">
        <Billboard data={billboardData} />
      </div>
      <div className="container ui-component-spacing">
        <CallToActionCardWithVideo
          title="Title for featured sales & training article or video"
          copy="Everlake wants to do more than just provide a portal. We are you partner in lorem ipsum dolor sit amet, consectetur adipiscing elit. Cras vel eros libero."
          buttonPrimaryLabel="Call to action to featured item"
          buttonPrimaryURL="/help/faqs"
          buttonPrimaryStyle="primary"
          buttonSecondaryLabel="Call to action to featured sales item"
          buttonSecondaryURL="/"
          buttonSecondaryStyle="primary"
          videoPath="https://www.youtube.com/embed/xbBr5b3-wSQ?rel=0"
          videoWidth="400"
          videoHeight="230"
          videoTitle="Getting started | Video viewing basics"
        />
      </div>
      <div className="container ui-component-spacing">
        <h2>Required &amp; Supplement Sales &amp; Training Materials</h2>
        <p className="text-sm flex flex-row justify-between">
          Browse through categories{" "}
          <button
            className="ui-btn-primary"
            onClick={() => setUploadDialogOpen(true)}
          >
            Upload Proof of External Training
          </button>
        </p>

        <hr />

        <Tab.Group>
          <div className="flex flex-col lg:flex-row lg:items-center">
            <div className="ui-card flex-grow px-2 p-1 md:px-4">
              <Tab.List>
                <Tab className="text-sm mx-3 pt-3 pb-2 mb-1 md:text-base md:mx-4 lg:text-lg">
                  Required
                </Tab>
                <Tab className="text-sm mx-3 pt-3 pb-2 mb-1 md:text-base md:mx-4 lg:text-lg">
                  Recommended
                </Tab>
                <Tab className="text-sm mx-3 pt-3 pb-2 mb-1 md:text-base md:mx-4 lg:text-lg">
                  Pinned
                </Tab>
                <Tab className="text-sm mx-3 pt-3 pb-2 mb-1 md:text-base md:mx-4 lg:text-lg">
                  Completed
                </Tab>
              </Tab.List>
            </div>
          </div>

          <div className="mx-2 flex flex-col items-centers justify-between md:mx-4 md:flex-row md:space-x-4">
            <div className="flex flex-row items-center mt-4 xl:mt-6">
              <SortByAlphabetical changesDisplay="trainingList" />
            </div>
            <div className="flex flex-row items-center mt-4 space-x-4 justify-between md:justify-end lg:space-x-6 xl:mt-6">
              <div>
                <button
                  className="ui-btn-primary flex items-center"
                  onClick={() => setFilterDialogOpen(true)}
                >
                  <svg
                    className="ui-icon w-3.5 h-3.5 mr-2"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-filter"></use>
                  </svg>
                  Filter options
                </button>
              </div>
            </div>
          </div>
          <div id="trainingList" className="mt-4 xl:mt-6">
            <Tab.Panels>
              <Tab.Panel>
                <div className="grid gap-4 sm:grid-cols-2 lg:md:grid-cols-3 xl:gap-6">
                  {requiredSalesMaterialsData.map((data, idx) => (
                    <ArticleCard
                      key={`required training - ${idx}`}
                      data={data}
                    />
                  ))}
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 xl:gap-6">
                  {recommandedSalesMaterialsData.map((data, idx) => (
                    <ArticleCard
                      key={`recommanded training - ${idx}`}
                      data={data}
                    />
                  ))}
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <div className="grid gap-4 md:grid-cols-2 lg:grid-cols-3 xl:gap-6">
                  {pinnedSalesMaterialsData.map((data, idx) => (
                    <ArticleCard key={`pinned training - ${idx}`} data={data} />
                  ))}
                </div>
              </Tab.Panel>
              <Tab.Panel>
                <div>
                  {currentCompletedTrainingData.map((data, idx) => (
                    <SimpleDataRow
                      key={`completed training - ${idx}`}
                      type="completedTraining"
                      data={data}
                    />
                  ))}

                  <div className="mt-4 xl:mt-6">
                    <Pagination
                      numberPerPage={numberPerPage}
                      totalPages={totalPages}
                      totalItems={totalItems}
                      currentPage={currentPage}
                      setCurrentPage={setCurrentPage}
                    />
                  </div>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </div>
        </Tab.Group>
      </div>
      <FilterDialog
        dialogState={filterDialogOpen}
        setDialogState={setFilterDialogOpen}
      />
      <UploadProofOfTrainingDialog
        dialogState={uploadDialogOpen}
        setDialogState={setUploadDialogOpen}
      />
    </>
  );
}
