import { RadioGroup } from "@headlessui/react";
import { useState } from "react";
import { Controller } from "react-hook-form";

const PanelFour = ({
  currDate,
  selectedDate,
  setSelectedDate,
  register,
  setValue,
  setCurrentStep,
  handleSubmit,
  dataSubmit,
  policyFilter,
  control
}) => {
  const [otherDate, setOtherDate] = useState()
  const dueDate = policyFilter[0].dueDate
  const onSubmit = () => {
    handleSubmit(dataSubmit)()
    setCurrentStep(5)
  }

  return (
    <>
      <form>
        <RadioGroup className="ui-form-card-row cursor-pointer font-semibold" value={selectedDate} onChange={setSelectedDate}>

          <RadioGroup.Option
            value="today" className={({ checked }) =>
              `${checked ? "ui-form-card cursor-pointer font-semibold selected" : "ui-form-card cursor-pointer font-semibold"}`
            }
            {...register("selectedDate")}
            onClick={() => {
              setValue("selectedDate", currDate)
              onSubmit()
            }
            }
          >

            <RadioGroup.Label
              as="p"
              className="block text-xxs mb-1 pr-8"
            >
              Today
            </RadioGroup.Label>
            <RadioGroup.Label className="block text-black">
              MM/DD/YYYY
            </RadioGroup.Label>
            <div className="ui-form-card-checkbox">
              <svg className="ui-icon w-2 h-2 text-white hidden" focusable="false" aria-hidden="true" role="presentation">
                <use href="#ui-icon-checkmark"></use>
              </svg>
            </div>

          </RadioGroup.Option>

          <RadioGroup.Option
            value="dueDate" className={({ checked }) =>
              `${checked ? "ui-form-card cursor-pointer font-semibold selected" : "ui-form-card cursor-pointer font-semibold"}  `
            }
            {...register("selectedDate")}
            onClick={() => {
              setValue("selectedDate", dueDate)
              onSubmit()
            }
            }
          >

            <RadioGroup.Label
              as="p"
              className="block text-xxs mb-1 pr-8"
            >
              Due Date
            </RadioGroup.Label>
            <RadioGroup.Label className="block text-black">
              MM/DD/YYYY
            </RadioGroup.Label>
            <div className="ui-form-card-checkbox">
              <svg className="ui-icon w-2 h-2 text-white hidden" focusable="false" aria-hidden="true" role="presentation">
                <use href="#ui-icon-checkmark"></use>
              </svg>
            </div>

          </RadioGroup.Option>

          <RadioGroup.Option
            id="ID_paymentStep1Option1"
            className={({ checked }) => `${checked ? "ui-form-card selected" : "ui-form-card"}  `}
            tabIndex="0"
            aria-label="Select to pay this policy"
            aria-controls="ID_paymentStep2Options"
            aria-expanded="false"
            value="otherDate"
          >
            {({ checked }) => (
              <>
                <RadioGroup.Label
                  className="text-xxs mb-1 pr-8 font-semibold"
                  as="p"
                >
                  Other Date
                </RadioGroup.Label>
                <Controller
                  name="selectedDate"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <input id="ID_paymentDateOther" {...rest}
                      type="date"
                      className="w-full"
                      value={otherDate}
                      onChange={(e) => {
                        setValue("selectedDate", e.target.value)
                        onSubmit()
                      }
                      }
                      disabled={!checked}
                      placeholder="MM/DD/YYYY" aria-placeholder="MM/DD/YYYY" aria-describedby="ID_paymentDateOtherDesc" aria-label="Enter date" aria-controls="ID_paymentStep3Options"
                    />
                  )}
                />

                <RadioGroup.Label as="div" className="text-xxs text-right">
                  Use format MM/DD/YYYY
                </RadioGroup.Label>
                <div className="text-xxs font-semibold pr-8 group-hover:underline">
                  <div className="ui-form-card-checkbox">
                    <svg
                      className="ui-icon w-2 h-2 text-white hidden"
                      focusable="false"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <use href="#ui-icon-checkmark"></use>
                    </svg>
                  </div>
                </div>
              </>
            )}
          </RadioGroup.Option>
        </RadioGroup>
      </form>
    </>
  )
}

export default PanelFour