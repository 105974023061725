// route: /messages
export const messsageCategories = [
  {
    id: 1,
    type: "all",
    title: "All Inbox",
    desc: "All messages in your inbox",
    icon: "#ui-icon-message",
    unread: 36,
  },
  {
    id: 2,
    type: "general",
    title: "General Alerts",
    desc: "Company alerts common for all policyholders",
    icon: "#ui-icon-alert",
  },
  {
    id: 3,
    type: "policy",
    title: "Policy Correspondence",
    desc: "Alerts sent to you specific to your policy or policy types",
    icon: "#ui-icon-message",
  },
  {
    id: 4,
    type: "documents",
    title: "Documents",
    desc: "Documents we have sent to you regarding your policies",
    icon: "#ui-icon-document",
  },
  {
    id: 5,
    type: "services",
    title: "Services & Claims",
    desc: "Alerts sent to you specific to your service & claims requests",
    icon: "#ui-icon-message",
  },
];
export const messages = [
  {
    id: 1,
    category: 3,
    status: "problem",
    title: "Payment past due",
    policyNumber: "234234523",
    desc: "Your payment is past due on policy #55555555. If full payment is not received by MM/DD/YYYY your policy may lapse. Please make a payment now.",
    cta: "Make a Payment",
    ctaUrl: "/payments",
    date: "01/01/2023",
  },
  {
    id: 2,
    category: 2,
    status: "",
    title: "Claim Received",
    policyNumber: "234234523",
    desc: "We have received your claim. Be sure to read and respond to all claims messages to avoid delay in payout.",
    cta: "Track Claim Status",
    ctaUrl: "",
    date: "01/01/2023",
  },
  {
    id: 3,
    category: 3,
    status: "problem",
    title: "Payment past due",
    policyNumber: "234234523",
    desc: "Your payment is past due on policy #55555555. If full payment is not received by MM/DD/YYYY your policy may lapse. Please make a payment now.",
    cta: "Make a Payment",
    ctaUrl: "",
    date: "01/01/2023",
  },
  {
    id: 4,
    category: 4,
    status: "",
    title: "Claim Received",
    policyNumber: "234234523",
    desc: "We have received your claim. Be sure to read and respond to all claims messages to avoid delay in payout.",
    cta: "Track Claim Status",
    ctaUrl: "",
    date: "01/01/2023",
  },
  {
    id: 5,
    category: 5,
    status: "good",
    title: "Payment Made",
    policyNumber: "234234523",
    desc: "We have recieved your payment in full.",
    cta: "View Invoice",
    ctaUrl: "",
    date: "01/01/2023",
  },
  {
    id: 6,
    category: 5,
    status: "",
    title: "Claim Received",
    policyNumber: "234234523",
    desc: "We have received your claim. Be sure to read and respond to all claims messages to avoid delay in payout.",
    cta: "Track Calim Status",
    ctaUrl: "",
    date: "01/01/2023",
  },
  {
    id: 7,
    category: 4,
    status: "",
    title: "Claim Received",
    policyNumber: "234234523",
    desc: "We have received your claim. Be sure to read and respond to all claims messages to avoid delay in payout.",
    cta: "Track Calim Status",
    ctaUrl: "",
    date: "01/01/2023",
  },
  {
    id: 8,
    category: 3,
    status: "",
    title: "Claim Received",
    policyNumber: "234234523",
    desc: "We have received your claim. Be sure to read and respond to all claims messages to avoid delay in payout.",
    cta: "Track Calim Status",
    ctaUrl: "",
    date: "01/01/2023",
  },
  {
    id: 9,
    category: 2,
    status: "risk",
    title: "Payment past due",
    policyNumber: "234234523",
    desc: "Your payment is past due on policy #55555555. If full payment is not received by MM/DD/YYYY your policy may lapse. Please make a payment now.",
    cta: "Make a payment",
    ctaUrl: "",
    date: "01/01/2023",
  },
  {
    id: 10,
    category: 3,
    status: "risk",
    title: "Payment past due",
    policyNumber: "234234523",
    desc: "Your payment is past due on policy #55555555. If full payment is not received by MM/DD/YYYY your policy may lapse. Please make a payment now.",
    cta: "Make a Payment",
    ctaUrl: "",
    date: "01/01/2023",
  },
  {
    id: 11,
    category: 3,
    status: "risk",
    title: "Payment past due",
    policyNumber: "234234523",
    desc: "Your payment is past due on policy #55555555. If full payment is not received by MM/DD/YYYY your policy may lapse. Please make a payment now.",
    cta: "Make a Payment",
    ctaUrl: "",
    date: "01/01/2023",
  },
  {
    id: 12,
    category: 2,
    status: "risk",
    title: "Payment past due",
    policyNumber: "234234523",
    desc: "Your payment is past due on policy #55555555. If full payment is not received by MM/DD/YYYY your policy may lapse. Please make a payment now.",
    cta: "Make a Payment",
    ctaUrl: "",
    date: "01/01/2023",
  },
  {
    id: 13,
    category: 4,
    status: "good",
    title: "Payment Made",
    policyNumber: "234234523",
    desc: "Your payment is past due on policy #55555555. If full payment is not received by MM/DD/YYYY your policy may lapse. Please make a payment now.",
    cta: "Make a Payment",
    ctaUrl: "",
    date: "01/01/2023",
  },
  {
    id: 14,
    category: 4,
    status: "",
    title: "Claim Received",
    policyNumber: "234234523",
    desc: "We have received your claim. Be sure to read and respond to all claims messages to avoid delay in payout.",
    cta: "Track Calim Status",
    ctaUrl: "",
    date: "01/01/2023",
  },
  {
    id: 15,
    category: 5,
    status: "",
    title: "Claim Received",
    policyNumber: "234234523",
    desc: "We have received your claim. Be sure to read and respond to all claims messages to avoid delay in payout.",
    cta: "Track Calim Status",
    ctaUrl: "",
    date: "01/01/2023",
  },
];
