import { useState } from "react";
import { useParams } from "react-router-dom";
import { claim } from "../js/placeholderData/servicesAndClaims";

import Carousel from "../components/General/Carousel";
import ClaimStatusCard from "../components/ServicesAndClaims/ClaimStatusCard";
import ClaimStatusRequiredDocCard from "../components/ServicesAndClaims/ClaimStatusRequiredDocCard";

export default function ClaimsStatus() {
  const [requiredDocDisplayState, setRequiredDocDisplayState] =
    useState("card");
  const params = useParams();

  return (
    <>
      <div className="container mb-8">
        <h1>Work on claim #{params.id}</h1>
        <p>
          Lorem ipsum dolor sit amet, eam ad autem fabulas. Expeten`d`a
          dissentiet
        </p>
        <hr />
      </div>

      <div className="container ui-component-spacing">
        <div className="ui-card p-4 relative xl:p-6">
          <Carousel />
        </div>
      </div>

      <div className="container ui-component-spacing">
        <ClaimStatusCard data={claim} />
      </div>

      <div className="container ui-component-spacing">
        <h2>Required Forms &amp; Documents</h2>
        <div className="flex items-start">
          <div>
            <p className="text-sm">
              Below are all of the forms and documents required to process your
              claim. Once all of these have been completed/uploaded, you will be
              able to track the processing of your claim.
            </p>
          </div>
          <div className="ml-8 hidden md:inline-flex md:items-center">
            <div className="text-sm font-light mr-2">View</div>
            <button
              className={`rounded-l ${
                requiredDocDisplayState === "card"
                  ? "ui-btn-layout-current"
                  : "ui-btn-layout"
              }`}
              aria-label="View documents as cards"
              onClick={() => setRequiredDocDisplayState("card")}
            >
              <svg
                className="ui-icon w-4 h-4"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href="#ui-icon-layout-cards"></use>
              </svg>
            </button>
            <button
              className={`rounded-r ${
                requiredDocDisplayState === "table"
                  ? "ui-btn-layout-current"
                  : "ui-btn-layout"
              }`}
              aria-label="View documents in table"
              onClick={() => setRequiredDocDisplayState("table")}
            >
              <svg
                className="ui-icon w-4 h-4"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href="#ui-icon-layout-table"></use>
              </svg>
            </button>
          </div>
        </div>
        <hr />
      </div>

      <div className="container ui-component-spacing">
        <div className="ui-documents-grid">
          <div
            className={
              requiredDocDisplayState === "card"
                ? "ui-grid-cards"
                : "ui-grid-table"
            }
          >
            {claim.requiredDocs.map((doc, key) => {
              return (
                <ClaimStatusRequiredDocCard
                  key={`${claim.title} required docs - ${key}`}
                  data={doc}
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}
