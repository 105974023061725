import { useEffect } from "react";

export const useMobileHeader = () => {
  let _headerUtilMenu;
  let _headerMainMenu;
  let _headerMainNavBar;
  let _headerMenuBtn;
  let _headerMask;
  let _headerNav;
  let _headerMenuBtnIcon;

  useEffect(() => {
    window.addEventListener("resize", closeMobileMenu);
    return () => window.removeEventListener("resize", closeMobileMenu);
  });

  var getStyle = function (e, styleName) {
    var styleValue = "";
    if (document.defaultView && document.defaultView.getComputedStyle) {
      styleValue = document.defaultView
        .getComputedStyle(e, "")
        .getPropertyValue(styleName);
    } else if (e.currentStyle) {
      styleName = styleName.replace(/\-(\w)/g, function (strMatch, p1) {
        return p1.toUpperCase();
      });
      styleValue = e.currentStyle[styleName];
    }
    return styleValue;
  };

  function openMobileMenu() {
    _headerUtilMenu = document.getElementById("id_utilityMenu");
    _headerMainMenu = document.getElementById("id_mainMenu"); // main
    if (_headerMainMenu) {
      _headerMainNavBar = _headerMainMenu.querySelector(".ui-navbar");
      _headerMenuBtn = document.getElementById("id_menuBtn");
      _headerMenuBtnIcon = _headerMenuBtn.querySelector("use");
      _headerMask = document.getElementById("id_mobileMenuMask");
      _headerNav = document.querySelector(".ui-main-menu");
    }
    if (
      localStorage.getItem("isMobileHeaderMenuOpen") &&
      localStorage.getItem("isMobileHeaderMenuOpen") === "true"
    ) {
      closeMobileMenu();
    } else {
      if (_headerNav) {
        localStorage.setItem("isMobileHeaderMenuOpen", "true");
        let ht = parseInt(getStyle(_headerUtilMenu, "height"), 10);
        ht = Math.ceil(ht + 2);
        _headerNav.style.top = ht + "px";
        _headerNav.style.bottom = "0px";
        _headerMask.style.top = ht + "px";
        _headerMainMenu.style.top = ht + "px";
        _headerMainMenu.tabIndex = -1;
        _headerMask?.classList.remove("hidden");
        _headerNav?.classList.remove("hidden");
        _headerMainNavBar?.classList.remove("hidden");
        _headerNav?.classList.add("overflow-y-auto");
        _headerMenuBtn?.setAttribute("aria-expanded", "true");
        document.body.classList.add("overflow-hidden");
        _headerMenuBtnIcon.setAttribute("href", "#ui-icon-close-x");

        _headerMenuBtn.addEventListener("keydown", (e) => {
          if (e.key === "Escape") closeMobileMenu();
        });
        _headerNav.addEventListener("keydown", (e) => {
          if (e.key === "Escape") closeMobileMenu();
        });
      }
    }
    return () => {};
  }

  function closeMobileMenu() {
    if (_headerNav) {
      _headerNav.style.top = "auto";
      _headerNav.style.bottom = "auto";
      _headerMainMenu.tabIndex = 0;
      _headerMainMenu.style.top = "auto";
      _headerMainMenu.style.bottom = "auto";
      _headerNav?.classList.add("hidden");
      _headerMainNavBar?.classList.add("hidden");
      _headerNav?.classList.remove("overflow-y-auto");
      _headerMenuBtn?.focus();
      _headerMenuBtn?.setAttribute("aria-expanded", "true");
      document.body.classList.remove("overflow-hidden");
      _headerMenuBtnIcon.setAttribute("href", "#ui-icon-menu");
    }
    localStorage.removeItem("isMobileHeaderMenuOpen");
  }

  return {
    open: openMobileMenu,
    close: closeMobileMenu,
  };
};
