export default function Billboard({ data }) {
  return (
    <div className="ui-card p-4 relative xl:p-6">
      <div className="flex flex-col">
        <div className="grid ui-grid-carousel gap-2">
          <div className="md:row-span-2">
            <svg
              className={`ui-icon w-12 h-12 ${data.svgClass}`}
              focusable="false"
              aria-hidden="true"
              role="presentation"
            >
              <use href={`#${data.svgUse}`}></use>
            </svg>
          </div>
          <h2 className="text-2xl font-body font-semibold">{data.title}</h2>
          <div className="col-span-2 md:col-auto">{data.description}</div>
        </div>
      </div>
    </div>
  );
}
