import { Link } from "react-router-dom";
import Badge from "./General/Badge";
import { useNavigate } from "react-router-dom";
export default function PolicyCard({ data }) {
  const navigate = useNavigate();
  return (
    <div className="ui-grid-item">
      {data.banner && (
        <div className={`ui-badge-bar ui-badge-bar-${data.banner}`}></div>
      )}
      <div className="ui-grid-layout">
        <div className="ui-policy-col-01">
          <div className="text-xxs text-theme-text-tertiary">
            Policy number {data.policyNumber}
          </div>
          <div className="ui-grid-name">
            <div className="text-lg font-semibold text-theme-text-secondary">
              {data.policyTitle}
            </div>
            <Badge
              status={data.badgeStatus}
              statusText={data.badgeText}
              srPrefix="Policy"
            />
          </div>
        </div>

        <div className="ui-policy-col-02">
          <div className="text-xs text-theme-text-tertiary">Insured Name</div>
          <div className="text-sm font-semibold text-theme-text-secondary">
            {data.insuredName}
          </div>
        </div>

        <div className="ui-policy-col-03">
          <div className="text-xs text-theme-text-tertiary">Coverage</div>
          <div className="text-sm font-semibold text-theme-text-secondary">
            ${data.coverage}
          </div>
        </div>

        <div className="ui-policy-col-04">
          <div className="text-xs text-theme-text-tertiary">Product Name</div>
          <div className="text-sm font-semibold text-theme-text-secondary">
            {data.productName}
          </div>
          <div className="text-xxs text-theme-text-tertiary">Term - {data.term}</div>
        </div>

        <div className="ui-policy-col-05">
          {/* Logic for cutover one to limit functionality */}
          {data.value.length > 1 && data.productName !== "Annual Renewable" && (
            <>
              <div className="text-xs text-theme-text-tertiary">Value</div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                ${data.value}
              </div>
            </>
          )}
        </div>

        <div className="ui-policy-col-06 text-right">
          <Badge
            status={data.badgeStatus}
            statusText={data.badgeText}
            srPrefix="Policy"
          />

          {data.cta === "payment" && (
            <button className="ui-btn-primary"            
            onClick={() => navigate(`/payment`)}>
              Make Payment
              <span className="sr-only"> for policy {data.policyNumber}</span>
            </button>
          )}
          {data.cta === "detail" && (
            <button className="ui-btn-secondary"
            onClick={() => navigate(`/policies/${data.policyNumber}`)}>
              View Details
              <span className="sr-only"> for policy {data.policyNumber}</span>
            </button>
          )}
        </div>
      </div>
      <Link
        className="ui-btn-arrow-details"
        aria-label={` View details for policy ${data.policyNumber}`}
        to={`/policies/${data.policyNumber}`}
      >
        <svg
          className="ui-icon w-8 h-8"
          focusable="false"
          aria-hidden="true"
          role="presentation"
        >
          <use href="#ui-icon-next"></use>
        </svg>
      </Link>
    </div>
  );
}
