import { accountArray } from "../../js/placeholderData/managePaymentData";
import { useEffect } from "react";

const AddPaymentEFT = ({
  register,
  errors,
  watch,
  autoValidate,
  trigger,
  getFieldState,
  validateTimer,
  setValidationState,
}) => {
  let debounceFunction;
  useEffect(() => {
    if (autoValidate) {
      !watch(["paymentMethod", "routingNumber", "accountNumber"]).filter(
        (v) => !v
      ).length &&
      !getFieldState("confirmRoutingNumber").error &&
      !getFieldState("confirmAccountNumber").error
        ? setValidationState(true)
        : setValidationState(false);
    }
  }, [
    watch([
      "paymentMethod",
      "routingNumber",
      "accountNumber",
      "confirmRoutingNumber",
      "confirmAccountNumber",
    ]),
  ]);
  return (
    <>
      <div>
        <label id="ID_paymentMethodLabel" htmlFor="ID_paymentMethod">
          Payment method:
        </label>
        <div>
          <select
            id="ID_paymentMethod"
            aria-labelledby="ID_paymentMethodLabel"
            aria-describedby="ID_paymentMethodError"
            {...register("paymentMethod")}
          >
            {accountArray.map((item, idx) => (
              <option key={idx} value={item.name}>
                {item.name}
              </option>
            ))}
          </select>
        </div>
      </div>

      <div className="col-span-1"></div>

      <div className="">
        <label id="ID_creditCardNumberLabel" htmlFor="ID_creditCardNumber">
          Routing number *
        </label>
        <div className="">
          <input
            id="ID_creditCardNumber"
            type="text"
            {...register("routingNumber", {
              required: {
                value: true,
                message: "please provide valid routing number",
              },
            })}
            aria-labelledby="ID_creditCardNumberLabel"
            aria-describedby="ID_creditCardNumberError ID_creditCardNumberNote"
            onKeyUp={() => {
              if (autoValidate) {
                clearTimeout(debounceFunction);
                debounceFunction = setTimeout(async () => {
                  await trigger("routingNumber");
                }, validateTimer);
              }
            }}
            onKeyDown={() => {
              if (autoValidate) {
                clearTimeout(debounceFunction);
              }
            }}
          />
          {errors.routingNumber && (
            <div id="ID_creditCardNumberError" className="ui-input-error">
              {errors.routingNumber.message}
            </div>
          )}
        </div>
      </div>
      <div>
        <label id="ID_creditCardNumberLabel" htmlFor="ID_creditCardNumber">
          Confirm Routing number *
        </label>
        <div className="">
          <input
            id="ID_creditCardNumber"
            type="text"
            {...register("confirmRoutingNumber", {
              validate: (value) => {
                if (watch("routingNumber") !== value) {
                  return "The routes do not match";
                }
              },
            })}
            aria-labelledby="ID_creditCardNumberLabel"
            aria-describedby="ID_creditCardNumberError ID_creditCardNumberNote"
            onKeyUp={() => {
              if (autoValidate) {
                clearTimeout(debounceFunction);
                debounceFunction = setTimeout(async () => {
                  await trigger("confirmRoutingNumber");
                }, validateTimer);
              }
            }}
            onKeyDown={() => {
              if (autoValidate) {
                clearTimeout(debounceFunction);
              }
            }}
          />
          {errors.confirmRoutingNumber && (
            <div id="ID_creditCardNumberError" className="ui-input-error">
              {errors.confirmRoutingNumber.message}
            </div>
          )}
        </div>
      </div>
      <div>
        <label id="ID_expDateLabel" htmlFor="ID_expDate">
          Account number *
        </label>
        <div className="">
          <input
            id="ID_expDate"
            type="text"
            {...register("accountNumber", {
              required: { value: true, message: "invalid account number" },
            })}
            aria-labelledby="ID_expDateLabel"
            aria-describedby="ID_expDateError ID_expDateNote"
            onKeyUp={() => {
              if (autoValidate) {
                clearTimeout(debounceFunction);
                debounceFunction = setTimeout(async () => {
                  await trigger("accountNumber");
                }, validateTimer);
              }
            }}
            onKeyDown={() => {
              if (autoValidate) {
                clearTimeout(debounceFunction);
              }
            }}
          />
        </div>
        {errors.accountNumber && (
          <div id="ID_expDateError" className="ui-input-error">
            {errors.accountNumber.message}
          </div>
        )}
        <div
          id="ID_expDateNote"
          className="text-xxs text-theme-text-tertiary mt-1"
        >
          Use format XXXXXXXXXXXXXXXX
        </div>
      </div>

      <div>
        <div className="ui-has-tooltip">
          <label id="ID_securityCodeLabel" htmlFor="ID_securityCode">
            Confirm account number *
          </label>
        </div>
        <div className="">
          <input
            id="ID_securityCode"
            type="text"
            {...register("confirmAccountNumber", {
              validate: (value) => {
                if (watch("accountNumber") !== value) {
                  return "numbers don't match";
                }
              },
            })}
            aria-labelledby="ID_securityCodeLabel"
            aria-describedby="ID_securityCodeError ID_securityCodeNote"
            onKeyUp={() => {
              if (autoValidate) {
                clearTimeout(debounceFunction);
                debounceFunction = setTimeout(async () => {
                  await trigger("confirmAccountNumber");
                }, validateTimer);
              }
            }}
            onKeyDown={() => {
              if (autoValidate) {
                clearTimeout(debounceFunction);
              }
            }}
          />
        </div>
        {errors.confirmAccountNumber && (
          <div id="ID_securityCodeError" className="ui-input-error">
            {errors.confirmAccountNumber.message}
          </div>
        )}
        <div
          id="ID_securityCodeNote"
          className="text-xxs text-theme-text-tertiary mt-1"
        >
          Use format XXXXXXXXXXXXXXXX
        </div>
      </div>
    </>
  );
};

export default AddPaymentEFT;
