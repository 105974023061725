import { useState } from "react";
import { useForm } from "react-hook-form";
import { Disclosure } from "@headlessui/react";

import PanelTwo from "../components/Registration/PanelTwo";
import PanelOne from "../components/Registration/PanelOne";
import PanelThree from "../components/Registration/PanelThree";
import PanelFour from "../components/Registration/PanelFour";

const Register = () => {
  const validatePanel = (position) => {
    const updateState = panelState.panels.map((item, idx) => {
      if (idx === position) return { panelValid: true };
      if (idx !== position) return { panelValid: false };
    });
    setPanelState({ panels: updateState });
  };

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    shouldUnregister: false,
  });

  const onSubmit = (panel) => {
    validatePanel(panel);
  };

  const [policyHolder, setPolicyHolder] = useState("individual");
  const [paperlessSettings, setPaperlessSettings] = useState("yes");

  const [panelState, setPanelState] = useState({
    panels: [
      { panelValid: true },
      { panelValid: false },
      { panelValid: false },
      { panelValid: false },
    ],
  });

  return (
    <>
      <div className="flex flex-col flex-grow">
        <div className="border border-transparent">
          <div className="container mb-8">
            <h1>Sign up for an account</h1>
            <hr />
          </div>

          <div className="container mb-8">
            <div className="ui-form-registration">
              {/* <!-- Step 1 --> */}
              <div className="flex flex-row md:ml-8 md:mr-0 lg:ml-20 xl:ml-28 lg:pr-16">
                <div className="w-8 md:flex md:flex-col md:justify-center md:mr-14">
                  <div className="flex justify-center items-center mt-6 mb-4">
                    <div
                      className={`inline-flex justify-center items-center h-8 w-8 text-white ${
                        panelState.panels[0].panelValid ||
                        panelState.panels[1].panelValid ||
                        panelState.panels[2].panelValid ||
                        panelState.panels[3].panelValid
                          ? "bg-ui-blue"
                          : "bg-ui-gray-medium-dark"
                      } rounded-full overflow-hidden`}
                    >
                      1
                    </div>
                  </div>
                  <div className="flex h-full w-px bg-ui-gray-medium-light mx-auto overflow-hidden"></div>
                </div>

                <div className="w-full flex-grow">
                  {/* <!-- Step 1 Card --> */}

                  <div className="ui-card relative px-4 py-4 mb-6 lg:px-6">
                    <Disclosure as="div">
                      {({ open }) => (
                        <>
                          <Disclosure.Button as="div">
                            <div className="flex justify-between items-start">
                              <h2 className="flex flex-grow font-body font-bold text-xl">
                                Verify Owner &amp; Policy Information
                              </h2>
                              <div className="font-semibold text-sm whitespace-nowrap">
                                Step 1
                              </div>
                            </div>
                          </Disclosure.Button>

                          <div>
                            {panelState.panels[0].panelValid && (
                              <Disclosure.Panel
                                static
                                className="pb-2 text-sm"
                              >
                                <PanelOne
                                  register={register}
                                  onSubmit={onSubmit}
                                  handleSubmit={handleSubmit}
                                  errors={errors}
                                  policyHolder={policyHolder}
                                  setPolicyHolder={setPolicyHolder}
                                  control={control}
                                />
                              </Disclosure.Panel>
                            )}
                          </div>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>

              {/* <!-- Step 2 --> */}
              <div className="flex flex-row md:ml-8 md:mr-0 lg:ml-20 xl:ml-28 lg:pr-16">
                <div className="w-8 md:flex md:flex-col md:justify-center md:mr-14">
                  <div className="flex justify-center items-center mt-3 mb-4">
                    <div
                      className={`inline-flex justify-center items-center h-8 w-8 text-white ${
                        panelState.panels[1].panelValid ||
                        panelState.panels[2].panelValid ||
                        panelState.panels[3].panelValid
                          ? "bg-ui-blue"
                          : "bg-ui-gray-medium-dark"
                      } rounded-full overflow-hidden`}
                    >
                      2
                    </div>
                  </div>
                  <div className="flex h-full w-px bg-ui-gray-medium-light mx-auto overflow-hidden"></div>
                </div>
                {/* <!-- Card 2 --> */}

                <div className="w-full flex-grow">
                  <div className="ui-card relative px-4 py-4 mb-6 lg:px-6">
                    <Disclosure as="div">
                      {({ open }) => (
                        <>
                          <Disclosure.Button as="div">
                            <div className="flex justify-between items-start">
                              <h2 className="flex flex-grow font-body font-bold text-xl">
                                Set User Name &amp; Password
                              </h2>
                              <div className="font-semibold text-sm whitespace-nowrap">
                                Step 2
                              </div>
                            </div>
                          </Disclosure.Button>
                          <div>
                            {panelState.panels[1].panelValid && (
                              <Disclosure.Panel
                                className="pb-2 text-sm"
                                static
                              >
                                <PanelTwo
                                  register={register}
                                  onSubmit={onSubmit}
                                  handleSubmit={handleSubmit}
                                  errors={errors}
                                  watch={watch}
                                  validatePanel={validatePanel}
                                />
                              </Disclosure.Panel>
                            )}
                          </div>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>

              {/* <!-- Step 3 --> */}
              <div className="flex flex-row md:ml-8 md:mr-0 lg:ml-20 xl:ml-28 lg:pr-16">
                <div className="w-8 md:flex md:flex-col md:justify-center md:mr-14">
                  <div className="flex justify-center items-center mt-3 mb-4">
                    <div
                      className={`inline-flex justify-center items-center h-8 w-8 text-white ${
                        panelState.panels[2].panelValid ||
                        panelState.panels[3].panelValid
                          ? "bg-ui-blue"
                          : "bg-ui-gray-medium-dark"
                      } rounded-full overflow-hidden`}
                    >
                      3
                    </div>
                  </div>
                  <div className="flex h-full w-px bg-ui-gray-medium-light mx-auto overflow-hidden"></div>
                </div>

                {/* Card 3 */}
                <div className="w-full flex-grow">
                  <div className="ui-card relative px-4 py-4 mb-6 lg:px-6">
                    <Disclosure as="div">
                      {({ open }) => (
                        <>
                          <Disclosure.Button as="div">
                            <div className="flex justify-between items-start">
                              <h2 className="flex flex-grow font-body font-bold text-xl">
                                Set Up Security Questions &amp; Notification
                                Preferences
                              </h2>
                              <div className="font-semibold text-sm whitespace-nowrap">
                                Step 3
                              </div>
                            </div>
                          </Disclosure.Button>
                          <div>
                            {panelState.panels[2].panelValid && (
                              <Disclosure.Panel
                                className="pb-2 text-sm"
                                static
                              >
                                <PanelThree
                                  register={register}
                                  onSubmit={onSubmit}
                                  handleSubmit={handleSubmit}
                                  errors={errors}
                                  validatePanel={validatePanel}
                                  paperlessSettings={paperlessSettings}
                                  setPaperlessSettings={setPaperlessSettings}
                                />
                                <div className="mt-4 text-right"></div>
                              </Disclosure.Panel>
                            )}
                          </div>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>
              {/* Step Four */}
              <div className="flex flex-row md:ml-8 md:mr-0 lg:ml-20 xl:ml-28 lg:pr-16">
                <div className="w-8 md:flex md:flex-col md:justify-center md:mr-14">
                  <div className="flex justify-center items-center mt-3 mb-4">
                    <div
                      className={`inline-flex justify-center items-center h-8 w-8 text-white ${
                        panelState.panels[3].panelValid
                          ? "bg-ui-blue"
                          : "bg-ui-gray-medium-dark"
                      } rounded-full overflow-hidden`}
                    >
                      4
                    </div>
                  </div>
                  <div className="flex h-full w-px bg-transparent mx-auto overflow-hidden"></div>
                </div>

                {/* Card 4 */}
                <div className="w-full flex-grow">
                  <div className="ui-card relative px-4 py-4 mb-6 lg:px-6">
                    <Disclosure as="div">
                      {({ open }) => (
                        <>
                          <Disclosure.Button as="div">
                            <div className="flex justify-between items-start">
                              <h2 className="flex flex-grow font-body font-bold text-xl">
                                Complete Sign-Up
                              </h2>
                              <div className="font-semibold text-sm whitespace-nowrap">
                                Step 4
                              </div>
                            </div>
                          </Disclosure.Button>
                          <div>
                            {panelState.panels[3].panelValid && (
                              <Disclosure.Panel
                                className="pb-2 text-sm"
                                static
                              >
                                <PanelFour validatePanel={validatePanel} />
                              </Disclosure.Panel>
                            )}
                          </div>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Register;
