import FormInputError from "../../General/FormInputError";
import { RadioGroup } from "@headlessui/react";
import { useState } from "react";

export default function AmountAndFrequency({
  register,
  setValue,
  errors,
  showNext,
}) {
  const [option, setOption] = useState("");

  const amountAndFrequencyData = [
    {
      title: "Monthly",
      type: "statement",
      price: "XX.XX",
      subText: "",
    },
    {
      title: "Semi-annual premium",
      type: "semi-annual",
      price: "XX.XX",
      subText: "(Save $X.XX)",
    },
    {
      title: "Annual premium",
      type: "annual",
      price: "XX.XX",
      subText: "(Save $X.XX)",
    },
  ];

  return (
    <>
      <div className="px-0 md:px-8">
        <RadioGroup
          className="ui-form-card-row2 font-semibold grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
          value={option}
          onChange={(v) => {
            setOption(v);
            setValue("amountAndfreq", v);
          }}
          aria-label="Select payment amount &amp; frequency"
        >
          {/* statement */}
          {amountAndFrequencyData.map((v) => {
            return (
              <RadioGroup.Option
                key={v.type}
                className={({ checked }) =>
                  `ui-form-card cursor-pointer font-semibold ${
                    checked ? "selected" : ""
                  }`
                }
                value={v.type}
                onClick={showNext}
              >
                <RadioGroup.Label as="label">
                  <span className="block text-xxs mr-5 mb-1">{v.title}</span>
                  <span className="block text-theme-text-secondary text-sm">
                    ${v.price}
                  </span>
                  <span className="block text-xxs text-theme-text-tertiary font-normal">
                    {v.subText}
                  </span>
                </RadioGroup.Label>
                <div className="ui-form-card-checkbox">
                  <svg
                    className="ui-icon w-2 h-2 text-white hidden"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-checkmark"></use>
                  </svg>
                </div>
              </RadioGroup.Option>
            );
          })}
        </RadioGroup>
      </div>

      {errors.amountAndfreq?.message && (
        <FormInputError errorMessage={errors.amountAndfreq.message} />
      )}
    </>
  );
}
