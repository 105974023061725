import { useForm } from "react-hook-form";
import { v4 as uuidv4 } from "uuid";
import FormInputError from "../../../General/FormInputError";

export default function FACStepFive({ formStep, setFormStep, setFACFormData }) {
  const uuid = uuidv4();

  const {
    register: FACStepFiveRegister,
    handleSubmit: FACStepFiveHandleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setFormStep(6);
    setFACFormData((currentFormData) => {
      return {
        ...currentFormData,
        ...data,
      };
    });
  };

  return (
    <>
      {formStep >= 5 && (
      <>
        <div>
          <hr />
          <p className="text-sm">
            This is a free form field for you to add anything that would help us
            process the claim.
          </p>

          <form onSubmit={FACStepFiveHandleSubmit(onSubmit)}>
            <div className="grid gap-4 grid-cols-1 lg:gap-6">
              <div className="ui-form-card-no-event mt-4">
                <label htmlFor={`${uuid}-note`} className="block mb-1">
                  Add note
                </label>
                <textarea
                  id={`${uuid}-note`}
                  type="text"
                  rows="4"                  
                  {...FACStepFiveRegister(`note`)}
                />
                <FormInputError
                  id={`${uuid}-note-error`}
                  errorMessage={errors?.note?.message}
                />
              </div>

              <div className="flex justify-end">
                <button
                  className="ui-btn-secondary ui-btn-min-w mt-4"
                  type="button"
                  onClick={() => setFormStep((current) => (current -= 1))}
                >
                  Previous
                </button>

                <button className="ui-btn-primary ui-btn-min-w mt-4">
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </>
      )}
    </>
  );
}
