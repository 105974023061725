export const userData = {
  userAvatar: "/assets/img/agent-default.png",
  firstName: "Robert",
  lastName: "Lisk",
  pronoun: "( he / him / his )",
  goal: "Space for copy for Rob to customize a brief bio about himself. This would be displayed for managers and/or other users who can see agent bios.",
  additionalText:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse sodales nunc sagittis, euismod tortor vel, pellentesque sem. Ut vulputate lacinia nunc. Donec viverra sem sed ipsum semper pellentesque.",
  agencyName: "Lincoln Park Agency",
  suiteNumber: "Suite 260",
  streetAddress: "555 Maple Shade Dr.",
  city: "Anytown",
  state: "NY",
  zipcode: "55555",
  mobilePhone: "XXX-XXX-XXXX",
  businessPhone: "XXX-XXX-XXXX",
  faxNumber: "XXX-XXX-XXXX",
  specialty: "Whole Life and annuities",
  yearsInService: "5 years",
  manager: "Sarah Toussant",
  status: "risk",
  statusText: "Active/Non-Compliant",
};
