export const carouselData = [
  {
    uiTextColor: "text-ui-yellow",
    iconBorder: "ui-icon-border-risk",
    title: "Attention: Commission statements available -- 0",
    detail: `Your monthly and year to date commission statement are now available to download. Additional copy for this example of a temporaey/times/curated hompage message goes here. Lorem ipsum dolor sit amet.`,
    buttons: [
      {
        buttonText: "Download statements",
        buttonClass: "ui-btn-primary",
        url: "#",
      },
    ],
  },
  {
    uiTextColor: "text-ui-yellow",
    iconBorder: "ui-icon-border-risk",
    title: "Attention: Commission statements available -- 1",
    detail: `Your monthly and year to date commission statement are now available to download. Additional copy for this example of a temporaey/times/curated hompage message goes here. Lorem ipsum dolor sit amet.`,
    buttons: [
      {
        buttonText: "Download statements",
        buttonClass: "ui-btn-primary",
        url: "#",
      },
    ],
  },
  {
    uiTextColor: "text-ui-yellow",
    iconBorder: "ui-icon-border-risk",
    title: "Attention: Commission statements available -- 2",
    detail: `Your monthly and year to date commission statement are now available to download. Additional copy for this example of a temporaey/times/curated hompage message goes here. Lorem ipsum dolor sit amet.`,
    buttons: [
      {
        buttonText: "Download statements",
        buttonClass: "ui-btn-primary",
        url: "#",
      },
    ],
  },
];