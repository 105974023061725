import { Link } from "react-router-dom";
export default function AppNavigationCard({ icon, title, desc, url }) {
  return (
    <div className="ui-card relative">
      <div className="flex p-3 items-start">
        <div className="p-1">
          <svg
            className="ui-icon h-4 w-4 mt-1"
            focusable="false"
            aria-hidden="true"
            role="presentation"
          >
            <use href={icon}></use>
          </svg>
        </div>
        <div className="p-1 pr-12">
          <div className="text-sm font-semibold">{title}</div>
          <div className="text-xxs">{desc}</div>
        </div>
      </div>
      <Link
        to={url}
        target="_blank"
        className="ui-btn-card-arrow"
        aria-label={`go to ${title.toLowerCase()}`}
      >
        <svg
          className="ui-icon w-8 h-8"
          focusable="false"
          aria-hidden="true"
          role="presentation"
        >
          <use href="#ui-icon-next"></use>
        </svg>
      </Link>
    </div>
  );
}
