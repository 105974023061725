import { useState } from "react";
import { usePagination } from "../../../hooks/usePagination";
import PolicyCard from "../../PolicyCard";
import Pagination from "../../General/Pagination";

const AllPolicyView = ({ policies, user }) => {
  const [policiesDisplay, setPoliciesDisplay] = useState("card");

  const { current, paginationProps } = usePagination(policies, 6);

  return (
    <>
      <div className="ui-policy-grid ui-agent-grid">
        <div className="flex flex-row items-center justify-between p-4 md:p-0">
          <div className="flex flex-grow justify-between items-start">
            <h3 className="font-brand text-lg md:text-2xl">
              All Policies linked to {user}
            </h3>

            <div className="hidden md:inline-flex md:items-center md:justify-end">
              <div className="text-sm font-light mr-2">View</div>
              <button
                className={`${
                  policiesDisplay === "card"
                    ? "ui-btn-layout-current"
                    : "ui-btn-layout"
                } rounded-l`}
                aria-label="View policies as cards"
                onClick={() => setPoliciesDisplay("card")}
              >
                <svg
                  className="ui-icon w-4 h-4"
                  focusable="false"
                  aria-hidden="true"
                  role="presentation"
                >
                  <use href="#ui-icon-layout-cards"></use>
                </svg>
              </button>
              <button
                className={`${
                  policiesDisplay === "table"
                    ? "ui-btn-layout-current"
                    : "ui-btn-layout"
                } rounded-r`}
                aria-label="View policies in table"
                onClick={() => setPoliciesDisplay("table")}
              >
                <svg
                  className="ui-icon w-4 h-4"
                  focusable="false"
                  aria-hidden="true"
                  role="presentation"
                >
                  <use href="#ui-icon-layout-table"></use>
                </svg>
              </button>
            </div>
          </div>
        </div>

        <hr className="ui-under-title hidden md:block" />
        <div className="ui-agent-grid ">
          <div
            className={`${
              policiesDisplay === "card" ? "ui-grid-cards" : "ui-grid-table"
            }`}
          >
            {current.map((policy, index) => {
              return <PolicyCard data={policy} key={`policy - ${index}`} />;
            })}
          </div>
        </div>
      </div>
      <div className="my-8">
        <Pagination {...paginationProps} />
      </div>
    </>
  );
};

export default AllPolicyView;
