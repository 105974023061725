import { Dialog } from "@headlessui/react";
import DialogBackdrop from "../../ModalDialogs/DialogBackdrop";
import PolicyLookup from "../General/PolicyLookup";

const PolicyLookupModal = ({ isOpenPolicyLookup, setIsOpenPolicyLookup }) => {
  // const navigate = useNavigate();
  // const {
  //   register,
  //   handleSubmit,
  //   formState: { errors },
  // } = useForm();

  // const searchPolicy = (data) => {
  //   navigate(`/policyLookup?keyword=${data.keyword}`);
  // };

  return (
    <>
      <Dialog
        open={isOpenPolicyLookup}
        onClose={() => setIsOpenPolicyLookup(false)}
        className="ui-dialogs"
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <DialogBackdrop />
        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel
            role="dialog"
            aria-modal="true"
            className="ui-default-dialog"
          >
            <Dialog.Title className="font-body text-xl font-bold text-left">
              Policy Look-Up
            </Dialog.Title>
            <button
              className="ui-default-dialog-close"
              aria-label="close Policy Look-Up window"
              onClick={() => setIsOpenPolicyLookup(false)}
            >
              <svg
                className="ui-icon h-3.5 w-3.5"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href="#ui-icon-close"></use>
              </svg>
            </button>
            <hr />
            <Dialog.Description>
              <span className="sr-only">Use input to find policy</span>
            </Dialog.Description>


<PolicyLookup />

{/*             
            <form
              className="flex flex-col sm:flex-row"
              onSubmit={handleSubmit(searchPolicy)}
            >
              <div className="flex flex-col flex-grow justify-start sm:mr-4">
                <div className="relative">
                  <input
                    id="id_policyLookupInput"
                    className="border border-ui-gray-medium h-9 w-full p-2 pl-8 rounded"
                    aria-label="Site Search"
                    placeholder="Enter policy number or policy holder name"
                    {...register("keyword", {
                      required:
                        "Please enter policy number or policy holder name before continuing",
                    })}
                  />
                  <svg
                    className="ui-icon w-4 h-4 absolute text-theme-action left-0 top-1/2 mt-[-.5rem] ml-2"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-search"></use>
                  </svg>
                </div>
                <FormInputError
                  id="id_policyLookupError"
                  errorMessage={errors?.keyword?.message}
                />
              </div>
              <div>
                <button
                  id="id_policyLookupBtn"
                  className="ui-btn-primary h-9 w-32 mx-auto ui-btn-min-w mt-4 sm:mt-0"
                >
                  Search
                </button>
              </div>
            </form> */}

            <div className="block text-right mt-6">
              <button
                className="ui-btn-secondary ui-btn-min-w mt-4"
                onClick={() => setIsOpenPolicyLookup(false)}
              >
                Close
                <span className="sr-only"> Policy Look-Up </span>
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
};

export default PolicyLookupModal;
