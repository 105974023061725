import { usePagination } from "../../../hooks/usePagination";

import ClaimCard from "./PolicyCards/ClaimCard";
import ServiceCard from "./PolicyCards/ServiceCard";
import AppNavigationCard from "../../General/AppNavigationCard";
import Pagination from "../../General/Pagination";

const ServiceAndClaimsTab = ({ claims, services }) => {
  let { current: claimRows, paginationProps: claimsPaginationProps } =
    usePagination(claims, 3);
  let { current: servicesRows, paginationProps: servicesPaginationProps } =
    usePagination(services, 3);
  return (
    <>
      <div className="ui-component-spacing">
        <h3 className="font-brand text-lg md:text-2xl">Claims</h3>
        <hr className="ui-under-title" />
        {claimRows.map((policy, index) => {
          return (
            <ClaimCard
              data={policy}
              cardExpanded={index === 0 ? true : false}
              key={`claims - ${index}`}
            />
          );
        })}
        <Pagination {...claimsPaginationProps} />
      </div>

      <div className="ui-component-spacing">
        <h3 className="font-brand text-lg md:text-2xl">Service Requests</h3>
        <hr className="ui-under-title" />
        {servicesRows.map((policy, index) => {
          return (
            <ServiceCard
              data={policy}
              cardExpanded={index === 0 ? true : false}
              key={`policy - ${index}`}
            />
          );
        })}
        <Pagination {...servicesPaginationProps} />
      </div>

      <div className="ui-component-spacing">
        <h3 className="font-brand text-lg md:text-2xl">
          How to service this policy
        </h3>
        <hr className="ui-under-title" />
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 auto-rows-fr">
          <AppNavigationCard
            icon="#ui-icon-payment"
            title="Change insured name"
            desc="Here goes a brief description"
            url="/policies/beneficiaries/5723452"
          />
          <AppNavigationCard
            icon="#ui-icon-payment"
            title="Change insured name"
            desc="Here goes a brief description"
            url="/payment/manage-payments"
          />
          <AppNavigationCard
            icon="#ui-icon-payment"
            title="Change insured name"
            desc="Here goes a brief description"
            url="/"
          />
          <AppNavigationCard
            icon="#ui-icon-payment"
            title="Change insured name"
            desc="Here goes a brief description"
            url="/"
          />
        </div>
      </div>
    </>
  );
};

export default ServiceAndClaimsTab;
