import { html, Grid, h } from "gridjs";
import { useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { messages } from "../../../js/placeholderData/policyDetail";

export default function RecentPolicyMessageGrid({ data }) {
  const messagesGrid = useRef(null);
  const navigate = useNavigate();
  const grid = new Grid({
    columns: [
      {
        name: "Alert",
        data: (row) => row.title,
      },
      {
        name: "Date",
        formatter: (cell) => {
          return html(`<span class="text-xxs font-bold">${cell}</span>`);
        },
      },
      {
        name: "Action",
        formatter: (cell, row) => {
          return h(
            "button",
            {
              className: "ui-btn-arrow",
              onClick: () => navigate(`/messages`),
            },
            html(`
              <svg
                class="ui-icon w-8 h-8"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href="#ui-icon-next"></use>
              </svg>`)
          );
        },
      },
    ],
    data: messages,
    className: {
      table: "ui-grid-table",
    },
  });
  useEffect(() => {
    grid.render(messagesGrid.current);
  }, []);
  return <div ref={messagesGrid} />;
}
