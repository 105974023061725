export const commissionStatements = [
  {
    date: 'February 2020',
    title: 'Monthly commission statement',
    srTitle: 'of Monthly commission statement'
  },
  {
    date: 'February 2020',
    title: 'YTD Commission statement',
    srTitle: 'of YTD Commission statement'
  },
  {
    date: 'February 2020',
    title: 'Monthly commission statement',
    srTitle: 'of Monthly commission statement'
  },
  {
    date: 'February 2020',
    title: 'YTD Commission statement',
    srTitle: 'of YTD Commission statement'
  },
  {
    date: 'February 2020',
    title: 'Monthly commission statement',
    srTitle: 'of Monthly commission statement'
  },
]

export const reportStatements = [
  {
    date: '2/22/2022',
    title: 'Book of business monthly report',
    srTitle: 'of Monthly commission statement'
  },
]