import { useState } from "react";
import moment from "moment";
import { RadioGroup } from "@headlessui/react";
import { v4 as uuidv4 } from "uuid";
import FormInputError from "../../General/FormInputError";

export default function PaymentDate({
  register,
  setValue,
  errors,
  setCurrentStep,
}) {
  const customDateId = uuidv4();
  const [option, setOption] = useState("");

  return (
    <>
      <RadioGroup
        className="ui-form-card-row cursor-pointer font-semibold"
        aria-label="Select a date option"
        value={option}
        onChange={setOption}
      >
        <RadioGroup.Option
          className={({ checked }) =>
            `ui-form-card cursor-pointer font-semibold w-auto  ${
              checked ? "selected" : ""
            }`
          }
          value="today"
          onClick={() => {
            setValue("date", moment().format("MM/DD/YYYY"));
            setCurrentStep(5);
          }}
        >
          <RadioGroup.Label as="label">
            <span className="block text-xxs mb-1 pr-8">Today</span>
            <span className="block text-theme-text-primary">
              {moment().format("MM/DD/YYYY")}
            </span>
          </RadioGroup.Label>
          <div className="ui-form-card-checkbox">
            <svg
              className="ui-icon w-2 h-2 text-white hidden"
              focusable="false"
              aria-hidden="true"
              role="presentation"
            >
              <use href="#ui-icon-checkmark"></use>
            </svg>
          </div>
        </RadioGroup.Option>

        <RadioGroup.Option
          className={({ checked }) =>
            `ui-form-card ${checked ? "selected" : ""}`
          }
          tabIndex="0"
          aria-label="Select to enter a date other than today"
          value="custom"
          onClick={() => {
            setCurrentStep(4);
          }}
        >
          {({ checked }) => (
            <>
              <div className="text-xxs mb-1 pr-8 font-semibold">
                Custom Date
              </div>
              <input
                id={customDateId}
                type="date"
                className="w-full"
                disabled={!checked}
                placeholder="MM/DD/YYYY"
                aria-placeholder="MM/DD/YYYY"
                aria-describedby={`${customDateId + "-help"} ${
                  customDateId + "-error"
                }`}
                min={new Date().toISOString().split("T")[0]}
                onInput={(e) => {
                  setValue("date", e.target.value);
                  setCurrentStep(5);
                }}
                {...register("date", {
                  setValueAs: (v) => moment(v).format("MM/DD/YYYY"),
                })}
              />
              <div
                id={customDateId + "-help"}
                className="text-xxs text-theme-text-tertiary mt-1"
              >
                Use format MM/DD/YYYY
              </div>
              <div className="text-xxs font-semibold pr-8 group-hover:underline">
                <div className="ui-form-card-checkbox">
                  <svg
                    className="ui-icon w-2 h-2 text-white hidden"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-checkmark"></use>
                  </svg>
                </div>
              </div>
              <div id={customDateId + "-error"}>
                {errors.date?.message && (
                  <FormInputError errorMessage={errors.date.message} />
                )}
              </div>
            </>
          )}
        </RadioGroup.Option>
      </RadioGroup>
    </>
  );
}
