import SearchForm from "../components/General/SearchForm";
import ArticleCard from "../components/General/ArticleCard";

export default function HelpExplore() {
  return (
    <>
      {/* SPRINT-5-CUSTOMER-help-section-screens.pdf (pgs 12-13) */}
      <div className="container mb-8">
        <h1>Explore the help section articles</h1>
        <p>
          Search or browse our help articles, videos, how-to's and FAQs
        </p>
        <hr />
      </div>

      {/* Site search */}
      <div className="container ui-component-spacing">
        <h2>Do a quick search</h2>
        <hr className="my-4" />
        <div className="mb-6">
          <div className="w-full md:w-3/5 xl:w-1/2">
            <SearchForm display={false} />
          </div>
        </div>
      </div>

      {/* Browse our articles, video and how-to’s */}
      <div className="container ui-component-spacing">
        <h2>Browse our articles, video and how-to's</h2>
        <hr className="my-4" />
        <div className="grid gap-4 grid-cols-1 auto-rows-min content-stretch md:grid-cols-2 lg:gap-6 lg:grid-cols-3 lg:grid-row-auto">
          <div>
            <ArticleCard
              title="Payments & Billing"
              instructions="Copy about payments category of the help section and what can be
                found here."
              links={[
                {
                  icon: "ui-icon-document",
                  label: "Make a payment",
                  url: "/help/article/1",
                },
                {
                  icon: "ui-icon-app",
                  label: "Edit or cancel a Payment",
                  url: "/help/article/1",
                },
                {
                  icon: "ui-icon-document",
                  label: "Check the status of a payment",
                  url: "/help/article/1",
                },
                {
                  icon: "ui-icon-document",
                  label: "Change payment methods",
                  url: "/help/article/1",
                },
                {
                  icon: "ui-icon-document",
                  label: "Manage paperless billing settings",
                  url: "/help/article/1",
                },
                {
                  icon: "ui-icon-document",
                  label: "Manage autopay settings",
                  url: "/help/article/1",
                },
                {
                  icon: "ui-icon-document",
                  label:
                    "Another article title that may wrap Loren ipsum dolor sit amen",
                  url: "/help/article/1",
                },
                {
                  icon: "ui-icon-document",
                  label: "Manage paperless billing settings",
                  url: "/help/article/1",
                },
              ]}
            />
          </div>
          <div>
            <ArticleCard
              title="Claims"
              instructions="Copy about claims category of the help section and what can be found here."
              links={[
                {
                  icon: "ui-icon-document",
                  label: "File a Claim",
                  url: "/help/article/1",
                },
                {
                  icon: "ui-icon-document",
                  label: "File ro track a claim",
                  url: "/help/article/1",
                },
                {
                  icon: "ui-icon-document",
                  label: "Find out when I will receive my payment",
                  url: "/help/article/1",
                },
              ]}
            />
          </div>
          <div>
            <ArticleCard
              title="Another Help Category"
              instructions="Copy about this category of the help section and what can be found here."
              links={[
                {
                  icon: "ui-icon-document",
                  label: "Another article title",
                  url: "/help/article/1",
                },
                {
                  icon: "ui-icon-document",
                  label: "Another article title",
                  url: "/help/article/1",
                },
                {
                  icon: "ui-icon-document",
                  label: "Another article title",
                  url: "/help/article/1",
                },
              ]}
            />
          </div>
          <div>
            <ArticleCard
              title="Another Help Category"
              instructions="Copy about this category of the help section and what can be found here."
              links={[
                {
                  icon: "ui-icon-document",
                  label: "Another article title",
                  url: "/help/article/1",
                },
                {
                  icon: "ui-icon-document",
                  label: "Another article title",
                  url: "/help/article/1",
                },
                {
                  icon: "ui-icon-document",
                  label: "Another article title",
                  url: "/help/article/1",
                },
              ]}
            />
          </div>
          <div>
            <ArticleCard
              title="Another high level help articles category"
              instructions="Copy about this category of the help section and what can be found here."
              links={[
                {
                  icon: "ui-icon-document",
                  label: "Another article title",
                  url: "/help/article/1",
                },
                {
                  icon: "ui-icon-document",
                  label: "Yet another article title",
                  url: "/help/article/1",
                },
                {
                  icon: "ui-icon-document",
                  label: "Yet another article title",
                  url: "/help/article/1",
                },
                {
                  icon: "ui-icon-document",
                  label: "Yet another article title",
                  url: "/help/article/1",
                },
                {
                  icon: "ui-icon-document",
                  label:
                    "Another featured article title that is longer and may eed to wrap lorem ipsum",
                  url: "/help/article/1",
                },
                {
                  icon: "ui-icon-document",
                  label: "And one last featured article title",
                  url: "/help/article/1",
                },
              ]}
            />
          </div>
          <div>
            <ArticleCard
              title="Another high level help articles category"
              instructions="Copy about this category of the help section and what can be found here."
              links={[
                {
                  icon: "ui-icon-document",
                  label: "Another article title",
                  url: "/help/article/1",
                },
                {
                  icon: "ui-icon-document",
                  label: "Yet another article title",
                  url: "/help/article/1",
                },
                {
                  icon: "ui-icon-document",
                  label: "Yet another article title",
                  url: "/help/article/1",
                },
                {
                  icon: "ui-icon-document",
                  label: "Yet another article title",
                  url: "/help/article/1",
                },
                {
                  icon: "ui-icon-document",
                  label:
                    "Another featured article title that is longer and may eed to wrap lorem ipsum",
                  url: "/help/article/1",
                },
                {
                  icon: "ui-icon-document",
                  label: "And one last featured article title",
                  url: "/help/article/1",
                },
              ]}
            />
          </div>
          <div>
            <ArticleCard
              title="Another Help Category"
              instructions="Copy about this category of the help section and what can be found here."
              links={[
                {
                  icon: "ui-icon-document",
                  label: "Another article title",
                  url: "/help/article/1",
                },
                {
                  icon: "ui-icon-document",
                  label: "Another article title",
                  url: "/help/article/1",
                },
                {
                  icon: "ui-icon-document",
                  label: "Another article title",
                  url: "/help/article/1",
                },
              ]}
            />
          </div>
          <div>
            <ArticleCard
              title="Another Help Category"
              instructions="Copy about this category of the help section and what can be found here."
              links={[
                {
                  icon: "ui-icon-document",
                  label: "Another article title",
                  url: "/help/article/1",
                },
                {
                  icon: "ui-icon-document",
                  label: "Another article title",
                  url: "/help/article/1",
                },
                {
                  icon: "ui-icon-document",
                  label: "Another article title",
                  url: "/help/article/1",
                },
              ]}
            />
          </div>
          <div>
            <ArticleCard
              title="Another Help Category"
              instructions="Copy about this category of the help section and what can be found here."
              links={[
                {
                  icon: "ui-icon-document",
                  label: "Another article title",
                  url: "/help/article/1",
                },
                {
                  icon: "ui-icon-document",
                  label: "Another article title",
                  url: "/help/article/1",
                },
                {
                  icon: "ui-icon-document",
                  label: "Another article title",
                  url: "/help/article/1",
                },
              ]}
            />
          </div>
        </div>
      </div>
    </>
  );
}
