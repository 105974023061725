import { v4 as uuidv4 } from "uuid";
import { useForm } from "react-hook-form";
import FormInputError from "../../../General/FormInputError";

export default function FACStepOne({ formStep, setFormStep, setFACFormData }) {
  const uuid = uuidv4();
  const {
    register: FACStepOneRegister,
    handleSubmit: FACStepOneHandleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setFormStep(2);
    setFACFormData((currentFormData) => {
      return {
        ...currentFormData,
        ...data,
      };
    });
  };

  return (
    <>
      {formStep === 1 && (
        <form onSubmit={FACStepOneHandleSubmit(onSubmit)}>
          <div>
            <hr />
            <div>
              <label htmlFor={`${uuid}-pn`} className="block mb-1 text-base">
                Policy number *
              </label>
              <p id={`${uuid}-pn-note`} className="text-xs mb-1">
                Your policy number can be found on the original policy/contract
                or on an annual policy statement
              </p>
              <input
                id={`${uuid}-pn`}
                type="text"
                placeholder="Please enter policy number(required)"
                aria-describedby={`${uuid}-pn-note`}
                {...FACStepOneRegister("policyNumber", {
                  required: "Please enter a policy number",
                })}
              />
              <FormInputError
                id={`${uuid}-pn-error`}
                errorMessage={errors.policyNumber?.message}
              />
            </div>
            <hr />

            <div className="grid gap-4 grid-cols-1 lg:gap-6">
              <div className="grid gap-4 grid-cols-1 md:gap-y-0 md:gap-x-4 md:grid-cols-[2fr,2fr,1fr]">
                <div>
                  <label htmlFor={`${uuid}-fn`} className="block mb-1">
                    First Name *
                  </label>
                  <input
                    id={`${uuid}-fn`}
                    type="text"
                    {...FACStepOneRegister("firstName", {
                      required: "Please enter a first name",
                    })}
                  />
                  <FormInputError
                    id={`${uuid}-fn-error`}
                    errorMessage={errors.firstName?.message}
                  />
                </div>

                <div>
                  <label htmlFor={`${uuid}-ln`} className="block mb-1">
                    Last Name *
                  </label>
                  <input
                    id={`${uuid}-ln`}
                    type="text"
                    {...FACStepOneRegister("lastName", {
                      required: "Please enter a last name",
                    })}
                  />
                  <FormInputError
                    id={`${uuid}-ln-error`}
                    errorMessage={errors.lastName?.message}
                  />
                </div>

                <div>
                  <label htmlFor={`${uuid}-mn`} className="block mb-1">
                    Middle Initial
                  </label>
                  <input
                    id={`${uuid}-mn`}
                    type="text"
                    {...FACStepOneRegister("middleName")}
                  />
                  <FormInputError
                    id={`${uuid}-mn-error`}
                    errorMessage={errors.middleName?.message}
                  />
                </div>
              </div>

              <div>
                <label htmlFor={`${uuid}-sa`} className="block mb-1">
                  Street Address *
                </label>
                <input
                  id={`${uuid}-sa`}
                  type="text"
                  {...FACStepOneRegister("streetAddress")}
                />
                <FormInputError
                  id={`${uuid}-sa-error`}
                  errorMessage={errors.streetAddress?.message}
                />
              </div>

              <div>
                <label htmlFor={`${uuid}-sa2`} className="block mb-1">
                  Street Address line 2
                </label>
                <input
                  id={`${uuid}-sa2`}
                  type="text"
                  {...FACStepOneRegister("streetAddress2")}
                />
                <FormInputError
                  id={`${uuid}-sa2-error`}
                  errorMessage={errors.streetAddress2?.message}
                />
              </div>
              <div className="grid gap-4 grid-cols-1 md:gap-y-0 md:gap-x-4 md:grid-cols-[1.5fr,1fr,1fr]">
                <div>
                  <label htmlFor={`${uuid}-city`} className="block mb-1">
                    City
                  </label>
                  <input
                    id={`${uuid}-city`}
                    type="text"
                    {...FACStepOneRegister("city")}
                  />
                  <FormInputError
                    id={`${uuid}-city-error`}
                    errorMessage={errors.city?.message}
                  />
                </div>

                <div>
                  <label htmlFor={`${uuid}-state`} className="block mb-1">
                    State
                  </label>
                  <input
                    id={`${uuid}-state`}
                    type="text"
                    {...FACStepOneRegister("state")}
                  />
                  <FormInputError
                    id={`${uuid}-state-error`}
                    errorMessage={errors.state?.message}
                  />
                </div>

                <div>
                  <label htmlFor={`${uuid}-zip`} className="block mb-1">
                    Zip
                  </label>
                  <input
                    id={`${uuid}-zip`}
                    type="text"
                    {...FACStepOneRegister("zip")}
                  />
                  <FormInputError
                    id={`${uuid}-zip-error`}
                    errorMessage={errors.zip?.message}
                  />
                </div>
              </div>
              <div className="grid gap-4 grid-cols-1 md:gap-y-0 md:gap-x-4 md:grid-cols-[1.5fr,1fr,1fr]">
                <div>
                  <label htmlFor={`${uuid}-ssn`} className="block mb-1">
                    Social Security # *
                  </label>
                  <input
                    id={`${uuid}-ssn`}
                    type="text"
                    aria-describedby={`${uuid}-ssn-note`}
                    {...FACStepOneRegister("ssn", {
                      required: "Please enter deceased social security number",
                    })}
                  />
                  <div
                    id={`${uuid}-ssn-note`}
                    className="text-xxs text-theme-text-tertiary mt-1"
                  >
                    Use format: XXX-XX-XXXX
                  </div>
                  <FormInputError
                    id={`${uuid}-ssn-error`}
                    errorMessage={errors.ssn?.message}
                  />
                </div>

                <div>
                  <label htmlFor={`${uuid}-dob`} className="block mb-1">
                    Date of birth
                  </label>
                  <input
                    id={`${uuid}-dob`}
                    type="date"
                    aria-describedby={`${uuid}-dob-note`}
                    {...FACStepOneRegister("dob")}
                  />
                  <div
                    id={`${uuid}-dob-note`}
                    className="text-xxs text-theme-text-tertiary mt-1"
                  >
                    Use format: MM/DD/YYYY
                  </div>
                  <FormInputError
                    id={`${uuid}-dob-error`}
                    errorMessage={errors.dob?.message}
                  />
                </div>
                <div>
                  <label htmlFor={`${uuid}-dod`} className="block mb-1">
                    Date of death
                  </label>
                  <input
                    id={`${uuid}-dod`}
                    type="date"
                    aria-describedby={`${uuid}-dod-note`}
                    {...FACStepOneRegister("dod")}
                  />
                  <div
                    id={`${uuid}-dod-note`}
                    className="text-xxs text-theme-text-tertiary mt-1"
                  >
                    Use format: MM/DD/YYYY
                  </div>
                  <FormInputError
                    id={`${uuid}-dod-error`}
                    errorMessage={errors.dod?.message}
                  />
                </div>
              </div>

              <div>
                <fieldset>
                  <legend>Marital Status</legend>
                  <div className="inline-block mt-2 mr-6">
                    <label className="inline-flex items-center text-sm font-normal">
                      <input
                        id={`${uuid}-marital1`}
                        type="radio"
                        name="marital"
                        className="mr-1"
                        value="married"
                        {...FACStepOneRegister("marital", {
                          required: "Please select a marital status",
                        })}
                      />{" "}
                      Married
                    </label>
                  </div>
                  <div className="inline-block mt-2 mr-6">
                    <label className="inline-flex items-center text-sm font-normal">
                      <input
                        id={`${uuid}-marital2`}
                        type="radio"
                        name="marital"
                        className="mr-1"
                        value="single"
                        {...FACStepOneRegister("marital", {
                          required: "Please select a marital status",
                        })}
                      />{" "}
                      Single
                    </label>
                  </div>

                  <div className="inline-block mt-2 mr-6">
                    <label className="inline-flex items-center text-sm font-normal">
                      <input
                        id={`${uuid}-marital3`}
                        type="radio"
                        name="marital"
                        className="mr-1"
                        value="divorced"
                        {...FACStepOneRegister("marital", {
                          required: "Please select a marital status",
                        })}
                      />
                      Divorced
                    </label>
                  </div>
                  <div className="inline-block mt-2 mr-6">
                    <label className="inline-flex items-center text-sm font-normal">
                      <input
                        id={`${uuid}-marital4`}
                        type="radio"
                        name="marital"
                        className="mr-1"
                        value="widowed"
                        {...FACStepOneRegister("marital", {
                          required: "Please select a marital status",
                        })}
                      />
                      Widowed
                    </label>
                  </div>
                  <FormInputError
                    id={`${uuid}-marital-error`}
                    errorMessage={errors.marital?.message}
                  />
                </fieldset>
              </div>

              <div>
                <fieldset>
                  <legend>Manner of death</legend>
                  <div className="inline-block mt-2 mr-6">
                    <label className="inline-flex items-center text-sm font-normal">
                      <input
                        id={`${uuid}-manner1`}
                        type="radio"
                        name="manner"
                        className="mr-1"
                        value="natural"
                        {...FACStepOneRegister("manner", {
                          required: "Please select a manner of death",
                        })}
                      />
                      Natural
                    </label>
                  </div>
                  <div className="inline-block mt-2 mr-6">
                    <label className="inline-flex items-center text-sm font-normal">
                      <input
                        id={`${uuid}-manner2`}
                        type="radio"
                        name="manner"
                        className="mr-1"
                        value="accidental"
                        {...FACStepOneRegister("manner", {
                          required: "Please select a manner of death",
                        })}
                      />
                      Accidental
                    </label>
                  </div>
                  <div className="inline-block mt-2 mr-6">
                    <label className="inline-flex items-center text-sm font-normal">
                      <input
                        id={`${uuid}-manner3`}
                        type="radio"
                        name="manner"
                        className="mr-1"
                        value="other"
                        {...FACStepOneRegister("manner", {
                          required: "Please select a manner of death",
                        })}
                      />
                      Other
                    </label>
                  </div>
                  <FormInputError
                    id={`${uuid}-manner-error`}
                    errorMessage={errors.manner?.message}
                  />
                </fieldset>
              </div>

              <div>
                <fieldset>
                  <legend>Did the death occur in a foreign country</legend>

                  <div className="inline-block mt-2 mr-6">
                    <label className="inline-flex items-center text-sm font-normal">
                      <input
                        id={`${uuid}-foreign1`}
                        type="radio"
                        name="foreign"
                        className="mr-1"
                        value="yes"
                        {...FACStepOneRegister("foreign", {
                          required:
                            "Please indicate if death occur in a foreign country",
                        })}
                      />
                      Yes
                    </label>
                  </div>
                  <div className="inline-block mt-2 mr-6">
                    <label className="inline-flex items-center text-sm font-normal">
                      <input
                        id={`${uuid}-forign2`}
                        type="radio"
                        name="foreign"
                        className="mr-1"
                        value="no"
                        {...FACStepOneRegister("foreign", {
                          required:
                            "Please indicate if death occur in a foreign country",
                        })}
                      />
                      No
                    </label>
                  </div>
                  <FormInputError
                    id={`${uuid}-foreign-error`}
                    errorMessage={errors.foreign?.message}
                  />
                </fieldset>
              </div>
              <div className="mt-4 flex justify-end">
                <button className="ui-btn-primary text-center">
                  Next: About the individual filing the claim
                </button>
              </div>
            </div>
          </div>
        </form>
      )}
    </>
  );
}
