import { useAgentBreadcrumb } from "../../../hooks/useAgentBreadcrumb";
import { Link } from "react-router-dom";
export default function AgentBreadcrumb() {
  const breadcrumb = useAgentBreadcrumb();
  return (
    <div className="container pt-6 pb-4 min-h-12">
      <nav aria-label="Breadcrumb" className="ui-breadcrumb text-xs">
        {breadcrumb.length > 1 && (
          <ol className="list-none">
            {breadcrumb.map((b, key) => {
              return (
                <li key={`breadcrumb-${key}`}>
                  <Link to={b.url} aria-current={b.url === "#" ? "page" : ""}>
                    {b.name}
                  </Link>
                </li>
              );
            })}
          </ol>
        )}
      </nav>
    </div>
  );
}
