// route: /billing
export const scheduledPayments = [
  {
    id: "1",
    policyNumber: "93485397",
    policyName: "Policy name goes here - 1",
    amount: "XX.XX",
    method: "method data",
    scheduledDate: "MM/DD//YYYY",
    type: "autopay",
  },
  {
    id: "2",
    policyNumber: "93485397",
    policyName: "Policy name goes here - 2",
    amount: "XX.XX",
    method: "method data",
    scheduledDate: "MM/DD//YYYY",
    type: "autopay",
  },
  {
    id: "3",
    policyNumber: "93485397",
    policyName: "Policy name goes here - 3",
    amount: "XX.XX",
    method: "method data",
    scheduledDate: "MM/DD//YYYY",
    type: "onetime",
  },
  {
    id: "4",
    policyNumber: "93485397",
    policyName: "Policy name goes here - 4",
    amount: "XX.XX",
    method: "method data",
    scheduledDate: "MM/DD//YYYY",
    type: "onetime",
  },
  {
    id: "5",
    policyNumber: "93485397",
    policyName: "Policy name goes here - 5",
    amount: "XX.XX",
    method: "method data",
    scheduledDate: "MM/DD//YYYY",
    type: "autopay",
  },
  {
    id: "6",
    policyNumber: "93485397",
    policyName: "Policy name goes here - 6",
    amount: "XX.XX",
    method: "method data",
    scheduledDate: "MM/DD//YYYY",
    type: "autopay",
  },
  {
    id: "7",
    policyNumber: "93485397",
    policyName: "Policy name goes here - 7",
    amount: "XX.XX",
    method: "method data",
    scheduledDate: "MM/DD//YYYY",
    type: "autopay",
  },
  {
    id: "8",
    policyNumber: "93485397",
    policyName: "Policy name goes here - 8",
    amount: "XX.XX",
    method: "method data",
    scheduledDate: "MM/DD//YYYY",
    type: "onetime",
  },
  {
    id: "9",
    policyNumber: "93485397",
    policyName: "Policy name goes here - 9",
    amount: "XX.XX",
    method: "method data",
    scheduledDate: "MM/DD//YYYY",
    type: "autopay",
  },
  {
    id: "10",
    policyNumber: "93485397",
    policyName: "Policy name goes here - 10",
    amount: "XX.XX",
    method: "method data",
    scheduledDate: "MM/DD//YYYY",
    type: "autopay",
  },
  {
    id: "11",
    policyNumber: "93485397",
    policyName: "Policy name goes here - 11",
    amount: "XX.XX",
    method: "method data",
    scheduledDate: "MM/DD//YYYY",
    type: "autopay",
  },
];
export const paymentHistory = [
  {
    id: "1",
    policyNumber: "93485397",
    policyName: "Policy history name goes here",
    amount: "XX.XX",
    method: "method data",
    scheduledDate: "MM/DD//YYYY",
    confirmationNumber: "1DF2321",
  },
  {
    id: "2",
    policyNumber: "93485397",
    policyName: "Policy history name goes here",
    amount: "XX.XX",
    method: "method data",
    scheduledDate: "MM/DD//YYYY",
    confirmationNumber: "1DF2321",
  },
  {
    id: "3",
    policyNumber: "93485397",
    policyName: "Policy history name goes here",
    amount: "XX.XX",
    method: "method data",
    scheduledDate: "MM/DD//YYYY",
    confirmationNumber: "1DF2321",
  },
  {
    id: "4",
    policyNumber: "93485397",
    policyName: "Policy history name goes here",
    amount: "XX.XX",
    method: "method data",
    scheduledDate: "MM/DD//YYYY",
    confirmationNumber: "1DF2321",
  },
  {
    id: "5",
    policyNumber: "93485397",
    policyName: "Policy history name goes here",
    amount: "XX.XX",
    method: "method data",
    scheduledDate: "MM/DD//YYYY",
    confirmationNumber: "1DF2321",
  },
  {
    id: "6",
    policyNumber: "93485397",
    policyName: "Policy history name goes here",
    amount: "XX.XX",
    method: "method data",
    scheduledDate: "MM/DD//YYYY",
    confirmationNumber: "1DF2321",
  },
];
export const statements = [
  {
    id: "1",
    statementName: "A statement name goes here",
    date: "MM/DD//YYYY",
  },
  {
    id: "2",
    statementName: "A statement name goes here",
    date: "MM/DD//YYYY",
  },
  {
    id: "3",
    statementName: "A statement name goes here",
    date: "MM/DD//YYYY",
  },
  {
    id: "4",
    statementName: "A statement name goes here",
    date: "MM/DD//YYYY",
  },
];
