
const PolicySelector = ({ policies, updatePolicyChoice, filteredPolicy }) => {

  return (
    <>
      <div className="flex flew-row items-center">
        <label htmlFor="ID_policySelector" className="mr-2">
          Select from owner policies
        </label>
        <div>
          <select
            id="ID_policySelector"
            value={filteredPolicy ? filteredPolicy[0].policyNumber : ''}
            onChange={(e) => updatePolicyChoice(e.target.value)}
          >
            <option value="">all</option>
            {policies.map((item, idx) => (
              <option key={"option" + idx} value={item.policyNumber}>
                {item.policyTitle} {item.policyNumber}
              </option>
            ))
            }
          </select>
        </div>
      </div>
    </>
  );
}

export default PolicySelector;