export const bob = [
  {
    policyName: "Policy Name -1",
    policyId: "1",
    policyNumber: "1234567890",
    policyType: "Whole Life",
    dueDate: "MM/DD",
    dueAmount: "XX.XX",
    lapseDate: "01/01/2023",
    premiumAmount: "XXX.XX",
    policyStatus: "problem",
    policyStatusTexxt: "Payment Past Due",
  },
  {
    policyName: "Policy Name -2",
    policyId: "2",
    policyNumber: "1234567890",
    policyType: "Whole Life",
    dueDate: "MM/DD",
    dueAmount: "XX.XX",
    lapseDate: "01/01/2023",
    premiumAmount: "XXX.XX",
    policyStatus: "good",
    policyStatusTexxt: "Payment Paid",
  },
  {
    policyName: "Policy Name -3",
    policyId: "3",
    policyNumber: "1234567890",
    policyType: "Whole Life",
    dueDate: "MM/DD",
    dueAmount: "XX.XX",
    lapseDate: "01/01/2023",
    premiumAmount: "XXX.XX",
    policyStatus: "risk",
    policyStatusTexxt: "Payment Due in 3 Days",
  },
  {
    policyName: "Policy Name -4",
    policyId: "4",
    policyNumber: "1234567890",
    policyType: "Whole Life",
    dueDate: "MM/DD",
    dueAmount: "XX.XX",
    lapseDate: "01/01/2023",
    premiumAmount: "XXX.XX",
    policyStatus: "risk",
    policyStatusTexxt: "Payment Due in 3 Days",
  },
  {
    policyName: "Policy Name -5",
    policyId: "5",
    policyNumber: "1234567890",
    policyType: "Whole Life",
    dueDate: "MM/DD",
    dueAmount: "XX.XX",
    lapseDate: "01/01/2023",
    premiumAmount: "XXX.XX",
    policyStatus: "risk",
    policyStatusTexxt: "Payment Due in 3 Days",
  },
  {
    policyName: "Policy Name -6",
    policyId: "6",
    policyNumber: "1234567890",
    policyType: "Whole Life",
    dueDate: "MM/DD",
    dueAmount: "XX.XX",
    lapseDate: "01/01/2023",
    premiumAmount: "XXX.XX",
    policyStatus: "risk",
    policyStatusTexxt: "Payment Due in 3 Days",
  },
  {
    policyName: "Policy Name -7",
    policyId: "7",
    policyNumber: "1234567890",
    policyType: "Whole Life",
    dueDate: "MM/DD",
    dueAmount: "XX.XX",
    lapseDate: "01/01/2023",
    premiumAmount: "XXX.XX",
    policyStatus: "risk",
    policyStatusTexxt: "Payment Due in 3 Days",
  },
  {
    policyName: "Policy Name -8",
    policyId: "8",
    policyNumber: "1234567890",
    policyType: "Whole Life",
    dueDate: "MM/DD",
    dueAmount: "XX.XX",
    lapseDate: "01/01/2023",
    premiumAmount: "XXX.XX",
    policyStatus: "risk",
    policyStatusTexxt: "Payment Due in 3 Days",
  },
  {
    policyName: "Policy Name -9",
    policyId: "9",
    policyNumber: "1234567890",
    policyType: "Whole Life",
    dueDate: "MM/DD",
    dueAmount: "XX.XX",
    lapseDate: "01/01/2023",
    premiumAmount: "XXX.XX",
    policyStatus: "risk",
    policyStatusTexxt: "Payment Due in 3 Days",
  },
  {
    policyName: "Policy Name -10",
    policyId: "10",
    policyNumber: "1234567890",
    policyType: "Whole Life",
    dueDate: "MM/DD",
    dueAmount: "XX.XX",
    lapseDate: "01/01/2023",
    premiumAmount: "XXX.XX",
    policyStatus: "risk",
    policyStatusTexxt: "Payment Due in 3 Days",
  },
  {
    policyName: "Policy Name -11",
    policyId: "11",
    policyNumber: "1234567890",
    policyType: "Whole Life",
    dueDate: "MM/DD",
    dueAmount: "XX.XX",
    lapseDate: "01/01/2023",
    premiumAmount: "XXX.XX",
    policyStatus: "risk",
    policyStatusTexxt: "Payment Due in 3 Days",
  },
  {
    policyName: "Policy Name -12",
    policyId: "12",
    policyNumber: "1234567890",
    policyType: "Whole Life",
    dueDate: "MM/DD",
    dueAmount: "XX.XX",
    lapseDate: "01/01/2023",
    premiumAmount: "XXX.XX",
    policyStatus: "risk",
    policyStatusTexxt: "Payment Due in 3 Days",
  },
  {
    policyName: "Policy Name -13",
    policyId: "13",
    policyNumber: "1234567890",
    policyType: "Whole Life",
    dueDate: "MM/DD",
    dueAmount: "XX.XX",
    lapseDate: "01/01/2023",
    premiumAmount: "XXX.XX",
    policyStatus: "risk",
    policyStatusTexxt: "Payment Due in 3 Days",
  },
  {
    policyName: "Policy Name -14",
    policyId: "14",
    policyNumber: "1234567890",
    policyType: "Whole Life",
    dueDate: "MM/DD",
    dueAmount: "XX.XX",
    lapseDate: "01/01/2023",
    premiumAmount: "XXX.XX",
    policyStatus: "risk",
    policyStatusTexxt: "Payment Due in 3 Days",
  },
];
