// route: /profile
export const userData = {
  userAvatar: "/assets/img/profile-photo.png",
  firstName: "Sarah",
  lastName: "Toissant",
  pronoun: "( she / her / hers )",
  goal: "Space for copy for Rob to customize a brief bio about himself. This would be displayed for managers and/or other users who can see agent bios.",
  agencyName: "Lincoln Park Agency",
  suiteNumber: "Suite 260",
  streetAddress: "555 Maple Shade Dr.",
  city: "Anytown",
  state: "NY",
  zipcode: "55555",
  mobilePhone: "XXX-XXX-XXXX",
  homePhone: "XXX-XXX-XXXX",
  email: "gtoissant@gmail.com",
};
