export default function AgentSignupCard() {
  return (
    <div className="ui-card text-center relative p-4 xl:p-6 ">
      <h2>Sign Up</h2>
      <div className="text-left max-w-sm mx-auto">
        <p className="text-sm text-center">
          Take advantage of the many services available in our customer portal.
        </p>

        <ul className="mt-6 text-xs max-w-xs mx-auto">
          <li className="flex items-center mb-3 font-semibold">
            <svg
              className="ui-icon w-4 h-4 text-ui-blue mr-2"
              focusable="false"
              aria-hidden="true"
              role="presentation"
            >
              <use href="#ui-icon-app"></use>
            </svg>
            Manage your book of business
          </li>
          <li className="flex items-center mb-3 font-semibold">
            <svg
              className="ui-icon w-4 h-4 text-ui-blue mr-2"
              focusable="false"
              aria-hidden="true"
              role="presentation"
            >
              <use href="#ui-icon-money"></use>
            </svg>
            Access and manage your commission statements
          </li>
          <li className="flex items-center mb-3 font-semibold">
            <svg
              className="ui-icon w-4 h-4 text-ui-blue mr-2"
              focusable="false"
              aria-hidden="true"
              role="presentation"
            >
              <use href="#ui-icon-illustration"></use>
            </svg>
            Quote and run illustrations
          </li>
          <li className="flex items-center mb-3 font-semibold">
            <svg
              className="ui-icon w-4 h-4 text-ui-blue mr-2"
              focusable="false"
              aria-hidden="true"
              role="presentation"
            >
              <use href="#ui-icon-piechart"></use>
            </svg>
            Run reports
          </li>
          <li className="flex items-center mb-3 font-semibold">
            <svg
              className="ui-icon w-4 h-4 text-ui-blue mr-2"
              focusable="false"
              aria-hidden="true"
              role="presentation"
            >
              <use href="#ui-icon-plus"></use>
            </svg>
            And so much more
          </li>
        </ul>

        <p className="mt-6 text-center text-sm font-bold">
          Contact your manager today for access information.
        </p>
      </div>
    </div>
  );
}
