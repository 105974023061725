// route: /policies/:policy_id
export const policies = [
  {
    banner: "problem",
    badgeStatus: "problem",
    badgeSrText: "Policy has problem",
    badgeText: "$7.00 past due",
    policyNumber: "5723452",
    policyTitle: "Term Life - Sarah Toisssant - 1",
    insuredName: "Sarah Toissant",
    coverage: "500,000",
    productName: "Annual Renewable",
    term: "15 Years",
    value: "0.00",
    startDate: "01/01/2023",
    endDate: "01/01/2023",
    cta: "payment",
    faceAmount: "<value>",
    deathBenefit: "<value>",
    nfOption: "Extended term insurance",
    accountValue: "<value>",
    surrenderCharge: "<value>",
    surrenderValue: "<value>",
    billingMethod: "<value>",
    draftDate: "Extended term insurance",
    mode: "<data>",
    modalPremium: "<data>",
    paymentAmount: "321.46",
    annualAmount: 321.46 * 6,
    paymentMethod: "Bank Account",
    dueDate: "01/01/2023",
    isAutoPay: true,
    policyType: "Whole Life",
  },
  {
    banner: "",
    badgeStatus: "risk",
    badgeSrText: "Policy is risk",
    badgeText: "Payment draft in X",
    policyNumber: "93485397",
    policyTitle: "Whole Life - George -2",
    insuredName: "George Toissant",
    coverage: "100,000",
    productName: "Whole Life Product",
    term: "10 Years",
    value: "X,000",
    startDate: "01/01/2023",
    endDate: "01/01/2023",
    cta: "detail",
    faceAmount: "<value>",
    deathBenefit: "<value>",
    nfOption: "Extended term insurance",
    accountValue: "<value>",
    surrenderCharge: "<value>",
    surrenderValue: "<value>",
    billingMethod: "<value>",
    draftDate: "Extended term insurance",
    mode: "<data>",
    modalPremium: "<data>",
    paymentAmount: "498.20",
    annualAmount: 498.2 * 6,
    paymentMethod: "Bank Account",
    dueDate: "01/01/2023",
    isAutoPay: false,
    policyType: "Term Life",
  },
  {
    banner: "problem",
    badgeStatus: "problem",
    badgeSrText: "Policy has problem",
    badgeText: "$0.00 past due",
    policyNumber: "5723453",
    policyTitle: "Annuitant - Sarah Toisssant - 12",
    insuredName: "Sarah Toissant",
    coverage: "500,000",
    productName: "Annuity",
    term: "15 Years",
    value: "0.00",
    startDate: "01/01/2023",
    endDate: "01/01/2023",
    cta: "detail",
    isAutoPay: true,
    policyType: "Annuity",
    annuitantName: "Sarah Toisssant",
    accountValue: "100,000",
    surrenderValue: "200,000",
  },
  {
    banner: "",
    badgeStatus: "good",
    badgeSrText: "Policy is good",
    badgeText: "Paid to Date",
    policyNumber: "9517535",
    policyTitle: "Term Life - George Toisssant - 3",
    insuredName: "Sarah Toissant",
    coverage: "500,000",
    productName: "Annual Renewable",
    term: "15 Years",
    value: "",
    startDate: "01/01/2023",
    endDate: "01/01/2023",
    cta: "detail",
    faceAmount: "<value>",
    deathBenefit: "<value>",
    nfOption: "Extended term insurance",
    accountValue: "<value>",
    surrenderCharge: "<value>",
    surrenderValue: "<value>",
    billingMethod: "<value>",
    draftDate: "Extended term insurance",
    mode: "<data>",
    modalPremium: "<data>",
    paymentAmount: "987.00",
    annualAmount: 987.0 * 6,
    paymentMethod: "Bank Account",
    dueDate: "01/01/2023",
    isAutoPay: true,
    policyType: "Term Life",
  },
  {
    banner: "problem",
    badgeStatus: "problem",
    badgeSrText: "Policy has problem",
    badgeText: "$0.00 past due",
    policyNumber: "7953198",
    policyTitle: "Term Life - Sarah Toisssant - 4",
    insuredName: "Sarah Toissant",
    coverage: "500,000",
    productName: "Annual Renewable",
    term: "15 Years",
    value: "0.00",
    startDate: "01/01/2023",
    endDate: "01/01/2023",
    cta: "payment",
    faceAmount: "<value>",
    deathBenefit: "<value>",
    nfOption: "Extended term insurance",
    accountValue: "<value>",
    surrenderCharge: "<value>",
    surrenderValue: "<value>",
    billingMethod: "<value>",
    draftDate: "Extended term insurance",
    mode: "<data>",
    modalPremium: "<data>",
    paymentAmount: "789999.01",
    annualAmount: 789999.01 * 6,
    paymentMethod: "Bank Account",
    dueDate: "01/01/2023",
    isAutoPay: false,
    policyType: "Term Life",
  },
  {
    banner: "problem",
    badgeStatus: "problem",
    badgeSrText: "Policy has problem",
    badgeText: "$0.00 past due",
    policyNumber: "2976173",
    policyTitle: "Term Life - Sarah Toisssant - 5",
    insuredName: "Sarah Toissant",
    coverage: "500,000",
    productName: "Annual Renewable",
    term: "15 Years",
    value: "0.00",
    startDate: "01/01/2023",
    endDate: "01/01/2023",
    cta: "payment",
    faceAmount: "<value>",
    deathBenefit: "<value>",
    nfOption: "Extended term insurance",
    accountValue: "<value>",
    surrenderCharge: "<value>",
    surrenderValue: "<value>",
    billingMethod: "<value>",
    draftDate: "Extended term insurance",
    mode: "<data>",
    modalPremium: "<data>",
    paymentAmount: "9875.00",
    annualAmount: 9875.0 * 6,
    paymentMethod: "Bank Account",
    dueDate: "01/01/2023",
    isAutoPay: true,
    policyType: "Term Life",
  },
  {
    banner: "problem",
    badgeStatus: "problem",
    badgeSrText: "Policy has problem",
    badgeText: "$0.00 past due",
    policyNumber: "1973258",
    policyTitle: "Term Life - Sarah Toisssant - 6",
    insuredName: "Sarah Toissant",
    coverage: "500,000",
    productName: "Annual Renewable",
    term: "15 Years",
    value: "0.00",
    startDate: "01/01/2023",
    endDate: "01/01/2023",
    cta: "payment",
    faceAmount: "<value>",
    deathBenefit: "<value>",
    nfOption: "Extended term insurance",
    accountValue: "<value>",
    surrenderCharge: "<value>",
    surrenderValue: "<value>",
    billingMethod: "<value>",
    draftDate: "Extended term insurance",
    mode: "<data>",
    modalPremium: "<data>",
    paymentAmount: "654.21",
    annualAmount: 654.21 * 6,
    paymentMethod: "Bank Account",
    dueDate: "01/01/2023",
    isAutoPay: false,
    policyType: "Term Life",
  },
  {
    banner: "problem",
    badgeStatus: "problem",
    badgeSrText: "Policy has problem",
    badgeText: "$0.00 past due",
    policyNumber: "76913258",
    policyTitle: "Term Life - Sarah Toisssant - 7",
    insuredName: "Sarah Toissant",
    coverage: "500,000",
    productName: "Annual Renewable",
    term: "15 Years",
    value: "0.00",
    startDate: "01/01/2023",
    endDate: "01/01/2023",
    cta: "payment",
    faceAmount: "<value>",
    deathBenefit: "<value>",
    nfOption: "Extended term insurance",
    accountValue: "<value>",
    surrenderCharge: "<value>",
    surrenderValue: "<value>",
    billingMethod: "<value>",
    draftDate: "Extended term insurance",
    mode: "<data>",
    modalPremium: "<data>",
    paymentAmount: "123.23",
    annualAmount: 123.23 * 6,
    paymentMethod: "Bank Account",
    dueDate: "01/01/2023",
    isAutoPay: true,
    policyType: "Term Life",
  },
  {
    banner: "problem",
    badgeStatus: "problem",
    badgeSrText: "Policy has problem",
    badgeText: "$0.00 past due",
    policyNumber: "9645875",
    policyTitle: "Term Life - Sarah Toisssant - 8",
    insuredName: "Sarah Toissant",
    coverage: "500,000",
    productName: "Annual Renewable",
    term: "15 Years",
    value: "0.00",
    startDate: "01/01/2023",
    endDate: "01/01/2023",
    cta: "payment",
    faceAmount: "<value>",
    deathBenefit: "<value>",
    nfOption: "Extended term insurance",
    accountValue: "<value>",
    surrenderCharge: "<value>",
    surrenderValue: "<value>",
    billingMethod: "<value>",
    draftDate: "Extended term insurance",
    mode: "<data>",
    modalPremium: "<data>",
    paymentAmount: "321.21",
    annualAmount: 321.21 * 6,
    paymentMethod: "Bank Account",
    dueDate: "01/01/2023",
    isAutoPay: false,
    policyType: "Term Life",
  },
  {
    banner: "problem",
    badgeStatus: "problem",
    badgeSrText: "Policy has problem",
    badgeText: "$0.00 past due",
    policyNumber: "3265982",
    policyTitle: "Term Life - Sarah Toisssant - 9",
    insuredName: "Sarah Toissant",
    coverage: "500,000",
    productName: "Annual Renewable",
    term: "15 Years",
    value: "0.00",
    startDate: "01/01/2023",
    endDate: "01/01/2023",
    cta: "payment",
    faceAmount: "<value>",
    deathBenefit: "<value>",
    nfOption: "Extended term insurance",
    accountValue: "<value>",
    surrenderCharge: "<value>",
    surrenderValue: "<value>",
    billingMethod: "<value>",
    draftDate: "Extended term insurance",
    mode: "<data>",
    modalPremium: "<data>",
    paymentAmount: "465.25",
    annualAmount: 465.25 * 6,
    paymentMethod: "Bank Account",
    dueDate: "01/01/2023",
    isAutoPay: true,
    policyType: "Term Life",
  },
  {
    banner: "problem",
    badgeStatus: "problem",
    badgeSrText: "Policy has problem",
    badgeText: "$0.00 past due",
    policyNumber: "7852159",
    policyTitle: "Term Life - Sarah Toisssant - 10",
    insuredName: "Sarah Toissant",
    coverage: "500,000",
    productName: "Annual Renewable",
    term: "15 Years",
    value: "0.00",
    startDate: "01/01/2023",
    endDate: "01/01/2023",
    cta: "payment",
    faceAmount: "<value>",
    deathBenefit: "<value>",
    nfOption: "Extended term insurance",
    accountValue: "<value>",
    surrenderCharge: "<value>",
    surrenderValue: "<value>",
    billingMethod: "<value>",
    draftDate: "Extended term insurance",
    mode: "<data>",
    modalPremium: "<data>",
    paymentAmount: "163.25",
    annualAmount: 163.25 * 6,
    paymentMethod: "Bank Account",
    dueDate: "01/01/2023",
    isAutoPay: false,
    policyType: "Term Life",
  },
  {
    banner: "problem",
    badgeStatus: "problem",
    badgeSrText: "Policy has problem",
    badgeText: "$0.00 past due",
    policyNumber: "5825894",
    policyTitle: "Term Life - Sarah Toisssant - 11",
    insuredName: "Sarah Toissant",
    coverage: "500,000",
    productName: "Annual Renewable",
    term: "15 Years",
    value: "0.00",
    startDate: "01/01/2023",
    endDate: "01/01/2023",
    cta: "payment",
    faceAmount: "<value>",
    deathBenefit: "<value>",
    nfOption: "Extended term insurance",
    accountValue: "<value>",
    surrenderCharge: "<value>",
    surrenderValue: "<value>",
    billingMethod: "<value>",
    draftDate: "Extended term insurance",
    mode: "<data>",
    modalPremium: "<data>",
    paymentAmount: "55.55",
    annualAmount: 55.55 * 6,
    paymentMethod: "Bank Account",
    dueDate: "01/01/2023",
    isAutoPay: true,
    policyType: "Term Life",
  },
];

export const services = [
  {
    showDot: false,
    id: "1DF2321",
    submittedDate: "02/03/2022",
    title: "Change insured name on Whole",
    policyNumber: "5723452",
    statusText: "PROCESSING",
    status: "risk",
  },
  {
    showDot: false,
    id: "1DF2322",
    submittedDate: "02/03/2022",
    title: "Updated Beneficiaries",
    policyNumber: "5723452",
    statusText: "PROCESSED",
    status: "good",
  },
];

export const claims = [
  {
    showDot: true,
    id: "1CL23523",
    submittedDate: "02/03/2022",
    title: "Claim on <name of>",
    policyNumber: "5723452",
    statusText: "ACTION REQUIRED",
    status: "problem",
  },
  {
    showDot: false,
    id: "1CL23524",
    submittedDate: "02/03/2022",
    title: "Claim on <name of>",
    policyNumber: "5723452",
    statusText: "PROCESSED",
    status: "good",
  },
];
