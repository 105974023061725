// route: /service-claims
export const services = [
  {
    id: "1",
    title: "Changed payment method 1",
    status: "problem",
    statusText: "ACTION REQUIRED",
    policyNumber: "38482883",
    requestId: "method data",
    dateSubmitted: "MM/DD//YYYY",
  },
  {
    id: "2",
    title: "Updated Beneficiaries 2",
    status: "risk",
    statusText: "PROCESSING",
    policyNumber: "38482883",
    requestId: "method data",
    dateSubmitted: "MM/DD//YYYY",
  },
  {
    id: "3",
    title: "Updated Beneficiaries 3",
    status: "good",
    statusText: "PROCESSED",
    policyNumber: "38482883",
    requestId: "method data",
    dateSubmitted: "MM/DD//YYYY",
  },
  {
    id: "4",
    title: "Updated Beneficiaries 4",
    status: "good",
    statusText: "PROCESSED",
    policyNumber: "38482883",
    requestId: "method data",
    dateSubmitted: "MM/DD//YYYY",
  },
  {
    id: "5",
    title: "Updated Beneficiaries 5",
    status: "good",
    statusText: "PROCESSED",
    policyNumber: "38482883",
    requestId: "method data",
    dateSubmitted: "MM/DD//YYYY",
  },
  {
    id: "6",
    title: "Updated Beneficiaries 6",
    status: "good",
    statusText: "PROCESSED",
    policyNumber: "38482883",
    requestId: "method data",
    dateSubmitted: "MM/DD//YYYY",
  },
  {
    id: "7",
    title: "Updated Beneficiaries 7",
    status: "risk",
    statusText: "PROCESSING",
    policyNumber: "38482883",
    requestId: "method data",
    dateSubmitted: "MM/DD//YYYY",
  },
];
// route: /service-claims
export const claims = [
  {
    id: "1",
    title: "ID: 928127283827 1",
    status: "problem",
    statusText: "ACTION REQUIRED",
    policyNumber: "38482883",
    claimType: "Death Claim",
    dateSubmitted: "MM/DD//YYYY",
  },
  {
    id: "2",
    title: "ID: 928127283827 2",
    status: "risk",
    statusText: "DESCRIPTION",
    policyNumber: "38482883",
    claimType: "Death Claim",
    dateSubmitted: "MM/DD//YYYY",
  },
  {
    id: "3",
    title: "ID: 928127283827 3",
    status: "good",
    statusText: "PROCESSED",
    policyNumber: "38482883",
    claimType: "Accident Claim",
    dateSubmitted: "MM/DD//YYYY",
  },
  {
    id: "4",
    title: "ID: 928127283827 4",
    status: "risk",
    statusText: "PROCESSED",
    policyNumber: "38482883",
    claimType: "Accident Claim",
    dateSubmitted: "MM/DD//YYYY",
  },
  {
    id: "5",
    title: "ID: 928127283827 5",
    status: "risk",
    statusText: "PROCESSED",
    policyNumber: "38482883",
    claimType: "Accident Claim",
    dateSubmitted: "MM/DD//YYYY",
  },
  {
    id: "6",
    title: "ID: 928127283827 6",
    status: "good",
    statusText: "PROCESSED",
    policyNumber: "38482883",
    claimType: "Death Claim",
    dateSubmitted: "MM/DD//YYYY",
  },
  {
    id: "7",
    title: "ID: 928127283827 7",
    status: "risk",
    statusText: "DESCRIPTION",
    policyNumber: "38482883",
    claimType: "Death Claim",
    dateSubmitted: "MM/DD//YYYY",
  },
  {
    id: "8",
    title: "ID: 928127283827 8",
    status: "risk",
    statusText: "DESCRIPTION",
    policyNumber: "38482883",
    claimType: "Death Claim",
    dateSubmitted: "MM/DD//YYYY",
  },
  {
    id: "9",
    title: "ID: 928127283827 9",
    status: "risk",
    statusText: "DESCRIPTION",
    policyNumber: "38482883",
    claimType: "Death Claim",
    dateSubmitted: "MM/DD//YYYY",
  },
  {
    id: "10",
    title: "ID: 928127283827 0",
    status: "risk",
    statusText: "DESCRIPTION",
    policyNumber: "38482883",
    claimType: "Death Claim",
    dateSubmitted: "MM/DD//YYYY",
  },
];

// route: /services-claims/claims/1
export const claim = {
  id: "9281837131",
  policyNumber: "23423523",
  claimType: "Death claim",
  dateSubmitted: "01/01/2023",
  status: "problem",
  statusText: "Pending receipt of required forms and docs",
  step: 2,
  requiredDocs: [
    {
      title: "Name of FNOL Form",
      status: "good",
      statusText: "Accepted",
      desc: "This is the form you completed when first filing the claim.",
      document: {
        id: 1,
        name: "Name of document",
        location: "/",
        desc: "Download PDF",
      },
    },
    {
      title: "Claimant form",
      status: "good",
      statusText: "Accepted",
      desc: "Concise description of what that form is and why it's needed.",
      document: {
        id: 1,
        name: "Name of document",
        location: "/",
        desc: "Download PDF",
      },
    },
    {
      title: "Certified copy of the death certificate",
      status: "good",
      statusText: "Accepted",
      desc: "Concise description of what that form is and why it’s needed",
      document: {
        id: 1,
        name: "Name of document",
        location: "/",
        desc: "Download PDF",
      },
    },
    {
      title: "Another required form",
      status: "good",
      statusText: "Accepted",
      desc: "This is the form you completed when first filing the claim.",
      document: {
        id: 1,
        name: "Name of document",
        location: "/",
        desc: "Download PDF",
      },
    },
    {
      title: "Another document that's needed",
      status: "problem",
      statusText: "Document missing",
      desc: "This is the form you completed when first filing the claim.",
      document: {},
    },
  ],
};
