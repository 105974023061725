import { Link } from "react-router-dom";
export default function LinkWithIcon({ link }) {
  return (
    <Link className="ui-link-icon" to={link.url}>
      <svg
        className="ui-icon w-4 h-4"
        focusable="false"
        aria-hidden="true"
        role="presentation"
      >
        <use href={`${link.icon}`}></use>
      </svg>
      {link.label}
    </Link>
  );
}
