import { Dialog } from "@headlessui/react";
import { useState } from "react";
import DialogBackdrop from "../../ModalDialogs/DialogBackdrop";

export default function PaymentRow({ data, type }) {
  let [isOpenCancelOnetimeDialog, setIsOpenCancelOnetimeDialog] =
    useState(false);
  let [isOpenEditPaymentDialog, setIsOpenEditPaymentDialog] = useState(false);
  let [isOpenCancelAutopayDialog, setIsOpenCancelAutopayDialog] =
    useState(false);
  return (
    <>
      <div className="ui-card-bar relative mt-4">
        <div className="px-3 py-2 lg:p-4">
          <div className="grid grid-cols-2 gap-4 md:grid-cols-5 lg:grid-cols-6">
            <div className="md:col-span-2">
              <div className="text-xs text-theme-text-tertiary mb-1">
                Policy #{data.policyNumber}
              </div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {data.policyName}
              </div>
            </div>
            <div className="">
              <div className="text-xs text-theme-text-tertiary mb-1">Payment Amount</div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                ${data.amount}
              </div>
            </div>
            <div className="hidden lg:block">
              <div className="text-xs text-theme-text-tertiary mb-1">Payment Method</div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {data.method}
              </div>
            </div>
            <div className="">
              <div className="text-xs text-theme-text-tertiary mb-1">Scheduled Date</div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {data.scheduledDate}
              </div>
            </div>

            {type === "scheduled" && (
              <div className="">
                {/* *** Need to ask Jay - showing what is in mock not what is in the wireframe */}
                <div className="text-xs text-theme-text-tertiary mb-1">
                  {data.type === "autopay" && "Enrolled in Autopay"}
                  {data.type === "onetime" && " One-Time Payment"}
                </div>
                <div className="text-sm font-semibold text-theme-text-secondary">
                  <button
                    className="ui-btn-hyperlink"
                    onClick={() => setIsOpenEditPaymentDialog(true)}
                  >
                    Edit
                    <span className="sr-only">
                      this schedule payment for Policy #{data.policyNumber}
                    </span>
                  </button>
                  <span className="mx-4">/</span>
                  <button
                    className="ui-btn-hyperlink"
                    onClick={() => setIsOpenCancelAutopayDialog(true)}
                  >
                    Cancel
                    <span className="sr-only">
                      this schedule payment for Policy #{data.policyNumber}
                    </span>
                  </button>
                </div>
              </div>
            )}
            {type === "history" && (
              <div className="block">
                {/* *** Need to ask Jay - showing what is in mock not what is in the wireframe */}
                <div className="text-xs text-theme-text-tertiary mb-1">
                  Confirmation Number
                </div>
                <div className="text-sm font-semibold text-theme-text-secondary">
                  {data.confirmationNumber}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <Dialog
        open={isOpenEditPaymentDialog}
        onClose={() => setIsOpenEditPaymentDialog(false)}
        className="ui-dialogs"
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <DialogBackdrop />
        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel
            role="dialog"
            aria-modal="true"
            className="ui-default-dialog"
          >
            <Dialog.Title className="font-body text-xl font-bold text-left">
              Edit a scheduled payment
            </Dialog.Title>
            <button
              className="ui-default-dialog-close"
              aria-label="close cancel payment window"
              onClick={() => setIsOpenEditPaymentDialog(false)}
            >
              <svg
                className="ui-icon h-3.5 w-3.5"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href="#ui-icon-close"></use>
              </svg>
            </button>
            <hr />
            <Dialog.Description>Edit payment information</Dialog.Description>

            <div className="text-xl my">
              <p>
                Work with Bryan to reuse Make a Payment for Wireframe pgs. 28 -
                33
              </p>
              <p>
                Wireframes so new screens for payment form vs the dialog in mock
              </p>
              <p>*** Need to ask Jay</p>
            </div>

            {/* <!-- Buttons --> */}
            <div className="block text-right mt-6">
              <button
                className="ui-btn-secondary ui-btn-min-w mt-4"
                onClick={() => setIsOpenEditPaymentDialog(false)}
              >
                Close
              </button>
              <button
                id="ID_applyFilterOptions"
                className="ui-btn-primary ui-btn-min-w mt-4"
                onClick={() => setIsOpenEditPaymentDialog(false)}
              >
                Close
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      <Dialog
        open={isOpenCancelOnetimeDialog}
        onClose={() => setIsOpenCancelOnetimeDialog(false)}
        className="ui-dialogs"
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <DialogBackdrop />
        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel
            role="dialog"
            aria-modal="true"
            className="ui-default-dialog"
          >
            <Dialog.Title className="font-body text-xl font-bold text-left">
              Are you sure you want to cancel this payment?
            </Dialog.Title>
            <button
              className="ui-default-dialog-close"
              aria-label="close cancel payment window"
              onClick={() => setIsOpenCancelOnetimeDialog(false)}
            >
              <svg
                className="ui-icon h-3.5 w-3.5"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href="#ui-icon-close"></use>
              </svg>
            </button>
            <hr />
            <Dialog.Description>
              Any other text relevant to canceling payment or reminding the user
              that a payment is still due can go here. Lorem ipsum dolor sit
              amet.
            </Dialog.Description>
            {/* <!-- Buttons --> */}
            <div className="block text-right mt-6">
              <button
                className="ui-btn-secondary ui-btn-min-w mt-4"
                onClick={() => setIsOpenCancelOnetimeDialog(false)}
              >
                No, keep scheduled payment
              </button>
              <button
                id="ID_applyFilterOptions"
                className="ui-btn-primary ui-btn-min-w mt-4"
                onClick={() => setIsOpenCancelOnetimeDialog(false)}
              >
                Yes, cancel payment
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>

      <Dialog
        open={isOpenCancelAutopayDialog}
        onClose={() => setIsOpenCancelAutopayDialog(false)}
        className="ui-dialogs"
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <DialogBackdrop />
        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel
            role="dialog"
            aria-modal="true"
            className="ui-default-dialog"
          >
            <Dialog.Title className="font-body text-xl font-bold text-left">
              Are you sure you want to cancel a payment?
            </Dialog.Title>
            <button
              className="ui-default-dialog-close"
              aria-label="close cancel payment window"
              onClick={() => setIsOpenCancelAutopayDialog(false)}
            >
              <svg
                className="ui-icon h-3.5 w-3.5"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href="#ui-icon-close"></use>
              </svg>
            </button>
            <hr />
            <Dialog.Description>
              Any other text relevant to canceling a recurring can go here.
              Lorem ipsum dolor sit amet consecteteur..
            </Dialog.Description>
            {/* <!-- Buttons --> */}
            <div className="block text-right mt-6">
              <button
                className="ui-btn-secondary ui-btn-min-w mt-4"
                onClick={() => setIsOpenCancelAutopayDialog(false)}
              >
                Do not cancel
                <div className="text-xs">(Keep scheduled payment)</div>
              </button>
              <button
                className="ui-btn-secondary ui-btn-min-w mt-4"
                onClick={() => setIsOpenCancelAutopayDialog(false)}
              >
                Cancel recurring payment
                <div className="text-xs">(Unenroll in autopay)</div>
              </button>
              <button
                id="ID_applyFilterOptions"
                className="ui-btn-primary ui-btn-min-w mt-4"
                onClick={() => setIsOpenCancelAutopayDialog(false)}
              >
                Yes, cancel one payment
                <div className="text-xs">(Payment is still due by MM/DD)</div>
              </button>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
}
