import Accordion from "../components/General/Accordion";
import { faqs } from "../js/placeholderData/help-faq-data";
export default function HelpFaqs() {
  return (
    <>
      {/* SPRINT-5-CUSTOMER-help-section-screens.pdf (pgs 6-7) */}
      <div className="container mb-8">
        <h1>FAQs</h1>
        <hr />
      </div>

      {/* FAQs */}
      <div className="container ui-component-spacing">
        <div className="grid grid-cols-1 gap-4">
          {faqs.map((faq, idx) => (
            <Accordion item={faq} key={`FAQ - ${idx}`} />
          ))}
        </div>
      </div>
    </>
  );
}
