import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

const ForgotUsername = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    shouldUnregister: false,
  });
  const [panel, setPanel] = useState({
    panels: [{ active: true }, { active: false }],
  });
  const [policyHolder, setPolicyHolder] = useState("individual");
  const onOptionChange = (e) => setPolicyHolder(e.target.value);

  const onSubmit = (position) => {
    const updateState = panel.panels.map((item, idx) => {
      if (idx === position) return { active: true };
      if (idx !== position) return { active: false };
    });
    setPanel({ panels: updateState });
  };
  return (
    <>
      {panel.panels[0].active && (
        <>
          {/* Forgot username - Let’s find your account - SPRINT-1-CUSTOMER-my-policies-landing-and-unauthenticated-landing.pdf Pg 28 */}
          <div className="container ui-component-spacing">
            <div className="ui-card p-4 mx-auto  lg:w-3/4 md:p-6">
              <h2 className="font-body font-bold text-xl">
                Let's find your account
              </h2>
              <p className="text-sm">
                Please enter owner verification information
              </p>

              <form onSubmit={handleSubmit(() => onSubmit(1))}>
                <fieldset>
                  <div className="inline-block mt-1 mr-6">
                    <label className="inline-flex items-center text-sm font-normal">
                      <input
                        type="radio"
                        name="policyOwnerType"
                        value="individual"
                        checked={policyHolder === "individual"}
                        onClick={onOptionChange}
                        id="ID_policyOwnerTypeIndividual"
                        {...register("policyOwnerType")}
                        className="mr-2"
                      />
                      An individual policyholder
                    </label>
                  </div>
                  <div className="inline-block">
                    <label className="inline-flex items-center text-sm font-normal">
                      <input
                        type="radio"
                        name="policyOwnerType"
                        value="entity"
                        checked={policyHolder === "entity"}
                        onClick={onOptionChange}
                        id="ID_policyOwnerTypeEntity"
                        {...register("policyOwnerType")}
                        className="mr-2"
                      />
                      A non-natural entity (corporation) trust, etc.
                    </label>
                  </div>
                </fieldset>

                <hr />

                <p className="mb-6 font-semibold text-base">
                  You will need your policy number (an active one) and the
                  policy owner's Social Security Number, telephone number and
                  zip code to sign up for an account.
                </p>

                <div>
                  <label
                    id="ID_policyNumberLabel"
                    className="text-base font-semibold"
                  >
                    Policy Number*:
                  </label>
                  <p className="text-xs text-theme-text-tertiary mb-1">
                    Your policy number can be found on your original
                    policy/contract or on your annual, policy statement.
                  </p>
                  <input
                    id="ID_policyNumber"
                    className="w-full"
                    type="text"
                    placeholder="#"
                    {...register("policyNumber", {
                      required: {
                        value: true,
                        message: "please enter a policy number",
                      },
                    })}
                    aria-describedby="ID_policyNumberError"
                    aria-labelledby="ID_policyNumberLabel"
                  />
                  {errors.policyNumber && (
                    <div id="ID_policyNumberError" className="ui-input-error">
                      {errors.policyNumber.message}
                    </div>
                  )}
                </div>

                <hr />

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
                  <div>
                    <label id="ID_firstNameLabel">First Name *</label>
                    <input
                      id="ID_firstName"
                      className="w-full"
                      type="text"
                      {...register("firstName", {
                        required: {
                          value: true,
                          message: "please enter your first name",
                        },
                      })}
                      aria-describedby="ID_firstNameError"
                      aria-labelledby="ID_firstNameLabel"
                    />
                    {errors.firstName && (
                      <div id="ID_firstNameError" className="ui-input-error">
                        {errors.firstName.message}
                      </div>
                    )}
                  </div>
                  <div>
                    <label id="ID_lastNameLabel">Last Name *</label>
                    <input
                      id="ID_lastName"
                      className="w-full"
                      type="text"
                      {...register("lastName", {
                        required: {
                          value: true,
                          message: "please enter your last name",
                        },
                      })}
                      aria-describedby="ID_lastNameError"
                      aria-labelledby="ID_lastNameLabel"
                    />
                    {errors.lastName && (
                      <div id="ID_lastNameError" className="ui-input-error">
                        {errors.lastName.message}
                      </div>
                    )}
                  </div>
                  <div>
                    <label id="ID_middleNameLabel">Middle Initial</label>
                    <input
                      id="ID_middleName"
                      className="w-full"
                      type="text"
                      {...register("middleInitial")}
                      aria-describedby="ID_middleNameError"
                      aria-labelledby="ID_middleNameLabel"
                    />
                  </div>
                  <div>
                    <label id="ID_ssnLabel">Social Security Number *</label>
                    <input
                      id="ID_ssn"
                      className="w-full"
                      type="text"
                      placeholder="XXX-XX-XXXX"
                      {...register("socialSecurity", {
                        required: {
                          value: true,
                          message: "please enter your ssn",
                        },
                      })}
                      aria-describedby="ID_ssnError"
                      aria-labelledby="ID_ssnLabel"
                    />
                    {errors.socialSecurity && (
                      <div id="ID_ssnError" className="ui-input-error">
                        {errors.socialSecurity.message}
                      </div>
                    )}
                    <span id="ID_ssnFormat" className="sr-only">
                      Enter as XXX-XX-XXXX
                    </span>
                  </div>
                  <div>
                    <label id="ID_zipLabel">Zip Code *</label>
                    <input
                      id="ID_zip"
                      className="w-full"
                      type="text"
                      {...register("zipCode", {
                        required: {
                          value: true,
                          message: "please enter your zip code",
                        },
                      })}
                      aria-describedby="ID_zipError"
                      aria-labelledby="ID_zipLabel"
                    />
                    {errors.zipCode && (
                      <div id="ID_zipError" className="ui-input-error">
                        {errors.zipCode.message}
                      </div>
                    )}
                  </div>
                  <div>
                    <label id="ID_phoneLabel">Mobile Phone Number *</label>
                    <input
                      id="ID_phone"
                      className="w-full"
                      type="text"
                      placeholder="(XXX) XXX-XXXX"
                      aria-describedby="ID_phoneFormat ID_phoneError"
                      aria-labelledby="ID_phoneLabel"
                      {...register("phoneNumber", {
                        required: {
                          value: true,
                          message: "please enter your phone number",
                        },
                      })}
                    />
                    {errors.phoneNumber && (
                      <div id="ID_phoneError" className="ui-input-error">
                        {errors.phoneNumber.message}
                      </div>
                    )}
                    <span id="ID_phoneFormat" className="sr-only">
                      Enter as (XXX) XXX-XXXX
                    </span>
                  </div>
                </div>
                <div className="mt-4 text-right">
                  <button
                    id="ID_registrationStep1Btn"
                    className="ui-btn-primary ui-btn-min-w mt-2"
                    aria-controls="ID_registrationStep2Form"
                    aria-expanded="false"
                  >
                    Continue <span className="onto sign up step 2"></span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </>
      )}

      {panel.panels[1].active && (
        <>
          {/* Forgot username - Thank you for verifying - SPRINT-1-CUSTOMER-my-policies-landing-and-unauthenticated-landing.pdf Pg 30 */}
          <div className="container ui-component-spacing">
            <div className="ui-card p-4 mx-auto sm:w-2/3 md:w-3/5 lg:w-1/2 xl:p-6">
              <h2 className="font-body font-bold text-xl">
                Thank you for verifying
              </h2>
              <hr />
              <p className="text-sm text-center">Your username is</p>
              <div className="text-2xl text-center font-semibold my-2 xl:text-3xl">
                gtoissant52
              </div>
              <div className="text-center mt-6 xl:mt-8">
                <Link to="/">
                  <button className="ui-btn-primary ui-btn-min-w">
                    Continue to login
                  </button>
                </Link>
                <div className="mt-4">
                  <Link to="/forgot-password" className="text-xs">
                    Forgot password
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ForgotUsername;
