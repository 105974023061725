import AgentNotesCard from "./PolicyCards/AgentNotesCard";
import Pagination from "../../General/Pagination";
import { usePagination } from "../../../hooks/usePagination";
import AgentNotesAddModal from "../ModalDialogs/AgentNotesAddModal";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";

const AgentNotesTab = ({ data }) => {
  const { current: agentNotes, paginationProps } = usePagination(data, 6);

  const catSelectId = uuidv4();
  const [isOpenAddNote, setIsOpenAddNote] = useState(false);

  return (
    <>
      <div className="ui-component-spacing">
        <div className="flex items-centers space-x-4 justify-between my-4 xl:my-6 w-full">
          <div className="flex flex-row items-center">
            <label className="mr-1" htmlFor={catSelectId}>
              Category
            </label>
            <select id={catSelectId}>
              <option>All</option>
              <option>Payments</option>
              <option>Contract Change</option>
              <option>Client Policy Questions</option>
            </select>
          </div>
          <div className="flex flex-row items-center justify-end">
            <div className="flex flex-row font-semibold items-center px-4">
              <div className="mr-2">Sort by date:</div>
              <button className="ui-btn-hyperlink font-semibold">
                Newest first
              </button>
              <div className="mx-2">|</div>
              <button className="ui-btn-hyperlink font-semibold" disabled>
                Oldest first
              </button>
            </div>
            <button
              className="ui-btn-primary"
              onClick={() => setIsOpenAddNote(true)}
            >
              Add New Note
            </button>
          </div>
        </div>
        {agentNotes.map((item, idx) => (
          <AgentNotesCard key={idx} data={item} />
        ))}
        <div className="my-8">
          <Pagination {...paginationProps} />
        </div>
      </div>

      {/* Add Agent Note Dialog */}
      <AgentNotesAddModal
        isOpenAddNote={isOpenAddNote}
        setIsOpenAddNote={setIsOpenAddNote}
      />
    </>
  );
};

export default AgentNotesTab;
