import {
  scheduledPayments,
  paymentHistory,
  statements,
} from "../js/placeholderData/billingData";
import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Carousel from "../components/General/Carousel";
import { Tab } from "@headlessui/react";
import ScheduledPaymentsTab from "../components/Payments/Billing/ScheduledPaymentsTab";
import PaymentHistoryTab from "../components/Payments/Billing/PaymentHistoryTab";
import StatementsTab from "../components/Payments/Billing/StatementsTab";
import { useNavigate } from "react-router-dom";
import { useAccountTypeCheck } from "../hooks/useAccountTypeCheck";

export default function Billing() {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const { userType } = useAccountTypeCheck("isBeneficiaryUser");
  let navigate = useNavigate(); 
  // search param config for setting active tab
  let [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    console.log('Is beneficiary user', userType);
   if(userType){
      navigate(`/beneficiaryErrorPlaceholderPage`, { replace: true });
    }  
    let tab = searchParams.get("tab");
    switch (tab) {
      case "scheduled":
        setSelectedTabIndex(0);
        break;

      case "history":
        setSelectedTabIndex(1);
        break;

      case "statement":
        setSelectedTabIndex(2);
        break;
    }
  }, [searchParams,userType]);

  return (
    <>
      {/* Billing Schedule, History & Statements */}
      <div className="container mb-8">
        <h1>Billing Schedule, History &amp; Statements</h1>
        <p>Lorem ipsum</p>
        <hr />
      </div>

      <div className="container ui-component-spacing">
        <div className="ui-card relative p-4 xl:p-6">
          <Carousel />
        </div>
      </div>

      {/* Scheduled payments - SPRINT-4-CUSTOMER-payments.pdf Pg 22-24 */}
      <div className="container ui-component-spacing">
        <Tab.Group selectedIndex={selectedTabIndex}>
          <div className="flex flex-col lg:flex-row lg:items-center">
            <div className="ui-card flex-grow px-2 p-1 md:px-4">
              <Tab.List>
                <Tab
                  className="text-sm mx-3 pt-3 pb-2 mb-1 md:text-base md:mx-4 lg:text-lg"
                  onClick={() => {
                    setSearchParams("?tab=scheduled");
                  }}
                >
                  Scheduled Payments
                </Tab>
                <Tab
                  className="text-sm mx-3 pt-3 pb-2 mb-1 md:text-base md:mx-4 lg:text-lg"
                  onClick={() => {
                    setSearchParams("?tab=history");
                  }}
                >
                  Payment History
                </Tab>
                <Tab
                  className="text-sm mx-3 pt-3 pb-2 mb-1 md:text-base md:mx-4 lg:text-lg"
                  onClick={() => {
                    setSearchParams("?tab=statement");
                  }}
                >
                  Statements
                </Tab>
              </Tab.List>
            </div>
            <div className="inline-flex items-center mt-4 lg:mt-0">
              <label className="text-xs mr-2 md:ml-6">Category</label>
              <select>
                <option>Show all</option>
                <option>-----------------------</option>
              </select>
            </div>
          </div>
          <Tab.Panels>
            {/* Scheduled payments */}
            <ScheduledPaymentsTab data={scheduledPayments} />

            {/* Payment History */}
            <PaymentHistoryTab data={paymentHistory} />

            {/* Statements */}
            <StatementsTab data={statements} />
          </Tab.Panels>
        </Tab.Group>
      </div>
    </>
  );
}
