import { useForm } from "react-hook-form";
import { useSearchParams, useNavigate } from "react-router-dom";
import FormInputError from "../../General/FormInputError";

const PolicyLookup = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      keyword: searchParams.get("keyword"),
    },
  });

  const searchPolicy = (data) => {
    navigate(`/policylookup?keyword=${data.keyword}`);
  };

  return (
    <>
      <form
        className="flex flex-col sm:flex-row"
        onSubmit={handleSubmit(searchPolicy)}
      >
        <div className="flex flex-col flex-grow justify-start sm:mr-4">
          <div className="relative">
            <input
              id="id_policyLookupInput"
              className="border border-ui-gray-medium h-9 w-full p-2 pl-8 rounded"
              aria-label="Site Search"
              placeholder="Enter policy number or policy holder name"
              {...register("keyword", {
                required:
                  "Please enter policy number or policy holder name before continuing",
              })}
            />
            <svg
              className="ui-icon w-4 h-4 absolute text-theme-action left-0 top-1/2 mt-[-.5rem] ml-2"
              focusable="false"
              aria-hidden="true"
              role="presentation"
            >
              <use href="#ui-icon-search"></use>
            </svg>
          </div>
          <FormInputError
            id="id_policyLookupError"
            errorMessage={errors?.keyword?.message}
          />
        </div>
        <div>
          <button
            id="id_policyLookupBtn"
            className="ui-btn-primary h-9 w-32 mx-auto ui-btn-min-w mt-4 sm:mt-0"
          >
            Search
          </button>
        </div>
      </form>
    </>
  );
};

export default PolicyLookup;
