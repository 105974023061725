import Badge from "../../../General/Badge";
import ClaimStatusCard from "../ClaimStatusCard";
import AgentUpdateRelatedDocModal from "../../ModalDialogs/AgentUpdateRelatedDocModal";
import { v4 as uuidv4 } from "uuid";
import { useState } from "react";

const ClaimCard = ({ data, cardExpanded }) => {
  const claimId = uuidv4();
  const [isOpen, setIsOpen] = useState(cardExpanded);
  const [isOpenUpdateDoc, setIsOpenUpdateDoc] = useState(false);

  return (
    <>
      <div className="ui-card my-4">
        <div className="p-4 xl:px-6">
          <div className="flex flex-col md:flex-row md:justify-between">
            <div className="text-left">
              <button
                id={claimId}
                className="flex items-center group"
                aria-controls={claimId + "-panel"}
                aria-expanded={isOpen}
                aria-label="Expand or collapse claim details"
                onClick={() => setIsOpen((isOpen) => (isOpen = !isOpen))}
              >
                <span className="inline-block p-1 rounded-full mr-1 text-ui-blue group-hover:text-white group-hover:bg-ui-blue">
                  <svg
                    className="ui-icon ui-plus w-4 h-4"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-plus"></use>
                  </svg>
                  <svg
                    className="ui-icon ui-minus w-4 h-4"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-minus"></use>
                  </svg>
                </span>
                {data.badgeStatus && (
                  <span className="mr-1 flex items-center md:hidden">
                    <Badge
                      iconOnly={true}
                      status={data.badgeStatus}
                      statusText={data.badgeText}
                      srPrefix="Claim"
                    />
                  </span>
                )}
                <span className="text-lg font-semibold text">
                  ID: {data.id}
                </span>
              </button>
            </div>
            {data.badgeStatus && (
              <div className="hidden md:block">
                <Badge
                  status={data.badgeStatus}
                  statusText={data.badgeText}
                  srPrefix="Claim"
                />
              </div>
            )}
          </div>
        </div>
        <hr className="ui-collapse" />
        <div className="p-4 xl:px-6">
          <div className="text-xs text-theme-text-tertiary">
            Policy number {data.policyNumber}
          </div>
          <div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 xl:gap-6">
            <div>
              <div className="text-xs text-theme-text-tertiary">Owner Name</div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {data.owner}
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">
                Policy Number
              </div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {data.policyNumber}
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">Claim type</div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {data.type}
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">
                Date submitted
              </div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {data.dateSubmitted}
              </div>
            </div>
          </div>
        </div>
        <div id={claimId + "-panel"} className="px-4 pb-4 xl:px-6 xl:pb-6">
          {isOpen && (
            <div>
              <hr />
              <div>
                <div className="text-lg font-semibold">
                  Where you are in the process:
                </div>
                <div className="flex justify-center mt-4 lg:mt-6">
                  <ClaimStatusCard data={data} />
                </div>
              </div>
              <hr />
              <div>
                <div className="text-lg font-semibold">
                  Required forms documents
                </div>
                <div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 md:gap-4 xl:grid-cols-5">
                  {data.documents.map((doc, index) => {
                    return (
                      <div key={index}>
                        <div className="flex flex-row">
                          <div className="pt-px mr-1">
                            <Badge
                              iconOnly="true"
                              status={doc.badgeStatus}
                              statusText={doc.statusText}
                              srPrefix={doc.srPrefix}
                            />
                          </div>
                          <div className="text-sm font-semibold">
                            {doc.name}
                            <div className="text-xs font-normal">
                              {doc.action === "Update" && (
                                <button
                                  className="ui-btn-hyperlink"
                                  onClick={() => setIsOpenUpdateDoc(true)}
                                >
                                  Update
                                  <span className="sr-only"> {doc.name}</span>
                                </button>
                              )}
                              {doc.action === "View" && (
                                <a
                                  className="ui-btn-hyperlink"
                                  href={doc.path}
                                  target="new"
                                >
                                  View
                                  <span className="sr-only"> {doc.name}</span>
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      {/* Update document Dialog */}
      <AgentUpdateRelatedDocModal
        isOpenUpdateDoc={isOpenUpdateDoc}
        setIsOpenUpdateDoc={setIsOpenUpdateDoc}
      />
    </>
  );
};
export default ClaimCard;
