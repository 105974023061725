import FormInputError from "../../../General/FormInputError";
import { v4 as uuidv4 } from "uuid";

export default function BeneficiaryInfoCard({
  componentIndex,
  lastComponent,
  FACStepThreeRegister,
  append,
  remove,
  errors,
}) {
  const uuid = uuidv4();

  return (
    <div className="mt-6 grid gap-4 grid-cols-1 lg:gap-6">
      <div className="grid gap-4 grid-cols-1 md:gap-y-0 md:gap-x-4 md:grid-cols-[2fr,2fr,1fr]">
        {componentIndex > 0 && (
          <div className="md:col-span-3">
            <button
              className="ui-btn-primary-error my-2"
              type="button"
              onClick={() => remove(componentIndex)}
            >
              Remove below beneficiary
            </button>
          </div>
        )}
        <div>
          <label htmlFor={`${uuid}-fn-beneficiary`} className="block mb-1">
            First Name *
          </label>
          <input
            id={`${uuid}-fn-beneficiary`}
            type="text"
            {...FACStepThreeRegister(
              `beneficiaries[${componentIndex}].firstName`,
              {
                required: `Please enter a first name for beneficiary - ${
                  componentIndex + 1
                }`,
              }
            )}
          />
          <FormInputError
            id={`${uuid}-fn-beneficiary-error`}
            errorMessage={
              errors?.beneficiaries?.length
                ? errors?.beneficiaries[componentIndex]?.firstName?.message
                : ""
            }
          />
        </div>

        <div>
          <label htmlFor={`${uuid}-ln-beneficiary`} className="block mb-1">
            Last Name *
          </label>
          <input
            id={`${uuid}-ln-beneficiary`}
            type="text"
            {...FACStepThreeRegister(
              `beneficiaries[${componentIndex}].lastName`,
              {
                required: `Please enter a last name for beneficiary - ${
                  componentIndex + 1
                }`,
              }
            )}
          />
          <FormInputError
            id={`${uuid}-ln-beneficiary-error`}
            errorMessage={
              errors?.beneficiaries?.length
                ? errors?.beneficiaries[componentIndex]?.lastName?.message
                : ""
            }
          />
        </div>

        <div>
          <label htmlFor={`${uuid}-mn-beneficiary`} className="block mb-1">
            Middle Initial
          </label>
          <input
            id={`${uuid}-mn-beneficiary`}
            type="text"
            {...FACStepThreeRegister(
              `beneficiaries[${componentIndex}].middleName`
            )}
          />
          <FormInputError
            id={`${uuid}-mn-beneficiary-error`}
            errorMessage={
              errors?.beneficiaries?.length
                ? errors?.beneficiaries[componentIndex]?.middleName?.message
                : ""
            }
          />
        </div>
      </div>

      <div>
        <label htmlFor={`${uuid}-sa-beneficiary`} className="block mb-1">
          Street Address *
        </label>
        <input
          id={`${uuid}-sa-beneficiary`}
          type="text"
          {...FACStepThreeRegister(
            `beneficiaries[${componentIndex}].streetAddress`
          )}
        />
        <FormInputError
          id={`${uuid}-sa-beneficiary-error`}
          errorMessage={
            errors?.beneficiaries?.length
              ? errors?.beneficiaries[componentIndex]?.streetAddress?.message
              : ""
          }
        />
      </div>

      <div>
        <label htmlFor={`${uuid}-sa2-beneficiary`} className="block mb-1">
          Street Address line 2
        </label>
        <input
          id={`${uuid}-sa2-beneficiary`}
          type="text"
          {...FACStepThreeRegister(
            `beneficiaries[${componentIndex}].streetAddress2`
          )}
        />
        <FormInputError
          id={`${uuid}-sa2-beneficiary-error`}
          errorMessage={
            errors?.beneficiaries?.length
              ? errors?.beneficiaries[componentIndex]?.streetAddress2?.message
              : ""
          }
        />
      </div>
      <div className="grid gap-4 grid-cols-1 md:gap-y-0 md:gap-x-4 md:grid-cols-[1.5fr,1fr,1fr]">
        <div>
          <label htmlFor={`${uuid}-city-beneficiary`} className="block mb-1">
            City
          </label>
          <input
            id={`${uuid}-city-beneficiary`}
            type="text"
            {...FACStepThreeRegister(`beneficiaries[${componentIndex}].city`)}
          />
          <FormInputError
            id={`${uuid}-city-beneficiary-error`}
            errorMessage={
              errors?.beneficiaries?.length
                ? errors?.beneficiaries[componentIndex]?.city?.message
                : ""
            }
          />
        </div>

        <div>
          <label htmlFor={`${uuid}-state-beneficiary`} className="block mb-1">
            State
          </label>
          <input
            id={`${uuid}-state-beneficiary`}
            type="text"
            {...FACStepThreeRegister(`beneficiaries[${componentIndex}].state`)}
          />
          <FormInputError
            id={`${uuid}-state-beneficiary-error`}
            errorMessage={
              errors?.beneficiaries?.length
                ? errors?.beneficiaries[componentIndex]?.state?.message
                : ""
            }
          />
        </div>

        <div>
          <label htmlFor={`${uuid}-zip-beneficiary`} className="block mb-1">
            Zip
          </label>
          <input
            id={`${uuid}-zip-beneficiary`}
            type="text"
            {...FACStepThreeRegister(`beneficiaries[${componentIndex}].zip`)}
          />
          <FormInputError
            id={`${uuid}-zip-beneficiary-error`}
            errorMessage={
              errors?.beneficiaries?.length
                ? errors?.beneficiaries[componentIndex]?.zip?.message
                : ""
            }
          />
        </div>
      </div>

      <div className="grid gap-4 grid-cols-1 md:gap-y-0 md:gap-x-4 md:grid-cols-[1.5fr,1fr,1fr]">
        <div>
          <label htmlFor={`${uuid}-ssn-beneficiary`} className="block mb-1">
            Social security
          </label>
          <input
            id={`${uuid}-ssn-beneficiary`}
            type="text"
            aria-describedby={`${uuid}-ssn-beneficiary-note`}
            {...FACStepThreeRegister(`beneficiaries[${componentIndex}].ssn`, {
              required: `Please enter social security number for beneficiary - ${
                componentIndex + 1
              }`,
            })}
          />
          <FormInputError
            id={`${uuid}-ssn-beneficiary-error`}
            errorMessage={
              errors?.beneficiaries?.length
                ? errors?.beneficiaries[componentIndex]?.ssn?.message
                : ""
            }
          />
          <div
            id={`${uuid}-ssn-beneficiary-note`}
            className="text-xxs text-theme-text-tertiary mt-1"
          >
            Use format: XXX-XX-XXXX
          </div>
        </div>
        <div>
          <label htmlFor={`${uuid}-dob-beneficiary`} className="block mb-1">
            Date of Birth
          </label>
          <input
            id={`${uuid}-dob-beneficiary`}
            type="date"
            {...FACStepThreeRegister(`beneficiaries[${componentIndex}].dob`)}
          />
          <FormInputError
            id={`${uuid}-email-dob-error`}
            errorMessage={
              errors?.beneficiaries?.length
                ? errors?.beneficiaries[componentIndex]?.dob?.message
                : ""
            }
          />
        </div>
      </div>

      <div className="grid gap-4 grid-cols-1 md:gap-y-0 md:gap-x-4 md:grid-cols-[1.5fr,1fr,1fr]">
        <div>
          <label htmlFor={`${uuid}-email-beneficiary`} className="block mb-1">
            Email address
          </label>
          <input
            id={`${uuid}-email-beneficiary`}
            type="text"
            {...FACStepThreeRegister(`beneficiaries[${componentIndex}].email`)}
          />
          <FormInputError
            id={`${uuid}-email-beneficiary-error`}
            errorMessage={
              errors?.beneficiaries?.length
                ? errors?.beneficiaries[componentIndex]?.email?.message
                : ""
            }
          />
        </div>

        <div>
          <label htmlFor={`${uuid}-phone-beneficiary`} className="block mb-1">
            Phone number
          </label>
          <input
            id={`${uuid}-phone-beneficiary`}
            aria-describedby={`${uuid}-phone-beneficiary-note`}
            type="text"
            {...FACStepThreeRegister(`beneficiaries[${componentIndex}].phone`)}
          />
          <div
            id={`${uuid}-phone-beneficiary-note`}
            className="text-xxs text-theme-text-tertiary mt-1"
          >
            Use format: XXX-XX-XXXX
          </div>
          <FormInputError
            id={`${uuid}-phone-beneficiary-error`}
            errorMessage={
              errors?.beneficiaries?.length
                ? errors?.beneficiaries[componentIndex]?.phone?.message
                : ""
            }
          />
        </div>
      </div>

      <div>
        <label
          htmlFor={`${uuid}-relationship-beneficiary`}
          className="block mb-1"
        >
          Relationship to the deceased
        </label>

        <select
          id={`${uuid}-relationship-beneficiary`}
          className="w-auto"
          {...FACStepThreeRegister(
            `beneficiaries[${componentIndex}].relationship`,
            {
              required: `Please select relationship between beneficiary - ${
                componentIndex + 1
              }  and the deceased`,
            }
          )}
        >
          <option value="">
            Please select your relationship with the deceased
          </option>
          <option value="spouse">Spouse</option>
          <option value="child">Child</option>
        </select>

        <FormInputError
          id={`${uuid}-relationship-beneficiary`}
          errorMessage={
            errors?.beneficiaries?.length
              ? errors?.beneficiaries[componentIndex]?.relationship?.message
              : ""
          }
        />
      </div>

      {lastComponent && (
        <div className="flex justify-start">
          <button
            className="ui-btn-secondary mt-4"
            type="button"
            onClick={() => append({ firstName: "" })}
          >
            Add another beneficiary
          </button>
        </div>
      )}
    </div>
  );
}
