import { Dialog } from "@headlessui/react";
import DialogBackdrop from "../../ModalDialogs/DialogBackdrop";
export default function UserAvatarDialog({ state, setState }) {
  return (
    <Dialog open={state} onClose={() => setState(false)} className="ui-dialogs">
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <DialogBackdrop />
      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel
          role="dialog"
          aria-modal="true"
          className="ui-default-dialog"
        >
          <Dialog.Title className="font-body text-xl font-bold text-left">
            Edit Photo
          </Dialog.Title>
          <button
            className="ui-default-dialog-close"
            aria-label="close edit photo window"
            onClick={() => setState(false)}
          >
            <svg
              className="ui-icon h-3.5 w-3.5"
              focusable="false"
              aria-hidden="true"
              role="presentation"
            >
              <use href="#ui-icon-close"></use>
            </svg>
          </button>
          <hr />
          <Dialog.Description>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </Dialog.Description>

          <div className="text-xl my">
            <p>placeholder for possible photo editing tool</p>
          </div>

          <div className="block text-right mt-6">
            <button
              className="ui-btn-secondary ui-btn-min-w mt-4"
              onClick={() => setState(false)}
            >
              Replace
              <span className="sr-only"> your account photo </span>
            </button>
            <button
              id="ID_applyFilterOptions"
              className="ui-btn-primary ui-btn-min-w mt-4"
              onClick={() => setState(false)}
            >
              Save
              <span className="sr-only"> your account photo</span>
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
