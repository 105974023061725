export const loansData = {
  current: [
    {
      title: "Whole Life - George",
      number: "384828283",
      amount: "XX,XXX",
      type: "type",
      startDate: "01/01/2023",
      interestRate: "0.00",
      balance: "XX,XXX",
    },
    {
      title: "Term Life - Sarah",
      number: "384828283",
      amount: "XX,XXX",
      type: "type",
      startDate: "01/01/2023",
      interestRate: "0.00",
      balance: "XX,XXX",
    },
  ],
  eligible: [
    {
      title: "Whole Life - Sarah",
      number: "384828283",
      maxLoanAmount: "XX,XXX",
      interestRate: "0.02",
      paymentAmount: "XXX.XX",
      startDate: "01/01/2023",
      duration: "36 months",
    },
    {
      title: "Whole Life - Sarah",
      number: "384828283",
      maxLoanAmount: "XX,XXX",
      interestRate: "0.02",
      paymentAmount: "XXX.XX",
      startDate: "01/01/2023",
      duration: "36 months",
    },
  ],
};