export const AgentData = [
  {
    id: 1,
    month: "March 2022 - 1",
    statementDate: "01/01/2023",
    commissionAmount: "$XXXX.XX",
    chargebacks: "$XX.XX",
  },
  {
    id: 2,
    month: "February 2022 - 2",
    statementDate: "01/01/2023",
    commissionAmount: "$XXXX.XX",
    chargebacks: "$XX.XX",
  },
  {
    id: 3,
    month: "<Month YYYY> - 3",
    statementDate: "01/01/2023",
    commissionAmount: "$XXXX.XX",
    chargebacks: "$XX.XX",
  },
  {
    id: 4,
    month: "<Month YYYY> - 4",
    statementDate: "01/01/2023",
    commissionAmount: "$XXXX.XX",
    chargebacks: "$XX.XX",
  },
  {
    id: 5,
    month: "<Month YYYY> - 5",
    statementDate: "01/01/2023",
    commissionAmount: "$XXXX.XX",
    chargebacks: "$XX.XX",
  },
  {
    id: 6,
    month: "<Month YYYY> - 6",
    statementDate: "01/01/2023",
    commissionAmount: "$XXXX.XX",
    chargebacks: "$XX.XX",
  },
  {
    id: 7,
    month: "<Month YYYY> - 7",
    statementDate: "01/01/2023",
    commissionAmount: "$XXXX.XX",
    chargebacks: "$XX.XX",
  },
  {
    id: 8,
    month: "<Month YYYY> - 8",
    statementDate: "01/01/2023",
    commissionAmount: "$XXXX.XX",
    chargebacks: "$XX.XX",
  },
  {
    id: 9,
    month: "<Month YYYY> - 9",
    statementDate: "01/01/2023",
    commissionAmount: "$XXXX.XX",
    chargebacks: "$XX.XX",
  },
  {
    id: 10,
    month: "<Month YYYY> - 10",
    statementDate: "01/01/2023",
    commissionAmount: "$XXXX.XX",
    chargebacks: "$XX.XX",
  },
  {
    id: 11,
    month: "<Month YYYY> - 11",
    statementDate: "01/01/2023",
    commissionAmount: "$XXXX.XX",
    chargebacks: "$XX.XX",
  },
  {
    id: 12,
    month: "<Month YYYY> - 12",
    statementDate: "01/01/2023",
    commissionAmount: "$XXXX.XX",
    chargebacks: "$XX.XX",
  },
  {
    id: 13,
    month: "<Month YYYY> - 13",
    statementDate: "01/01/2023",
    commissionAmount: "$XXXX.XX",
    chargebacks: "$XX.XX",
  },
  {
    id: 14,
    month: "<Month YYYY> - 14",
    statementDate: "01/01/2023",
    commissionAmount: "$XXXX.XX",
    chargebacks: "$XX.XX",
  },
  {
    id: 15,
    month: "<Month YYYY> - 15",
    statementDate: "01/01/2023",
    commissionAmount: "$XXXX.XX",
    chargebacks: "$XX.XX",
  },
  {
    id: 16,
    month: "<Month YYYY> - 16",
    statementDate: "01/01/2023",
    commissionAmount: "$XXXX.XX",
    chargebacks: "$XX.XX",
  },
  {
    id: 17,
    month: "<Month YYYY> - 17",
    statementDate: "01/01/2023",
    commissionAmount: "$XXXX.XX",
    chargebacks: "$XX.XX",
  },
  {
    id: 18,
    month: "Dec 2022 - 18",
    statementDate: "01/01/2023",
    commissionAmount: "$XXXX.XX",
    chargebacks: "$XX.XX",
  },
  {
    id: 19,
    month: "Dec 2022 - 19",
    statementDate: "01/01/2023",
    commissionAmount: "$XXXX.XX",
    chargebacks: "$XX.XX",
  },
];

export const commissionTotals = [
  {
    title: "YTD earnings",
    earnedCommissions: "$12,302",
    chargeBacks: "$221",
    additionalText:
      "Additional text for disclaimers or context goes here. Lorem ipsum",
    explanationText: null,
  },
  {
    title: "Previous month earnings",
    earnedCommissions: "$4,124",
    chargeBacks: "$52",
    additionalText:
      "Additional text for disclaimers or context goes here. Lorem ipsum",
    explanationText: null,
  },
  {
    title: "Projected earnings for this month*",
    earnedCommissions: "$4,863",
    chargeBacks: "$73",
    additionalText:
      "Additional text for disclaimers or context goes here. Lorem ipsum",
    explanationText: "text explaining how projections work",
  },
];
