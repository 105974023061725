import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import DialogBackdrop from "./DialogBackdrop";

const ConfirmModal = ({ modalState, setModalState, unauthUser = false }) => {
  const navigate = useNavigate();

  return (
    <Transition appear show={modalState} as={Fragment}>
      <Dialog
        as="div"
        className="fixed inset-0 z-10 overflow-y-auto"
        open={modalState}
        onClose={() => setModalState(false)}
      >
        <DialogBackdrop />
        <div className="min-h-screen px-4 text-center">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="inline-block w-full max-w-5xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
              <Dialog.Title
                as="h2"
                className="dialog_label2 font-body text-xl font-bold text-left"
              >
                Thank you for your payment
                <button
                  className="ui-default-dialog-close"
                  onClick={() => setModalState(false)}
                >
                  <svg
                    className="ui-icon h-3.5 w-3.5"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-close"></use>
                  </svg>
                </button>
              </Dialog.Title>
              <hr />
              <div className="mx-auto lg:w-4/5">
                <p id="ID_dialogPaymentThankDesc">
                  <strong>
                    It will take X-X business days to process this request. You
                    will see the payment reflected as immediately as
                    “processing” in your policies. Here is your service request
                    ID # for tracking the payment:
                  </strong>
                </p>
                <div className="w-full text-lg font-semibold text-ui-blue mb-6 text-center">
                  1DF2321
                </div>
                <p>
                  If you have opted in to email or text notifications you can
                  find this tracking number there. You can also find this
                  service request any time on the Service & Claims landing page
                  on the customer portal.
                </p>
              </div>
              <div className="block text-right mt-6">
                {!unauthUser && (
                  <button
                    id="ID_OptInNotifications"
                    className="ui-btn-secondary min-w-[140px] mt-4"
                    aria-label="Opt in to notifications"
                    onClick={() => setModalState(false)}
                  >
                    Opt in to notifications
                  </button>
                )}
                <button
                  className="ui-btn-primary min-w-[140px]  mt-4"
                  aria-label="Go to my policies now"
                  onClick={() => {
                    setModalState(false);
                    if (unauthUser) navigate("/");
                  }}
                >
                  {unauthUser ? "Close" : "Go to my policies now"}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default ConfirmModal;
