import { useEffect, useState } from "react";
export const useRowPagination = (data) => {
  const perPageSelection = [6, 12, 24, 48, "all"];

  // state hooks for controls
  const [currentRows, setCurrentRows] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPerPage, setCurrentPerPage] = useState(perPageSelection[0]);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    // reset current page so that it goes to first page
    setCurrentPage(1);
    // for setting up total pages and the dynamic displaying text
    setTotalPages(Math.ceil(data.length / currentPerPage));
  }, [currentPerPage]);

  // effect hook for dynamic rendering rows on page and return rows for display
  useEffect(() => {
    setCurrentRows(() => {
      if (currentPage === 1) return data.slice(0, currentPerPage);

      return data.slice(
        currentPage * currentPerPage - currentPerPage,
        currentPage * currentPerPage
      );
    });
  }, [currentPage, currentPerPage]);

  const first = () => {
    setCurrentPage(1);
  };
  const last = () => {
    setCurrentPage(totalPages);
  };
  const next = () => {
    if (currentPage + 1 <= totalPages) setCurrentPage(currentPage + 1);
  };
  const back = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };

  return {
    rowsForDisplay: currentRows,
    rowPaginationProps: {
      data,
      perPageSelection,
      first,
      last,
      next,
      back,
      currentPage,
      currentPerPage,
      setCurrentPerPage,
      totalPages,
    },
  };
};
