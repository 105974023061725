import Badge from "../../../General/Badge";
import Tooltip from "../../../General/Tooltip";
import { useCollapsePanel } from "../../../../hooks/useCollapsePanel";
import DownloadDocumentTile from "../../../General/DownloadDocumentTile";
import { v4 as uuidv4 } from "uuid";

const CoverageAndDocuments = ({
  policy,
  policyDetail,
  isOpenSendReminder,
  setIsOpenSendReminder,
}) => {
  const uuid = uuidv4();
  useCollapsePanel(uuid);

  return (
    <>
      <div className="ui-card">
        <div className="p-4 xl:pt-6 xl:px-6">
          <div className="flex flex-col md:flex-row md:justify-between md:items-center">
            <h2 className="mb-0">
              <button
                id={uuid}
                className="mb-0 flex items-center group md:hidden"
                aria-controls={`${uuid}-panel`}
                aria-expanded="true"
                aria-label="Expand or collapse coverage &amp; documents details"
              >
                <span className="inline-block p-1 rounded-full mr-1 text-ui-blue group-hover:text-white group-hover:bg-ui-blue">
                  <svg
                    className="ui-icon ui-plus w-4 h-4"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-plus"></use>
                  </svg>
                  <svg
                    className="ui-icon ui-minus w-4 h-4"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-minus"></use>
                  </svg>
                </span>
                {policy.badgeStatus && (
                  <span className="mr-1 flex items-center md:hidden">
                    <Badge
                      iconOnly={true}
                      status={policy.badgeStatus}
                      statusText={policy.badgeText}
                      srPrefix="Policy"
                    />
                  </span>
                )}
                <span className="text-base font-body font-bold md:ui-no-badge-icon-inline">
                  Coverage &amp; Documents
                </span>
              </button>
              <span className="text-xl font-body font-bold hidden md:block">
                Coverage &amp; Documents
              </span>
            </h2>
            <div>
              <a href="/" className="text-xs">
                Quote additional benefits
              </a>
            </div>
          </div>
        </div>
        <hr className="ui-mobile-collapse" />
        <div
          id={`${uuid}-panel`}
          className="overflow-hidden h-20 p-4 md:h-auto xl:p-6"
        >
          {policy.badgeStatus && (
            <div className="hidden md:block">
              <Badge
                status={policy.badgeStatus}
                statusText={policy.badgeText}
                srPrefix={policy.BadgeSrText}
                srPrefix="Policy"
              />
            </div>
          )}
          <div className="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:gap-6 xl:mt-6">
            <div>
              <div className="text-xs text-theme-text-tertiary">
                Policy number
              </div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {policy.policyNumber}
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">Coverage</div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {policy.coverage}
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">
                Product name
                <Tooltip
                  srText="Tooltip for product name"
                  content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam sed ullamcorper felis. Proin aliquam sem et mattis pulvinar."
                  xMargin="m-px"
                />
              </div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {policy.productName}
                <div className="text-xxs text-theme-text-tertiary">
                  {policy.term}
                </div>
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">Issue date</div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {policy.startDate}
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">
                Coverage end date
              </div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                <div></div>
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">Title</div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                Copy
              </div>
            </div>
          </div>

          {/* <!-- Policy Documents --> */}
          <div className="font-semibold mt-6 mb-2">Policy Documents</div>
          <div className="ui-download-document">
            {policyDetail.policy_detail.documents.map((document) => {
              return <DownloadDocumentTile data={document} key={document.id} />;
            })}
          </div>
          {/* <!-- Policy Documents --> */}

          <div className="mt-4 text-right xl:mt-6">
            <button
              className="ui-btn-primary ui-btn-min-w"
              onClick={() => setIsOpenSendReminder(true)}
            >
              Notify George
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default CoverageAndDocuments;
