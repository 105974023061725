import { Tab } from "@headlessui/react";
import PaymentRow from "./PaymentRow";
import Pagination from "../../General/Pagination";
import { usePagination } from "../../../hooks/usePagination";

export default function ScheduledPaymentsTab({ data }) {
  const { current, paginationProps } = usePagination(data, 5);

  return (
    <Tab.Panel>
      {current.map((a, idx) => {
        return (
          <PaymentRow
            key={`payment row for payment - ${a.id}`}
            data={a}
            type="scheduled"
          />
        );
      })}

      {/* <!-- paging component --> */}
      <div className="flex justify-between items-start mt-6">
        <div></div>
        <Pagination {...paginationProps} />
      </div>
      {/* <!-- paging component --> */}
    </Tab.Panel>
  );
}
