import CurrentLoansCard from "./PolicyCards/CurrentLoansCard";
import EligibleLoansCard from "./PolicyCards/EligibleLoansCard";

const Loans = ({ loansData }) => {
  return (
    <>
      <div className="ui-component-spacing">
        <h3 className="font-brand text-lg md:text-2xl">Current loans</h3>
        <hr className="ui-under-title" />
        <div>
          {loansData.current.map((item, idx) => (
            <CurrentLoansCard key={idx} data={item} />
          ))}
        </div>
      </div>
      <div className="ui-component-spacing">
        <h3 className="font-brand text-lg md:text-2xl">Eligible loans</h3>
        <hr className="ui-under-title" />

        <div className="">
          {loansData.eligible.map((item, idx) => (
            <EligibleLoansCard data={item} key={`eligible loans - ${idx}`} />
          ))}
        </div>
      </div>
    </>
  );
};

export default Loans;
