import Badge from "../../../General/Badge";
import Tooltip from "../../../General/Tooltip";
import { v4 as uuidv4 } from "uuid";
import { useCollapsePanel } from "../../../../hooks/useCollapsePanel";

const FinancialsCard = ({ policy, policyDetail }) => {
  const uuid = uuidv4();
  useCollapsePanel(uuid);
  return (
    <>
      <div className="ui-card">
        <div className="p-4 xl:pt-6 xl:px-6">
          <div className="flex flex-col md:flex-row md:justify-between md:items-center">
            <h2 className="mb-0">
              <button
                id={uuid}
                className="flex items-center group md:hidden"
                aria-controls={`${uuid}-panel`}
                aria-expanded="true"
                aria-label="Expand or collapse Financials details"
              >
                <span className="inline-block p-1 rounded-full mr-1 text-ui-blue group-hover:text-white group-hover:bg-ui-blue">
                  <svg
                    className="ui-icon ui-plus w-4 h-4"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-plus"></use>
                  </svg>
                  <svg
                    className="ui-icon ui-minus w-4 h-4"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-minus"></use>
                  </svg>
                </span>
                {policy.badgeStatus && (
                  <span className="mr-1 flex items-center md:hidden">
                    <Badge
                      iconOnly={true}
                      status={policy.badgeStatus}
                      statusText={policy.badgeText}
                      srPrefix="Policy"
                    />
                  </span>
                )}
                <span className="text-base font-body font-bold md:ui-no-badge-icon-inline">
                  Financials
                </span>
              </button>
              <span className="text-xl font-body font-bold hidden md:block">
                Financials
              </span>
            </h2>
          </div>
        </div>
        <hr className="ui-mobile-collapse" />
        <div
          id={`${uuid}-panel`}
          className="overflow-hidden h-20 p-4 md:h-auto xl:p-6"
        >
          {policy.badgeStatus && (
            <div className="hidden md:block">
              <Badge
                status={policy.badgeStatus}
                statusText={policy.badgeText}
                srPrefix={policy.BadgeSrText}
                srPrefix="Policy"
              />
            </div>
          )}

          <div className="grid grid-cols-1 gap-4 mt-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:gap-6 xl:mt-6">
            <div>
              <div className="text-xs text-theme-text-tertiary">
                Face amount
              </div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {policy.faceAmount}
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">
                Death benefit
              </div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {policy.deathBenefit}
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">
                NF option
                <Tooltip
                  srText="Tooltip for NF option"
                  content="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam sed ullamcorper felis. Proin aliquam sem et mattis pulvinar."
                  xMargin="m-px"
                />
              </div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {policy.nfOption}
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">
                Account value
              </div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {policy.accountValue}
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">
                surrender charge
              </div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {policy.surrenderCharge}
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">
                Surrender value
              </div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {policy.surrenderValue}
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">
                Billing method
              </div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {policy.billingMethod}
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">Draft date</div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {policy.draftDate}
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">Mode</div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {policy.mode}
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">
                Modal premium
              </div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {policy.modalPremium}
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">
                Another financial field
              </div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {policy.modalPremium}
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">
                Another financial field
              </div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {policy.modalPremium}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FinancialsCard;
