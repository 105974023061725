export const claims = [
  {
    id: 9281837131,
    banner: "risk",
    badgeStatus: "risk",
    badgeSrText: "Claim",
    badgeText: "Processing",
    policyNumber: "5723451",
    owner: "George Dawson",
    type: "Death Claim",
    dateSubmitted: "01/01/2023",
    step: 2,
    documents: [
      {
        badgeStatus: "good",
        badgeSrText: "Document",
        badgeText: "",
        name: "First Notice Of Loss",
        action: "View",
        path: "https://developer.adobe.com/document-services/static/AdobeInDesign-7e9e28ec01deaf8bb1c5f368c476162a.pdf",
      },
      {
        badgeStatus: "good",
        badgeSrText: "Document",
        badgeText: "",
        name: "Claimant Form",
        action: "View",
        path: "https://developer.adobe.com/document-services/static/AdobeInDesign-7e9e28ec01deaf8bb1c5f368c476162a.pdf",
      },
      {
        badgeStatus: "risk",
        badgeSrText: "Document",
        badgeText: "",
        name: "Claimant Form",
        action: "Update",
        path: "https://developer.adobe.com/document-services/static/AdobeInDesign-7e9e28ec01deaf8bb1c5f368c476162a.pdf",
      },
      {
        badgeStatus: "good",
        badgeSrText: "Document",
        badgeText: "",
        name: "Another Required Form",
        action: "View",
        path: "https://developer.adobe.com/document-services/static/AdobeInDesign-7e9e28ec01deaf8bb1c5f368c476162a.pdf",
      },
      {
        badgeStatus: "problem",
        badgeSrText: "Document",
        badgeText: "",
        name: "Another Required Form",
        action: "Update",
        path: "https://developer.adobe.com/document-services/static/AdobeInDesign-7e9e28ec01deaf8bb1c5f368c476162a.pdf",
      },
    ],
  },
  {
    id: 9281837132,
    banner: "problem",
    badgeStatus: "problem",
    badgeSrText: "Claim",
    badgeText: "Pending receipt of required forms",
    policyNumber: "5723452",
    owner: "Christian Blake",
    type: "Death Claim",
    dateSubmitted: "01/01/2023",
    step: 1,
    documents: [
      {
        badgeStatus: "good",
        badgeSrText: "Document",
        badgeText: "",
        name: "First Notice Of Loss",
        action: "View",
        path: "https://developer.adobe.com/document-services/static/AdobeInDesign-7e9e28ec01deaf8bb1c5f368c476162a.pdf",
      },
      {
        badgeStatus: "problem",
        badgeSrText: "Document",
        badgeText: "",
        name: "Claimant Form",
        action: "Update",
        path: "https://developer.adobe.com/document-services/static/AdobeInDesign-7e9e28ec01deaf8bb1c5f368c476162a.pdf",
      },
      {
        badgeStatus: "good",
        badgeSrText: "Document",
        badgeText: "",
        name: "Claimant Form",
        action: "View",
        path: "https://developer.adobe.com/document-services/static/AdobeInDesign-7e9e28ec01deaf8bb1c5f368c476162a.pdf",
      },
      {
        badgeStatus: "good",
        badgeSrText: "Document",
        badgeText: "",
        name: "Another Required Form",
        action: "View",
        path: "https://developer.adobe.com/document-services/static/AdobeInDesign-7e9e28ec01deaf8bb1c5f368c476162a.pdf",
      },
      {
        badgeStatus: "good",
        badgeSrText: "Document",
        badgeText: "",
        name: "Another Required Form",
        action: "View",
        path: "https://developer.adobe.com/document-services/static/AdobeInDesign-7e9e28ec01deaf8bb1c5f368c476162a.pdf",
      },
    ],
  },
  {
    id: 9281837132,
    banner: "problem",
    badgeStatus: "problem",
    badgeSrText: "Claim",
    badgeText: "Pending receipt of required forms",
    policyNumber: "5723452",
    owner: "Christian Blake",
    type: "Death Claim",
    dateSubmitted: "01/01/2023",
    step: 1,
    documents: [
      {
        badgeStatus: "good",
        badgeSrText: "Document",
        badgeText: "",
        name: "First Notice Of Loss",
        action: "View",
        path: "https://developer.adobe.com/document-services/static/AdobeInDesign-7e9e28ec01deaf8bb1c5f368c476162a.pdf",
      },
      {
        badgeStatus: "problem",
        badgeSrText: "Document",
        badgeText: "",
        name: "Claimant Form",
        action: "Update",
        path: "https://developer.adobe.com/document-services/static/AdobeInDesign-7e9e28ec01deaf8bb1c5f368c476162a.pdf",
      },
      {
        badgeStatus: "good",
        badgeSrText: "Document",
        badgeText: "",
        name: "Claimant Form",
        action: "View",
        path: "https://developer.adobe.com/document-services/static/AdobeInDesign-7e9e28ec01deaf8bb1c5f368c476162a.pdf",
      },
      {
        badgeStatus: "good",
        badgeSrText: "Document",
        badgeText: "",
        name: "Another Required Form",
        action: "View",
        path: "https://developer.adobe.com/document-services/static/AdobeInDesign-7e9e28ec01deaf8bb1c5f368c476162a.pdf",
      },
      {
        badgeStatus: "good",
        badgeSrText: "Document",
        badgeText: "",
        name: "Another Required Form",
        action: "View",
        path: "https://developer.adobe.com/document-services/static/AdobeInDesign-7e9e28ec01deaf8bb1c5f368c476162a.pdf",
      },
    ],
  },
  {
    id: 9281837132,
    banner: "problem",
    badgeStatus: "problem",
    badgeSrText: "Claim",
    badgeText: "Pending receipt of required forms",
    policyNumber: "5723452",
    owner: "Christian Blake",
    type: "Death Claim",
    dateSubmitted: "01/01/2023",
    step: 1,
    documents: [
      {
        badgeStatus: "good",
        badgeSrText: "Document",
        badgeText: "",
        name: "First Notice Of Loss",
        action: "View",
        path: "https://developer.adobe.com/document-services/static/AdobeInDesign-7e9e28ec01deaf8bb1c5f368c476162a.pdf",
      },
      {
        badgeStatus: "problem",
        badgeSrText: "Document",
        badgeText: "",
        name: "Claimant Form",
        action: "Update",
        path: "https://developer.adobe.com/document-services/static/AdobeInDesign-7e9e28ec01deaf8bb1c5f368c476162a.pdf",
      },
      {
        badgeStatus: "good",
        badgeSrText: "Document",
        badgeText: "",
        name: "Claimant Form",
        action: "View",
        path: "https://developer.adobe.com/document-services/static/AdobeInDesign-7e9e28ec01deaf8bb1c5f368c476162a.pdf",
      },
      {
        badgeStatus: "good",
        badgeSrText: "Document",
        badgeText: "",
        name: "Another Required Form",
        action: "View",
        path: "https://developer.adobe.com/document-services/static/AdobeInDesign-7e9e28ec01deaf8bb1c5f368c476162a.pdf",
      },
      {
        badgeStatus: "good",
        badgeSrText: "Document",
        badgeText: "",
        name: "Another Required Form",
        action: "View",
        path: "https://developer.adobe.com/document-services/static/AdobeInDesign-7e9e28ec01deaf8bb1c5f368c476162a.pdf",
      },
    ],
  },
];

export const serviceRequests = [
  {
    id: 9281837141,
    banner: "problem",
    badgeStatus: "problem",
    badgeSrText: "Service request",
    badgeText: "Documentation required",
    policyNumber: "5723421",
    owner: "George Dawson",
    type: "Death Claim",
    dateSubmitted: "01/01/2023",
    expectedComplete: "01/01/2023",
    originalInsured: "Sarah Jamesson",
    newInsured: "Sarah Toissant",
    reason: "reason",
    other: "data",
    documents: [
      {
        id: 1,
        name: "Policy pages",
        location: "#",
        desc: "Download PDF",
      },
      {
        id: 2,
        name: "Required document",
        location: "#",
        desc: "Download PDF",
      },
    ],
  },
  {
    id: 9281837142,
    banner: "risk",
    badgeStatus: "risk",
    badgeSrText: "Service request",
    badgeText: "Processing",
    policyNumber: "5723453",
    owner: "George Dawson",
    type: "Death Claim",
    dateSubmitted: "01/01/2023",
    expectedComplete: "01/01/2023",
    originalInsured: "Sarah Jamesson",
    newInsured: "Sarah Toissant",
    reason: "reason",
    other: "data",
    documents: [
      {
        id: 1,
        name: "Policy pages",
        location: "#",
        desc: "Download PDF",
      },
      {
        id: 2,
        name: "Required document",
        location: "#",
        desc: "Download PDF",
      },
    ],
  },
];
