import { v4 as uuid } from "uuid";

export default function NotificationSelection({ register, mainType, subType }) {
  const UUID = uuid();
  return subType !== "none" ? (
    <label className="ui-checkbox relative place-self-center p-1">
      <input
        id={`${UUID}-${mainType}-${subType}`}
        type="checkbox"
        {...register(`${mainType}-${subType}`)}
      />
      <span className="sr-only">notify by {subType}</span>
      <div className="ui-checkmark mx-2">
        <svg
          className="ui-icon w-2 h-2 text-white"
          focusable="false"
          aria-hidden="true"
          role="presentation"
        >
          <use href="#ui-icon-checkmark"></use>
        </svg>
      </div>
    </label>
  ) : (
    <div className="ui-checkbox relative place-self-center p-1">
      <div className="w-3.5 h-3.5 rounded border mx-2 border-ui-gray-medium bg-ui-gray-light"></div>
    </div>
  );
}
