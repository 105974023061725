export default function RowPagination({
  data,
  perPageSelection,
  first,
  last,
  next,
  back,
  currentPage,
  currentPerPage,
  setCurrentPerPage,
  totalPages,
}) {
  return (
    <div className="mt-4 flex flex-col text-sm justify-start md:flex-row md:justify-center md:space-x-6">
      <div className="flex flex-grow flex-row flex-wrap items-center my-2 space-x-2 md:justify-center">
        {totalPages > 1 && (
          <button
            onClick={first}
            disabled={currentPage === 1}
            className="ui-btn-hyperlink font-semibold text-sm px-1 flex items-center no-underline hover:underline"
          >
            <svg
              className="ui-icon h-2.5 w-2.5 mr-1"
              focusable="false"
              aria-hidden="true"
              role="presentation"
            >
              <use href="#ui-icon-paging-first"></use>
            </svg>
            First
          </button>
        )}
        {totalPages > 1 && (
          <button
            onClick={back}
            disabled={currentPage === 1}
            className="ui-btn-hyperlink font-semibold text-sm px-1 flex items-center no-underline hover:underline"
          >
            <svg
              className="ui-icon h-2.5 w-2.5 mr-1"
              focusable="false"
              aria-hidden="true"
              role="presentation"
            >
              <use href="#ui-icon-paging-previous"></use>
            </svg>
            Back
          </button>
        )}
        <div className="md:px-4">
          Showing{" "}
          {`${
            currentPage === 1
              ? data.length > 0
                ? "1"
                : "0"
              : currentPage * currentPerPage - (currentPerPage - 1)
          } - ${
            currentPage === 1
              ? currentPerPage > data.length
                ? data.length
                : currentPerPage
              : currentPage * currentPerPage > data.length
              ? data.length
              : currentPage * currentPerPage
          } of ${data.length}`}
        </div>
        {totalPages > 1 && (
          <button
            onClick={next}
            disabled={currentPage === totalPages}
            className="ui-btn-hyperlink font-semibold text-sm px-1 flex items-center no-underline hover:underline"
          >
            Next
            <svg
              className="ui-icon h-2.5 w-2.5 ml-1"
              focusable="false"
              aria-hidden="true"
              role="presentation"
            >
              <use href="#ui-icon-paging-next"></use>
            </svg>
          </button>
        )}
        {totalPages > 1 && (
          <button
            onClick={last}
            className="ui-btn-hyperlink font-semibold text-sm px-1 flex items-center no-underline hover:underline"
            disabled={currentPage === totalPages}
          >
            Last
            <svg
              className="ui-icon h-2.5 w-2.5 ml-1"
              focusable="false"
              aria-hidden="true"
              role="presentation"
            >
              <use href="#ui-icon-paging-last"></use>
            </svg>
          </button>
        )}
      </div>
      <div className="flex flex-row flex-wrap items-center my-4 space-x-2">
        <div>
          <label htmlFor="UUID" className="text-sm">
            Per page
          </label>
        </div>
        <div>
          <select
            id="UUID"
            className="text-xs py-1 px-2 min-w-20 md:min-w-max"
            onChange={(e) => setCurrentPerPage(parseInt(e.target.value))}
          >
            {perPageSelection.map((v, i) => {
              return (
                <option
                  key={`per page selection - ${i}`}
                  value={v === "all" ? 9999 : v}
                >
                  {v}
                </option>
              );
            })}
          </select>
        </div>
      </div>
    </div>
  );
}
