import { useState } from "react";
import { useForm } from "react-hook-form";
import { v4 as uuid } from "uuid";
import { Link } from "react-router-dom";
import FormInputError from "../../General/FormInputError";

export default function AgentForgotUsername() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    shouldUnregister: false,
  });

  const UUID = uuid();
  const [step, setStep] = useState(1);

  const onSubmit = (data) => {
    if (step === 1) return setStep(2);
    if (step === 2) {
      alert(JSON.stringify(data));
      return setStep(3);
    }
  };

  return (
    <>
      {step === 1 && (
        <div className="container ui-component-spacing">
          <div className="ui-card p-4 mx-auto  lg:w-3/4 md:p-6">
            <h2>Let's find your account</h2>
            <p className="text-sm">
              Please enter owner verification information
            </p>

            <form onSubmit={handleSubmit(onSubmit)}>
              <fieldset className="mt-1">
                <div className="inline-block mr-6">
                  <label className="inline-flex items-center text-sm font-normal">
                    <input
                      type="radio"
                      value="agent"
                      className="mr-2"
                      {...register("accountType", {
                        required: "Please select your account type",
                      })}
                    />
                    Agent
                  </label>
                </div>
                <div className="inline-block mr-6">
                  <label className="inline-flex items-center text-sm font-normal">
                    <input
                      type="radio"
                      value="agent-manager"
                      className="mr-2"
                      {...register("accountType", {
                        required: "Please select your account type",
                      })}
                    />
                    Agent manager
                  </label>
                </div>
                <div className="inline-block mr-6">
                  <label className="inline-flex items-center text-sm font-normal">
                    <input
                      type="radio"
                      value="Agent-administrative-assistant"
                      className="mr-2"
                      {...register("accountType", {
                        required: "Please select your account type",
                      })}
                    />
                    Agent administrative assistant
                  </label>
                </div>

                {errors.accountType && (
                  <FormInputError
                    id={`${UUID}-accountType-error`}
                    errorMessage={errors.accountType.message}
                  />
                )}
              </fieldset>

              <hr />

              <p className="mb-6 font-semibold text-base">
                You will need your agent ID number (an active one) and your
                Social Security Number, telephone number and zip code to find
                your for an account.
              </p>

              <div>
                <label
                  id={`${UUID}-agent-portal-id`}
                  className="text-base font-semibold"
                >
                  Agent ID #:
                </label>
                <p className="text-xs mb-1">
                  This is your Everlake Agent ID.
                </p>
                <input
                  id={`${UUID}-portalId`}
                  className="w-full"
                  type="text"
                  placeholder="#"
                  {...register("portalId", {
                    required: {
                      value: true,
                      message: "Please enter your protal ID",
                    },
                  })}
                  aria-describedby={`${UUID}-portalId-error`}
                  aria-labelledby={`${UUID}-agent-portal-id`}
                />

                {errors.portalId && (
                  <FormInputError
                    id={`${UUID}-portalId-error`}
                    errorMessage={errors.portalId.message}
                  />
                )}
              </div>

              <hr />

              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
                <div>
                  <label id={`${UUID}-firstname-label`}>First Name *</label>
                  <input
                    id={`${UUID}-firstname`}
                    className="w-full"
                    type="text"
                    {...register("firstname", {
                      required: "Please enter your first name",
                    })}
                    aria-describedby={`${UUID}-firstname-error`}
                    aria-labelledby={`${UUID}-firstname-label`}
                  />
                  {errors.firstname && (
                    <FormInputError
                      id={`${UUID}-firstname-error`}
                      errorMessage={errors.firstname.message}
                    />
                  )}
                </div>

                <div>
                  <label id={`${UUID}-lastname-label`}>Last Name *</label>
                  <input
                    id={`${UUID}-lastname`}
                    className="w-full"
                    type="text"
                    {...register("lastname", {
                      required: "please enter your last name",
                    })}
                    aria-describedby={`${UUID}-lastname-error`}
                    aria-labelledby={`${UUID}-lastname-label`}
                  />
                  {errors.lastname && (
                    <FormInputError
                      id={`${UUID}-lastname-error`}
                      errorMessage={errors.lastname.message}
                    />
                  )}
                </div>

                <div>
                  <label id={`${UUID}-mi-label`}>Middle Initial</label>
                  <input
                    id={`${UUID}-mi`}
                    className="w-full"
                    type="text"
                    {...register("middleInitial")}
                    aria-labelledby={`${UUID}-mi-label`}
                  />
                </div>

                <div>
                  <label id={`${UUID}-ssn-label`}>
                    Social Security Number *
                  </label>
                  <input
                    id={`${UUID}-ssn`}
                    className="w-full"
                    type="text"
                    placeholder="XXX-XX-XXXX"
                    {...register("ssn", {
                      required: "Please enter your ssn",
                    })}
                    aria-labelledby={`${UUID}-ssn-label`}
                    aria-describedby={`${UUID}-ssn-error`}
                  />

                  {errors.ssn && (
                    <FormInputError
                      id={`${UUID}-ssn-error`}
                      errorMessage={errors.ssn.message}
                    />
                  )}
                  <span id={`${UUID}-ssn-format`} className="sr-only">
                    Enter as XXX-XX-XXXX
                  </span>
                </div>

                <div>
                  <label id={`${UUID}-zipcode-label`}>Zip Code *</label>
                  <input
                    id={`${UUID}-zipcode`}
                    className="w-full"
                    type="text"
                    {...register("zipCode", {
                      required: "Please enter your zip code",
                    })}
                    aria-describedby={`${UUID}-zipcode-error`}
                    aria-labelledby={`${UUID}-zipcode-label`}
                  />
                  {errors.zipCode && (
                    <FormInputError
                      id={`${UUID}-zipcode-error`}
                      errorMessage={errors.zipCode.message}
                    />
                  )}
                </div>

                <div>
                  <label id={`${UUID}-phone-label`}>
                    Mobile Phone Number *
                  </label>
                  <input
                    id={`${UUID}-phone`}
                    className="w-full"
                    type="text"
                    placeholder="(XXX) XXX-XXXX"
                    aria-describedby={`${UUID}-phone-format ${UUID}-phone-error`}
                    aria-labelledby={`${UUID}-phone-label`}
                    {...register("phoneNumber", {
                      required: "Please enter your phone number",
                    })}
                  />
                  {errors.phoneNumber && (
                    <FormInputError
                      id={`${UUID}-phone-error`}
                      errorMessage={errors.phoneNumber.message}
                    />
                  )}
                  <span id={`${UUID}-phone-format`} className="sr-only">
                    Enter as (XXX) XXX-XXXX
                  </span>
                </div>
              </div>

              <div className="mt-4 text-right">
                <button className="ui-btn-primary ui-btn-min-w mt-2">
                  Continue <span className="onto find username step 2"></span>
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {step === 2 && (
        <div className="container ui-component-spacing">
          <div className="ui-card p-4 mx-auto  lg:w-3/4 md:p-6">
            <h2>We found your account</h2>
            <p className="text-sm">
              Please check your email for a one-time code to reset your
              password. This code is valid for {"<#>"} minutes.
            </p>

            <form onSubmit={handleSubmit(onSubmit)} className="mt-6">
              <div>
                <label
                  id={`${UUID}-pw-resetcode-label`}
                  className="text-base font-semibold"
                >
                  Password reset code
                </label>

                <input
                  id={`${UUID}-pw-resetcode`}
                  className="w-full"
                  type="text"
                  placeholder="Enter password reset code found in your email"
                  {...register("resetCode", {
                    required: "Please enter your password reset code",
                  })}
                  aria-describedby={`${UUID}-pw-resetcode-error`}
                  aria-labelledby={`${UUID}-agent-pw-resetcode-label`}
                />

                {errors.resetCode && (
                  <FormInputError
                    id={`${UUID}-pw-resetcode-error`}
                    errorMessage={errors.resetCode.message}
                  />
                )}
              </div>

              <div className="mt-4 text-right">
                <button className="ui-btn-primary ui-btn-min-w mt-2">
                  Continue <span className="onto find username step 3"></span>
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {step === 3 && (
        <div className="container ui-component-spacing">
          <div className="ui-card p-4 mx-auto sm:w-2/3 md:w-3/5 lg:w-1/2 md:p-6">
            <h2>Thank you for verifying</h2>
            <p className="text-sm">Your username is</p>
            <div className="text-xl text-center font-semibold mt-4 md:mt-6">
              gtoissant52
            </div>
            <div className="text-center mt-4 md:mt-6">
              <Link to="/">
                <button className="ui-btn-primary ui-btn-min-w">
                  Continue to login
                </button>
              </Link>
              <hr />
              <Link to="/forgot-password" className="text-xs">
                Forgot password
              </Link>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
