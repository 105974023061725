import { Dialog } from "@headlessui/react";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { v4 as uuid } from "uuid";
import FormInputError from "../../../General/FormInputError";
import DialogBackdrop from "../../../ModalDialogs/DialogBackdrop";
export default function UserSecurityDialog({ dialogState, setDialogState }) {
  const [step, setStep] = useState(1);
  const [newPasswordStatus, setNewPasswordStatus] = useState("");
  const [confirmedPasswordStatus, setConfirmedPasswordStatus] = useState("");
  const UUID = uuid();
  const {
    register,
    trigger,
    getValues,
    getFieldState,
    handleSubmit,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setStep(1);
  }, [dialogState]);

  const submitStepOne = (formData) => {
    setStep(2);
  };

  const submitStepTwo = (formData) => {
    alert(`form submittion -- ${JSON.stringify(formData)}`);
    setDialogState(false);
  };

  let debounceFunction; //timer identifier
  let debounceTimer = 500; //timer in ms

  // console.log(getFieldState("newPassword"));

  return (
    <Dialog
      open={dialogState}
      onClose={() => setDialogState(false)}
      className="ui-dialogs"
    >
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <DialogBackdrop />
      {/* Full-screen container to center the panel */}
      <div className="fixed inset-0 flex items-center justify-center p-4">
        <Dialog.Panel
          role="dialog"
          aria-modal="true"
          className="ui-default-dialog"
        >
          <Dialog.Title className="font-body text-xl font-bold text-left">
            Security Settings
          </Dialog.Title>
          <button
            className="ui-default-dialog-close"
            aria-label="close manage security settings window"
            onClick={() => setDialogState(false)}
          >
            <svg
              className="ui-icon h-3.5 w-3.5"
              focusable="false"
              aria-hidden="true"
              role="presentation"
            >
              <use href="#ui-icon-close"></use>
            </svg>
          </button>
          <Dialog.Description>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
            tincidunt mi sit amet ipsum tempus interdum.
          </Dialog.Description>
          <hr />
          {step === 1 && (
            <form onSubmit={handleSubmit(submitStepOne)}>
              <div className="w-full mx-auto sm:w-4/5 md:w-3/4 lg:w-3/5">
                <div className="text-base font-semibold">
                  Step 1 - Change your username &amp; password
                </div>
                <div className="grid grid-cols-1 gap-4 mt-4">
                  {/* Username */}
                  <div>
                    <label
                      id={`${UUID}-username-label`}
                      htmlFor={`${UUID}-username`}
                      className="block mb-1"
                    >
                      Username
                    </label>
                    <input
                      id={`${UUID}-username`}
                      aria-labelledby={`${UUID}-username-label`}
                      aria-required="true"
                      type="text"
                      placeholder="Please enter a username"
                      {...register("username", {
                        required: "Please enter a user name",
                      })}
                    />
                    {errors.username?.message && (
                      <FormInputError errorMessage={errors.username?.message} />
                    )}
                  </div>
                  {/* current password */}
                  <div>
                    <label
                      id={`${UUID}-current-password-label`}
                      htmlFor={`${UUID}-current-password`}
                      className="block mb-1"
                    >
                      Current password
                    </label>
                    <input
                      id={`${UUID}-current-password`}
                      aria-labelledby={`${UUID}-current-password-label`}
                      aria-required="true"
                      type="password"
                      {...register("currentPassword", {
                        required: "Please enter your current password",
                      })}
                    />
                    {errors.currentPassword?.message && (
                      <FormInputError
                        errorMessage={errors.currentPassword?.message}
                      />
                    )}
                  </div>
                  {/* new password */}
                  <div>
                    <label
                      id={`${UUID}-new-password-label`}
                      htmlFor={`${UUID}-new-password`}
                      className="block mb-1"
                    >
                      New password
                    </label>
                    <div className={`relative ${newPasswordStatus}`}>
                      <input
                        id={`${UUID}-new-password`}
                        aria-labelledby={`${UUID}-new-password-label`}
                        aria-describedby={`${UUID}-new-password-help`}
                        aria-required="true"
                        type="password"
                        {...register("newPassword", {
                          required: "Please enter a new password",
                          minLength: 8,
                        })}
                        onKeyUp={() => {
                          clearTimeout(debounceFunction);
                          debounceFunction = setTimeout(async () => {
                            await trigger("newPassword");
                            setNewPasswordStatus(
                              getFieldState("newPassword")?.error
                                ? "ui-input-val-false"
                                : "ui-input-val-true"
                            );
                          }, debounceTimer);
                        }}
                        onKeyDown={() => {
                          clearTimeout(debounceFunction);
                        }}
                      />
                    </div>
                    {errors.newPassword?.message && (
                      <FormInputError
                        errorMessage={errors.newPassword?.message}
                      />
                    )}
                    <div id={`${UUID}-new-password-help`} className="mt-1">
                      <div className="text-xxs font-semibold">
                        In order to protect your account, make sure your
                        password:
                      </div>
                      <ul className="list-disc ml-6">
                        <li className="text-xxs text-theme-text-tertiary">
                          Is minimum of 8 characters long
                        </li>
                        <li className="text-xxs text-theme-text-tertiary">
                          Does not match or significantly contain your username
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* confirm password */}
                  <div>
                    <label
                      id={`${UUID}-confirm-password-label`}
                      htmlFor={`${UUID}-confirm-password`}
                      className="block mb-1"
                    >
                      Confirm password
                    </label>
                    <div className={`relative ${confirmedPasswordStatus}`}>
                      <input
                        id={`${UUID}-confirm-password`}
                        aria-labelledby={`${UUID}-confirm-password-label`}
                        aria-describedby={`${UUID}-new-password-help`}
                        aria-required="true"
                        type="password"
                        {...register("confirmedPassword", {
                          required: "Please enter your new password again",
                        })}
                        onKeyUp={() => {
                          clearTimeout(debounceFunction);
                          debounceFunction = setTimeout(async () => {
                            await trigger("confirmedPassword");
                            setConfirmedPasswordStatus(
                              getValues("newPassword") ===
                                getValues("confirmedPassword")
                                ? "ui-input-val-true"
                                : "ui-input-val-false"
                            );
                          }, debounceTimer);
                        }}
                        onKeyDown={() => {
                          clearTimeout(debounceFunction);
                        }}
                      />
                    </div>
                    {errors.confirmedPassword?.message && (
                      <FormInputError
                        errorMessage={errors.confirmedPassword?.message}
                      />
                    )}
                  </div>
                </div>
              </div>
              {/* <!-- Buttons --> */}
              <div className="block text-right mt-6">
                <button
                  type="button"
                  className="ui-btn-secondary ui-btn-min-w mt-4"
                  onClick={() => setDialogState(false)}
                >
                  Cancel
                  <span className="sr-only">
                    changes to your security settings
                  </span>
                </button>
                <button className="ui-btn-primary ui-btn-min-w mt-4">
                  Next
                </button>
              </div>
            </form>
          )}

          {step === 2 && (
            <form onSubmit={handleSubmit(submitStepTwo)}>
              <div className="w-full mx-auto sm:w-4/5 md:w-3/4 lg:w-3/5">
                <div className="text-base font-semibold">
                  Step 2 - Confirm Your Identity
                </div>
                <div className="w-3/4 mx-auto mt-4">
                  <div>Thanks for keeping your account secure</div>
                  <div>Check your mobile device: xxx-xxx-0099</div>
                </div>

                <div className="grid grid-cols-1 gap-4 mt-4 xl:mt-6">
                  {/* Your Verification Code */}
                  <div>
                    <label
                      id={`${UUID}-verification-label`}
                      htmlFor={`${UUID}-verification`}
                      className="block mb-1"
                    >
                      Your Verification Code
                    </label>
                    <input
                      id={`${UUID}-verification`}
                      aria-labelledby={`${UUID}-verification-label`}
                      aria-required="true"
                      type="text"
                      placeholder="Please enter 6-digit code"
                      {...register("verificationCode", {
                        required:
                          "Please enter verification code before continuing.",
                        minLength: {
                          value: 6,
                          message: "Please enter a valid verification code.",
                        },
                      })}
                    />
                    {errors.verificationCode?.message && (
                      <FormInputError
                        errorMessage={errors.verificationCode.message}
                      />
                    )}
                  </div>
                  <div>
                    <div className="ui-checkbox inline-block">
                      <input
                        id={`${UUID}-trusted-device`}
                        type="checkbox"
                        aria-labelledby={`${UUID}-trusted-device-label`}
                        {...register("trustedDevice")}
                      />
                      <div className="ui-checkmark">
                        <svg
                          className="ui-icon w-2 h-2 text-white"
                          focusable="false"
                          aria-hidden="true"
                          role="presentation"
                        >
                          <use href="#ui-icon-checkmark"></use>
                        </svg>
                      </div>
                    </div>
                    <span id={`${UUID}-trusted-device-label`} className="mr-2">
                      This is a trusted device, don't ask again.
                    </span>
                    <Link to="/" className="ui-btn-hyperlink">
                      Learn more
                      <span className="sr-only"> about trusted devices.</span>
                    </Link>
                  </div>

                  <div>
                    <span className="mr-2">Haven't received it?</span>
                    <button className="ui-btn-hyperlink">Resend code</button>
                  </div>
                </div>
              </div>
              {/* <!-- Buttons --> */}
              <div className="block text-right mt-6">
                <button
                  type="button"
                  className="ui-btn-secondary ui-btn-min-w mt-4"
                  onClick={() => setDialogState(false)}
                >
                  Cancel
                  <span className="sr-only">
                    changes to your security settings
                  </span>
                </button>
                <button className="ui-btn-primary ui-btn-min-w mt-4">
                  Confirm
                  <span className="sr-only">
                    changes to your security settings
                  </span>
                </button>
              </div>
            </form>
          )}
        </Dialog.Panel>
      </div>
    </Dialog>
  );
}
