import { Link } from "react-router-dom";
export default function CallToActionCardWithVideo({
  title,
  copy,
  buttonPrimaryLabel,
  buttonPrimaryURL,
  buttonPrimaryStyle,
  buttonSecondaryLabel,
  buttonSecondaryURL,
  buttonSecondaryStyle,
  videoPath,
  videoWidth,
  videoHeight,
  videoTitle,
}) {
  return (    
    <div className="ui-card p-4 xl:p-6 relative">
      <div className="flex flex-col-reverse md:flex-row md:justify-between md:space-x-4 xl:space-x-6">
        <div className="mt-4 md:mt-0">
          <h2>{title}</h2>
          <p>{copy}</p>
          <div className="inline-grid gap-4 mt-4 grid-cols-2 xl:mt-6">
            {buttonSecondaryLabel && buttonSecondaryURL && (
              <Link
                to={buttonSecondaryURL}
                // className="ui-btn-secondary ui-btn-min-w"
                className={`ui-btn-min-w ${
                  buttonSecondaryStyle.length > 0
                    ? `ui-btn-${buttonSecondaryStyle}`
                    : "ui-btn-secondary"
                }`}
              >
                {buttonSecondaryLabel}
              </Link>
            )}
            {buttonPrimaryLabel && buttonPrimaryURL && (
              <Link
                to={buttonPrimaryURL}
                className={`ui-btn-min-w ${
                  buttonPrimaryStyle.length > 0
                    ? `ui-btn-${buttonPrimaryStyle}`
                    : "ui-btn-primary"
                }`}
              >
                {buttonPrimaryLabel}
              </Link>
            )}
          </div>
        </div>
        {/* This markup is for illustration only and needs to be replaced with the final video presentation method's markup */}
        {videoPath && videoWidth && videoHeight && videoTitle && (
          <div className="justify-self-center md:justify-self-end">
            <iframe
              width={videoWidth}
              height={videoHeight}
              className="w-full h-auto mx-auto md:min-w-[40%]"
              src={videoPath}
              title={videoTitle}
              frameBorder="0"
              allowFullScreen={true}
            ></iframe>
          </div>
        )}
      </div>
    </div>    
  );
}
