import { services, claims } from "../js/placeholderData/policiesData";
import { useEffect } from "react";
import {
  data as policyDetail,
  links,
} from "../js/placeholderData/policyDetail";
import { useParams, useNavigate } from "react-router-dom";
import SimpleDataRow from "../components/General/SimpleDataRow";
import Carousel from "../components/General/Carousel";
import CoverageAndDocumentsCard from "../components/Policy/Detail/CoverageAndDocumentsCard";
import OwnerAndInsuredCard from "../components/Policy/Detail/OwnerAndInsured";
import BeneficiariesCard from "../components/Policy/Detail/BeneficiariesCard";
import PaymentSummaryCard from "../components/Policy/Detail/PaymentSummary";
import LoansCard from "../components/Policy/Detail/LoansCard";
import AgentCard from "../components/Policy/Detail/AgentCard";
import ThingsYouCanDoCard from "../components/Policy/Detail/ThingsYouCanDoCard";
import RecentPolicyMessageGrid from "../components/Policy/Detail/RecentPolicyMessagesGrid";
import { useAccountTypeCheck } from "../hooks/useAccountTypeCheck";
export default function PolicyDetails() {
  const { policy_id } = useParams();
  const { userType } = useAccountTypeCheck("isBeneficiaryUser");
  let navigate = useNavigate();
  useEffect(() => {
    console.log('Is beneficiary user', userType);
    if (userType) {
      navigate(`/beneficiaryErrorPlaceholderPage`, { replace: true });
    }
  }, [userType]);
  return (
    <>
      <div className="container mb-8">
        <h1>Term Life - George Policy Details</h1>
        <p>
          Lorem ipsum dolor sit amet, eam ad autem fabulas. Expetenda dissentiet
        </p>
        <hr />
      </div>

      <div className="container ui-component-spacing">
        <div className="ui-card p-4 relative xl:p-6">
          <Carousel />
        </div>
      </div>

      <div className="container ui-component-spacing">
        <div className="flex flex-col lg:flex-row">
          <div className="lg:w-2/3 lg:pr-3">
            {/* <!-- Coverage &amp; Documents --> */}
            {policyDetail?.policy_detail && (
              <CoverageAndDocumentsCard data={policyDetail.policy_detail} />
            )}
            {/* <!-- Coverage &amp; Documents --> */}

            {/* <!-- Owner &amp; Insured --> */}
            {policyDetail?.people && (
              <OwnerAndInsuredCard data={policyDetail.people} />
            )}

            {/* <!-- Owner &amp; Insured --> */}

            {/* <!-- Beneficiaries --> */}
            {policyDetail?.beneficiaries && (
              <BeneficiariesCard data={policyDetail.beneficiaries} />
            )}
            {/* <!-- Beneficiaries --> */}

            {/* <!-- Payment Summary --> */}
            {policyDetail?.paymentSummary && (
              <PaymentSummaryCard
                data={policyDetail.paymentSummary}
                policy_id={policy_id}
              />
            )}
            {/* <!-- Payment Summary --> */}

            {/* <!-- Loans Summary --> */}
            {/* Limiting functionality for cutover one */}
            {policyDetail?.policy_detail.name !== "Annual Renewable" && (
              <LoansCard data={policyDetail.loans} />
            )}
            {/* <!-- Loans Summary --> */}

            {/* <!-- Agent --> */}
            {policyDetail?.agent && <AgentCard data={policyDetail.agent} />}
            {/* <!-- Agent --> */}
          </div>

          <div className="lg:w-1/3 lg:pl-3">
            {/* <!-- Things You Can Do --> */}
            <ThingsYouCanDoCard data={links} />
            {/* <!-- Things You Can Do --> */}

            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-x-6 lg:grid-cols-1 lg:gap-0">
              {/* <!-- My Service Requests --> */}
              {services.filter(p => p.policyNumber === policy_id).length > 0 && (
                <div className="ui-card mb-4 md:mb-6">
                  <div className="px-4 pt-4 pb-1 border-b border-theme-rule-light">
                    <h2 className="text-sm font-body font-semibold">
                      My Service Requests
                    </h2>
                  </div>
                  {services.filter(p => p.policyNumber === policy_id).map((service, idx) => (
                    <SimpleDataRow
                      type="customerServicesAndClaims"
                      key={`Customer service - ${idx}`}
                      data={service}
                    />
                  ))}
                  <button
                    className="ui-card-btn-more"
                    aria-label="View more service requests"
                  >
                    View more
                  </button>
                </div>
              )}
              {/* <!-- My Claims --> */}
              {claims.filter(p => p.policyNumber === policy_id).length > 0 && (
                <div className="ui-card mb-4 md:mb-6">
                  <div className="px-4 pt-4 pb-1 border-b border-theme-rule-light">
                    <h2 className="text-sm font-body font-semibold">My Claims</h2>
                  </div>
                  <div>
                    {claims.filter(p => p.policyNumber === policy_id).map((claim, idx) => (
                      <SimpleDataRow
                        type="customerServicesAndClaims"
                        key={`Customer claim - ${idx}`}
                        data={claim}
                      />
                    ))}
                  </div>
                  <button
                    className="ui-card-btn-more"
                    aria-label="View more thing you can do"
                  >
                    View More Service Requests
                  </button>
                </div>
              )}
              {/* <!-- My Claims --> */}
            </div>

            {/* <!-- Recent Policy Message --> */}
            <div className="ui-card mb-4 md:mb-6">
              <div className="px-4 pt-4 pb-1 border-b border-theme-rule-light">
                <h2 className="text-sm font-body font-semibold">
                  Recent Policy Alerts
                </h2>
              </div>
              <div className="py-4">
                <RecentPolicyMessageGrid />
              </div>
              <button
                className="ui-card-btn-more"
                aria-label="View more recent Policy Messages"
              >
                View More
              </button>
            </div>
            {/* <!-- Recent Policy Message --> */}
          </div>
        </div>
      </div>
    </>
  );
}
