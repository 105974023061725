import { useCollapsePanel } from "../../../hooks/useCollapsePanel";
import { v4 as uuidv4 } from "uuid";
export default function LoansCard({ data }) {
  const uuid = uuidv4();
  useCollapsePanel(uuid);
  return (
    <div className="ui-card mb-4 md:mb-6">
      <div className="md:flex md:flex-row md:items-start">
        <div className="px-4 pt-3 md:flex-grow xl:px-6 xl:pt-6">
          <h2 className="mb-0">
            <button
              id={uuid}
              className="flex items-center group md:hidden"
              aria-controls={`${uuid}-panel`}
              aria-expanded="true"
              aria-label="Expand or collapse Loans details"
            >
              <span className="inline-block p-1 rounded-full mr-2 text-ui-blue group-hover:text-white group-hover:bg-ui-blue">
                <svg
                  className="ui-icon ui-plus w-4 h-4"
                  focusable="false"
                  aria-hidden="true"
                  role="presentation"
                >
                  <use href="#ui-icon-plus"></use>
                </svg>
                <svg
                  className="ui-icon ui-minus w-4 h-4"
                  focusable="false"
                  aria-hidden="true"
                  role="presentation"
                >
                  <use href="#ui-icon-minus"></use>
                </svg>
              </span>
              <span className="text-base font-body font-bold md:ui-no-badge-icon-inline">
                Loans
              </span>
            </button>
            <span className="text-xl font-body font-bold hidden md:block">
              Loans
            </span>
          </h2>
        </div>
        <hr className="mb-0 mt-3 md:hidden" />
        <div className="hidden px-4 pt-4 text-sm md:justify-self-end md:px-6 md:pt-6">
          {/* <!-- Card title link --> */}
        </div>
      </div>
      <hr className="hidden md:block" />
      <div
        id={`${uuid}-panel`}
        className="px-4 pb-4 overflow-hidden h-20 md:h-auto xl:px-6 xl:pb-6"
      >
        {/* <!-- Loans Details --> */}
        <div className="grid grid-cols-1 grid-flow-row gap-4 md:grid-cols-2 xl:gap-6">
          <div className="mt-4 md:mt-6">
            <div className="md:border-r md:border-theme-rule-light">
              <div className="font-semibold mb-3 md:mb-4">
                Loans Against Whole Life Policy
              </div>
              <div className="grid grid-cols-2 gap-4 xl:gap-6">
                <div>
                  <div className="text-xs text-theme-text-tertiary">
                    Policy Number
                  </div>
                  <div className="text-sm font-semibold text-theme-text-secondary">
                    #{data.loan.number}
                  </div>
                </div>
                <div>
                  <div className="text-xs text-theme-text-tertiary">
                    Loan Amount
                  </div>
                  <div className="text-sm font-semibold text-theme-text-secondary">
                    ${data.loan.amount}
                  </div>
                </div>
                <div>
                  <div className="text-xs text-theme-text-tertiary">
                    Loan Type
                  </div>
                  <div className="text-sm font-semibold text-theme-text-secondary">
                    {data.loan.type}
                  </div>
                </div>
                <div>
                  <div className="text-xs text-theme-text-tertiary">
                    Date Loan Was Taken
                  </div>
                  <div className="text-sm font-semibold text-theme-text-secondary">
                    {data.loan.startDate}
                  </div>
                </div>
                <div>
                  <div className="text-xs text-theme-text-tertiary">
                    Interest Rate
                  </div>
                  <div className="text-sm font-semibold text-theme-text-secondary">
                    {data.loan.interestRate}%
                  </div>
                </div>
                <div>
                  <div className="text-xs text-theme-text-tertiary">
                    Loan Balance
                  </div>
                  <div className="text-sm font-semibold text-theme-text-secondary">
                    ${data.loan.balance}
                  </div>
                </div>
              </div>
            </div>
            <div className="text-right pt-4 xl:pt-6">
              <button className="ui-btn-primary">Make a Payment</button>
            </div>
          </div>

          <div className="mt-4 xl:mt-6">
            <div className="font-semibold mb-3 md:mb-4">
              Additional Funds Available
            </div>
            <div className="grid grid-cols-2 gap-4 md:gap-6">
              <div>
                <div className="text-xs text-theme-text-tertiary">
                  Policy Number
                </div>
                <div className="text-sm font-semibold text-theme-text-secondary">
                  #{data.fund.number}
                </div>
              </div>
              <div>
                <div className="text-xs text-theme-text-tertiary">
                  Max Loan Amount
                </div>
                <div className="text-sm font-semibold text-theme-text-secondary">
                  ${data.fund.maxLoanAmount}
                </div>
              </div>
              <div>
                <div className="text-xs text-theme-text-tertiary">
                  Interest Rate
                </div>
                <div className="text-sm font-semibold text-theme-text-secondary">
                  {data.fund.interestRate}%
                </div>
              </div>
              <div>
                <div className="text-xs text-theme-text-tertiary">
                  Payment Amount
                </div>
                <div className="text-sm font-semibold text-theme-text-secondary">
                  ${data.fund.paymentAmount}
                </div>
              </div>
              <div>
                <div className="text-xs text-theme-text-tertiary">
                  Interest Rate
                </div>
                <div className="text-sm font-semibold text-theme-text-secondary">
                  {data.fund.interestRate}%
                </div>
              </div>
              <div>
                <div className="text-xs text-theme-text-tertiary">
                  Payment Schedule
                </div>
                <div className="text-sm font-semibold text-theme-text-secondary">
                  {data.fund.duration} months
                </div>
              </div>
            </div>
            <div className="text-right pt-4 xl:pt-6">
              <button className="ui-btn-primary">Submit an Application</button>
            </div>
          </div>
        </div>
        {/* <!-- Loans Details --> */}
      </div>
    </div>
  );
}
