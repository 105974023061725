import { useState, useEffect } from "react";
import { payments } from "../js/placeholderData/paymentLandingData";
import Carousel from "../components/General/Carousel";
import SimpleCallToActionCard from "../components/General/SimpleCallToActionCard";
import PaymentDetailCard from "../components/Payments/PaymentDetailCard";
import { useNavigate } from "react-router-dom";
import { useAccountTypeCheck } from "../hooks/useAccountTypeCheck";

export default function Payment() {
  let [paymentsToShow, setPaymentsToShow] = useState(payments);
  let [selectedPaymentType, setSelectedPaymentType] = useState("0"); 
  let navigate = useNavigate(); 
  const { userType } = useAccountTypeCheck("isBeneficiaryUser");
  useEffect(() => {
    console.log('Is beneficiary user', userType);
    if(userType){
       navigate(`/beneficiaryErrorPlaceholderPage`, { replace: true });
     } 
    selectedPaymentType === "0"
      ? setPaymentsToShow(payments)
      : setPaymentsToShow(
          payments.filter((payment) => payment.typeId === selectedPaymentType)
        );
  }, [selectedPaymentType,userType]);

  const isTabAllowToShow = (typeId) => {
    const selectedPayments = payments.filter((payment) => payment.typeId === typeId)
    if(selectedPayments && selectedPayments.length > 0) return true
    return false;
  }; 

  return (
    <>
      {/* Manage Payments & Billing */}
      <div className="container mb-8">
        <h1>Manage Payments &amp; Billing</h1>
        <p>
          Here you can manage payments on your policies, view statements and
          more.
        </p>
        <hr />
      </div>

      <div className="container ui-component-spacing">
        <div className="ui-card p-4 relative xl:p-6">
          <Carousel />
        </div>
      </div>

      <div className="container ui-component-spacing">
        <div className="ui-card px-2 p-1 md:px-4">
          <button
            className={`ui-btn-tab ${
              selectedPaymentType === "0" ? "ui-btn-tab-current" : ""
            }`}
            onClick={() => setSelectedPaymentType("0")}
          >
            All Polices
          </button>
          {isTabAllowToShow("1") && (
            <button
              className={`ui-btn-tab ${
                selectedPaymentType === "1" ? "ui-btn-tab-current" : ""
              }`}
              onClick={() => setSelectedPaymentType("1")}
            >
              Term Life
            </button>
          )}
          {isTabAllowToShow("2") && (
            <button
              className={`ui-btn-tab ${
                selectedPaymentType === "2" ? "ui-btn-tab-current" : ""
              }`}
              onClick={() => setSelectedPaymentType("2")}
            >
              Whole Life
            </button>
          )}
          {isTabAllowToShow("3") && (
            <button
              className={`ui-btn-tab ${
                selectedPaymentType === "3" ? "ui-btn-tab-current" : ""
              }`}
              onClick={() => setSelectedPaymentType("3")}
            >
              Annuity
            </button>
          )}
        </div>

        {paymentsToShow.map((payment, idx) => (
          <PaymentDetailCard
            key={`Policy ${payment.policyNumber} payment card - ${idx}`}
            idx={idx}
            data={payment}
          />
        ))}
      </div>

      <div className="container ui-component-spacing">
        <h2>Learn How You Can</h2>
        <p className="text-sm">
          Lorem ipsum dolor sit amet, eam ad autem fabulas. Expetenda dissentiet
        </p>
        <hr />
        <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-3 xl:gap-6">
          <SimpleCallToActionCard
            title="Manage Payments"
            desc="You can change your payment amount and frequency, payment method and payment day. Click Here. "
            ctaText="Manage"
            ctaUrl="/payment/billing"
          />
          <SimpleCallToActionCard
            title="Paperless billing"
            desc="Paperless billing helps you eliminate extra paper mail while also helping the environment. By going paperless, you can receive bills by utilizing this online account. You can also pay bills online or set up automatic payments."
            ctaText="Call to Action"
            ctaUrl="/payment/manage-payments"
          />

          <SimpleCallToActionCard
            title="Autopay"
            desc="Autopay can help automate the process of paying bills and make life simpler.  CTA button takes user to autopay screen."
            ctaText="Call to Action"
            ctaUrl="/payment/manage-payments"
          />
          <SimpleCallToActionCard
            title="Understand a policy lapse"
            desc="Blurb about late fees or other relevant fees customers tend to have questions about goes here lore ipsum dolor sit amet dolor est."
            ctaText="Call to Action"
            ctaUrl="#"
          />
          <SimpleCallToActionCard
            title="Grace period"
            desc="Blurb about understanding that life happens and sometimes we are late on payment or unable to make payments on time."
            ctaText="Call to Action"
            ctaUrl="#"
          />
          <SimpleCallToActionCard
            title="Policy Lapse"
            desc="A life insurance lapse occurs when you stop paying your policy's premium and the contractual grace period has expired.  To apply for reinstatement complete the form found on the Forms tab."
            ctaText="Call to Action"
            ctaUrl="#"
          />
          <SimpleCallToActionCard
            title="Request a Duplicate Policy"
            desc="If your policy it is lost or destroyed, wholly or partially, you can complete an application for the issuance of a duplicate policy document.  You can find the application on the Forms tab.  CTA takes user to forms tab."
            ctaText="Call to Action"
            ctaUrl="#"
          />
          <SimpleCallToActionCard
            title="Collateral Assignment"
            desc="Collateral assignment of life insurance is a method of providing a lender with collateral when you apply for a loan. If your lender has requested this, you can complete the application form found on the Forms tab. "
            ctaText="Call to Action"
            ctaUrl="#"
          />
        </div>
      </div>
    </>
  );
}
