import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import DialogBackdrop from "./DialogBackdrop";

const DeleteNonAutopayPaymentModal = ({ modalState, setModalState, onSubmit }) => {

  return (
    <Transition appear show={modalState} as={Fragment}>
      <Dialog open={modalState} onClose={() => setModalState(false)} className="ui-dialogs fixed inset-0 z-10 overflow-y-auto">

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0" />
        </Transition.Child>
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <DialogBackdrop />
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >

          <Dialog.Panel className="ui-default-dialog">
            <Dialog.Title className="font-body text-xl font-bold text-left">Delete payment method</Dialog.Title>
            <button className="ui-default-dialog-close" onClick={() => setModalState(false)}>
              <svg className="ui-icon h-3.5 w-3.5" focusable="false" aria-hidden="true" role="presentation">
                <use href="#ui-icon-close"></use>
              </svg>
            </button>
            <hr />

            <Dialog.Description as="div">
              <p>Are you sure you want to cancel this payment? Please be sure to select another payment option, 
                if necessary, for this policy if you cancel this payment.
              </p>
              <div className="p-2 mt-4 border border-gray-light bg-ui-gray-lightest rounded-sm">
                <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-none lg:grid-flow-col gap-4">
                  <div>
                    <div className="text-xs text-theme-text-tertiary">Credit Card</div>
                    <div className="text-sm font-semibold text-theme-text-secondary">****-****-****-1234</div>
                  </div>
                  <div>
                    <div className="text-xs text-theme-text-tertiary">Cardholder name</div>
                    <div className="text-sm font-semibold text-theme-text-secondary">George Toissant</div>
                  </div>
                  <div>
                    <div className="text-xs text-theme-text-tertiary">Expiration Date</div>
                    <div className="text-sm font-semibold text-theme-text-secondary">MM/YYYY</div>
                  </div>
                  <div>
                    <div className="text-xs text-theme-text-tertiary">Card type</div>
                    <div className="text-sm font-semibold text-theme-text-secondary">Visa</div>
                  </div>
                </div>
              </div>
            </Dialog.Description>
            <div className="block text-right mt-6">
              <button className="ui-btn-secondary ui-btn-min-w mt-4" onClick={() => setModalState(false)}>
                No, keep<span className="sr-only"> this payment method</span>
              </button>
              <button className="ui-btn-primary ui-btn-min-w mt-4" onClick={() => onSubmit()}>
                Yes, delete<span className="sr-only"> this payment method</span>
              </button>
            </div>
          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition>
  )
}

export default DeleteNonAutopayPaymentModal