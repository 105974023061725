export const notes = [
  {
    type: "Payments",
    subject: "Paid on loan",
    note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
    date: "03-29/2022",
  },
  {
    type: "Contract Change",
    subject: "Loan information",
    note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
    date: "03-29/2022",
  },
  {
    type: "Client Policy Questions",
    subject: "Phone call",
    note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
    date: "03-29/2022",
  },
  {
    type: "Client Policy Questions",
    subject: "Phone call",
    note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
    date: "03-29/2022",
  },
  {
    type: "Client Policy Questions",
    subject: "Phone call",
    note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
    date: "03-29/2022",
  },
  {
    type: "Payments",
    subject: "Another note title goes here",
    note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    date: "03-29/2022",
  },
  {
    type: "Agent Service",
    subject: "Sent reminder for policy lapse",
    note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
    date: "03-29/2022",
  },
  {
    type: "Agent Service",
    subject: "Sent reminder for policy lapse",
    note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
    date: "03-29/2022",
  },
  {
    type: "Client Claim Questions",
    subject: "Paid on loan",
    note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
    date: "03-29/2022",
  },
  {
    type: "Client Claim Questions",
    subject: "Paid on loan",
    note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
    date: "03-29/2022",
  },
  {
    type: "Client Claim Questions",
    subject: "Paid on loan",
    note: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.",
    date: "03-29/2022",
  },
];