export const settings = [
  {
    icon: "#ui-icon-alert",
    title: "Manage Training Notifications",
    desc: "Here goes a brief description",
    url: "/policies/beneficiaries/5723452",
  },
  {
    icon: "#ui-icon-clipboard",
    title: "Manage My Licence",
    desc: "Here goes a brief description",
    url: "/policies/beneficiaries/5723452",
  },
  {
    icon: "#ui-icon-heart",
    title: "Manage Favorites",
    desc: "Here goes a brief description",
    url: "/policies/beneficiaries/5723452",
  },
  {
    icon: "#ui-icon-book",
    title: "Training Compliance",
    desc: "Here goes a brief description",
    url: "/policies/beneficiaries/5723452",
  },
  {
    icon: "#ui-icon-circle-checkmark",
    title: "Manage Agent Permissions",
    desc: "Here goes a brief description",
    url: "/policies/beneficiaries/5723452",
  },
  {
    icon: "#ui-icon-profile",
    title: "Agent Look-Up",
    desc: "Here goes a brief description",
    url: "/policies/beneficiaries/5723452",
  },
  {
    icon: "#ui-icon-profile",
    title: "Manage Agents",
    desc: "Here goes a brief description",
    url: "/policies/beneficiaries/5723452",
  },
  {
    icon: "#ui-icon-profile",
    title: "Another Thing You Can Do",
    desc: "Here goes a brief description",
    url: "/policies/beneficiaries/5723452",
  },
];
