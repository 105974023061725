import { useState } from "react";
import { MaskedInput, SecureMaskedInput } from "react-control-library";
import { Controller } from "react-hook-form";

const PanelOne = ({
  register,
  onSubmit,
  handleSubmit,
  errors,
  policyHolder,
  setPolicyHolder,
  control,
}) => {
  const onOptionChange = (e) => setPolicyHolder(e.target.value);

  const [ssn, setSsn] = useState("");
  const [entityEin, setEntityEin] = useState("");
  const [phone, setPhone] = useState("?");

  const getSsnMask = () => {
    const ssnMask = [
      /^[0-9]*$/,
      /^[0-9]*$/,
      /^[0-9]*$/,
      "-",
      /^[0-9]*$/,
      /^[0-9]*$/,
      "-",
      /^[0-9]*$/,
      /^[0-9]*$/,
      /^[0-9]*$/,
      /^[0-9]*$/,
    ];
    return ssnMask;
  };

  const phoneMask = () => [
    "(",
    /^[0-9]*$/,
    /^[0-9]*$/,
    /^[0-9]*$/,
    ")",
    " ",
    /^[0-9]*$/,
    /^[0-9]*$/,
    /^[0-9]*$/,
    "-",
    /^[0-9]*$/,
    /^[0-9]*$/,
    /^[0-9]*$/,
    /^[0-9]*$/,
  ];

  return (
    <div className="pt-4 mt-2 lg:pt-6 border-t border-theme-rule-light">
      <p className="mb-4 text-sm">
        Let's get you signed up for an account to view and manage your policies
        online.
      </p>
      <form onSubmit={handleSubmit(() => onSubmit(1))}>
        <fieldset>
          <legend className="mb-4 text-sm">
            Tell us what type of policy owner you are:
          </legend>
          <div className="inline-block mr-6">
            <label className="inline-flex items-center text-sm font-normal">
              <input
                type="radio"
                name="policyOwnerType"
                value="individual"
                checked={policyHolder === "individual"}
                onClick={onOptionChange}
                id="ID_policyOwnerTypeIndividual"
                {...register("policyOwnerType")}
                className="mr-2"
              />
              An individual policyholder
            </label>
          </div>
          <div className="inline-block">
            <label className="inline-flex items-center text-sm font-normal">
              <input
                type="radio"
                name="policyOwnerType"
                value="entity"
                checked={policyHolder === "entity"}
                onClick={onOptionChange}
                id="ID_policyOwnerTypeEntity"
                {...register("policyOwnerType")}
                className="mr-2"
              />
              A non-natural entity (corporation) trust, etc.
            </label>
          </div>
        </fieldset>

        <hr />
        {policyHolder === "individual" && (
          <p className="mb-6 font-semibold text-base">
            You will need your policy number (an active one) and the policy
            owner's Social Security Number, telephone number and zip code to
            sign up for an account.
          </p>
        )}

        {policyHolder === "entity" && (
          <p className="mb-6 font-semibold text-base">
            You will need your policy number (an active one) and the policy
            owner's Social Security/EIN Number, telephone number and zip code to
            sign up for an account.
          </p>
        )}

        {policyHolder === "individual" && (
          <div>
            <label
              id="ID_policyNumberLabel"
              className="text-base font-semibold"
            >
              Policy Number*:
            </label>
            <p className="text-xs text-theme-text-tertiary mb-1">
              Your policy number can be found on your original policy/contract
              or on your annual, policy statement.
            </p>
            <input
              id="ID_policyNumber"
              className="w-full"
              type="text"
              placeholder="#"
              {...register("policyNumber", {
                required: {
                  value: true,
                  message: "please enter a policy number",
                },
              })}
              aria-describedby="ID_policyNumberError"
              aria-labelledby="ID_policyNumberLabel"
            />
            <div id="ID_policyNumberError">
              {errors.policyNumber && (
                <div className="ui-input-error">
                  {errors.policyNumber.message}
                </div>
              )}
            </div>
          </div>
        )}

        {policyHolder === "entity" && (
          <div>
            <label
              id="ID_policyNumberLabel"
              className="text-base font-semibold"
            >
              Business/Trust*:
            </label>
            <p className="text-xs mb-1">
              Name of your Business or Trust.
            </p>
            <input
              id="ID_policyNumber"
              className="w-full"
              type="text"
              placeholder=""
              {...register("businessName", {
                required: {
                  value: true,
                  message: "please enter a business/trust name",
                },
              })}
              aria-describedby="ID_policyNumberError"
              aria-labelledby="ID_policyNumberLabel"
            />
            <div id="ID_policyNumberError">
              {errors.businessName && (
                <div className="ui-input-error">
                  {errors.businessName.message}
                </div>
              )}
            </div>
          </div>
        )}

        <hr />
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 md:gap-6">
          {policyHolder === "individual" && (
            <>
              <div>
                <label id="ID_firstNameLabel">First Name *</label>
                <input
                  id="ID_firstName"
                  className="w-full"
                  type="text"
                  {...register("firstName", {
                    required: {
                      value: true,
                      message: "please enter your first name",
                    },
                  })}
                  aria-describedby="ID_firstNameError"
                  aria-labelledby="ID_firstNameLabel"
                />
                <div id="ID_firstNameError">
                  {errors.firstName && (
                    <div className="ui-input-error">
                      {errors.firstName.message}
                    </div>
                  )}
                </div>
              </div>
              <div>
                <label id="ID_lastNameLabel">Last Name *</label>
                <input
                  id="ID_lastName"
                  className="w-full"
                  type="text"
                  {...register("lastName", {
                    required: {
                      value: true,
                      message: "please enter your last name",
                    },
                  })}
                  aria-describedby="ID_lastNameError"
                  aria-labelledby="ID_lastNameLabel"
                />
                <div id="ID_lastNameError"></div>
                {errors.lastName && (
                  <div className="ui-input-error">
                    {errors.lastName.message}
                  </div>
                )}
              </div>
              <div>
                <label id="ID_middleNameLabel">Middle Initial</label>
                <input
                  id="ID_middleName"
                  className="w-full"
                  type="text"
                  {...register("middleInitial")}
                  aria-labelledby="ID_middleNameLabel"
                />
              </div>
              <div>
                <label id="ID_ssnLabel">Social Security Number *</label>

                <Controller
                  name="socialSecurity"
                  control={control}
                  value={ssn}
                  render={({ field: { ref, value, onChange } }) => (
                    <SecureMaskedInput
                      id="ID_ssn"
                      className="w-full"
                      type="text"
                      placeholder="XXX-XX-XXXX"
                      value={value}
                      mask={getSsnMask()}
                      exactLength={9}
                      secure={{
                        getValue: (detail, data) => {
                          if (!data) return data;
                          if (detail.value.length === 9)
                            return `***-**-${detail.value.substring(
                              detail.value.length - 4,
                              detail.value.length
                            )}`;
                          return data;
                        },
                      }}
                      onChange={onChange}
                      aria-describedby="ID_ssnError ID_ssnFormat"
                      aria-labelledby="ID_ssnLabel"
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: "please enter your ssn",
                    },
                  }}
                />
                <div id="ID_ssnError">
                  {errors.socialSecurity && (
                    <div className="ui-input-error">
                      {errors.socialSecurity.message}
                    </div>
                  )}
                </div>
                <span id="ID_ssnFormat" className="sr-only">
                  Enter as XXX-XX-XXXX
                </span>
              </div>
            </>
          )}

          {policyHolder === "entity" && (
            <div>
              <label id="ID_ssnLabel">Social Security/EIN Number *</label>
              <Controller
                name="socialSecurity_EIN"
                control={control}
                value={entityEin}
                render={({ field: { ref, value, onChange } }) => (
                  <SecureMaskedInput
                    id="ID_ssn"
                    className="w-full"
                    type="text"
                    placeholder="XXX-XX-XXXX"
                    value={value}
                    mask={getSsnMask()}
                    exactLength={9}
                    secure={{
                      getValue: (detail, data) => {
                        if (!data) return data;
                        if (detail.value.length === 9)
                          return `***-**-${detail.value.substring(
                            detail.value.length - 4,
                            detail.value.length
                          )}`;
                        return data;
                      },
                    }}
                    onChange={onChange}
                    aria-describedby="ID_ssnError ID_ssnFormat"
                    aria-labelledby="ID_ssnLabel"
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: "please enter your ssn or EIN",
                  },
                }}
              />

              <div id="ID_ssnError">
                {errors.socialSecurity_EIN && (
                  <div className="ui-input-error">
                    {errors.socialSecurity_EIN.message}
                  </div>
                )}
              </div>
              <span id="ID_ssnFormat" className="sr-only">
                Enter as XXX-XX-XXXX
              </span>
            </div>
          )}

          <div>
            <label id="ID_zipLabel">Zip Code *</label>
            <input
              id="ID_zip"
              className="w-full"
              type="text"
              {...register("zipCode", {
                required: {
                  value: true,
                  message: "please enter your zip code",
                },
              })}
              aria-describedby="ID_zipError"
              aria-labelledby="ID_zipLabel"
            />
            <div id="ID_zipError">
              {errors.zipCode && (
                <div className="ui-input-error">{errors.zipCode.message}</div>
              )}
            </div>
          </div>
          <div>
            <label id="ID_phoneLabel">Mobile Phone Number</label>
            <Controller
              name="phoneNumber"
              control={control}
              render={({ field: { ref, onChange } }) => (
                <MaskedInput
                  id="ID_phone"
                  className="w-full"
                  type="text"
                  placeholder="(XXX) XXX-XXXX"
                  mask={phoneMask()}
                  value={phone}
                  onChange={onChange}
                  aria-describedby="ID_phoneFormat ID_phoneError"
                  aria-labelledby="ID_phoneLabel"
                />
              )}
            />

            <div id="ID_phoneError">
              {errors.phoneNumber && (
                <div className="ui-input-error">
                  {errors.phoneNumber.message}
                </div>
              )}
            </div>
            <span id="ID_phoneFormat" className="sr-only">
              Enter as (XXX) XXX-XXXX
            </span>
          </div>
        </div>
        <div className="mt-4 text-right">
          <button
            id="ID_registrationStep1Btn"
            className="ui-btn-primary ui-btn-min-w mt-2"
            aria-controls="ID_registrationStep2Form"
            aria-expanded="false"
          >
            Continue<span className="sr-only"> to step 2</span>
          </button>
        </div>
      </form>
    </div>
  );
};

export default PanelOne;
