import { Link, useLocation } from "react-router-dom";

export default function NavLink({
  mainLink = false,
  includeUrl = false,
  title,
  url,
  currentPage,
  setCurrentPage,
  closeMenu,
  closeMobileMenu,
}) {
  const location = useLocation();
  return (
    <Link
      to={url}
      className={`
        ${
          currentPage === url ||
          (location.pathname.split("/")[1] === "guest"
            ? location.pathname.split("/")[2] === includeUrl
            : location.pathname.split("/")[1] === includeUrl)
            ? "ui-current"
            : ""
        }
        ${mainLink ? "ui-main-link" : ""}
      `}
      aria-current={currentPage === url ? "page" : ""}
      onClick={() => {
        setCurrentPage((page) => (page = url));
        if (closeMenu) closeMenu();
        if (closeMobileMenu) closeMobileMenu();
      }}
    >
      {title}
    </Link>
  );
}
