// route: /payment/manage-payments
export const paymentData = [
  {
    id: 1,
    isDefault: true,
    type: "Credit Card",
    accountNumber: "****-****-****-1234",
    ownerName: "George Toissant",
    expDate: "12/15/20",
    cardType: "Visa",
    isAutopay: "Yes",
  },
  {
    id: 2,
    isDefault: false,
    type: "Bank Account",
    accountNumber: "****-****-****-5678",
    ownerName: "George Toissant",
    expDate: "",
    cardType: "",
    isAutopay: "No",
  },
];

export const cardArray = [
  {
    id: 1,
    name: "Credit Card",
    value: "credit",
  },
  // {
  //   id: 2,
  //   name: "EFT (Electronic Funds Transfer)",
  //   value: "eft",
  // },
];

export const accountArray = [
  {
    id: 1,
    name: "Checking Account",
  },
  {
    id: 2,
    name: "Savings Account",
  },
];

export const policyArray = [
  {
    id: 1,
    policyNumber: "#93485397",
    type: "Whole Life - George",
    premium: "$17.50",
    paymentMethod: "Visa ending in 1234",
    cardNumber: 1234,
    isAutopay: "Yes",
    paymentFrequency: "Monthly on the 15th",
  },
  {
    id: 2,
    policyNumber: "#11421597",
    type: "Term Life - Stacy",
    premium: "$177.50",
    paymentMethod: "Visa ending in 1134",
    cardNumber: 1134,
    isAutopay: "No",
    paymentFrequency: "Monthly on the 19th",
  },
];
