import Badge from "../../../General/Badge";

const EligibleLoansCard = ({ data }) => {
  return (
    <>
      <div className="ui-card p-4 my-4 xl:p-6">
        <div className="text-base font-bold lg:text-lg">
          Additional funds available {data.title}
        </div>
        <hr className="ui-under-title" />
        <div className="grid grid-cols-1 gap-2 sm:gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-6 xl:gap-6">
          <div>
            <div className="text-xs text-theme-text-tertiary">Policy number</div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              <div>#{data.number}</div>
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">Max loan amount</div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              <div>${data.maxLoanAmount}</div>
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">Interest rate</div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              <div>{data.interestRate}</div>
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">Payment amount</div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              <div>${data.paymentAmount}</div>
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">Start date</div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              <div>{data.startDate}</div>
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">Duration</div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              <div>{data.duration}</div>
            </div>
          </div>
        </div>
        <div className="mt-6 text-right">
          <button className="ui-btn-primary">
            Send Application
            <span className="sr-only"> for load Against {data.title}</span>
          </button>
        </div>
        <p className="mt-4 text-right text-xxs text-theme-text-tertiary">
          You can send a loan application to the policy owner by clicking the
          button.
        </p>
      </div>
    </>
  );
};

export default EligibleLoansCard;
