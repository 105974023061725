import FormInputError from "../../General/FormInputError";
import { v4 as uuidv4 } from "uuid";
export default function PaymentPolicy({
  register,
  validate,
  getFieldState,
  errors,
  setCurrentStep,
}) {
  // debounce function config
  let debounceFunction; //timer identifier
  let debounceTimer = 1500; //time in ms, 1.5 seconds for example

  const policyNumId = uuidv4();

  return (
    <>
      <div className="ui-form-card-no-event">
        <label
          id={policyNumId + "-label"}
          htmlFor={policyNumId}
          className="block mb-1"
        >
          Policy number *
        </label>
        <input
          id={policyNumId}
          aria-labelledby={policyNumId + "-label"}
          aria-describedby={`${policyNumId + "-help"} ${
            policyNumId + "-error"
          }`}
          type="text"
          placeholder="Please enter a policy number"
          {...register("policyNumber", {
            required: {
              value: true,
              message: "Please enter a valid policy number.",
            },
            minLength: {
              value: 8,
              message: "Policy number should be more than 8 characters.",
            },
          })}
          onKeyUp={() => {
            clearTimeout(debounceFunction);
            debounceFunction = setTimeout(async () => {
              await validate("policyNumber");
              if (!getFieldState("policyNumber").invalid) setCurrentStep(2);
            }, debounceTimer);
          }}
          onKeyDown={() => {
            clearTimeout(debounceFunction);
          }}
        />
        <div id={policyNumId + "-error"}>
          {errors.policyNumber?.message && (
            <FormInputError errorMessage={errors.policyNumber.message} />
          )}
        </div>
        <div
          id={policyNumId + "-help"}
          className="text-xxs text-theme-text-tertiary mt-1"
        >
          Enter policy number as it appears in your policy
        </div>
      </div>
    </>
  );
}
