export default function SVGSprite() {
  return (
    <div
      id="svgSprint"
      style={{
        width: "1px !important",
        height: "1px !important",
        display: "none!important",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        style={{
          display: "none!important",
          width: "1px",
          height: "1px",
        }}
      >
        <symbol id="ui-icon-app" viewBox="0 0 18 18">
          <path d="M3.5,15.9c-0.9,0-1.8-0.3-2.4-1c-0.7-0.6-1-1.5-1.1-2.4V7.2c0,0,0,0,0,0s0,0,0,0V5.5C0,3.5,1.6,2,3.5,2.1h11c0,0,0,0,0.1,0c1.9,0,3.4,1.5,3.5,3.4v1.8c0,0,0,0,0,0s0,0,0,0v5.3c0,1.9-1.6,3.4-3.5,3.4H3.5C3.5,15.9,3.5,15.9,3.5,15.9zM1.5,8v4.5c0,0.5,0.2,1,0.6,1.3s0.9,0.5,1.4,0.5c0,0,0,0,0,0h11c1.1,0,2-0.8,2-1.9V8H1.5z M1.5,6.5h14.9v-1c0-1-0.9-1.9-1.9-1.9c0,0,0,0,0,0h-11c-1.1,0-2,0.8-2,1.9V6.5z M9.9,12.4H9c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h0.9c0.4,0,0.8,0.3,0.8,0.8S10.3,12.4,9.9,12.4z M5.3,12.4H4.4c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h0.9c0.4,0,0.8,0.3,0.8,0.8S5.8,12.4,5.3,12.4z" />
        </symbol>

        <symbol id="ui-icon-alert" viewBox="0 0 18 18">
          <path d="M9,18C9,18,9,18,9,18c-1.9,0-3.3-1.5-3.4-3.3v-0.4H1.4c-0.2,0-0.4-0.1-0.5-0.3c-0.1-0.2,0-0.4,0.1-0.6L2.4,12c0.3-0.3,0.4-0.6,0.4-1v-3c0-2.5,1.5-4.7,3.8-5.7c0-0.6,0.3-1.2,0.7-1.7C7.7,0.3,8.4,0,9,0c0,0,0,0,0,0c0.6,0,1.3,0.3,1.7,0.7c0.4,0.4,0.7,1,0.7,1.7c2.3,1,3.8,3.2,3.8,5.7v3c0,0.4,0.1,0.7,0.4,1l1.3,1.3c0.2,0.2,0.2,0.4,0.1,0.6c-0.1,0.2-0.3,0.3-0.5,0.3h-4.2v0.4C12.3,16.5,10.8,18,9,18z M6.7,14.3v0.4c0,1.2,1,2.2,2.3,2.2c1.3,0,2.3-1,2.3-2.3v-0.4H6.7zM6.2,13.2h5.7l0,0c0,0,0,0,0,0h3.4l-0.4-0.4c-0.5-0.5-0.7-1.1-0.7-1.7v-3c0-2.2-1.4-4.1-3.4-4.8c-0.2-0.1-0.4-0.3-0.4-0.5V2.4c0-0.4-0.1-0.7-0.4-1S9.4,1.1,9,1.1c0,0,0,0,0,0c-0.7,0-1.4,0.6-1.4,1.4v0.3c0,0.2-0.1,0.4-0.4,0.5C5.2,4,3.9,5.9,3.9,8.1v3c0,0.7-0.3,1.3-0.7,1.7l-0.4,0.4H6.2z" />
        </symbol>

        <symbol id="ui-icon-apply" viewBox="0 0 18 18">
          <path d="M12.9,17.2H3.6c-1.4,0-2.5-1.1-2.5-2.5V3.6c0-1.4,1.1-2.5,2.5-2.5h1.2C5.2,0,6.2-0.8,7.3-0.8h1.8 c1.2,0,2.1,0.8,2.4,1.8h1.2c1.4,0,2.5,1.1,2.5,2.5v11.1C15.4,16.1,14.3,17.2,12.9,17.2z M3.6,2.5C3,2.5,2.5,3,2.5,3.6v11.1 c0,0.6,0.5,1.2,1.2,1.2h9.2c0.6,0,1.2-0.5,1.2-1.2V3.6c0-0.6-0.5-1.2-1.2-1.2h-1.2c-0.3,1.1-1.3,1.8-2.4,1.8H7.3 c-1.2,0-2.1-0.8-2.4-1.8H3.6z M6.2,1.8c0,0.6,0.5,1.2,1.2,1.2h1.8c0.6,0,1.2-0.5,1.2-1.2c0-0.6-0.5-1.2-1.2-1.2H7.3 C6.7,0.6,6.2,1.2,6.2,1.8z M7.3,12.6c-0.2,0-0.4-0.1-0.5-0.2L5,10.6c-0.3-0.3-0.3-0.7,0-1c0.3-0.3,0.7-0.3,1,0L7.3,11l3.2-3.2 c0.3-0.3,0.7-0.3,1,0c0.3,0.3,0.3,0.7,0,1l-3.7,3.7C7.7,12.6,7.5,12.6,7.3,12.6z" />
        </symbol>

        <symbol id="ui-icon-arrow-right" viewBox="0 0 20 20">
          <path d="M10 3.333 8.825 4.508l4.65 4.659H3.333v1.667h10.142l-4.65 4.658L10 16.667 16.667 10 10 3.333z" />
        </symbol>

        <symbol id="ui-icon-book" viewBox="0 0 18 18">
          <path d="M9,16.3L9,16.3L9,16.3c-0.1,0-0.1,0-0.2,0c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0 c-1.1-0.7-2.4-1.1-3.8-1c-1.3,0-2.7,0.3-3.8,1.1c-0.2,0.1-0.5,0.1-0.7,0C0.1,16.1,0,15.8,0,15.6v-12C0,3.3,0.1,3.1,0.3,3 c1.4-0.9,2.9-1.3,4.5-1.3c1.5,0,2.9,0.3,4.1,1c1.3-0.7,2.7-1.1,4.2-1c1.6,0,3.2,0.4,4.5,1.3C17.9,3.1,18,3.3,18,3.6v12 c0,0.3-0.1,0.5-0.4,0.6c-0.2,0.1-0.5,0.1-0.7,0c-1.1-0.7-2.5-1.1-3.8-1.1c-1.4,0-2.7,0.3-3.8,1c0,0,0,0,0,0c0,0-0.1,0-0.1,0.1 C9.1,16.3,9.1,16.3,9,16.3z M1.4,4v10.5c1.1-0.5,2.3-0.7,3.5-0.7c1.2,0,2.4,0.2,3.4,0.7V4C7.3,3.4,6.1,3.1,4.9,3.1 C3.6,3.1,2.4,3.4,1.4,4z M9.7,4v10.5c1.1-0.5,2.3-0.7,3.5-0.7c1.2,0,2.4,0.2,3.4,0.7V4c-1.1-0.6-2.2-0.9-3.4-0.9 C11.9,3.1,10.7,3.4,9.7,4z" />
        </symbol>

        <symbol id="ui-icon-bolt" viewBox="0 0 18 18">
          <path d="M8.1,18c-0.1,0-0.2,0-0.3-0.1c-0.4-0.1-0.6-0.5-0.6-0.8v-5.4H1.8c-0.3,0-0.7-0.2-0.8-0.5c-0.1-0.3-0.1-0.7,0.1-1l8.1-9.9c0.2-0.3,0.6-0.4,1-0.3c0.4,0.1,0.6,0.5,0.6,0.8v5.4h5.4c0.3,0,0.7,0.2,0.8,0.5c0.1,0.3,0.1,0.7-0.1,1l-8.1,9.9 C8.6,17.9,8.4,18,8.1,18z M3.7,9.9h4.4c0.5,0,0.9,0.4,0.9,0.9v3.8l5.3-6.5H9.9C9.4,8.1,9,7.7,9,7.2V3.4L3.7,9.9z" />
        </symbol>

        <symbol id="ui-icon-border-problem" viewBox="0 0 48 48">
          <g>
            <path
              fill="none"
              d="M24,0c13.3,0,24,10.7,24,24S37.3,48,24,48S0,37.3,0,24S10.7,0,24,0z"
            />
            <path
              d="M24,3c-2.8,0-5.6,0.6-8.2,1.6c-2.5,1.1-4.7,2.6-6.7,4.5s-3.4,4.2-4.5,6.7C3.6,18.4,3,21.2,3,24
            s0.6,5.6,1.6,8.2c1.1,2.5,2.6,4.7,4.5,6.7s4.2,3.4,6.7,4.5c2.6,1.1,5.3,1.6,8.2,1.6s5.6-0.6,8.2-1.6c2.5-1.1,4.7-2.6,6.7-4.5
            s3.4-4.2,4.5-6.7c1.1-2.6,1.6-5.3,1.6-8.2s-0.6-5.6-1.6-8.2c-1.1-2.5-2.6-4.7-4.5-6.7s-4.2-3.4-6.7-4.5C29.6,3.6,26.8,3,24,3
               M24,0c13.3,0,24,10.7,24,24S37.3,48,24,48S0,37.3,0,24S10.7,0,24,0z"
            />
          </g>
          <g transform="translate(13.069 14)">
            <g>
              <path
                fill="#222222"
                d="M18.6,19.5H2.4c0,0,0,0,0,0c-0.5,0-1.1-0.1-1.5-0.4c-0.7-0.4-1.2-1.1-1.4-1.9c-0.2-0.8-0.1-1.6,0.3-2.4
               L7.8,0.8c0.3-0.5,0.7-0.9,1.1-1.1c0.7-0.4,1.6-0.5,2.4-0.3s1.5,0.7,1.9,1.4l8.1,14.1c0.3,0.5,0.4,1,0.4,1.6
               C21.7,18.2,20.3,19.5,18.6,19.5z M10.5,0.7c-0.3,0-0.6,0.1-0.8,0.2C9.4,1.1,9.2,1.3,9.1,1.6L1,15.6c-0.2,0.4-0.3,0.8-0.2,1.2
               s0.4,0.8,0.7,1C1.8,18,2.1,18,2.4,18v0h16.3c0.9,0,1.6-0.7,1.6-1.6c0-0.3-0.1-0.6-0.2-0.8L11.9,1.5c-0.2-0.4-0.6-0.6-1-0.7
               C10.8,0.8,10.6,0.7,10.5,0.7z M10.5,14.8c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.7-0.8h0c0.4,0,0.8,0.3,0.8,0.8S10.9,14.8,10.5,14.8z
                M10.5,11.9c-0.4,0-0.8-0.3-0.8-0.8V6.2c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8v4.9C11.3,11.6,10.9,11.9,10.5,11.9z"
              />
            </g>
          </g>
        </symbol>

        <symbol id="ui-icon-border-good" viewBox="0 0 48 48">
          <g>
            <path
              fill="none"
              d="M24,0c13.3,0,24,10.7,24,24S37.3,48,24,48S0,37.3,0,24S10.7,0,24,0z"
            />
            <path
              d="M24,3c-2.8,0-5.6,0.6-8.2,1.6c-2.5,1.1-4.7,2.6-6.7,4.5s-3.4,4.2-4.5,6.7C3.6,18.4,3,21.2,3,24
            s0.6,5.6,1.6,8.2c1.1,2.5,2.6,4.7,4.5,6.7s4.2,3.4,6.7,4.5c2.6,1.1,5.3,1.6,8.2,1.6s5.6-0.6,8.2-1.6c2.5-1.1,4.7-2.6,6.7-4.5
            s3.4-4.2,4.5-6.7c1.1-2.6,1.6-5.3,1.6-8.2s-0.6-5.6-1.6-8.2c-1.1-2.5-2.6-4.7-4.5-6.7s-4.2-3.4-6.7-4.5C29.6,3.6,26.8,3,24,3
               M24,0c13.3,0,24,10.7,24,24S37.3,48,24,48S0,37.3,0,24S10.7,0,24,0z"
            />
          </g>
          <g transform="translate(14.333 13)">
            <path
              fill="#222222"
              d="M9.7,22.7c-2.4,0-4.4-2-4.4-4.4v-0.5H0c-0.3,0-0.6-0.2-0.7-0.5c-0.1-0.3-0.1-0.6,0.2-0.8l1.7-1.7
            c0.3-0.3,0.5-0.8,0.5-1.2V9.8c0-3.2,1.9-6.1,4.8-7.4c0,0,0,0,0,0c0-0.8,0.4-1.6,1-2.2c0.6-0.6,1.4-0.9,2.3-0.9
            c0.8,0,1.6,0.4,2.2,1c0.6,0.6,0.9,1.3,0.9,2.2c2.9,1.3,4.8,4.2,4.8,7.4v3.9c0,0.5,0.2,0.9,0.5,1.2l1.7,1.7
            c0.2,0.2,0.3,0.5,0.2,0.8c-0.1,0.3-0.4,0.5-0.7,0.5H14v0.5C14,20.7,12.1,22.7,9.7,22.7z M6.8,17.9v0.5c0,1.6,1.3,2.9,2.9,2.9
            c1.6,0,2.9-1.3,2.9-2.9v-0.5H6.8z M13.3,16.4h4.2l-0.4-0.4c-0.6-0.6-0.9-1.4-0.9-2.3V9.8c0-2.8-1.7-5.3-4.3-6.2
            c-0.3-0.1-0.5-0.4-0.5-0.7V2.4c0-0.5-0.2-0.9-0.5-1.2s-0.7-0.5-1.2-0.5c-0.4,0-0.9,0.2-1.2,0.5C8.2,1.5,8,1.9,8,2.4v0.5
            c0,0.3-0.2,0.6-0.5,0.7C4.9,4.5,3.2,7,3.2,9.8v3.9c0,0.9-0.3,1.7-0.9,2.3l-0.4,0.4H13.3z"
            />
          </g>
        </symbol>

        <symbol id="ui-icon-border-risk" viewBox="0 0 48 48">
          <g>
            <path
              fill="none"
              d="M24,0c13.3,0,24,10.7,24,24S37.3,48,24,48S0,37.3,0,24S10.7,0,24,0z"
            />
            <path
              d="M24,3c-2.8,0-5.6,0.6-8.2,1.6c-2.5,1.1-4.7,2.6-6.7,4.5s-3.4,4.2-4.5,6.7C3.6,18.4,3,21.2,3,24
               s0.6,5.6,1.6,8.2c1.1,2.5,2.6,4.7,4.5,6.7s4.2,3.4,6.7,4.5c2.6,1.1,5.3,1.6,8.2,1.6s5.6-0.6,8.2-1.6c2.5-1.1,4.7-2.6,6.7-4.5
               s3.4-4.2,4.5-6.7c1.1-2.6,1.6-5.3,1.6-8.2s-0.6-5.6-1.6-8.2c-1.1-2.5-2.6-4.7-4.5-6.7s-4.2-3.4-6.7-4.5C29.6,3.6,26.8,3,24,3
               M24,0c13.3,0,24,10.7,24,24S37.3,48,24,48S0,37.3,0,24S10.7,0,24,0z"
            />
          </g>
          <g>
            <path
              fill="#222222"
              d="M14.6,34.1c-0.4,0-0.8-0.3-0.8-0.8V16.7c0-1.6,1.3-2.8,2.8-2.8h6.8c0.2,0,0.4,0.1,0.5,0.2l0.5,0.5
            c0,0,0.1,0.1,0.1,0.1l0.3,0.3h8.6c0.3,0,0.5,0.1,0.6,0.4c0.1,0.2,0.1,0.5,0,0.7l-3,5.9l3,5.9c0.1,0.2,0.1,0.5,0,0.7
            c-0.1,0.2-0.4,0.4-0.6,0.4h-8.9c-0.2,0-0.4-0.1-0.5-0.2l-0.8-0.8h-6.5c0,0,0,0,0,0c-0.7,0-1.3,0.6-1.3,1.3v4.2
            C15.4,33.8,15,34.1,14.6,34.1z M24.8,27.4h7.3l-2.6-5.2c-0.1-0.2-0.1-0.5,0-0.7l2.6-5.2h-7.4v4.5c0,0.4-0.3,0.8-0.8,0.8
            s-0.8-0.3-0.8-0.8v-5.4l-0.1-0.1h-6.5c-0.7,0-1.3,0.6-1.3,1.3v10c0.4-0.2,0.9-0.3,1.3-0.3c0,0,0,0,0,0h6.8c0.2,0,0.4,0.1,0.5,0.2
            L24.8,27.4z"
            />
          </g>
        </symbol>

        <symbol id="ui-icon-cancel" viewBox="0 0 18 18">
          <path d="M9,18c-2.3,0-4.6-0.9-6.4-2.6C0.9,13.7,0,11.4,0,9c0-2.4,0.9-4.7,2.6-6.4l0,0c0,0,0,0,0,0C4.3,0.9,6.6,0,9,0 s4.7,0.9,6.4,2.6c3.5,3.5,3.5,9.2,0,12.7l0,0l0,0C13.6,17.1,11.3,18,9,18z M3.3,4.6C2.3,5.8,1.8,7.4,1.8,9c0,1.9,0.7,3.7,2.1,5.1 c2.6,2.6,6.7,2.8,9.5,0.6L3.3,4.6z M4.6,3.3l10.1,10.1c2.2-2.8,2-6.9-0.6-9.5C12.7,2.5,10.9,1.8,9,1.8C7.4,1.8,5.8,2.3,4.6,3.3z" />
        </symbol>

        <symbol id="ui-icon-cash" viewBox="0 0 18 18">
          <path d="M15.5,16.2H6.2c-1.4,0-2.5-1.1-2.5-2.5v-1.2H2.5C1.1,12.5,0,11.3,0,9.9V4.4C0,3,1.1,1.8,2.5,1.8h9.2 c1.4,0,2.5,1.1,2.5,2.5v1.2h1.2c1.4,0,2.5,1.1,2.5,2.5v5.5C18,15,16.9,16.2,15.5,16.2z M5.1,11.8v1.8c0,0.6,0.5,1.2,1.2,1.2h9.2 c0.6,0,1.2-0.5,1.2-1.2V8.1c0-0.6-0.5-1.2-1.2-1.2H6.2c-0.6,0-1.2,0.5-1.2,1.2V11.8z M2.5,3.2c-0.6,0-1.2,0.5-1.2,1.2v5.5 c0,0.6,0.5,1.2,1.2,1.2h1.2v-3c0-1.4,1.1-2.5,2.5-2.5h6.7V4.4c0-0.6-0.5-1.2-1.2-1.2H2.5z M10.8,13.4c-1.4,0-2.5-1.1-2.5-2.5 s1.1-2.5,2.5-2.5s2.5,1.1,2.5,2.5S12.2,13.4,10.8,13.4z M10.8,9.7c-0.6,0-1.2,0.5-1.2,1.2c0,0.6,0.5,1.2,1.2,1.2 c0.6,0,1.2-0.5,1.2-1.2C12,10.2,11.5,9.7,10.8,9.7z" />
        </symbol>

        <symbol id="ui-icon-chat" viewBox="0 0 18 18">
          <path
            d="M15.5,3.7h-1.2V2.5c0-1.4-1.1-2.5-2.5-2.5H2.5C1.1,0,0,1.1,0,2.5v5.5c0,1.4,1.1,2.5,2.5,2.5h1.2v3
      c0,0.3,0.2,0.5,0.4,0.6c0.1,0,0.2,0.1,0.3,0.1c0.2,0,0.4-0.1,0.5-0.2L5,14c0.4,0.2,0.8,0.3,1.2,0.3c0,0,0,0,0,0h3.4l3.5,3.5
      c0.1,0.1,0.3,0.2,0.5,0.2c0.1,0,0.2,0,0.3-0.1c0.3-0.1,0.4-0.4,0.4-0.6v-3h1.2c1.4,0,2.5-1.1,2.5-2.5V6.2C18,4.8,16.9,3.7,15.5,3.7z
       M2.5,9.2c-0.6,0-1.2-0.5-1.2-1.2V2.5c0-0.6,0.5-1.2,1.2-1.2h9.2c0.6,0,1.2,0.5,1.2,1.2v5.5c0,0.6-0.5,1.2-1.2,1.2H8.1
      c-0.2,0-0.4,0.1-0.5,0.2l-2.5,2.5v-2c0-0.4-0.3-0.7-0.7-0.7H2.5z M16.6,11.8c0,0.6-0.5,1.2-1.2,1.2h-1.8c-0.4,0-0.7,0.3-0.7,0.7v2
      l-2.5-2.5c-0.1-0.1-0.3-0.2-0.5-0.2H6.2c0,0,0,0,0,0c0,0-0.1,0-0.1,0l2.3-2.3h3.4c1.4,0,2.5-1.1,2.5-2.5v-3h1.2
      c0.6,0,1.2,0.5,1.2,1.2V11.8z"
          />
        </symbol>

        <symbol id="ui-icon-chat-alt" viewBox="0 0 18 18">
          <path
            d="M6.2,17.5c-0.1,0-0.2,0-0.3-0.1c-0.3-0.1-0.4-0.4-0.4-0.6v-3.9h-3c-1.4,0-2.5-1.1-2.5-2.5V3
	c0-1.4,1.1-2.5,2.5-2.5h12.9C16.9,0.5,18,1.6,18,3v7.4c0,1.4-1.1,2.5-2.5,2.5h-4.3l-4.4,4.4C6.6,17.5,6.4,17.5,6.2,17.5z M2.5,1.8
	C1.9,1.8,1.4,2.4,1.4,3v7.4c0,0.6,0.5,1.2,1.2,1.2h3.7c0.4,0,0.7,0.3,0.7,0.7v2.9l3.4-3.4c0.1-0.1,0.3-0.2,0.5-0.2h4.6
	c0.6,0,1.2-0.5,1.2-1.2V3c0-0.6-0.5-1.2-1.2-1.2H2.5z M12.7,7.4c-0.4,0-0.7-0.3-0.7-0.7C12,6.3,12.3,6,12.7,6h0
	c0.4,0,0.7,0.3,0.7,0.7C13.4,7.1,13.1,7.4,12.7,7.4z M9,7.4c-0.4,0-0.7-0.3-0.7-0.7C8.3,6.3,8.6,6,9,6h0c0.4,0,0.7,0.3,0.7,0.7
	C9.7,7.1,9.4,7.4,9,7.4z M5.3,7.4c-0.4,0-0.7-0.3-0.7-0.7C4.6,6.3,4.9,6,5.3,6h0C5.7,6,6,6.3,6,6.7C6,7.1,5.7,7.4,5.3,7.4z"
          />
        </symbol>

        <symbol id="ui-icon-chat-solid" viewBox="0 0 18 18">
          <path d="M0,2.8c0-1.2,1-2.2,2.2-2.2h7.9c1.2,0,2.2,1,2.2,2.2v4.5c0,1.2-1,2.2-2.2,2.2H7.9l-3.4,3.4V9.6H2.2C1,9.6,0,8.6,0,7.3V2.8z" />
          <path d="M14.6,5.1v6.8H8.8l-2,2c0.3,0.2,0.7,0.3,1.1,0.3h2.2l3.4,3.4v-3.4h2.2c1.2,0,2.2-1,2.2-2.2V7.3c0-1.2-1-2.2-2.2-2.2H14.6z" />
        </symbol>

        <symbol id="ui-icon-checklist" viewBox="0 0 18 18">
          <path d="M13.5,18h-9c-1.5,0-2.7-1.2-2.7-2.7V4.5C1.8,3,3,1.8,4.5,1.8h1.1C5.9,0.8,6.9,0,8.1,0h1.8c1.2,0,2.2,0.8,2.5,1.8h1.1 c1.5,0,2.7,1.2,2.7,2.7v10.8C16.2,16.8,15,18,13.5,18z M4.5,3.6C4,3.6,3.6,4,3.6,4.5v10.8c0,0.5,0.4,0.9,0.9,0.9h9 c0.5,0,0.9-0.4,0.9-0.9V4.5c0-0.5-0.4-0.9-0.9-0.9h-1.1c-0.4,1-1.4,1.8-2.5,1.8H8.1c-1.2,0-2.2-0.8-2.5-1.8H4.5z M7.2,2.7 c0,0.5,0.4,0.9,0.9,0.9h1.8c0.5,0,0.9-0.4,0.9-0.9s-0.4-0.9-0.9-0.9H8.1C7.6,1.8,7.2,2.2,7.2,2.7z M11.7,13.5H9 c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9h2.7c0.5,0,0.9,0.4,0.9,0.9S12.2,13.5,11.7,13.5z M6.3,13.5c-0.5,0-0.9-0.4-0.9-0.9 s0.4-0.9,0.9-0.9h0c0.5,0,0.9,0.4,0.9,0.9S6.8,13.5,6.3,13.5z M11.7,9.9H9C8.5,9.9,8.1,9.5,8.1,9S8.5,8.1,9,8.1h2.7 c0.5,0,0.9,0.4,0.9,0.9S12.2,9.9,11.7,9.9z M6.3,9.9C5.8,9.9,5.4,9.5,5.4,9s0.4-0.9,0.9-0.9h0c0.5,0,0.9,0.4,0.9,0.9 S6.8,9.9,6.3,9.9z" />
        </symbol>

        <symbol id="ui-icon-clipboard" viewBox="0 0 18 18">
          <path d="M13.6,18H4.4c-1.4,0-2.6-1.2-2.6-2.6v-11C1.8,3,3,1.8,4.4,1.8h1.2C5.9,0.8,6.9,0,8.1,0h1.8c1.2,0,2.2,0.8,2.5,1.8h1.2c1.4,0,2.6,1.2,2.6,2.6v11C16.2,16.8,15,18,13.6,18z M4.4,3.4c-0.6,0-1.1,0.5-1.1,1.1v11c0,0.6,0.5,1.1,1.1,1.1h9.1c0.6,0,1.1-0.5,1.1-1.1v-11c0-0.6-0.5-1.1-1.1-1.1h-1.2c-0.3,1.1-1.3,1.8-2.5,1.8H8.1c-1.2,0-2.2-0.8-2.5-1.8H4.4z M7,2.6c0,0.6,0.5,1,1.1,1h1.8c0.6,0,1-0.5,1.1-1c0,0,0,0,0,0c0-0.6-0.5-1.1-1.1-1.1H8.1C7.5,1.5,7,2,7,2.6C7,2.6,7,2.6,7,2.6z M11.7,13.4H9c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h2.7c0.4,0,0.8,0.3,0.8,0.8S12.2,13.4,11.7,13.4z M6.3,13.4c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h0c0.4,0,0.8,0.3,0.8,0.8S6.7,13.4,6.3,13.4z M11.7,9.8H9C8.6,9.8,8.2,9.4,8.2,9S8.6,8.2,9,8.2h2.7c0.4,0,0.8,0.3,0.8,0.8S12.2,9.8,11.7,9.8z M6.3,9.8C5.8,9.8,5.5,9.4,5.5,9s0.3-0.8,0.8-0.8h0C6.7,8.2,7,8.6,7,9S6.7,9.8,6.3,9.8z" />
        </symbol>

        {/* Paging icons - first back next last */}
        <symbol id="ui-icon-paging-last" viewBox="0 0 16 16">
          <path d="M8.5,15.5c-0.3,0-0.6-0.1-0.9-0.4c-0.5-0.5-0.5-1.2,0-1.7L13.1,8L7.6,2.5C7.1,2,7.1,1.3,7.6,0.8s1.2-0.5,1.7,0l6.3,6.3 c0.5,0.5,0.5,1.2,0,1.7l-6.3,6.3C9.1,15.4,8.8,15.5,8.5,15.5z M1.2,15.5c-0.3,0-0.6-0.1-0.9-0.4c-0.5-0.5-0.5-1.2,0-1.7L5.8,8 L0.4,2.5c-0.5-0.5-0.5-1.2,0-1.7s1.2-0.5,1.7,0l6.3,6.3c0.5,0.5,0.5,1.2,0,1.7l-6.3,6.3C1.8,15.4,1.5,15.5,1.2,15.5z" />
        </symbol>

        <symbol id="ui-icon-paging-previous" viewBox="0 0 16 16">
          <path d="M10.3,15.2L4,8.9C3.5,8.4,3.5,7.6,4,7.1l6.3-6.3c0.5-0.5,1.2-0.5,1.7,0s0.5,1.2,0,1.7L6.5,8l5.5,5.5c0.5,0.5,0.5,1.2,0,1.7 c-0.2,0.2-0.5,0.4-0.9,0.4C10.9,15.5,10.5,15.4,10.3,15.2z" />
        </symbol>

        <symbol id="ui-icon-paging-next" viewBox="0 0 16 16">
          <path d="M4.8,15.5c-0.3,0-0.6-0.1-0.9-0.4c-0.5-0.5-0.5-1.2,0-1.7L9.5,8L4,2.5C3.5,2,3.5,1.3,4,0.8s1.2-0.5,1.7,0L12,7.1 c0.5,0.5,0.5,1.2,0,1.7l-6.3,6.3C5.5,15.4,5.1,15.5,4.8,15.5z" />
        </symbol>

        <symbol id="ui-icon-paging-first" viewBox="0 0 16 16">
          <path d="M6.7,15.2L0.4,8.9c-0.5-0.5-0.5-1.2,0-1.7l6.3-6.3c0.5-0.5,1.2-0.5,1.7,0s0.5,1.2,0,1.7L2.9,8l5.5,5.5 c0.5,0.5,0.5,1.2,0,1.7c-0.2,0.2-0.5,0.4-0.9,0.4S6.9,15.4,6.7,15.2z M13.9,15.2L7.6,8.9c-0.5-0.5-0.5-1.2,0-1.7l6.3-6.3 c0.5-0.5,1.2-0.5,1.7,0c0.5,0.5,0.5,1.2,0,1.7L10.2,8l5.5,5.5c0.5,0.5,0.5,1.2,0,1.7c-0.2,0.2-0.5,0.4-0.9,0.4 C14.5,15.5,14.2,15.4,13.9,15.2z" />
        </symbol>

        {/* <!-- paging icons --> */}
        <symbol id="ui-icon-next" viewBox="0 0 24 24">
          <path d="M10.5,6.5c0.3,0,0.5,0.1,0.7,0.3l4,4c0.4,0.4,0.4,1,0,1.4l-4,4c-0.4,0.4-1,0.4-1.4,0s-0.4-1,0-1.4l3.3-3.3 L9.8,8.2c-0.4-0.4-0.4-1,0-1.4C10,6.6,10.2,6.5,10.5,6.5z" />
        </symbol>

        <symbol id="ui-icon-next-hover" viewBox="0 0 24 24">
          <path d="M21,24H3c-1.7,0-3-1.3-3-3V3c0-1.7,1.3-3,3-3h18c1.7,0,3,1.3,3,3v18C24,22.7,22.7,24,21,24z" />
          <path
            fill="#FFFFFF"
            d="M10.5,6.5c0.3,0,0.5,0.1,0.7,0.3l4,4c0.4,0.4,0.4,1,0,1.4l-4,4c-0.4,0.4-1,0.4-1.4,0s-0.4-1,0-1.4l3.3-3.3 L9.8,8.2c-0.4-0.4-0.4-1,0-1.4C10,6.6,10.2,6.5,10.5,6.5z"
          />
        </symbol>

        <symbol id="ui-icon-previous" viewBox="0 0 24 24">
          <path d="M13.5,17.5c-0.3,0-0.5-0.1-0.7-0.3l-4-4c-0.4-0.4-0.4-1,0-1.4l4-4c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4 l-3.3,3.3l3.3,3.3c0.4,0.4,0.4,1,0,1.4C14,17.4,13.8,17.5,13.5,17.5z" />
        </symbol>

        <symbol id="ui-icon-previous-hover" viewBox="0 0 24 24">
          <path d="M3,0h18c1.7,0,3,1.3,3,3v18c0,1.7-1.3,3-3,3H3c-1.7,0-3-1.3-3-3V3C0,1.3,1.3,0,3,0z" />
          <path
            fill="#FFFFFF"
            d="M13.5,17.5c-0.3,0-0.5-0.1-0.7-0.3l-4-4c-0.4-0.4-0.4-1,0-1.4l4-4c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4 l-3.3,3.3l3.3,3.3c0.4,0.4,0.4,1,0,1.4C14,17.4,13.8,17.5,13.5,17.5z"
          />
        </symbol>

        <symbol id="ui-icon-dot" viewBox="0 0 24 24">
          <g>
            <circle fill="#FFFFFF" cx="12" cy="12" r="7.5" />
            <path d="M12,5c3.9,0,7,3.1,7,7s-3.1,7-7,7s-7-3.1-7-7S8.1,5,12,5 M12,4c-4.4,0-8,3.6-8,8s3.6,8,8,8s8-3.6,8-8 S16.4,4,12,4L12,4z" />
          </g>
        </symbol>

        <symbol id="ui-icon-dot-hover" viewBox="0 0 24 24">
          <circle cx="12" cy="12" r="9.5" />
          <path d="M12,3c5,0,9,4,9,9s-4,9-9,9s-9-4-9-9S7,3,12,3 M12,2C6.5,2,2,6.5,2,12s4.5,10,10,10s10-4.5,10-10 S17.5,2,12,2L12,2z" />
        </symbol>

        <symbol id="ui-icon-dot-current" viewBox="0 0 24 24">
          <circle cx="12" cy="12" r="8" />
        </symbol>

        <symbol id="ui-icon-checkmark" viewBox="0 0 8 8">
          <path
            fill="#FFFFFF"
            d="M1.7,7.4c0,0-0.1,0-0.1,0C1.3,7.3,1.1,7.2,1,7L0.1,4.8c-0.2-0.4,0-0.8,0.4-1c0.4-0.2,0.8,0,1,0.4L2,5.4 l4.7-4.6c0.3-0.3,0.8-0.3,1.1,0C8,1.1,8,1.6,7.7,1.9L2.3,7.2C2.1,7.3,1.9,7.4,1.7,7.4z"
          />
        </symbol>

        <symbol id="ui-icon-circle-checkmark" viewBox="0 0 18 18">
          <path d="M9,18c-5,0-9-4-9-9s4-9,9-9s9,4,9,9S14,18,9,18z M9,1.4C4.8,1.4,1.4,4.8,1.4,9s3.4,7.6,7.6,7.6s7.6-3.4,7.6-7.6S13.2,1.4,9,1.4z M8.1,11.5c-0.2,0-0.4-0.1-0.5-0.2L5.7,9.5c-0.3-0.3-0.3-0.7,0-1c0.3-0.3,0.7-0.3,1,0l1.4,1.4l3.2-3.2c0.3-0.3,0.7-0.3,1,0c0.3,0.3,0.3,0.7,0,1l-3.7,3.7C8.4,11.5,8.3,11.5,8.1,11.5z" />
        </symbol>

        <symbol id="ui-icon-circle-close" viewBox="0 0 16 16">
          <circle cx="8" cy="8" r="8" />
          <line
            fill="none"
            stroke="#ffffff"
            x2="6"
            y2="6"
            transform="translate(5 5)"
            strokeLinecap="round"
            strokeWidth="1.5"
          />
          <line
            fill="none"
            stroke="#ffffff"
            x2="6"
            y2="6"
            transform="translate(11 5) rotate(90)"
            strokeLinecap="round"
            strokeWidth="1.5"
          />
        </symbol>

        <symbol id="ui-icon-circle-solid-checkmark" viewBox="0 0 16 16">
          <circle cx="8" cy="8" r="8" />
          <path
            fill="none"
            stroke="#ffffff"
            d="M4.854,8.406l2.083,2.245,4.95-4.95"
            transform="translate(-0.354 -0.201)"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          />
        </symbol>

        <symbol id="ui-icon-circle-solid-exclamation" viewBox="0 0 18 18">
          <circle cx="9" cy="9" r="9" />
          <path
            fill="#FFFFFF"
            d="M9,10.2c-0.5,0-0.8-0.4-0.8-0.8V4.5C8.2,4,8.5,3.7,9,3.7S9.8,4,9.8,4.5v4.8C9.8,9.8,9.5,10.2,9,10.2z"
          />
          <path
            fill="#FFFFFF"
            d="M9,14c-0.2,0-0.4-0.1-0.6-0.2c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3c0-0.1,0.1-0.2,0.2-0.3c0.3-0.3,0.9-0.3,1.2,0c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.3C9.4,13.9,9.2,14,9,14z"
          />
        </symbol>

        <symbol id="ui-icon-clock" viewBox="0 0 20 20">
          <path d="M9,18c-5,0-9-4-9-9c0-5,4-9,9-9c5,0,9,4,9,9C18,14,14,18,9,18z M9,1.5C4.9,1.5,1.5,4.9,1.5,9c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5C16.5,4.9,13.1,1.5,9,1.5z M11.7,12.5c-0.2,0-0.4-0.1-0.5-0.2L8.5,9.5C8.3,9.4,8.2,9.2,8.2,9V5.3c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8v3.3l2.5,2.5c0.3,0.3,0.3,0.8,0,1.1C12.1,12.4,11.9,12.5,11.7,12.5z" />
        </symbol>

        {/* <!--
   <symbol id="ui-icon-circle-solid-exclamation" viewBox="0 0 18 18">
      <circle cx="9" cy="9" r="9"/>
      <g transform="translate(0 1.914)">
         <path fill="#FFFFFF" d="M9,8.2c-0.5,0-0.8-0.4-0.8-0.8V2.6c0-0.5,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8v4.8C9.8,7.9,9.5,8.2,9,8.2z"/>
         <path fill="#FFFFFF" d="M9,12.1c-0.2,0-0.4-0.1-0.6-0.2c-0.1-0.1-0.1-0.2-0.2-0.3c0-0.1-0.1-0.2-0.1-0.3c0-0.1,0-0.2,0.1-0.3c0-0.1,0.1-0.2,0.2-0.3c0.3-0.3,0.9-0.3,1.2,0c0.1,0.1,0.1,0.2,0.2,0.3c0,0.1,0.1,0.2,0.1,0.3c0,0.1,0,0.2-0.1,0.3c0,0.1-0.1,0.2-0.2,0.3C9.4,12,9.2,12.1,9,12.1z"/>
      </g>
   </symbol> --> */}

        <symbol id="ui-icon-close" viewBox="0 0 20 20">
          <path d="M17,4.4L15.6,3L10,8.6L4.4,3L3,4.4L8.6,10L3,15.6L4.4,17l5.6-5.6l5.6,5.6l1.4-1.4L11.4,10L17,4.4z" />
        </symbol>

        <symbol id="ui-icon-close-x" viewBox="0 0 18 18">
          <g transform="translate(1.061 1.061)">
            <path d="M0,16.9c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1.1,0-1.5L15.1-0.8c0.4-0.4,1.1-0.4,1.5,0c0.4,0.4,0.4,1.1,0,1.5L0.7,16.6C0.5,16.8,0.3,16.9,0,16.9z" />
            <path d="M15.9,16.9c-0.3,0-0.5-0.1-0.7-0.3L-0.7,0.8c-0.4-0.4-0.4-1.1,0-1.5s1.1-0.4,1.5,0l15.9,15.9c0.4,0.4,0.4,1.1,0,1.5C16.4,16.8,16.1,16.9,15.9,16.9z" />
          </g>
        </symbol>

        <symbol id="ui-icon-collection" viewBox="0 0 18 18">
          <path d="M15.5,18H2.5C1.1,18,0,16.9,0,15.5V9.9c0-1.2,0.8-2.1,1.8-2.4V6.2c0-1.2,0.8-2.1,1.8-2.4V2.5 C3.7,1.1,4.8,0,6.2,0h5.5c1.4,0,2.5,1.1,2.5,2.5v1.2c1.1,0.3,1.8,1.3,1.8,2.4v1.2C17.2,7.8,18,8.8,18,9.9v5.5 C18,16.9,16.9,18,15.5,18z M2.5,8.8c-0.6,0-1.2,0.5-1.2,1.2v5.5c0,0.6,0.5,1.2,1.2,1.2h12.9c0.6,0,1.2-0.5,1.2-1.2V9.9 c0-0.6-0.5-1.2-1.2-1.2H2.5z M3.2,7.4h11.5V6.2c0-0.6-0.5-1.2-1.2-1.2H4.4c-0.6,0-1.2,0.5-1.2,1.2V7.4z M5.1,3.7h7.8V2.5 c0-0.6-0.5-1.2-1.2-1.2H6.2c-0.6,0-1.2,0.5-1.2,1.2V3.7z" />
        </symbol>

        <symbol id="ui-icon-convert" viewBox="0 0 18 18">
          <path d="M5.4,18c-0.2,0-0.4-0.1-0.6-0.2l-3.6-3.6C1.1,14,1,14,1,13.9c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1-0.1-0.2-0.1-0.3v0c0,0,0,0,0,0 v0v0c0,0,0,0,0,0l0,0c0,0,0,0,0,0s0,0,0,0l0,0c0,0,0,0,0,0v0v0c0,0,0,0,0,0v0c0-0.1,0-0.2,0.1-0.3c0,0,0,0,0,0c0,0,0,0,0,0 c0-0.1,0.1-0.2,0.2-0.3l3.6-3.6C5.1,9,5.6,9,5.9,9.3c0.3,0.3,0.3,0.8,0,1.1l-2.3,2.3h9c0.4,0,0.8,0.4,0.8,0.8s-0.4,0.8-0.8,0.8h-9 l2.3,2.3c0.3,0.3,0.3,0.8,0,1.1C5.8,17.9,5.6,18,5.4,18z M12.6,8.9c-0.2,0-0.4-0.1-0.6-0.2c-0.3-0.3-0.3-0.8,0-1.1l2.3-2.3h-9 c-0.4,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8h9l-2.3-2.3c-0.3-0.3-0.3-0.8,0-1.1c0.3-0.3,0.8-0.3,1.1,0l3.6,3.6C16.9,4,17,4,17,4.1 c0,0,0,0,0,0c0,0.1,0.1,0.2,0.1,0.3l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0c0,0,0,0,0,0s0,0,0,0l0,0c0,0,0,0,0,0c0,0,0,0,0,0l0,0 c0,0.1,0,0.2-0.1,0.3c0,0,0,0,0,0c0,0.1-0.1,0.2-0.2,0.3l-3.6,3.6C13.1,8.8,12.8,8.9,12.6,8.9z" />
        </symbol>

        <symbol id="ui-icon-document" viewBox="0 0 18 18">
          <path d="M13.6,18H4.4c-1.4,0-2.6-1.2-2.6-2.6V2.6C1.8,1.2,3,0,4.4,0h5.1c0.5,0,0.9,0.2,1.2,0.5l4.9,4.9c0.3,0.3,0.5,0.8,0.5,1.2v8.7c0,0.7-0.3,1.4-0.8,1.9C14.9,17.7,14.3,18,13.6,18z M4.4,1.6c-0.6,0-1,0.5-1,1v12.8c0,0.6,0.5,1,1,1h9.1c0.3,0,0.5-0.1,0.7-0.3c0.2-0.2,0.3-0.5,0.3-0.7V6.6l-5-5L4.4,1.6z M11.7,13.4H6.3c-0.4,0-0.8-0.4-0.8-0.8s0.4-0.8,0.8-0.8h5.5c0.4,0,0.8,0.4,0.8,0.8S12.2,13.4,11.7,13.4z M11.7,9.8H6.3C5.8,9.8,5.5,9.4,5.5,9s0.4-0.8,0.8-0.8h5.5c0.4,0,0.8,0.4,0.8,0.8S12.2,9.8,11.7,9.8z" />
        </symbol>

        <symbol id="ui-icon-document-download" viewBox="0 0 18 18">
          <path d="M13.6,18L13.6,18H4.4c-1.4,0-2.6-1.2-2.6-2.6V2.6C1.8,1.2,3,0,4.4,0h5.1c0.4,0,0.9,0.2,1.2,0.5l5,5 c0.3,0.3,0.5,0.7,0.5,1.2v8.8C16.2,16.8,15,18,13.6,18z M4.4,1.5C3.8,1.5,3.4,2,3.4,2.6v12.8c0,0.6,0.5,1.1,1.1,1.1h9.1 c0.6,0,1.1-0.5,1.1-1.1V6.6c0,0,0-0.1,0-0.1l-5-5c0,0-0.1,0-0.1,0H4.4z M9,13.4c-0.2,0-0.4-0.1-0.5-0.2c0,0,0,0,0,0l-2.7-2.7 c-0.3-0.3-0.3-0.8,0-1.1c0.3-0.3,0.8-0.3,1.1,0l1.4,1.4V7.2c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8v3.6l1.4-1.4 c0.3-0.3,0.8-0.3,1.1,0c0.3,0.3,0.3,0.8,0,1.1l-2.7,2.7C9.4,13.4,9.2,13.4,9,13.4z" />
        </symbol>

        <symbol id="ui-icon-document-upload" viewBox="0 0 18 18">
          <path d="M13.6,18L13.6,18H4.4c-1.4,0-2.6-1.2-2.6-2.6V2.6C1.8,1.2,3,0,4.4,0h5.1c0.4,0,0.9,0.2,1.2,0.5l5,5c0.3,0.3,0.5,0.7,0.5,1.2 v8.8C16.2,16.8,15,18,13.6,18z M4.4,1.5c-0.6,0-1,0.5-1,1.1v12.8c0,0.6,0.5,1.1,1.1,1.1h9.1c0.6,0,1.1-0.5,1.1-1.1V6.6V6.5l-5-5H9.6 H4.4z M9.7,5.6l2.7,2.7c0.3,0.3,0.3,0.8,0,1.1s-0.8,0.3-1.1,0L9.9,8v3.6c0,0.5-0.3,0.8-0.8,0.8S8.3,12,8.3,11.6V8L6.9,9.4 c-0.3,0.3-0.8,0.3-1.1,0s-0.3-0.8,0-1.1l2.7-2.7l0,0C8.6,5.5,8.8,5.4,9,5.4S9.4,5.4,9.7,5.6z" />
        </symbol>

        <symbol id="ui-icon-document-multiple" viewBox="0 0 18 18">
          <path d="M10.8,18H3.5c-1.4,0-2.5-1.1-2.5-2.5V6.2c0-1.4,1.1-2.5,2.5-2.5h1.2V2.5C4.6,1.1,5.8,0,7.2,0h4.2 c0.4,0,0.8,0.2,1.1,0.5l4.1,4.1c0.3,0.3,0.5,0.7,0.5,1.1v6.1c0,1.4-1.1,2.5-2.5,2.5h-1.2v1.2C13.4,16.9,12.2,18,10.8,18z M3.5,5.1 c-0.6,0-1.2,0.5-1.2,1.2v9.2c0,0.6,0.5,1.2,1.2,1.2h7.4c0.6,0,1.2-0.5,1.2-1.2v-1.2H7.2c-1.4,0-2.5-1.1-2.5-2.5V5.1H3.5z M12.7,12.9 h1.8c0.6,0,1.2-0.5,1.2-1.2V5.7c0-0.1,0-0.1-0.1-0.2l-4.1-4.1c0,0-0.1-0.1-0.2-0.1H7.2C6.5,1.4,6,1.9,6,2.5v9.2 c0,0.6,0.5,1.2,1.2,1.2H12.7z" />
        </symbol>

        <symbol id="ui-icon-edit" viewBox="0 0 18 18">
          <path d="M13.4,18H2.7C1.2,18,0,16.8,0,15.3V4.6c0-1.5,1.2-2.7,2.7-2.7h4.8c0.4,0,0.8,0.3,0.8,0.8S8,3.5,7.5,3.5H2.7C2.1,3.5,1.5,4,1.5,4.6v10.7c0,0.6,0.5,1.2,1.2,1.2h10.7c0.6,0,1.2-0.5,1.2-1.2v-4.8c0-0.4,0.3-0.8,0.8-0.8c0.4,0,0.8,0.3,0.8,0.8v4.8C16.1,16.8,14.9,18,13.4,18z M8.4,13.2H5.6c-0.4,0-0.8-0.3-0.8-0.8V9.6c0-0.2,0.1-0.4,0.2-0.5l8.3-8.3l0,0l0,0l0,0c1.1-1.1,2.8-1.1,3.8,0c1.1,1.1,1.1,2.8,0,3.8l-8.3,8.3C8.8,13.1,8.6,13.2,8.4,13.2z M6.4,11.6H8l8.1-8.1c0.5-0.5,0.5-1.2,0-1.6c-0.5-0.5-1.2-0.5-1.6,0L6.4,10V11.6z" />
        </symbol>

        <symbol id="ui-icon-edit-solid" viewBox="0 0 18 18">
          <circle cx="9" cy="9" r="9" />
          <path
            fill="#ffffff"
            d="M12.5,5.6c-0.6-0.6-1.7-0.6-2.3,0l0,0l-5.1,4.8C5,10.5,5,10.6,5,10.8v1.6c0,0.3,0.2,0.5,0.5,0.5h1.7 c0.1,0,0.2,0,0.3-0.1L12.6,8l0.1-0.1C13.2,7.2,13.1,6.2,12.5,5.6z M6.9,11.9h-1V11l0.3-0.3l1,1L6.9,11.9z M11.9,7.2l-4,3.8l-1-1 l3.9-3.7c0.3-0.3,0.7-0.3,1,0C12.1,6.5,12.1,6.9,11.9,7.2z"
          />
        </symbol>

        <symbol id="ui-icon-edit-stroke" viewBox="0 0 18 18">
          <circle fill="#ffffff" cx="9" cy="9" r="9" />
          <path d="M12.5,5.6c-0.6-0.6-1.7-0.6-2.3,0c0,0,0,0,0,0l-5.1,4.8C5,10.5,5,10.6,5,10.8v1.6c0,0.3,0.2,0.5,0.5,0.5 h1.7c0.1,0,0.2,0,0.3-0.1l5.1-4.8c0,0,0.1-0.1,0.1-0.1C13.2,7.2,13.1,6.2,12.5,5.6z M6.9,11.9h-1V11l0.3-0.3l1,1L6.9,11.9z M11.9,7.2l-4,3.8l-1-1l3.9-3.7c0.3-0.3,0.7-0.3,1,0C12.1,6.5,12.1,6.9,11.9,7.2z" />
          <path d="M9,18c-5,0-9-4-9-9s4-9,9-9s9,4,9,9S14,18,9,18z M9,0.9C4.6,0.9,0.9,4.6,0.9,9s3.6,8.1,8.1,8.1 s8.1-3.6,8.1-8.1S13.4,0.9,9,0.9z" />
        </symbol>

        <symbol id="ui-icon-edit-calendar" viewBox="0 0 18 18">
          <path d="M5.5,17.5V16h-3c-0.6,0-1-0.5-1-1V4.3c0-0.6,0.5-1,1-1h1.9v1c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-1h5.6 v1c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-1H15c0.6,0,1,0.5,1,1v1.2c0,0,0,0,0.1,0c0.5,0,1,0.1,1.4,0.3V4.3c0-1.4-1.1-2.5-2.5-2.5 h-1.9v-1c0-0.4-0.3-0.8-0.8-0.8s-0.8,0.3-0.8,0.8v1H5.9v-1C5.9,0.3,5.6,0,5.2,0S4.4,0.3,4.4,0.8v1H2.5C1.1,1.8,0,2.9,0,4.3V15 c0,1.4,1.1,2.5,2.5,2.5L5.5,17.5C5.5,17.5,5.5,17.5,5.5,17.5z" />
          <path d="M17.4,7.5C17.1,7.2,16.6,7,16.1,7s-1,0.2-1.4,0.6c0,0,0,0,0,0l-7.6,7.6C7.1,15.2,7,15.3,7,15.5v2C7,17.8,7.2,18,7.5,18h1.9 c0.1,0,0.3-0.1,0.4-0.2l7.6-7.6C18.2,9.5,18.2,8.3,17.4,7.5z M9.2,16.9H8.1v-1.2l6.2-6.2l1.2,1.2L9.2,16.9z M16.7,9.5l-0.4,0.4 l-1.2-1.2l0.4-0.4c0.2-0.2,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2C17,8.6,17,9.2,16.7,9.5z" />
        </symbol>

        <symbol id="ui-icon-edit-payment" viewBox="0 0 18 18">
          <path d="M6.1,14.1l1.2-1.2H3.5c-1.1,0-2-0.9-2-2V6.1h12.7c0.6-0.4,1.2-0.6,1.9-0.6c0.7,0,1.3,0.2,1.9,0.6V3.5 C18,1.6,16.4,0,14.5,0h-11C1.6,0,0,1.6,0,3.5v7.3c0,1.9,1.6,3.5,3.5,3.5h2.4C6,14.2,6,14.2,6.1,14.1z M1.5,3.5c0-1.1,0.9-2,2-2h11 c1.1,0,2,0.9,2,2v1.1h-15V3.5z" />
          <path d="M4.4,9.2C4,9.2,3.7,9.5,3.7,9.9c0,0.4,0.3,0.8,0.8,0.8h0.9c0.4,0,0.8-0.3,0.8-0.8c0-0.4-0.3-0.8-0.8-0.8 H4.4z" />
          <path d="M9.9,9.2H9c-0.4,0-0.8,0.3-0.8,0.8c0,0.4,0.3,0.8,0.8,0.8h0.5l1.1-1.1C10.5,9.3,10.2,9.2,9.9,9.2z" />
          <path d="M17.4,7.5C17.1,7.2,16.6,7,16.1,7s-1,0.2-1.4,0.6c0,0,0,0,0,0l-7.6,7.6C7.1,15.2,7,15.3,7,15.5v2C7,17.8,7.2,18,7.5,18h1.9 c0.1,0,0.3-0.1,0.4-0.2l7.6-7.6C18.2,9.5,18.2,8.3,17.4,7.5z M9.2,16.9H8.1v-1.2l6.2-6.2l1.2,1.2L9.2,16.9z M16.7,9.5l-0.4,0.4 l-1.2-1.2l0.4-0.4c0.2-0.2,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2C17,8.6,17,9.2,16.7,9.5z" />
        </symbol>

        <symbol id="ui-icon-edit-settings" viewBox="0 0 18 18">
          <g>
            <path
              d="M8.2,11.7c-1.7,0-3.1-1.4-3.1-3.1s1.4-3.1,3.1-3.1s3.1,1.4,3.1,3.1c0,0.3,0,0.6-0.1,0.9L9,11.6C8.8,11.6,8.5,11.7,8.2,11.7
              z M8.2,6.7c-1,0-1.8,0.8-1.8,1.8s0.8,1.8,1.8,1.8c1,0,1.8-0.8,1.8-1.8S9.2,6.7,8.2,6.7z"
            />
            <path d="M13.9,4L13.9,4L13.9,4z" />
            <path d="M9.7,1.9C9.7,1.9,9.7,1.9,9.7,1.9C9.7,1.9,9.7,1.9,9.7,1.9z" />
            <path d="M6.7,1.9C6.7,1.9,6.7,1.9,6.7,1.9C6.7,1.9,6.7,1.9,6.7,1.9z" />
            <path d="M3.2,3.9C3.2,3.9,3.2,3.9,3.2,3.9C3.2,3.9,3.2,3.9,3.2,3.9z" />
            <path d="M1.5,6L1.5,6L1.5,6z" />
            <path d="M1.5,11.1L1.5,11.1L1.5,11.1z" />
            <path d="M1.7,6.5C1.7,6.5,1.7,6.5,1.7,6.5C1.7,6.5,1.7,6.5,1.7,6.5z" />
            <path d="M2.6,13.1C2.6,13.1,2.6,13.1,2.6,13.1C2.6,13.1,2.6,13.1,2.6,13.1z" />
            <path
              d="M17.4,7.5C17,7.2,16.5,7,16.1,7s-1,0.2-1.4,0.6c0,0,0,0,0,0l-7.6,7.6C7,15.2,7,15.3,7,15.5v2C7,17.8,7.2,18,7.5,18h1.9
              c0.1,0,0.3-0.1,0.4-0.2l7.6-7.6C18.2,9.5,18.2,8.3,17.4,7.5z M9.2,16.9H8.1v-1.2l6.2-6.2l1.2,1.2L9.2,16.9z M16.6,9.5l-0.4,0.4
              L15,8.8l0.4-0.4c0.2-0.2,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2C17,8.6,17,9.2,16.6,9.5z"
            />
            <path
              d="M14.1,2.7c-0.2-0.2-0.5-0.3-0.8-0.3c-0.2,0-0.4,0-0.5,0.1L11.9,3l-0.1-0.1c-0.3-0.2-0.7-0.4-1.1-0.6l-0.1-0.1V1.2
              c0-0.5-0.3-0.9-0.8-1C9.2,0.1,8.6,0,8,0C7.4,0,6.8,0.1,6.2,0.2c-0.5,0.1-0.8,0.5-0.8,1v1.1L5.3,2.3C4.9,2.5,4.5,2.7,4.2,3L4.1,3
              L3.2,2.5C3,2.4,2.8,2.3,2.6,2.3c-0.3,0-0.6,0.1-0.7,0.3C1,3.6,0.4,4.7,0,5.9c-0.1,0.5,0,1,0.5,1.2l0.9,0.5l0,0.1c0,0.4,0,0.8,0,1.2
              l0,0.1L0.5,9.6C0.1,9.9-0.1,10.4,0,10.8c0.4,1.2,1,2.3,1.8,3.2c0.2,0.2,0.5,0.3,0.8,0.3c0.2,0,0.4,0,0.5-0.1l0.9-0.5l0.1,0.1
              c0.3,0.2,0.7,0.4,1.1,0.6l0.1,0.1v0l0.4,0.2c0.1-0.2,0.2-0.3,0.3-0.5l0.6-0.6v0l-0.4-0.2c-0.6-0.2-1.2-0.5-1.7-1l-0.4-0.3l-1.5,0.9
              l-0.1-0.1c-0.5-0.6-0.9-1.3-1.2-2l-0.1-0.2l1.5-0.9L2.8,9.3C2.7,8.7,2.7,8,2.8,7.4l0.1-0.5L1.4,6l0.1-0.2c0.3-0.7,0.7-1.4,1.2-2
              l0.1-0.1l1.5,0.9l0.4-0.3c0.5-0.4,1.1-0.8,1.7-1l0.4-0.2V1.4l0.2,0C7.2,1.3,7.6,1.3,8,1.3c0.4,0,0.8,0,1.2,0.1l0.2,0v1.8l0.4,0.2
              c0.6,0.2,1.2,0.5,1.7,1l0.4,0.3l1.5-0.9l0.1,0.1c0.5,0.6,0.9,1.3,1.2,2l0,0c0.4-0.2,0.8-0.3,1.2-0.3c0,0,0.1,0,0.1,0
              C15.5,4.5,14.9,3.5,14.1,2.7z"
            />
          </g>
        </symbol>

        <symbol id="ui-icon-edit-user" viewBox="0 0 18 18">
          <path d="M17.4,7.5C17.1,7.2,16.6,7,16.1,7s-1,0.2-1.4,0.6c0,0,0,0,0,0l-7.6,7.6C7.1,15.2,7,15.3,7,15.5v2C7,17.8,7.2,18,7.5,18h1.9 c0.1,0,0.3-0.1,0.4-0.2l7.6-7.6C18.2,9.5,18.2,8.3,17.4,7.5z M9.2,16.9H8.1v-1.2l6.2-6.2l1.2,1.2L9.2,16.9z M16.7,9.5l-0.4,0.4 l-1.2-1.2l0.4-0.4c0.2-0.2,0.4-0.2,0.6-0.2c0.2,0,0.4,0.1,0.6,0.2C17,8.6,17,9.2,16.7,9.5z" />
          <path d="M2.6,11c-0.1,0.4,0.1,0.8,0.4,0.9c0.4,0.1,0.8-0.1,0.9-0.4c0.2-0.7,0.9-1.2,1.7-1.2h0c0,0,0,0,0,0s0,0,0,0h0 c0.7,0,1.4,0.5,1.7,1.2C7.3,11.8,7.6,12,7.9,12c0.1,0,0.2,0,0.2,0c0.4-0.1,0.5-0.5,0.4-0.9c-0.2-0.6-0.6-1.2-1.2-1.5 C7.7,9.1,7.9,8.6,7.9,8c0-1.3-1-2.3-2.3-2.3S3.3,6.7,3.3,8c0,0.6,0.2,1.1,0.5,1.5C3.3,9.9,2.8,10.4,2.6,11z M5.6,7.1 c0.5,0,0.9,0.4,0.9,0.9C6.5,8.5,6.1,9,5.6,9C5.1,9,4.6,8.5,4.6,8C4.6,7.5,5.1,7.1,5.6,7.1z" />
          <path d="M6.7,14.3L7.9,13H2.3c-0.5,0-0.9-0.4-0.9-0.9V4.8c0-0.5,0.4-0.9,0.9-0.9h3.5C6.1,4.8,7,5.4,8,5.4c1,0,1.9-0.7,2.2-1.6h3.5 c0.5,0,0.9,0.4,0.9,0.9v1.6c0.4-0.3,0.9-0.5,1.4-0.6V4.8c0-1.3-1-2.3-2.3-2.3h-3.4V2.3C10.3,1,9.3,0,8,0C6.7,0,5.7,1,5.7,2.3v0.1 H2.3C1,2.4,0,3.5,0,4.8v7.3c0,1.3,1,2.3,2.3,2.3L6.7,14.3C6.6,14.4,6.6,14.3,6.7,14.3z M7.1,2.3c0-0.5,0.4-0.9,0.9-0.9 C8.5,1.4,9,1.8,9,2.3v0.8C9,3.7,8.5,4.1,8,4.1c-0.5,0-0.9-0.4-0.9-0.9V2.3z" />
        </symbol>

        <symbol id="ui-icon-filter" viewBox="0 0 18 18">
          <path d="M7.2,18c-0.1,0-0.2,0-0.3-0.1c-0.3-0.1-0.5-0.4-0.5-0.7v-6c0,0,0-0.1,0-0.1L0.5,5.2C0.2,4.9,0,4.5,0,4V1.7 C0,0.8,0.8,0,1.7,0h14.7C17.2,0,18,0.8,18,1.7V4c0,0.4-0.2,0.9-0.5,1.2l-5.9,5.9c0,0-0.1,0.1-0.1,0.1v2.4c0,0.2-0.1,0.4-0.2,0.5 l-3.6,3.7C7.6,17.9,7.4,18,7.2,18z M7.9,17.2L7.9,17.2L7.9,17.2z M1.7,1.5c-0.1,0-0.2,0.1-0.2,0.2V4c0,0,0,0.1,0,0.1L7.4,10 c0.3,0.3,0.5,0.7,0.5,1.2v4.2l2.1-2.1v-2.1c0-0.4,0.2-0.9,0.5-1.2l5.9-5.9c0,0,0.1-0.1,0.1-0.1V1.7c0-0.1-0.1-0.2-0.2-0.2H1.7z" />
        </symbol>

        <symbol id="ui-icon-error" viewBox="0 0 24 24">
          <path d="M2,20.6h20L12,3.4L2,20.6z M12.9,17.9h-1.8v-1.8h1.8V17.9z M12.9,14.3h-1.8v-3.6h1.8V14.3z" />
        </symbol>

        <symbol id="ui-icon-external-link" viewBox="0 0 18 18">
          <path d="M13,18H3c-1.7,0-3-1.3-3-3V5c0-1.7,1.3-3,3-3h4c0.6,0,1,0.4,1,1S7.6,4,7,4H3C2.4,4,2,4.4,2,5v10c0,0.6,0.4,1,1,1h10 c0.6,0,1-0.4,1-1v-4c0-0.6,0.4-1,1-1s1,0.4,1,1v4C16,16.7,14.7,18,13,18z M7,12c-0.3,0-0.5-0.1-0.7-0.3c-0.4-0.4-0.4-1,0-1.4 L14.6,2H11c-0.6,0-1-0.4-1-1s0.4-1,1-1h6c0,0,0,0,0,0l0,0c0.1,0,0.3,0,0.4,0.1c0.1,0,0.2,0.1,0.3,0.2s0.2,0.2,0.2,0.3 C18,0.7,18,0.9,18,1l0,0c0,0,0,0,0,0v6c0,0.6-0.4,1-1,1s-1-0.4-1-1V3.4l-8.3,8.3C7.5,11.9,7.3,12,7,12z" />
        </symbol>

        <symbol id="ui-icon-facebook" viewBox="0 0 18 18">
          <path d="M18,9.1c0-5-4-9-9-9s-9,4-9,9c0,4.5,3.3,8.2,7.6,8.9v-6.3H5.3V9.1h2.3v-2c0-2.3,1.3-3.5,3.4-3.5c1,0,2,0.2,2,0.2V6h-1.1 c-1.1,0-1.5,0.7-1.5,1.4v1.7h2.5l-0.4,2.6h-2.1v6.3C14.7,17.3,18,13.5,18,9.1z" />
        </symbol>

        <symbol id="ui-icon-heart" viewBox="0 0 18 18">
          <path d="M9,16.8c-0.2,0-0.4-0.1-0.5-0.2L1.4,9.5c-1.9-1.9-1.9-5,0-6.9s5-1.9,6.9,0L9,3.4l0.7-0.7 c0.9-0.9,2.1-1.4,3.4-1.4s2.5,0.5,3.4,1.4C17.5,3.6,18,4.8,18,6.1s-0.5,2.5-1.4,3.4l-7.1,7.1C9.4,16.7,9.2,16.8,9,16.8z M4.8,2.6 c-0.9,0-1.8,0.3-2.4,1C1,5,1,7.2,2.4,8.5L9,15.1l6.6-6.6c0.7-0.7,1-1.5,1-2.4s-0.4-1.8-1-2.4c-1.3-1.3-3.6-1.3-4.9,0L9.5,4.8 C9.4,5,9.2,5,9,5l0,0C8.8,5,8.6,5,8.5,4.8L7.3,3.6C6.6,2.9,5.7,2.6,4.8,2.6z" />
        </symbol>

        <symbol id="ui-icon-help" viewBox="0 0 18 18">
          <path
            d="M9,18c-5,0-9-4-9-9c0-5,4-9,9-9c5,0,9,4,9,9C18,14,14,18,9,18z M9,1.5C4.9,1.5,1.5,4.9,1.5,9
         c0,4.1,3.3,7.5,7.5,7.5c4.1,0,7.5-3.3,7.5-7.5C16.5,4.9,13.1,1.5,9,1.5z M9,14.3c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h0
         c0.4,0,0.8,0.3,0.8,0.8S9.4,14.3,9,14.3z M9,11.6C9,11.6,9,11.6,9,11.6c-0.5,0-0.8-0.4-0.8-0.8c0-0.9,0.7-1.6,1.6-1.7
         c1.1-0.1,2-0.9,2.1-2c0-1-1.3-1.9-2.9-1.9C7.8,5.1,6.8,5.7,6.2,6.7C6,7,5.5,7.1,5.2,6.9C4.8,6.7,4.7,6.2,4.9,5.9
         C5.8,4.4,7.4,3.5,9,3.7c0,0,0,0,0,0c2.4,0,4.4,1.6,4.4,3.5c-0.2,1.9-1.7,3.3-3.5,3.4c-0.1,0-0.2,0.1-0.2,0.3
         C9.7,11.3,9.4,11.6,9,11.6z"
          />
        </symbol>

        <symbol id="ui-icon-help3" viewBox="0 0 20 20">
          <path d="M9.167 15h1.666v-1.667H9.167V15zM10 1.667A8.336 8.336 0 0 0 1.667 10c0 4.6 3.733 8.333 8.333 8.333S18.333 14.6 18.333 10 14.6 1.667 10 1.667zm0 15A6.675 6.675 0 0 1 3.333 10 6.676 6.676 0 0 1 10 3.333 6.675 6.675 0 0 1 16.667 10 6.675 6.675 0 0 1 10 16.667zM10 5a3.332 3.332 0 0 0-3.333 3.333h1.666c0-.916.75-1.666 1.667-1.666s1.667.75 1.667 1.666c0 1.667-2.5 1.459-2.5 4.167h1.666c0-1.875 2.5-2.083 2.5-4.167A3.332 3.332 0 0 0 10 5z" />
        </symbol>

        {/* <!-- <symbol id="ui-icon-info-lg" viewBox="0 0 72 72">
      <circle opacity="0.2" cx="36" cy="36" r="36" />
      <circle cx="36" cy="36" r="23" />
      <g opacity="0.94" className="fill-white">
         <rect x="33.7" y="23.8" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 62.481 -9.519)" width="4.5" height="5.5" />
         <rect x="27.6" y="36.6" transform="matrix(-1.836970e-16 1 -1 -1.836970e-16 75.37 3.37)" width="16.8" height="5.5" />
      </g>
   </symbol> --> */}

        <symbol id="ui-icon-illustration" viewBox="0 0 18 18">
          <path d="M13.6,18H4.4c-1.4,0-2.6-1.2-2.6-2.6V2.6C1.8,1.2,3,0,4.4,0h5.1c0.5,0,0.9,0.2,1.2,0.5l4.9,4.9c0.3,0.3,0.5,0.8,0.5,1.2v8.7 C16.2,16.8,15,18,13.6,18z M4.4,1.6c-0.6,0-1,0.5-1,1v12.8c0,0.6,0.5,1,1,1h9.1c0.6,0,1-0.5,1-1V6.6l-5-5L4.4,1.6z M11.7,14.4 c-0.4,0-0.8-0.4-0.8-0.8V8.1c0-0.4,0.4-0.8,0.8-0.8c0.4,0,0.8,0.4,0.8,0.8v5.5C12.5,14,12.2,14.4,11.7,14.4z M9,14.4 c-0.4,0-0.8-0.4-0.8-0.8V9.9c0-0.4,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8v3.6C9.8,14,9.4,14.4,9,14.4z M6.3,14.4c-0.4,0-0.8-0.4-0.8-0.8 v-1.8c0-0.4,0.4-0.8,0.8-0.8s0.8,0.4,0.8,0.8v1.8C7.1,14,6.7,14.4,6.3,14.4z" />
        </symbol>

        <symbol id="ui-icon-layout-cards" viewBox="0 0 18 18">
          <path d="M2.7,9.6C1.2,9.6,0,10.8,0,12.3c0,0,0,0,0,0V15c0,1.5,1.2,2.7,2.7,2.7h2.7c1.5,0,2.7-1.2,2.7-2.7l0,0v-2.7c0-1.5-1.2-2.7-2.7-2.7L2.7,9.6z" />
          <path d="M9.9,12.3c0-1.5,1.2-2.7,2.7-2.7h2.7c1.5,0,2.7,1.2,2.7,2.7V15c0,1.5-1.2,2.7-2.7,2.7h-2.7c-1.5,0-2.7-1.2-2.7-2.7V12.3z" />
          <path d="M2.7,0.2C1.2,0.2,0,1.5,0,3c0,0,0,0,0,0v2.7c0,1.5,1.2,2.7,2.7,2.7l0,0h2.7c1.5,0,2.7-1.2,2.7-2.7l0,0V3c0-1.5-1.2-2.7-2.7-2.7c0,0,0,0,0,0H2.7z" />
          <path d="M9.9,3c0-1.5,1.2-2.7,2.7-2.7c0,0,0,0,0,0h2.7C16.8,0.2,18,1.5,18,3v2.7c0,1.5-1.2,2.7-2.7,2.7l0,0h-2.7c-1.5,0-2.7-1.2-2.7-2.7L9.9,3z" />
        </symbol>

        <symbol id="ui-icon-layout-table" viewBox="0 0 18 18">
          <path d="M16.7,16.1H1.3c-0.7,0-1.3-0.6-1.3-1.3c0-0.7,0.6-1.3,1.3-1.3h15.5c0.7,0,1.3,0.6,1.3,1.3C18,15.5,17.4,16.1,16.7,16.1z M16.7,10.3H1.3C0.6,10.3,0,9.7,0,9s0.6-1.3,1.3-1.3h15.5C17.4,7.7,18,8.3,18,9S17.4,10.3,16.7,10.3zM16.7,4.5H1.3C0.6,4.5,0,3.9,0,3.2s0.6-1.3,1.3-1.3h15.5c0.7,0,1.3,0.6,1.3,1.3S17.4,4.5,16.7,4.5z" />
        </symbol>

        <symbol id="ui-icon-menu" viewBox="0 0 24 18">
          <g transform="translate(0.75 0.75)">
            <path d="M22.3,17.2H0.2c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h22.2c0.4,0,0.7,0.3,0.7,0.7S22.7,17.2,22.3,17.2zM22.3,8.9H0.2c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h22.2c0.4,0,0.7,0.3,0.7,0.7S22.7,8.9,22.3,8.9z M22.3,0.6H0.2c-0.4,0-0.7-0.3-0.7-0.7s0.3-0.7,0.7-0.7h22.2c0.4,0,0.7,0.3,0.7,0.7S22.7,0.6,22.3,0.6z" />
          </g>
        </symbol>

        <symbol id="ui-icon-menu2" viewBox="0 0 24 24">
          <path d="M20.5,6h-17C2.7,6,2,5.3,2,4.5v0C2,3.6,2.7,3,3.5,3h17C21.3,3,22,3.6,22,4.5v0 C22,5.3,21.3,6,20.5,6z" />
          <path d="M20.5,13.1h-17c-0.8,0-1.5-0.7-1.5-1.5v0c0-0.8,0.7-1.5,1.5-1.5h17 c0.8,0,1.5,0.7,1.5,1.5v0C22,12.5,21.3,13.1,20.5,13.1z" />
          <path d="M20.5,20.3h-9c-0.8,0-1.5-0.7-1.5-1.5v0c0-0.8,0.7-1.5,1.5-1.5h9 c0.8,0,1.5,0.7,1.5,1.5v0C22,19.6,21.3,20.3,20.5,20.3z" />
        </symbol>

        <symbol id="ui-icon-menu-alt" viewBox="0 0 24 24">
          <path d="M20.5,6h-17C2.7,6,2,5.3,2,4.5v0C2,3.6,2.7,3,3.5,3h17C21.3,3,22,3.6,22,4.5v0C22,5.3,21.3,6,20.5,6z" />
          <path d="M20.5,13.1h-17c-0.8,0-1.5-0.7-1.5-1.5v0c0-0.8,0.7-1.5,1.5-1.5h17c0.8,0,1.5,0.7,1.5,1.5v0C22,12.5,21.3,13.1,20.5,13.1z" />
          <path d="M20.5,20.3h-17c-0.8,0-1.5-0.7-1.5-1.5v0c0-0.8,0.7-1.5,1.5-1.5h17c0.8,0,1.5,0.7,1.5,1.5v0C22,19.6,21.3,20.3,20.5,20.3z" />
        </symbol>

        <symbol id="ui-icon-message" viewBox="0 0 18 18">
          <path d="M15.4,17.8H2.6c-1.4,0-2.6-1.2-2.6-2.6c0,0,0,0,0,0V7.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0-0.8,0.5-1.6,1.2-2l6.4-4.3c0.9-0.6,2-0.6,2.9,0l6.4,4.3c0.7,0.5,1.1,1.2,1.2,2c0,0,0,0.1,0,0.1c0,0,0,0,0,0v8.2c0,0,0,0,0,0C18,16.7,16.8,17.8,15.4,17.8z M1.6,15.6c0.1,0.4,0.5,0.7,1,0.7h12.8c0.5,0,0.8-0.3,1-0.7L11.1,12l-0.6,0.4c-0.9,0.6-2,0.6-2.9,0L6.9,12L1.6,15.6z M12.4,11.1l4,2.7V8.4L12.4,11.1z M1.5,8.4v5.3l4-2.7L1.5,8.4z M7.4,10.5l1,0.7c0.4,0.2,0.8,0.2,1.2,0l1-0.7c0,0,0,0,0,0l5.7-3.8c-0.1-0.2-0.2-0.3-0.4-0.5L9.6,1.9c-0.4-0.2-0.8-0.2-1.2,0L2,6.2C1.8,6.3,1.7,6.4,1.6,6.6L7.4,10.5C7.4,10.5,7.4,10.5,7.4,10.5z" />
        </symbol>

        <symbol id="ui-icon-message2" viewBox="0 0 18 18">
          <path d="M15.5,16.2H2.5C1.1,16.2,0,15,0,13.6V5.3c0,0,0,0,0,0V4.4C0,3,1.1,1.8,2.5,1.8h12.9C16.9,1.8,18,3,18,4.4 v0.9c0,0,0,0,0,0v8.3C18,15,16.9,16.2,15.5,16.2z M1.4,6.6v7c0,0.6,0.5,1.2,1.2,1.2h12.9c0.6,0,1.2-0.5,1.2-1.2v-7l-6.2,4.1 c-0.9,0.6-2,0.6-2.8,0L1.4,6.6z M1.4,4.9l7,4.6c0.4,0.3,0.9,0.3,1.3,0l7-4.6V4.4c0-0.6-0.5-1.2-1.2-1.2H2.5c-0.6,0-1.2,0.5-1.2,1.2 V4.9z" />
        </symbol>

        <symbol id="ui-icon-minus" viewBox="0 0 18 18">
          <path d="M17,8.1H1C0.5,8.1,0.1,8.5,0.1,9S0.5,9.9,1,9.9h16c0.5,0,0.9-0.4,0.9-0.9S17.5,8.1,17,8.1z" />
        </symbol>

        <symbol id="ui-icon-money" viewBox="0 0 18 18">
          <path d="M9.5,8.5V5.9c0.5,0,1.1,0.3,1.5,0.7c0.1,0.1,0.2,0.1,0.4,0.1c0.1,0,0.3,0,0.4-0.1c0.2-0.2,0.2-0.5,0-0.7 c-0.6-0.6-1.3-0.9-2.2-1V4.5C9.6,4.2,9.4,4,9.1,4C8.8,4,8.5,4.2,8.5,4.5v0.4C7,5,5.7,6,5.7,7.2S7,9.3,8.5,9.5v2.6 C8,12,7.4,11.8,7,11.4c-0.2-0.2-0.5-0.2-0.7,0c-0.2,0.2-0.2,0.5,0,0.7c0.6,0.6,1.4,0.9,2.2,1v0.4C8.5,13.8,8.7,14,9,14 s0.5-0.2,0.5-0.5v-0.4c1.5-0.2,2.8-1.1,2.8-2.2S11,8.7,9.5,8.5z M6.8,7.2c0-0.6,0.7-1.1,1.7-1.2v2.6C7.6,8.3,6.8,7.8,6.8,7.2z M9.5,12.1V9.5c0.9,0.1,1.7,0.7,1.7,1.2C11.2,11.5,10.4,12,9.5,12.1z" />
          <path d="M9,0C4.1,0,0,4.1,0,9s4,9,9,9c4.9,0,9-4,9-9C18,4.1,13.9,0,9,0z M9,16.4c-4.1,0-7.4-3.3-7.4-7.4S4.9,1.6,9,1.6 s7.4,3.3,7.4,7.4S13.1,16.4,9,16.4z" />
        </symbol>

        <symbol id="ui-icon-payment" viewBox="0 0 18 18">
          <path d="M3.5,15.9c-0.9,0-1.8-0.3-2.4-1c-0.7-0.6-1-1.5-1.1-2.4V7.2c0,0,0,0,0,0s0,0,0,0V5.5C0,3.5,1.6,2,3.5,2.1h11c0,0,0,0,0.1,0c1.9,0,3.4,1.5,3.5,3.4v1.8c0,0,0,0,0,0s0,0,0,0v5.3c0,1.9-1.6,3.4-3.5,3.4H3.5C3.5,15.9,3.5,15.9,3.5,15.9zM1.5,8v4.5c0,0.5,0.2,1,0.6,1.3s0.9,0.5,1.4,0.5c0,0,0,0,0,0h11c1.1,0,2-0.8,2-1.9V8H1.5z M1.5,6.5h14.9v-1c0-1-0.9-1.9-1.9-1.9c0,0,0,0,0,0h-11c-1.1,0-2,0.8-2,1.9V6.5z M9.9,12.4H9c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h0.9c0.4,0,0.8,0.3,0.8,0.8S10.3,12.4,9.9,12.4z M5.3,12.4H4.4c-0.4,0-0.8-0.3-0.8-0.8s0.3-0.8,0.8-0.8h0.9c0.4,0,0.8,0.3,0.8,0.8S5.8,12.4,5.3,12.4z" />
        </symbol>

        <symbol id="ui-icon-percentage" viewBox="0 0 18 18">
          <path d="M13,17c-1.9,0-3.4-1.4-3.4-3.1v-1.4c0-1.7,1.5-3.1,3.4-3.1s3.4,1.4,3.4,3.1v1.4C16.4,15.6,14.9,17,13,17z M13,11.3 c-0.8,0-1.4,0.6-1.4,1.3v1.4c0,0.7,0.6,1.3,1.4,1.3c0.8,0,1.4-0.6,1.4-1.3v-1.4C14.4,11.8,13.8,11.3,13,11.3z" />
          <path d="M5,8.5c-1.9,0-3.4-1.4-3.4-3.1V4.1C1.6,2.4,3.1,1,5,1s3.4,1.4,3.4,3.1v1.4C8.4,7.1,6.9,8.5,5,8.5z M5,2.8 c-0.8,0-1.4,0.6-1.4,1.3v1.4c0,0.7,0.6,1.3,1.4,1.3s1.4-0.6,1.4-1.3V4.1C6.4,3.4,5.8,2.8,5,2.8z" />
          <path d="M4.5,17c-0.2,0-0.3,0-0.5-0.1c-0.5-0.2-0.6-0.8-0.3-1.2l9.1-14.2C13,1,13.6,0.9,14.1,1.1c0.5,0.2,0.6,0.8,0.3,1.2L5.3,16.6 C5.1,16.8,4.8,17,4.5,17z" />
        </symbol>

        <symbol id="ui-icon-piechart" viewBox="0 0 18 18">
          <path d="M9,18c-2,0-4-0.7-5.6-2c-1.9-1.5-3.1-3.7-3.3-6C-0.5,5,3.1,0.6,8,0c0.2,0,0.5,0,0.6,0.2c0.2,0.2,0.3,0.4,0.3,0.6v8.3h8.3 c0.2,0,0.4,0.1,0.6,0.3C17.9,9.5,18,9.8,18,10c-0.5,4.2-3.7,7.5-8,8C9.7,18,9.3,18,9,18z M7.3,1.7C3.7,2.6,1.2,6,1.6,9.8 c0.2,2,1.2,3.7,2.7,5c1.6,1.2,3.5,1.8,5.5,1.6c3.2-0.4,5.7-2.6,6.4-5.7H8.1c-0.4,0-0.8-0.4-0.8-0.8V1.7z" />
          <path d="M16.8,7h-5C11.3,7,11,6.7,11,6.2v-5c0-0.3,0.1-0.5,0.3-0.7c0.2-0.1,0.5-0.2,0.7-0.1c2.6,0.9,4.6,2.9,5.5,5.5 c0.1,0.2,0,0.5-0.1,0.7C17.3,6.9,17,7,16.8,7z M12.6,5.4h3c-0.7-1.3-1.7-2.3-3-3V5.4z" />
        </symbol>
        <symbol id="ui-icon-phone" viewBox="0 0 18 18">
          <path d="M15.5,18h-0.9C6.5,18,0,11.5,0,3.5V2.5C0,1.1,1.1,0,2.5,0h3c0.7,0,1.3,0.4,1.5,1.1l1.4,4.1 c0.3,0.8-0.1,1.6-0.8,2L6.2,7.9c0.9,1.6,2.2,3,3.9,3.9l0.7-1.5c0.4-0.7,1.2-1.1,2-0.8l4.1,1.4c0.7,0.2,1.1,0.8,1.1,1.5v3 C18,16.9,16.9,18,15.5,18z M2.5,1.4c-0.6,0-1.2,0.5-1.2,1.2v0.9c0,7.3,5.9,13.2,13.2,13.2h0.9c0.6,0,1.2-0.5,1.2-1.2v-3 c0-0.1-0.1-0.2-0.2-0.2l-4.1-1.4c-0.1,0-0.2,0-0.3,0.1L11,13c-0.2,0.3-0.6,0.5-0.9,0.3c-2.4-1.1-4.3-3-5.4-5.4C4.5,7.6,4.6,7.2,5,7 l2.1-1c0.1-0.1,0.2-0.2,0.1-0.3L5.8,1.5c0-0.1-0.1-0.2-0.2-0.2H2.5z" />
        </symbol>

        <symbol id="ui-icon-plus" viewBox="0 0 18 18">
          <path d="M17,8.1H9.9V1c0-0.5-0.4-0.9-0.9-0.9S8.1,0.5,8.1,1v7.1H1C0.5,8.1,0.1,8.5,0.1,9S0.5,9.9,1,9.9h7.1V17 c0,0.5,0.4,0.9,0.9,0.9s0.9-0.4,0.9-0.9V9.9H17c0.5,0,0.9-0.4,0.9-0.9S17.5,8.1,17,8.1z" />
        </symbol>

        <symbol id="ui-icon-profile" viewBox="0 0 18 18">
          <path d="M14.5,18h-11c-0.4,0-0.8-0.3-0.8-0.8v-0.9c0-3.5,2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3v0.9C15.3,17.7,14.9,18,14.5,18z M4.3,16.5h9.4v-0.1c0-2.6-2.1-4.7-4.7-4.7s-4.7,2.1-4.7,4.7V16.5z M9,8.9c-2.4,0-4.4-2-4.4-4.4C4.6,2,6.6,0,9,0s4.4,2,4.4,4.4C13.4,6.9,11.4,8.9,9,8.9z M9,1.5c-1.6,0-2.9,1.3-2.9,2.9C6.1,6,7.4,7.3,9,7.3s2.9-1.3,2.9-2.9C11.9,2.8,10.6,1.5,9,1.5z" />
        </symbol>

        <symbol id="ui-icon-refresh" viewBox="0 0 18 18">
          <path d="M9,17c-1,0-2.1-0.2-3-0.6c-2.7-1.1-4.6-3.6-5-6.4c0-0.6,0.4-0.9,0.9-1s0.9,0.3,1,0.8c0.3,2.3,1.8,4.2,3.9,5 c1.5,0.7,3.2,0.6,4.8,0c1.1-0.5,2-1.2,2.6-2.2h-2.5c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9h3.9l0,0h0.5c0.5,0,0.9,0.4,0.9,0.9 v4.4c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9v-2.1c-0.9,1-1.9,1.8-3.1,2.4C11.2,16.9,10.1,17,9,17z M16.1,9 c-0.5,0-0.9-0.3-0.9-0.8c-0.5-3.4-3.6-5.9-7-5.5C6.5,3,4.9,4,3.9,5.4h2.5c0.5,0,0.9,0.4,0.9,0.9c0,0.5-0.4,0.9-0.9,0.9h-4l0,0H1.9 C1.4,7.2,1,6.8,1,6.4V1.8C1,1.3,1.4,1,1.9,1s0.9,0.4,0.9,0.9v2.1C4.1,2.4,5.9,1.2,8,1.1c4.4-0.6,8.4,2.6,9,7 C17.1,8.5,16.7,9,16.1,9C16.2,9,16.1,9,16.1,9z" />
          {/* <path d="M9,17.5c-1.1,0-2.2-0.2-3.2-0.6c-2.9-1.2-4.9-3.8-5.3-6.8C0.5,9.5,0.9,9.1,1.4,9c0.5-0.1,1,0.3,1.1,0.8 c0.3,2.4,1.9,4.4,4.1,5.3c1.6,0.7,3.4,0.6,5.1,0c1.2-0.5,2.1-1.3,2.8-2.3h-2.6c-0.5,0-0.9-0.4-0.9-0.9c0-0.5,0.4-0.9,0.9-0.9H16 c0,0,0,0,0,0h0.5c0.5,0,0.9,0.4,0.9,0.9v4.7c0,0.5-0.4,0.9-0.9,0.9c-0.5,0-0.9-0.4-0.9-0.9v-2.2c-0.9,1.1-2,1.9-3.3,2.5 C11.3,17.3,10.1,17.5,9,17.5z M16.5,9c-0.5,0-0.9-0.3-0.9-0.8C15.1,4.6,11.8,2,8.2,2.4C6.3,2.7,4.6,3.7,3.6,5.2h2.6 c0.5,0,0.9,0.4,0.9,0.9S6.7,7.1,6.2,7.1H2c0,0,0,0,0,0H1.4c-0.5,0-0.9-0.4-0.9-0.9V1.4c0-0.5,0.4-0.9,0.9-0.9s0.9,0.4,0.9,0.9v2.2 C3.8,2,5.7,0.8,7.9,0.6c4.7-0.6,8.9,2.7,9.5,7.4c0.1,0.5-0.3,1-0.8,1.1C16.6,9,16.5,9,16.5,9z"/> */}
        </symbol>

        <symbol id="ui-icon-search" viewBox="0 0 18 18">
          <path d="M17.8,16.7l-5-5c1-1.2,1.6-2.8,1.6-4.5l0,0c0-4-3.2-7.2-7.2-7.2C3.2,0,0,3.2,0,7.2c0,4,3.2,7.2,7.2,7.2c1.7,0,3.3-0.6,4.5-1.6l5,5c0.2,0.2,0.3,0.2,0.5,0.2s0.4-0.1,0.5-0.2C18.1,17.5,18.1,17,17.8,16.7z M7.2,12.8c-3.1,0-5.6-2.5-5.6-5.6c0-3.1,2.5-5.6,5.6-5.6c3.1,0,5.6,2.5,5.6,5.6C12.8,10.3,10.3,12.8,7.2,12.8z" />
        </symbol>

        <symbol id="ui-icon-share" viewBox="0 0 18 18">
          <path d="M14.5,18c-0.5,0-1-0.1-1.5-0.4c-0.8-0.4-1.4-1.1-1.7-2c-0.2-0.7-0.2-1.3-0.1-2l-5-2.5 c-0.4,0.5-1,0.9-1.6,1.1c-0.9,0.3-1.8,0.2-2.6-0.2c-1.7-0.9-2.4-2.9-1.5-4.6C1.2,5.7,3.3,5.1,5,5.9c0.5,0.2,0.9,0.6,1.2,1l5-2.5 C11,3.7,11,3,11.3,2.4c0.3-0.9,0.9-1.6,1.7-2c1.7-0.9,3.8-0.2,4.6,1.5c0.9,1.7,0.2,3.8-1.5,4.6c-1.5,0.7-3.3,0.3-4.3-1l-5,2.5 C7,8.7,7,9.3,6.8,9.9l5,2.5c0.4-0.5,1-0.9,1.6-1.1c0.9-0.3,1.8-0.2,2.6,0.2c1.7,0.9,2.4,2.9,1.5,4.6C17,17.3,15.8,18,14.5,18z M12.7,13.6L12.7,13.6c-0.2,0.5-0.3,1.1-0.1,1.6c0.2,0.5,0.5,1,1,1.2c1,0.5,2.3,0.1,2.8-0.9c0.2-0.5,0.3-1.1,0.1-1.6 c-0.2-0.5-0.5-1-1-1.2c-0.5-0.2-1.1-0.3-1.6-0.1C13.4,12.7,12.9,13.1,12.7,13.6C12.7,13.6,12.7,13.6,12.7,13.6 C12.7,13.6,12.7,13.6,12.7,13.6z M3.5,6.9C2.7,6.9,2,7.3,1.6,8.1c-0.5,1-0.1,2.3,0.9,2.8C3,11.1,3.6,11.1,4.1,11 c0.5-0.2,1-0.5,1.2-1c0.3-0.6,0.3-1.3,0-1.9c0,0,0,0,0,0C5.1,7.7,4.8,7.3,4.4,7.1C4.1,7,3.8,6.9,3.5,6.9z M12.7,4.4 c0.5,1,1.8,1.4,2.8,0.9c1-0.5,1.4-1.8,0.9-2.8c-0.5-1-1.8-1.4-2.8-0.9c-0.5,0.2-0.9,0.7-1,1.2C12.4,3.3,12.4,3.9,12.7,4.4 C12.7,4.4,12.7,4.4,12.7,4.4C12.7,4.4,12.7,4.4,12.7,4.4z" />
        </symbol>

        <symbol id="ui-icon-signout" viewBox="0 0 18 18">
          <path d="M7.5,17.5H3.6c-2,0-3.6-1.6-3.6-3.6V4.1c0-2,1.6-3.6,3.6-3.6h3.9c2,0,3.6,1.6,3.6,3.6v1 c0,0.4-0.3,0.7-0.7,0.7c-0.4,0-0.7-0.3-0.7-0.7v-1c0-1.2-1-2.2-2.2-2.2H3.6c-1.2,0-2.2,1-2.2,2.2v9.7c0,1.2,1,2.2,2.2,2.2h3.9 c1.2,0,2.2-1,2.2-2.2v-1c0-0.4,0.3-0.7,0.7-0.7c0.4,0,0.7,0.3,0.7,0.7v1C11.2,15.9,9.6,17.5,7.5,17.5z M13.4,13.6 c-0.2,0-0.4-0.1-0.5-0.2c-0.3-0.3-0.3-0.7,0-1l2.6-2.6H4.6C4.2,9.7,3.9,9.4,3.9,9s0.3-0.7,0.7-0.7h10.9l-2.6-2.6 c-0.3-0.3-0.3-0.7,0-1c0.3-0.3,0.7-0.3,1,0l3.9,3.9c0.1,0.1,0.1,0.2,0.2,0.2C18,8.8,18,8.9,18,9s0,0.2-0.1,0.3 c0,0.1-0.1,0.2-0.2,0.2l-3.9,3.9C13.8,13.6,13.6,13.6,13.4,13.6z" />
        </symbol>

        <symbol id="ui-icon-steps" viewBox="0 0 18 18">
          <path d="M4.6,6.2H2.3c-0.9,0-1.6,0.7-1.6,1.6v2c0,0.9,0.7,1.6,1.6,1.6h2.2c0.9,0,1.6-0.7,1.6-1.6v-2C6.2,6.9,5.5,6.2,4.6,6.2z M2.2,7.8c0-0.1,0.1-0.2,0.2-0.2h2.2c0.1,0,0.2,0.1,0.2,0.2v2c0,0.1-0.1,0.2-0.2,0.2H2.3c-0.1,0-0.2-0.1-0.2-0.2V7.8z" />
          <path d="M4.6,12.8H2.3c-0.9,0-1.6,0.7-1.6,1.6v2c0,0.9,0.7,1.6,1.6,1.6h2.2c0.9,0,1.6-0.7,1.6-1.6v-2C6.2,13.5,5.5,12.8,4.6,12.8z M2.2,14.4c0-0.1,0.1-0.2,0.2-0.2h2.2c0.1,0,0.2,0.1,0.2,0.2v2c0,0.1-0.1,0.2-0.2,0.2H2.3c-0.1,0-0.2-0.1-0.2-0.2V14.4z" />
          <path d="M6.4,0.3C6.2,0.2,6,0.1,5.8,0.1c-0.2,0-0.4,0.1-0.6,0.2L2.7,2.9l-1-1C1.5,1.8,1.3,1.7,1.1,1.7S0.7,1.8,0.5,1.9 C0.4,2.1,0.3,2.3,0.3,2.5c0,0.2,0.1,0.4,0.2,0.6l1.6,1.6c0.1,0.1,0.3,0.2,0.6,0.2s0.4-0.1,0.6-0.2l3.2-3.2c0.1-0.1,0.2-0.3,0.2-0.6 S6.5,0.5,6.4,0.3z" />
          <path d="M16,16.2H8.3c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9H16c0.5,0,0.9,0.4,0.9,0.9S16.5,16.2,16,16.2z" />
          <path d="M16,9.7H8.3c-0.5,0-0.9-0.4-0.9-0.9s0.4-0.9,0.9-0.9H16c0.5,0,0.9,0.4,0.9,0.9S16.5,9.7,16,9.7z" />
          <path d="M16,3.8H8.3c-0.5,0-0.9-0.4-0.9-0.9S7.8,2,8.3,2H16c0.5,0,0.9,0.4,0.9,0.9S16.5,3.8,16,3.8z" />
        </symbol>

        <symbol id="ui-icon-toggle" viewBox="0 0 18 18">
          <path d="M12.2,4.7c2.4,0,4.3,1.9,4.3,4.3s-1.9,4.3-4.3,4.3H5.8c-2.4,0-4.3-1.9-4.3-4.3s1.9-4.3,4.3-4.3H12.2 M12.2,3.2H5.8 C2.6,3.2,0,5.8,0,9s2.6,5.8,5.8,5.8h6.4c3.2,0,5.8-2.6,5.8-5.8S15.4,3.2,12.2,3.2L12.2,3.2z" />
          <circle cx="6" cy="9" r="3.1" />
        </symbol>

        <symbol id="ui-icon-tooltip" viewBox="0 0 10.95 10.95">
          <g transform="translate(-69.216 -31.985)">
            <path
              d="M104.74,29.265a5.475,5.475,0,1,1-5.475,5.475A5.469,5.469,0,0,1,104.74,29.265Zm0,1.965a2.112,2.112,0,0,0-2.106,2.106.421.421,0,1,0,.842,0,1.263,1.263,0,1,1,2.527,0,1.42,1.42,0,0,1-.465,1.04,10.882,10.882,0,0,1-1.062.877.421.421,0,0,0-.158.329v.561a.421.421,0,1,0,.842,0v-.355a7.88,7.88,0,0,0,.992-.834,2.33,2.33,0,0,0,.693-1.619,2.112,2.112,0,0,0-2.106-2.106Zm0,5.9a.562.562,0,1,0,.561.562A.561.561,0,0,0,104.74,37.127Z"
              transform="translate(-30.049 2.72)"
            />
          </g>
        </symbol>

        <symbol id="ui-icon-twitter" viewBox="0 0 18 18">
          <path
            d="M16.1,5.3c0,0.2,0,0.3,0,0.5c0,4.9-3.7,10.5-10.5,10.5c-2.1,0-4-0.6-5.7-1.7c0.3,0,0.6,0,0.9,0
            c1.7,0,3.3-0.6,4.6-1.6c-1.6,0-3-1.1-3.4-2.6c0.2,0,0.5,0.1,0.7,0.1c0.3,0,0.7,0,1-0.1c-1.7-0.3-3-1.8-3-3.6v0
            c0.5,0.3,1.1,0.4,1.7,0.5c-1-0.7-1.6-1.8-1.6-3.1c0-0.7,0.2-1.3,0.5-1.9c1.8,2.2,4.5,3.7,7.6,3.9C8.8,6,8.8,5.7,8.8,5.4
            c0-2,1.6-3.7,3.7-3.7c1.1,0,2,0.4,2.7,1.2C16,2.7,16.8,2.4,17.5,2c-0.3,0.9-0.9,1.6-1.6,2c0.7-0.1,1.5-0.3,2.1-0.6
            C17.5,4.2,16.9,4.8,16.1,5.3L16.1,5.3z"
          />
        </symbol>

        <symbol id="ui-icon-user-group" viewBox="0 0 18 18">
          <path d="M17.2,16.4H0.8C0.4,16.4,0,16,0,15.5v-1.6c0-1.8,1.5-3.3,3.3-3.3c0.6,0,1.2,0.2,1.7,0.5c1.3-1.8,3.7-2.6,5.9-1.8 c0.9,0.4,1.7,1,2.2,1.8c0.5-0.3,1.1-0.5,1.7-0.5c1.8,0,3.3,1.5,3.3,3.3v1.6C18,16,17.6,16.4,17.2,16.4z M13.9,14.7h2.5v-0.8 c0-0.9-0.7-1.6-1.6-1.6c-0.4,0-0.7,0.1-1,0.3c0.1,0.4,0.2,0.9,0.2,1.3V14.7z M5.7,14.7h6.5v-0.8c0-0.4-0.1-0.8-0.2-1.2c0,0,0,0,0,0 c-0.3-0.8-1-1.5-1.8-1.8C8.5,10.2,6.6,11,6,12.7c0,0,0,0,0,0c-0.2,0.4-0.2,0.8-0.2,1.2V14.7z M1.6,14.7h2.5v-0.8 c0-0.4,0.1-0.9,0.2-1.3c-0.3-0.2-0.6-0.3-1-0.3c-0.9,0-1.6,0.7-1.6,1.6V14.7z M14.7,9.8c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5 s2.5,1.1,2.5,2.5S16.1,9.8,14.7,9.8z M14.7,6.5c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8c0.5,0,0.8-0.4,0.8-0.8 C15.5,6.9,15.2,6.5,14.7,6.5z M3.3,9.8c-1.4,0-2.5-1.1-2.5-2.5s1.1-2.5,2.5-2.5S5.7,6,5.7,7.4S4.6,9.8,3.3,9.8z M3.3,6.5 c-0.5,0-0.8,0.4-0.8,0.8c0,0.5,0.4,0.8,0.8,0.8s0.8-0.4,0.8-0.8C4.1,6.9,3.7,6.5,3.3,6.5z M9,8.2c-1.8,0-3.3-1.5-3.3-3.3 S7.2,1.6,9,1.6c1.8,0,3.3,1.5,3.3,3.3S10.8,8.2,9,8.2z M9,3.3C8.1,3.3,7.4,4,7.4,4.9S8.1,6.5,9,6.5c0.9,0,1.6-0.7,1.6-1.6 S9.9,3.3,9,3.3z" />
        </symbol>

        <symbol id="ui-icon-user-percentage" viewBox="0 0 18 18">
          <path d="M6.3,8.9c-2.4,0-4.4-2-4.4-4.4C1.8,2,3.8,0,6.3,0s4.4,2,4.4,4.4C10.7,6.9,8.7,8.9,6.3,8.9z M6.3,1.5 c-1.6,0-2.9,1.3-2.9,2.9s1.3,2.9,2.9,2.9S9.1,6,9.1,4.4S7.8,1.5,6.3,1.5z" />
          <path d="M16.1,18c-1,0-1.8-0.8-1.8-1.8v-0.8c0-1,0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8v0.8C17.9,17.2,17.1,18,16.1,18z M16.1,14.7 c-0.4,0-0.8,0.3-0.8,0.8v0.8c0,0.4,0.3,0.8,0.8,0.8c0.4,0,0.8-0.3,0.8-0.8v-0.8C16.9,15,16.6,14.7,16.1,14.7z" />
          <path d="M11.9,13.1c-1,0-1.8-0.8-1.8-1.8v-0.8c0-1,0.8-1.8,1.8-1.8s1.8,0.8,1.8,1.8v0.8C13.7,12.3,12.9,13.1,11.9,13.1z M11.9,9.8 c-0.4,0-0.8,0.3-0.8,0.8v0.8c0,0.4,0.3,0.8,0.8,0.8s0.8-0.3,0.8-0.8v-0.8C12.6,10.1,12.3,9.8,11.9,9.8z" />
          <path d="M11.6,18c-0.1,0-0.2,0-0.3-0.1c-0.2-0.1-0.3-0.5-0.2-0.7L16,9c0.1-0.2,0.5-0.3,0.7-0.2c0.2,0.1,0.3,0.5,0.2,0.7l-4.8,8.2 C12,17.9,11.8,18,11.6,18z" />
          <path d="M8.9,17.3c0-0.4-0.3-0.7-0.7-0.7v0H1.6v-0.1c0-2.6,2.1-4.7,4.7-4.7c0.6,0,1.1,0.1,1.6,0.3c0,0,0,0,0,0 C8,12,8.1,12,8.2,12C8.7,12,9,11.7,9,11.2c0-0.4-0.3-0.7-0.6-0.8v0C7.8,10.1,7.1,10,6.3,10C2.8,10,0,12.8,0,16.3v0.9 C0,17.7,0.3,18,0.7,18h7.5c0,0,0,0,0,0s0,0,0,0h0v0C8.6,18,8.9,17.7,8.9,17.3z" />
        </symbol>

        <symbol id="ui-icon-user-plus" viewBox="0 0 18 18">
          <path d="M11.7,18h-11C0.3,18,0,17.7,0,17.2v-0.9c0-3.5,2.8-6.3,6.3-6.3s6.3,2.8,6.3,6.3v0.9C12.5,17.7,12.2,18,11.7,18z M1.5,16.5H11v-0.1c0-2.6-2.1-4.7-4.7-4.7s-4.7,2.1-4.7,4.7V16.5z M14.5,12.5c-0.4,0-0.8-0.3-0.8-0.8v-2h-2C11.3,9.8,11,9.4,11,9s0.3-0.8,0.8-0.8h2v-2c0-0.4,0.3-0.8,0.8-0.8s0.8,0.3,0.8,0.8v2h2C17.7,8.2,18,8.6,18,9s-0.3,0.8-0.8,0.8h-2v2C15.3,12.2,14.9,12.5,14.5,12.5z M6.3,8.9c-2.4,0-4.4-2-4.4-4.4C1.8,2,3.8,0,6.3,0s4.4,2,4.4,4.4C10.7,6.9,8.7,8.9,6.3,8.9z M6.3,1.5c-1.6,0-2.9,1.3-2.9,2.9c0,1.6,1.3,2.9,2.9,2.9S9.1,6,9.1,4.4C9.1,2.8,7.8,1.5,6.3,1.5z" />
        </symbol>

        <symbol id="ui-icon-whatsapp" viewBox="0 0 18 18">
          <path
            d="M8.6,0C3.8,0.2,0.1,4.2,0.1,9c0,1.4,0.4,2.8,1,4l-0.9,4.6C0,17.8,0.3,18,0.5,18L5,16.9
            c1.2,0.6,2.5,0.9,3.8,0.9c4.9,0.1,8.9-3.8,9.1-8.6C18.1,4,13.8-0.2,8.6,0z M13.9,13.9c-1.3,1.3-3.1,2-4.9,2c-1.1,0-2.1-0.2-3.1-0.7
            l-0.6-0.3l-2.8,0.7l0.6-2.8l-0.3-0.6C2.3,11.1,2,10,2,8.9c0-1.9,0.7-3.6,2-4.9c1.3-1.3,3.1-2,4.9-2c1.9,0,3.6,0.7,4.9,2
            c1.3,1.3,2,3.1,2,4.9C16,10.8,15.3,12.6,13.9,13.9z"
          />
          <path
            d="M13.3,10.9l-1.7-0.5c-0.2-0.1-0.5,0-0.6,0.2L10.5,11c-0.2,0.2-0.4,0.2-0.7,0.1c-0.8-0.3-2.5-1.9-3-2.6
            C6.8,8.3,6.8,8,6.9,7.8l0.4-0.5c0.1-0.2,0.2-0.4,0.1-0.7L6.7,5C6.5,4.6,6,4.5,5.6,4.8c-0.5,0.4-1.1,1-1.1,1.7
            c-0.1,1.2,0.4,2.7,2.4,4.6c2.3,2.1,4.1,2.4,5.3,2.1c0.7-0.2,1.2-0.8,1.5-1.3C13.9,11.5,13.7,11,13.3,10.9z"
          />
        </symbol>
      </svg>
    </div>
  );
}
