import { v4 as uuidv4 } from "uuid";
import { useCollapsePanel } from "../../../../hooks/useCollapsePanel";
const OwnerAndInsuredCard = ({
  policy,
  policyDetail,
  isOpenSendReminder,
  setIsOpenSendReminder,
}) => {
  const uuid = uuidv4();
  useCollapsePanel(uuid);
  return (
    <>
      <div className="ui-card">
        <div className="p-4 xl:pt-6 xl:px-6">
          <div className="flex flex-col md:flex-row md:justify-between md:items-center">
            <h2 className="mb-0">
              <button
                id={uuid}
                className="flex items-center group md:hidden"
                aria-controls={`${uuid}-panel`}
                aria-expanded="true"
                aria-label="Expand or collapse Owner &amp; Insured as of MM/DD/YYYY details"
              >
                <span className="inline-block p-1 rounded-full mr-1 text-ui-blue group-hover:text-white group-hover:bg-ui-blue">
                  <svg
                    className="ui-icon ui-plus w-4 h-4"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-plus"></use>
                  </svg>
                  <svg
                    className="ui-icon ui-minus w-4 h-4"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-minus"></use>
                  </svg>
                </span>
                <span
                  className={`text-base font-body font-bold md:ui-no-badge-icon-inline`}
                >
                  Owner &amp; Insured as of MM/DD/YYYY
                </span>
              </button>
              <span className="text-xl font-body font-bold hidden md:block">
                Owner &amp; Insured as of MM/DD/YYYY
              </span>
            </h2>
          </div>
        </div>
        <hr className="ui-mobile-collapse" />
        <div
          id={`${uuid}-panel`}
          className="overflow-hidden h-20 p-4 md:h-auto xl:p-6"
        >
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:gap-6">
            <div>
              <div className="text-xs text-theme-text-tertiary">Owner</div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {policyDetail.people.owner}
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">Insured</div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {policy.insuredName}
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">
                Owner birthday
              </div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {policyDetail.people.dob}
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">
                Owner issue age
              </div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                age
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">
                Owner addess
              </div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                <div>
                  {policyDetail.people.address.streetNumber}{" "}
                  {policyDetail.people.address.streetName}
                </div>
                <div>
                  {policyDetail.people.address.city},{" "}
                  {policyDetail.people.address.state}{" "}
                  {policyDetail.people.address.zipCode}
                </div>
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">
                Owner phone
              </div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {policyDetail.people.phone.map((item, idx) => (
                  <div key={idx}>
                    {item.number} ({item.type})
                  </div>
                ))}
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">
                Owner email
              </div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                {policyDetail.people.email}
              </div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary">
                Opt in to alerts?
              </div>
              <div className="text-sm font-semibold text-theme-text-secondary">
                <div>
                  {policyDetail.people.notification[0]}
                  {policyDetail.people.notification.length > 1 &&
                    ` & ${policyDetail.people.notification[1]}`}
                </div>
              </div>
            </div>
          </div>

          <div className="mt-4 text-right xl:mt-6">
            <button
              className="ui-btn-primary ui-btn-min-w"
              onClick={() => setIsOpenSendReminder(true)}
            >
              Request customer review
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default OwnerAndInsuredCard;
