import Badge from "../../../General/Badge";
import DownloadDocumentTile from "../../../General/DownloadDocumentTile";
import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";

const ServiceCard = ({ data, cardExpanded }) => {
  const serviceId = uuidv4();

  const [isOpen, setIsOpen] = useState(cardExpanded);

  return (
    <div className="ui-card my-4">
      <div className="p-4 xl:px-6">
        <div className="flex flex-col md:flex-row md:justify-between">
          <div className="text-left">
            <button
              id={serviceId}
              className="flex items-center group"
              aria-controls={serviceId + "-panel"}
              aria-expanded={isOpen}
              aria-label="Expand or collapse service request details"
              onClick={() => setIsOpen((isOpen) => (isOpen = !isOpen))}
            >
              <span className="inline-block p-1 rounded-full mr-1 text-ui-blue group-hover:text-white group-hover:bg-ui-blue">
                <svg
                  className="ui-icon ui-plus w-4 h-4"
                  focusable="false"
                  aria-hidden="true"
                  role="presentation"
                >
                  <use href="#ui-icon-plus"></use>
                </svg>
                <svg
                  className="ui-icon ui-minus w-4 h-4"
                  focusable="false"
                  aria-hidden="true"
                  role="presentation"
                >
                  <use href="#ui-icon-minus"></use>
                </svg>
              </span>
              {data.badgeStatus && (
                <span className="mr-1 flex items-center md:hidden">
                  <Badge
                    iconOnly={true}
                    status={data.badgeStatus}
                    statusText={data.badgeText}
                    srPrefix="Service request"
                  />
                </span>
              )}
              <span className="text-lg font-semibold">ID: {data.id}</span>
            </button>
          </div>
          {data.badgeStatus && (
            <div className="hidden md:block">
              <Badge
                status={data.badgeStatus}
                statusText={data.badgeText}
                srPrefix="Service request"
              />
            </div>
          )}
        </div>
      </div>
      <hr className="ui-collapse" />
      <div className="p-4 xl:px-6">
        <div className="text-xs text-theme-text-tertiary">
          Policy # {data.policyNumber}
        </div>
        <div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 xl:gap-6">
          <div>
            <div className="text-xs text-theme-text-tertiary">Service Type</div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              {data.type}
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">
              Date submitted
            </div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              {data.dateSubmitted}
            </div>
          </div>
          <div>
            <div className="text-xs text-theme-text-tertiary">
              Expected to complete
            </div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              {data.expectedComplete}
            </div>
          </div>
        </div>
      </div>
      <div id={serviceId + "-panel"} className="px-4 pb-4 xl:px-6 xl:pb-6">
        {isOpen && (
          <div>
            <hr />
            <div className="text-lg font-semibold">Request Details</div>
            <div className="mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-4 xl:mt-6 xl:gap-6">
              <div>
                <div className="text-xs text-theme-text-tertiary">
                  Original insured name
                </div>
                <div className="text-sm font-semibold text-theme-text-secondary">
                  {data.originalInsured}
                </div>
              </div>
              <div>
                <div className="text-xs text-theme-text-tertiary">
                  New insured name
                </div>
                <div className="text-sm font-semibold text-theme-text-secondary">
                  {data.newInsured}
                </div>
              </div>
              <div>
                <div className="text-xs text-theme-text-tertiary">
                  Reason for change
                </div>
                <div className="text-sm font-semibold text-theme-text-secondary">
                  {data.reason}
                </div>
              </div>
              <div>
                <div className="text-xs text-theme-text-tertiary">
                  Any other relevant field
                </div>
                <div className="text-sm font-semibold text-theme-text-secondary">
                  {data.other}
                </div>
              </div>
            </div>

            <div className="mt-4 xl:mt-6">
              <div className="ui-download-document">
                {data.documents.map((document) => {
                  return (
                    <DownloadDocumentTile data={document} key={document.id} />
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ServiceCard;
