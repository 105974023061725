import { Link } from "react-router-dom";
export default function ServicesAndClaimsRow({ data, type }) {
  return (
    <div className="ui-card-bar relative mt-4">
      <div className="px-3 py-2 lg:p-4">
        <div className="grid grid-cols-2 gap-2 md:grid-cols-4 md:gap-4 lg:grid-cols-5">
          <div className="lg:col-span-2">
            <div className="text-sm font-semibold text-theme-text-secondary">
              {data.title}
            </div>
            <div>
              <div className={`ui-badge-pill ui-badge-pill-${data.status}`}>
                {data.statusText}
              </div>
            </div>
          </div>
          <div className="">
            <div className="text-xs text-theme-text-tertiary">Policy </div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              #{data.policyNumber}
            </div>
          </div>
          <div className="">
            <div className="text-xs text-theme-text-tertiary">
              {type === "service" && "Service Request ID"}
              {type === "claim" && "Claim Type"}
            </div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              {type === "service" && data.requestId}
              {type === "claim" && data.claimType}
            </div>
          </div>
          <div className="">
            <div className="text-xs text-theme-text-tertiary">Date Submitted</div>
            <div className="text-sm font-semibold text-theme-text-secondary">
              {data.dateSubmitted}
            </div>
          </div>
        </div>
      </div>
      <Link
        to={
          type === "claim"
            ? `/services-claims/claims/${data.id}`
            : `/services-claims/services/${data.id}`
        }
        className="ui-btn-arrow-details row-span-2"
        aria-label={`View ${type} details`}        
      >
        <svg
          className="ui-icon w-8 h-8"
          focusable="false"
          aria-hidden="true"
          role="presentation"
        >
          <use href="#ui-icon-next"></use>
        </svg>
      </Link>
    </div>
  );
}
