import FormInputError from "../../General/FormInputError";
import { moneyMask } from "../../../js/formatting";
import { v4 as uuidv4 } from "uuid";
export default function PaymentAmount({
  register,
  validate,
  getFieldState,
  getValues,
  errors,
  setCurrentStep,
}) {
  // debounce function config
  let debounceFunction; //timer identifier
  let debounceTimer = 5;

  const paymentAmtId = uuidv4();

  return (
    <>
      <div className="ui-form-card-no-event">
        <label
          id={paymentAmtId + "-label"}
          htmlFor={paymentAmtId}
          className="block mb-1"
        >
          Payment amount *
        </label>
        <input
          id={paymentAmtId}
          aria-labelledby={paymentAmtId + "-label"}
          aria-describedby={`
            ${paymentAmtId + "-help"}
            ${paymentAmtId + "-error"}
          `}
          type="text"
          placeholder="Please enter pay amount"
          {...register("amount", {
            required: {
              value: true,
              message: "Please enter an amount to pay",
            },
          })}
          onKeyUp={(e) => {
            clearTimeout(debounceFunction);
            debounceFunction = setTimeout(async () => {
              await validate("amount");
              if (!getFieldState("amount").invalid) {
                e.target.value = moneyMask(getValues("amount"));
                setCurrentStep(3);
              } else {
                setCurrentStep(2);
              }
            }, debounceTimer);
          }}
          onKeyDown={() => {
            clearTimeout(debounceFunction);
          }}
        />
        <div id={paymentAmtId + "-error"}>
          {errors.amount?.message && (
            <FormInputError errorMessage={errors.amount.message} />
          )}
        </div>
        <div
          id={paymentAmtId + "-help"}
          className="text-xxs text-theme-text-tertiary mt-1"
        >
          Use $XX.XX
        </div>
      </div>
    </>
  );
}
