import { useState, useEffect } from "react";
import { RadioGroup } from "@headlessui/react";
import FormInputError from "../../General/FormInputError";

export default function SelectMethod({
  register,
  setValue,
  errors,
  showNext,
  setIsPaymentMethodDialogOpen,
}) {
  const [option, setOption] = useState("");
  const [defaultCardMessage, setDefaultCardMessage] = useState("");
  const paymentMethodData = [
    {
      id: "1",
      type: "Mastercard",
      ending: "4321",
      default: false,
    },
    {
      id: "2",
      type: "Visa",
      ending: "1234",
      default: false,
    },
    {
      id: "3",
      type: "Checking Account",
      ending: "3241",
      default: false,
    },
  ];

  useEffect(() => {
    let defaultCard = paymentMethodData.find((card) => card.default);
    if (defaultCard)
      setDefaultCardMessage(
        `Make ${defaultCard.type} ending in ${defaultCard.ending} my default payment method`
      );
  }, []);

  return (
    <>
      <div className="px-0 md:px-8">
        <RadioGroup
          className="ui-form-card-row2 font-semibold grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4"
          aria-label="Select payment method"
          value={option}
          onChange={(value) => {
            setOption(value);
            setValue("method", value);
          }}
        >
          {paymentMethodData.map((card) => {
            return (
              <RadioGroup.Option
                key={`${card.type}-${card.ending}`}
                className={({ checked }) =>
                  `ui-form-card cursor-pointer font-semibold ${
                    checked ? "selected" : ""
                  }`
                }
                value={card.id}
                onClick={() => {
                  showNext();
                  setDefaultCardMessage(
                    `Make ${card.type} ending in ${card.ending} my default payment method`
                  );
                }}
              >
                <RadioGroup.Label as="label">
                  <span className="block text-xxs font-semibold mr-5 mb-1">
                    {card.type}
                  </span>
                  <span className="block text-sm font-semibold">
                    Ending in {card.ending}
                  </span>
                  {card.default && (
                    <span className="block text-xxs text-theme-text-tertiary font-normal">
                      (Default)
                    </span>
                  )}
                </RadioGroup.Label>
                <div className="ui-form-card-checkbox">
                  <svg
                    className="ui-icon w-2 h-2 text-white hidden"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-checkmark"></use>
                  </svg>
                </div>
              </RadioGroup.Option>
            );
          })}
          <button
            id="ID_paymentStep3Option4"
            type="button"
            className="ui-form-card "
            tabIndex="1"
            aria-label="Add payment a method"
            onClick={() =>
              setIsPaymentMethodDialogOpen({
                addPaymentModal: true,
              })
            }
          >
            <div className="ui-form-card-icon">
              <svg
                className="ui-icon h-3 w-3"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href="#ui-icon-plus"></use>
              </svg>
            </div>
            <span className="ui-link mr-5 mb-1">Add payment method</span>
          </button>
        </RadioGroup>

        {defaultCardMessage && (
          <div className="mt-4">
            <label className="ui-checkbox">
              <input
                type="checkbox"
                className="w-auto"
                {...register("defaultCard")}
              />
              <div className="ui-checkmark">
                <svg
                  className="ui-icon w-2 h-2"
                  focusable="false"
                  aria-hidden="true"
                  role="presentation"
                >
                  <use href="#ui-icon-checkmark"></use>
                </svg>
              </div>
              <span>{defaultCardMessage}</span>
            </label>
          </div>
        )}

        {errors.method?.message && (
          <FormInputError errorMessage={errors.method.message} />
        )}
      </div>
    </>
  );
}
