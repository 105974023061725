import { Link } from "react-router-dom";
export default function SimpleCallToActionCard({
  title,
  desc,
  ctaText,
  ctaUrl,
}) {
  return (
    <div className="ui-card p-4 xl:p-6">
      <div className="text-xl font-semibold">{title}</div>
      <hr />
      <p className="text-xs">{desc}</p>
      <div className="text-right mt-4 xl:mt-6">
        <Link to={ctaUrl}>
          <button className="ui-btn-primary">{ctaText}</button>
        </Link>
      </div>
    </div>
  );
}
