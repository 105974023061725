import { agentReportsAppNavigations } from "../../js/placeholderData/agents/agentAppNavigationCardData";
import { agentReportsData } from "../../js/placeholderData/agents/agentReportsData";

import { Tab } from "@headlessui/react";
import { useState } from "react";

import AppNavigationCard from "../../components/General/AppNavigationCard";
import FilteredReportTabPanel from "../../components/AgentPortal/AgentReport/FilteredReportTabPanel";
import FilterDialog from "../../components/AgentPortal/SalesMaterials/FilterDialog";

export default function AgentReports() {
  const PARTIALREPORTCOUNT = 4;
  const [showAllReportTypes, setShowAllReportTypes] = useState(false);
  const [filtedReports, setFilteredReports] = useState(agentReportsData);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);

  return (
    <>
      <div className="container mb-8">
        <h1>Reports</h1>
        <p>
          Lorem ipsum dolor sit amet, eam ad autem fabulas. Expetenda dissentiet
        </p>
        <hr />
      </div>

      <div className="container ui-component-spacing">
        <h2>Reports you can run</h2>
        <p className="text-sm">
          Text goes here to explain that below reports an agent can run. The
          business can decide which reports are shown by default and in which
          order.
        </p>
        <hr />

        {/* DEV NOTE: Please see "agentAppNavigationCardData.js" to change to appropriate icon */}
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 gap-4 auto-rows-fr">
          {showAllReportTypes
            ? // all report types
              agentReportsAppNavigations.map((item, idx) => (
                <AppNavigationCard
                  key={`agent-app-navigation-card-${idx}`}
                  icon={item.icon}
                  title={item.title}
                  desc={item.desc}
                  url={item.url}
                />
              ))
            : // partial reports
              agentReportsAppNavigations
                .slice(0, PARTIALREPORTCOUNT)
                .map((item, idx) => (
                  <AppNavigationCard
                    key={`agent-app-navigation-card-${idx}`}
                    icon={item.icon}
                    title={item.title}
                    desc={item.desc}
                    url={item.url}
                  />
                ))}
        </div>

        <button
          className="ui-btn-primary mt-6"
          onClick={() => setShowAllReportTypes((option) => (option = !option))}
        >
          View {showAllReportTypes ? "Less" : "More"}
        </button>
      </div>

      <div className="container ui-component-spacing">
        <h2>Saved Reports</h2>

        <p className="text-sm">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit.
        </p>
        <hr />
        <Tab.Group
          onChange={(tabGroupIndex) => {
            switch (tabGroupIndex) {
              // all
              case 0:
                setFilteredReports(agentReportsData);
                break;
              // category 1
              case 1:
                setFilteredReports(
                  agentReportsData.filter((report) => report.category === "1")
                );
                break;
              // category 2
              case 2:
                setFilteredReports(
                  agentReportsData.filter((report) => report.category === "2")
                );
                break;
              // category 3
              case 3:
                setFilteredReports(
                  agentReportsData.filter((report) => report.category === "3")
                );
                break;
            }
          }}
        >
          <div>
            <div className="ui-card flex-grow px-2 p-1 md:px-4">
              <Tab.List>
                <Tab className="text-sm mx-3 pt-3 pb-2 mb-1 md:text-base md:mx-4 lg:text-lg">
                  All report Type
                </Tab>
                <Tab className="text-sm mx-3 pt-3 pb-2 mb-1 md:text-base md:mx-4 lg:text-lg">
                  Category 1
                </Tab>
                <Tab className="text-sm mx-3 pt-3 pb-2 mb-1 md:text-base md:mx-4 lg:text-lg">
                  Custom reports &amp; analytics
                </Tab>
              </Tab.List>
            </div>

            <div className="mx-2 flex flex-col items-centers justify-between md:mx-4 md:flex-row md:space-x-4">
              <div className="flex flex-row items-center mt-4 xl:mt-6"></div>
              <div className="flex flex-row items-center mt-4 space-x-4 justify-between md:justify-end lg:space-x-6 xl:mt-6">
                <div>
                  <button
                    className="ui-btn-primary flex items-center"
                    onClick={() => setFilterDialogOpen(true)}
                  >
                    <svg
                      className="ui-icon w-3.5 h-3.5 mr-2"
                      focusable="false"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <use href="#ui-icon-filter"></use>
                    </svg>
                    Filter options
                  </button>
                  {/* DEV NOTE: I dont think we have a mock for filtering. might need to ask jay about the mock */}
                  <FilterDialog
                    dialogState={filterDialogOpen}
                    setDialogState={setFilterDialogOpen}
                  />
                </div>
              </div>
            </div>

          </div>
          <Tab.Panels>
            {/* category all */}
            <Tab.Panel>
              <FilteredReportTabPanel reports={filtedReports} />
            </Tab.Panel>

            {/* category 1 */}
            <Tab.Panel>
              <FilteredReportTabPanel reports={filtedReports} />
            </Tab.Panel>

            {/* category 2 */}
            <Tab.Panel>
              <FilteredReportTabPanel reports={filtedReports} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </>
  );
}
