export const agentServiceClaimNavigationCardData = [
  {
    icon: "#ui-icon-payment",
    title: "My Commissions",
    desc: "Access your commission summaries",
    url: "/commissions",
  },
  {
    icon: "#ui-icon-payment",
    title: "Sales & Training Material",
    desc: "Here goes a brief description of the call to action",
    url: "/sales-training",
  },
  {
    icon: "#ui-icon-payment",
    title: "Explore Help Section",
    desc: "Here goes a brief description of the call to action",
    url: "/help",
  },
  {
    icon: "#ui-icon-payment",
    title: "Manage Your Profile And License",
    desc: "Here goes a brief description of the call to action",
    url: "/profile",
  },
];
