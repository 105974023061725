import { useState } from "react";
import { Disclosure } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import EnrollmentStatus from "../components/Payments/AutoPay/EnrollmentStatus";
import AmountAndFrequency from "../components/Payments/AutoPay/AmountAndFrequency";
import SchedulePaymentDate from "../components/Payments/AutoPay/SchedulePaymentDate";
import SelectMethod from "../components/Payments/AutoPay/SelectMethod";
import OptoutDialog from "../components/Payments/AutoPay/OptoutDialog";
import AddPaymentMethodModal from "../components/ModalDialogs/AddPaymentMethodModal";
import { policies } from "../js/placeholderData/policiesData";
import { useParams } from "react-router-dom";

export default function AutoPay() {
  const { policy_id } = useParams();
  const {
    register: registerAutoPayStepForm,
    setValue,
    handleSubmit: handleAutoPayStepFormSubmit,
    getFieldState,
    trigger,
    formState: { errors },
  } = useForm();

  const [currentStep, setCurrentStep] = useState(1);
  const [disableSubmit, setDisableSubmit] = useState(true);
  const [isOptoutDialogOpen, setIsOptoutDialogOpen] = useState(false);
  const [isPaymentMethodDialogOpen, setIsPaymentMethodDialogOpen] = useState(false);
  const [paymentMethodState, setPaymentMethodState] = useState("Add"); 

  const filteredPolicy = policies.find(
    (item) => item.policyNumber === policy_id
  );

  const setUpAutoPay = (data) => {
    if (data.status === "no") {
      setIsOptoutDialogOpen(true);
    } else {
      alert("submitting data -- " + JSON.stringify(data));
    } 
  };

  return (
    <>
      <div className="flex flex-col flex-grow">
        <div className="border border-transparent">
          <div className="container mb-8">
            <h1>Manage Autopay settings</h1>
            <p>Lorem ipsum</p>
            <hr />
          </div>

          <form
            className="container mb-8"
            onSubmit={handleAutoPayStepFormSubmit(setUpAutoPay)}
          >
            <div className="ui-form-make-payment">
              {/* <!-- Step 1 --> */}
              <div className="flex flex-row md:ml-8 md:mr-0 lg:ml-20 xl:ml-28 lg:pr-16">
                <div className="w-8 mr-2 sm:mr-4 md:flex md:flex-col md:justify-center md:mr-14">
                  <div className="flex justify-center items-center mt-4 mb-4">
                    <div
                      className={`inline-flex justify-center items-center h-8 w-8 text-white ${
                        currentStep >= 1
                          ? "bg-ui-blue-dark"
                          : "bg-ui-gray-medium-dark"
                      } rounded-full overflow-hidden`}
                    >
                      1
                    </div>
                  </div>
                  <div className="flex h-full w-px bg-ui-gray-medium-light mx-auto overflow-hidden"></div>
                </div>
                <div className="w-full flex-grow">
                  <div className="ui-card relative px-4 py-4 mb-6 lg:px-6">
                    <Disclosure as="div">
                      {({ open }) => (
                        <>
                          <Disclosure.Button as="div">
                            <div className="flex justify-between items-start">
                              <h2 className="flex flex-grow font-body font-bold text-xl">
                                Autopay enrollment status
                              </h2>
                              <div className="font-semibold text-sm whitespace-nowrap">
                                Step 1
                              </div>
                            </div>
                          </Disclosure.Button>

                          <div className="pt-4 mt-2 lg:pt-6 border-t border-theme-rule-light">
                            {currentStep >= 1 && (
                              <Disclosure.Panel
                                static
                                className="pb-2 text-sm"
                              >
                                <p className="mb-4 text-sm">
                                  This is a description of the first step of
                                  billing, where we tell the user they can pick
                                  the pending policies or choose any other.
                                </p>

                                <EnrollmentStatus
                                  register={registerAutoPayStepForm}
                                  setValue={setValue}
                                  errors={errors}
                                  setCurrentStep={setCurrentStep}
                                  showNext={() => setCurrentStep(2)}
                                  setDisableSubmit={setDisableSubmit}
                                  filteredPolicy = {filteredPolicy}
                                />
                              </Disclosure.Panel>
                            )}
                          </div>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>

              {/* <!-- Step 2 --> */}
              <div className="flex flex-row md:ml-8 md:mr-0 lg:ml-20 xl:ml-28 lg:pr-16">
                <div className="w-8 mr-2 sm:mr-4 md:flex md:flex-col md:justify-center md:mr-14">
                  <div className="flex justify-center items-center mt-4 mb-4">
                    <div
                      className={`inline-flex justify-center items-center h-8 w-8 text-white ${
                        currentStep >= 2
                          ? "bg-ui-blue-dark"
                          : "bg-ui-gray-medium-dark"
                      } rounded-full overflow-hidden`}
                    >
                      2
                    </div>
                  </div>
                  <div className="flex h-full w-px bg-ui-gray-medium-light mx-auto overflow-hidden"></div>
                </div>
                <div className="w-full flex-grow">
                  <div className="ui-card relative px-4 py-4 mb-6 lg:px-6">
                    <Disclosure as="div">
                      {({ open }) => (
                        <>
                          <Disclosure.Button as="div">
                            <div className="flex justify-between items-start">
                              <h2 className="flex flex-grow font-body font-bold text-xl">
                                Select payment amount &amp; frequency
                              </h2>
                              <div className="font-semibold text-sm whitespace-nowrap">
                                Step 2
                              </div>
                            </div>
                          </Disclosure.Button>
                          <div className="pt-4 mt-2 lg:pt-6 border-t border-theme-rule-light">
                            {currentStep >= 2 && (
                              <Disclosure.Panel
                                className="pb-2 text-sm"
                                static
                              >
                                <p className="mb-4 text-sm">
                                  This is a description of the first step of
                                  billing, where we tell the user they can pick
                                  the pending policies or choose any other.
                                </p>

                                <AmountAndFrequency
                                  register={registerAutoPayStepForm}
                                  setValue={setValue}
                                  errors={errors}
                                  showNext={() => setCurrentStep(3)}
                                />
                              </Disclosure.Panel>
                            )}
                          </div>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>

              {/* <!-- Step 3 --> */}
              <div className="flex flex-row md:ml-8 md:mr-0 lg:ml-20 xl:ml-28 lg:pr-16">
                <div className="w-8 mr-2 sm:mr-4 md:flex md:flex-col md:justify-center md:mr-14">
                  <div className="flex justify-center items-center mt-4 mb-4">
                    <div
                      className={`inline-flex justify-center items-center h-8 w-8 text-white ${
                        currentStep >= 3
                          ? "bg-ui-blue-dark"
                          : "bg-ui-gray-medium-dark"
                      } rounded-full overflow-hidden`}
                    >
                      3
                    </div>
                  </div>
                  <div className="flex h-full w-px bg-ui-gray-medium-light mx-auto overflow-hidden"></div>
                </div>
                <div className="w-full flex-grow">
                  <div className="ui-card relative px-4 py-4 mb-6 lg:px-6">
                    <Disclosure as="div">
                      {({ open }) => (
                        <>
                          <Disclosure.Button as="div">
                            <div className="flex justify-between items-start">
                              <div className="flex flex-start">
                                <h2 className="flex flex-grow font-body font-bold text-xl">
                                  Select payment method
                                </h2>
                              </div>

                              <div className="font-semibold text-sm whitespace-nowrap">
                                Step 3
                              </div>
                            </div>
                          </Disclosure.Button>
                          <div
                            id="ID_paymentStep3Options"
                            className="pt-4 mt-2 lg:pt-6 border-t border-theme-rule-light"
                          >
                            {currentStep >= 3 && (
                              <Disclosure.Panel
                                className="pb-2 text-sm"
                                static
                              >
                                <p className="mb-4 text-sm">
                                  This is a description of the first step of
                                  billing, where we tell the user they can pick
                                  the pending policies or choose any other.
                                </p>
                                <SelectMethod
                                  register={registerAutoPayStepForm}
                                  setValue={setValue}
                                  errors={errors}
                                  showNext={() => setCurrentStep(4)}
                                  setIsPaymentMethodDialogOpen={
                                    setIsPaymentMethodDialogOpen
                                  }
                                />
                              </Disclosure.Panel>
                            )}
                          </div>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>

              {/* <!-- Step 4 --> */}
              <div className="flex flex-row md:ml-8 md:mr-0 lg:ml-20 xl:ml-28 lg:pr-16">
                <div className="w-8 mr-2 sm:mr-4 md:flex md:flex-col md:justify-center md:mr-14">
                  <div className="flex justify-center items-center mt-4 mb-4">
                    <div
                      className={`inline-flex justify-center items-center h-8 w-8 text-white ${
                        currentStep >= 4
                          ? "bg-ui-blue-dark"
                          : "bg-ui-gray-medium-dark"
                      } rounded-full overflow-hidden`}
                    >
                      5
                    </div>
                  </div>
                  <div className="flex h-full w-px bg-transparent  mx-auto overflow-hidden">
                    1
                  </div>
                </div>
                <div className="w-full flex-grow">
                  <div className="ui-card relative px-4 py-4 mb-6 lg:px-6">
                    <Disclosure as="div">
                      {({ open }) => (
                        <>
                          <Disclosure.Button as="div">
                            <div className="flex justify-between items-start">
                              <h2 className="flex flex-grow font-body font-bold text-xl">
                                Schedule payment day
                              </h2>
                              <div className="font-semibold text-sm whitespace-nowrap">
                                Step 4
                              </div>
                            </div>
                          </Disclosure.Button>
                          <div
                            id="ID_paymentStep4Options"
                            className="pt-4 mt-2 lg:pt-6 border-t border-theme-rule-light"
                          >
                            {currentStep >= 4 && (
                              <Disclosure.Panel
                                className="pb-2 text-sm"
                                static
                              >
                                <p className="mb-4 text-sm">
                                  This is a description of the first step of
                                  billing, where we tell the user they can pick
                                  the pending policies or choose any other.
                                </p>

                                <SchedulePaymentDate
                                  register={registerAutoPayStepForm}
                                  errors={errors}
                                  trigger={trigger}
                                  getFieldState={getFieldState}
                                  setDisableSubmit={setDisableSubmit}
                                />
                              </Disclosure.Panel>
                            )}
                          </div>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </div>
              </div>

              <div className="block text-right mb-8 md:ml-12 md:mr-6 lg:ml-28 lg:pr-16">
                <Link to="/payment/manage-payments">
                  <button
                    className="ui-btn-secondary min-w-[140px]"
                    aria-label="cancel auto pay editing"
                  >
                    Cancel
                  </button>
                </Link>
                <button
                  id="ID_submitPayment"
                  className="ui-btn-primary min-w-[140px] ml-3"
                  aria-label="submit auto pay information"
                  disabled={disableSubmit || currentStep >= 5}
                >
                  Submit
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <OptoutDialog
        isDialogOpen={isOptoutDialogOpen}
        setDialogopen={setIsOptoutDialogOpen}
      />

      <AddPaymentMethodModal
        modalState={isPaymentMethodDialogOpen}
        setModalState={setIsPaymentMethodDialogOpen}
        config={paymentMethodState}
        setConfig={setPaymentMethodState}
      />
    </>
  );
}
