import { HashLink } from "react-router-hash-link";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer>
      <div className="container py-6 lg:py-8">
        <div className="flex w-full flex-grow justify-between items-start">
          <div className="flex flex-wrap flex-row items-start text-sm  md:items-center md:text-base">
            <Link to="/help" className="inline-block mb-4 mr-8">
              FAQ &amp; Help
            </Link>
            <HashLink to="/help#contact-us" className="inline-block mb-4 mr-8">
              Contact Us
            </HashLink>
            <a
              href="https://www.everlakelife.com/"
              target="_blank"
              className="inline-flex items-center basis-full mb-4 mr-8 sm:basis-auto"
            >
              <svg
                className="ui-icon w-6 h-6 mr-3"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href="#ui-icon-external-link"></use>
              </svg>
              Visit everlakelife.com
            </a>
          </div>
          <div className="flex items-start">
            <Link to="/policies" title="Return to dashboard">
              <div id="everlakeLogoFooter" className="ui-logo">
                <img
                  src="/assets/img/everlake-logo-white.svg"
                  className="ui-logo-footer-mobile"
                  alt="Everlake Logo"
                />
              </div>
              <div id="lumicoLogoFooter" className="ui-logo">
                <img
                  src="assets/img/lumico-footer-logo.png"
                  className="ui-logo-footer-mobile"
                  alt="Lumico Logo"
                />
              </div>
            </Link>
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex flex-col-reverse flex-wrap md:flex-row text-xs">
            <div className="mb-2 mr-5 basis-full sm:basis-auto">
              © Copyright {new Date().getFullYear()} Everlake
            </div>
            <div>
              <Link to="/policies" className="inline-block mb-2 mr-5">
                Terms of Use
              </Link>
              <Link to="/policies" className="inline-block mb-2 mr-5">
                Privacy Policy
              </Link>
              <Link to="/policies" className="inline-block mb-2 mr-5">
                Accessibility Statement
              </Link>
            </div>
          </div>
          <hr className="mt-2 mb-4" />
          {localStorage.getItem("isCookiesAccepted") &&
          localStorage.getItem("isCookiesAccepted") === "true" ? (
            ""
          ) : (
            <div className="flex flex-wrap flex-grow justify-center text-xs">
              <span className="mb-4 mr-5">
                This website uses cookies. Read more about our Privacy Notice
                &amp; Terms of Use
              </span>
              <div className="block md:inline">
                <Link to="/policies" className="mb-4 mr-5">
                  Opt out
                </Link>
                <Link
                  to="/policies"
                  className="mb-4 mr-5"
                  onClick={() =>
                    localStorage.setItem("isCookiesAccepted", "true")
                  }
                >
                  Accept and close
                </Link>
              </div>
            </div>
          )}
        </div>
      </div>
    </footer>
  );
}
