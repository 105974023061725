import Tippy from "@tippyjs/react";
import "../../css/tooltip.css";
import { useNavigate } from "react-router-dom";
export default function Tooltip({
  srText,
  content,
  placement = "bottom",
  xMargin = "mx-2",
  cta
}) {
  let navigate= useNavigate()
  return (
    <Tippy
      content={<p class="text-theme-text-primary">{content}</p>}
      placement={placement}
      offset={[0, 4]}
      popperOptions={{
        modifiers: [
          {
            name: "flip",
            options: {
              fallbackPlacements: ["bottom", "top"],
            },
          },
        ],
      }}
    >
      <div className="relative inline-block"> 
         <button
            className={`ui-btn-icon h-5 w-5 rounded-full flex items-center justify-center ${xMargin}`}     
            onClick={() => cta? navigate(cta) : false }    
            >
            <svg
              className="ui-icon w-4 h-4"
              focusable="false"
              aria-hidden="true"
              role="presentation"
            >
              <use href="#ui-icon-help3"></use>
            </svg>
            <span className="sr-only">{srText}</span>
          </button>            
      </div>
    </Tippy>
  );
}
