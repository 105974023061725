import { Disclosure, Transition } from "@headlessui/react";

export default function Accordion({ item }) {
  return (
    <Disclosure>
      {({ open }) => (
        <div className="ui-card ui-accordion_item">
          <Disclosure.Button className="group text-lg text-left p-4 font-semibold flex items-start justify-start hover:text-theme-action-hover">
            <span className="flex-none no-underline mt-[.375rem] mr-4">
              <svg
                className="ui-icon w-4 h-4 text-theme-action group-hover:text-theme-action-hover"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href={`#${open ? "ui-icon-minus" : "ui-icon-plus"}`}></use>
              </svg>
            </span>
            <span className="group-hover:underline">{item.title}</span>
          </Disclosure.Button>
          <Transition
            show={open}
            enter="transition duration-300 ease-out"
            enterFrom="transform scale-y-50 opacity-0"
            enterTo="transform scale-y-100 opacity-100"
            leave="transition duration-300 ease-out"
            leaveFrom="transform scale-y-100 opacity-100"
            leaveTo="transform scale-y-50 opacity-0"
          >
            <Disclosure.Panel className="overflow-hidden transition-all ease-in-out duration-300">
              {/* note: need to clean up html parsing note: need to add focus management for closed answers */}
              <div
                className={`p-4 border-t border-theme-rule-medium ${
                  open ? "" : ""
                }`}
                dangerouslySetInnerHTML={{ __html: item.desc }}
              ></div>
            </Disclosure.Panel>
          </Transition>
        </div>
      )}
    </Disclosure>
  );
}
