import { useState, useEffect } from "react";
import { securityQuestions } from "../js/placeholderData/securityQuestions";
export const useDynamicSecurityQuestionsOptions = () => {
  const [securityQuestionOneId, setSecurityQuestionOneId] = useState(0);
  const [securityQuestionsOne, setSecurityQuestionsOne] =
    useState(securityQuestions);
  const [securityQuestionTwoId, setSecurityQuestionTwoId] = useState(0);
  const [securityQuestionsTwo, setSecurityQuestionsTwo] =
    useState(securityQuestions);
  const [securityQuestionThreeId, setSecurityQuestionThreeId] = useState(0);
  const [securityQuestionsThree, setSecurityQuestionsThree] =
    useState(securityQuestions);

  useEffect(() => {
    setSecurityQuestionsOne(
      securityQuestions.filter(
        (question) =>
          question.id !== securityQuestionTwoId &&
          question.id !== securityQuestionThreeId
      )
    );
    setSecurityQuestionsTwo(
      securityQuestions.filter(
        (question) =>
          question.id !== securityQuestionOneId &&
          question.id !== securityQuestionThreeId
      )
    );
    setSecurityQuestionsThree(
      securityQuestions.filter(
        (question) =>
          question.id !== securityQuestionOneId &&
          question.id !== securityQuestionTwoId
      )
    );
  }, [securityQuestionOneId, securityQuestionTwoId, securityQuestionThreeId]);

  return {
    setSecurityQuestionOneId,
    securityQuestionsOne,
    setSecurityQuestionTwoId,
    securityQuestionsTwo,
    setSecurityQuestionThreeId,
    securityQuestionsThree,
  };
};
