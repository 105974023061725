import { Dialog, Transition } from "@headlessui/react";
import { useForm } from "react-hook-form";
import { useState, Fragment } from "react";
import { policyArray } from "../../js/placeholderData/managePaymentData";
import DialogBackdrop from "./DialogBackdrop";

const DeleteAutopayPaymentModal = ({ modalState, setModalState, setAddPaymentModal }) => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    reValidateMode: "onBlur",
    shouldUnregister: true,
  });

  const [autopaySetting, setAutopaySetting] = useState(); 

  const onSubmit = () => {
    if (autopaySetting === "remain") {
      setModalState(false)
      setAddPaymentModal(true)
    } else setModalState(false);
  };

  const onOptionChange = (e) => setAutopaySetting(e.target.value);

  return (
    <Transition appear show={modalState} as={Fragment}>
      <Dialog
        open={modalState}
        onClose={() => setModalState(false)}
        className="ui-dialogs fixed inset-0 z-10 overflow-y-auto"
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <DialogBackdrop />

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Overlay className="fixed inset-0" />
        </Transition.Child>

        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <Dialog.Panel className="ui-default-dialog">
            <Dialog.Title className="font-body text-xl font-bold text-left">
              Delete payment method
            </Dialog.Title>
            <button
              className="ui-default-dialog-close"
              onClick={() => setModalState(false)}
            >
              <svg
                className="ui-icon h-3.5 w-3.5"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href="#ui-icon-close"></use>
              </svg>
            </button>

            <hr />

            <form onSubmit={handleSubmit(onSubmit)}>
              <Dialog.Description as="div">
                <p>
                  Are you sure you want to cancel this payment? Please be sure
                  to select another payment option, if necessary, for this
                  policy if you cancel this payment.
                </p>

                <fieldset>
                  <div className="text-sm font-semibold">Autopay settings</div>
                  <div className="grid grid-cols-1 mt-1 gap-1 justify-start">
                    <div>
                      <label className="inline-flex items-start md:items-center text-sm font-normal">
                        <input
                          type="radio"
                          name="autopayPref"
                          id="ID_autopayPrefRemain"
                          className="mr-2 mt-1 md:mt-0"
                          value="remain"
                          checked={autopaySetting === "remain"}
                          onChange={(e) => onOptionChange(e)}
                        />
                        You can link policies to a new payment method and remain
                        in autopay.
                      </label>
                    </div>
                    <div>
                      <label className="inline-flex items-start md:items-center text-sm font-normal">
                        <input
                          type="radio"
                          name="autopayPref"
                          id="ID_autopayPrefUnenroll"
                          className="mr-2 mt-1 md:mt-0"
                          value="unenroll"
                          checked={autopaySetting === "unenroll"}
                          onChange={(e) => onOptionChange(e)}
                        />
                        Or you can delete the payment method which will unenroll
                        these policies from autopay.
                      </label>
                    </div>
                  </div>
                </fieldset>

                <div className="mt-6">
                  <div className="text-sm font-semibold">Linked policies</div>
                  <div>
                    <label className="ui-checkbox mt-1">
                      <input
                        type="checkbox"
                        value="unenroll"
                        checked={autopaySetting === "unenroll"}
                        {...register("apply to all linked policies")}
                        onClick={() => onOptionChange()}
                      />
                      <div className="ui-checkmark">
                        <svg
                          className="ui-icon w-2 h-2 text-white"
                          focusable="false"
                          aria-hidden="true"
                          role="presentation"
                        >
                          <use href="#ui-icon-checkmark"></use>
                        </svg>
                      </div>
                      <span>Apply across all linked policies</span>
                    </label>
                  </div>
                  {autopaySetting === "unenroll" && (
                    <div className="text-ui-red font-semibold ">
                      All below policies will be unenrolled
                    </div>
                  )}
                </div>

                {policyArray.map((item, index) => (
                  <div
                    key={`policy ${index}`}
                    className="p-2 border rounded border-ui-gray-medium-light bg-ui-gray-lightest mt-4"
                  >
                    <div className="grid grid-cols-2 gap-2 place-content-stretch">
                      <div>
                        <div className="text-xs text-theme-text-tertiary">
                          {item.policyNumber}
                        </div>
                        <div className="text-sm font-semibold text-theme-text-secondary">
                          {item.details}
                        </div>
                      </div>
                      <div>
                        <select
                          aria-label={`select payment method for Policy ${item.policyNumber} `}
                        >
                          {policyArray.map((item, idx) => (
                            <option key={`card ${idx}`} value={item.cardNumber}>
                              {item.paymentMethod}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                ))}

                <p className="mt-4 md:mt-6 text-ui-red">
                  Note: by clicking submit the above policies will be unenrolled
                  from autopay. Please remember to make one-time payment
                  arrangements by due dates to avoid policy lapse.
                </p>
              </Dialog.Description>
              <div className="block text-right mt-6">
                <button
                  className="ui-btn-secondary ui-btn-min-w mt-4"
                  onClick={() => setModalState(false)}
                >
                  Cancel
                </button>
                <button className="ui-btn-primary ui-btn-min-w mt-4">
                  Submit
                </button>
              </div>
            </form>
          </Dialog.Panel>
        </Transition.Child>
      </Dialog>
    </Transition>
  );
};

export default DeleteAutopayPaymentModal;
