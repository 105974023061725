export const agentDashboardData = [
  {
    icon: "#ui-icon-payment",
    title: "Access My Commission",
    desc: "Here goes a brief description of the call to action",
    url: "/",
  },
  {
    icon: "#ui-icon-document-multiple",
    title: "Quote & Run Illustrations",
    desc: "Here goes a brief description of the call to action",
    url: "/",
  },
  {
    icon: "#ui-icon-edit",
    title: "Make policy changes",
    desc: "Here goes a brief description of the call to action",
    url: "/",
  },
  {
    icon: "#ui-icon-book",
    title: "Sales Materials & Training",
    desc: "Here goes a brief description of the call to action",
    url: "/",
  },
  {
    icon: "#ui-icon-help",
    title: "Explore The Help Section",
    desc: "Here goes a brief description of the call to action",
    url: "/",
  },
  {
    icon: "#ui-icon-apply",
    title: "Submit an Application",
    desc: "Here goes a brief description of the call to action",
    url: "/",
  },
  {
    icon: "#ui-icon-cash",
    title: "Lorem Ipsum",
    desc: "Here goes a brief description of the call to action",
    url: "/",
  },
  {
    icon: "#ui-icon-edit",
    title: "Lorem Ipsum",
    desc: "Here goes a brief description of the call to action",
    url: "/",
  },
];

export const agentReportsAppNavigations = [
  {
    icon: "#ui-icon-payment",
    title: "Annual Book of Business",
    desc: "Here goes a brief description of the call to action",
    url: "/reports/1",
  },
  {
    icon: "#ui-icon-document-multiple",
    title: "Line of business reports",
    desc: "Here goes a brief description of the call to action",
    url: "/reports/1",
  },
  {
    icon: "#ui-icon-edit",
    title: "Lead reports",
    desc: "Here goes a brief description of the call to action",
    url: "/reports/1",
  },
  {
    icon: "#ui-icon-book",
    title: "New business report",
    desc: "Here goes a brief description of the call to action",
    url: "/reports/1",
  },
  {
    icon: "#ui-icon-help",
    title: "Quarterly report",
    desc: "Here goes a brief description of the call to action",
    url: "/reports/1",
  },
  {
    icon: "#ui-icon-apply",
    title: "Monthly report",
    desc: "Here goes a brief description of the call to action",
    url: "/reports/1",
  },
  {
    icon: "#ui-icon-cash",
    title: "Name of report",
    desc: "Here goes a brief description of the call to action",
    url: "/reports/1",
  },
  {
    icon: "#ui-icon-edit",
    title: "Name of report",
    desc: "Here goes a brief description of the call to action",
    url: "/reports/1",
  },
];
