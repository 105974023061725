import { Link } from "react-router-dom";
export default function DownloadDocumentTile({ data }) {
  return (
    <div
      // className="py-2 px-4 border border-ui-gray-light-medium rounded"
      className="inline-block py-2 px-4 border border-ui-gray-light-medium rounded"
      key={data.id}
    >
      <div className="text-xs font-semibold text-theme-text-secondary">
        {data.name}
      </div>
      <div>
        <Link to={data.location} className="ui-link-icon font-bold text-xs">
          <svg
            className="ui-icon w-4 h-4"
            focusable="false"
            aria-hidden="true"
            role="presentation"
          >
            <use href="#ui-icon-document-download"></use>
          </svg>
          {data.desc}
        </Link>
      </div>
    </div>
  );
}
