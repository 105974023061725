import { useEffect, useState } from "react";
import FormInputError from "../General/FormInputError";
import { Controller } from "react-hook-form";
import { MaskedInput } from 'react-control-library';
import Tooltip from "../General/Tooltip";

const AddPaymentCard = ({
  register,
  errors,
  trigger,
  watch = false,
  autoValidate = false,
  validateTimer = "",
  setValidationState,
  control,
}) => {
  let debounceFunction;
  useEffect(() => {
    if (autoValidate) {
      !watch(["cardNumber", "expMonth", "expYear", "securityCode"]).filter(
        (v) => !v
      ).length
        ? setValidationState(true)
        : setValidationState(false);
    }
  }, [watch(["cardNumber", "expMonth", "expYear", "securityCode"])]);

  const [cvv, setCvv] = useState("")
  const [card, setCard] = useState("")

  const cardMask = () => [
    /^[0-9]*$/, /^[0-9]*$/, /^[0-9]*$/, /^[0-9]*$/, " ",
    /^[0-9]*$/, /^[0-9]*$/, /^[0-9]*$/, /^[0-9]*$/, " ",
    /^[0-9]*$/, /^[0-9]*$/, /^[0-9]*$/, /^[0-9]*$/, " ",
    /^[0-9]*$/, /^[0-9]*$/, /^[0-9]*$/, /^[0-9]*$/, " ",
  ]
  const CvvMask = () => [/^[0-9]*$/, /^[0-9]*$/, /^[0-9]*$/]

  return (
    <>
      <div className="">
        <label id="ID_creditCardNumberLabel" htmlFor="ID_creditCardNumber">
          Credit card number:
        </label>
        <div>
          <Controller
            name="cardNumber"
            control={control}
            render={({ field: { ref, ...rest } }) => (
              <MaskedInput
                {...rest}
                id="ID_creditCardNumber"
                type="text"
                mask={cardMask()}
                value={card}
                aria-labelledby="ID_creditCardNumberLabel"
                aria-describedby="ID_creditCardNumberError ID_creditCardNumberNote"
                onKeyUp={() => {
                  if (autoValidate) {
                    clearTimeout(debounceFunction);
                    debounceFunction = setTimeout(async () => {
                      await trigger("cardNumber");
                    }, validateTimer);
                  }
                }}
                onKeyDown={() => {
                  if (autoValidate) {
                    clearTimeout(debounceFunction);
                  }
                }}
              />
            )}
            rules={{
              required: { value: true, message: "Please enter card number" },
            }}
          />

          <FormInputError
            id="ID_creditCardNumberError"
            errorMessage={errors.cardNumber?.message}
          />
          <div
            id="ID_creditCardNumberNote"
            className="text-xxs text-theme-text-tertiary mt-1"
          >
            We accept American Express Discover Card JCB Visa Mastercard
          </div>
        </div>
      </div>
      {/* empty div to push div to next grid */}
      <div className="hidden md:grid md:col-span-1"></div>
      <div>
        <label id="ID_expDateLabel" htmlFor="ID_expDate">
          Expiration date:
        </label>
        <div className="grid gap-x-4 grid-cols-2">
          <div>
            <select
              {...register("expMonth", {
                required: "Please select a month for card expiration.",
              })}
            >
              <option value="">Select month</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
            </select>
          </div>

          <div>
            <select
              {...register("expYear", {
                required: "Please select a year for card expiration.",
              })}
            >
              <option value="">Select year</option>
              {[...Array(30)].map((v, idx) => {
                return (
                  <option
                    key={`credit card year selection - ${idx}`}
                    value={parseInt(new Date().getFullYear()) + idx}
                  >
                    {parseInt(new Date().getFullYear()) + idx}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="col-span-2">
            <FormInputError
              id="ID_expMonthError"
              errorMessage={errors.expMonth?.message}
            />
            <FormInputError
              id="ID_expYearError"
              errorMessage={errors.expYear?.message}
            />
          </div>
        </div>
      </div>
      <div>
        <div className="ui-has-tooltip">
          <div className="text-xs text-theme-text-tertiary">
            <label>Security code:</label>
            <Tooltip
              srText="Tooltip for product name"
              content="This is the 3 digit code on the back of your card"
              xMargin="m-px"
            />
          </div>
        </div>
        <div>
          <Controller
            name="securityCode"
            control={control}
            render={({ field: { ref, ...rest } }) => (
              <MaskedInput
                {...rest}
                id="ID_securityCode"
                type="text"
                mask={CvvMask()}
                value={cvv}
                className="w-28"
                aria-labelledby="ID_securityCodeLabel"
                aria-describedby="ID_securityCodeError ID_securityCodeNote"
                onKeyUp={() => {
                  if (autoValidate) {
                    clearTimeout(debounceFunction);
                    debounceFunction = setTimeout(async () => {
                      await trigger("securityCode");
                    }, validateTimer);
                  }
                }}
                onKeyDown={() => {
                  if (autoValidate) {
                    clearTimeout(debounceFunction);
                  }
                }}
              />
            )}
            rules={{
              required: { value: true, message: "enter valid CVV " },
            }}
          />
        </div>
        <FormInputError
          id="ID_creditCardNumberError"
          errorMessage={errors.securityCode?.message}
        />
        <div
          id="ID_securityCodeNote"
          className="text-xxs text-theme-text-tertiary mt-1"
        >
          Use format XXX
        </div>
      </div>
    </>
  );
};

export default AddPaymentCard;
