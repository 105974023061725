import { useEffect, useState, Fragment } from "react";
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { v4 as uuidv4 } from "uuid";
import DialogBackdrop from "../components/ModalDialogs/DialogBackdrop";
import FACStepOne from "../components/ServicesAndClaims/Claims/FileAClaim/FACStepOne";
import FACStepTwo from "../components/ServicesAndClaims/Claims/FileAClaim/FACStepTwo";
import FACStepThree from "../components/ServicesAndClaims/Claims/FileAClaim/FACStepThree";
import FACStepFour from "../components/ServicesAndClaims/Claims/FileAClaim/FACStepFour";
import FACStepFive from "../components/ServicesAndClaims/Claims/FileAClaim/FACStepFive";
import FormInputError from "../components/General/FormInputError";

export default function FileAClaim() {
  const uuid = uuidv4();
  const {
    register: FACStepSixRegister,
    handleSubmit: FACStepSixHandleSubmit,
    formState: { errors },
  } = useForm();

  const [formStep, setFormStep] = useState(1);
  const [FACFormData, setFACFormData] = useState({});
  const [isStepSixDialogOpen, setIsStepSixDialogOpen] = useState(false);
  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  useEffect(() => {
    if (formStep === 6) setIsStepSixDialogOpen(true);
  }, [formStep]);

  const onSubmit = (data) => {
    console.log({
      ...FACFormData,
      ...data,
    });
    setIsStepSixDialogOpen(false);
    setIsConfirmationDialogOpen(true);
  };

  return (
    <>
      <div className="container mb-8">
        <h1>File a claim</h1>
        <p>
          We are sorry for your loss, our insurance professionals are ready to
          help and guide you through the claim process.
        </p>
        <hr />
      </div>

      <div className="container ui-component-spacing">
        <div className="grid gap-4 grid-cols-1 lg:gap-6 lg:grid-cols-[66.66667%,33.33333%]">
          <div className="grid gap-4 grid-cols-1 lg:gap-6">
            {/* intro card */}
            <div className="ui-card ui-rte-component p-4 xl:p-6">
              <h2 className="text-xl font-body font-bold">
                Before you file: Gather information
              </h2>
              <hr className="mt-4 mb-6" />
              <div className="text-sm">
                <p className="font-semibold">
                  To start a claim, you'll need the following information about
                  the policyholder:
                </p>
                <ul className="mt-4 list-disc list-outside ml-8">
                  <li className="">
                    Policy Number(if available) - if there are multiple policies
                    that the deceased was insured with us, you only need to
                    enter one. We will automatically process the claim for all
                    applicable policies.
                  </li>
                  <li className="">Full Name</li>
                  <li className="">Social Security Number</li>
                  <li className="">Date of Birth</li>
                  <li className="">Date of Death</li>
                  <li className="">Place of Death</li>
                  <li className="">
                    Policyholder's Original, Certified Death Certificate
                  </li>
                </ul>
              </div>
            </div>

            <div>
              {/* step 1 card */}
              <div className="flex flex-row">
                <div className="w-8 mr-2 md:flex md:flex-col md:justify-center md:mr-4 lg:mr-8">
                  <div className="flex justify-center items-center mt-6 mb-4">
                    <div className="inline-flex justify-center items-center h-8 w-8 text-white bg-ui-blue-dark rounded-full overflow-hidden">
                      1
                    </div>
                  </div>
                  <div className="flex h-full w-px bg-ui-gray-medium-light mx-auto overflow-hidden"></div>
                </div>

                <div className="w-full flex-grow">
                  <div className="ui-card relative px-4 py-4 mb-4 xl:mb-6 xl:px-6">
                    <div className="flex justify-between items-start">
                      <h2 className="flex flex-grow font-body font-bold text-xl mb-0">
                        Policy &amp; Deceased information
                      </h2>
                      <div className="font-semibold text-sm whitespace-nowrap">
                        Step 1
                      </div>
                    </div>
                    <FACStepOne
                      formStep={formStep}
                      setFormStep={setFormStep}
                      setFACFormData={setFACFormData}
                    />
                  </div>
                </div>
              </div>

              {/* step 2 card */}
              <div className="flex flex-row">
                <div className="w-8 mr-2 md:flex md:flex-col md:justify-center md:mr-4 lg:mr-8">
                  <div className="flex justify-center items-center mt-3 mb-0">
                    <div
                      className={`inline-flex justify-center items-center h-8 w-8 text-white rounded-full overflow-hidden
                        ${
                          formStep > 1
                            ? "bg-ui-blue-dark"
                            : "bg-ui-gray-medium-dark"
                        }
                        `}
                    >
                      2
                    </div>
                  </div>
                  <div className="flex h-full w-px bg-ui-gray-medium-light mx-auto overflow-hidden"></div>
                </div>
                <div className="w-full flex-grow">
                  <div className="ui-card relative px-4 py-4 mb-4 xl:mb-6 xl:px-6">
                    <div className="flex justify-between items-start">
                      <h2 className="flex flex-grow font-body font-bold text-xl mb-0">
                        About the individual filing the claim
                      </h2>
                      <div className="font-semibold text-sm whitespace-nowrap">
                        Step 2
                      </div>
                    </div>

                    {/* {formStep === 2 && ( */}
                    <FACStepTwo
                      formStep={formStep}
                      setFormStep={setFormStep}
                      setFACFormData={setFACFormData}
                    />
                    {/* )} */}
                  </div>
                </div>
              </div>

              {/* step 3 card */}
              <div className="flex flex-row">
                <div className="w-8 mr-2 md:flex md:flex-col md:justify-center md:mr-4 lg:mr-8">
                  <div className="flex justify-center items-center mt-3 mb-0">
                    <div
                      className={`inline-flex justify-center items-center h-8 w-8 text-white rounded-full overflow-hidden
                        ${
                          formStep > 2
                            ? "bg-ui-blue-dark"
                            : "bg-ui-gray-medium-dark"
                        }
                        `}
                    >
                      3
                    </div>
                  </div>
                  <div className="flex h-full w-px bg-ui-gray-medium-light mx-auto overflow-hidden"></div>
                </div>
                <div className="w-full flex-grow">
                  <div className="ui-card relative px-4 py-4 mb-4 xl:mb-6 xl:px-6">
                    <div className="flex justify-between items-start">
                      <h2 className="flex flex-grow font-body font-bold text-xl mb-0">
                        Beneficiary Information
                      </h2>
                      <div className="font-semibold text-sm whitespace-nowrap">
                        Step 3
                      </div>
                    </div>

                    {/* {formStep === 3 && ( */}
                    <FACStepThree
                      formStep={formStep}
                      setFormStep={setFormStep}
                      setFACFormData={setFACFormData}
                    />
                    {/* )} */}
                  </div>
                </div>
              </div>

              {/* step 4 card */}
              <div className="flex flex-row">
                <div className="w-8 mr-2 md:flex md:flex-col md:justify-center md:mr-4 lg:mr-8">
                  <div className="flex justify-center items-center mt-3 mb-0">
                    <div
                      className={`inline-flex justify-center items-center h-8 w-8 text-white rounded-full overflow-hidden
                        ${
                          formStep > 3
                            ? "bg-ui-blue-dark"
                            : "bg-ui-gray-medium-dark"
                        }
                        `}
                    >
                      4
                    </div>
                  </div>
                  <div className="flex h-full w-px bg-ui-gray-medium-light mx-auto overflow-hidden"></div>
                </div>
                <div className="w-full flex-grow">
                  <div className="ui-card relative px-4 py-4 mb-4 xl:mb-6 xl:px-6">
                    <div className="flex justify-between items-start">
                      <h2 className="flex flex-grow font-body font-bold text-xl mb-0">
                        Funeral Home Information
                      </h2>
                      <div className="font-semibold text-sm whitespace-nowrap">
                        Step 4
                      </div>
                    </div>

                    {/* {formStep === 4 && ( */}
                    <FACStepFour
                      formStep={formStep}
                      setFormStep={setFormStep}
                      setFACFormData={setFACFormData}
                    />
                    {/* )} */}
                  </div>
                </div>
              </div>

              {/* step 5 card */}
              <div className="flex flex-row">
                <div className="w-8 mr-2 md:flex md:flex-col md:justify-center md:mr-4 lg:mr-8">
                  <div className="flex justify-center items-center mt-3 mb-0">
                    <div
                      className={`inline-flex justify-center items-center h-8 w-8 text-white rounded-full overflow-hidden
                        ${
                          formStep > 4
                            ? "bg-ui-blue-dark"
                            : "bg-ui-gray-medium-dark"
                        }
                        `}
                    >
                      5
                    </div>
                  </div>
                  <div className="flex h-full w-px bg-transparent overflow-hidden"></div>
                </div>
                <div className="w-full flex-grow">
                  <div className="ui-card relative px-4 py-4 mb-4 xl:mb-6 xl:px-6">
                    <div className="flex justify-between items-start">
                      <h2 className="flex flex-grow font-body font-bold text-xl mb-0">
                        Note (Optional)
                      </h2>
                      <div className="font-semibold text-sm whitespace-nowrap">
                        Step 5
                      </div>
                    </div>

                    {/* {formStep === 5 && ( */}
                    <FACStepFive
                      formStep={formStep}
                      setFormStep={setFormStep}
                      setFACFormData={setFACFormData}
                    />
                    {/* )} */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="flex flex-col">
            <div className="grid grid-cols-1 gap-4 lg:gap-6">
              <div className="ui-card text-sm p-4 xl:p-6">
                <h2 className="font-body text-sm font-semibold">
                  What to expect next
                </h2>
                <hr className="my-4" />

                <p className="font-semibold">
                  Next you will receive your claim packet
                </p>
                <br />
                <p>
                  Once your claim is filed, you will receive a packet via your
                  selected method (email or mail) that includes a claimant
                  statement form you must fill out. The packet will also include
                  detailed instructions on any other forms or documents that are
                  needed to process your claim on what to do next.
                </p>
                <br />
                <p className="font-semibold">
                  Complete and submit claims packet forms and send us requested
                  documents
                </p>
                <p>
                  Complete the required claimant statement form and upload or if
                  required mail it back to us with a certified copy of the death
                  certificate. Your claim representative will let you know if
                  you're required to provide additional documents.
                </p>
                <br />
                <p className="font-semibold">Track your claim</p>
                <p>
                  Your can track your claim online here or call the life claim
                  department at 1-800-555-5555.
                </p>
                <br />
                <p className="font-semibold">Receive Payment</p>
                <p>
                  Most claims are processed within X-X business days after
                  receiving your completed claim form and related documents.
                  Beneficiaries usually receive payment shortly thereafter. Some
                  claims may take longer. Once your claim is approved, you can
                  decide how you'd like to receive the life insurance benefit.
                  We can mail you a check or issue a direct deposit.
                </p>
              </div>
              <div className="ui-card p-4 xl:p-6">
                <h2 className="font-body text-sm font-semibold">
                  Other ways to file
                </h2>
                <hr className="my-4" />
                <p className="font-semibold">By phone</p>
                <p>
                  Call the life claim department at 1-800-366-3495 to start your
                  claim over the phone.
                </p>
                <br />
                <p className="font-semibold">By mail</p>
                <p>
                  Download and fill out our{" "}
                  <a href="/">claim notification form</a> and mail it to:
                </p>
                <br />
                <p>
                  Life Claims Reports
                  <br />
                  555 Streetname Rd.
                  <br />
                  Townname, NY 55555-5555
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* step 6 dialog form for submit */}
      <Dialog
        open={isStepSixDialogOpen}
        onClose={() => {
          setIsStepSixDialogOpen(true);
          setFormStep(5);
        }}
        className="ui-dialogs"
      >
        <DialogBackdrop />
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel
            role="dialog"
            aria-modal="true"
            className="ui-default-dialog"
          >
            <Dialog.Title className="font-body text-xl font-bold text-left">
              Choose how you will recieve your claims packet
            </Dialog.Title>
            <button
              className="ui-default-dialog-close"
              aria-label="close receive claims packet selection window"
              onClick={() => {
                setIsStepSixDialogOpen(false);
                setFormStep(5);
              }}
            >
              <svg
                className="ui-icon h-3.5 w-3.5"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href="#ui-icon-close"></use>
              </svg>
            </button>
            <hr />
            <form onSubmit={FACStepSixHandleSubmit(onSubmit)}>
              <div>
                <label className="flex items-center font-normal">
                  <input
                    id={`${uuid}-method-digital`}
                    type="radio"
                    className="mr-1"
                    value="digital"
                    {...FACStepSixRegister(`method`, {
                      required:
                        "Please select a format to recieve your claims packet.",
                    })}
                  />
                  Digitally - faster We email you next steps for processing your
                  claims.
                </label>
                <label className="mt-2 flex items-center font-normal">
                  <input
                    id={`${uuid}-method-mail`}
                    type="radio"
                    className="mr-1"
                    value="mail"
                    {...FACStepSixRegister(`method`, {
                      required:
                        "Please select a format to recieve your claims packet.",
                    })}
                  />
                  By mail.
                </label>
              </div>

              <FormInputError
                id={`${uuid}-method-error`}
                errorMessage={errors?.method?.message}
              />

              {/* <!-- Buttons --> */}
              <div className="block text-right mt-6">
                <button
                  className="ui-btn-secondary ui-btn-min-w mt-4"
                  type="button"
                  onClick={() => {
                    setIsStepSixDialogOpen(false);
                    setFormStep(5);
                  }}
                >
                  Go back to file a claim form.
                </button>
                <button
                  id="ID_applyFilterOptions"
                  className="ui-btn-primary ui-btn-min-w mt-4"
                >
                  Submit
                </button>
              </div>
            </form>
          </Dialog.Panel>
        </div>
      </Dialog>

      {/* confirmation modal */}
      <Transition appear show={isConfirmationDialogOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => setIsConfirmationDialogOpen(false)}
        >
          <DialogBackdrop />
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-5xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h2"
                  className="dialog_label2 font-body text-xl font-bold text-left"
                >
                  Claim recieved
                  <button
                    className="ui-default-dialog-close"
                    onClick={() => setIsConfirmationDialogOpen(false)}
                  >
                    <svg
                      className="ui-icon h-3.5 w-3.5"
                      focusable="false"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <use href="#ui-icon-close"></use>
                    </svg>
                  </button>
                </Dialog.Title>
                <hr />
                <div className="mx-auto lg:w-4/5">
                  <p id="ID_dialogPaymentThankDesc">
                    <strong>
                      It will take X-X business days to process this request.
                      You will see the payment reflected as immediately as
                      “processing” in your policies. Here is your service
                      request ID # for tracking the payment:
                    </strong>
                  </p>
                  <div className="w-full text-2xl font-semibold text-theme-action my-6 text-center xl:text-3xl xl:my-8">
                    5555555555
                  </div>
                  <p>
                    If you have opted in to email or text notifications you can
                    find this tracking number there. You can also find this
                    service request any time on the Service &amp; Claims landing
                    page on the customer portal.
                  </p>
                </div>
                <div className="block text-right mt-6">
                  <button
                    id="ID_OptInNotifications"
                    className="ui-btn-secondary min-w-[140px] mt-4"
                    aria-label="Go back to service &amp; claims main menu"
                    onClick={() => {
                      setIsConfirmationDialogOpen(false);
                    }}
                  >
                    <Link to="/services-claims">
                      Go back to service &amp; claims main menu
                    </Link>
                  </button>
                  <button
                    className="ui-btn-primary min-w-[140px] mt-4"
                    aria-label="Track claim now"
                    onClick={() => {
                      setIsConfirmationDialogOpen(false);
                    }}
                  >
                    <Link to="/services-claims/claims/1">Track claim now</Link>
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
