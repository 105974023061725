import { usePagination } from "../../hooks/usePagination";
import { policyLookUpData } from "../../js/placeholderData/agents/policyLookUpData";
import { useState } from "react";

import SimpleDataRow from "../../components/General/SimpleDataRow";
import Pagination from "../../components/General/Pagination";
import PolicyLookup from "../../components/AgentPortal/General/PolicyLookup";
import FilterDialog from "../../components/AgentPortal/BookOfBusiness/FilterDialog";
import AgentSendReminderModal from "../../components/AgentPortal/ModalDialogs/AgentSendReminderModal";

export default function PolicyLookUp() {
  const MAXIMUM_PER_PAGE = 5;
  const { current, paginationProps } = usePagination(
    policyLookUpData,
    MAXIMUM_PER_PAGE
  );
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [isOpenSendReminder, setIsOpenSendReminder] = useState(false);

  return (
    <>
      <div className="container mb-8">
        <h1>Policy Look Up</h1>
        <p>Lorem ipsum dolor sit amen sit dolor.</p>
        <hr />
      </div>

      <div className="container ui-component-spacing">
        <div className="text-sm max-w-[480px]">
          <PolicyLookup />
        </div>
      </div>

      <div className="container ui-component-spacing">
        <h2>Results ({policyLookUpData.length})</h2>
        <p className="text-sm">
          Lorem ipsum dolor sit amen sit dolor imps est.
        </p>
        <hr />

        <div className="mx-2 flex items-centers justify-end md:mx-4">
          <button
            className="ui-btn-primary flex items-center"
            onClick={() => setFilterDialogOpen(true)}
          >
            <svg
              className="ui-icon w-3.5 h-3.5 mr-2"
              focusable="false"
              aria-hidden="true"
              role="presentation"
            >
              <use href="#ui-icon-filter"></use>
            </svg>
            Filter options
          </button>
        </div>

        {current.map((policy, idx) => {
          return (
            <SimpleDataRow
              key={`policy-look-up-result-${idx}`}
              data={policy}
              type="policyResult"
              isOpenSendReminder={isOpenSendReminder}
              setIsOpenSendReminder={setIsOpenSendReminder}
            />
          );
        })}

        {/* <!-- paging component --> */}
        <div className="flex justify-between items-start mt-6">
          <div></div>
          <Pagination {...paginationProps} />
        </div>
      </div>

      <FilterDialog
        dialogState={filterDialogOpen}
        setDialogState={setFilterDialogOpen}
      />
      {/* Send customer a reminder Dialog */}
      <AgentSendReminderModal
        isOpenSendReminder={isOpenSendReminder}
        setIsOpenSendReminder={setIsOpenSendReminder}
      />
    </>
  );
}
