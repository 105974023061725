import { Link } from "react-router-dom";
export default function SignupCard(props) {
  return (
    <div className="ui-card relative text-center p-4 xl:p-6">
      <h2>Sign Up</h2>
      <p className="text-sm mb-6">
        Take advantage of the many services available in our customer portal.
      </p>
      <div className="text-left mb-6">
        <ul className="text-xs">
          <li className="flex items-center mb-3 font-semibold">
            <svg
              className="ui-icon w-4 h-4 text-theme-action mr-2"
              focusable="false"
              aria-hidden="true"
              role="presentation"
            >
              <use href="#ui-icon-payment"></use>
            </svg>
            Manage billing and payments
          </li>
          <li className="flex items-center mb-3 font-semibold">
            <svg
              className="ui-icon w-4 h-4 text-theme-action mr-2"
              focusable="false"
              aria-hidden="true"
              role="presentation"
            >
              <use href="#ui-icon-document-multiple"></use>
            </svg>
            View Coverages and Policy Documents
          </li>
          <li className="flex items-center mb-3 font-semibold">
            <svg
              className="ui-icon w-4 h-4 text-theme-action mr-2"
              focusable="false"
              aria-hidden="true"
              role="presentation"
            >
              <use href="#ui-icon-edit"></use>
            </svg>
            View Service Requests
          </li>
          <li className="flex items-center mb-3 font-semibold">
            <svg
              className="ui-icon w-4 h-4 text-theme-action mr-2"
              focusable="false"
              aria-hidden="true"
              role="presentation"
            >
              <use href="#ui-icon-alert"></use>
            </svg>
            View Real-Time Policy Alerts
          </li>
        </ul>
      </div>
      <Link
        to="/register"
        className="ui-btn-primary min-w-[144px] mb-10 mx-auto"
      >
        Sign Up
      </Link>
    </div>
  );
}
