import { Link } from "react-router-dom";
export default function ServicesAndClaimsCardHotLink({ data }) {
  return (
    <li className="py-3 text-left border-b border-theme-rule-light last:border-b-0">
      <Link to={data.url} className="flex items-start justify-start">
        <svg
          className="ui-icon w-4 h-4 mt-1 mr-2"
          focusable="false"
          aria-hidden="true"
          role="presentation"
        >
          <use href={data.icon}></use>
        </svg>
        <span>{data.title}</span>
      </Link>
    </li>
  );
}
