import Billboard from "../components/General/Billboard";
import GuestServicesCard from "../components/GuestServicesCard";
import LoginCard from "../components/LoginCard";
import SignupCard from "../components/SignupCard";
import { Link } from "react-router-dom";
import { useEffect } from "react";

export default function Landing({ setAuthenicated }) {
  useEffect(() => {
    setAuthenicated(false);
  }, []);

  return (
    <>
      <div className="container mb-8">
        <h1>Welcome to the Everlake Customer Hub</h1>
        <hr />
      </div>

      <div className="container ui-component-spacing">
        <Billboard
          data={{
            title:
              "Life insurance is an essential part of any financial strategy. ",
            svgClass: "text-ui-green",
            svgUse: "ui-icon-border-good",
            description:
              "It provides a death benefit to help protect the most important people in your life. It can replace income for dependents, create inheritances, provide charitable contributions, and much more.  We're here to help!",
          }}
        />
      </div>

      <div className="container ui-component-spacing">
        <div className="grid grid-cols-1 gap-4 md:grid-cols-3 xl:gap-6">
          <LoginCard setAuthenicated={setAuthenicated} />
          <SignupCard />
          <GuestServicesCard />
        </div>
      </div>

      <div className="container ui-component-spacing">
        <div className="ui-card relative overflow-hidden mb-10 lg:mb-12 sm:bg-contain sm:bg-right sm:bg-origin-border sm:bg-no-repeat sm:bg-[url(/public/assets/img/cta-Image-1.jpg)]">
          <img
            src="/assets/img/cta-Image-1.jpg"
            className="w-full h-auto sm:hidden"
            alt="lifestyle photo of family"
          />
          <div className="w-full sm:w-1/2 md:w-3/5 lg:w-1/2">
            <div
              className="p-4 md:p-8 lg:p-10 xl:p-20"
              style={{
                // background: "rgb(255,255,255)",
                background:
                  "linear - gradient(90deg, rgba(255, 255, 255, 1) 0 %, rgba(255, 255, 255, 1) 75 %, rgba(255, 255, 255, .85) 85 %, rgba(255, 255, 255, 0) 99 %)",
              }}
            >
              <div className="pr-14">
                <h2 className="text-2xl mb-2 md:text-3xl lg:text-5xl md:mb-6 lg:mb-8">
                  Trusted When It Matters Most
                </h2>
                <p className="mb-4 md:mb-6 lg:mb-8">
                  Through life's twists and turns, we are there. With heritage
                  back to 1957 through the acquisition of Allstate Life
                  Insurance Company, we've been taking care of our customers and
                  honoring our promises when they need it most.
                </p>
                <Link to="/" className="ui-btn-primary">
                  Call to Action
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
