import { Dialog } from "@headlessui/react";
import { v4 as uuidv4 } from "uuid";
import DialogBackdrop from "../../ModalDialogs/DialogBackdrop";


export default function UploadProofOfTrainingDialog({ dialogState, setDialogState }) {
  const docId = uuidv4();

  return (
    <>
      <Dialog
        open={dialogState}
        onClose={() => setDialogState(false)}
        className="ui-dialogs"
      >
        {/* The backdrop, rendered as a fixed sibling to the panel container */}
        <DialogBackdrop />
        {/* Full-screen container to center the panel */}
        <div className="fixed inset-0 flex items-center justify-center p-4">
          <Dialog.Panel
            role="dialog"
            aria-modal="true"
            className="ui-default-dialog"
          >
            <Dialog.Title className="font-body text-xl font-bold text-left">
              Upload proof of training
            </Dialog.Title>
            <button
              className="ui-default-dialog-close"
              aria-label="close the send to customer window"
              onClick={() => setDialogState(false)}
            >
              <svg
                className="ui-icon h-3.5 w-3.5"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href="#ui-icon-close"></use>
              </svg>
            </button>
            <p className="text-xs">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec
              tincidunt mi sit amet ipsum tempus interdum.
            </p>
            <hr />
            <Dialog.Description>
              <span className="sr-only">
                Use this window to upload proof of training
              </span>
            </Dialog.Description>
            <div className="mx-auto md:w-4/5">
              <form className="grid grid-cols-1 gap-4">
                <div>
                  <label
                    forHTML={`${docId}-doc`}
                    className="mb-1 block text-lg"
                  >
                    You can upload a form/document
                  </label>

                  <input
                    type="file"
                    id={`${docId}-doc`}
                    name="relatedDocument"
                    accept=".doc,.docx,.pdf"
                  />
                </div>


                <div>
                  <label forHTML={`${docId}-note`} className="mb-1 block">
                    Add a message
                  </label>
                  <textarea
                    type="text"
                    id={`${docId}-note`}
                    rows="4"
                    value="Type message here..."
                  />
                </div>
                <div className="block text-right">
                  <button
                    className="ui-btn-secondary ui-btn-min-w mt-4"
                    onClick={() => setDialogState(false)}
                  >
                    Cancel
                    <span className="sr-only">
                      {" "}
                      upload and close window
                    </span>
                  </button>
                  <button
                    className="ui-btn-primary ui-btn-min-w mt-4"
                    onClick={() => setDialogState(false)}
                  >
                    Upload
                    <span className="sr-only"> document</span>
                  </button>
                </div>
              </form>
            </div>
          </Dialog.Panel>
        </div>
      </Dialog>
    </>
  );
}


