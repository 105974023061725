import ResetPassword from "../components/ResetPassword/ResetPassword";

export default function ForgotPasswordContainer() {
  return (
    <>
      <div className="container mb-8">
        <h1>Forgot Password</h1>
        <p>
          Lorem ipsum dolor sit amet, eam ad autem fabulas. Expetenda dissentiet
        </p>
        <hr />
      </div>
      <ResetPassword />
    </>
  );
}
