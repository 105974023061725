import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import DialogBackdrop from "./DialogBackdrop";
import { useNavigate } from "react-router-dom";

const RegistrationConfirmModal = ({ modalState, setModalState }) => {
  const navigate = useNavigate();
  const navigatetoLogin = () => {
    setModalState(false);
    navigate("/");
  };

  return (
    <>
      <Transition appear show={modalState.confirmationModal} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 z-10 overflow-y-auto"
          onClose={() => navigatetoLogin()}
        >
          <DialogBackdrop />
          <div className="min-h-screen px-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div className="inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-2xl">
                <Dialog.Title
                  as="h2"
                  className="font-body text-xl font-bold text-left"
                >
                  Sign up was successful
                </Dialog.Title>
                <button
                  className="ui-default-dialog-close"
                  onClick={() => navigatetoLogin()}
                >
                  <svg
                    className="ui-icon h-3.5 w-3.5"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-close"></use>
                  </svg>
                </button>
                <hr />
                <Dialog.Description>
                  Thank you for signing up, you can now go to log in page to
                  access your account information.
                </Dialog.Description>

                <div className="block text-right mt-6">
                  <button
                    className="ui-btn-primary min-w-[140px]  mt-4"
                    aria-label="Go to login page"
                    onClick={() => navigatetoLogin()}
                  >
                    Go to login page
                  </button>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default RegistrationConfirmModal;
