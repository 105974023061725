import { useState, useRef } from "react";
import { Disclosure } from "@headlessui/react";
import { useForm } from "react-hook-form";
import PaymentPolicy from "../components/UnauthorizedUser/MakeAPayment/PaymentPolicy";
import PaymentAmount from "../components/UnauthorizedUser/MakeAPayment/PaymentAmount";
import PaymentMethod from "../components/UnauthorizedUser/MakeAPayment/PaymentMethod";
import PaymentDate from "../components/UnauthorizedUser/MakeAPayment/PaymentDate";
import PaymentPayor from "../components/UnauthorizedUser/MakeAPayment/PaymentPayor";
import AuthorizeModal from "../components/ModalDialogs/AuthorizeModal";
import ConfirmModal from "../components/ModalDialogs/ConfirmModal";

export default function UnauthPayment() {
  const {
    register,
    setValue,
    trigger,
    watch,
    getFieldState,
    getValues,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const [currentStep, setCurrentStep] = useState(1);
  const [authorizeModal, setAuthorizeModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const makeAPaymentUnauthForm = useRef();

  const advanceModal = () => {
    setAuthorizeModal(true);
    setConfirmationModal(false);
  };
  const makeAPayment = (data) => {
    alert("Submitting data -- " + JSON.stringify(data));
  };

  return (
    <>
      {/*
        DEVELOPMENT NOTE:

        The interacting with the input elements controls the display of the next panel
        So the input element in the components needs the aria attributes' "aria-controls" and "aria-expands"
        No sure how to get and pass the dynamic IDs assigned by the Headless UI Disclosure between the page and the components
        We might need to change the interaction to have next buttons like the registration page

      */}
      <div className="container mb-8">
        <h1>Make a Payment</h1>
        <p>Lorem ipsum</p>
        <hr />
      </div>
      <form
        className="container mb-8"
        onSubmit={handleSubmit(makeAPayment)}
        ref={makeAPaymentUnauthForm}
      >
        <div className="ui-form-make-payment container">
          {/* <!-- Step 1 --> */}
          <div className="flex flex-row md:ml-8 md:mr-0 lg:ml-20 xl:ml-28 lg:pr-16">
            <div className="w-8 md:flex md:flex-col md:justify-center md:mr-14">
              <div className="flex justify-center items-center mt-6 mb-4">
                <div
                  className={`inline-flex justify-center items-center h-8 w-8 text-white ${
                    currentStep >= 1
                      ? "bg-ui-blue-dark"
                      : "bg-ui-gray-medium-dark"
                  } rounded-full overflow-hidden`}
                >
                  1
                </div>
              </div>
              <div className="flex h-full w-px bg-ui-gray-medium-light mx-auto overflow-hidden"></div>
            </div>
            <div className="w-full flex-grow">
              <div className="ui-card relative px-4 py-4 mb-6 lg:px-6">
                <Disclosure as="div">
                  {({ open }) => (
                    <>
                      <Disclosure.Button as="div">
                        <div className="flex justify-between items-start">
                          <h2 className="flex flex-grow font-body font-bold text-xl">
                            Enter policy to apply payment towards
                          </h2>
                          <div className="font-semibold text-sm whitespace-nowrap">
                            Step 1
                          </div>
                        </div>
                      </Disclosure.Button>

                      <div className="pt-4 mt-2 lg:pt-6 border-t border-theme-rule-light">
                        {currentStep >= 1 && (
                          <Disclosure.Panel
                            static
                            className="pb-2 text-sm"
                          >
                            <p className="mb-4 text-sm">
                              This is a description of the first step of
                              billing, where we tell the user they can pick the
                              pending policies or choose any other.
                            </p>

                            <PaymentPolicy
                              register={register}
                              validate={trigger}
                              getFieldState={getFieldState}
                              errors={errors}
                              setCurrentStep={setCurrentStep}
                            />
                          </Disclosure.Panel>
                        )}
                      </div>
                    </>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>

          {/* <!-- Step 2 --> */}
          <div className="flex flex-row md:ml-8 md:mr-0 lg:ml-20 xl:ml-28 lg:pr-16">
            <div className="w-8 md:flex md:flex-col md:justify-center md:mr-14">
              <div className="flex justify-center items-center mt-3 mb-4">
                <div
                  className={`inline-flex justify-center items-center h-8 w-8 text-white ${
                    currentStep >= 2
                      ? "bg-ui-blue-dark"
                      : "bg-ui-gray-medium-dark"
                  } rounded-full overflow-hidden`}
                >
                  2
                </div>
              </div>
              <div className="flex h-full w-px bg-ui-gray-medium-light mx-auto overflow-hidden"></div>
            </div>
            <div className="w-full flex-grow">
              <div className="ui-card relative px-4 py-4 mb-6 lg:px-6">
                <Disclosure as="div">
                  {({ open }) => (
                    <>
                      <Disclosure.Button as="div">
                        <div className="flex justify-between items-start">
                          <h2 className="flex flex-grow font-body font-bold text-xl">
                            Enter payment amount
                          </h2>
                          <div className="font-semibold text-sm whitespace-nowrap">
                            Step 2
                          </div>
                        </div>
                      </Disclosure.Button>
                      <div
                        id="ID_paymentStep2Options"
                        className="pt-4 mt-2 lg:pt-6 border-t border-theme-rule-light"
                      >
                        {currentStep >= 2 && (
                          <Disclosure.Panel
                            className="pb-2 text-sm"
                            static
                          >
                            <p className="mb-4 text-sm">
                              This is a description of the first step of
                              billing, where we tell the user they can pick the
                              pending policies or choose any other.
                            </p>

                            <PaymentAmount
                              register={register}
                              validate={trigger}
                              getValues={getValues}
                              getFieldState={getFieldState}
                              errors={errors}
                              setCurrentStep={setCurrentStep}
                            />
                          </Disclosure.Panel>
                        )}
                      </div>
                    </>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>

          {/* <!-- Step 3 --> */}
          <div
            id="ID_paymentStep3"
            className="flex flex-row md:ml-8 md:mr-0 lg:ml-20 xl:ml-28 lg:pr-16"
          >
            <div className="w-8 md:flex md:flex-col md:justify-center md:mr-14">
              <div className="flex justify-center items-center mt-3 mb-4">
                <div
                  id="ID_paymentStep3Number"
                  className={`inline-flex justify-center items-center h-8 w-8 text-white ${
                    currentStep >= 3
                      ? "bg-ui-blue-dark"
                      : "bg-ui-gray-medium-dark"
                  } rounded-full overflow-hidden`}
                >
                  3
                </div>
              </div>
              <div className="flex h-full w-px bg-ui-gray-medium-light mx-auto overflow-hidden"></div>
            </div>

            <div className="w-full flex-grow">
              <div className="ui-card relative px-4 py-4 mb-6 lg:px-6">
                <Disclosure as="div">
                  {({ open }) => (
                    <>
                      <Disclosure.Button as="div">
                        <div className="flex justify-between items-start">
                          <h2 className="flex flex-grow font-body font-bold text-xl">
                            Enter payment method
                          </h2>
                          <div className="font-semibold text-sm whitespace-nowrap">
                            Step 3
                          </div>
                        </div>
                      </Disclosure.Button>
                      <div
                        id="ID_paymentStep3Options"
                        className="pt-4 mt-2 lg:pt-6 border-t border-theme-rule-light"
                      >
                        {currentStep >= 3 && (
                          <Disclosure.Panel
                            className="pb-2 text-sm"
                            static
                          >
                            <p className="mb-4 text-sm">
                              This is a description of the first step of
                              billing, where we tell the user they can pick the
                              pending policies or choose any other.
                            </p>

                            <PaymentMethod
                              register={register}
                              validate={trigger}
                              watch={watch}
                              control={control}
                              setValue={setValue}
                              getFieldState={getFieldState}
                              errors={errors}
                              setCurrentStep={setCurrentStep}
                            />
                          </Disclosure.Panel>
                        )}
                      </div>
                    </>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>

          {/* <!-- Step 4 --> */}
          <div className="flex flex-row md:ml-8 md:mr-0 lg:ml-20 xl:ml-28 lg:pr-16">
            <div className="w-8 md:flex md:flex-col md:justify-center md:mr-14">
              <div className="flex justify-center items-center mt-3 mb-4">
                <div
                  id="ID_paymentStep4Number"
                  className={`inline-flex justify-center items-center h-8 w-8 text-white ${
                    currentStep >= 4
                      ? "bg-ui-blue-dark"
                      : "bg-ui-gray-medium-dark"
                  } rounded-full overflow-hidden`}
                >
                  4
                </div>
              </div>
              <div className="flex h-full w-px bg-ui-gray-medium-light mx-auto overflow-hidden"></div>
            </div>
            <div className="w-full flex-grow">
              <div className="ui-card relative px-4 py-4 mb-6 lg:px-6">
                <Disclosure as="div">
                  {({ open }) => (
                    <>
                      <Disclosure.Button as="div">
                        <div className="flex justify-between items-start">
                          <h2 className="flex flex-grow font-body font-bold text-xl">
                            Schedule payment date
                          </h2>
                          <div className="font-semibold text-sm whitespace-nowrap">
                            Step 4
                          </div>
                        </div>
                      </Disclosure.Button>
                      <div
                        id="ID_paymentStep4Options"
                        className="pt-4 mt-2 lg:pt-6 border-t border-theme-rule-light"
                      >
                        {currentStep >= 4 && (
                          <Disclosure.Panel
                            className="pb-2 text-sm"
                            static
                          >
                            <p className="mb-4 text-sm">
                              This is a description of the first step of
                              billing, where we tell the user they can pick the
                              pending policies or choose any other.
                            </p>

                            <PaymentDate
                              register={register}
                              setValue={setValue}
                              errors={errors}
                              setCurrentStep={setCurrentStep}
                            />
                          </Disclosure.Panel>
                        )}
                      </div>
                    </>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>

          {/* <!-- Step 5 --> */}
          <div className="flex flex-row md:ml-8 md:mr-0 lg:ml-20 xl:ml-28 lg:pr-16">
            <div className="w-8 md:flex md:flex-col md:justify-center md:mr-14">
              <div className="flex justify-center items-center mt-3 mb-4">
                <div
                  id="ID_paymentStep5Number"
                  className={`inline-flex justify-center items-center h-8 w-8 text-white ${
                    currentStep >= 5
                      ? "bg-ui-blue-dark"
                      : "bg-ui-gray-medium-dark"
                  } rounded-full overflow-hidden`}
                >
                  5
                </div>
              </div>
              <div className="flex h-full w-px bg-transparent mx-auto overflow-hidden"></div>
            </div>

            <div className="w-full flex-grow">
              <div className="ui-card relative px-4 py-4 mb-6 lg:px-6">
                <Disclosure as="div">
                  {({ open }) => (
                    <>
                      <Disclosure.Button as="div">
                        <div className="flex justify-between items-start">
                          <h2 className="flex flex-grow font-body font-bold text-xl">
                            Payor detail
                          </h2>
                          <div className="font-semibold text-sm whitespace-nowrap">
                            Step 5
                          </div>
                        </div>
                      </Disclosure.Button>
                      <div
                        id="ID_paymentStep5Options"
                        className="pt-4 mt-2 lg:pt-6 border-t border-theme-rule-light"
                      >
                        {currentStep >= 5 && (
                          <Disclosure.Panel
                            className="pb-2 text-sm"
                            static
                          >
                            <p className="mb-4 text-sm">
                              This is a description of the first step of
                              billing, where we tell the user they can pick the
                              pending policies or choose any other.
                            </p>

                            <PaymentPayor
                              register={register}
                              validate={trigger}
                              getFieldState={getFieldState}
                              errors={errors}
                              setCurrentStep={setCurrentStep}
                            />
                          </Disclosure.Panel>
                        )}
                      </div>
                    </>
                  )}
                </Disclosure>
              </div>
            </div>
          </div>

          <div className="block text-right mb-8 md:ml-12 md:mr-6 lg:ml-28 lg:pr-16">
            <button
              type="button"
              className="ui-btn-secondary min-w-[140px]"
              aria-label="cancel make a payment"
              onClick={() => advanceModal()}
            >
              Cancel
            </button>
            <button
              id="ID_submitPayment"
              type="button"
              className="ui-btn-primary min-w-[140px]"
              aria-label="submit payment"
              disabled={currentStep !== 6}
              onClick={() => advanceModal()}
            >
              Submit
            </button>
          </div>
        </div>
      </form>

      <AuthorizeModal
        modalState={authorizeModal}
        setModalState={setAuthorizeModal}
        setConfirmationModal={setConfirmationModal}
        unauthUser={true}
        submitParentData={true}
        submitFuction={handleSubmit(makeAPayment)}
      />
      <ConfirmModal
        modalState={confirmationModal}
        setModalState={setConfirmationModal}
        unauthUser={true}
      />
    </>
  );
}
