export default function StatusDot({ status, inlineStyle = false }) {
  let dotClass;
  switch (status) {
    default:
    case "problem":
      dotClass = "ui-badge-dot-problem";
      break;
    case "risk":
      dotClass = "ui-badge-dot-risk";
      break;
    case "good":
      dotClass = "ui-badge-dot-good";
      break;
  }
  return inlineStyle ? (
    <span className={`ui-badge-dot ${dotClass}`}></span>
  ) : (
    <div className={`ui-badge-dot ${dotClass}`}></div>
  );
}
