import { RadioGroup } from "@headlessui/react";
import { cardData } from "../../../js/placeholderData/cardData";

const PanelThree = ({
  panelThreeSelection,
  setPanelThreeSelection,
  setModalState,
  register,
  setValue,
  setCurrentStep,
  handleSubmit,
  dataSubmit
}) => {

  const onSubmit = () => {
    setCurrentStep(4)
    handleSubmit(dataSubmit)()
  }

  return (
    <>
      <div className="px-0 md:px-16">
        <form>
          <RadioGroup
            className="ui-form-card-row cursor-pointer font-semibold"
            name="panelThreeSelection"
            value={panelThreeSelection}
            onChange={setPanelThreeSelection}
          >
            {cardData.map((item, idx) => (
              <RadioGroup.Option
                id="ID_paymentStep3Option1"
                key={idx}
                className={({ checked }) =>
                  `${checked ? "ui-form-card selected" : "ui-form-card"}  `
                }
                tabIndex="0"
                aria-label="Select this payment method"
                aria-controls="ID_paymentStep4Options"
                aria-expanded="false"
                {...register("selectedCard")}
                value={`selectedCard ${idx}`}
                onClick={
                  () => {
                    setValue("selectedCard", item.lastFour)
                    onSubmit()
                  }
                }
              >
                {({ checked }) => (
                  <>
                    <RadioGroup.Label className="block text-xxs mb-1 pr-8">
                      {item.name}
                    </RadioGroup.Label>
                    <RadioGroup.Label className="block text-black">
                      Ending in {item.lastFour}
                    </RadioGroup.Label>
                    {item.isDefault && (
                      <RadioGroup.Label className="text-xxs">
                        (Default)
                      </RadioGroup.Label>
                    )}

                    <div className="ui-form-card-checkbox">
                      <svg
                        className="ui-icon w-2 h-2 text-white hidden"
                        focusable="false"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <use href="#ui-icon-checkmark"></use>
                      </svg>
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            ))}



            <button
              id="ID_paymentStep3Option4"
              className="ui-form-card "
              tabIndex="0"
              aria-label="Add payment a method"
              onClick={() => setModalState(true)}
            >
              <div className="ui-form-card-icon">
                <svg
                  className="ui-icon h-3 w-3"
                  focusable="false"
                  aria-hidden="true"
                  role="presentation"
                >
                  <use href="#ui-icon-plus"></use>
                </svg>
              </div>
              <span className="ui-link md:w-12">Add payment method</span>
            </button>

            <div className="p-2">
              <label className="ui-checkbox">
                <input type="checkbox" />
                <div className="ui-checkmark">
                  <svg
                    className="ui-icon w-2 h-2 text-white"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-checkmark"></use>
                  </svg>
                </div>
                <span className="">
                  Make Visa ending in 5555 my default payment method
                </span>
              </label>

              <label className="ui-checkbox">
                <input type="checkbox" />
                <div className="ui-checkmark focus-visible">
                  <svg
                    className="ui-icon w-2 h-2 text-white"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-checkmark"></use>
                  </svg>
                </div>
                <span>Enroll in autopay with this payment method</span>
              </label>
            </div>
          </RadioGroup>
        </form>

      </div>
    </>
  );
};

export default PanelThree;
