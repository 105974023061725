import { Link } from "react-router-dom";
import { useState } from "react";
import { useForm } from "react-hook-form";

const ResetPassword = () => {
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const [panel, setPanel] = useState({
    panels: [
      { active: true },
      { active: false },
      { active: false },
      { active: false },
    ],
  });
  const onSubmit = (position) => {
    const updateState = panel.panels.map((item, idx) => {
      if (idx === position) return { active: true };
      if (idx !== position) return { active: false };
    });
    setPanel({ panels: updateState });
  };

  return (
    <>
      {panel.panels[0].active && (
        <form onSubmit={handleSubmit(() => onSubmit(1))}>
          {/* Forgot password - Let's find your account - SPRINT-1-CUSTOMER-my-policies-landing-and-unauthenticated-landing.pdf Pg 24 */}
          <div className="container ui-component-spacing">
            <div className="ui-card p-4 mx-auto sm:w-2/3 md:w-3/5 lg:w-1/2 md:p-6">
              <h2 className="font-body font-bold text-xl">
                Let's find your account
              </h2>
              <p className="text-sm">Please enter your User ID</p>
              <div className="mt-4 md:mt-6">
                <label
                  id="ID_forgotPasswordUserNameLabel"
                  htmlFor="ID_forgotPasswordUserName"
                >
                  User ID
                </label>
                <input
                  id="ID_forgotPasswordUserName"
                  aria-labelledby="ID_forgotPasswordUserNameLabel"
                  aria-describedby="ID_forgotPasswordUserNameError"
                  type="text"
                  {...register("userID", {
                    required: {
                      value: true,
                      message: "please enter your user ID",
                    },
                  })}
                />
                {errors.userID && (
                  <div
                    id="ID_forgotPasswordUserNameError"
                    className="ui-input-error"
                  >
                    {errors.userID.message}
                  </div>
                )}
              </div>
              <div className="text-center mt-4 md:mt-6">
                <button className="ui-btn-primary ui-btn-min-w">
                  Continue
                </button>
                <hr />
                <Link to="/forgot-username" className="text-xs">
                  Forgot username
                </Link>
              </div>
            </div>
          </div>
        </form>
      )}

      {panel.panels[1].active && (
        <form onSubmit={handleSubmit(() => onSubmit(2))}>
          {/* Forgot password - We found your account - SPRINT-1-CUSTOMER-my-policies-landing-and-unauthenticated-landing.pdf Pg 25 & 29*/}
          <div className="container ui-component-spacing">
            <div className="ui-card p-4 mx-auto sm:w-2/3 md:w-3/5 lg:w-1/2 md:p-6">
              <h2 className="font-body font-bold text-xl">
                We found your account
              </h2>
              <p className="text-sm">
                Please check your email for a one-time code to reset your
                password. This code is valid for # minutes.
              </p>
              <div className="mt-4 md:mt-6">
                <label
                  id="ID_passwordResetCodeLabel"
                  htmlFor="ID_passwordResetCode"
                >
                  Password reset code
                </label>
                <input
                  id="ID_passwordResetCode"
                  aria-labelledby="ID_passwordResetCodeLabel"
                  aria-describedby="ID_passwordResetCodeError"
                  type="text"
                  {...register("resetCode", {
                    required: {
                      value: true,
                      message: "please enter your code",
                    },
                  })}
                />
                {errors.resetCode && (
                  <div
                    id="ID_passwordResetCodeError"
                    className="ui-input-error"
                  >
                    {errors.resetCode.message}
                  </div>
                )}
              </div>
              <div className="text-center mt-4 md:mt-6">
                <button className="ui-btn-primary ui-btn-min-w">
                  Continue
                </button>
              </div>
            </div>
          </div>
        </form>
      )}

      {panel.panels[2].active && (
        <form onSubmit={handleSubmit(() => onSubmit(3))}>
          {/* Forgot password - Reset your password - SPRINT-1-CUSTOMER-my-policies-landing-and-unauthenticated-landing.pdf Pg 26 */}
          <div className="container ui-component-spacing">
            <div className="ui-card p-4 mx-auto sm:w-2/3 md:w-3/5 lg:w-1/2 md:p-6">
              <h2 className="font-body font-bold text-xl">
                Reset your password
              </h2>
              <p className="text-sm">
                In order to protect your account, make sure your password:
              </p>
              <ul className="text-xs list-disc mt-4 ml-4">
                <li>Is a minimum of 8 characters long</li>
                <li>
                  Does not match or significantly contain your username, eg. do
                  not use “username123”
                </li>
                <li>Any other rules get listed out here</li>
              </ul>

              <div className="mt-4 md:mt-6">
                <label id="ID_passwordResetLabel" htmlFor="ID_passwordReset">
                  New password
                </label>
                <input
                  id="ID_passwordReset"
                  aria-labelledby="ID_passwordResetLabel"
                  aria-describedby="ID_passwordResetError"
                  type="password"
                  {...register("password", {
                    required: "password is required",
                    pattern: {
                      value:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
                      message: "please enter a valid password",
                    },
                  })}
                />
                {errors.password && (
                  <div id="ID_passwordResetError" className="ui-input-error">
                    {errors.password.message}
                  </div>
                )}
              </div>
              <div className="mt-4 md:mt-6">
                <label
                  id="ID_passwordResetConfirmLabel"
                  htmlFor="ID_passwordResetConfirm"
                >
                  Confirm new password
                </label>
                <input
                  id="ID_passwordResetConfirm"
                  aria-labelledby="ID_passwordResetConfirmLabel"
                  aria-describedby="ID_passwordResetConfirmError"
                  type="password"
                  {...register("confirmPassword", {
                    validate: (value) => {
                      if (watch("password") !== value) {
                        return "The passwords do not match";
                      }
                    },
                  })}
                />
                {errors.confirmPassword && (
                  <div
                    id="ID_passwordResetConfirmError"
                    className="ui-input-error"
                  >
                    {errors.confirmPassword.message}
                  </div>
                )}
              </div>
              <div className="text-center mt-4 md:mt-6">
                <button className="ui-btn-primary ui-btn-min-w">Submit</button>
              </div>
            </div>
          </div>
        </form>
      )}

      {panel.panels[3].active && (
        <>
          {/* Forgot password - Your password has been reset - SPRINT-1-CUSTOMER-my-policies-landing-and-unauthenticated-landing.pdf Pg 27 */}
          <div className="container ui-component-spacing">
            <div className="ui-card p-4 mx-auto sm:w-2/3 md:w-3/5 lg:w-1/2 md:p-6">
              <h2 className="font-body font-bold text-xl">
                Your password has been reset
              </h2>
              <p className="text-sm">
                Messaging about keeping passwords safe and the company's
                commitment to security and privacy can go here. Below is the
                service request number for this change.
              </p>
              <div className="text-xl text-center font-semibold mt-4 md:mt-6">
                5555555555
              </div>
              <div className="text-center mt-4 md:mt-6">
                <Link to="/" className="ui-btn-primary ui-btn-min-w">
                  Return to login
                </Link>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ResetPassword;
