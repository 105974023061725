import { Link } from "react-router-dom";
export default function CallToActionCardWithIconOnTop({
  action = "link",
  actionMethod,
  icon,
  title,
  desc,
  ctaUrl,
  ctaText,
  ctaSrText,
  subAction = false,
  phoneNumber,
}) {
  return (
    <div className="ui-card p-4 xl:p-6">
      <div className="grid grid-cols-1 grid-rows-[min-content,min-content] gap-2 h-full text-center content-between">
        <div className="grid grid-cols-1 gap-4">
          <div className="place-self-center">
            <svg
              className="ui-icon w-6 h-6 text-ui-blue"
              focusable="false"
              aria-hidden="true"
              role="presentation"
            >
              <use href={icon}></use>
            </svg>
          </div>
          <div className="font-header text-theme-text-quinary text-2xl">
            {title}
          </div>
          <div className="text-sm">{desc}</div>
        </div>
        <div className="grid grid-row-2 gap-4">
          <div>
            {action === "link" && (
              <Link to={ctaUrl} className="mt-2 ui-btn-primary ui-btn-min-w">
                {ctaText}
                {ctaSrText && <span className="sr-only"> {ctaSrText}</span>}
              </Link>
            )}
            {action === "modal" && (
              <button
                className="mt-2 ui-btn-primary ui-btn-min-w"
                onClick={actionMethod}
              >
                {ctaText}
              </button>
            )}
          </div>
          <div className="text-sm">
            &nbsp;
            {subAction === "phone" && (
              <span>
                Or call{" "}
                <a className="whitespace-nowrap" href={`tel:+${phoneNumber}`}>
                  {phoneNumber}
                </a>
              </span>
            )}
            &nbsp;
          </div>
        </div>
      </div>
    </div>
  );
}
