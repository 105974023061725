import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import FormInputError from "../General/FormInputError";

export default function SearchForm({
  focus = false,
  display,
  siteSearchIcon,
  setShowDesktopSearch,
}) {
  const navigate = useNavigate();
  const searchInputRef = useRef(null);

  useEffect(() => {
    if (focus) {
      setTimeout(() => {
        searchInputRef.current.focus();
      }, 10);
    }
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { ref, ...rest } = register("question", {
    required: "Please tell me what are you searching for.",
  });

  const submitSearch = (data) => {
    navigate(`/help/search-results?type=${data.type}&q=${data.question}`);
  };

  return (
    <form
      className="flex flex-col sm:flex-row"
      onKeyDown={(e) => {
        if (e.key === "Escape" && display === "desktop") {
          setShowDesktopSearch(false);
          setTimeout(() => {
            siteSearchIcon.current.focus();
          }, 5);
        }
      }}
      onSubmit={handleSubmit(submitSearch)}
    >
      <div className="flex flex-col flex-grow justify-start sm:mr-4">
        <div className="relative">
          <input
            // id="id_searchFormInput"
            // name="question"
            {...rest}
            ref={(e) => {
              ref(e);
              searchInputRef.current = e;
            }}
            className="border border-ui-gray-medium h-9 w-full p-2 pl-8 rounded"
            aria-label="Site Search"
            placeholder="Looking for something?"
          />
          <svg
            className="ui-icon w-4 h-4 absolute text-theme-action left-0 top-1/2 mt-[-.5rem] ml-2"
            focusable="false"
            aria-hidden="true"
            role="presentation"
          >
            <use href="#ui-icon-search"></use>
          </svg>
        </div>
        {errors.question?.message && (
          <FormInputError errorMessage={errors.question.message} />
        )}
        <fieldset className="text-left">
          <legend className="sr-only">Search what area of site</legend>
          <div className="inline-block mt-2 mr-6">
            <label className="inline-flex items-center text-sm font-normal">
              <input
                type="radio"
                name="policyOwnerType"
                value="help"
                className="bg-white mr-2"
                {...register("type", {
                  required: "Please select where would you like to search",
                })}
              />
              Help Section
            </label>
          </div>

          <div className="inline-block mt-2">
            <label className="inline-flex items-center text-sm font-normal">
              <input
                type="radio"
                name="policyOwnerType"
                value="whole"
                className="bg-white mr-2"
                {...register("type", {
                  required: "Please select where would you like to search",
                })}
              />
              Entire Site
            </label>
          </div>
          {errors.type?.message && (
            <FormInputError errorMessage={errors.type.message} />
          )}
        </fieldset>
      </div>
      <div>
        <button
          // id="id_searchFormBtn"
          className="ui-btn-primary h-9 w-32 mx-auto ui-btn-min-w mt-4 sm:mt-0"
          onKeyDown={(e) => {
            if (display === "desktop" && e.key === "Tab" && !e.shiftKey) {
              setShowDesktopSearch(false);
              setTimeout(() => {
                siteSearchIcon.current.focus();
              }, 5);
            }
          }}
        >
          Search
        </button>
      </div>
    </form>
  );
}
