import { useState, useEffect } from "react";
import moment from "moment";
import Badge from "../components/General/Badge";
import { Tab } from "@headlessui/react";

import ManageAutopayTab from "../components/Payments/AutoPay/ManageAutopayTab";
import AuthorizeModal from "../components/ModalDialogs/AuthorizeModal";
import ConfirmModal from "../components/ModalDialogs/ConfirmModal";
import AddPaymentMethodModal from "../components/ModalDialogs/AddPaymentMethodModal";
import DeleteAutopayPaymentModal from "../components/ModalDialogs/DeleteAutopayPaymentModal";
import DeleteNonAutopayPaymentModal from "../components/ModalDialogs/DeleteNonAutopayPaymentModal";

import {
  paymentData,
  policyArray,
} from "../js/placeholderData/managePaymentData";

export default function ManagePayment() {
  const [requiredDocDisplayState, setRequiredDocDisplayState] =
    useState("card");

  const [addPaymentModal, setAddPaymentModal] = useState(false);
  const [authorizeModal, setAuthorizeModal] = useState(false);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [deleteAutopayPaymentModal, setDeleteAutopayPaymentModal] =
    useState(false);
  const [deleteNonAutopayPaymentModal, setDeleteNonAutopayPaymentModal] =
    useState(false);

  const [config, setConfig] = useState("Update");

  const openPaymentModal = () => {
    setConfig("Add");
    setAddPaymentModal(true);
  };

  const dateChecker = (date) => {
    return moment().isAfter(date, "MM-DD-YY");
  };

  const onSubmit = () => {
    setAddPaymentModal(false);
    setConfirmationModal(true);
  };

  // When entering from the /payment path the page renders at the same scroll position of that page. This fixes that
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      <div className="container ui-component-spacing">
        <h1>Manage payment methods</h1>
        <p>
          Lorem ipsum dolor sit amet, eam ad autem fabulas. Expetenda dissentiet
        </p>
        <hr />
      </div>
      <div className="container ui-component-spacing">
        <h2>Payment Types</h2>
        <div className="flex items-start">
          <div className="flex flex-grow">
            <p className="text-sm">
              Lorem ipsum dolor sit amet, eam ad autem fabulas. Expetenda
              dissentiet
            </p>
          </div>
          <div className="ml-8 hidden md:inline-flex md:items-center">
            <div className="text-sm font-light mr-2">View</div>
            <button
              className={`rounded-l 
              ${
                requiredDocDisplayState === "card"
                  ? "ui-btn-layout-current"
                  : "ui-btn-layout"
              }`}
              aria-label="View payment methods as cards"
              onClick={() => setRequiredDocDisplayState("card")}
            >
              <svg
                className="ui-icon w-4 h-4"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href="#ui-icon-layout-cards"></use>
              </svg>
            </button>
            <button
              className={`rounded-r ${
                requiredDocDisplayState === "table"
                  ? "ui-btn-layout-current"
                  : "ui-btn-layout"
              }`}
              aria-label="View payment methods in table"
              onClick={() => setRequiredDocDisplayState("table")}
            >
              <svg
                className="ui-icon w-4 h-4"
                focusable="false"
                aria-hidden="true"
                role="presentation"
              >
                <use href="#ui-icon-layout-table"></use>
              </svg>
            </button>
          </div>
        </div>
        <hr />

        <div className="ui-paymethod-grid">
          <div
            className={
              requiredDocDisplayState === "card"
                ? "ui-grid-cards"
                : "ui-grid-table"
            }
          >
            {/* credit card */}
            {paymentData.map((item, idx) => (
              <div key={`payment ${idx}`} className="ui-card p-4 md:p-6">
                <div className="ui-card-grid-layout">
                  <div className="ui-paymethod-grid-title">
                    <div className="text-lg font-semibold">
                      {item.type} {item.isDefault ? "- Default" : ""}
                    </div>
                    <div className="text-sm font-semibold">
                      {item.accountNumber}
                    </div>
                  </div>
                  <div className="ui-paymethod-grid-badge justify-self-end text-right">
                    {dateChecker(item.expDate) === true && (
                      <Badge
                        mediaSwitch={false}
                        status={"problem"}
                        statusText={"Expired"}
                        srPrefix=""
                      />
                    )}
                  </div>
                  <div>
                    <div className="text-xs text-theme-text-tertiary">Cardholder name</div>
                    <div className="text-sm text-theme-text-secondary font-semibold">
                      {item.ownerName}
                    </div>
                  </div>
                  <div>
                    <div className="text-xs text-theme-text-tertiary">Expiration Date</div>
                    <div
                      className={`text-sm text-theme-text-secondary font-semibold ${
                        dateChecker(item.expDate) === true ? "text-ui-red" : ""
                      }`}
                    >
                      {item.expDate}
                    </div>
                  </div>
                  <div>
                    <div className="text-xs text-theme-text-tertiary">Card type</div>
                    <div className="text-sm text-theme-text-secondary font-semibold">{item.type}</div>
                  </div>
                  <div>
                    <div className="text-xs text-theme-text-tertiary">
                      Used for autopay{" "}
                    </div>
                    <div className="text-sm text-theme-text-secondary font-semibold">
                      {item.isAutopay}
                    </div>
                  </div>
                  <div className="ui-paymethod-grid-actions place-self-end text-right">
                    <button
                      className="ui-btn-secondary ui-btn-min-w"
                      onClick={() =>
                        item.isAutopay === "Yes"
                          ? setDeleteAutopayPaymentModal(true)
                          : setDeleteNonAutopayPaymentModal(true)
                      }
                    >
                      Delete
                      <span className="sr-only"> Credit Card - 1234</span>
                    </button>
                    <span className="ui-paymethod-grid-divider ml-4 mr-2">
                      /
                    </span>
                    <button
                      className="ui-btn-primary ui-btn-min-w ml-2"
                      onClick={() => setAddPaymentModal(true)}
                    >
                      Update<span className="sr-only"> Credit Card - 1234</span>
                    </button>
                  </div>
                </div>
              </div>
            ))}

            <div className="ui-card overflow-hidden">
              <button
                className="ui-btn-hyperlink text-center w-full h-full flex flex-1 flex-col justify-center rounded p-4 md:p-6"
                onClick={() => openPaymentModal()}
              >
                <svg
                  className="ui-icon w-4.5 h-4.5 block mx-auto mb-4"
                  focusable="false"
                  aria-hidden="true"
                  role="presentation"
                >
                  <use href="#ui-icon-plus"></use>
                </svg>
                <span className="mx-auto">Add another payment method</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="container ui-component-spacing">
        <h2>Manage autopay enrollment and frequency</h2>
        <hr />

        <Tab.Group>
          <div>
            <div className="ui-card flex-grow px-2 p-1 md:px-4">
              <Tab.List>
                <Tab
                  className="text-sm mx-3 pt-3 pb-2 mb-1 md:text-base md:mx-4 lg:text-lg"
                  // onClick={() => {
                  //   setSearchParams("?tab=scheduled");
                  // }}
                >
                  All policies
                </Tab>
                <Tab
                  className="text-sm mx-3 pt-3 pb-2 mb-1 md:text-base md:mx-4 lg:text-lg"
                  // onClick={() => {
                  //   setSearchParams("?tab=history");
                  // }}
                >
                  Enrolled
                </Tab>
                <Tab
                  className="text-sm mx-3 pt-3 pb-2 mb-1 md:text-base md:mx-4 lg:text-lg"
                  // onClick={() => {
                  //   setSearchParams("?tab=statement");
                  // }}
                >
                  Not Enrolled
                </Tab>
              </Tab.List>
            </div>

            <div className="flex lg:inline-flex items-center px-2 mt-4 mb-2 xl:mt-6">
              <label
                id="ID_filterLabel"
                htmlFor="ID_filter"
                className="whitespace-nowrap text-xs mr-2"
              >
                Filter by policy number
              </label>
              <select
                id="ID_filter"
                aria-labelledby="ID_filterLabel"
                className="w-auto lg:w-full"
              >
                <option>Show all</option>
                <option>-----------------------</option>
              </select>
            </div>
          </div>
          <Tab.Panels>
            <Tab.Panel>
              <ManageAutopayTab
                data={policyArray}
                filter={false}
                filterBy={null}
                modalState={addPaymentModal}
                setModalState={setAddPaymentModal}
              />
            </Tab.Panel>

            <Tab.Panel>
              <ManageAutopayTab
                data={policyArray}
                filter={true}
                filterBy={"Yes"}
                modalState={addPaymentModal}
                setModalState={setAddPaymentModal}
              />
            </Tab.Panel>
            <Tab.Panel>
              <ManageAutopayTab
                data={policyArray}
                filter={true}
                filterBy={"No"}
                modalState={addPaymentModal}
                setModalState={setAddPaymentModal}
              />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
      {/* Modal Dialogs */}

      <AuthorizeModal
        modalState={authorizeModal}
        setModalState={setAuthorizeModal}
      />

      <ConfirmModal
        modalState={confirmationModal}
        setModalState={setConfirmationModal}
      />

      <AddPaymentMethodModal
        modalState={addPaymentModal}
        setModalState={setAddPaymentModal}
        config={config}
        setConfig={setConfig}
        onSubmit={onSubmit}
      />

      <DeleteAutopayPaymentModal
        modalState={deleteAutopayPaymentModal}
        setModalState={setDeleteAutopayPaymentModal}
        setAddPaymentModal={setAddPaymentModal}
        onSubmit={onSubmit}
      />

      <DeleteNonAutopayPaymentModal
        modalState={deleteNonAutopayPaymentModal}
        setModalState={setDeleteNonAutopayPaymentModal}
        onSubmit={onSubmit}
      />
    </>
  );
}
