import { Tab } from "@headlessui/react";
import { Link } from "react-router-dom";
import ScheduledPaymentGrid from "./ScheduledPaymentGrid";
import PaymentsHistoryGrid from "./PaymentsHistoryGrid";
import StatementsGrid from "./StatementsGrid";
export default function PaymentDetailMultiGridTabs({
  scheduledPayments,
  paymentHistory,
  statements,
}) {
  return (
    <div className="ui-payment-policy-tables px-4 pt-2 pb-4 xl:px-6 xl:pb-6">
      <div className="ui-tabs-sm">
        <Tab.Group>
          <Tab.List className="rounded-t border border-theme-rule-light bg-ui-gray-light">
            <Tab>Scheduled payments</Tab>
            <Tab>Payment history</Tab>
            <Tab>Statements</Tab>
          </Tab.List>
          <Tab.Panels>
            {scheduledPayments?.length > 0 && (
              <Tab.Panel>
                <div className="border border-l border-r pt-2">
                  <ScheduledPaymentGrid data={scheduledPayments} />
                </div>
                <div className="px-4 py-3 text-xxs border border-t-0 border-theme-rule-light rounded-b sm:text-xs md:text-sm">
                  <Link to="/payment/billing?tab=scheduled">
                    View all scheduled payments
                  </Link>
                </div>
              </Tab.Panel>
            )}

            {paymentHistory?.length > 0 && (
              <Tab.Panel>
                <div className="border border-l-1 border-r-1 pt-2">
                  <PaymentsHistoryGrid data={paymentHistory} />
                </div>
                <div className="px-4 py-3 text-xxs border border-t-0 border-theme-rule-light rounded-b sm:text-xs md:text-sm">
                  <Link to="/payment/billing?tab=history">
                    View all payment history
                  </Link>
                </div>
              </Tab.Panel>
            )}

            {statements?.length > 0 && (
              <Tab.Panel>
                <div className="border border-b-0 pt-2">
                  <StatementsGrid data={statements} />
                </div>
                <div className="px-4 py-3 text-xxs border border-t-0 border-theme-rule-light rounded-b sm:text-xs md:text-sm">
                  <Link to="/payment/billing?tab=statement">
                    View all statements
                  </Link>
                </div>
              </Tab.Panel>
            )}
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
}
