export const agentList = [
  {
    agentName: "Kathleen Celeste",
    pronouns: "( she / her )",
    photoURL: "/assets/img/agent-kathleen-celeste.png",
    badgeStatus: "good",
    badgeText: "Active / Compliant",
    policiesManaged: "7341",
    earnings: "10,456.00",
    profileURL: "/",
    bookURL: "/",
    office: "Lincoln Park Agency",
    address: "555 Maple Shade Dr.",
    address2: "Suite 260",
    city: "Anytown",
    state: "NY",
    zip: "55555",
  },
  {
    agentName: "Name Lastname",
    pronouns: "",
    photoURL: "/assets/img/agent-name-lastname-1.png",
    policiesManaged: "7341",
    earnings: "10,456.00",
    profileURL: "/",
    bookURL: "/",
    office: "Lincoln Park Agency",
    address: "555 Maple Shade Dr.",
    address2: "Suite 260",
    city: "Anytown",
    state: "NY",
    zip: "55555",
  },
  {
    agentName: "Robert Lisk",
    pronouns: "( he / him / his )",
    photoURL: "/assets/img/agent-name-lastname-2.png",
    badgeStatus: "risk",
    badgeText: "Active / Non-Compliant",
    policiesManaged: "7341",
    earnings: "10,456.00",
    profileURL: "/",
    bookURL: "/",
    office: "Lincoln Park Agency",
    address: "555 Maple Shade Dr.",
    address2: "Suite 260",
    city: "Anytown",
    state: "NY",
    zip: "55555",
  },
  {
    agentName: "Name Lastname",
    pronouns: "",
    photoURL: "/assets/img/agent-name-lastname-3.png",
    policiesManaged: "7341",
    earnings: "10,456.00",
    profileURL: "/",
    bookURL: "/",
    office: "Lincoln Park Agency",
    address: "555 Maple Shade Dr.",
    address2: "Suite 260",
    city: "Anytown",
    state: "NY",
    zip: "55555",
  },
  {
    agentName: "Name Lastname",
    pronouns: "",
    photoURL: "",
    policiesManaged: "12",
    earnings: "5,456.00",
    profileURL: "/",
    bookURL: "/",
    office: "Lincoln Park Agency",
    address: "555 Maple Shade Dr.",
    address2: "Suite 260",
    city: "Anytown",
    state: "NY",
    zip: "55555",
  },
];
export const policySummaries = [
  {
    banner: "problem",
    badgeStatus: "problem",
    badgeSrText: "",
    badgeText: "",
    title: "Pending Lapse",
    type: "pendingLapse",
    number: "7341",
    description:
      "Not issued, still awaiting requirements in new business and/or final approval.",
    actionNeeded: true,
    actionURL: "/",
  },
  {
    banner: "",
    badgeStatus: "risk",
    badgeSrText: "",
    badgeText: "",
    title: "Incomplete",
    type: "incomplete",
    number: "756",
    description:
      "Not issued, still awaiting requirements in new business and/or final approval.",
    actionNeeded: true,
    actionURL: "/",
  },
  {
    banner: "",
    badgeStatus: "risk",
    badgeSrText: "",
    badgeText: "",
    title: "Conditionally Issued",
    type: "conditionallyIssued",
    number: "2",
    description:
      "Text to explain this category of policies goes here. Lorem ipsum dolor sit amet.",
    actionNeeded: true,
    actionURL: "/",
  },
  {
    banner: "",
    badgeStatus: "good",
    badgeSrText: "",
    badgeText: "",
    title: "In Force",
    type: "inForce",
    number: "1979",
    description:
      "Text to explain this category of policies goes here. Lorem ipsum dolor sit amet.",
    actionNeeded: true,
    actionURL: "/",
  },
  {
    banner: "",
    badgeStatus: "risk",
    badgeSrText: "",
    badgeText: "",
    title: "Issued Pending Acceptance",
    type: "issuedPendingAcceptance",
    number: "625",
    description:
      "Text to explain this category of policies goes here. Lorem ipsum dolor sit amet.",
    actionNeeded: true,
    actionURL: "/",
  },
  {
    banner: "",
    badgeStatus: "risk",
    badgeSrText: "",
    badgeText: "",
    title: "Policies with Open Claims",
    type: "policiesWithOpenClaims",
    number: "342",
    description:
      "Text to explain this category of policies goes here. Lorem ipsum dolor sit amet.",
    actionNeeded: true,
    actionURL: "/",
  },
  {
    banner: "",
    badgeStatus: "",
    badgeSrText: "",
    badgeText: "",
    title: "Withdrawn",
    type: "Withdrawn",
    number: "0",
    description:
      "Text to explain this category of policies goes here. Lorem ipsum dolor sit amet.",
    actionNeeded: false,
    actionURL: "",
  },
  {
    banner: "",
    badgeStatus: "",
    badgeSrText: "",
    badgeText: "",
    title: "Non Taken",
    type: "Non Taken",
    number: "0",
    description:
      "Text to explain this category of policies goes here. Lorem ipsum dolor sit amet.",
    actionNeeded: false,
    actionURL: "",
  },
];
export const policyPriority = [
  {
    banner: "problem",
    badgeStatus: "problem",
    badgeSrText: "",
    badgeText: "",
    title: "Pending Lapse",
    type: "Pending Lapse",
    number: "7341",
    description:
      "Not issued, still awaiting requirements in new business and/or final approval.",
    actionNeeded: true,
    actionURL: "/",
  },
  {
    banner: "",
    badgeStatus: "risk",
    badgeSrText: "",
    badgeText: "",
    title: "Incomplete",
    type: "incomplete",
    number: "756",
    description:
      "Not issued, still awaiting requirements in new business and/or final approval.",
    actionNeeded: true,
    actionURL: "/",
  },
  {
    banner: "",
    badgeStatus: "risk",
    badgeSrText: "",
    badgeText: "",
    title: "Conditionally Issued",
    type: "conditionallyIssued",
    number: "2",
    description:
      "Text to explain this category of policies goes here. Lorem ipsum dolor sit amet.",
    actionNeeded: true,
    actionURL: "/",
  },
  {
    banner: "",
    badgeStatus: "risk",
    badgeSrText: "",
    badgeText: "",
    title: "Issued Pending Acceptance",
    type: "issuedPendingAcceptance",
    number: "625",
    description:
      "Text to explain this category of policies goes here. Lorem ipsum dolor sit amet.",
    actionNeeded: true,
    actionURL: "/",
  },
];
export const policyPinned = [
  {
    banner: "problem",
    badgeStatus: "problem",
    badgeSrText: "",
    badgeText: "",
    title: "Pending Lapse",
    type: "Pending Lapse",
    number: "7341",
    description:
      "Not issued, still awaiting requirements in new business and/or final approval.",
    actionNeeded: true,
    actionURL: "/",
  },
  {
    banner: "",
    badgeStatus: "risk",
    badgeSrText: "",
    badgeText: "",
    title: "Conditionally Issued",
    type: "conditionallyIssued",
    number: "2",
    description:
      "Text to explain this category of policies goes here. Lorem ipsum dolor sit amet.",
    actionNeeded: true,
    actionURL: "/",
  },
  {
    banner: "",
    badgeStatus: "good",
    badgeSrText: "",
    badgeText: "",
    title: "In Force",
    type: "inForce",
    number: "1979",
    description:
      "Text to explain this category of policies goes here. Lorem ipsum dolor sit amet.",
    actionNeeded: true,
    actionURL: "/",
  },
];
