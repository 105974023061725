
const ManageAutopayTab = ({ data, filter, filterBy, modalState, setModalState }) => {

  const filterFunction = filter ? data.filter(item => item.isAutopay === filterBy) : data

  return (
    filterFunction.map((item, idx) =>
    (
      <div key={idx} className="ui-card-bar relative mt-4">
        <div className="px-3 py-2 lg:p-4">
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-none lg:grid-flow-col gap-4">
            <div>
              <div className="text-xs text-theme-text-tertiary mb-1">{item.policyNumber}</div>
              <div className="text-sm font-semibold text-theme-text-secondary">{item.type}</div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary mb-1">Premium</div>
              <div className="text-sm font-semibold text-theme-text-secondary">{item.premium}</div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary mb-1">Payment Method</div>
              <div className="text-sm font-semibold text-theme-text-secondary">{item.paymentMethod}</div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary mb-1">Enrolled in autopay</div>
              <div className="text-sm font-semibold text-theme-text-secondary">{item.isAutopay}</div>
            </div>
            <div>
              <div className="text-xs text-theme-text-tertiary mb-1">Payment frequency</div>
              <div className="text-sm font-semibold text-theme-text-secondary">{item.paymentFrequency}</div>
            </div>
            <div className="lg:place-self-end lg:text-right">
              <div className="text-xs text-theme-text-tertiary mb-1">Manage autopay</div>
              <button className="ui-btn-hyperlink text-sm"
                onClick={() => setModalState(true)}
              >
                Edit
                <span className="sr-only">`Autopay for Policy ${item.policyNumber}`</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    ))
  )
}

export default ManageAutopayTab