import { useEffect, useState, useRef } from "react";

export default function SalesForceTest() {
  return (
    <>
      <h1>Let's start chatting...</h1>
      <br />
      Click the button to start a chat.
      <br />
    </>
  );
}
