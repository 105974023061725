import { useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { NumberMask } from 'react-control-library';
import { Controller } from "react-hook-form"

import FormInputError from "../../General/FormInputError";

const PanelTwo = ({
  panelTwoSelection,
  setPanelTwoSelection,
  register,
  setValue,
  setCurrentStep,
  selected,
  errors,
  control,
  handleSubmit,
  dataSubmit,
  trigger,
}) => {
  // debounce function config
  let debounceFunction; //timer identifier
  let debounceTimer = 1500;

  const minPayment = selected.paymentAmount

  const [checkboxState, setCheckboxState] = useState({
    amountDue: false,
    annualAmount: false,
    otherAmount: false
  })

  const [amount, setAmount] = useState('')
  const [customAmount, setCustomAmount] = useState(0)
  const getNumberMask = () => {
    return {
      prefix: '$',
      suffix: '',
      thousandsSeparatorSymbol: ',',
      decimalSymbol: '.',
      decimalLimit: 2,
      maxLength: 20,
      negativeAllowed: false,
    }
  }

  const onSubmit = () => {
    // Number(a) < Number(minPayment) ? setCurrentStep(2) : setCurrentStep(3)
    handleSubmit(dataSubmit)()
    setCurrentStep(3)
  }

  return (
    <>
      {selected && (
        <div className="px-0 md:px-16">
          <form>
            <RadioGroup className="ui-form-card-row"
              name="panelOneSelection"
              value={panelTwoSelection}
              onChange={setPanelTwoSelection}>
              <RadioGroup.Option
                id="ID_paymentStep1Option1"
                className={({ checked }) => `${checked && checkboxState.monthly ? "ui-form-card selected" : "ui-form-card"}  `}
                {...register("amount")}
                tabIndex="0"
                aria-label="Select to pay this policy"
                aria-controls="ID_paymentStep2Options"
                aria-expanded="false"

                onClick={() => {
                  setCheckboxState({
                    monthly: true,
                    annualAmount: false,
                    otherAmount: false
                  })
                  setValue("amount", selected.paymentAmount)
                  onSubmit()
                }
                }
                value="monthly"
              >
                {({ checked }) => (
                  <>
                    <RadioGroup.Label
                      className="block text-xxs mb-1 pr-8 cursor-pointer font-semibold"
                      as="p"
                    >
                      Amount Due
                    </RadioGroup.Label>
                    <RadioGroup.Label className="block text-black"
                    >
                      ${selected.paymentAmount}
                    </RadioGroup.Label>
                    <RadioGroup.Label className="text-xxs">
                      (Monthly)
                    </RadioGroup.Label>
                    <div className="text-xxs font-semibold pr-8 group-hover:underline">
                      <div className="ui-form-card-checkbox">
                        <svg
                          className="ui-icon w-2 h-2 text-white hidden"
                          focusable="false"
                          aria-hidden="true"
                          role="presentation"
                        >
                          <use href="#ui-icon-checkmark"></use>
                        </svg>
                      </div>
                    </div>
                  </>
                )}
              </RadioGroup.Option>

              <RadioGroup.Option
                id="ID_paymentStep1Option1"
                className={({ checked }) => `${checked && checkboxState.annualAmount ? "ui-form-card selected" : "ui-form-card"}  `}
                {...register("amount")}
                tabIndex="0"
                aria-label="Select to pay this policy"
                aria-controls="ID_paymentStep2Options"
                aria-expanded="false"

                onClick={() => {
                  setCheckboxState({
                    monthly: true,
                    annualAmount: true,
                    otherAmount: false
                  })
                  setValue("amount", selected.annualAmount)
                  onSubmit()
                }
                }
                value="annual"
              >
                {({ checked }) => (
                  <>
                    <RadioGroup.Label
                      className="block text-xxs mb-1 pr-8 cursor-pointer font-semibold"
                      as="p"
                    >
                      Semi-annual premium
                    </RadioGroup.Label>
                    <RadioGroup.Label className="block text-black">
                      ${selected.annualAmount.toFixed(2)}
                    </RadioGroup.Label>
                    <RadioGroup.Label className="text-xxs">
                      (Save $X.XX)
                    </RadioGroup.Label>
                    <div className="text-xxs font-semibold pr-8 group-hover:underline">
                      <div className="ui-form-card-checkbox">
                        <svg
                          className="ui-icon w-2 h-2 text-white hidden"
                          focusable="false"
                          aria-hidden="true"
                          role="presentation"
                        >
                          <use href="#ui-icon-checkmark"></use>
                        </svg>
                      </div>
                    </div>
                  </>
                )}
              </RadioGroup.Option>
              <RadioGroup.Option
                id="ID_paymentStep1Option1"
                className={({ checked }) => `${checked && checkboxState.otherAmount ? "ui-form-card selected" : "ui-form-card"}  `}
                tabIndex="0"
                aria-label="Select to pay this policy"
                aria-controls="ID_paymentStep2Options"
                aria-expanded="false"
                onKeyUp={() => {
                  clearTimeout(debounceFunction);
                  debounceFunction = setTimeout(async () => {
                    await trigger("amount");
                    onSubmit()
                  }, debounceTimer);
                }}
                onKeyDown={() => {
                  clearTimeout(debounceFunction);
                }}
                onChange={() => setAmount()}
                value={amount}
              >
                {({ checked }) => (
                  <>
                    <RadioGroup.Label
                      className="block text-xxs mb-1 pr-8 cursor-pointer font-semibold"
                      as="p"
                    >
                      Other Amount
                    </RadioGroup.Label>
                    <Controller
                      name="amount"

                      control={control}


                      render={({ field: { ref, ...rest } }) => (
                        <NumberMask {...rest}
                          mask={getNumberMask()}
                          value={customAmount}
                          onClick={(e) => {
                            e.stopPropagation()
                            setCheckboxState({
                              monthly: true,
                              annualAmount: false,
                              otherAmount: true
                            })
                          }
                          }
                          onChange={(e) => {
                            setCustomAmount(e.target.value.substring(1).replace(/[^0-9\.-]+/g, ""))
                            setValue("amount", e.target.value)
                          }
                          }
                        />
                      )}
                      rules={
                        {
                          validate: (value) => {
                            if (!checkboxState.monthly && !checkboxState.annualAmount) {
                              if (Number(customAmount) < Number(minPayment)) {
                                return "please enter an amount greater than minimum due";
                              }
                            }
                          }
                        }
                      }
                    />
                    <div id={"-error"}>
                      {errors.otherAmount && (
                        <FormInputError errorMessage={errors.otherAmount.message} />
                      )}
                    </div>
                    <RadioGroup.Label as="div" className="text-xxs text-theme-text-primary mt-1">
                      Use $XX.XX
                    </RadioGroup.Label>
                    <div className="text-xxs font-semibold pr-8 group-hover:underline">
                      <div className="ui-form-card-checkbox">
                        <svg
                          className="ui-icon w-2 h-2 text-white hidden"
                          focusable="false"
                          aria-hidden="true"
                          role="presentation"
                        >
                          <use href="#ui-icon-checkmark"></use>
                        </svg>
                      </div>
                    </div>
                  </>
                )}
              </RadioGroup.Option>
            </RadioGroup>
          </form>
        </div >
      )}

    </>
  )
}

export default PanelTwo;
