import { useState } from "react";
import { userData } from "../../js/placeholderData/agents/profile";
import { settings } from "../../js/placeholderData/agents/settings";
import ProfileCard from "../../components/AgentPortal/AgentProfile/AgentProfileCard";
import SimpleCTAToDialogCard from "../../components/General/SimpleCTAToDialogCard";
import AppNavigationCard from "../../components/General/AppNavigationCard";


import UserInfoDialog from "../../components/AgentPortal/AgentProfile/Dialogs/UserInfoDialog";
import UserSecurityDialog from "../../components/AgentPortal/AgentProfile/Dialogs/UserSecurityDialog";
import UserNotificationDialog from "../../components/AgentPortal/AgentProfile/Dialogs/UserNotificationDialog";

const Profile = () => {
  let [openUserInfoDialog, setOpenUserInfoDialog] = useState(false);
  let [openUserSecurityDialog, setOpenUserSecurityDialog] = useState(false);
  let [openUserNotificationDialog, setOpenUserNotificationDialog] =
    useState(false);

  return (
    <>
      {/* SPRINT-9-AGENT-help-sales-and-search.pdf (pg. 17) */}
      <div className="container mb-8">
        <h1>My Account </h1>
        <p>Lorem ipsum</p>
        <hr />
      </div>

      {/* Profile Details */}
      <ProfileCard data={userData} />

      {/* Manage my Info & Preferences */}
      <div className="container ui-component-spacing">
        <h2>Manage my information &amp; preferences</h2>
        <p className="text-sm">
          Subtitle explaining this group of functions goes here
        </p>
        <hr />

        <div className="grid grid-cols-1 gap-4 md:grid-cols-2 lg:grid-cols-3 xl:gap-6">
          <SimpleCTAToDialogCard
            title="Contact Info"
            desc="Text to explain this function goes here. Lore ipsum dolor sit amen ipsum dolor est."
            ctaText="Update"
            openDialog={() => setOpenUserInfoDialog(true)}
          />
          {/* Contact Information */}
          <UserInfoDialog
            dialogState={openUserInfoDialog}
            setDialogState={setOpenUserInfoDialog}
          />

          <SimpleCTAToDialogCard
            title="Security Settings"
            desc="Text to explain this function goes here. Lore ipsum dolor sit amen ipsum dolor est."
            ctaText="Manage"
            openDialog={() => setOpenUserSecurityDialog(true)}
          />
          {/* Security Settings */}
          <UserSecurityDialog
            dialogState={openUserSecurityDialog}
            setDialogState={setOpenUserSecurityDialog}
          />

          <SimpleCTAToDialogCard
            title="Notifications"
            desc="Text to explain this function goes here. Lore ipsum dolor sit amen ipsum dolor est."
            ctaText="Manage"
            openDialog={() => setOpenUserNotificationDialog(true)}
          />
          {/*  Manage Notification Preferences */}
          <UserNotificationDialog
            dialogState={openUserNotificationDialog}
            setDialogState={setOpenUserNotificationDialog}
          />
        </div>
      </div>

      {/* Other Settings */}
      <div className="container ui-component-spacing">
        <h2>Other settings</h2>
        <p className="text-sm">
          Subtitle explaining this group of functions goes here
        </p>
        <hr />

        <div className="grid grid-cols-1 gap-4 auto-rows-fr sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-4">
          {settings.map((item, idx) => (
            <AppNavigationCard
              key={idx}
              icon={item.icon}
              title={item.title}
              desc={item.desc}
              url={item.url}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default Profile;
