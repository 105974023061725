import { v4 as uuidv4 } from "uuid";
import { useState, useEffect } from "react";
import { cardArray } from "../../../js/placeholderData/managePaymentData";
import AddPaymentEFT from "../../ModalDialogs/AddPaymentEFT";
import AddPaymentCard from "../../ModalDialogs/AddPaymentCard";
import FormInputError from "../../General/FormInputError";

export default function PaymentMethod({
  register,
  validate,
  watch,
  control,
  setValue,
  getFieldState,
  errors,
  setCurrentStep,
}) {
  const paymentMethodId = uuidv4();
  const [paymentMethodState, setPaymentMethodState] = useState("");
  const [paymentMethodValid, setPaymentMethodValid] = useState(false);

  useEffect(() => {
    if (!paymentMethodState) {
      setPaymentMethodValid(false);
      setCurrentStep(3);
    }
  }, [paymentMethodState]);

  useEffect(() => {
    paymentMethodValid ? setCurrentStep(4) : setCurrentStep(3);
  }, [paymentMethodValid]);

  return (
    <>
      <div className="ui-form-card-no-event">
        <label
          id={paymentMethodId + "-label"}
          htmlFor={paymentMethodId}
          className="block mb-1"
        >
          Payment method *
        </label>
        <select
          name="method"
          id={paymentMethodId}
          aria-labelledby={paymentMethodId + "-label"}
          aria-describedby={paymentMethodId + "-error"}
          {...register("method", "Please select a payment method")}
          onChange={async (e) => {
            await setValue("method", e.target.value, { shouldValidate: true });
            setPaymentMethodState(e.target.value);
          }}
        >
          <option value="">Please select a payment method</option>
          {cardArray.map((type, idx) => {
            return (
              <option key={idx} value={type.name}>
                {type.name}
              </option>
            );
          })}
        </select>
        <div id={paymentMethodId + "-error"}>
          {errors.method?.message && (
            <FormInputError errorMessage={errors.method.message} />
          )}
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 grid-flow-row gap-4 mt-4">
        {paymentMethodState === "Credit Card" && (
          <AddPaymentCard
            register={register}
            errors={errors}
            autoValidate={true}
            trigger={validate}
            watch={watch}
            control={control}
            validateTimer="100"
            setValidationState={setPaymentMethodValid}
          />
        )}
        {paymentMethodState === "EFT (Electronic Funds Transfer)" && ( 
          <AddPaymentEFT
            register={register}
            errors={errors}
            watch={watch}
            autoValidate={true}
            trigger={validate}
            getFieldState={getFieldState}
            validateTimer="100"
            setValidationState={setPaymentMethodValid}
          />
        )}
      </div>
    </>
  );
}
