import { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import useEmblaCarousel from "embla-carousel-react";
import "../../css/carousel.css";

export default function Carousel() {
  const placeholderData = [
    {
      uiTextColor: "text-ui-green",
      iconBorder: "ui-icon-border-good",
      title: "Grid Attention: Claims packet available for you to complete -- 0",
      detail: `We need a claimant form and several other documents to process claim #1CL23523. To expedite processing of your claim fill out the requested forms and upload the requested documents as soon as possible. You can <a href="#">work on the claim here</a>. If you need assistance, please <a href="#">contact us</a>.`,
      buttons: [
        {
          buttonText: "Sign Up For Autopay",
          buttonClass: "ui-btn-secondary",
          url: "/payment/manage-payments",
        },
        {
          buttonText: "Make a Payment",
          buttonClass: "ui-btn-primary",
          url: "/payment",
        },
      ],
    },
    {
      uiTextColor: "text-ui-yellow",
      iconBorder: "ui-icon-border-risk",
      title: "Grid Attention: Claims packet available for you to complete -- 1",
      detail: `We need a claimant form and several other documents to process claim #1CL23523. To expedite processing of your claim fill out the requested forms and upload the requested documents as soon as possible. You can <a href="#">work on the claim here</a>. If you need assistance, please <a href="#">contact us</a>.`,
      buttons: [
        {
          buttonText: "Sign Up For Autopay",
          buttonClass: "ui-btn-secondary",
          url: "/payment/manage-payments",
        },
        {
          buttonText: "Make a Payment",
          buttonClass: "ui-btn-primary",
          url: "/payment",
        },
      ],
    },
    {
      uiTextColor: "text-ui-red",
      iconBorder: "ui-icon-border-problem",
      title: "Grid Attention: Claims packet available for you to complete -- 2",
      detail: `We need a claimant form and several other documents to process claim #1CL23523. To expedite processing of your claim fill out the requested forms and upload the requested documents as soon as possible. You can <a href="#">work on the claim here</a>. If you need assistance, please <a href="#">contact us</a>.`,
      buttons: [
        {
          buttonText: "Sign Up For Autopay",
          buttonClass: "ui-btn-secondary",
          url: "/payment/manage-payments",
        },
        {
          buttonText: "Make a Payment",
          buttonClass: "ui-btn-primary",
          url: "/payment",
        },
      ],
    },
  ];

  const [viewportRef, embla] = useEmblaCarousel(
    {
      skipSnaps: false,
      draggable: false,
    },
    []
  );
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [scrollSnaps, setScrollSnaps] = useState([]);
  const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
  const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);

  const scrollTo = useCallback(
    (index) => embla && embla.scrollTo(index),
    [embla]
  );

  const onSelect = useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
  }, [embla, setSelectedIndex]);

  const handleKeyPress = (e) => {
    switch (e.keyCode) {
      default:
        break;
      case 37:
        scrollPrev();
        break;
      case 39:
        scrollNext();
        break;
    }
  };

  useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on("select", onSelect);
  }, [embla, setScrollSnaps, onSelect]);

  return (
    <div className="embla" onKeyDown={handleKeyPress}>
      <div className="embla__viewport" ref={viewportRef}>
        <div className="embla__container">
          {placeholderData.map((slide, i) => {
            return (
              <div className="embla__slide" key={`slide-${i + 1}`}>
                <div className="flex flex-col">
                  <div className="grid ui-grid-carousel gap-2">
                    <div className="md:row-span-2">
                      {slide.iconBorder && (
                        <svg
                          className={`ui-icon w-12 h-12 ${slide.uiTextColor}`}
                          focusable="false"
                          aria-hidden="true"
                          role="presentation"
                        >
                          <use href={"#" + slide.iconBorder}></use>
                        </svg>
                      )}
                    </div>
                    <h2 className="text-2xl font-body font-semibold">
                      {slide.title}
                    </h2>
                    <div className="col-span-2 md:col-auto">
                      {slide.detail}

                      {slide.buttons.length > 0 && (
                        <div className="ui-btn-bar">
                          {slide?.buttons.map((val, j) => {
                            return (
                              <Link
                                to={val.url}
                                className={val.buttonClass}
                                tabIndex={`${selectedIndex === i ? "0" : "-1"}`}
                                key={`action-buttons-${j}`}
                              >
                                {val.buttonText}
                              </Link>
                            );
                          })}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>

        <div className="flex justify-center items-center pt-2">
          <button
            aria-label="previous slide"
            className="mx-1"
            onClick={scrollPrev}
          >
            <svg
              className="ui-icon w-6 h-6 group text-ui-blue hover:shadow"
              focusable="false"
              aria-hidden="true"
              role="presentation"
            >
              <use
                className="hidden group-hover:block"
                href="#ui-icon-previous-hover"
              ></use>
              <use
                className="block group-hover:hidden"
                href="#ui-icon-previous"
              ></use>
            </svg>
          </button>

          {placeholderData.map((slide, k) => {
            return (
              <button
                aria-label={`slide ${k + 1}`}
                className="mx-1 rounded-full"
                onClick={() => scrollTo(k)}
                key={`slide-button-${k + 1}`}
              >
                {selectedIndex === k && (
                  <svg
                    className="ui-icon w-6 h-6 text-theme-text-primary cursor-default"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use href="#ui-icon-dot-current"></use>
                  </svg>
                )}

                {selectedIndex != k && (
                  <svg
                    className="ui-icon w-6 h-6 group text-ui-blue"
                    focusable="false"
                    aria-hidden="true"
                    role="presentation"
                  >
                    <use
                      className="hidden group-hover:block"
                      href="#ui-icon-dot-hover"
                    ></use>
                    <use
                      className="block group-hover:hidden"
                      href="#ui-icon-dot"
                    ></use>
                  </svg>
                )}
              </button>
            );
          })}
          <button aria-label="next slide" className="mx-1" onClick={scrollNext}>
            <svg
              className="ui-icon w-6 h-6 group text-ui-blue hover:shadow"
              focusable="false"
              aria-hidden="true"
              role="presentation"
            >
              <use
                className="hidden group-hover:block"
                href="#ui-icon-next-hover"
              ></use>
              <use
                className="block group-hover:hidden"
                href="#ui-icon-next"
              ></use>
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
}
