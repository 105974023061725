
const CommissionsCard = ({ data }) => {
    return (
      <>
        <h2>Summary</h2>
        <p className="text-sm">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
        <hr />

        <div className="grid grid-cols-1 gap-4 md:grid-cols-3 xl:gap-6">
          {data.map((item, idx) => (
            <div key={idx} className="ui-card p-4 xl:p-6 grid grid-cols-1">
              <div>
                <div className="font-semibold">{item.title}</div>
                <div className="mt-2 grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-1 lg:grid-cols-2">
                  <div>
                    <div className="text-2xl font-semibold text-theme-text-secondary">
                      {item.earnedCommissions}
                    </div>
                    <div className="text-xs text-theme-text-tertiary">
                      Earned commissions
                    </div>
                  </div>
                  <div>
                    <div className="text-2xl font-semibold text-theme-text-secondary">
                      {item.chargeBacks}
                    </div>
                    <div className="text-xs text-theme-text-tertiary">
                      Chargebacks
                    </div>
                  </div>
                </div>
                <hr className="my-4" />
                <div className="mt-4 text-xxs">{item.additionalText}</div>
                {item.explanationText && (
                  <div className="mt-4 text-xxs">{item.explanationText}</div>
                )}
              </div>
              <div className="mt-4 text-right self-end">
                <button className="ui-btn-primary">call to action</button>
              </div>
            </div>
          ))}
        </div>
      </>
    );
}

export default CommissionsCard