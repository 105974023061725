import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useIsAgentCheck } from "../hooks/useIsAgentCheck";

export default function LoginCard({ setAuthenicated }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  let navigate = useNavigate();
  const onSubmit = (data) => {
    // alert(JSON.stringify(data));
    setAuthenicated(true);
    window.localStorage.setItem("authenticated", true);
    window.localStorage.setItem("isBeneficiaryUser", false);     
    IS_AGENT ? navigate("/dashboard") : navigate("/policies");
  };
  const { IS_AGENT } = useIsAgentCheck();

  return (
    <div className="ui-card text-center p-4 relative xl:p-6">
      <h2>Login</h2>
      <p className="text-sm mb-6">
        Login to view and manage your policies and account.
      </p>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="text-left max-w-xs mx-auto">
          <div className="mb-4">
            <label
              id="ID_usernameLabel"
              htmlFor="ID_username"
              className="block mb-1"
            >
              Username
            </label>
            <input
              id="ID_username"
              type="text"
              placeholder="Enter username"
              aria-placeholder="Enter username"
              aria-labelledby="ID_usernameLabel"
              aria-describedby="ID_usernameError"
              {...register("username", {
                required: {
                  value: true,
                  message: "Please enter username.",
                },
              })}
            />
            <div id="ID_usernameError">
              {errors.username && (
                <div className="ui-input-error">{errors.username.message}</div>
              )}
            </div>
          </div>
          <div className="mb-6">
            <label
              id="ID_passwordLabel"
              htmlFor="ID_password"
              className="block mb-1"
            >
              Password
            </label>
            <input
              id="ID_password"
              type="password"
              placeholder="Enter password"
              aria-placeholder="Enter password"
              aria-labelledby="ID_passwordLabel"
              aria-describedby="ID_passwordError"
              {...register("password", {
                required: {
                  value: true,
                  message: "Please enter password.",
                },
              })}
            />
            <div id="ID_passwordError">
              {errors.password && (
                <div className="ui-input-error">{errors.password.message}</div>
              )}
            </div>
          </div>
        </div>
        <button className="ui-btn-primary min-w-[144px] mb-10 mx-auto">
          Login
        </button>
      </form>
      <p className="text-xs mb-3">
        Forgot username or password?{" "}
        <Link to="/forgot-password" className="font-semibold">
          Click here
        </Link>
      </p>
      {IS_AGENT ? (
        <p className="text-xs">New here? Contact your manager to get set up</p>
      ) : (
        <p className="text-xs">
          New here?{" "}
          <Link to="/register" className="font-semibold">
            Sign up for an account
          </Link>
        </p>
      )}
    </div>
  );
}
